import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resources';
import {makePostDataRequest} from '../../utils/postRequest';
import {createDateTimeObject} from '../../utils/dataFunctions';
import {setDueDate} from '../../utils/projectFunctions';
import BaseInput from '../fields/BaseInput';
import SelectInput from '../fields/SelectInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import ResourceSelectInput from '../fields/ResourceSelectInput';
import TextAreaInput from '../fields/TextAreaInput';
import {CHALLENGE_TABS} from '../../data/formTabs';
import {BOOLEAN_OPTIONS} from '../../data/objects';
import 'react-datepicker/dist/react-datepicker.min.css';

/**
 * Form: Add Challenge
 *
 * @param challengePath
 * @param formStage
 * @param onFormEvent
 * @param basePath
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormChallengeAdd(
    {
        challengePath,
        formStage,
        onFormEvent,
        basePath = ''
    }) {

    // Set up form and state
    const {register, errors, formState, getValues, handleSubmit, control} = useForm({mode: 'onBlur'});

    // State: Form Stage (from parent)
    const [formStageState, updateFormStageState] = useState(
        {
            stage: formStage
        }
    );
    if (formStageState.stage !== formStage) {
        updateFormStageState({...formStageState, stage: formStage});
    }
    // Initial Form State
    const [fieldState, updateFieldState] = useState(
        {
            active: true,
            community_desc: '',
            complete: false,
            due_date: createDateTimeObject(new Date()),
            gitlab_group_id: '',
            goals: '',
            introduction: '',
            org_id: '',
            org_select: null,
            private: true,
            published: false,
            title: '',
            reward: '',
            summary: ''
        }
    );
    // State: Due Date Object
    const [dueDateState, updateDueDateState] = useState(
        {
            min_date_obj: setDueDate('/'),
            due_date_obj: setDueDate('/').due_date
        }
    );
    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [tabState, setTab] = useState(CHALLENGE_TABS.overview);
    // State: Created Challenge
    const [challengeId, setChallengeId] = useState('');

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            getValues('org_id') === '' ||
            getValues('introduction') === '' ||
            getValues('reward') === '' ||
            getValues('summary') === '' ||
            getValues('title') === '' ||
            tabState.tab === 'saving' ||
            (errors &&
                (
                    errors.introduction ||
                    errors.reward ||
                    errors.summary ||
                    errors.title
                ))
        );
    };

    // Handle change from specific form fields
    const handleFormChange = (e) => {
        if (e.name === 'due_date') {
            // Set a Due Date object
            const dateString = createDateTimeObject(e.value);
            updateFieldState({...fieldState, [e.name]: dateString});
            updateDueDateState({...dueDateState, due_date_obj: e.value});

        } else if (e.name === 'org_select') {
            // Populate form values
            updateFieldState({...fieldState, gitlab_group_id: e.value.gitlab_org_id, org_id: e.value.id});
        } else if (e.name === 'active' || e.name === 'complete' || e.name === 'published' || e.name === 'private') {
            updateFieldState({...fieldState, [e.name]: (e.value.toString() === 'true')});
        } else {
            updateFieldState({...fieldState, [e.name]: e.value});
        }
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // console.log('SUBMIT fieldState: ', fieldState);
            // console.log('SUBMIT data args before: ', data);

            // Set parameters from form state
            data.gitlab_group_id = fieldState.gitlab_group_id;
            data.org_id = fieldState.org_id;
            data.active = fieldState.active;
            data.complete = fieldState.complete;
            data.private = fieldState.private;
            data.published = fieldState.published;

            // Delete unused parameters before submitting
            delete data.org_select;

            // console.log('SUBMIT data args: ', data);

            // Submit form to API
            setTab(CHALLENGE_TABS.saving);
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'Challenge', 'CREATE', data);
        request.then(result => {
            if (result && !result.error) {
                updateSuccessState({success: true, message: ''});
                setTab(CHALLENGE_TABS.success);
                setChallengeId(result.id);
                setPostChallenge(result);
                return Promise.resolve(result);
            } else {
                updateSuccessState({success: false, message: 'Sorry, your Challenge could not be created.'});
                setTab(CHALLENGE_TABS.fail);
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Add Challenge Error: ', error);
            updateSuccessState({success: false, message: 'Sorry, there was an error with your Challenge: ' + error});
            setTab(CHALLENGE_TABS.fail);
            return Promise.reject('Server Error');
        });
    };

    // Process after Challenge form completion
    const setPostChallenge = (data) => {
        if (onFormEvent !== null && typeof onFormEvent === 'function') {
            // Notify the page that Challenge has been created
            onFormEvent('challenge', 'complete', data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                {/* Form Menu */}
                <div className="item-menu">
                    <ul>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.overview)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Overview
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.information)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Information
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.confirm)}
                                    disabled={!!disableSubmit()}
                            >
                                Finalize
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="item-content">

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(tabState.tab === 'overview') ? 'active' : ''}`}>
                        <p className="instructions">
                            Select an Organization this Challenge is associated with.<br/>
                            Enter basic Challenge information. Click <em>Next</em> to enter Description information.
                        </p>

                        <div className="form-group">
                            <Controller
                                name="org_select"
                                control={control}
                                defaultValue={fieldState.org_select}
                                render={props => (
                                    <ResourceSelectInput
                                        label="Select Organization"
                                        resource="Organization"
                                        optionText="name"
                                        optionValue="id"
                                        params={{active: true, page: 1, perPage: 10000}}
                                        ref={register}
                                        required
                                        changeFunc={(e) => handleFormChange({name: 'org_select', value: e})}
                                        className="form-input"
                                        {...props}
                                    />
                                )}
                            />
                        </div>
                        <div className="form-group">
                            {/* Title */}
                            <BaseInput
                                type="text"
                                id="challenge-title"
                                name="title" label="Title" placeholder="Title of Challenge"
                                aria-describedby="error-title-required error-title-minLength error-title-maxLength"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Title is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Title must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 80,
                                        message: (<FormError id="maxLength"
                                                             text="Title cannot be longer than 80 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Reward */}
                            <TextAreaInput
                                id="challenge-reward"
                                rows={3} cols={50}
                                name="reward" label="Reward"
                                instructions="Enter a dollar amount, list of prizes, or other rewards for this Challenge."
                                aria-describedby="error-reward-required"
                                aria-invalid={errors.reward ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Reward is required"/>)
                                })}
                                required
                                className={`form-input ${errors.reward ? 'input-error' : ''}`}
                            >
                                {errors.reward && errors.reward.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Goals */}
                            <TextAreaInput
                                id="challenge-goals"
                                rows={3} cols={50}
                                name="goals" label="Challenge Goals"
                                aria-describedby="error-goals-required"
                                aria-invalid={errors.goals ? 'true' : 'false'}
                                ref={register}
                            >
                            </TextAreaInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Due Date */}
                            <label htmlFor="challenge-due-date">
                                <div>Due Date<span className="required">*</span></div>
                                <DatePicker
                                    id="challenge-due-date"
                                    minDate={dueDateState.min_date_obj.due_date}
                                    selected={dueDateState.due_date_obj}
                                    onChange={(e) => handleFormChange({name: 'due_date', value: e})}
                                    dateFormat="MMMM d, yyyy"
                                    popperPlacement="right"
                                    style={{display: 'block'}}
                                />
                                <input type="hidden" name="due_date" value={fieldState.due_date} ref={register}/>
                            </label>
                            {/* Challenge Active */}
                            <SelectInput
                                id="challenge-active"
                                name="active"
                                label="Active"
                                options={BOOLEAN_OPTIONS}
                                changeFunc={(e) => handleFormChange({name: 'active', value: e.value})}
                                optionSelected={fieldState.active}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-group">
                            {/* Private */}
                            <SelectInput
                                id="challenge-private"
                                instructions='Note that "private" is automatically set to true upon saving.
                                If you want the challenge to be public, you can Update The Challenge and mark "private"
                                as false after first creating the Challenge.'
                                name="private"
                                label="Private"
                                options={BOOLEAN_OPTIONS}
                                changeFunc={(e) => handleFormChange({name: 'private', value: e.value})}
                                optionSelected={true}
                                disabled
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-group">
                            {/* Published */}
                            <SelectInput
                                id="challenge-published"
                                instructions='Note that "Published" is automatically set to false upon saving. If you
                                want the challenge to be published, you can Update The Challenge and mark "Published"
                                as true after first creating the Challenge.'
                                name="published"
                                label="Published"
                                options={BOOLEAN_OPTIONS}
                                changeFunc={(e) => handleFormChange({name: 'published', value: e.value})}
                                optionSelected={fieldState.published}
                                disabled
                                ref={register()}

                            >
                            </SelectInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Complete */}
                            <SelectInput
                                id="challenge-complete"
                                name="complete"
                                label="Complete"
                                options={BOOLEAN_OPTIONS}
                                changeFunc={(e) => handleFormChange({name: 'complete', value: e.value})}
                                optionSelected={fieldState.complete}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>

                        <input type="hidden" name="org_id" value={fieldState.org_id} ref={register}/>
                        {/*<input type="hidden" name="gitlab_group_id" value={fieldState.gitlab_group_id}*/}
                        {/*       ref={register}/>*/}
                    </div>

                    {/* Tab: Information */}

                    <div className={`tab-content ${(tabState.tab === 'information') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter Challenge descriptive data. Markdown may be used, but no HTML is allowed. When all
                            information is completed, click <em>Next</em> to confirm and Save the Challenge.
                        </p>
                        <div className="form-group">
                            {/* Summary */}
                            <TextAreaInput
                                id="challenge-summary"
                                rows={4} cols={50}
                                name="summary" label="Summary"
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.summary ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Summary is required"/>)
                                })}
                                required
                                className={`form-input ${errors.summary ? 'input-error' : ''}`}
                            >
                                {errors.summary && errors.summary.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Introduction */}
                            <TextAreaInput
                                id="challenge-introduction"
                                rows={6} cols={50} maxLength={255}
                                name="introduction" label="Introduction"
                                aria-describedby="error-introduction-required"
                                aria-invalid={errors.introduction ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="An Introduction is required"/>)
                                })}
                                required
                                className={`form-input ${errors.introduction ? 'input-error' : ''}`}
                            >
                                {errors.introduction && errors.introduction.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Community Description */}
                            <TextAreaInput
                                id="challenge-community-description"
                                rows={6} cols={50}
                                name="community_desc" label="Community Description"
                                ref={register}
                                className="form-input"
                            >
                            </TextAreaInput>
                        </div>
                    </div>

                    {/* Tab: Confirm && Save */}

                    <div className={`tab-content ${(tabState.tab === 'confirm') ? 'active' : ''}`}>
                        <p className="instructions">
                            Save your Challenge. Once the Challenge is saved, you will be able to upload a Dataset
                            associated with this Challenge if desired.
                        </p>
                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(tabState.tab === 'saving') ? 'active' : ''}`}>
                        <h4>Saving Challenge...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(tabState.tab === 'success') ? 'active' : ''}`}>
                        <h4>Challenge Saved</h4>
                        <p>
                            Your Challenge has been saved. If this Challenge needs a Dataset, add one below. You can
                            add a Dataset at any time by viewing a Challenge and
                            clicking <strong>Edit Dataset</strong>.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(tabState.tab === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                            <p className="message">
                                {successState.message}
                            </p>
                        }
                        {successState.message === '' &&
                            <p className="message">
                                Sorry, there was an issue saving this Challenge.
                            </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        <div className="tab-nav">
                            {tabState.tab === 'overview' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Next"
                                            onClick={() => setTab(CHALLENGE_TABS.information)}
                                    >
                                        Next
                                    </button>
                                </>
                            }
                            {tabState.tab === 'information' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Back"
                                            onClick={() => setTab(CHALLENGE_TABS.overview)}
                                    >
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-clear" title="Next"
                                            onClick={() => setTab(CHALLENGE_TABS.confirm)}
                                            disabled={!!disableSubmit()}
                                    >
                                        Next
                                    </button>
                                </>
                            }
                            {tabState.tab === 'confirm' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Back"
                                            onClick={() => setTab(CHALLENGE_TABS.information)}
                                    >
                                        Back
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                        Add Challenge
                                    </button>
                                </>
                            }
                            {tabState.tab === 'success' &&
                                <Link className="btn btn-primary"
                                      to={`${basePath}/x/${challengePath}/challenge/${challengeId}/overview`}>
                                    View Challenge Details
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}


import Config from '../config';

/**
 * Determine mobile state based on width
 *
 * @return {boolean}
 */
export const isMobile = () => {
    return (window.innerWidth <= Config.pageSize.mobile);
};

/**
 * Return small mobile state based on width
 *
 * @return {boolean}
 */
export const isMobileSmall = () => {
    return (window.innerWidth <= Config.pageSize.small);
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {readEntryContentAsync} from '../utils/fileFunctions';
import IconAddCircle from './images/IconAddCircle';
import '../css/drag.css';

/**
 * A Drag and Drop component for files and folders
 */
class FileDrag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drag: false,
            error: null,
        };
        this.dragCounter = 0;
    }

    componentDidMount() {
        this.addDragListeners();
    }

    componentWillUnmount() {
        this.removeDragListeners();
    }

    addDragListeners = () => {
        this.removeDragListeners();
        let el = document.getElementById(this.props.id);
        el.addEventListener('dragenter', event => this.handleDragIn(event));
        el.addEventListener('dragleave', event => this.handleDragOut(event));
        el.addEventListener('dragover', event => this.handleDrag(event));
        el.addEventListener('drop', event => this.handleDrop(event));
    };

    removeDragListeners = () => {
        let el = document.getElementById(this.props.id);
        el.removeEventListener('dragenter', event => this.handleDragIn(event));
        el.removeEventListener('dragleave', event => this.handleDragOut(event));
        el.removeEventListener('dragover', event => this.handleDrag(event));
        el.removeEventListener('drop', event => this.handleDrop(event));
    };

    resetFileUpload = (file = null) => {
        this.props.onReady((file) ? file : null);
    };

    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter++;
        this.setState({
            drag: true
        });
    }

    handleDragOut(event) {
        event.preventDefault();
        event.stopPropagation();

        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({
                drag: false,
                error: null
            });
        }
    }

    handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let dtFiles, dtItems;
        let filesList = [];
        let entryList = [];
        let processed = 0;

        if (e.dataTransfer) {
            dtFiles = e.dataTransfer.files;
            dtItems = e.dataTransfer.items;
            const dtFileLength = dtFiles.length;

            const updateProcessed = () => {
                processed++;
                if (processed >= dtItemLength) {
                    this.setState({
                        drag: false
                    });
                    this.dragCounter = 0;
                    e.dataTransfer.clearData();
                    this.processItems(entryList);
                }
            };

            // Store Files temporarily to reference in collection
            if (dtFiles && dtFileLength > 0) {
                for (let i = 0; i < dtFileLength; i++) {
                    let file = dtFiles[i];
                    if (file.name.substring(0, 1) !== '.') {
                        filesList.push(file);
                    }
                }
            }

            // Cycle through DataTransfer items
            const dtItemLength = dtItems.length;
            if (dtItems && dtItemLength > 0) {
                for (let i = 0; i < dtItemLength; i++) {
                    let item = dtItems[i];
                    if (item) {
                        let entry = (item.getAsEntry) ? item.getAsEntry() : item.webkitGetAsEntry();
                        // If item is a file, add it to the list
                        if (entry.isFile && entry.name.substring(0, 1) !== '.') {
                            let file = filesList.find(f => f.name === entry.name);
                            if (file) {
                                let obj = {
                                    file: file,
                                    path: entry.fullPath
                                };
                                entryList.push(obj);
                                updateProcessed();
                            }
                        }
                        // If item is a directory, extract it and its contents recursively
                        if (entry.isDirectory) {
                            readEntryContentAsync(entry)
                                .then((collection) => {
                                    entryList.push(...collection);
                                    updateProcessed();
                                }).catch(error => {
                                    console.log('error: ', error);
                                });
                        }
                    }
                }
            }
        }
    };

    /**
     * Arrange dragged files and pass to parent
     *
     * @param collection
     */
    processItems = (collection) => {
        this.props.onReady(collection);
    };

    render() {
        const {
            id
        } = this.props;

        const {
            drag,
            error
        } = this.state;

        let containerClasses;
        if (error) {
            containerClasses = 'error';
        } else if (drag) {
            containerClasses = 'drag';
        } else {
            containerClasses = 'ready';
        }

        return (
            <div id={id} className={`drag-container ${containerClasses}`}>
                <div className="drag-border">
                    <IconAddCircle className="icon-add"/>
                </div>
            </div>
        )
    }
}

FileDrag.propTypes = {
    id: PropTypes.string,
    onReady: PropTypes.func
};

FileDrag.defaultProps = {
    id: 'drag-container'
};

export default FileDrag;

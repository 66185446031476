import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Component to display article thumbnail
 *
 */
class ArticleLink extends Component {
    render() {
        const {
            article,
            classes,
            slug
        } = this.props;

        const style = {backgroundImage: `url(/images/${article.thumb})`};

        return (
            <div className={`article-link-container ${classes}`}>
                <Link to={article.link}
                      title={article.thumb_title}
                      className={`article-link ${slug}`}
                >
                    <span className="bg" style={style}>
                    </span>
                    {article.thumb_title &&
                    <span className="title">
                        {article.thumb_title}
                    </span>
                    }
                </Link>
            </div>
        )
    }
}

ArticleLink.propTypes = {
    article: PropTypes.object,
    classes: PropTypes.string,
    slug: PropTypes.string
};

ArticleLink.defaultProps = {
    classes: ''
};

export default withRouter(ArticleLink);

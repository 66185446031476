import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {convertSizeToDisplayString} from '../utils/dataFunctions';
import FileUploadListItem from './FileUploadListItem';

class FileUploadList extends Component {

    handleRemoveClick = (e) => {
        if (typeof this.props.onRemove === 'function') {
            this.props.onRemove(e);
        }
    };

    setFileItems = () => {
        const list = this.props.list;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Files Uploaded</span>
            )
        }

        return (
            list.map((item, index) => (
                <FileUploadListItem
                    key={`file-${item.file.name}-${index}`}
                    name={item.file.name}
                    path={item.path}
                    size={item.file.size}
                    sizeStr={convertSizeToDisplayString(item?.file?.size ?? 0)}
                    onRemove={this.handleRemoveClick}
                />
            ))
        )
    };

    render() {
        return (
            <div>
                {this.props.list.length > 0 &&
                <>
                    {[...this.setFileItems()]}
                </>
                }
            </div>
        )
    }
}

FileUploadList.propTypes = {
    list: PropTypes.array,
    onRemove: PropTypes.func
};

FileUploadList.defaultProps = {
    list: []
};

export default FileUploadList;

import React, {useCallback, useEffect, useState} from 'react';
import {RESOURCES as resources} from '../../resourcesUser';
import {makeGetDataRequest} from '../../utils/getRequest';
import Config from '../../config';

/**
 * Event Configuration Fields and Inputs
 *
 * @param viewType
 * @param event
 * @param config
 * @param ownEvent
 * @param onConfigUpdated
 * @returns {JSX.Element}
 * @constructor
 */
const EventConfigFields = ({viewType, event, config, ownEvent, onConfigUpdated}) => {
    const [global, setGlobal] = useState({
        max_event_shares: config?.max_shares ?? Config.eventConfigDefault.max_shares
    });
    const [configValues, setConfigValues] = useState({
        max_event_shares: event?.max_shares ?? global.max_event_shares

    });
    // Callback: Set Config values with fallback
    const updateConfig = useCallback((userConfig) => {
        let config = {};
        // Set Event value, User value, or global default value
        if (event.max_shares !== null) {
            config.max_event_shares = event.max_shares;
        } else {
            config.max_event_shares = (userConfig.max_event_shares !== null)
                    ? userConfig.max_event_shares : global.max_event_shares;
        }
        setConfigValues(config);

        if (typeof onConfigUpdated === 'function') {
            onConfigUpdated(config);
        }
    }, [event, global, onConfigUpdated])

    // Effect: Update Global default Configurations
    useEffect(
        () => {
            const updateGlobalConfig = () => {
                setGlobal({
                    max_event_shares: config?.max_event_shares ?? Config.eventConfigDefault.max_shares
                });
            };

            return updateGlobalConfig();

        }, [config]
    )

    // Effect: Get Configurations from Event's Creator (if any)
    useEffect(() => {
        const params = {
            username: event.username
        };
        const fetchUserData = () => {
            const resource = (ownEvent) ? 'User' : 'UserInfo';
            const request = makeGetDataRequest(resources, resource, 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    updateConfig({
                        max_event_shares: result?.max_event_shares ?? null
                    });
                    return Promise.resolve(result);
                } else {
                    console.log('User Error: No result');
                    return Promise.reject('Get User failed');
                }
            }).catch(error => {
                console.log('Get User Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchUserData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event.username, ownEvent]);

    if (viewType === 'edit') {
        return (
            <>
            </>
        )
    }

    return (
        <div className="row mb-2">
            <span className="col col-xs-6 col-md-4">
                <h4>Max Invites</h4>

                <p className="details">{configValues.max_event_shares}</p>
            </span>
        </div>
    );
};

export default EventConfigFields;

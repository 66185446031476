export const CHALLENGE_TABS = {
    confirm: {tab: 'confirm', title: 'Confirm and Save'},
    fail: {tab: 'fail', title: 'Error'},
    information: {tab: 'information', title: 'Information'},
    overview: {tab: 'overview', title: 'Overview'},
    saving: {tab: 'saving', title: 'Saving Challenge'},
    success: {tab: 'success', title: 'Challenge Saved'},
};

export const PROJECT_TABS = {
    config: {tab: 'config', title: 'Configurations'},
    confirm: {tab: 'confirm', title: 'Confirm and Save'},
    fail: {tab: 'fail', title: 'Error'},
    information: {tab: 'information', title: 'Information'},
    overview: {tab: 'overview', title: 'Overview'},
    saving: {tab: 'saving', title: 'Saving Project'},
    success: {tab: 'success', title: 'Project Saved'},
};

export const EVENT_TABS = {
    confirm: {tab: 'confirm', title: 'Confirm and Save'},
    fail: {tab: 'fail', title: 'Error'},
    overview: {tab: 'overview', title: 'Overview'},
    saving: {tab: 'saving', title: 'Saving Event'},
    success: {tab: 'success', title: 'Event Saved'},
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class SubstrateGreeting extends Component {
    render() {
        const {
            substrate,
            user
        } = this.props;

        let welcome = (user.name) ? ', ' + user.name : '';
        let persona = (user.persona.plural) ? ' for ' + user.persona.plural : '';
        let greeting = 'Welcome' + welcome + '.';
        greeting += (substrate !== 'Marketplace' && substrate !== 'Events')
            ? ' You are on the ' + substrate + ' Dashboard, a customizable page' + persona + '.'
            : '';

        return (
            <span className="welcome-user">
                {greeting}
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

SubstrateGreeting.propTypes = {
    user: PropTypes.any,
    substrate: PropTypes.any,
};

export default connect(
    mapStateToProps,
    {}
)(SubstrateGreeting);

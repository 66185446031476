import React, {useState, useEffect, useCallback} from 'react';
import {Link, withRouter} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {RESOURCES as resources} from '../resources';
import {isBlank, makeDateString} from '../utils/dataFunctions';
import {makePostDataRequest} from '../utils/postRequest';
import EditFilesButton from './buttons/EditFilesButton';
import FileDatasetList from './FileDatasetList';
import LoadingIndicator from './LoadingIndicator';
import IconCheckmark from './images/IconCheckmark';

const tabs = {
    overview: {tab: 'overview', title: 'Overview'},
    description: {tab: 'description', title: 'Description'},
    data: {tab: 'data', title: 'Data'}
};

const lab_url = localStorage.getItem('lab_url');

/**
 * Panel: Challenge Detail
 *
 * @param isCreator
 * @param isSysop
 * @param basePath
 * @param projectItem
 * @param projectDataset
 * @param projectFiles
 * @param onAirmeetLink
 * @param onJoinFunc
 * @param inSubscribedChallenges
 * @param allowLab
 * @param tab
 * @return {*}
 * @constructor
 */
const ChallengeDetail = (
    {
        isCreator,
        isSysop,
        basePath = '',
        projectItem,
        projectDataset,
        projectFiles,
        onAirmeetLink,
        onJoinFunc,
        inSubscribedChallenges = false,
        allowLab = false,
        tab = 'overview'
    }) => {

    const [tabState, setTab] = useState({
        tab: tabs.overview.tab,
        title: tabs.overview.title
    });

    const [joining, setJoining] = useState(false);
    const [joinMessage, setJoinMessage] = useState({
        error: false,
        message: ''
    });
    const [labMessage, setLabMessage] = useState(false);

    // Set tab if an outside source requests it
    useEffect(() => {
        // @TODO first click from outside after internal tabState change fails
        // @TODO avoid checking/update internally
        if (tabs[tab] && (tabs[tab].tab)) {
            // console.log('useEffect check tab: current ' + tabState.tab + ', new: ', tab);
            // console.log('useEffect tabs[tab].tab: ', tabs[tab].tab);
            // changeTab(tabs[tab].tab, tabs[tab].title);
        }
    }, [tab]);

    const changeTab = (tab, title) => {
        setJoining(false);
        setJoinMessage({error: false, message: ''});
        setTab({tab: tab, title: title});
    };

    const joinChallenge = () => {
        setJoining(true);
        const request = makePostDataRequest(resources, 'ChallengeJoin', 'PUT', {id: projectItem.id});
        let errorMessage = 'Sorry, there was an issue joining this Challenge.';
        request.then(result => {
            setJoining(false);
            if (result && (result.sign_up_date || result === 'OK')) {
                setJoinMessage({error: false, message: 'You have successfully joined this Challenge.'});
                // Notify the parent page
                if (typeof onJoinFunc === 'function') {
                    onJoinFunc();
                }
                return Promise.resolve(result);

            } else if (result && result.data) {
                // console.log('Join Challenge error: ', result);
                let error = result.data.error;
                if (error && error.indexOf('no challenge found') !== -1) {
                    errorMessage = 'Sorry, no Challenge was found to join. Please try another Challenge.';
                }
                if (error && error.indexOf('already participating') !== -1) {
                    errorMessage = 'You are already participating in this Challenge.';
                }
                setJoinMessage({
                    error: true,
                    message: errorMessage
                });
                return Promise.resolve();

            } else {
                setJoinMessage({
                    error: true,
                    message: errorMessage
                });
                return Promise.resolve();
            }
        }).catch(error => {
            // console.log('Join Challenge Error: ', error);
            setJoining(false);
            setJoinMessage({
                error: true,
                message: errorMessage
            });
            return Promise.reject('Server Error');
        });
    };

    // Handle click from Session Join button
    const launchAirmeetEvent = useCallback((uuid) => {
        if (typeof onAirmeetLink === 'function') {
            onAirmeetLink('event', uuid);
        }
    }, [onAirmeetLink]);

    const displayLabMessaging = () => {
        setLabMessage(true);
    };

    const dateObj = (projectItem.due_date) ? new Date(projectItem.due_date.replace(/-/g, '/').replace(/T.+/, '')) : null;
    const dueStr = (dateObj) ? makeDateString(dateObj, 'm', false) : null;
    const discourseLink = (projectItem.discourse_id)
        ? localStorage.getItem('forum_url') + 'c/challenge/-/' + projectItem.discourse_id
        : null;
    const hasAirmeet = (!isBlank(projectItem.airmeet_id));

    return (
        <div className="panel no-padding">

            <h3 className="header">{tabState.title}</h3>

            <div className="xrathus-detail">

                <div className="item-menu">
                    <ul>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.overview.tab, tabs.overview.title)}>
                                Overview
                            </button>
                        </li>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.description.tab, tabs.description.title)}>
                                Description
                            </button>
                        </li>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.data.tab, tabs.data.title)}>
                                Data
                            </button>
                        </li>
                        <li className="divider">
                        </li>
                        {discourseLink &&
                        <li>
                            <a href={discourseLink} title="View Cluster" className="btn"
                               target="_blank" rel="noopener noreferrer">
                                View Cluster
                            </a>
                        </li>
                        }
                    </ul>
                </div>

                <div className="item-content">
                    {/* Tab: Overview */}
                    {tabState.tab === 'overview' &&
                    <>
                        <h4>Short Description</h4>
                        <ReactMarkdown source={projectItem.short_desc}/>

                        {projectItem.goals &&
                        <>
                            <h4>Goals</h4>
                            <ReactMarkdown source={projectItem.goals}/>
                        </>
                        }

                        {projectItem.reward &&
                        <>
                            <h4>Reward</h4>
                            <ReactMarkdown source={projectItem.reward}/>
                        </>
                        }

                        {dueStr &&
                        <>
                            <h4>Due Date</h4>
                            <p className="details">{dueStr}</p>
                        </>
                        }
                        {isSysop &&
                        <div className="row mb-2">
                            <span className="col col-xs-6 col-md-3">
                                <h4>Active</h4>
                                <p className="details">{projectItem.active ? 'Yes' : 'No'}</p>
                            </span>

                            <span className="col col-xs-6 col-md-3">
                                <h4>Published</h4>
                                <p className="details">{projectItem.published ? 'Yes' : 'No'}</p>
                            </span>

                            <span className="col col-xs-6 col-md-3">
                                <h4>Private</h4>
                                <p className="details">{projectItem.private ? 'Yes' : 'No'}</p>
                            </span>

                            <span className="col col-xs-6 col-md-3">
                                <h4>Complete</h4>
                                <p className="details">{projectItem.complete ? 'Yes' : 'No'}</p>
                            </span>
                        </div>
                        }
                    </>
                    }

                    {/* Tab: Description */}
                    {tabState.tab === 'description' &&
                    <>
                        <h4>Introduction</h4>
                        <ReactMarkdown source={projectItem.introduction}/>
                        <h4>Summary</h4>
                        <ReactMarkdown source={projectItem.summary}/>
                        <h4>Community Description</h4>
                        <ReactMarkdown source={projectItem.community_desc}/>
                    </>
                    }

                    {/* Tab: Data */}
                    {tabState.tab === 'data' &&
                    <>
                        {projectDataset &&
                        <>
                            <h4>Data Description</h4>
                            <ReactMarkdown source={projectDataset.description}/>
                        </>
                        }
                        {projectFiles && projectFiles.length > 0 &&
                        <>
                            <h4>Files ({projectFiles.length})</h4>
                            <FileDatasetList list={projectFiles}/>
                        </>
                        }
                    </>
                    }

                    <div className="form-btns right">
                        {joining &&
                        <p className="message">
                            <LoadingIndicator color="dark" centered={false} size={26} active={joining}/>
                        </p>
                        }
                        {!joinMessage.error && joinMessage.message !== '' &&
                        <p className="message">
                            <IconCheckmark/>
                        </p>
                        }
                        {joinMessage.message !== '' &&
                        <p className={`message${(joinMessage.error) ? ' error' : ''}`}>
                            {joinMessage.message}
                        </p>
                        }
                        {!inSubscribedChallenges &&
                        <button type="button" className="btn btn-primary" title="Join Challenge"
                                onClick={() => joinChallenge()}
                        >
                            Join Challenge
                        </button>
                        }
                        {isSysop &&
                        <Link className="btn btn-primary" to={`${basePath}/x/hub/challenge/${projectItem.id}/edit`}>
                            Edit Challenge
                        </Link>
                        }
                        {isSysop &&
                        <>
                            <Link className="btn btn-primary" to={`${basePath}/x/hub/challenge/dataset/${projectItem.data_id}/edit`}>
                                Edit Dataset
                            </Link>
                            <EditFilesButton
                                id={projectItem.id}
                                type="challenge"
                            />
                        </>
                        }
                        {(inSubscribedChallenges || isCreator) && hasAirmeet &&
                            <button type="button" className="btn btn-primary"
                                    title="Access Project Real Time Collaboration Tool"
                                    onClick={() => launchAirmeetEvent(projectItem.airmeet_id)}
                            >
                                Launch RTC
                            </button>
                        }
                        {allowLab && inSubscribedChallenges &&
                        <a href={lab_url} title="Launch Workspace" className="btn btn-primary" target="_blank"
                           rel="noopener noreferrer">
                            Launch Workspace
                        </a>
                        }
                        {!allowLab && inSubscribedChallenges &&
                        <button type="button" className="btn btn-primary" title="Launch Workspace"
                                onClick={() => displayLabMessaging()}
                        >
                            Launch Workspace
                        </button>
                        }
                    </div>
                    {labMessage &&
                    <div className="form-btns right">
                        <p className="message right">You need to be granted access to the Xrathus Lab.<br/>
                                                     Once status is granted, please logout and log back in to access.
                        </p>
                    </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default withRouter(ChallengeDetail);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';

import '../../../css/storyblok-custom.css';

const slugify = str =>
    str
        .toLowerCase()
        .trim()
    //.replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

const SectionHeader = ({
    title,
    style,
    hideTitle,
    onClick,
    toggleButtonText,
    //toggleCustomCss,
    toggleContent,
    customStyle,
    toggleCustomStyle,
}) => {

    const [collapsedContent, setCollapsedContent] = useState(true);

    let toggleContentClass = 'collapsible-content';

    const { ref, inView} = useInView({
        /* Optional options */
        threshold: 0,
    });

    let hideNewLine = false;
    let styleClass = "";
    let chosenHeaderNum = 1;
    switch (style) {
        case 'primary':
            styleClass = 'section-header divider';
            break;
        case 'secondary':
            styleClass = 'section-header full';
            break;
        case 'tertiary':
            styleClass = 'section-header tertiary';
            break;
        case 'quaternary':
            styleClass = 'subheader';
            chosenHeaderNum = 2;
            break;
        case 'underlined':
            styleClass = 'section-header';
            break;
        case 'panel':
            styleClass = 'header';
            break;
        case 'document':
        case 'document-primary':
            styleClass = 'document-header divider';
            if (hideTitle) styleClass = 'document-header';
            chosenHeaderNum = 3;
            break;
        case 'document-subheader':
        case 'document-secondary':
            styleClass = 'document-subheader';
            chosenHeaderNum = 4;
            break;
        case 'document-tertiary':
            styleClass = 'document-subheader';
            chosenHeaderNum = 5;
            break;
        case 'document-toggle':
        case 'document-primary-toggle':
            styleClass = 'header toggle full';
            hideNewLine = true;
            break;
        case 'document-secondary-toggle':
            styleClass = 'header toggle full';
            chosenHeaderNum = 4;
            hideNewLine = true;
            break;
        case 'document-tertiary-toggle':
            styleClass = 'header toggle full';
            chosenHeaderNum = 5;
            hideNewLine = true;
            break;
        default:
            break;
    }

    const slugTitle = slugify(title);

    const displayedTitleText = hideTitle ? null : title;

    if (!customStyle) {
        customStyle="{}";
    }
    if (!toggleCustomStyle) {
        toggleCustomStyle="{}";
    }

    switch (chosenHeaderNum) {
        case 1:
            if (!toggleContent) {
                return (
                    <div>
                        <h3 className={`${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h3>
                    </div>
                );
            }
            else {
                return (
                    <>
                        <h3 className={`alignleft ${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h3>
                        <h4 className={`alignright header toggle full`} 
                            onClick={() => {setCollapsedContent(!collapsedContent);}} 
                            style={JSON.parse(toggleCustomStyle)}>
                            {toggleButtonText}</h4>
                        <div className={toggleContentClass + (collapsedContent ? "" : " expanded") }>
                            {parseStoryblokContent(toggleContent, "", "", [])}
                        </div>
                        <div style={{clear: "both"}}></div>
                    </>
                );
            }
        case 2:
            if (!toggleContent) {
                return (
                    <div>
                        <h4 className={`${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h4>
                    </div>
                );
            }
            else {
                return (
                    <>
                        <h4 className={`alignleft ${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h4>
                        <h4 className={`alignright header toggle full`} 
                            onClick={() => {setCollapsedContent(!collapsedContent);}}
                            style={JSON.parse(toggleCustomStyle)}>
                            {toggleButtonText}</h4>
                        <div className={toggleContentClass + (collapsedContent ? "" : " expanded") }>
                            {parseStoryblokContent(toggleContent, "", "", [])}
                        </div>
                        <div style={{clear: "both"}}></div>
                    </>
                );
            }
        case 4:
            if (!toggleContent || (toggleContent.length === 0)) {
                return (
                    <div>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <h4 className={`${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h4>
                    </div>
                );
            }
            else {
                return (
                    <>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <h3 className={`alignleft document-header`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h3>
                        <h4 className={`alignright`} 
                            onClick={() => {setCollapsedContent(!collapsedContent);}}
                            style={JSON.parse(toggleCustomStyle)}>
                            {toggleButtonText}</h4>
                        <div style={{clear: "both"}}></div>
                        <div className={toggleContentClass + (collapsedContent ? "" : " expanded") }>
                            {parseStoryblokContent(toggleContent, "", "", [])}
                        </div>
                    </>
                );
            }
        case 5:
            if (!toggleContent || (toggleContent.length === 0)) {
                return (
                    <div>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <h5 className={`${styleClass}`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h5>
                    </div>
                );
            }
            else {
                return (
                    <>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <h5 className={`alignleft document-header`} onClick={onClick} style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h5>
                        <h5 className={`alignright`} 
                            onClick={() => {setCollapsedContent(!collapsedContent);}}
                            style={JSON.parse(toggleCustomStyle)}>
                            {toggleButtonText}</h5>
                        <div style={{clear: "both"}}></div>
                        <div className={toggleContentClass + (collapsedContent ? "" : " expanded") }>
                            {parseStoryblokContent(toggleContent, "", "", [])}
                        </div>
                    </>
                );
            }
        case 3:
        default:
            if (!toggleContent || (toggleContent.length === 0)) {
                return (
                    <div>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <h3 className={`${styleClass}`} onClick={onClick}  style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h3>
                    </div>
                );
            }
            else {
                return (
                    <>
                        {hideNewLine ? null : <br id={slugTitle} className={`${inView ? "active" : ""}`} ref={ref}/>}
                        <hr/>
                        <h3 className={`alignleft document-header`} onClick={onClick}  style={JSON.parse(customStyle)}>
                            {displayedTitleText}</h3>
                        <h4 className={`alignright`} 
                            onClick={() => {setCollapsedContent(!collapsedContent);}}
                            style={JSON.parse(toggleCustomStyle)}>
                            {toggleButtonText}</h4>
                        <div style={{clear: "both"}}></div>
                        <div className={toggleContentClass + (collapsedContent ? "" : " expanded") }>
                            {parseStoryblokContent(toggleContent, "", "", [])}
                        </div>
                    </>
                );
            }
    }
};


SectionHeader.propTypes = {
    /**
     * What is the displayed title?
     */
    title: PropTypes.string,
    /**
     * Required style
     */
    style: PropTypes.oneOf([
        'primary', 
        'secondary', 
        'tertiary', 
        'quaternary',
        'underlined', 
        'panel', 
        //deprecated
        'document', 
        //deprecated
        'document-subheader',
        'document-primary',
        'document-secondary',
        'document-tertiary',
        'document-toggle',
        'document-primary-toggle',
        'document-secondary-toggle',
        'document-tertiary-toggle',
    ]),
    hideTitle: PropTypes.bool,
    onClick: PropTypes.any,
    toggleButtonText: PropTypes.string,
    toggleCustomCss: PropTypes.string,
    toggleContent: PropTypes.any,
    customStyle: PropTypes.string,
    toggleCustomStyle: PropTypes.string,
};

SectionHeader.defaultProps = {
    title: "",
    style: 'primary',
    hideTitle: false,
    onClick: ()=>null,
    toggleButtonText: "",
    toggleCustomCss: "",
    toggleContent: null,
    customStyle: "{}",
    toggleCustomStyle: "{}",
};

export default SectionHeader;

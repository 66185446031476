import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {setEventConfigUserRefresh, setEventListRefresh} from '../../store/actions';
import {openHelpWidget} from '../../utils/pageFunctions';
import {
    getEventPermissions,
    populatePersonalEventUserConfig
} from '../../utils/projectFunctions';
import FormEventAdd from '../../partials/forms/FormEventAdd';
import '../../css/challenge.css';

/**
 * Page: Create Event form
 */
class XConnectEventAdd extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        const eventId = Number(pathArr.pop());

        this.state = {
            event: null,
            eventId: eventId,
            formStage: 'event'
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        if (e === 'event' && status === 'complete') {
            this.setState({
                eventId: data.id
            }, () => {
                // Refresh User Configuration and Events
                this.props.setEventConfigUserRefresh(true);
                this.props.setEventListRefresh('personal_own');
            })
        }
    };

    // Set form toggling
    updateFormStage = (stage) => {
        this.setState({
            formStage: stage
        });
    };

    // Populate Event variables and text for Add Event
    setEventLimits = (ownEvents, userConfig) => {
        const created = ownEvents?.length ?? 0;
        const createdStr = (created > 0) ? created : 'no';
        const max = userConfig?.max_events ?? 0;
        let allowed = max - created;
        let eventsStr = (created === 1) ? 'Event' : 'Events';
        if (allowed < 0) {
            allowed = 0;
        }
        const textHave = ((created < max) || allowed > 0)
            ? 'You have created ' + createdStr + ' Personal ' + eventsStr + '. You may create ' + (allowed) + ' more.'
            : 'You have reached the maximum number of allotted Personal Events: ' + max + '. ';
        return {
            events_created: created,
            events_max: max,
            events_allowed: allowed,
            text: textHave
        };
    };

    render() {
        const {
            event_config,
            event_config_user,
            event_list_own,
            user
        } = this.props;

        const {
            eventId,
            formStage
        } = this.state;

        const userConfig = populatePersonalEventUserConfig(event_config, event_config_user);
        const eventLimits = this.setEventLimits(event_list_own, userConfig);
        const eventPermissions = getEventPermissions(user, event_list_own, eventId);

        // If User cannot create Events due to having reached a limit:
        if (!eventPermissions.sysop && formStage === 'event' && eventLimits.events_allowed <= 0) {
            return (
                <>
                    <div className="content xhub event">
                        <h1 className="page-title">
                            Personal Events
                        </h1>
                        <p>
                            {eventLimits.text}
                        </p>
                        {((eventLimits.events_created >= eventLimits.events_max) || eventLimits.events_allowed === 0) &&
                        <p>
                            To create more, please
                            contact <button className="link text" title="Support"
                                            onClick={() => openHelpWidget()}>Xrathus Support</button>.
                        </p>
                        }
                    </div>
                </>
            )
        }

        // Display Add Event form
        return (
            <>
                <div className="content xhub event">
                    <h1 className="page-title">
                        Create a Personal Event
                    </h1>
                    {!eventPermissions.sysop &&
                    <p>
                        {eventLimits.text}
                    </p>
                    }

                    <div className="panel no-padding">
                        <h3 className="header">Personal Event</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormEventAdd
                                        formStage={formStage}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        event_config: state.event_config,
        event_config_current: state.event_config_current,
        event_config_user: state.event_config_user,
        event_list_own: state.event_list_own,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

const mapDispatchToProps = {
    setEventConfigUserRefresh: setEventConfigUserRefresh,
    setEventListRefresh: setEventListRefresh
};

export default withKeycloak(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XConnectEventAdd)
);

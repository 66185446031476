import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeDateString, sortArrayByKey} from '../utils/dataFunctions';
import APIAirmeetSessions from '../utils/APIAirmeetSessions';
import TableWithSubrows from './tables/TableWithSubrows';
import ChevronDown from '../css/images/icon-Table-Down-Chevron.svg';
import ChevronUp from '../css/images/icon-Table-Up-Chevron.svg';

/**
 * Component that displays a Challenge's, Project's, or Event's active Airmeet Sessions in a React Table
 *
 * @param airmeetView
 * @param itemId
 * @param onList
 * @param onAirmeetLink
 * @param inPanel
 * @param refresh
 * @return {JSX.Element}
 * @constructor
 */
const AirmeetSessionsTable = ({
                                         airmeetView,
                                         itemId,
                                         onList,
                                         onAirmeetLink,
                                         inPanel = true,
                                         refresh = false
                                     }) => {

    const [hasLinks, setHasLinks] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [refreshList, setRefreshList] = useState(refresh);

    const joinBtn = (airmeetView === 'owner') ? 'Join Session' : 'Launch RTC';

    useEffect(
        () => {
            return setRefreshList(refresh);
        }, [refresh]
    );

    // Set list of Airmeet Sessions for current Challenge, Project, or Event
    const setAirmeetSessions = (data) => {
        let sorted = [];
        let entryLinks = 0;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let session = data[i];
                if (!session.title || session.title === '') {
                    session.title = '(No Title)';
                }
                session.start_date_str = (session.start_date) ? makeDateString(session.start_date, 'm', true) : '';
                session.duration_str = (session.duration > 0) ? (session.duration === 1) ? '1 minute ' : session.duration + ' minutes ' : '';
                if (session.entry_link && session.entry_link !== '') {
                    entryLinks++;
                }
                sorted.push(session);
            }
            sorted = sortArrayByKey(sorted, 'start_date', 'ASC');
            setItemList(sorted);
            if (entryLinks > 0) {
                setHasLinks(true);
            }
            if (typeof onList === 'function') {
                onList(sorted);
            }
        }
        setRefreshList(false);
    };

    // Handle click from Session Join button
    const launchAirmeetSession = useCallback((uuid) => {
        if (typeof onAirmeetLink === 'function') {
            onAirmeetLink('session', uuid);
        }
    }, [onAirmeetLink]);

    // Display subrow with Airmeet Summary
    const renderSubrow = useCallback(
        ({row}) => (
            <p className="margin-1">
                {row.original.summary}
            </p>
        ),
        []
    );

    // Set table data
    const tableData = useMemo(() => itemList, [itemList]);

    // Set table columns
    const tableColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Session Title',
                id: 'session_title',
                Cell: ({row}) => (
                    <>
                        <span>{row.original.title}</span>
                        {row.original.summary !== '' &&
                            <span
                                {...row.getToggleRowExpandedProps()}
                                className="btn-icon x-table-icon right ml-1"
                            >
                                {row.isExpanded
                                    ? <img src={ChevronUp} alt="Close"/>
                                    : <img src={ChevronDown} alt="View Summary"/>}

                            </span>
                        }
                    </>
                ),
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Starts',
                id: 'start_date_str',
                Cell: ({row}) => {
                    return (row.original.start_date)
                        ? <span>{row.original.start_date_str}</span>
                        : <span>N/A</span>
                },
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Duration',
                id: 'duration',
                Cell: ({row}) => {
                    return (row.original.duration_str)
                        ? <span>{row.original.duration_str}</span>
                        : <span>N/A</span>
                },
                disableFilters: true,
                disableSortBy: true
            }
        ];
        // Only joined Users and (Project/Event) Owners may launch Sessions
        if (hasLinks) {
            columns.push({
                Header: '',
                id: 'entry_link',
                Cell: ({row}) => (
                    <button
                        type="button"
                        className="btn btn-primary table"
                        title={joinBtn}
                        disabled={(row.original.entry_link === '')}
                        onClick={(row.original.entry_link !== '')
                            ? () => launchAirmeetSession(row.original.session_id)
                            : undefined}
                    >
                        {joinBtn}
                    </button>
                ),
                disableFilters: true,
                disableSortBy: true
            });
        }

        return columns;
    }, [hasLinks, launchAirmeetSession, joinBtn]);

    return (
        <>
            {inPanel && itemList.length > 0 &&
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel no-padding">
                            <h3 className="header">Real Time Collaboration Sessions</h3>
                            <TableWithSubrows
                                columns={tableColumns}
                                data={tableData}
                                subrowComponent={renderSubrow}
                            />
                        </div>
                    </div>
                </div>
            }
            {!inPanel && itemList.length > 0 &&
                <TableWithSubrows
                    columns={tableColumns}
                    data={tableData}
                    subrowComponent={renderSubrow}
                />
            }

            <APIAirmeetSessions
                id={itemId}
                onResult={(e) => setAirmeetSessions(e)}
                refresh={refreshList}
            />
        </>
    );
};

export default AirmeetSessionsTable;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FileDatasetListItem from './FileDatasetListItem';

class FileDatasetList extends Component {

    setFileItems = () => {
        const list = this.props.list;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Files Uploaded</span>
            )
        }

        return (
            list.map((item, index) => (
                <FileDatasetListItem
                    key={`file-${item.object}-${index}`}
                    item={item}
                />
            ))
        )
    };

    render() {
        return (
            <div className="file-list">
                {this.props.list.length > 0 &&
                <>
                    {[...this.setFileItems()]}
                </>
                }
            </div>
        )
    }
}

FileDatasetList.propTypes = {
    list: PropTypes.array
};

FileDatasetList.defaultProps = {
    list: []
};

export default FileDatasetList;

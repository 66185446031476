import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import DocumentIcon from '../../css/images/icon-Table-Document.svg';

const DocumentButton = ({ basePath = '', record = {}, type, label = 'Edit Dataset' }) => {
    return (
        <Link 
            className="btn-icon x-table-icon" 
            title={label}
            to={`${basePath}/${type}/${record.cell.row.original.data_id}/edit`}
        >
            <img src={DocumentIcon} alt="Document Table Icon"/>
        </Link>
    );
};

DocumentButton.propTypes = {
    /**
     * Currently not used
     */
    basePath: PropTypes.string,
    /**
     * The Link to the data repo in which the file is to be found
     */
    type: PropTypes.string,
    /**
     * The record from which the filepath for editing is extracted. (record.cell.row.original.data_id)
     */
    record: PropTypes.object,
    /**
     * The label displayed in browser tooltips
     */
    label: PropTypes.string,
};

export default DocumentButton;
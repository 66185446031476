import React from 'react';


class CalendarWeekylDayItem extends React.Component {

    render() {
        return (
            <div className="dayCell" role="presentation">
              &nbsp;
            </div>);
    }
}

export default CalendarWeekylDayItem;
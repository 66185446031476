import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import LoadingIndicator from '../../LoadingIndicator';
import {useStoryblokAssetFetch} from '../../../utils/StoryblokFunctions';


import '../../../css/storyblok-custom.css';


const StoryblokAssetFetcher = ({
    assetType,
    link,
    alt,
    customCss,
    height,
    width,
    buttonLink,
    isInternal,
}) => {

    //fetch url, if any, from storage
    const usedUrl = localStorage.getItem(`fetchedAsset/${link}`);

    const [data, isLoading] = useStoryblokAssetFetch(link);

    if (!link || link === "") return null;

    let loadingSize=24;

    switch (assetType) {
        case 'iframe':
            loadingSize=60;
            height="800px";
            width="100%";
            break;
        case 'image':
            loadingSize=24;
            break;
        default:
            loadingSize=24;
            break;
    }
    let imgElement = null;
    if (isLoading) {
        //check if url exists
        if (usedUrl) {
            switch (assetType) {
                case 'iframe':
                    return (
                        <div className="lab-container">
                            <Iframe url={usedUrl} id="document" display="flex" width={width} height={height}/>
                        </div>
                    );
                case 'image':
                    if (buttonLink && buttonLink!=="") {
                        imgElement = <img src={usedUrl} alt={alt}
                            className={"storyblok-image center"} 
                            style={{maxHeight: height+'px', maxWidth: width+'px',}}/>;
                        if (isInternal) {
                            return (
                                <Link to={buttonLink}  title={alt ? alt : "Image"}  
                                    className={"btn btn-image "+customCss}>
                                    {imgElement}
                                    {alt}
                                </Link>
                            );
                        }
                        else {
                            return (
                                <a href={buttonLink}
                                    title={alt ? alt : "Image"}
                                    target="_blank" rel="noopener noreferrer"
                                    className={"btn btn-image "+customCss}
                                >
                                    {imgElement}
                                    {alt}
                                </a>
                            );
                        }
                    }
                    else {
                        return (
                            <img src={usedUrl} 
                                className={"storyblok-image center "+customCss} 
                                alt={alt} style={{maxHeight: height+'px', maxWidth: width+'px',}}>
                            </img>
                        );
                    }
                default:
                    return (
                        <div className="lab-container">
                            <Iframe url={usedUrl} id="document" display="flex" width={width} height={height}/>
                        </div>
                    );
            }
        }
        else {
            return <LoadingIndicator color="dark" centered={true} size={loadingSize} active={isLoading}/>;
        }
    }
    //save fetch time to storage
    localStorage.setItem(`fetchedAsset/${link}/fetchTime`, `${new Date().getTime()}`);


    let fetchedUrl = null;

    if (data.asset && data.asset.signed_url) {
        fetchedUrl = data.asset.signed_url;
    }

    if (fetchedUrl) {
        if (!usedUrl || (usedUrl !== fetchedUrl)) {
            //save signed url to storage
            localStorage.setItem(`fetchedAsset/${link}`, fetchedUrl);
        }
        imgElement = null;
        switch (assetType) {
            case 'iframe':
                return (
                    <div className="lab-container">
                        <Iframe url={fetchedUrl} id="document" display="flex" width={width} height={height}/>
                    </div>
                );
            case 'image':
                if (buttonLink && buttonLink!=="") {
                    imgElement = <img src={fetchedUrl} 
                        className={"storyblok-image center"} 
                        alt={alt ? alt : ""} style={{maxHeight: height+'px', maxWidth: width+'px',}}/>;
                    if (isInternal) {
                        return (
                            <Link to={buttonLink}  title={alt ? alt : "Image"}  
                                className={"btn btn-image "+customCss}>
                                {imgElement}
                                {alt}
                            </Link>
                        );
                    }
                    else {
                        return (
                            <a href={buttonLink}
                                title={alt ? alt : "Image"}
                                target="_blank" rel="noopener noreferrer"
                                className={"btn btn-image "+customCss}
                            >
                                {imgElement}
                                {alt}
                            </a>
                        );
                    }
                }
                else {
                    return (
                        <img src={fetchedUrl} 
                            className={"storyblok-image center "+customCss} 
                            alt={alt} style={{maxHeight: height+'px', maxWidth: width+'px',}}>
                        </img>
                    );
                }
            default:
                return (
                    <div className="lab-container">
                        <Iframe url={data.asset.signed_url} id="document" display="flex" width={width} height={height}/>
                    </div>
                );
        }
    }
    else {
        return null;
    }
};


StoryblokAssetFetcher.propTypes = {
    /**
     * What asset is this?
     */
    assetType: PropTypes.oneOf([
        'iframe', 
        'image']),
    /**
     * The original asset link
     */
    link: PropTypes.string,
    alt: PropTypes.string,
    customCss: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    /**
     * A button link to another webpage
     */
    buttonLink: PropTypes.string,
    isInternal: PropTypes.bool,
};

StoryblokAssetFetcher.defaultProps = {
    assetType: 'iframe',
    link: "",
    alt: "",
    customCss: "",
    height: "10px",
    width: "10px",
    buttonLink: "",
    isInternal: false,
};

export default StoryblokAssetFetcher;

export const XRATHUS_CAREERS = {
    'position-partnership-manager': {
        order: 1,
        title: 'Partnership Manager',
        label: 'Help us build our community of value-adding Collaborators.',
        link_email: 'info@xrathus.com',
        link: ''
    },
    'position-business-development': {
        order: 2,
        title: 'Business Development',
        label: 'Show the world what Xrathus can do and help grow our user community.',
        link_email: 'info@xrathus.com',
        link: ''
    }
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setProjectId} from '../../store/actions';
import SubstrateGreeting from '../../partials/SubstrateGreeting';

class XEventsStatic extends Component {
    componentDidMount() {
        this.props.setProjectId(null);
    }

    render() {

        // When Event objects are implemented, rely on totals to display
        const displayEvents = false;

        return (
            <>
                <div className="content xconnect">
                    <h1 className="page-title">
                        Xrathus Events
                    </h1>
                    <SubstrateGreeting substrate="Events"/>

                    <div className="row mt-2">
                        <div className="col col-xs-12">
                            <h3 className="section-header full">Join our Community Events and Programs</h3>
                        </div>
                    </div>
                    <div className="row">
                        {displayEvents &&
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                {/* Place Event objects or components in columns */}
                            </div>
                        }
                        {!displayEvents &&
                            <div className="col col-xs-12">
                                There are no upcoming events at this time.
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    setProjectId: setProjectId
};

XEventsStatic.propTypes = {
    setProjectId: PropTypes.any,
};

export default connect(
    null,
    mapDispatchToProps
)(XEventsStatic);

import React from 'react';
import PropTypes from 'prop-types';
import {Link, BrowserRouter} from 'react-router-dom';

import '../../../css/storyblok-custom.css';


const Button = ({
    title,
    isExternal,
    link,
    customClass,
    demoRouter,
}) => {

    if (isExternal) {
        return (
            <div className={"btn-storyblok "+customClass}>
                <a href={link}
                    title={title} target="_blank" rel="noopener noreferrer"
                    className="btn btn-primary btn-image-text nowrap"
                >
                    {title}
                </a>
            </div>
        );
    }
    else {
        if (demoRouter) {
            return (
                <div className={"btn-storyblok "+customClass}>
                    <BrowserRouter>
                        <Link to={link}  title={title}  className="btn btn-primary btn-image-text nowrap">{title}</Link>
                    </BrowserRouter>
                </div>
            );
        }
        else {
            return (
                <div className={"btn-storyblok "+customClass}>
                    <Link to={link}  title={title}  className="btn btn-primary btn-image-text nowrap">{title}</Link>
                </div>
            );
        }
    }
};


Button.propTypes = {
    /**
     * What label is on the button?
     */
    title: PropTypes.string,
    /**
     * Is this an external link or internal?
     */
    isExternal: PropTypes.bool,
    /**
     * The link used. Should start with '/x/' if it is not isExternal.
     */
    link: PropTypes.string,
    customClass: PropTypes.string,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
};

Button.defaultProps = {
    title: "",
    isExternal: true,
    link: "",
    customClass: "",
    demoRouter: false,
};

export default Button;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ValueImage from '../css/images/Home-Value-Bichronous.jpg';

class HomeValueSectionBichronous extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Bichronous Collaboration'
            });
        }
    };

    render() {
        return (
            <>
                <div id="bichronous" className="row">
                    <div className="col col-xs-12">
                        <div className="panel center-content row home-value bichronous">
                            <div className="col col-xs-12 col-sm-4 vertical-center">
                                <img className="value-image" src={ValueImage} alt="Bichronous"/>
                            </div>
                            <div className="col col-xs-12 col-sm-8">
                                <h2>
                                    Harness the power of working bichronously.
                                </h2>
                                <p>
                                    Get ready to learn your new favorite word!
                                </p>
                                <p className="primary strong">
                                    Bichronous = synchronous + asynchronous
                                </p>
                                <p>
                                    The Xrathus platform is built for all modes of work, including
                                    on-demand real-time collaboration, so your project team members
                                    can switch effortlessly between working in sync and working
                                    independently, all within the same dedicated space.
                                </p>

                                <button
                                    type="button"
                                    className="btn btn-training btn-arrow uppercase table pull-right mt-1 mb-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Learn More >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

HomeValueSectionBichronous.propTypes = {
    onClickFunc: PropTypes.func
};

export default HomeValueSectionBichronous;

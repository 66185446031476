const config = {
    version: '1.22.9',
    language: 'en-US',
    fileParams: {
        FPO: 'audio/*,video/*,image/*',
        accept: '.csv',
        acceptExtension: 'csv',
        acceptName: 'CSV',
        text_error_token: 'Your token has expired. Please log in.'
    },
    fileParamsChallenge: {
        fileLimit: 0,
        maxFileSize: 5000000000,
        maxFileSizeName: '5 GB',
        minFileSize: 1,
        minFileSizeName: '10 bytes',
        text_error_file: 'Sorry, there was an error uploading this file.',
        text_error_max_size: 'Your file must be under 5 GB in size.'
    },
    fileParamsProject: {
        fileLimit: 0,
        maxFileSize: 1000000000,
        maxFileSizeName: '1 GB',
        minFileSize: 1,
        minFileSizeName: '10 bytes',
        text_error_file: 'Sorry, there was an error uploading this file.',
        text_error_max_size: 'Your file must be under 1 GB in size.'
    },
    listStatus: {
        challenge_subscribed: false,
        project_invited: false,
        project_own: false,
        project_subscribed: false
    },
    pageSize: {
        mobile: 960,
        small: 680
    },
    eventConfigCurrent: {
        max_events: null,
        max_shares: null
    },
    eventConfigDefault: {
        max_events: 1,
        max_shares: 1,
        events: 1
    },
    eventConfigMin: {
        max_events: 0,
        max_shares: 1,
        events: 1
    },
    eventConfigUser: {
        max_events: null,
        max_shares: null,
        events: null
    },
    projectConfigCurrent: {
        max_dataset_size: null,
        max_shares: null,
        max_volumes: null
    },
    projectConfigDefault: {
        max_dataset_size: 1,
        max_shares: 5,
        max_volumes: 3,
        projects: 3
    },
    projectConfigMin: {
        max_dataset_size: 1,
        max_shares: 0,
        max_volumes: 0,
        projects: 0
    },
    projectConfigUser: {
        max_dataset_size: null,
        max_shares: null,
        max_volumes: null,
        projects: null
    },
    redirectUponLogin: '/x/connect/my-xrathus',
    routesFullWidth: [
        '/x/about',
        '/x/getting-started',
        '/x/privacy-policy',
        '/x/substrates',
        '/x/terms',
        '/x/use-cases'
    ],
    routesSubstrate: [
        'connect',
        'hub',
        'marketplace',
        'training'
    ],
    routesSysop: [
        '/x/admin',
        '/x/connect/challenges/add',
        '/x/connect/challenges',
        '/x/organization/add'
    ],
    tokensDefault: {
        idToken: null,
        token: null,
        refreshToken: null
    },
    urls: {
        api_url: '',
        forum_url: '',
        gitlab_url: '',
        lab_url: ''
    },
    user: {
        email: '',
        events: null,
        family_name: '',
        gitlab_user_name: '',
        given_name: '',
        groups: [],
        is_sysop: false,
        max_dataset_size: null,
        max_event_shares: null,
        max_events: null,
        max_shares: null,
        max_volumes: null,
        name: '',
        persona: {
            id: null,
            name: null,
            plural: null
        },
        preferred_username: '',
        projects: null
    }
};

export default config;

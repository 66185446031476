import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../../utils/eventEmitter';
import {filterListByValue, sortListByValue} from '../../utils/filterFunctions';
import {setChallengesList} from '../../utils/projectFunctions';
import APIChallenges from '../../utils/APIChallenges';
import ChallengeAddModalContent from '../../partials/modal/ChallengeAddModalContent';
import Modal from '../../partials/modal/Modal';
import {Select, Header, Input} from "../../partials/filters/ChallengeFilterComponents";
import '../../css/challenge.css';
import '../../css/search-filter.css';

/**
 * Set current list type based on Challenge route
 *
 * @param path
 * @returns {string}
 */
const setListType = (path) => {
    if (path.indexOf('challenges/list/completed') !== -1) {
        return 'challenges-completed';
    } else if (path.indexOf('challenges/list/popular') !== -1) {
        return 'challenges-popular';
    } else if (path.indexOf('challenges/list/trending') !== -1) {
        return 'challenges-popular';
    } else if (
        path.indexOf('challenges') !== -1 ||
        path.indexOf('challenges/list') !== -1 ||
        path.indexOf('challenges/list/all') !== -1
    ) {
        return 'challenges-all';
    }
    return 'challenges-all';
};

/**
 * Set page title based on Challenge list type
 *
 * @param listType
 * @return {string}
 */
const setChallengePageTitle = (listType) => {
    if (listType === 'challenges-completed') {
        return 'Completed Challenges';
    } else if (listType === 'challenges-popular' || listType === 'challenges-trending') {
        return 'Popular Challenges';
    } else if (listType === 'challenges-all') {
        return 'All Challenges';
    }
    return 'Challenges';
};

const sortTypes = {
    latest: 'created_on',
    popular: 'created_on',
    alphabetically: 'title',
}

/**
 * Page: Challenges List (All, Popular, Trending, Completed)
 */
class XConnectChallenges extends Component {
    constructor(props) {
        super(props);

        const listType = setListType(props.location.pathname);
        const pageTitle = setChallengePageTitle(listType);
        const publicChallenges = (props.challenge_list_public.length > 0) ? props.challenge_list_public : [];
        this.state = {
            filteredList: [],
            listTotals: {
                challengePublic: 0,
                challengeUser: 0
            },
            listType: listType,
            mainList: publicChallenges,
            modalChallengeOpen: false,
            pageTitle: pageTitle,
            params: {
                active: true
            },
            pathname: '',
            refreshChallenges: false,
            resetList: true,
            search: {
                value: ''
            },
            sort: {
                value: 'latest'
            }
        };
    }

    componentDidMount() {
        Emitter.on('addChallenge', () => this.setChallengeModal(true));

        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Update state on location change
        if (nextProps.location.pathname !== prevState.pathname) {
            const listType = setListType(nextProps.location.pathname);
            const pageTitle = setChallengePageTitle(listType);
            if (document.getElementById('searchHeader')) {
                document.getElementById('searchHeader').reset();
            }

            return {
                filteredList: [],
                listType: listType,
                pageTitle: pageTitle,
                pathname: nextProps.location.pathname,
                resetList: true,
                search: {
                    value: ''
                },
                sort: {
                    value: 'latest'
                }
            };
        }

        // Reset current list if stored totals have changed or populated
        if (prevState.listTotals.challengePublic !== nextProps.challenge_list_public.length) {
            return {
                listTotals: {
                    challengePublic: nextProps.challenge_list_public.length,
                    challengeUser: 0
                },
                resetList: true,
            }
        }

        return null;
    }

    componentWillUnmount() {
        Emitter.off('addChallenge');
    }

    setChallengeModal = (isOpen) => {
        this.setState({
            modalChallengeOpen: isOpen
        });
    };

    // Handle event from Filter By Title
    setSearch = (value) => {
        this.setState({
            search: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    // Handle event from Sort By filter
    setSort = (value) => {
        this.setState({
            sort: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    setReset = (value) => {
        this.setState({
            resetList: value
        }, () => {
            this.filterAndSortList();
        });
    }

    setMainList = () => {
        this.setState({
            mainList: this.props.challenge_list_public
        }, () => {
            this.filterAndSortList();
        })
    }

    filterAndSortList = () => {
        let tempList = JSON.parse(JSON.stringify(this.state.mainList));
        if (!tempList || tempList.length === 0) {
            return;
        }

        // Apply Text Filter
        tempList = filterListByValue(tempList, this.state.search?.value?.toLowerCase(), ['title', 'summary']);

        // Apply sorting
        tempList = sortListByValue(tempList, this.state.sort?.value, sortTypes);

        this.setState({
            filteredList: tempList
        })
    }

    render() {
        const {
            challenge_list_subscribed
        } = this.props;

        const {
            filteredList,
            listType,
            modalChallengeOpen,
            pageTitle,
            refreshChallenges,
            search,
            sort
        } = this.state;

        return (
            <>
                <div className="content xconnect">
                    <h1 className="page-title">
                        {pageTitle}
                    </h1>
                    <form id="searchHeader">
                        <Header>
                            <Input
                                placeholder="Filter by Challenge Title"
                                onKeyUp={e => this.setSearch(e.target.value)}
                                className="control-search"
                            />
                            <Select
                                values={["Latest"]}
                                onChange={e => this.setSort(e.target.value)}
                                className="control-sort"
                            />
                            <div className="control-label">
                                Sort By:
                            </div>
                        </Header>
                    </form>

                    {/* Row: Public Challenges */}
                    {(listType === 'challenges-all' || listType === 'challenges-completed') &&
                    <div className="row">
                        {[...setChallengesList(filteredList, challenge_list_subscribed, 'No Challenges to display.')]}
                    </div>
                    }

                </div>

                <Modal
                    id="modal-add-challenge"
                    show={this.state.modalChallengeOpen}
                    onClose={() => this.setChallengeModal(false)}
                    closeButtonOnly={true}
                    header={true}
                    classes="no-padding"
                    style={{maxWidth: '90%', minHeight: 200}}
                >
                    {modalChallengeOpen &&
                    <ChallengeAddModalContent/>
                    }
                </Modal>

                <APIChallenges
                    getType="list"
                    viewType="public"
                    params={{
                        order: 'ASC',
                        page: 1,
                        perPage: 10000,
                        search: (search.value !== '') ? search.value : '',
                        sort: 'ASC',
                        sortBy: (sort.value === 'alphabetically') ? 'title' : 'created_on'
                    }}
                    refresh={refreshChallenges}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_public: state.challenge_list_public,
        challenge_list_subscribed: state.challenge_list_subscribed
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(XConnectChallenges)
);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Emitter from './eventEmitter';
import {RESOURCES as resourcesUser} from '../resourcesUser';
import {makeGetDataRequest} from './getRequest';
import {setEventConfigUser, setEventConfigUserRefresh, setProjectConfigUser, setProjectConfigUserRefresh, setUser} from '../store/actions';
import Config from '../config';

/**
 * Component for retrieving User data
 */
class APIUser extends Component {

    componentDidMount() {
        if (this.props.user.preferred_username !== '') {
            this.checkUserInfo();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Get User Info/Config if the username has changed or if the Config needs to refresh
        const username = this.getUsername();
        const userRefresh = this.props.project_config_user_refresh;
        if (
            (username !== '' && prevProps.user.preferred_username !== username) ||
            (userRefresh && userRefresh !== prevProps.project_config_user_refresh)
        ) {
            this.checkUserInfo();
        }
    }

    getUsername = () => {
        return this.props.user.preferred_username;
    };

    /**
     * Process user info and handle rechecking
     *
     * @param result
     */
    processUserInfo = (result) => {
        if (result.error) {
            // No user info exists; set form to select Persona and initialize the user
            Emitter.emit('selectPersona', 'init');
        } else if (result.username && !result.primary_persona) {
            // User exists but has not selected a Primary Persona; set form to select Persona
            Emitter.emit('selectPersona', 'persona');
        } else if (result.primary_persona) {
            // Primary Persona exists in data; store it
            Emitter.emit('loadedPersona', result.primary_persona);
        }

        // Update User data with additional parameters
        const gitlab_user_name = result?.gitlab_user_name ?? '';
        if (gitlab_user_name !== '') {
            const userObj = JSON.parse(JSON.stringify(this.props.user));
            userObj.gitlab_user_name = gitlab_user_name;
            this.props.setUser(userObj);
        }
        // Store Project Configurations for this User
        let userConfig = JSON.parse(JSON.stringify(Config.projectConfigUser));
        if (result.max_dataset_size) {
            userConfig.max_dataset_size = result.max_dataset_size;
        }
        if (result.max_event_shares) {
            userConfig.max_event_shares = result.max_event_shares;
        }
        if (result.max_events) {
            userConfig.max_events = result.max_events;
        }
        if (result.max_shares) {
            userConfig.max_shares = result.max_shares;
        }
        if (result.max_volumes) {
            userConfig.max_volumes = result.max_volumes;
        }
        if (result.projects) {
            userConfig.projects = result.projects;
        }

            this.props.setProjectConfigUser(userConfig);
            this.props.setProjectConfigUserRefresh(false);
            this.props.setEventConfigUser(userConfig);
            this.props.setEventConfigUserRefresh(false);


    };

    /**
     * Get User Info
     */
    checkUserInfo = () => {
        const username = this.getUsername();
        if (username) {
            const request = makeGetDataRequest(resourcesUser, 'User', 'GET_ONE', {});
            request.then(result => {
                this.processUserInfo(result);
                return Promise.resolve(result);
            }).catch(error => {
                console.log('User Info Error: ', error);
                return Promise.reject('Server Error');
            });
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_config_user_refresh: state.project_config_user_refresh
    };
};

const mapDispatchToProps = {
    setProjectConfigUser: setProjectConfigUser,
    setProjectConfigUserRefresh: setProjectConfigUserRefresh,
    setEventConfigUser: setEventConfigUser,
    setEventConfigUserRefresh: setEventConfigUserRefresh,
    setUser: setUser
};

APIUser.propTypes = {
    user: PropTypes.object
};

APIUser.defaultProps = {
    user: Config.user
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIUser);

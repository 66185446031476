import React from 'react';
import ViewIcon from '../../css/images/icon-Table-View.svg';

const ViewVolumeFilesButton = ({ onFormEvent, dataId, volumeName, disabled = false}) => {

    const handleClick = () => {
        if (typeof onFormEvent === 'function') {
            onFormEvent({dataId: dataId, volumeName: volumeName});
        }
    };

    const disabledClass = (disabled) ? ' disabled' : '';

    return (
        <span className={`btn-icon x-table-icon${disabledClass}`}>
            <img src={ViewIcon} alt="View Icon" onClick={() => handleClick()}/>
        </span>
    );
}

export default ViewVolumeFilesButton;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from './getRequest';

/**
 * Component for retrieving Discourse Topics by ID
 */
class APICluster extends Component {

    componentDidMount() {
        this.getCluster();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh  && !prevProps.refresh) {
            this.getCluster();
        }
    }

    /**
     * Get Discourse Topic by Discourse ID
     */
    getCluster = () => {
        const request = makeGetDataRequest(resources, 'DiscourseTopic', 'GET_LIST', {discourse_id: this.props.discourseId});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Discourse Topics Error: ', result);
                    this.processData([], []);
                    return Promise.resolve(result);
                }
                if (result.topic_list && result.topic_list.topics && result.users) {
                    this.processData(result.topic_list.topics, result.users);
                }
                return Promise.resolve(result);
            } else {
                console.log('Discourse Topics Error: No result');
                this.processData([], []);
                return Promise.reject('Discourse Topics failed');
            }
        }).catch(error => {
            console.log('Discourse Topics Error: ', error);
            this.processData([], []);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Assemble Discourse Topic and User data
     *
     * @param topics
     * @param users
     */
    processData = (topics, users) => {
        let list = [];
        const topicsLength = topics.length;
        if (topics && topicsLength > 0) {
            const forumUrl = localStorage.getItem('forum_url');
            for (let i = 0; i < topicsLength; i++) {
                const topic = topics[i];

                // Set Activity date based on last post or created
                let lastPosted = topic.last_posted_at;
                topics[i].activity_date = (!lastPosted || lastPosted === '0001-01-01T00:00:00Z')
                    ? topic.created_at
                    : topic.last_posted_at;

                // Add User data (username, avatar) to list of Posters on this Topic
                const posters = topic.posters;
                for (let p = 0; p < posters.length; p++) {
                    const posterIdx = users.findIndex(r => r.id === posters[p].user_id);
                    if (posterIdx !== -1) {
                        const avatarTemplate = users[posterIdx].avatar_template.replace('{size}', '25');
                        const avatar = (avatarTemplate.charAt(0) === '/') ? avatarTemplate.substr(1) : avatarTemplate;
                        topics[i].posters[p].avatar = forumUrl + avatar;
                        topics[i].posters[p].name = users[posterIdx].name;
                        topics[i].posters[p].username = users[posterIdx].username;
                    }
                }

                // Assemble link to Topic
                topics[i].link = forumUrl + 't/' + topic.slug + '/' + topic.id;

                list.push(topics[i]);
            }
        }
        if (typeof this.props.onResult === 'function') {
            this.props.onResult(list);
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

APICluster.propTypes = {
    discourseId: PropTypes.number,
    onResult: PropTypes.func,
    refresh: PropTypes.bool,
};

APICluster.defaultProps = {
    refresh: false,
};

export default APICluster;

import React, {Component} from 'react';

class Support extends Component {
    render() {
        return (
            <>
                <div className="content support">
                    <h1 className="page-title">
                        Support
                    </h1>
                </div>
            </>
        )
    }
}

export default Support;

import React from 'react';

/**
 * Form Error Element
 *
 * @param id
 * @param text
 * @param className
 * @return {*}
 * @constructor
 */
export default function FormError({id, text, className = 'error'}) {
    return (
        <span role="alert" id={`error-title-${id}`} className={className}>
            {text}
        </span>
    );
}

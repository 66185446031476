import React from 'react';

const XrathusLogoHorizontalReversed = () => {
    return (
        <span className="logo">
            <svg version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 width="648px" height="165.6px"
                 viewBox="290.52 146.433 648 165.6" enableBackground="new 290.52 146.433 648 165.6"
            >
				<path id="path16_1_" fill="#D2A715" d="M455.956,219.857h-33.991l16.996-16.995l-13.146-13.145l-39.434,39.434l39.434,39.433
	l13.146-13.145l-16.996-16.992h33.991V219.857"/>
<path id="path18_1_" fill="#69C7EE" d="M399.527,163.429l-16.994,16.993v-33.989h-18.589v33.989l-16.995-16.993l-13.145,13.145
	l39.434,39.434l39.435-39.434L399.527,163.429"/>
<path id="path20_1_" fill="#AE8BCD" d="M412.672,281.73l-39.435-39.435l-39.434,39.435l13.145,13.145l16.995-16.996v34.141h18.589
	v-34.141l16.994,16.996L412.672,281.73"/>
<path id="path22_1_" fill="#71C32D" d="M320.658,189.718l-13.144,13.145l16.994,16.995H290.52v18.59h33.988l-16.994,16.992
	l13.144,13.145l39.436-39.433L320.658,189.718"/>
<path id="path24_1_" fill="#FFFFFF" d="M399.527,163.429h39.435v39.433l-13.146-13.145l-39.434,39.434l-13.145-13.144l39.435-39.434
	L399.527,163.429"/>
<path id="path26_1_" fill="#FFFFFF" d="M307.514,202.862v-39.433h39.434l-13.145,13.145l39.434,39.434l-13.144,13.144
	l-39.436-39.434L307.514,202.862"/>
<path id="path28_1_" fill="#FFFFFF" d="M346.948,294.875h-39.434v-39.436l13.144,13.145l39.436-39.433l13.144,13.145l-39.434,39.435
	L346.948,294.875"/>
<path id="path30_1_" fill="#FFFFFF" d="M438.961,255.439v39.436h-39.435l13.146-13.145l-39.435-39.435l13.145-13.145l39.434,39.433
	L438.961,255.439"/>
<path id="path32_1_" fill="#FFFFFF" d="M585.87,240.146v-9.464h-24.142v13.442h9.053v37.034h-16.168l-24.552-35.636l24.142-34.021
	h8.504v-13.447H526.77v13.447h8.642l-14.54,20.978l-14.266-20.978h8.505v-13.447h-36.762v13.447h9.053l23.457,35.367l-24.279,34.29
	h-8.916v13.716h36.212v-13.716h-8.778l14.814-21.247l14.541,21.247h-8.506v13.716h70.486v-13.716h-9.876v-28.256
	c6.174-6.447,12.072-9.191,20.164-9.191c0.961,0,1.92,0,2.881,0.139v-13.992c-0.139,0-0.275,0-0.414,0
	C601.096,229.858,593.553,233.425,585.87,240.146"/>
<path id="path34_1_" fill="#FFFFFF" d="M639.129,284.45c-8.094,0-13.031-3.979-13.031-10.288c0-5.624,4.938-9.327,13.031-9.327
	c8.778,0,14.677,4.116,14.677,9.74C653.806,280.061,647.772,284.45,639.129,284.45L639.129,284.45z M666.836,253.587
	c0-7.818-0.549-13.166-5.897-17.692c-4.8-3.979-12.07-6.31-20.849-6.31c-7.544,0-14.816,1.782-22.222,5.212l5.074,11.11
	c5.625-2.333,10.562-3.841,15.5-3.841c10.288,0,15.088,4.253,15.088,13.304c0,0.96,0,2.059-0.135,3.293
	c-5.352-3.154-11.249-5.485-17.971-5.485c-14.813,0-25.102,9.326-25.102,21.534c0,12.482,9.465,21.811,24.004,21.811
	c8.229,0,14.815-2.333,20.438-7.408v5.761h21.673V281.57h-9.603V253.587"/>
<path id="path36_1_" fill="#FFFFFF" d="M704.39,209.832h-15.773v20.851h-9.603v13.442h9.603v50.75h25.787v-13.716H704.39v-37.034
	h10.014v-13.442H704.39V209.832"/>
<path id="path38_1_" fill="#FFFFFF" d="M788.281,256.055c0-17.555-5.624-26.06-20.303-26.06c-7.132,0-14.265,3.019-21.945,9.189
	v-41.131h-24.966v13.306h9.055v69.799h-9.055v13.716h34.842v-13.716h-9.876v-28.256c5.898-5.898,11.385-8.778,17.009-8.778
	c6.582,0,9.326,4.251,9.326,13.029v24.005h-9.326v13.716h33.882v-13.716h-8.643V256.055"/>
<path id="path40_1_" fill="#FFFFFF" d="M865.927,230.682h-25.652v13.442h9.877v28.803c-5.896,5.898-11.521,8.917-17.146,8.917
	c-6.584,0-9.188-4.253-9.188-13.031v-38.131h-24.554v13.442h8.642v25.648c0,17.558,5.762,26.2,20.3,26.2
	c7.271,0,14.402-3.157,22.222-9.467v8.368h24.69v-13.716h-9.19V230.682"/>
<path id="path42_1_" fill="#FFFFFF" d="M915.878,255.644c-11.66-2.467-18.381-3.016-18.381-8.366c0-3.977,3.704-6.721,9.465-6.721
	c5.213,0,10.15,1.783,15.362,5.624v4.527h11.797v-20.026h-11.797v3.703c-5.212-3.703-10.425-5.214-16.46-5.214
	c-13.716,0-22.632,8.369-22.632,20.164c0,6.309,2.468,11.247,7.682,14.813c4.526,3.156,11.658,4.389,20.575,6.312
	c8.229,1.781,12.618,2.331,12.618,7.405c0,3.843-3.703,6.859-9.19,6.859c-7.132,0-13.03-2.195-18.379-6.995v-5.075H883.92v22.22
	h12.618v-4.662c5.485,4.251,11.247,6.31,17.832,6.31c15.086,0,24.141-8.368,24.141-20.165
	C938.511,264.972,931.103,258.8,915.878,255.644"/>
            </svg>
        </span>
    )
};

export default XrathusLogoHorizontalReversed;

import React, {Component} from 'react';
import AAPGLogo from '../css/images/AAPG-Logo.png';
import CriterionLogo from '../css/images/Criterion-Logo.jpg';
import DeasilLogo from '../css/images/Deasil-Works-logo.svg';
import SeeraLabsLogo from '../css/images/Seera-Labs-logo.svg';
import SeetaResourcesLogo from '../css/images/SeetaResources-logo.svg';
import SEGLogo from '../css/images/SEG-SoEG-logo.png';
import '../css/static.css';

class Collaborators extends Component {
    render() {

        return (
            <>
                <div className="content deasil">
                    <h1 className="page-title">
                        Our Collaborators
                    </h1>
                    <h3 className="section-header divider">Industry</h3>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image criterion">
                                <img src={CriterionLogo} alt="Criterion"/>
                            </span>
                        </div>
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image deasil">
                                <img src={DeasilLogo} alt="Deasil Works"/>
                            </span>
                        </div>
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image seera-labs">
                                <img src={SeeraLabsLogo} alt="Seera Labs"/>
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image seeta-resources">
                                <img src={SeetaResourcesLogo} alt="Seeta Resources"/>
                            </span>
                        </div>
                    </div>

                    <h3 className="section-header divider">Associations</h3>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image aapg">
                                <img src={AAPGLogo} alt="AAPG - Advancing the World of Petroleum Geosciences"/>
                            </span>
                        </div>
                        <div className="col col-xs-12 col-sm-6 col-md-4">
                            <span className="single-image seg">
                                <img src={SEGLogo} alt="SEG - Society of Exploration Geophysicists"/>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Collaborators;

import React from 'react';
import PropTypes from 'prop-types';
import {useStoryblokDataSourceFetch} from '../../../utils/StoryblokFunctions';
import LoadingIndicator from '../../LoadingIndicator';

import '../../../css/storyblok-custom.css';


const StoryblokDataSourceFetcher = ({
    name,
    datasource,
    innerContent,
    render,
    nullText,
    nullRender,
}) => {

    const params = {
        "datasource": datasource,
        per_page: 25,
    };

    //fetch any existing saved datasource
    const fullDataSource = JSON.parse(localStorage.getItem(`fetchedDataSource/${datasource}`));

    const [data, isLoading] = useStoryblokDataSourceFetch(params);

    if (!name || name === "" || !datasource || datasource === "") {
        if (nullRender) {
            return nullRender(null);
        }
        else {
            return null;
        }
    }
    
    const initValue = fullDataSource ? (fullDataSource.find(o => o.name === name) ? fullDataSource.find(o => o.name === name).value : null) : null;

    if (isLoading) {
        if (initValue) {
            return render(name, initValue, innerContent);
        }
        else {
            if (nullRender) {
                return nullRender(name, null, innerContent);
            }
            return <LoadingIndicator color="dark" centered={true} size={24} active={isLoading}/>;
        }
    }
    //save fetch time to storage
    localStorage.setItem(`fetchedDataSource/${datasource}/fetchTime`, `${new Date().getTime()}`);

    //return data
    if (data.length > 0) {
        let value = "";
        if (data.find(o => o.name === name)) {
            value = data.find(o => o.name === name).value;
        }
        if (!fullDataSource || (fullDataSource !== data)) {
            //save data source to localStorage
            localStorage.setItem(`fetchedDataSource/${datasource}`, JSON.stringify(data));
        }

        return render(name, value, innerContent);
    }
    else {
        if (nullRender) {
            return nullRender(name, null, innerContent);
        }
        return (
            <div className="center">
                &nbsp;
                <h4>{nullText}</h4>
                &nbsp;
            </div>
        );
    }
};


StoryblokDataSourceFetcher.propTypes = {
    name: PropTypes.string,
    datasource: PropTypes.string,
    innerContent: PropTypes.any,
    render: PropTypes.any,
    nullText: PropTypes.string,
    nullRender: PropTypes.any,
};

StoryblokDataSourceFetcher.defaultProps = {
    name: "",
    datasource: "",
    innerContent: null,
    render: null,
    nullText: "No Content",
    nullRender: null,
};

export default StoryblokDataSourceFetcher;

import React from 'react';

/**
 * Icon Button for expand/collapse in a table
 *
 * @param active
 * @param open
 * @return {JSX.Element}
 * @constructor
 */
const TableExpandIconButton = ({active = false, open = false}) => {

    let activeClass = (active) ? ' active' : '';
    let openClass = (open) ? ' open' : '';

    return (
        <span className={`button-table-expand${activeClass}${openClass}`}>
        </span>
    )
};

export default TableExpandIconButton;

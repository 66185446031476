import React from 'react';

// Select component.
export const Select = ({values, onChange, className}) => {
    return (
        <div className={`control ${className}`}>
            <div className="control-icon">
                <i className="material-icons">&#xE152;</i>
            </div>
            <div className="select-arrow">
                <i className="material-icons">&#xE313;</i>
            </div>
            <select
                className="control-field filter-field form-control"
                onChange={onChange}
                defaultValue={values[0]}
            >
                {values.map((value, i) => (
                    <option key={i} value={value.toLowerCase()}>
                        {value}
                    </option>
                ))}
            </select>
        </div>
    );
};

// Input component.
export const Input = ({onKeyUp, className, placeholder = 'Search...'}) => (
    <div className={`control ${className}`}>
        <div className="control-icon">
            <i className="material-icons">&#xE8B6;</i>
        </div>
        <input
            type="search"
            className="control-field search-field form-control"
            onKeyUp={onKeyUp}
            placeholder={placeholder}
        />
    </div>
);

// Button component.
export const Button = ({onClick}) => (
    <button className="add-more-items btn btn-primary" onClick={onClick}>
        <i className="material-icons">&#xE145;</i>
        Add more items
    </button>
);

// Header component.
export const Header = ({children}) => (
    <>
        <div className="controls cf">{children}</div>
    </>
);
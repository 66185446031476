import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ArticleLink from '../partials/ArticleLink';
import {getSubstrate} from '../utils/pageFunctions';
import {articles} from '../data/articles';

class ArticleLinks extends Component {
    constructor(props) {
        super(props);

        const substrate = getSubstrate(props.location);
        const links = this.createLinks(substrate);

        this.state = {
            links: links,
            substrate: substrate
        };
    }

    createLinks = (substrate) => {
        let links = [];
        let a = 0;
        Object.keys(articles).forEach(function (key) {
            if (articles[key].title && articles[key].featured) {
                const article = {
                    slug: key,
                    title: articles[key].title,
                    thumb_title: articles[key].thumb_title,
                    subtitle: articles[key].subtitle,
                    author: articles[key].author,
                    link: `/x/${substrate}/blogosphere/${key}`,
                    thumb: articles[key].thumb,
                };
                links.push(article);
                a++;
                if (a > 2) {
                    return;
                }
            }
        });
        return links;
    };

    renderLinks = () => {
        const list = this.state.links;
        if (!list || list.length === 0) {
            return (
                <div className="col col-xs-12" key="articles-empty">
                    <p>No Articles Available</p>
                </div>
            )
        }

        return (
            list.map((article, index) => (
                <ArticleLink
                    key={`article-link-${index}`}
                    article={article}
                    slug={article.slug}
                    classes="col col-xs-12 col-sm-4"
                />
            ))
        )
    };

    render() {
        return (
            <div className="row">
                {[...this.renderLinks()]}
            </div>
        )
    }
}

export default withRouter(ArticleLinks);

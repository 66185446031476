import React from 'react';
import PropTypes from 'prop-types';
import { extractHeadingsFromMd, removeCodeBlockFromMd } from './utils';
import { newHeading } from './Heading';

const Toc = ({
    markdownText,
    titleLimit,
    highestHeadingLevel,
    lowestHeadingLevel,
    className,
    customMatchers,
}) => {
    if (!markdownText) return null;
    // Set default values
    const limit = titleLimit;
    const headingLevels = [
        highestHeadingLevel,
        lowestHeadingLevel,
    ];

    // Mutate headings
    const matchedHeadings = extractHeadingsFromMd(
        removeCodeBlockFromMd(markdownText),
        headingLevels[0],
        headingLevels[1],
    );
    const headingObjects = matchedHeadings?.map((heading) =>
        newHeading(heading, limit, customMatchers),
    );
    const headingTags = headingObjects?.map((heading) =>
        heading.generateList(),
    );

    if (!headingTags) return null;

    return (
        <ul className={className}>
            {headingTags.map((heading, index) => (
                <React.Fragment key={index}>{heading}</React.Fragment>
            ))}
        </ul>
    );
};

Toc.propTypes = {
    markdownText: PropTypes.string,
    titleLimit: PropTypes.number,
    highestHeadingLevel: PropTypes.number,
    lowestHeadingLevel: PropTypes.number,
    className: PropTypes.string,
    customMatchers: PropTypes.any,
};

Toc.defaultProps = {
    markdownText: "",
    titleLimit: 200,
    highestHeadingLevel: 1,
    lowestHeadingLevel: 6,
    className: 'react-toc',
    customMatchers: null,
};

export default Toc;
import React, {Component} from 'react';
import AAPGLogo from '../css/images/AAPG-Logo.png';
import CriterionLogo from '../css/images/Criterion-Logo.jpg';
import DeasilLogo from '../css/images/Deasil-Works-logo.svg';
import MessageUpLogo from '../css/images/MessageUp-logo.jpg'
import SeeraLabsLogo from '../css/images/Seera-Labs-logo.svg';
import SeetaResourcesLogo from '../css/images/SeetaResources-logo.svg';

/**
 * Component to display a grid of Collaborator logos and industries
 *
 */
class AboutCollaborators extends Component {
    render() {
        return (
            <div className="about-collaborators">
                <div className="collaborator">
                    <img className="logo criterion" src={CriterionLogo} alt="Criterion"/>
                    <span className="industry">
                        <span>Industry</span>Geothermal Power
                    </span>
                </div>
                <div className="collaborator">
                    <img className="logo deasil" src={DeasilLogo} alt="Deasil Works"/>
                    <span className="industry">
                        <span>Industry</span>Software Development
                    </span>
                </div>
                <div className="collaborator">
                    <img className="logo messageup" src={MessageUpLogo} alt="MessageUp"/>
                    <span className="industry">
                        <span>Industry</span>Content Marketing
                    </span>
                </div>
                <div className="collaborator">
                    <img className="logo seera" src={SeeraLabsLogo} alt="Seera Labs"/>
                    <span className="industry">
                        <span>Industry</span>Machine Learning
                    </span>
                </div>
                <div className="collaborator">
                    <img className="logo seeta" src={SeetaResourcesLogo} alt="Seeta Resources"/>
                    <span className="industry">
                        <span>Industry</span>Revenue Acceleration
                    </span>
                </div>
                <div className="collaborator">
                    <img className="logo aapg" src={AAPGLogo} alt="AAPG"/>
                    <span className="industry">
                        <span>Industry</span>Petroleum Geoscience
                    </span>
                </div>
            </div>
        )
    }
}

export default AboutCollaborators;

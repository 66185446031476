import {clustersNav} from './navigation-clusters';
import {collaboratorsNav} from './navigation-collaborators';
import {dashboardNav} from './navigation-dashboard';
import {publicNav} from './navigation-public';

const gitlab_url = localStorage.getItem('gitlab_url');
const training_url = localStorage.getItem('training_url');

/**
 * Navigation: Sidebar
 *     Default
 *     Connect
 *     Hub
 *     Hub: Challenge Detail
 *     Hub: Project Detail
 *     Marketplace
 *     Training
 */
export const navigationSidebar = {
    'default': {
        title: '',
        description: 'Default navigation items',
        slug: '',
        path: '',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/connect/my-xrathus',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            ...publicNav
        ]
    },
    'connect': {
        title: 'Connect',
        description: 'Navigation for Substrate CONNECT',
        slug: 'connect',
        path: '/x/connect/my-xrathus',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/connect/my-xrathus',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            clustersNav,
            collaboratorsNav,
            {
                title: 'Blogosphere',
                slug: 'connect-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
                links: [
                    {
                        title: 'All',
                        slug: 'connect-blogosphere-all',
                        substrateClass: 'connect',
                        route: '/x/connect/blogosphere/list/all'
                    },
                ]
            },
            {
                title: 'Challenges',
                slug: 'connect-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
                links: [
                    {
                        title: 'All',
                        slug: 'connect-challenges-all',
                        substrateClass: 'connect',
                        route: '/x/connect/challenges/list/all'
                    },
                    {
                        title: '+ Add',
                        slug: 'connect-challenges-add',
                        substrateClass: 'connect',
                        route: '/x/connect/challenges/add',
                        role: 'sysop'
                    }
                ]
            },
            {
                title: 'Events',
                slug: 'connect-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png',
                links: [
                    {
                        title: 'Personal Events',
                        slug: 'connect-events-personal',
                        substrateClass: 'connect',
                        route: '/x/connect/events/personal'
                    },
                    {
                        title: 'Public Events',
                        slug: 'connect-events-public',
                        substrateClass: 'connect',
                        route: '/x/connect/events/public',
                    },
                    {
                        title: 'Event Invitations',
                        slug: 'connect-events-sharing',
                        substrateClass: 'connect',
                        route: '/x/connect/events/sharing'
                    },
                    {
                        title: 'Joined Events',
                        slug: 'connect-events-subscribed',
                        substrateClass: 'connect',
                        route: '/x/connect/events/subscribed'
                    },
                    {
                        title: '+ Add',
                        slug: 'connect-events-add',
                        substrateClass: 'connect',
                        route: '/x/connect/events/add',
                    }
                ]
            },
            {
                title: 'Projects',
                slug: 'connect-projects',
                substrateClass: 'hub',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
            },
            {
                title: 'Knowledge Base',
                slug: 'connect-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    },
    'hub': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Landing)',
        slug: 'hub',
        path: '/x/hub/my-hub',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/hub/my-hub',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            {
                title: 'Clusters',
                slug: 'nav-clusters',
                substrateClass: 'connect',
                route: '/x/connect/clusters',
                icon: 'images/icon-Connect-Forums.svg',
            },
            {
                title: 'Collaborators',
                slug: 'hub-collaborators',
                substrateClass: 'connect',
                route: '/x/connect/collaborators',
                icon: 'images/icon-Connect-Collaboration.svg',
            },
            {
                title: 'Blogosphere',
                slug: 'hub-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
            },
            {
                title: 'Challenges',
                slug: 'hub-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
            },
            {
                title: 'Events',
                slug: 'hub-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png'
            },
            {
                title: 'Projects',
                slug: 'hub-projects',
                substrateClass: 'hub',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
                links: [
                    {
                        title: 'Personal',
                        slug: 'hub-projects-personal',
                        substrateClass: 'hub',
                        route: '/x/hub/projects/personal'
                    },
                    {
                        title: 'Challenges',
                        slug: 'hub-projects-challenges-subscribed',
                        substrateClass: 'hub',
                        route: '/x/hub/projects/challenges',
                    },
                    {
                        title: 'Training',
                        slug: 'hub-projects-training',
                        substrateClass: 'hub',
                        route: '/x/hub/projects/hub-training'
                    },
                    {
                        title: '+ Add',
                        slug: 'hub-projects-add',
                        substrateClass: 'hub',
                        route: '/x/hub/projects/add'
                    }
                ]
            },
            {
                title: 'Files',
                slug: 'hub-files',
                route: gitlab_url,
                external: true,
                icon: 'images/icon-Hub-Files.svg'
            },
            {
                title: 'Toolbox',
                slug: 'hub-toolbox',
                route: '/x/hub/toolbox',
                icon: 'images/icon-Hub-Toolbox.svg'
            },
            {
                title: 'Models',
                slug: 'hub-models',
                route: '/x/hub/models',
                icon: 'images/icon-Hub-Models.svg'
            },
            {
                title: 'Sharing',
                slug: 'hub-sharing',
                route: '/x/hub/sharing',
                icon: 'images/icon-Hub-Sharing.svg'
            },
            {
                title: 'Publish',
                slug: 'hub-publish',
                route: '/x/hub/publish',
                icon: 'images/icon-Hub-Publish.svg'
            },
            {
                title: 'Knowledge Base',
                slug: 'hub-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    },
    'hub-challenge': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Challenge Detail)',
        slug: 'hub',
        subSlug: 'challenge',
        path: '/x/hub/challenge',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/hub/my-hub',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            {
                title: 'Clusters',
                slug: 'nav-clusters',
                substrateClass: 'connect',
                route: '/x/connect/clusters',
                icon: 'images/icon-Connect-Forums.svg',
            },
            {
                title: 'Collaborators',
                slug: 'hub-collaborators',
                substrateClass: 'connect',
                route: '/x/connect/collaborators',
                icon: 'images/icon-Connect-Collaboration.svg',
            },
            {
                title: 'Blogosphere',
                slug: 'hub-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
            },
            {
                title: 'Challenges',
                slug: 'hub-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
            },
            {
                title: 'Events',
                slug: 'hub-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png'
            },
            {
                title: 'Projects',
                slug: 'hub-projects',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
                links: [
                    {
                        title: 'Personal',
                        slug: 'hub-personal',
                        route: '/x/hub/projects/personal'
                    },
                    {
                        title: 'Challenges',
                        slug: 'hub-challenges-subscribed',
                        route: '/x/hub/projects/challenges',
                        role: 'sysop'
                    },
                    {
                        title: 'Training',
                        slug: 'hub-training',
                        route: '/x/hub/projects/hub-training'
                    }
                ]
            },
            {
                title: 'Overview',
                slug: 'hub-overview',
                route: '/x/hub/challenge/$project_id/overview',
                replace: '$project_id',
                icon: 'images/icon-Hub-Overview.svg',
            },
            {
                title: 'Files',
                slug: 'hub-files',
                route: gitlab_url,
                external: true,
                icon: 'images/icon-Hub-Files.svg',
            },
            {
                title: 'Workflows',
                slug: 'hub-workflows',
                route: '/x/hub/challenge/$project_id/workflows',
                replace: '$project_id',
                icon: 'images/icon-Hub-Workflow.svg',
            },
            {
                title: 'Pipelines',
                slug: 'hub-pipelines',
                route: '/x/hub/challenge/$project_id/pipelines',
                replace: '$project_id',
                icon: 'images/icon-Hub-Pipeline.svg',
            },
            {
                title: 'Toolbox',
                slug: 'hub-toolbox',
                route: '/x/hub/challenge/$project_id/toolbox',
                replace: '$project_id',
                icon: 'images/icon-Hub-Toolbox.svg',
            },
            {
                title: 'Models',
                slug: 'hub-models',
                route: '/x/hub/challenge/$project_id/models',
                replace: '$project_id',
                icon: 'images/icon-Hub-Models.svg',
            },
            {
                title: 'Sharing',
                slug: 'hub-sharing',
                route: '/x/hub/sharing',
                icon: 'images/icon-Hub-Sharing.svg',
            },
            {
                title: 'Publish',
                slug: 'hub-publish',
                route: '/x/hub/challenge/$project_id/publish',
                replace: '$project_id',
                icon: 'images/icon-Hub-Publish.svg',
            },
            {
                title: 'Knowledge Base',
                slug: 'hub-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    },
    'hub-project': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Project Detail)',
        slug: 'hub',
        subSlug: 'project',
        path: '/x/hub/project',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/hub/my-hub',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            {
                title: 'Clusters',
                slug: 'nav-clusters',
                substrateClass: 'connect',
                route: '/x/connect/clusters',
                icon: 'images/icon-Connect-Forums.svg',
            },
            {
                title: 'Collaborators',
                slug: 'hub-collaborators',
                substrateClass: 'connect',
                route: '/x/connect/collaborators',
                icon: 'images/icon-Connect-Collaboration.svg',
            },
            {
                title: 'Blogosphere',
                slug: 'hub-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
            },
            {
                title: 'Challenges',
                slug: 'hub-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
            },
            {
                title: 'Events',
                slug: 'hub-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png'
            },
            {
                title: 'Projects',
                slug: 'hub-projects',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
                links: [
                    {
                        title: 'Personal',
                        slug: 'hub-personal',
                        route: '/x/hub/projects/personal'
                    },
                    {
                        title: 'Challenges',
                        slug: 'hub-challenges-subscribed',
                        route: '/x/hub/projects/challenges',
                        role: 'sysop'
                    },
                    {
                        title: 'Training',
                        slug: 'hub-training',
                        route: '/x/hub/projects/hub-training'
                    }
                ]
            },
            {
                title: 'Overview',
                slug: 'hub-overview',
                route: '/x/hub/project/personal/$project_id/overview',
                replace: '$project_id',
                icon: 'images/icon-Hub-Overview.svg',
            },
            {
                title: 'Files',
                slug: 'hub-files',
                route: gitlab_url,
                external: true,
                icon: 'images/icon-Hub-Files.svg',
            },
            {
                title: 'Workflows',
                slug: 'hub-workflows',
                route: '/x/hub/project/personal/$project_id/workflows',
                replace: '$project_id',
                icon: 'images/icon-Hub-Workflow.svg',
            },
            {
                title: 'Pipelines',
                slug: 'hub-pipelines',
                route: '/x/hub/project/personal/$project_id/pipelines',
                replace: '$project_id',
                icon: 'images/icon-Hub-Pipeline.svg',
            },
            {
                title: 'Toolbox',
                slug: 'hub-toolbox',
                route: '/x/hub/project/personal/$project_id/toolbox',
                replace: '$project_id',
                icon: 'images/icon-Hub-Toolbox.svg',
            },
            {
                title: 'Models',
                slug: 'hub-models',
                route: '/x/hub/project/personal/$project_id/models',
                replace: '$project_id',
                icon: 'images/icon-Hub-Models.svg',
            },
            {
                title: 'Sharing',
                slug: 'hub-sharing',
                route: '/x/hub/sharing',
                icon: 'images/icon-Hub-Sharing.svg',
            },
            {
                title: 'Publish',
                slug: 'hub-publish',
                route: '/x/hub/project/personal/$project_id/publish',
                replace: '$project_id',
                icon: 'images/icon-Hub-Publish.svg',
            },
            {
                title: 'Knowledge Base',
                slug: 'hub-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    },
    'marketplace': {
        title: 'Marketplace',
        description: 'Navigation for Substrate MARKETPLACE',
        slug: 'marketplace',
        path: '/x/marketplace/my-marketplace',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/marketplace/my-marketplace',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            {
                title: 'Clusters',
                slug: 'nav-clusters',
                substrateClass: 'connect',
                route: '/x/connect/clusters',
                icon: 'images/icon-Connect-Forums.svg',
            },
            {
                title: 'Collaborators',
                slug: 'marketplace-collaborators',
                substrateClass: 'connect',
                route: '/x/connect/collaborators',
                icon: 'images/icon-Connect-Collaboration.svg',
            },
            {
                title: 'Blogosphere',
                slug: 'marketplace-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
            },
            {
                title: 'Challenges',
                slug: 'hub-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
            },
            {
                title: 'Events',
                slug: 'hub-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png'
            },
            {
                title: 'Projects',
                slug: 'marketplace-projects-public',
                substrateClass: 'hub',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
            },
            {
                title: 'Knowledge Base',
                slug: 'marketplace-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    },
    'training': {
        title: 'Training',
        description: 'Navigation for Substrate TRAINING',
        slug: 'training',
        path: '/x/training/my-training',
        links: [
            {
                title: 'Dashboard',
                slug: 'nav-dashboard',
                substrateClass: 'dashboard',
                route: '/x/training/my-training',
                icon: 'images/icon-Dashboard.png',
                links: dashboardNav,
            },
            {
                title: 'Clusters',
                slug: 'nav-clusters',
                substrateClass: 'connect',
                route: '/x/connect/clusters',
                icon: 'images/icon-Connect-Forums.svg',
            },
            {
                title: 'Collaborators',
                slug: 'training-collaborators',
                substrateClass: 'connect',
                route: '/x/connect/collaborators',
                icon: 'images/icon-Connect-Collaboration.svg',
            },
            {
                title: 'Blogosphere',
                slug: 'training-blogosphere',
                substrateClass: 'connect',
                route: '/x/connect/blogosphere',
                icon: 'images/icon-Connect-Blogosphere.svg',
            },
            {
                title: 'Challenges',
                slug: 'hub-challenges-public',
                substrateClass: 'connect',
                route: '/x/connect/challenges/',
                icon: 'images/icon-Connect-Challenges.svg',
            },
            {
                title: 'Events',
                slug: 'hub-events',
                substrateClass: 'connect',
                route: '/x/connect/events',
                icon: 'images/icon-Events.png'
            },
            {
                title: 'Projects',
                slug: 'training-projects-public',
                substrateClass: 'hub',
                route: '/x/hub/projects',
                icon: 'images/icon-Hub-Projects.svg',
            },
            {
                title: 'Knowledge Base',
                slug: 'training-knowledge-base',
                substrateClass: 'training',
                route: '/x/training/knowledge-base',
                icon: 'images/icon-Training-Knowledge-Base.svg',
                links: [
                    {
                        title: 'Platform',
                        slug: 'training-knowledge-base-platform',
                        route: '/x/training/knowledge-base/platform',
                        links: [
                            {
                                title: 'Xrathus',
                                slug: 'training-knowledge-base-platform-xrathus',
                                route: '/x/training/knowledge-base/platform/xrathus'
                            },
                        ]
                    },
                    {
                        title: 'Documentation',
                        slug: 'training-knowledge-base-documentation',
                        route: '/x/training/knowledge-base/documentation',
                        groupLimited: 'knowledge-base',
                        links: [
                            {
                                title: 'Xrathus',
                                slug: 'training-knowledge-base-documentation-xrathus',
                                route: '/x/training/knowledge-base/documentation/xrathus'
                            },
                        ]
                    },
                    {
                        title: 'Industry',
                        slug: 'training-knowledge-base-industry',
                        route: '/x/training/knowledge-base/industry',
                        links: [
                            {
                                title: 'Seeta Resources',
                                slug: 'training-knowledge-base-industry-seeta',
                                route: '/x/training/knowledge-base/industry/seeta'
                            },
                        ]
                    },
                    {
                        title: 'Associations',
                        slug: 'training-knowledge-base-associations',
                        route: '/x/training/knowledge-base/associations',
                        links: [
                            {
                                title: 'AAPG',
                                slug: 'training-knowledge-base-associations-aapg',
                                route: '/x/training/knowledge-base/associations/aapg'
                            },
                            {
                                title: 'SEG',
                                slug: 'training-knowledge-base-associations-seg',
                                route: '/x/training/knowledge-base/associations/seg'
                            },
                        ]
                    },
                ]
            },
            {
                title: 'Courses',
                slug: 'training-courses',
                substrateClass: 'training',
                route: training_url,
                icon: 'images/icon-Training-Courses.svg',
                external: true
            },
            {
                title: 'Solutions',
                slug: 'marketplace-solutions',
                substrateClass: 'marketplace',
                route: '/x/marketplace/solutions',
                icon: 'images/icon-Marketplace-Solutions.svg',
            }
        ]
    }
};

export const navigationSidebarPublic = {
    'default': {
        title: '',
        description: 'Default navigation items',
        slug: '',
        path: '',
        links: publicNav
    },
    'connect': {
        title: 'Connect',
        description: 'Navigation for Substrate CONNECT',
        slug: 'connect',
        path: '/x/connect/my-xrathus',
        links: publicNav
    },
    'hub': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Landing)',
        slug: 'hub',
        path: '/x/hub/my-hub',
        links: publicNav
    },
    'hub-challenge': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Challenge Detail)',
        slug: 'hub',
        subSlug: 'challenge',
        path: '/x/hub/challenge',
        links: publicNav
    },
    'hub-project': {
        title: 'Hub',
        description: 'Navigation for Substrate HUB (Project Detail)',
        slug: 'hub',
        subSlug: 'project',
        path: '/x/hub/project',
        links: publicNav
    },
    'marketplace': {
        title: 'Marketplace',
        description: 'Navigation for Substrate MARKETPLACE',
        slug: 'marketplace',
        path: '/x/marketplace/my-marketplace',
        links: publicNav
    },
    'training': {
        title: 'Training',
        description: 'Navigation for Substrate TRAINING',
        slug: 'training',
        path: '/x/training/my-training',
        links: publicNav
    }
};

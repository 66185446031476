import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {copyArray, dateIsPast, sortArrayByKey} from '../utils/dataFunctions';
import ChallengeItem from '../partials/ChallengeItem';

/**
 * A component to assemble and display Challenge result items by Due Date
 */
class DashboardChallenges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            challengeList: []
        };
    }

    componentDidMount() {
        this.assembleChallengeList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.challenge_list_subscribed) !== JSON.stringify(this.props.challenge_list_subscribed)) {
            this.assembleChallengeList();
        }
    }

    /**
     * Assemble list of joined Challenges by Due Date
     */
    assembleChallengeList = () => {
        const onComplete = this.props.onComplete;
        let joined = copyArray(this.props.challenge_list_subscribed);
        let sorted = [];
        if (joined.length > 0) {
            for (let i = 0; i < joined.length; i++) {
                const dueDate = joined[i].due_date;
                if (dueDate) {
                    if (joined[i].created_on === '0001-01-01T00:00:00Z') {
                        joined[i].created_on = '2021-05-01T00:00:00Z';
                    }
                    if (!dateIsPast(new Date(dueDate))) {
                        sorted.push(joined[i]);
                    }
                }
            }
            sorted = sortArrayByKey(sorted, 'due_date', 'ASC');
            // Keep the latest two
            if (sorted.length > 2) {
                sorted.length = 2;
            }
            this.setState({
                challengeList: sorted
            });
        }
        if (typeof onComplete === 'function') {
            onComplete(sorted);
        }
    };

    /**
     * Set pair of Challenge result cards
     *
     * @param list
     * @returns {*}
     */
    setChallengeItems = (list) => {
        return (
            list.map((item, index) => (
                <div className="col col-sm-12 col-md-6" key={`challenge-${item.id}-${index}`}>
                    <ChallengeItem
                        projectItem={item}
                        idx={index}
                        inUserChallenges={true}
                        progress={true}
                        clickFunc={null}
                        clickLink={`/x/hub/challenge/${item.id}/overview`}
                    />
                </div>
            ))
        )
    };

    render() {
        const {
            challengeList
        } = this.state;

        return (
            <>
                {challengeList.length > 0 &&
                <div className="col col-xs-12 col-sm-6">
                    <h3 className="section-header no-underline">Challenges</h3>
                    <div className="row">
                        {[...this.setChallengeItems(challengeList)]}
                    </div>
                    <div className="form-btns center">
                        <Link className="btn btn-primary center" title="Challenges"
                              to="/x/connect/challenges/list/all">
                            Show More
                        </Link>
                    </div>
                </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_subscribed: state.challenge_list_subscribed
    };
};

DashboardChallenges.propTypes = {
    onComplete: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps
    )(DashboardChallenges)
);

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollHandler() {
    const { hash, pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                window.scrollTo({
                    behavior: element ? 'smooth' : 'auto',
                    top: element ? element.offsetTop-100 : 0
                });
            }
            else {
                window.scrollTo(0, 0);
            }
        }, 200);
    }, [hash, pathname]);

    return null;
}

/**
 * Determine if current runtime is local
 *
 * @type {boolean}
 */
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * Load JSON file with environment variables
 *
 * @return {Promise<unknown>}
 */
export async function getEnvironmentConfig() {
    return fetch('/config.json')
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Update an object via Object.assign
 *
 * @param oldObject
 * @param newValues
 * @return {any}
 */
export const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
};

/**
 * Copy an array
 *
 * @param array
 * @return {any}
 */
export const copyArray = (array) => {
    return JSON.parse(JSON.stringify(array))
};

/**
 * Combine two items into an array
 *
 * @param arr1
 * @param arr2
 * @return {*[]}
 */
export const combineArrays = (arr1, arr2) => {
    return [].concat(arr1, arr2);
};

/**
 * Convert bytes to a human-readable string
 *
 * @param bytes
 * @return {string}
 */
export const convertBytesToString = (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
};

/**
 * Sort array of objects alphanumerically
 *
 * @param arr
 * @param sortField
 * @param sortOrder
 */
export const sortArrayByKey = (arr, sortField, sortOrder = 'ASC') => {
    arr.sort(function (a, b) {
        return a[sortField].toString().toLowerCase().localeCompare(b[sortField].toString().toLowerCase());
    });
    if (sortOrder === 'DESC') {
        arr = arr.reverse();
    }
    return arr;
};

/**
 * Add items from one list to another without duplication
 *
 * @param list
 * @param newList
 * @param key
 * @returns {*}
 */
export const addItemsToListByKey = (list, newList, key = 'id') => {
    for (let i = 0; i < newList.length; i++) {
        if (list.findIndex(r => r[key] === newList[i][key]) === -1) {
            list.push(newList[i]);
        }
    }
    return list;
};

/**
 * Add commas to numerical strings
 *
 * @param nStr
 * @return {string}
 */
export const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
};

/**
 * Truncate a string with an ellipse
 *
 * @param str
 * @param n
 * @returns {string|*}
 */
export const truncateString = (str, n) => {
    if (str.length > n) {
        return str.substring(0, n) + '...';
    }
    return str;
};

/**
 * Convert file size to a stylized string
 *
 * @param num
 * @return {string|*}
 */
export const convertSizeToDisplayString = (num) => {
    if (num === null || num === undefined) {
        return '0k';
    }

    // For values under 1,000, return number with comma (1,234 bytes)
    if (num < 10000) {
        return addCommas(num) + ' bytes';
    }

    const symbol = ['', 'KB', 'MB', 'GB'];
    const tier = Math.log10(num) / 3 | 0;
    if (tier === 0) return num;

    const suffix = symbol[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = num / scale;
    if (isNaN(scaled)) {
        return '0 bytes';
    }
    // Allow 2 numbers after decimal point for millions (2.34M, 100.3k)
    const fixed = (tier > 1) ? 1 : 0;
    return scaled.toFixed(fixed) + suffix;
};

/**
 * Format a new datetime object
 * @param date
 * @return {string}
 */
export const createDateTimeObject = (date) => {
    // Format: YYYY-MM-DDTHH:MM:SS+07:00
    const dateObj = (date) ? date : new Date();
    const tzo = dateObj.getTimezoneOffset();
    const oHrs = padDateString(Math.abs(tzo / 60));
    const osMin = padDateString(Math.abs(tzo % 60));
    let tz_standard = 'Z';
    if (osMin < 0) {
        tz_standard = '+' + oHrs + ':' + osMin;
    } else if (osMin > 0) {
        tz_standard = '-' + oHrs + ':' + osMin;
    }

    return dateObj.getFullYear() + '-'
        + padDateString(dateObj.getMonth() + 1) + '-'
        + padDateString(dateObj.getDate()) + 'T'
        + padDateString(dateObj.getHours()) + ':'
        + padDateString(dateObj.getMinutes()) + ':'
        + padDateString(dateObj.getSeconds())
        + tz_standard;
};

/**
 * Assemble a human-readable date string
 *
 * @param datetime
 * @param order
 * @param withTime
 * @return {string}
 */
export const makeDateString = (datetime, order = 'm', withTime = true) => {
    const dateObj = new Date(datetime);
    const mo = dateObj.getMonth() + 1;
    const dy = dateObj.getDate();
    const yy = dateObj.getFullYear();
    let hr = dateObj.getHours();
    const ampm = (hr > 11) ? 'pm' : 'am';
    if (hr === 0) {
        hr = 12;
    }
    if (hr > 12) {
        hr -= 12;
    }
    let mn = dateObj.getMinutes();
    let minVal = String('00' + mn).slice(-2);
    let dateStr;
    if (order === 'm') {
        dateStr = mo + '/' + dy + '/' + yy;
    } else if (order === 'd') {
        dateStr = dy + '/' + mo + '/' + yy;
    } else {
        dateStr = mo + '/' + dy + '/' + yy;
    }
    if (withTime) {
        dateStr += ' ' + hr + ':' + minVal + ' ' + ampm;
    }
    if (isNaN(yy) || isNaN(mo)) {
        dateStr = 'No Date';
    }
    return dateStr;
};

/**
 * Pad numbers less than 10 with a leading zero
 *
 * @param num
 * @return {string}
 */
export const padDateString = (num) => {
    return String('00' + num).slice(-2);
};

/**
 * Determine whether a date is older than now
 *
 * @param date
 * @returns {boolean}
 */
export const dateIsPast = (date) => {
    return (new Date().getTime() - date.getTime() > 0);
};

/**
 * Check for a blank or invalid value
 *
 * @param term
 * @return {boolean}
 */
export const isBlank = (term) => {
    return (term === undefined || term === null || term === '');
};

export const secondsToTimeString = (time) => {
    let mins = Math.floor(time / 60);
    let secs = time - mins * 60;
    let minStr = (mins > 0) ? (mins === 1) ? '1 minute ' : mins + ' minutes ' : '';
    let secStr = (secs > 0) ? (secs === 1) ? '1 second' : secs + ' seconds' : '';
    return minStr + secStr;
};

/**
 * Assemble a paginated, filtered query for GET calls
 *
 * @param params
 * @returns {string}
 */
export const assembleQuery = (params) => {
    const page = `page=${params.page}`;
    const perPage = `&per_page=${params.perPage}`;
    const search = (params.search) ? `&search=${params.search}` : '&search='
    const sortBy = (params.sortBy) ? `&sort_by=${params.sortBy}` : '&sort_by=id';
    const order = (params.order) ? `&order=${params.order}` : '&order=';
    return page + perPage + search + sortBy + order;
};

/**
 * Assemble "ago" string for dates
 *
 * @param timeStamp
 * @return {string}
 */
export const timeSince = (timeStamp) => {
    let now = new Date();
    let date = new Date(timeStamp);
    let secondsPast = (now.getTime() - date.getTime()) / 1000;
    if (secondsPast < 60) {
        return Math.round(secondsPast) + 's';
    }
    if (secondsPast < 3600) {
        return Math.round(secondsPast) + 'm';
    }
    if (secondsPast <= 86400) {
        return Math.round(secondsPast / 3600) + 'h';
    }
    if (secondsPast > 86400) {
        let day = date.getDate();
        let month = date.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
        let year = date.getFullYear() === now.getFullYear() ? '' : ', ' + date.getFullYear();
        return month + ' ' + day + year;
    }
};

/**
 * Get a Discourse Category link from stored object
 *
 * @param cat
 * @return {string|*}
 */
export const getForumCategoryLink = (cat) => {
    const cats = localStorage.getItem('forum_categories');
    if (cats && cats !== 'undefined') {
        const catsObj = JSON.parse(cats);
        if (catsObj && catsObj[cat]) {
            return catsObj[cat];
        }
    }
    return '';
};

/**
 * Get all Discourse Category links from stored object
 * @return {{}|any}
 */
export const getAllForumCategoryLinks = () => {
    const cats = localStorage.getItem('forum_categories');
    if (cats && cats !== 'undefined') {
        const catsObj = JSON.parse(cats);
        if (catsObj) {
            return catsObj;
        }
    }
    return {};
};

export const extractDiscourseId = (forumPaths, key) => {
    const obj = forumPaths[key];
    if (obj) {
        const id = obj.split('/').pop();
        return (id) ? parseInt(id, 10) : null;
    }
    return null;
};
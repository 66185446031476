import {assembleQuery} from './utils/dataFunctions';

/**
 * Resources for API
 *      Project Configuration (Default)
 *      Project Configuration (Project Override)
 *      Non-Personal Projects (pending)
 *      Personal Projects (CRUD)
 *      Personal Projects (Owned by User)
 *      Personal Projects (Joined by User)
 *      Personal Projects (Shared with User)
 *      Shared Users
 *      Airmeet Sessions
 *      Join Personal Project
 *      Reject Shared Personal Project
 *      Share Personal Project with User
 *      Unsubscribe User from Personal Project
 */
export const RESOURCES = {
    /* Project Configuration (Default) */
    'ProjectConfiguration': {
        GET_LIST: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/configs/personal'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/config/personal/' + params.config
            }
        },
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/config/personal/update/' + params.config + '/' + params.value
            }
        }
    },
    /* Project Configuration (Project Override) */
    'ProjectPersonalConfig': {
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/update/configs'
            }
        }
    },
    /* non-Personal Projects (Available once non-Personal Projects are available) */
    'Project': {
        GET_LIST: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/projects/all'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/' + params.id
            }
        }
    },
    /* Personal Projects: List, Single, Create/Update */
    'ProjectPersonal': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/browse/personal/' + params.active + '?' + query
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/create'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/update'
            }
        }
    },
    /* List: Personal Projects owned by User */
    'ProjectPersonalOwn': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/all/personal/' + params.active
            }
        }
    },
    /* List: Personal Projects owned by User by Active Status */
    'ProjectPersonalOwnByActive': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/all/personal/' + params.active
            }
        }
    },
    /* List: Personal Projects joined/subscribed to by User */
    'ProjectPersonalSubscribed': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/browse/subscribed/personal?' + query
            }
        }
    },
    /* List: Personal Projects joined/subscribed to by Sysop */
    'ProjectPersonalSubscribedSysop': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/browse/joined/personal/sysop?' + query
            }
        }
    },
    /* List: Personal Projects shared with User  */
    'ProjectPersonalShared': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/browse/shared/personal?' + query
            }
        }
    },
    /* List: Users a Personal Project is shared with  */
    'ProjectPersonalSharedUsers': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/shares/personal/' + params.id
            }
        }
    },
    /* Action: Delete a Personal Project */
    'ProjectPersonalDelete': {
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/' + params.id
            }
        }
    },
    /* Action: Join a Personal Project */
    'ProjectPersonalJoin': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/join/personal/' + params.id
            }
        }
    },
    /* Action: Reject a Shared Personal Project */
    'ProjectPersonalReject': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/rejectInvite/personal/' + params.id
            }
        }
    },
    /* Action: Share a Personal Project */
    'ProjectPersonalShare': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/share/personal/' + params.id + '/' + params.username
            }
        }
    },
    /* Action: Unsubscribe User from Personal Project (by self or by owner) */
    'ProjectPersonalUnsubscribe': {
        POST: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/unsubscribe/personal'
            }
        }
    },
    /* List, Action: Airmeet Sessions for a Challenge or Personal Project  */
    'AirmeetSessions': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/sessions/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/create/session'
            }
        }
    },
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Challenge"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resourcesEvent';
import {
    setListStatus,
    setEventId,
    setEventListInvited,
    setEventListOwn,
    setEventListRefresh,
    setEventListSubscribed
} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import {copyArray} from './dataFunctions';
import {populateShortDescription} from './projectFunctions';

/**
 * Component for retrieving Event Lists and single Event items
 *
 */
class APIEvents extends Component {

    componentDidMount() {
        const getType = this.props.getType;
        if (getType === 'list') {
            this.getEvents();
        }
        if (getType === 'single') {
            this.getEvent();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const getType = this.props.getType;
        if (this.props.refresh === true) {
            if (getType === 'list') {
                this.getEvents();
            }
            if (getType === 'single') {
                this.getEvent();
            }
        } else if (this.props.event_list_refresh !== null) {
            if (getType === 'list') {
                this.getEvents();
            }
        }
    }


    /**
     * Get Events with optional parameters
     *
     */
    getEvents = () => {
        const refreshType = this.props.event_list_refresh;
        const viewType = this.props.viewType;
        // If Events are being refreshed, only do so if this API matches the refresh type
        if (refreshType && refreshType !== viewType) {
            return;
        }
        let resourceName = 'Event';
        if (viewType === 'personal_own') {
            resourceName = 'EventPersonalOwn';
        }
        if (viewType === 'home_public') {
            resourceName = 'EventHomePagePublic';
        }
        if (viewType === 'personal_public') {
            resourceName = 'Event';
        }
        if (viewType === 'admin') {
            resourceName = 'EventAdmin';
        }
        if (viewType === 'shared') {
            resourceName = 'EventPersonalShared';
        }
        if (viewType === 'subscribed') {
            resourceName = 'EventPersonalSubscribed';
        }
        if (viewType === 'subscribed_sysop') {
            resourceName = 'EventPersonalSubscribedSysop';
        }
        const params = this.props.params;

        const request = makeGetDataRequest(resources, resourceName, 'GET_LIST', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Events Retrieval Error: ', result);
                    this.processList(null);
                    return Promise.resolve(result);
                }
                this.processList(result);
                return Promise.resolve(result);
            } else {
                console.log('Events Retrieval Error: No result');
                return Promise.reject('Events Retrieval failed');
            }
        }).catch(error => {
            console.log('Events Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Get single Event
     */
    getEvent = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const viewType = this.props.viewType;
        let resource = 'Event';
        if (viewType === 'personal' || viewType === 'personal_own') {
            resource = 'EventPersonal';
        }

        const request = makeGetDataRequest(resources, resource, 'GET_ONE', {id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Event Retrieval Error: ', result);
                    this.processSingle(null);
                    return Promise.resolve(result);
                }
                this.processSingle(result);
                return Promise.resolve(result);
            } else {
                console.log('Event Retrieval Error: No result');
                return Promise.reject('Event Retrieval failed');
            }
        }).catch(error => {
            console.log('Event Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Return and/or Store list data
     *
     * @param data
     */
    processList = (data) => {
        let list = [];
        const viewType = this.props.viewType;

        // Events owned by User
        if (viewType === 'personal_own') {
            if (data && data.length > 0) {
                list = copyArray(data);
            }
        }

        // All Public Events
        if (viewType === 'personal_public' || viewType === 'shared' || viewType === 'subscribed') {
            if (data && data.AirmeetEvents && data.AirmeetEvents.length > 0) {
                list = copyArray(data.AirmeetEvents);
            }
        }

        if (viewType === 'home_public') {
            if (data && data.AirmeetEvents && data.AirmeetEvents.length > 0) {
                list = copyArray(data);
            }
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(list);
        }
        // Store results in Redux for specific lists
        if (this.props.storeExists && this.props.storeResults) {
            if (viewType === 'personal_own') {
                this.props.setEventListOwn(list);
                this.props.setListStatus({list: 'event_own', status: true});
            }
            if (viewType === 'shared') {
                this.props.setEventListInvited(list);
                this.props.setListStatus({list: 'event_invited', status: true});
            }
            if (viewType === 'subscribed' || viewType === 'subscribed_sysop') {
                this.props.setEventListSubscribed(list);
                this.props.setListStatus({list: 'event_subscribed', status: true});
            }
            this.props.setEventListRefresh(null);
        }
    };

    /**
     * Return single result
     *
     * @param data
     */
    processSingle = (data) => {
        if (data) {
            data.short_desc = populateShortDescription(data, 'event');
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(data);
        }
        // Store results in Redux
        if (this.props.storeExists && this.props.storeResults) {
            this.props.setEventId(data.id);
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event_list_refresh: state.event_list_refresh
    };
};

const mapDispatchToProps = {
    setListStatus: setListStatus,
    setEventId: setEventId,
    setEventListInvited: setEventListInvited,
    setEventListOwn: setEventListOwn,
    setEventListRefresh: setEventListRefresh,
    setEventListSubscribed: setEventListSubscribed
};

APIEvents.propTypes = {
    getType: PropTypes.string,
    id: PropTypes.string,
    onResult: PropTypes.func,
    params: PropTypes.object,
    refresh: PropTypes.bool,
    storeExists: PropTypes.bool,
    storeResults: PropTypes.bool,
    viewType: PropTypes.string
};

APIEvents.defaultProps = {
    getType: 'list',
    params: {
        active: true
    },
    refresh: false,
    storeExists: true,
    storeResults: true,
    viewType: 'subscribed'
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIEvents);

export const US_STATES = [
    { id: 'AK', abbreviation: 'AK', name: 'Alaska' },
    { id: 'AL', abbreviation: 'AL', name: 'Alabama' },
    { id: 'AR', abbreviation: 'AR', name: 'Arkansas' },
    { id: 'AS', abbreviation: 'AS', name: 'American Samoa' },
    { id: 'AZ', abbreviation: 'AZ', name: 'Arizona' },
    { id: 'CA', abbreviation: 'CA', name: 'California' },
    { id: 'CO', abbreviation: 'CO', name: 'Colorado' },
    { id: 'CT', abbreviation: 'CT', name: 'Connecticut' },
    { id: 'DC', abbreviation: 'DC', name: 'District of Columbia' },
    { id: 'DE', abbreviation: 'DE', name: 'Delaware' },
    { id: 'FL', abbreviation: 'FL', name: 'Florida' },
    { id: 'GA', abbreviation: 'GA', name: 'Georgia' },
    { id: 'GU', abbreviation: 'GU', name: 'Guam' },
    { id: 'HI', abbreviation: 'HI', name: 'Hawaii' },
    { id: 'IA', abbreviation: 'IA', name: 'Iowa' },
    { id: 'ID', abbreviation: 'ID', name: 'Idaho' },
    { id: 'IL', abbreviation: 'IL', name: 'Illinois' },
    { id: 'IN', abbreviation: 'IN', name: 'Indiana' },
    { id: 'KS', abbreviation: 'KS', name: 'Kansas' },
    { id: 'KY', abbreviation: 'KY', name: 'Kentucky' },
    { id: 'LA', abbreviation: 'LA', name: 'Louisiana' },
    { id: 'MA', abbreviation: 'MA', name: 'Massachusetts' },
    { id: 'MD', abbreviation: 'MD', name: 'Maryland' },
    { id: 'ME', abbreviation: 'ME', name: 'Maine' },
    { id: 'MI', abbreviation: 'MI', name: 'Michigan' },
    { id: 'MN', abbreviation: 'MN', name: 'Minnesota' },
    { id: 'MO', abbreviation: 'MO', name: 'Missouri' },
    { id: 'MS', abbreviation: 'MS', name: 'Mississippi' },
    { id: 'MT', abbreviation: 'MT', name: 'Montana' },
    { id: 'NC', abbreviation: 'NC', name: 'North Carolina' },
    { id: 'ND', abbreviation: 'ND', name: 'North Dakota' },
    { id: 'NE', abbreviation: 'NE', name: 'Nebraska' },
    { id: 'NH', abbreviation: 'NH', name: 'New Hampshire' },
    { id: 'NJ', abbreviation: 'NJ', name: 'New Jersey' },
    { id: 'NM', abbreviation: 'NM', name: 'New Mexico' },
    { id: 'NV', abbreviation: 'NV', name: 'Nevada' },
    { id: 'NY', abbreviation: 'NY', name: 'New York' },
    { id: 'OH', abbreviation: 'OH', name: 'Ohio' },
    { id: 'OK', abbreviation: 'OK', name: 'Oklahoma' },
    { id: 'OR', abbreviation: 'OR', name: 'Oregon' },
    { id: 'PA', abbreviation: 'PA', name: 'Pennsylvania' },
    { id: 'PR', abbreviation: 'PR', name: 'Puerto Rico' },
    { id: 'RI', abbreviation: 'RI', name: 'Rhode Island' },
    { id: 'SC', abbreviation: 'SC', name: 'South Carolina' },
    { id: 'SD', abbreviation: 'SD', name: 'South Dakota' },
    { id: 'TN', abbreviation: 'TN', name: 'Tennessee' },
    { id: 'TX', abbreviation: 'TX', name: 'Texas' },
    { id: 'UT', abbreviation: 'UT', name: 'Utah' },
    { id: 'VA', abbreviation: 'VA', name: 'Virginia' },
    { id: 'VI', abbreviation: 'VI', name: 'Virgin Islands' },
    { id: 'VT', abbreviation: 'VT', name: 'Vermont' },
    { id: 'WA', abbreviation: 'WA', name: 'Washington' },
    { id: 'WI', abbreviation: 'WI', name: 'Wisconsin' },
    { id: 'WV', abbreviation: 'WV', name: 'West Virginia' },
    { id: 'WY', abbreviation: 'WY', name: 'Wyoming' }
];
import React, {useState, useCallback, useEffect} from 'react';
import {RESOURCES as resources} from '../resourcesData';
import {makeGetDataRequest} from '../utils/getRequest';
import {convertSizeToDisplayString} from '../utils/dataFunctions';

/**
 * Table of Volumes for Datasets
 */
const VolumeFileList = ({dataId, volumeName}) => {

    const [filesList, setFilesList] = useState([]);

    // Callback: Process directories and files recursively
    const processList = useCallback((data) => {

        const getItemContents = (contents) => {
            let list = [];
            const root = '/data/data-' + dataId;
            for (let i = 0; i < contents.length; i++) {
                const item = contents[i];
                if (item.type === 'file') {
                    item.displayName = item.name.replace(root, '');
                    item.size_string = convertSizeToDisplayString(item.size);
                    list.push(item);
                }
                if (item.type === 'directory' && item.contents?.length > 0) {
                    list.push(...getItemContents(item.contents));
                }
            }
            return list;
        };

        let list = [];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.type === 'directory' && item.name.indexOf('lost+found') === -1) {
                if (item.contents?.length > 0) {
                    list.push(...getItemContents(item.contents));
                }
            }
        }
        setFilesList(list);

    }, [dataId]);

    // Effect: Get directories and files for a Volume
    useEffect(() => {
        const params = {
            data_id: dataId,
            volume_name: volumeName
        };

        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'VolumeFile', 'GET_LIST', params);
            request.then(result => {
                if (result && result.length > 0) {
                    processList(result);
                    return Promise.resolve(result);
                } else {
                    console.log('Volumes Error: No Volumes');
                    return Promise.reject('No Volume Files');
                }
            }).catch(error => {
                console.log('Volume Files Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        if (dataId && volumeName) {
            return fetchData();
        }

    }, [dataId, volumeName, processList]);
    
    const setRows = () => {
        if (filesList.length === 0) {
            return (
                [
                    <span key="volume-file-empty">
                        No Files in this Volume
                    </span>
                ]
            )
        }

        return (
            filesList.map((item, index) => (
                <span className="volume-file" key={`volume-file-${item.displayName}-${index}`}>
                    <span className="file">
                        {item.displayName}
                    </span>
                    <span className="size">
                        {item.size_string}
                    </span>
                </span>
            ))
        )
    };

    const style = {
        display: 'block',
        fontSize: 15,
        textAlign: 'left',
        padding: '6px 10px 4px 10px'
    };

    return (
        <>
            <span style={style}>
                {[...setRows()]}
            </span>
        </>
    );
};

export default VolumeFileList;

import React, {Component} from 'react';

class LoggedOut extends Component {
    render() {
        return (
            <>
                <div className="content about">
                    <h1 className="page-title">
                        Please Log In
                    </h1>
                    <p>
                        (login)
                    </p>
                </div>
            </>
        )
    }
}

export default LoggedOut;

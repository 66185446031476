import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resources';
import {
    setChallengeListPublic,
    setChallengeListSubscribed,
    setChallengeListSubscribedRefresh,
    setListStatus,
    setProjectId
} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import {copyArray} from './dataFunctions';
import {populateShortDescription} from './projectFunctions';

/**
 * Component for retrieving Challenge Lists and single Challenge items
 */
class APIChallenges extends Component {

    componentDidMount() {
        const getType = this.props.getType;
        if (getType === 'list') {
            this.getChallenges();
        }
        if (getType === 'single') {
            this.getChallenge();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const getType = this.props.getType;
        if (this.props.refresh === true) {
            if (getType === 'list') {
                this.getChallenges();
            }
            if (getType === 'single') {
                this.getChallenge();
            }
        } else if (this.props.challenge_list_subscribed_refresh === true) {
            if (getType === 'list' && this.props.viewType === 'subscribed') {
                this.getChallenges();
            }
        }
    }


    /**
     * Get Challenges with optional parameters
     *
     */
    getChallenges = () => {
        let resourceName = 'ChallengePublic';
        if (this.props.viewType === 'subscribed') {
            resourceName = 'ChallengeUser'
        }
        const params = this.props.params;
        const request = makeGetDataRequest(resources, resourceName, 'GET_LIST', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Challenges Retrieval Error: ', result);
                    this.processList(null);
                    return Promise.resolve(result);
                }
                this.processList(result);
                return Promise.resolve(result);
            } else {
                console.log('Challenges Retrieval Error: No result');
                return Promise.reject('Challenges Retrieval failed');
            }
        }).catch(error => {
            console.log('Challenges Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Get single Challenge
     */
    getChallenge = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const request = makeGetDataRequest(resources, 'Challenge', 'GET_ONE', {id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Challenge Retrieval Error: ', result);
                    this.processSingle(null);
                    return Promise.resolve(result);
                }
                this.processSingle(result);
                return Promise.resolve(result);
            } else {
                console.log('Challenge Retrieval Error: No result');
                return Promise.reject('Challenge Retrieval failed');
            }
        }).catch(error => {
            console.log('Challenge Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Return and/or Store list data
     *
     * @param data
     */
    processList = (data) => {
        let list = [];
        if (data && data.length > 0) {
            list = copyArray(data);
            for (let i = 0; i < list.length; i++) {
                let c = list[i];
                c.short_desc = populateShortDescription(c);
            }
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(list);
        }
        // Store results in Redux
        if (this.props.storeExists && this.props.storeResults) {
            if (this.props.viewType === 'public') {
                this.props.setChallengeListPublic(list);
            }
            if (this.props.viewType === 'subscribed') {
                this.props.setChallengeListSubscribed(list);
                this.props.setListStatus({list: 'challenge_subscribed', status: true});
                this.props.setChallengeListSubscribedRefresh(false);
            }
        }
    };

    /**
     * Return single result
     *
     * @param data
     */
    processSingle = (data) => {
        if (data) {
            data.short_desc = populateShortDescription(data);
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(data);
        }
        // Store results in Redux
        if (this.props.storeExists && this.props.storeResults) {
            this.props.setProjectId(data.id);
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_subscribed_refresh: state.challenge_list_subscribed_refresh
    };
};

const mapDispatchToProps = {
    setChallengeListPublic: setChallengeListPublic,
    setChallengeListSubscribed: setChallengeListSubscribed,
    setChallengeListSubscribedRefresh: setChallengeListSubscribedRefresh,
    setListStatus: setListStatus,
    setProjectId: setProjectId
};

APIChallenges.propTypes = {
    getType: PropTypes.string,
    id: PropTypes.number,
    onResult: PropTypes.func,
    params: PropTypes.object,
    refresh: PropTypes.bool,
    storeExists: PropTypes.bool,
    storeResults: PropTypes.bool,
    viewType: PropTypes.string
};

APIChallenges.defaultProps = {
    getType: 'list',
    params: {
        active: true
    },
    refresh: false,
    storeExists: true,
    storeResults: true,
    viewType: 'public'
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIChallenges);

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resources} from '../../resourcesData';
import {makeDeleteDataRequest} from '../../utils/deleteRequest';
import DeleteIcon from '../../css/images/icon-Table-Delete.svg';
import Modal from '../modal/Modal';

const DeleteVolumeButton = ({record = {}, dataId, onFormEvent, disabled}) => {
    const [modalStatus, setModalStatus] = useState(false);

    const handleDeleteConfirmation = () => {
        const params = {
            data_id: dataId,
            volume_name: record.cell.row.original.name
        };

        const request = makeDeleteDataRequest(resources, 'Volume', 'DELETE', params);
        request.then(result => {
            if (result) {
                setModalStatus(false);
                if (typeof result === 'string') {
                    console.log('Volume Deletion Error: ', result);
                    return Promise.resolve(result);
                }
                if (result?.message?.indexOf('marked') !== -1) {
                    if (typeof onFormEvent === 'function') {
                        onFormEvent({type: 'volumes', state: 'complete'});
                    }
                }
                return Promise.resolve(result);
            } else {
                console.log('Volume Error: No volume');
                return Promise.reject('Volume Deletion failed');
            }
        }).catch(error => {
            console.log('Volume Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    return (
        <>
            <div
                className={`btn-icon x-table-icon${(disabled) ? ' disabled' : ''}`}
                onClick={() => setModalStatus(true)}
            >
                <img src={DeleteIcon} alt="Delete Icon"/>
            </div>
            <Modal
                allowClose={true}
                id="modal-volume-delete"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">Are you sure you want to mark this Volume for deletion?</h3>
                    <p>Volume: {record.cell.row.original.name}</p>
                    <p>Note: Because Xrathus workspaces may be using this Volume, it will not immediately be deleted, but placed in a queue for deletion.</p>
                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleDeleteConfirmation}>YES</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

DeleteVolumeButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object
};

export default DeleteVolumeButton;
import React, {Component} from 'react';
import AAPGLogo from '../css/images/AAPG-Logo.png';
import BizHubBAMLogo from '../css/images/BizHub-BAM-logo.png';
import CriterionLogo from '../css/images/Criterion-Logo.jpg';
import SEGLogo from '../css/images/SEG-SoEG-logo.png';

/**
 * Component to display a grid of Organization logos
 *
 */
class HomeOrganizations extends Component {
    render() {
        return (
            <div className="home-organizations">
                <div className="organization">
                    <img className="logo square bizhub" src={BizHubBAMLogo} alt="BizHub"/>
                </div>
                <div className="organization">
                    <img className="logo horizontal criterion" src={CriterionLogo} alt="Criterion"/>
                </div>
                <div className="organization">
                    <img className="logo horizontal seg" src={SEGLogo} alt="Society of Exploration Geophysicists"/>
                </div>
                <div className="organization">
                    <img className="logo horizontal aapg" src={AAPGLogo} alt="AAPG"/>
                </div>
            </div>
        )
    }
}

export default HomeOrganizations;

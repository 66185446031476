import React, {Component} from 'react';
import {connect} from 'react-redux';
import {extractDiscourseId, getAllForumCategoryLinks} from '../utils/dataFunctions';
import ClusterTable from '../partials/ClusterTable';

const forumPaths = getAllForumCategoryLinks();

/**
 * A component to retrieve and display Discourse Topics by Persona
 */
class DashboardClustersPersona extends Component {
    constructor(props) {
        super(props);
        const personaId = (props.user?.persona?.id) ? extractDiscourseId(forumPaths, props.user.persona.id) : null;
        this.state = {
            discourseIdPersona: personaId
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Extract Discourse ID from user Persona
        let persona = nextProps.user?.persona?.id ?? null;
        if (persona && !prevState.discourseIdPersona) {
            const personaId = extractDiscourseId(forumPaths, persona);
            return {
                discourseIdPersona: personaId
            }
        }
        return null
    }

    render() {
        const {
            discourseIdPersona
        } = this.state;

        return (
            <>
                {discourseIdPersona &&
                <div className="panel no-padding">
                    <h3 className="header">Relevant for you</h3>
                    <ClusterTable discourseId={discourseIdPersona} height="auto"/>
                </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(
    mapStateToProps
)(DashboardClustersPersona);

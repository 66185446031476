import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import {inUserList} from '../../utils/projectFunctions';
import APIAirmeetSessions from '../../utils/APIAirmeetSessions';
import APIChallenges from '../../utils/APIChallenges';
import LoadingIndicator from '../../partials/LoadingIndicator';
import {airmeetStyles} from '../../utils/stylesAirmeet';

/**
 * Page: Airmeet embed for specified Challenge
 */
class XConnectChallengeAirmeet extends Component {
    constructor(props) {
        super(props);
        // Get Challenge ID from path
        const pathArr = this.props.location.pathname.split('/');
        const challengeIdx = pathArr.findIndex(c => c === 'challenge');
        const challengeId = Number(pathArr[challengeIdx + 1]);
        const hasUserList = !!(this.props.challenge_list_subscribed?.length);
        
        // Get Airmeet type and UUID from path
        const airmeetIdx = pathArr.findIndex(c => c === 'rtc');
        let airmeetType = pathArr[airmeetIdx + 1];
        let airmeetId = pathArr[airmeetIdx + 2];

        this.state = {
            challenge: null,
            challengeId: challengeId,
            challengeTab: 'overview',
            airmeetType: airmeetType,
            airmeetId: airmeetId,
            airmeetUrl: null,
            hasUserList: hasUserList
        };
    }

    // Store Challenge and set the Airmeet URL
    setChallenge = (data) => {
        this.setState({
            challenge: data
        }, () => {
            if (this.state.airmeetType === 'e') {
                this.setAirmeetURL(null);
            }
        });
    };

    // Set the Airmeet URL from the Event or Session Entry Link
    setAirmeetURL = (sessions) => {
        let url;
        if (sessions) {
            const session = sessions.find(r => r.session_id === this.state.airmeetId);
            url = session?.entry_link ?? null;
        } else {
            url = this.state.challenge?.airmeet_entry_link ?? null;
        }
        this.setState({
            airmeetUrl: url
        });
    };

    render() {
        const {
            challenge_list_subscribed,
            user
        } = this.props;

        const {
            airmeetType,
            airmeetUrl,
            challenge,
            challengeId
        } = this.state;

        const inSubscribedChallenges = (inUserList(challenge_list_subscribed, challengeId));

        return (
            <div className="content xhub project">
                {/* User is not sysop or has not joined Challenge */}
                {!user.is_sysop && !inSubscribedChallenges &&
                    <>
                        <div className="row">
                            <div className="col col-xs-12">
                                <h1 className="page-title" style={airmeetStyles.title}>
                                    {(challenge && challenge.title) ? challenge.title : 'Challenge'}
                                </h1>
                                <Link className="btn btn-primary mt-1 mr-0"
                                      to={`/x/hub/challenge/${challengeId}/overview`}>
                                    Back to Challenge
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-xs-12" key="projects-empty">
                                <div className="panel">
                                    You do not have permission to access this Challenge's Airmeet
                                    Events or Sessions.
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* User is sysop or has joined Challenge */}
                {(user.is_sysop || inSubscribedChallenges) &&
                    <>
                        {!challengeId &&
                            <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                        }

                        {challenge && challengeId &&
                            <>
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h1 className="page-title" style={airmeetStyles.title}>
                                            {(challenge.title) ? challenge.title : 'Challenge'}
                                        </h1>
                                        <Link className="btn btn-primary mt-1 mr-0"
                                              to={`/x/hub/challenge/${challengeId}/overview`}>
                                            Back to Challenge
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-xs-12" style={airmeetStyles.container}>
                                        {airmeetUrl &&
                                            <Iframe
                                                id="Airmeet"
                                                url={airmeetUrl}
                                                display="flex"
                                                frameBorder="0"
                                                width="100%"
                                                height="100%"
                                                style={airmeetStyles.iframe}
                                                allow="camera *;microphone *;display-capture *;fullscreen *;"
                                            />
                                        }
                                    </div>
                                </div>

                                {airmeetType === 's' &&
                                    <APIAirmeetSessions
                                        id={challengeId}
                                        onResult={(e) => this.setAirmeetURL(e)}
                                        refresh={false}
                                    />
                                }
                            </>
                        }

                        <APIChallenges
                            getType="single"
                            id={challengeId}
                            onResult={this.setChallenge}
                            refresh={false}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_subscribed: state.challenge_list_subscribed,
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(XConnectChallengeAirmeet)
);

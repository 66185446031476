import React, {Component} from 'react';
import HomeHeroBG from '../css/images/Home-Hero-bg.jpg';

const homeHeroStyle = {
    backgroundImage: `url(${HomeHeroBG})`
};

class HomeHero extends Component {
    render() {
        return (
            <div className="row">
                <div className="col col-xs-12">
                    <div
                        className="panel full center-content column home-hero no-border no-margin"
                        style={homeHeroStyle}
                    >
                        <h2>
                            Join the digital ecosystem where
                            <span className="break desktop"> </span>
                            productive collaboration happens.
                        </h2>
                        <p>
                            Xrathus is your venue for collaborating productively across
                            <span className="break desktop"> </span>
                            time zones and organizational boundaries.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeHero;

import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resourcesEvent';
import {setEventConfigCurrent} from '../../store/actions';
import {makePostDataRequest} from '../../utils/postRequest';
import NumberInput from '../fields/NumberInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';

/**
 * Form: Edit Event Configurations
 *
 * @param basePath
 * @param defaultConfig
 * @param eventType
 * @param eventConfig
 * @param eventId
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormEventConfigEdit = (
    {
        basePath = '',
        defaultConfig,
        eventType,
        eventConfig,
        eventId,
        ...props
    }) => {

    // Set up form and state
    const {register, errors, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    const [eventValues, setEventValues] = useState(eventConfig.max_shares ?? defaultConfig.max_shares);

    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            saving: false,
            success: false
        }
    );

    const setDefaultConfigMessage = (type) => {
        if (defaultConfig[type]) {
            return ' The default is ' + defaultConfig[type] + '.';
        }
        return '';
    };

    // Callback: Set Configuration values and update form values
    const updateConfigurations = useCallback((data, defaultConfig) => {
        setValue('max_shares', data?.max_shares ?? defaultConfig.max_shares);
    }, [setValue]);

    // Effect: Get Event data
    useEffect(() => {
        const updateData = () => {
            setEventValues(eventConfig);
            props.setEventConfigCurrent({
                max_shares: eventConfig.max_shares ?? defaultConfig.max_shares,
            });
        };

        return updateData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, eventConfig, defaultConfig]);

    // Effect: Update Event Configuration values when global values are populated
    useEffect(
        () => {
            const updateConfig = () => {
                const max_shares = eventValues?.max_shares ?? defaultConfig.max_shares;
                let config = {
                    max_shares: (!max_shares || max_shares === '') ? defaultConfig.max_shares : max_shares,
                };
                updateConfigurations(config, defaultConfig);
            };

            return updateConfig();

        }, [defaultConfig, eventValues, updateConfigurations]
    );

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            getValues('max_shares') === '' ||
            (errors &&
                (
                    errors.max_shares
                ))
        );
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // Set parameters from form state
            data.id = eventId;

            // Submit form to API
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const params = {
            id: data.id,
            value: data.max_shares
        }
        const request = makePostDataRequest(resources, 'EventPersonalUpdateMaxShares', 'PUT', params);
        request.then(result => {
            if (result && result.id) {
                updateSuccessState({saving: false, success: true, message: ''});
                // Store Event Configuration for Dataset
                props.setEventConfigCurrent({
                    max_shares: result.max_shares ?? null,
                });
                return Promise.resolve(result);
            } else {
                updateSuccessState({
                    saving: false,
                    success: false,
                    message: 'Sorry, Event Configurations could not be updated.'
                });
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Update Event Configurations Error: ', error);
            updateSuccessState({
                saving: false,
                success: false,
                message: 'Sorry, there was an error updating Event Configurations: ' + error
            });
            return Promise.reject('Server Error');
        });
    };

    const eventPath = (eventType === 'personal' || eventType === 'personal_own') ? 'event/personal' : 'event/public';

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">
                <div className="item-content full">
                    <div className="tab-content active">
                        <p className="instructions">
                            If this Event needs more shares outside of the Global Configuration and User Configuration,
                            please update here.
                        </p>
                        <div className="row">
                            <div className="form-group col col-xs-6">
                                <NumberInput
                                    id="config-max-shares"
                                    name="max_shares"
                                    label="Max Number of Shares"
                                    instructions={`The maximum number of times this Event may be shared with other users.${setDefaultConfigMessage('max_shares')}`}
                                    min={0}
                                    max={1000}
                                    step={1}
                                    defaultValue={eventValues}
                                    aria-describedby="error-max_shares-required"
                                    aria-invalid={errors.max_shares ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    className={`form-input ${errors.max_shares ? 'input-error' : ''}`}
                                >
                                    {errors.max_shares && errors.max_shares.message}
                                </NumberInput>
                            </div>
                        </div>
                    </div>

                    <div className="form-btns right mb-1">
                        {successState.saving &&
                            <>
                                <p className="message">
                                    Saving Event Configurations...
                                </p>
                                <LoadingIndicator color="dark" centered={false} active={true}/>
                            </>
                        }
                        {successState.success &&
                            <p className="message">
                                Event Configurations updated.
                            </p>
                        }
                        {!successState.success && successState.message !== '' &&
                            <p className="message">
                                {successState.message}
                            </p>
                        }
                        <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                            Update Event Configuration
                        </button>
                    </div>
                    <div className="form-btns right mb-1">
                        {successState.success &&
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/connect/${eventPath}/${eventId}/overview`}>
                                View Event Details
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        event_config: state.event_config,
        event_list_own: state.event_list_own,
        user: state.user
    };
};

const mapDispatchToProps = {
    setEventConfigCurrent: setEventConfigCurrent
};

FormEventConfigEdit.propTypes = {
    basePath: PropTypes.string,
    defaultConfig: PropTypes.any,
    eventType: PropTypes.any,
    eventConfig: PropTypes.any,
    eventId: PropTypes.any,
    setEventConfigCurrent: PropTypes.any,
    user: PropTypes.any,
    event_list_own: PropTypes.any,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormEventConfigEdit);
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FileDatasetListItem extends Component {

    render() {
        const {
            item
        } = this.props;

        /*
content_type: "file"
failed: false
message: ""
modified: "2020-07-29T00:12:30+0000"
object: "/drag folder one/project files/secondary folder/tertiary folder/bootstrap.css"
size: 145933
 */

        return (
            <span className="file-list-item">
                <span className="name">
                    {item.object}
                </span>
            </span>
        )
    }
}

FileDatasetListItem.propTypes = {
    item: PropTypes.object,
    name: PropTypes.string,
    path: PropTypes.string,
};

FileDatasetListItem.defaultProps = {
    item: {}
};

export default FileDatasetListItem;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PlayButton from '../../../css/images/Overlay-play.png';
import ModalVideo from 'react-modal-video';
import '../../../css/modal-video.css';

/**
 * Video Item (grid)
 *
 * @param id
 */
const YoutubeVideoTile= ({
    id,
    name,
}) => {
    const [modalStatus, setModalStatus] = useState(false);

    const openModal = () => {
        //reset();
        setModalStatus(true);
    };

    const closeModal = () => {
        //reset();
        setModalStatus(false);
    };

    return (
        <>
            <div className="video-thumbnail">
                <div className="tile" onClick={openModal}>
                    <div className="image-stack">
                        <div className="image-stack__item image-stack__item--top">
                            <img src={PlayButton} alt="Play Video"
                                className="video thumbnail"/>
                        </div>
                        <div className="image-stack__item image-stack__item--bottom">
                            <img src={"https://img.youtube.com/vi/"+id+"/0.jpg"} alt=""
                                className="video thumbnail"/>
                        </div>
                    </div>
                    <div className="video-name">
                        {name}
                    </div>
                </div>
            </div>
            <ModalVideo key={id} channel='youtube' isOpen={modalStatus} 
                videoId={id} youtube={{autoplay:1}} onClose={()=> closeModal()} />
        </>
    );
};

YoutubeVideoTile.propTypes = {
    /**
     * ID of the video, as seen in the youtube url
     */
    id: PropTypes.string,
    /**
     * Name of the video
     */
    name: PropTypes.string,
    /**
     * Function for what happens on clicking the video
     */
    open: PropTypes.any,
};

YoutubeVideoTile.defaultProps = {
    id: "",
    name: "",
    open: null,
};

export default YoutubeVideoTile;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SectionImage from '../css/images/Substrates-Section-Training.jpg';

class SubstrateSectionTraining extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Training Substrate'
            });
        }
    };

    render() {
        return (
            <>
                <div id="training" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-40 row substrate-section bg-page-color-training">
                            <div className="col col-xs-12 col-sm-5 col-md-4 vertical-center">
                                <img className="substrate-section-image" src={SectionImage} alt="Training"/>
                            </div>
                            <div className="col col-xs-12 col-sm-7 col-sm-8 vertical-center">
                                <h2>Sharing Knowledge.<br/>Developing People.</h2>
                                <p>
                                    The <strong>Training</strong> substrate provides tools for
                                    course creators and administrators to set up and deliver
                                    cross-domain learning activities and events.
                                </p>
                                <p>
                                    By integrating with the <strong>Connect</strong> substrate,
                                    this helps you share knowledge and empower people to take
                                    your ideas further.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-training btn-arrow table uppercase pull-right mt-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Show Me How
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

SubstrateSectionTraining.propTypes = {
    onClickFunc: PropTypes.func
};

export default SubstrateSectionTraining;

import React, { useState, useEffect, useRef } from 'react'; 
import ReactMarkdown from 'react-markdown';
import CollaboratorHeader from '../partials/storyblok/page-sections/CollaboratorHeader';
import ContentRow from '../partials/storyblok/containers/ContentRow';
import Panel from '../partials/storyblok/containers/Panel';
import Button from '../partials/storyblok/basic/Button';
import NextEventWidget from '../partials/storyblok/page-sections/NextEvent';
import ContentList from '../partials/storyblok/containers/ContentList';
import CalendarGeneric from '../partials/calendar/CalendarGeneric';
import SectionHeader from "../partials/storyblok/basic/SectionHeader";
import SectionWithHeader from "../partials/storyblok/containers/SectionWithHeader";
import TermLink from "../partials/storyblok/basic/TermLink";
import Storyblok, { StoryblokAsset } from './storyblok-client';
import StoryblokAssetFetcher from '../partials/storyblok/content-fetcher/StoryblokAssetFetcher';
import YoutubeVideoTile from '../partials/storyblok/tiles/YoutubeVideoTile';
import KnowledgeBaseTile from '../partials/storyblok/tiles/KnowledgeBaseTile';
import SolutionTile from '../partials/storyblok/tiles/SolutionTile';
import XrathusTile from '../partials/storyblok/tiles/XrathusTile';
import StoryblokWebpage from '../partials/storyblok/pages/StoryblokWebpage';
import StoryblokDocument from '../partials/storyblok/pages/StoryblokDocument';
import NotFound from '../routes/NotFound';
import {hasGroup} from './tokenFunctions';



/**
 * Parse story content fetched from Storyblok
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
export const parseStoryblokContent = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    if (content) {
        if (content.constructor === Object && "component" in content) {
            return parseStoryblokDefinedComponent(content, contentType, className, allStories);
        }
        else if (content.constructor === Object && "type" in content) {
            return parseStoryblokTypeDefinedContent(content, contentType, className, allStories);
        }
        else if (Array.isArray(content)) {
            return parseStoryblokArray(content, contentType, className, allStories);
        }
        //@TODO: may not be the safest way to display the markdown content
        else {
            return <ReactMarkdown source={String(content)} escapeHtml={false}/>;
        }
    }
    return null;
};


/**
 * Parse a defined Storyblok Component
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
const parseStoryblokDefinedComponent = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    let customClass = className;
    let editable = null;
    switch (content.component) {
        case "Highlight":
            customClass = content.custom_css ? content.custom_css : "";
            return (
                <div key={content._uid}>
                    {editable}
                    <div >
                        {content.title ?
                            <h3>{content.title}</h3> :
                            null
                        }
                        {
                            parseStoryblokContent(content.content, contentType, customClass, allStories)
                        }
                    </div>
                </div>
            );
        case "Preformatted Content":
        
            customClass = content.custom_css ? content.custom_css : "";
            return (
                <div key={content._uid} className="pref">
                    {editable}
                    <div >
                        {parseStoryblokContent(content.content, contentType, customClass, allStories)}   
                    </div>
                </div>
            );
        case "BlogPost":
            customClass = content.custom_css ? content.custom_css : "";
            if ("content" in content) {
                return (
                    <div key={content._uid} className="pref">
                        {editable}
                        <div >
                            {parseStoryblokContent(content.content, contentType, customClass, allStories)}
                        </div>
                    </div>
                );
            }
            break;
        case "Collaborator Header":
            return (
                <CollaboratorHeader 
                    content={content.content} 
                    panelCustomClass={content.panel_custom_css}
                    panelLabel={content.panel_label}
                    labelCustomClass={content.label_custom_css}
                    showBorder={content.show_border}
                    buttons={content.buttons}
                    displayCalendar={content.display_calendar}
                    calendarTags={content.calendar_tags}
                    extraContent={content.extra_content}
                    allEvents={allStories.allEvents}
                    editableStoryblok={content._editable}
                    key={content._uid}
                />
            );
        case "Document Header":
            return (
                <div key={content._uid}>
                    {editable}
                    <div >
                        {parseStoryblokContent(content.content, contentType, "header-section", allStories)}
                    </div>
                </div>
            );
        case "Panel":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <Panel 
                            content={content.content}
                            panelCustomClass={content.panel_custom_css}
                            panelCustomStyle={content.panel_custom_style}
                            label={content.label}
                            labelCustomClass={content.label_custom_css}
                            labelCustomStyle={content.label_custom_style}
                            showBorder={content.show_border}
                            allStories={allStories} 
                            storyblokFormatted={true}
                            key={content._uid}/>
                    </div>
                </div>
            );
        case "Content Row":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <ContentRow 
                            contentData={content} 
                            content={content.content}
                            isVertical={content.is_vertical}
                            useCols={content.use_cols}
                            colWidths={content.col_widths}
                            alignment={content.alignment}
                            allStories={allStories} 
                            storyblokFormatted={true}
                            key={content._uid}/>
                    </div>
                </div>
            );
        case "Button":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <Button 
                            title={content.title}
                            isExternal={content.is_external}
                            link={content.link}
                            customClass={content.custom_css}
                            demoRouter={content.demo_router}
                            key={content._uid}/>
                    </div>
                </div>
            );
        case "Next Event Widget":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <NextEventWidget contentData={content} allEvents={allStories.allEvents} key={content._uid}/>
                    </div>
                    
                </div>
            );
        case "Scrollable List":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <ContentList
                            isVertical={content.is_vertical}
                            is2D={content.is_2d}
                            useSlides={content.use_slides}
                            listType={content.list_type}
                            tags={content.tags}
                            playlistLink={content.playlist_link}
                            slidesToShow={Number(content.slides_to_show)}
                            infiniteSlides={content.infinite_slides}
                            customNullText={content.custom_null_text}
                            sortByPriority={content.sort_by_priority}
                            sortedBy={content.sorted_by}
                            content={content.content}
                            allStories={allStories}
                            storyblokFormatted={true}
                            demoRouter={content.demo_router}
                            key={content._uid}/>
                    </div>
                </div>
            );
        case "Calendar":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <CalendarGeneric allEvents={allStories.allEvents} tags={content.tags} key={content._uid}/>
                    </div>
                </div>
            );
        case "Section Header":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <SectionHeader 
                            title={content.title} 
                            style={(content.style==='') ? 'primary' : content.style}
                            toggleButtonText={content.toggle_button_text}
                            toggleCustomCss={content.toggle_custom_css}
                            toggleContent={content.toggle_content}
                        />
                    </div>
                </div>
            );
        case "Section with Header":
            return (
                <div key={content._uid}>
                    {editable}
                    <div>
                        <SectionWithHeader 
                            title={content.title} 
                            style={(content.title_style==='') ? 'document-primary' : content.title_style} 
                            hideTitle={content.hide_title}
                            content={content.content}
                            storyblokFormatted={true}
                            toggleButtonText={content.toggle_button_text}
                            toggleCustomCss={content.toggle_custom_css}
                            toggleContent={content.toggle_content}
                        />
                    </div>
                </div>
            );
        case "IFrame":
            return (
                <div key={content._uid}>
                    <StoryblokAssetFetcher assetType={'iframe'} link={content.content.filename}/>
                </div>
            );
        case "Image":
            //@TODO: add alt
            if (content.height && content.width)
                return (
                    <div key={content._uid}>
                        {editable}
                        <div style={{maxHeight: content.height+'px', maxWidth: content.width+'px',}}>
                            <StoryblokAssetFetcher assetType={'image'} customCss={content.custom_css} 
                                link={content.content.filename} alt={content.alt}
                                height={content.height} width={content.width}
                                buttonLink={content.link} isInternal={content.is_internal}/>
                        </div>
                    </div>
                );
            else if (content.height)
                return (
                    <div key={content._uid}>
                        {editable}
                        <div style={{maxHeight: content.height+'px'}}>
                            <StoryblokAssetFetcher assetType={'image'} customCss={content.custom_css} 
                                link={content.content.filename} alt={content.alt}
                                height={content.height}
                                buttonLink={content.link} isInternal={content.is_internal}/> 
                        </div>
                    </div>
                );
            else if (content.width)
                return (
                    <div key={content._uid}>
                        {editable}
                        <div style={{maxWidth: content.width+'px',}}>
                            <StoryblokAssetFetcher assetType={'image'} customCss={content.custom_css} 
                                link={content.content.filename} alt={content.alt}
                                width={content.width}
                                buttonLink={content.link} isInternal={content.is_internal}/>
                        </div>
                    </div>
                );
            else
                return (
                    <div key={content._uid}>
                        {editable}
                        <div>
                            <StoryblokAssetFetcher assetType={'image'} customCss={content.custom_css} 
                                link={content.content.filename} alt={content.alt}
                                buttonLink={content.link} isInternal={content.is_internal}/>
                        </div>
                    </div>
                );
        case "New Line":
            return (
                <div>&nbsp;<br/>&nbsp;
                </div>
            );
        default:
            return null;
    }
};


/**
 * Parse Storyblok Content defined by built-in Storyblok type
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
// eslint-disable-next-line no-unused-vars
const parseStoryblokTypeDefinedContent = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    switch (content.type) {
        case "text":
            if (!("marks" in content))
                return content.text;
            else {
                //@TODO: Attempt to replace  with a Markdown interpreter
                return parseTextStyle(content.text, content.marks, className);
            }
        case "heading":
            if (!("attrs" in content))
                return parseStoryblokContent(content.content, content.type, className);
            else {
                //@TODO: Attempt to replace  with a Markdown interpreter
                return parseHeadingStyle(content.content, content.attrs, className);
            }
        case "image":
            //@TODO: change alt to something meaningful
            if (content.height && content.width)
                return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height} width={content.width}></img>);
            else if (content.height)
                return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height}></img>);
            else if (content.width)
                return (<img alt='' key={content.attrs.src} src={content.attrs.src} width={content.width}></img>);
            else
                return (<img alt='' key={content.attrs.src} src={content.attrs.src}></img>);
        case "bullet_list":
            if ("content" in content) {
                return <ul className={className} key={content._uid}>
                    {parseStoryblokContent(content.content, content.type, className)}
                </ul>;
            }
            return null;
        case "paragraph":
            if ("content" in content) {
                //find unique key
                let tempUniqueKey = content._uid;
                return <p className={className} key={tempUniqueKey}>
                    {parseStoryblokContent(content.content, content.type, className)}
                </p>;
            }
            return null;
        case "horizontal_rule":
            return <hr className='horizontal-rule'/>;
        case "ordered_list":
            if ("content" in content) {
                return <ol className={className} key={content._uid}>
                    {parseStoryblokContent(content.content, content.type, className)}
                </ol>;
            }
            return null;
        case "doc":
        default:
            if ("content" in content) {
                return parseStoryblokContent(content.content, content.type, className);
            }
            return null;
    }
};


/**
 * Parse Storyblok Array of content
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
const parseStoryblokArray = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    switch (contentType) {
        case "bullet_list":
        case "ordered_list":
            return content.map((item, i) => (
                <li key={i}>
                    {parseStoryblokContent(item, contentType, className, allStories)}
                </li>
            ));
                
        case "paragraph":
            return content.map((item, i) => (
                <span key={i}>
                    {parseStoryblokContent(item, contentType, className, allStories)}
                </span>
            ));
        default:
            return content.map((item, i) => (
                <span key={i}>
                    {parseStoryblokContent(item, contentType, className, allStories)}
                </span>
            ));
    }
};


/**
 * Parse story content fetched from Storyblok
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
export const parseStoryblokContentForHeadings = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    if (content) {
        if (content.constructor === Object && "component" in content) {
            return parseStoryblokDefinedComponentForHeadings(content, contentType, className, allStories);
        }
        else if (content.constructor === Object && "type" in content) {
            return null;
        }
        else if (Array.isArray(content)) {
            return parseStoryblokArrayForHeadings(content, contentType, className, allStories);
        }
        //@TODO: may not be the safest way to display the markdown content
        else return <ReactMarkdown source={content} escapeHtml={false}/>;
    }
    return '';
};


/**
 * Parse a defined Storyblok Component for Headers
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
// eslint-disable-next-line no-unused-vars
const parseStoryblokDefinedComponentForHeadings = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    //let customClass = className;
    //let editable = null;
    let tempHeader = '';
    let extraResult = '';
    let finalResult = '';
    switch (content.component) {
        case "Section Header":
            switch (content.style) {
                case 'document':
                case 'document-primary':
                    tempHeader = '\n# '+content.title;
                    break;
                case 'document-subheader':
                case 'document-secondary':
                    tempHeader = '\n## '+content.title;
                    break;
                case 'document-tertiary':
                    tempHeader = '\n### '+content.title;
                    break;
                default:
                    tempHeader = '\n# '+content.title;
            }
            extraResult = parseStoryblokContentForHeadings(content.content, "", "", []);
            if (extraResult) extraResult=extraResult.join('');
            finalResult = tempHeader+extraResult+"\n";
            return (
                finalResult
            );
        case "Section with Header":
            switch (content.title_style) {
                case 'document':
                case 'document-primary':
                case 'document-primary-toggle':
                    tempHeader = '\n# '+content.title;
                    break;
                case 'document-subheader':
                case 'document-secondary':
                case 'document-secondary-toggle':
                    tempHeader = '\n## '+content.title;
                    break;
                case 'document-tertiary':
                case 'document-tertiary-toggle':
                    tempHeader = '\n### '+content.title;
                    break;
                default:
                    tempHeader = '\n# '+content.title;
            }
            extraResult = parseStoryblokContentForHeadings(content.content, "", "", []);
            if (extraResult) extraResult=extraResult.join('');
            finalResult = tempHeader+extraResult+"\n";
            return (
                finalResult
            );
        default:
            return '';
    }
};


/**
 * Parse Storyblok Array of content
 *
 * @param content
 * @param contentType
 * @param className
 * @param allStories
 * @return {any | null}
 */
const parseStoryblokArrayForHeadings = ( content, contentType="", className="", allStories={allBlogPosts:[], allDocuments: [], allTopics:[], allEvents:[], allHighlights:[], allPeople:[], allVideos:[]}) => {
    return content.map((item) => (
        parseStoryblokContentForHeadings(item, contentType, className, allStories)
    ));
};


/**
 * Get objects from list of Storyblok objects that are tagged
 *
 * @param list
 * @param tags
 * @return {array | null}
 */
export const getTaggedData = (list, tags) => {
    if (!Array.isArray(list)) {
        return null;
    }

    let allTags = tags ? tags.split(" ") : "";
    let relevantList = [];
    //sort out anything without the proper tag
    if (allTags !== "") {
        for (let i = 0; i < allTags.length; i++) {
            relevantList = relevantList.concat(list.filter(element => (element.tags ? element.tags.indexOf(allTags[i]) : -1) !== -1));
        }
        //remove duplicates
        let tempCheck = new Set();
        relevantList = relevantList.filter(obj => !tempCheck.has(obj) && tempCheck.add(obj));

        return relevantList;
    }
    else {
        //alternatively, return []
        return list; 
    }
};


/**
 * Get string date and time from a Storyblok-formatted date object
 *
 * @param passedProps
 * @return {array}
 */
export const getDisplayedDateAndTime = ( passedProps ) => {
    let tempTimeStart = new Date(passedProps.start_date+" UTC").toLocaleTimeString();
    let tempTimeEnd = new Date(passedProps.end_date+" UTC").toLocaleTimeString();
    let tempDispTime = tempTimeStart.substring(0,tempTimeStart.lastIndexOf(":")) + " " +
        tempTimeStart.substring(tempTimeStart.lastIndexOf(" ")) + " - " + 
        tempTimeEnd.substring(0, tempTimeEnd.lastIndexOf(":")) + " " +
        tempTimeEnd.substring(tempTimeEnd.lastIndexOf(" "));
    let tempDateStart = new Date(passedProps.start_date+" UTC").toDateString().substring(4);
    let tempDispDate = tempDateStart.substring(0,tempTimeStart.lastIndexOf(" ")-1) + ", " + 
        tempDateStart.substring(tempTimeStart.lastIndexOf(" ")-1);

    return [tempDispDate, tempDispTime];
};


/**
 * Parse a Storyblok-styled heading
 *
 * @param content
 * @param style
 * @param className
 * @return {array | null}
 */
export const parseHeadingStyle = (content, style, className="" ) => {
    if (Array.isArray(style)) {
        for (let i = 0; i < style.length; i++) {
            content = parseHeadingStyle(content, style[i].level, className);
        }
        return content;
    }
    else {
        let tempStyle = style;
        if ('level' in style) {
            tempStyle = style.level;
        }
        switch (tempStyle) {
            case 1:
                return (<h1>{parseStoryblokContent(content, "", className)}</h1>);
            case 2:
                return (<h2>{parseStoryblokContent(content, "", className)}</h2>);
            case 3:
                return (<h3>{parseStoryblokContent(content, "", className)}</h3>);
            case 4:
                return (<h4>{parseStoryblokContent(content, "", className)}</h4>);
            case 5:
                return (<h5>{parseStoryblokContent(content, "", className)}</h5>);
            case 6:
                return (<h6>{parseStoryblokContent(content, "", className)}</h6>);
            default: 
                return (<h6>{parseStoryblokContent(content, "", className)}</h6>);
        }
    }
};


/**
 * Parse a Storyblok-styled text
 *
 * @param content
 * @param style
 * @param className
 * @param uniqueId
 * @return {array | null}
 */
export const parseTextStyle = (content, style, className="", uniqueId="", attrs=[]) => {
    if (Array.isArray(style)) {
        for (let i = 0; i < style.length; i++) {
            if ("attrs" in style[i]) {
                content = parseTextStyle(content, style[i].type, className, uniqueId, style[i].attrs);
            }
            else {
                content = parseTextStyle(content, style[i].type, className, uniqueId);
            }
        }
        return content;
    }
    else {
        let term = null;
        switch (style) {
            case "italic":
                return (<i key={content}>{content}</i>);
            case "bold":
                return (<strong key={content}>{content}</strong>);
            case "link":
                if (attrs.href.substring(0,10) === "datasource") {
                    const splitLink = attrs.href.split(".");
                    if (splitLink[2]) {
                        term = splitLink[2];
                    }
                }
                return (
                    term ?
                        <TermLink term={term} displayedTerm={content}/>
                        :
                        <a href={attrs.href}
                            title={content} target={attrs.target} rel="noopener noreferrer">
                            {content}
                        </a>
                );
            default:
                return (<span>{content}</span>);
        }
    }
};


/**
 * Get the Renderer for a list by list type
 *
 * @param listType
 */
export function getListRenderer( listType ) {
    let ListRenderer = null;

    switch (listType) {
        /*
        case 'vertical':
            ListRenderer = () => {
                return (
                    null
                );
            };
            break;
        */
        //case 'horizontal-slides':
        /*
            ListRenderer = (content) => {
                const refKey = useRef();
                function updateSliderWidth(){

                }
                window.addEventListener("resize", this.updateSliderWidth.bind(this));
                useEffect(() => {
                    window.addEventListener("keyup", eventhandler);
                    return () => {
                        window.removeEventListener("keyup", eventhandler);
                    };
                }, []);
                return (
                    <div ref={this.sliderList}>
                        <Slider {...listProps}>
                            {content}
                        </Slider>
                    </div>
                );
            };
            break;
            */
        case 'vertical':
        case 'horizontal':
        case 'horizontal-slides':
        case 'grid':
        default:
            // eslint-disable-next-line react/display-name, react/prop-types
            ListRenderer = ({children}) => {
                return (
                    <div className="content">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                );
            };
    }

    return ListRenderer;
}


/**
 * Get the Renderer for a single item type, usually for a list of items
 *
 * @param itemType
 */
export function getListItemRenderer( itemType ) {
    let ItemRenderer = null;


    switch (itemType) {
        case 'events':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={itemContent._uid}>
                            <br/>
                            {itemContent.title}
                            <hr/>&nbsp;
                        </div>
                    );
            };
            break;
        case 'highlights':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={itemContent._uid}>
                            {parseStoryblokContent(itemContent)}
                            <hr/><br/>&nbsp;
                        </div>
                    );
            };
            break;
        case 'youtube-playlist':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                return (
                    <div key={item.id} className={'youtube-square-item'}>
                        <YoutubeVideoTile id={item.id} name={item.name}/>
                    </div>
                );
            };
            break;
        case 'knowledge-base-documents':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={item.uuid}>
                            <KnowledgeBaseTile
                                title={itemContent.title}
                                image={itemContent.image ? itemContent.image.filename : ""}
                                intro={itemContent.intro}
                                size={'large'}
                                isExternal={!itemContent.custom_link ? false : true}
                                link={!itemContent.custom_link ? 
                                    "/x/"+item.full_slug.substring(item.full_slug.indexOf("/")+1) : 
                                    itemContent.custom_link}
                                ownerTitle={itemContent.owner_title}
                                ownerLogo={itemContent.owner_logo}
                            />
                        </div>
                    );
            };
            break;
        case 'solution-documents':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={item.uuid}>
                            <SolutionTile
                                title={itemContent.title}
                                image={itemContent.image ? itemContent.image.filename : ""}
                                subtitle={itemContent.subtitle}
                                dataset={itemContent.dataset}
                                size={'large'}
                                isExternal={!itemContent.custom_link ? false : true}
                                link={!itemContent.custom_link ? 
                                    "/x/"+item.full_slug.substring(item.full_slug.indexOf("/")+1) : 
                                    itemContent.custom_link}
                                ownerTitle={itemContent.owner_title}
                                ownerLogo={itemContent.owner_logo}
                            />
                        </div>
                    );
            };
            break;
        case 'xrathus-documents':
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={item.uuid}>
                            <XrathusTile
                                title={itemContent.title}
                                image={itemContent.image ? itemContent.image.filename : ""}
                                intro={itemContent.intro}
                                size={'large'}
                                isExternal={!itemContent.custom_link ? false : true}
                                link={!itemContent.custom_link ? 
                                    "/x/"+item.full_slug.substring(item.full_slug.indexOf("/")+1) : 
                                    itemContent.custom_link}
                                ownerTitle={itemContent.owner_title}
                                ownerLogo={itemContent.owner_logo}
                            />
                        </div>
                    );
            };
            break;
        default:
            // eslint-disable-next-line react/display-name
            ItemRenderer = (item) => {
                const itemContent = (item && item.content) ? item.content : null;
                if (itemContent)
                    return (
                        <div key={item.uuid}>
                            {parseStoryblokContent(itemContent)}
                            <hr/><br/>&nbsp;
                        </div>
                    );
            };
    }

    return ItemRenderer;
}


/**
 * Get the Renderer for a page type
 *
 * @param pageType
 */
export function getPageRenderer( pageType ) {
    let PageRenderer = null;

    switch (pageType) {
        case "documents":
            // eslint-disable-next-line react/display-name
            PageRenderer = (page) => {
                const pageContent = (page && page.content) ? page.content : null;
                if (pageContent) {
                    return (
                        <StoryblokDocument
                            title={pageContent.title}
                            subtitle={pageContent.subtitle}
                            slug={page.slug}
                            image={pageContent.image ? pageContent.image.filename : ""}
                            imageCustomClass={pageContent.custom_icon_css}
                            contentCustomClass={pageContent.custom_body_css}
                            tocToggleOff={pageContent.no_table_of_contents}
                            marginsToggleOff={pageContent.no_document_margins}
                            content={pageContent.body}
                            hideBreadcrumbs={pageContent.hide_bread_crumbs}
                            breadCrumbStepsToJump={pageContent.bread_crumb_steps_to_jump ? Number(pageContent.bread_crumb_steps_to_jump) : 2}
                        />
                    );
                }
                return (
                    <NotFound/>
                );
            };
            break;
        case "collaborators":
        case "knowledge-base":
        case "nested-pages":
        case "solutions":
            // eslint-disable-next-line react/display-name
            PageRenderer = (page) => {
                const pageContent = (page && page.content) ? page.content : null;
                if (pageContent)
                    return (
                        <div key={pageContent._uid}>
                            <StoryblokWebpage 
                                title={pageContent.title}
                                titleStyle={pageContent.title_style!=='' ? pageContent.title_style : 'page-title'}
                                subtitle={pageContent.subtitle}
                                slug={page.slug}
                                logo={pageContent.logo ? pageContent.logo.filename : ""}
                                hideLogo={pageContent.hide_logo}
                                logoCustomClass={pageContent.custom_logo_css}
                                content={pageContent.body}
                                contentCustomClass={pageContent.custom_body_css}
                                hideBreadCrumbs={pageContent.hide_bread_crumbs}
                                breadCrumbStepsToJump={pageContent.bread_crumb_steps_to_jump ? Number(pageContent.bread_crumb_steps_to_jump) : 2}
                            />
                        </div>
                    );
                return (
                    <NotFound/>
                );
            };
            break;
        case 'dashboards':
            // eslint-disable-next-line react/display-name
            PageRenderer = (page) => {
                return (
                    <div className={"content " + page.slug}>
                        { parseStoryblokContent(page.content.body) }
                    </div>
                );
            };
            break;
        default:
            // eslint-disable-next-line react/display-name, no-unused-vars
            PageRenderer = (page) => {
                return (
                    <NotFound/>
                );
            };
    }

    return PageRenderer;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


/**
 * Perform a Storyblok content fetch
 *
 * @param url
 * @param params
 * @param pauseTime
 */
export function useStoryblokFetch( url, params, pauseTime ) {

    //for localstorage purposes, clean up params
    let localStorageSaveParams = params;
    if (localStorageSaveParams['cv']) {
        delete localStorageSaveParams['cv'];
    }
    if (localStorageSaveParams['token']) {
        delete localStorageSaveParams['token'];
    }

    //check if content has been fetched recently
    //const prevTime = localStorage.getItem(`fetchedContent/${url}/${JSON.stringify(localStorageSaveParams)}/fetchTime`);

    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const unmountedRef = useRef(false);
    useEffect(()=>()=>(unmountedRef.current = true), []);

    useEffect(() => {
        let effectStale = false;
        
        // declare the async data fetching function
        async function fetchData() {
            await sleep(pauseTime);

            if (unmountedRef.current) return;
            if (effectStale) return;

            let preparedParams = params;
            const perPage = 25;
            if (!url) preparedParams.per_page = perPage;

            // get the data from the api
            const response = await Storyblok.get('cdn/stories/'+url, preparedParams);

            let totalStories = [];
            let modifiedReponse = response;
            
            if (response && response.data && response.data.stories) {
                totalStories = response.data.stories;
                if (response.headers && response.headers.total && response.headers.total > perPage) {
                    let totalPages = Math.ceil(response.headers.total/perPage);
                    for (let i = 2; i<=totalPages; i++) {
                        preparedParams.page = i;
                        if (unmountedRef.current) return;
                        if (effectStale) return;
                        const respAdditional = await Storyblok.get('cdn/stories/'+url, preparedParams);
                        totalStories = totalStories.concat(respAdditional.data.stories);
                    }
                }
                modifiedReponse.data.stories = totalStories;
            }

            if (unmountedRef.current) return;
            if (effectStale) return;
            setLoading(false);
            
            if (modifiedReponse && modifiedReponse.data) {
                setData(modifiedReponse.data);
            }
        }

        if (isLoading && (url || params)) {
            // call the function
            fetchData()
                // make sure to catch any error
                .catch(function (error) {
                    if (error.response) {
                        // request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // request was made but no response was received
                        console.log(error.request);
                    } else {
                        // something happened in setting up the request that triggered an error
                        console.log('Error', error.message);
                    }
                });
        }
        return ()=>(effectStale = true);

    },[data, isLoading, url, params, pauseTime]);

    if (!data || data===[] || data.length === 0) {
        return [null, isLoading];
    }

    return [data, isLoading];
}


/**
 * Perform a Storyblok asset fetch
 *
 * @param filename
 */
export function useStoryblokAssetFetch( filename ) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const unmountedRef = useRef(false);
    useEffect(()=>()=>(unmountedRef.current = true), []);

    useEffect(() => {
        let effectStale = false;
        
        // declare the async data fetching function
        async function fetchData() {
            if (unmountedRef.current) return;
            if (effectStale) return;

            const response = await StoryblokAsset.get('cdn/assets/me', {
                filename: filename
            });

            if (unmountedRef.current) return;
            if (effectStale) return;
            setLoading(false);
            
            if (response && response.data) {
                setData(response.data);
            }
        }

        if (isLoading && filename) {
            fetchData()
            // make sure to catch any error
                .catch(function (error) {
                    if (error.response) {
                    // request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                    // request was made but no response was received
                        console.log(error.request);
                    } else {
                    // something happened in setting up the request that triggered an error
                        console.log('Error', error.message);
                    }
                });
        }
        return ()=>(effectStale = true);

    },[isLoading, filename]);

    if (!data || data===[] || data.length === 0) {
        return [[], true];
    }

    return [data, isLoading];
}


/**
 * Perform a Storyblok datasource fetch
 *
 * @param params
 */
export function useStoryblokDataSourceFetch( params ) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const unmountedRef = useRef(false);
    useEffect(()=>()=>(unmountedRef.current = true), []);

    useEffect(() => {
        let effectStale = false;
        
        // declare the async data fetching function
        async function fetchData() {
            if (unmountedRef.current) return;
            if (effectStale) return;

            let preparedParams = params;
            const perPage = 25;
            preparedParams.per_page = perPage;

            const response = await Storyblok.get('cdn/datasource_entries', preparedParams);

            let totalEntries = [];
            if (response && response.data && response.data.datasource_entries) {
                totalEntries = response.data.datasource_entries;
                if (response.headers && response.headers.total && response.headers.total > perPage) {
                    let totalPages = Math.ceil(response.headers.total/perPage);
                    for (let i = 2; i<=totalPages; i++) {
                        preparedParams.page = i;
                        if (unmountedRef.current) return;
                        if (effectStale) return;
                        const respAdditional = await Storyblok.get('cdn/datasource_entries', preparedParams);
                        totalEntries = totalEntries.concat(respAdditional.data.datasource_entries);
                    }
                }
            }

            if (unmountedRef.current) return;
            if (effectStale) return;
            setLoading(false);
            
            if (response && response.data && response.data.datasource_entries) {
                setData(totalEntries);
            }
        }

        if (isLoading && params) {
            fetchData()
            // make sure to catch any error
                .catch(function (error) {
                    if (error.response) {
                    // request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                    // request was made but no response was received
                        console.log(error.request);
                    } else {
                    // something happened in setting up the request that triggered an error
                        console.log('Error', error.message);
                    }
                });
        }
        return ()=>(effectStale = true);

    },[isLoading, params]);

    if (!data || data===[] || data.length === 0) {
        return [[], true];
    }

    return [data, isLoading];
}


/**
 * Sort Storyblok list content by specific order
 *
 * @param list
 * @param ordered
 */
export function sortStoryblokListContent (list, ordered) {
    let returnList = [];
    switch (ordered) {
        case 'time':
            returnList = list.sort(function(a,b) {
                if ((!a.content || !a.content.start_date) && (!b.content || !b.content.start_date)) {
                    return 0;
                }
                else if (!a.content || !a.content.start_date) {
                    return 1;
                }
                else if (!b.content || !b.content.start_date) {
                    return -1;
                }
                if (new Date(a.content.start_date+" UTC") < new Date(b.content.start_date+" UTC")) {
                    return -1;
                }
                if (new Date(a.content.start_date+" UTC") > new Date(b.content.start_date+" UTC")) {
                    return 1;
                }
                return 0;
            });
            break;
        case 'time-from-present':
            returnList = list.sort(function(a,b) {
                if ((!a.content || !a.content.start_date) && (!b.content || !b.content.start_date)) {
                    return 0;
                }
                else if (!a.content || !a.content.start_date) {
                    return 1;
                }
                else if (!b.content || !b.content.start_date) {
                    return -1;
                }
                if (new Date(a.content.start_date+" UTC") < new Date(b.content.start_date+" UTC")) {
                    return -1;
                }
                if (new Date(a.content.start_date+" UTC") > new Date(b.content.start_date+" UTC")) {
                    return 1;
                }
                return 0;
            });
            // if not displaying old events, filter out only new
            returnList = returnList.filter(element => new Date(element.content.end_date).toISOString() >= new Date().toISOString());
            break;
        case 'priority':
            returnList = list.sort(function(a,b) {
                if ((!a.content || !a.content.priority) && (!b.content || !b.content.priority)) {
                    return 0;
                }
                else if (!a.content || !a.content.priority) {
                    return 1;
                }
                else if (!b.content || !b.content.priority) {
                    return -1;
                }
                if (Number(a.content.priority) < Number(b.content.priority)) {
                    return -1;
                }
                if (Number(a.content.priority) > Number(b.content.priority)) {
                    return 1;
                }
                return 0;
            });
            break;
        case 'alphabet':
        default:
            returnList = list.sort(function(a,b) {
                if (!a.content.title || !b.content.title) {
                    return 0;
                }
                let titleA = a.content.title.toUpperCase();
                let titleB = b.content.title.toUpperCase();
                if (titleA < titleB) {
                    return -1;
                }
                if (titleA > titleB) {
                    return 1;
                }
                return 0;
            });
            break;
    }
    return returnList;
}


/**
 * Filter Storyblok content through access groups
 *
 * @param content
 * @param accessGroups
 */
export function filterStoryblokContentThroughGroups( content, accessGroups ) {
    if (content && content.content && content.content.only_available_to_user_groups && accessGroups) {
        const contentGroups = content.content.only_available_to_user_groups.split(' ');
        for (let i=0; i<contentGroups.length; i++) {
            if (hasGroup(accessGroups, contentGroups[i])) {
                return content;
            }
        }
        return null;
    }
    return content;
}
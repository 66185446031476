import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CodeIcon from '../../css/images/icon-Code.svg';
import XWidgetIcon from '../../css/images/XWidget.png';

const lab_url = localStorage.getItem('lab_url');

class XHubToolbox extends Component {
    render() {

        const {
            project_id
        } = this.props;

        const disableViewer = (!project_id);
        const disabledClass = (project_id) ? '' : ' disabled';

        return (
            <>
                <div className="content toolbox">
                    <h1 className="page-title">
                        Toolbox
                    </h1>
                    <div className="row equal-height">

                        <div className="col col-xs-12 col-md-6 col-lg-4">
                            <div className="xrathus-item link">
                                <div className="item-header"><h3>OpendTect</h3></div>
                                <div className="item-content">
                                    <p>
                                        <img src={CodeIcon} alt="OpendTect" className="pic inline left"/>
                                        OpendTect is a free, open source seismic interpretation system and software
                                        development platform. The system supports all tools needed for visualizing,
                                        analyzing and interpreting 2D, 3D and 4D seismic data. It is widely used for
                                        Geo-Radar interpretation as well. OpendTect is released under the GNU Public
                                        License (GPL).
                                    </p>
                                </div>
                                <div className="item-footer">
                                    <a href="https://www.dgbes.com/index.php/download" target="_blank"
                                       rel="noopener noreferrer" title="OpendTect">Download</a>
                                </div>
                            </div>
                        </div>

                        <div className="col col-xs-12 col-md-6 col-lg-4">
                            <div className="xrathus-item link">
                                <div className="item-header"><h3>2D Seismic Viewer</h3></div>
                                <div className="item-content">
                                    <p>
                                        <img src={XWidgetIcon} alt="2D Seismic Viewer" className="pic inline left"/>
                                        Visualize a 2D slice of 3D seismic data in a highly adjustable viewer. Use the
                                        viewer slider to select the XY slice to be displayed. Customize the axis
                                        labels, color maps, axis orientation, and downsampling level of the data to
                                        visualize.
                                    </p>
                                </div>
                                <div className="item-footer">
                                    <a href={lab_url} title="Open" target="_blank"
                                       rel="noopener noreferrer" disabled={disableViewer} className={disabledClass}>
                                        Open
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col col-xs-12 col-md-6 col-lg-4">
                            <div className="xrathus-item link">
                                <div className="item-header"><h3>3D Seismic Viewer</h3></div>
                                <div className="item-content">
                                    <p>
                                        <img src={XWidgetIcon} alt="3D Seismic Viewer" className="pic inline left"/>
                                        Visualize 3D seismic data in a highly adjustable environment. Choose the X, Y,
                                        and Z index values using the viewer sliders at which you want to display
                                        YZ, XZ, and XY slices of your data at the same time. Customize the axis
                                        labels, color maps, axis orientation, and downsampling level of the data to
                                        visualize.
                                    </p>
                                </div>
                                <div className="item-footer">
                                    <a href={lab_url} title="Open" target="_blank"
                                       rel="noopener noreferrer" disabled={disableViewer} className={disabledClass}>
                                        Open
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_id: state.project_id
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(XHubToolbox)
);

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class HomePersona extends Component {

    handleHover = (type) => {
        if (typeof this.props.onHoverFunc === 'function') {
            this.props.onHoverFunc((type === 'over') ? this.props.typeName : null);
        }
    };

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc(this.props.type);
        }
    };

    render() {
        const {
            content,
            icon,
            selected,
            type
        } = this.props;

        return (
            <>
                <div className={`persona ${type}${(selected) ? ' selected' : ''}`}
                     onMouseOver={() => this.handleHover('over')}
                     onMouseOut={() => this.handleHover('out')}
                     onClick={(e) => this.handleClick(e)}
                >
                    {icon &&
                    <img className="icon" src={icon} alt={content.name}/>
                    }
                    <span className="name">
                        {content.name}
                    </span>
                </div>
            </>
        )
    }
}

HomePersona.propTypes = {
    content: PropTypes.object,
    icon: PropTypes.any,
    onClickFunc: PropTypes.func,
    onHoverFunc: PropTypes.func,
    rotation: PropTypes.string,
    selected: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
    typeName: PropTypes.string
};

HomePersona.defaultProps = {
    content: {
        name: ''
    },
    rotation: '0deg',
    selected: false,
    style: {},
    type: 'developer',
    typeName: 'developer'
};

export default HomePersona;

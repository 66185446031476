import React, {Component} from 'react';
import StoryblokPage from '../../partials/storyblok/StoryblokPageTemplate';
import '../../css/static.css';


class XMarketplaceSolutions extends Component {
    render() {
        return (
            
            <div className="xmarketplace">
                <h1 className="content page-title">
                    Solutions
                </h1>
                <StoryblokPage url={'solutions/solutions'}/>
            </div>
        );
    }
}

export default XMarketplaceSolutions;

import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select, {createFilter} from 'react-select';
import {FixedSizeList as List} from 'react-window';
import {RESOURCES as resources} from '../../resources';
import {makeGetDataRequest} from '../../utils/getRequest';
import {copyArray} from '../../utils/dataFunctions';

/**
 * React-Window Fixed Menu List for select options
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const MenuList = (props) => {

    let itemSize = 35;
    let listSize = props.children.length>8 ? 300 : props.children.length*itemSize;

    return (
        <List
            height={listSize}
            itemCount={props.children.length}
            itemSize={itemSize}
            initialScrollOffset={props.initialOffset}
            className="select-options"
        >
            {({index, style}) => <div className="select-option" style={style}>{props.children[index]}</div>}
        </List>
    );
};

MenuList.propTypes = {
    /**
     * Children array
     */
    children: PropTypes.array,
    /**
     * Initial Scroll offset
     */
    initialOffset: PropTypes.any,
};

/**
 * A component to retrieve data from a resource and create a React-Select input
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly label?: *, readonly disabled?: *, readonly changeFunc?: *, readonly optionValue?: *, readonly resource?: *, readonly required?: *, readonly id?: *, readonly params?: *, readonly instructions?: *, readonly optionText?: *}> & React.RefAttributes<unknown>>}
 */
const ResourceSelectInput = forwardRef(
    ({
        id,
        label,
        instructions,
        changeFunc,
        resource,
        required,
        optionText = 'name',
        optionValue = 'id',
        addOption = null,
        addOptionPosition = 'start',
        params = {}
    }, ref) => {

        const [dataList, setDataList] = useState({
            list: []
        });
        const [selectOptions, setSelectOptions] = useState({
            options: []
        });
        const [selectLoading, setSelectLoading] = useState(false);

        useEffect(() => {
            if (resource && params) {
                const fetchData = () => {
                    setSelectLoading(true);
                    const request = makeGetDataRequest(resources, resource, 'GET_LIST', params);
                    request.then(result => {
                        if (result) {
                            if (typeof result === 'string') {
                                console.log('Select Data Error: ', result);
                                setSelectLoading(false);
                                setDataList(dataList => ({...dataList, list: []}));
                                setSelectOptions(selectOptions => ({...selectOptions, options: []}));
                                return Promise.resolve(result);
                            }
                            let list = [];
                            let options = [];
                            if (result && result.length > 0) {
                                list = copyArray(result);
                                for (let i = 0; i < list.length; i++) {
                                    let item = list[i];
                                    let option = {
                                        label: item[optionText],
                                        value: item[optionValue]
                                    };
                                    options.push(option);
                                    list[i].optionName = item[optionText];
                                }
                                if (addOption) {
                                    addOption.optionName = addOption.label;
                                    if (addOptionPosition === 'end') {
                                        options.push(addOption);
                                        list.push(addOption);
                                    } else {
                                        options.unshift(addOption);
                                        list.unshift(addOption);
                                    }
                                }
                                setDataList(dataList => ({...dataList, list: list}));
                                setSelectOptions(selectOptions => ({...selectOptions, options: options}));
                            }
                            setSelectLoading(false);
                            return Promise.resolve(result);
                        } else {
                            console.log('Select Data Error: No result');
                            return Promise.reject('Select Data failed');
                        }
                    }).catch(error => {
                        console.log('Select Data Error: ', error);
                        return Promise.reject('Server Error');
                    });
                };

                if (resource) {
                    fetchData();
                }
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleChange = (e) => {
            let selected = e;
            const idx = dataList.list.findIndex(r => r[optionValue] === e.value);
            if (idx !== -1) {
                selected = dataList.list[idx];
            }
            if (typeof changeFunc === 'function') {
                changeFunc(selected);
            }
        };

        return (
            <>
                {selectOptions.options && selectOptions.options.length > 0 &&
                <label htmlFor={id}>
                    {label &&
                    <span>
                        {label}
                        {required &&
                        <span className="required">*</span>
                        }
                    </span>
                    }
                    {instructions &&
                    <span className="instructions">{instructions}</span>
                    }
                    <Select
                        ref={ref}
                        label={label}
                        components={{MenuList}}
                        options={selectOptions.options}
                        filterOption={createFilter({ignoreAccents: false})}
                        onChange={(e) => handleChange(e)}
                        isLoading={selectLoading}
                        className="resource-select-input"
                    />
                </label>
                }
            </>
        );
    }
);

//@TODO: cleanup proptypes to have the right types on each variable
ResourceSelectInput.propTypes = {
    /**
     * ID
     */
    id: PropTypes.any,
    /**
     * Label
     */
    label: PropTypes.string,
    /**
     * Instructions
     */
    instructions: PropTypes.any,
    /**
     * Change Funcion
     */
    changeFunc: PropTypes.func,
    /**
     * Resource
     */
    resource: PropTypes.any,
    /**
     * Required
     */
    required: PropTypes.bool,
    /**
     * Disabled
     */
    disabled: PropTypes.bool,
    /**
     * Option Text
     */
    optionText: PropTypes.string,
    /**
     * Option Value
     */
    optionValue: PropTypes.string,
    /**
     * Add Option
     */
    addOption: PropTypes.any,
    /**
     * Add Option Position
     */
    addOptionPosition: PropTypes.string,
    /**
     * Params
     */
    params: PropTypes.any,
};

export default ResourceSelectInput;

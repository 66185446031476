import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {makeDateString} from '../utils/dataFunctions';
import APISharedUsers from '../utils/APISharedUsers';
import TableWithSubrows from './tables/TableWithSubrows';
import UnsubscribeUserButton from './buttons/UnsubscribeUserButton';

/**
 * Component that displays Users of a Shared Event in a React Table
 *
 * @param eventId
 * @param onList
 * @param inPanel
 * @param refresh
 * @return {JSX.Element}
 * @constructor
 */
const EventPublicJoinedUsersTable = ({eventId, onList, inPanel = true, refresh = false}) => {

    const [itemList, setItemList] = useState([]);
    const [refreshList, setRefreshList] = useState(refresh);

    useEffect(
        () => {
            return setRefreshList(refresh);
        }, [refresh]
    );

    const setSharedList = (data) => {
        let list = [];
        if (data.length > 0) {

            // @TODO need structure

            for (let i = 0; i < data.length; i++) {
                list[i] = data[i];
                list[i].joined_on_str = (data[i].joined_on) ? makeDateString(data[i].joined_on) : '';
                list[i].rejected_on_str = (data[i].rejected_on) ? makeDateString(data[i].rejected_on) : '';
                list[i].shared_on_str = (data[i].shared_on) ? makeDateString(data[i].shared_on) : '';
                list[i].unsubscribed_on_str = (data[i].unsubscribed_on) ? makeDateString(data[i].unsubscribed_on) : '';
            }
        }
        setItemList(list);
        if (typeof onList === 'function') {
            onList(list);
        }
        setRefreshList(false);
    };

    // Refresh list after User Unsubscription
    const handleUnsubscribe = () => {
        setRefreshList(true);
        setRefreshList(false);
    };

    // Subscription data subrow
    const renderSubscriptionRow = useCallback(
        ({row}) => (
            <span className="shared-user-subscription">
                {row.original.rejected &&
                    <>
                        <em>Rejected by</em>:
                        <strong> {row.original.username}</strong>
                        {row.original.rejected_on_str &&
                            <>
                                &nbsp;on {row.original.rejected_on_str}
                            </>
                        }
                    </>
                }
                {row.original.unsubscribed_by &&
                    <>
                        <em>Unsubscribed by</em>:
                        <strong> {row.original.unsubscribed_by}</strong>
                        {row.original.unsubscribed_on_str &&
                            <>
                                &nbsp;on {row.original.unsubscribed_on_str}
                            </>
                        }
                        <br/>
                        <em>Reason</em>: {row.original.unsubscribed_notes}
                    </>
                }
                {(row.original.rejected || row.original.unsubscribed_by) &&
                    <>
                        <br/>
                        <UnsubscribeUserButton
                            type="reject"
                            eventId={eventId}
                            username={row.original.username}
                            isOwnerOrSysop={true}
                            onFormEvent={() => handleUnsubscribe()}
                        />
                    </>
                }
            </span>
        ),
        [eventId]
    )

    // Set table data
    const tableData = useMemo(() => itemList, [itemList]);

    // Set table columns
    const columns = React.useMemo(
        () => [
            {
                Header: 'Username',
                accessor: 'username',
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Joined',
                id: 'joined_col',
                Cell: ({row}) => {
                    return (row.original.joined_on)
                        ? <span>{row.original.joined_on_str}</span>
                        : <span>No</span>
                },
                disableFilters: true,
                disableSortBy: true
            }
        ],
        []
    );

    return (
        <>
            {inPanel && itemList.length > 0 &&
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel no-padding">
                            <h3 className="header">Joined Users</h3>
                            <TableWithSubrows
                                columns={columns}
                                data={tableData}
                                subrowComponent={renderSubscriptionRow}
                            />
                        </div>
                    </div>
                </div>
            }
            {!inPanel && itemList.length > 0 &&
                <TableWithSubrows
                    columns={columns}
                    data={tableData}
                    subrowComponent={renderSubscriptionRow}
                />
            }

            <APISharedUsers
                getType="event-public"
                id={eventId}
                onResult={(e) => setSharedList(e)}
                refresh={refreshList}
            />
        </>
    );
};

export default EventPublicJoinedUsersTable;

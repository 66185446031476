import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {RESOURCES as resources} from '../../resources';
import {makeGetDataRequest} from '../../utils/getRequest';
import '../../css/organization.css';

/**
 * Form: Edit Organization
 *
 * @return {*}
 * @constructor
 */
export default function OrganizationDetails({pathArr, isSysop, basePath = ''}) {

    const [fieldState, setFieldState] = useState({
        id: '',
        active: true,
        address: '',
        city: '',
        country: '',
        description: '',
        name: '',
        state: '',
        website: '',
        zip: ''
    });

    useEffect(() => {
        const orgId = pathArr[3];
        const params = {
            id: orgId,
            getBy: 'id'
        };
        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'Organization', 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    setFieldState(result);
                    return Promise.resolve(result);
                } else {
                    console.log('Organization Error: No result');
                    return Promise.reject('Organization failed');
                }
            }).catch(error => {
                console.log('Organization Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const website = (fieldState?.website?.indexOf('http') === -1) ? 'https://' + fieldState.website : fieldState.website;

    return (
        <div className="xrathus-detail">
            <div className="item-content full">
                {fieldState.name &&
                <>
                    <h4>Name</h4>
                    <p className="details">{fieldState.name}</p>
                </>
                }
                {fieldState.description &&
                <>
                    <h4>Description</h4>
                    <ReactMarkdown source={fieldState.description}/>

                </>
                }
                {fieldState.address &&
                <>
                    <h4>Address Information</h4>
                    <p className="details">
                        {fieldState.address}
                        {fieldState.city &&
                        <>, {fieldState.city}</>
                        }
                        {fieldState.state &&
                        <>, {fieldState.state}</>
                        }
                        {fieldState.zip &&
                        <> {fieldState.zip}</>
                        }
                        {fieldState.country &&
                        <> {fieldState.country}</>
                        }
                    </p>
                </>
                }
                {fieldState.website &&
                <>
                    <h4>Website</h4>
                    <p className="details">
                        <a href={website} title={fieldState.name}
                           target="_blank" rel="noopener noreferrer">
                            {fieldState.website}
                        </a>
                    </p>
                </>
                }
                {fieldState.active &&
                <>
                    <h4>Active</h4>
                    <p className="details">{fieldState.active ? 'Yes' : 'No'}</p>
                </>
                }

                {isSysop &&
                <div className="form-btns right">
                    <Link className="btn btn-primary" to={`${basePath}/x/organization/${pathArr[3]}/edit`}>
                        Edit Organization
                    </Link>
                </div>
                }
            </div>
        </div>
    );
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resourcesData';
import {setProjectFileList} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import {copyArray} from './dataFunctions';

/**
 * Component for retrieving File Lists and single Dataset and Files for Challenges and Projects
 */
class APIProjectDataset extends Component {

    componentDidMount() {
        const type = this.props.type;
        if (type === 'dataset') {
            this.getDataset();
            this.getProjectFiles();
        }
        if (type === 'file') {
            this.getProjectFile();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh === true) {
            const type = this.props.type;
            if (type === 'dataset') {
                this.getDataset();
                this.getProjectFiles();
            }
            if (type === 'file') {
                this.getProjectFile();
            }
        }
    }

    /**
     * Get Project Dataset Files with optional parameters
     *
     */
    getProjectFiles = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const params = {
            data_id: id
        };
        const request = makeGetDataRequest(resources, 'File', 'GET_LIST', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Dataset Files Retrieval Error: ', result);
                    this.processFileList(null);
                    return Promise.resolve(result);
                }
                this.processFileList(result);
                return Promise.resolve(result);
            } else {
                console.log('Dataset Files Retrieval Error: No result');
                return Promise.reject('Dataset Files Retrieval failed');
            }
        }).catch(error => {
            console.log('Dataset Files Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Get single Dataset
     */
    getDataset = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const request = makeGetDataRequest(resources, 'Data', 'GET_ONE', {id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Dataset Retrieval Error: ', result);
                    this.processDataset(null);
                    return Promise.resolve(result);
                }
                this.processDataset(result);
                return Promise.resolve(result);
            } else {
                console.log('Dataset Retrieval Error: No result');
                return Promise.reject('Dataset Retrieval failed');
            }
        }).catch(error => {
            console.log('Dataset Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Get single Dataset File
     */
    getProjectFile = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const request = makeGetDataRequest(resources, 'File', 'GET_ONE', {data_id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Challenge Retrieval Error: ', result);
                    this.processSingleFile(null);
                    return Promise.resolve(result);
                }
                this.processSingleFile(result);
                return Promise.resolve(result);
            } else {
                console.log('Challenge Retrieval Error: No result');
                return Promise.reject('Challenge Retrieval failed');
            }
        }).catch(error => {
            console.log('Challenge Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Return single Dataset result
     *
     * @param data
     */
    processDataset = (data) => {
        if (data && this.props.onDataResult) {
            this.props.onDataResult(data);
        }
    };

    /**
     * Return and/or Store list data
     *
     * @param data
     */
    processFileList = (data) => {
        let list = [];
        if (data && data.files && data.files.length > 0) {
            list = copyArray(data.files);
        }

        // Pass results to parent
        if (this.props.onFileListResult) {
            this.props.onFileListResult(list);
        }
        // Store results in Redux
        if (this.props.store) {
            this.props.setProjectFileList(list);
        }
    };

    /**
     * Return single Dataset File result
     *
     * @param data
     */
    processSingleFile = (data) => {
        if (data && this.props.onFileResult) {
            this.props.onFileResult(data);
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_file_list: state.project_file_list,
    };
};

const mapDispatchToProps = {
    setProjectFileList: setProjectFileList
};

APIProjectDataset.propTypes = {
    id: PropTypes.number,
    onDataResult: PropTypes.func,
    onFileResult: PropTypes.func,
    onFileListResult: PropTypes.func,
    refresh: PropTypes.bool,
    store: PropTypes.bool,
    type: PropTypes.string
};

APIProjectDataset.defaultProps = {
    refresh: false,
    store: true,
    type: 'data'
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIProjectDataset);

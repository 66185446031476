import React, {forwardRef} from 'react';

const TextAreaInput = forwardRef(
    (
        {id, label, name, placeholder, instructions, className, changeFunc, children, defaultValue, required, value, maxLength, rows = 5, cols = 33}, ref) => {
        return (
            <label htmlFor={id}>
                {label &&
                <span>
                    {label}
                    {required &&
                    <span className="required">*</span>
                    }
                </span>
                }
                {instructions &&
                <span className="instructions">{instructions}</span>
                }
                <textarea
                    id={id}
                    name={name}
                    aria-label={placeholder}
                    placeholder={placeholder}
                    value={(value) ? value : undefined}
                    ref={ref}
                    rows={rows}
                    cols={cols}
                    maxLength={(maxLength) ? maxLength : undefined}
                    required={(required) ? true : undefined}
                    className={className}
                    onChange={(changeFunc)
                        ? (e) => changeFunc({
                            name: e.target.name,
                            value: e.target.value
                        })
                        : undefined}
                >
                </textarea>
                {children}
            </label>
        );
    }
);

export default TextAreaInput;

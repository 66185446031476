import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resourcesProject';
import {makeGetDataRequest} from '../../utils/getRequest';
import {makePostDataRequest} from '../../utils/postRequest';
import {createDateTimeObject} from '../../utils/dataFunctions';
import {setDueDate, getProjectPermissions} from '../../utils/projectFunctions';
import BaseInput from '../fields/BaseInput';
import SelectInput from '../fields/SelectInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import IconUser from '../images/IconUser';
import {PROJECT_TABS} from '../../data/formTabs';
import {BOOLEAN_OPTIONS} from '../../data/objects';
import 'react-datepicker/dist/react-datepicker.min.css';

/**
 * Form: Edit Project
 *
 * @param basePath
 * @param projectType
 * @param projectId
 * @param formStage
 * @param onFormEvent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormProjectEdit = (
    {
        basePath = '',
        projectType,
        projectId,
        formStage,
        onFormEvent,
        ...props
    }) => {

    // Set up form and state
    const {register, errors, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    // State: Form Stage (from parent)
    const [formStageState, updateFormStageState] = useState(
        {
            stage: formStage
        }
    );
    if (formStageState.stage !== formStage) {
        updateFormStageState({...formStageState, stage: formStage});
    }

    const [projectValues, setProjectValues] = useState(null);

    // State: Due Date Object
    const [dueDateState, updateDueDateState] = useState(
        {
            min_date_obj: setDueDate('/'),
            due_date_obj: ''
        }
    );
    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [tabState, setTab] = useState(PROJECT_TABS.overview);

    const updateTab = (tab) => {
        setTab(PROJECT_TABS[tab]);
        document.body.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    // Effect: Get Project data
    useEffect(() => {
        const params = {
            id: projectId
        };
        let resourceName = 'Project';
        if (projectType === 'personal' || projectType === 'personal_own') {
            resourceName = 'ProjectPersonal';
        }
        const fetchData = () => {
            const request = makeGetDataRequest(resources, resourceName, 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    const data = JSON.parse(JSON.stringify(result));
                    setValue('id', result?.id ?? '');
                    setValue('active', !!(result.active));
                    setValue('community_desc', result?.community_desc ?? '');
                    setValue('complete', !!(result.complete));
                    setValue('due_date', result?.due_date ?? '');
                    setValue('goals', result?.goals ?? '');
                    setValue('introduction', result?.introduction ?? '');
                    setValue('published', !!(result.published));
                    setValue('title', result?.title ?? '');
                    setValue('summary', result?.summary ?? '');
                    setValue('username', result?.username ?? '');

                    const due_date_obj = (data.due_date) ? new Date(data.due_date.replace(/-/g, '/').replace(/T.+/, '')) : '';
                    updateDueDateState({...dueDateState, due_date_obj: due_date_obj});
                    setProjectValues(result);
                    if (onFormEvent !== null && typeof onFormEvent === 'function') {
                        // Notify the page that Project has been retrieved
                        onFormEvent('project', 'info', result);
                    }
                    return Promise.resolve(result);
                } else {
                    console.log('Project Error: No result');
                    return Promise.reject('Project failed');
                }
            }).catch(error => {
                console.log('Project Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            getValues('introduction') === '' ||
            getValues('summary') === '' ||
            getValues('title') === '' ||
            tabState.tab === 'saving' ||
            (errors &&
                (
                    errors.introduction ||
                    errors.summary ||
                    errors.title
                ))
        );
    };

    // Handle change from specific form fields
    const handleFormChange = (e) => {
        if (e.name === 'due_date') {
            // Set a Due Date object
            const dateString = createDateTimeObject(e.value);
            setValue(e.name, dateString);
            updateDueDateState({...dueDateState, due_date_obj: e.value});
        }
        if (e.name === 'active') {
            setValue(e.name, e.value);
            setProjectValues({...projectValues, active: (e.value !== 'false')});
        }
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            const active = getValues('active');
            // Set parameters from form state
            data.username = getValues('username');
            data.id = parseInt(getValues('id'), 10);
            data.active = (active.toString() === 'true');
            data.complete = (getValues('complete').toString() === 'true');
            data.published = (projectValues.published.toString() === 'true');

            // Submit form to API
            updateTab('saving');
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'ProjectPersonal', 'UPDATE', data);
        request.then(result => {
            if (result && result.id) {
                updateSuccessState({success: true, message: ''});
                updateTab('success');
                setPostProject(result);
                return Promise.resolve(result);
            } else {
                updateSuccessState({success: false, message: 'Sorry, your Project could not be updated.'});
                updateTab('fail');
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Updated Project Error: ', error);
            updateSuccessState({success: false, message: 'Sorry, there was an error with your Project: ' + error});
            updateTab('fail');
            return Promise.reject('Server Error');
        });
    };

    // Process after Project form completion
    const setPostProject = (data) => {
        if (onFormEvent !== null && typeof onFormEvent === 'function') {
            // Notify the page that Project has been created
            onFormEvent('project', 'complete', data);
        }
    };

    const projectPath = (projectType === 'personal' || projectType === 'personal_own') ? 'project/personal' : 'project';
    const projectPermissions = getProjectPermissions(props.user, props.project_list_own, projectId, null);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">
                {/* Form Menu */}
                <div className="item-menu">
                    <ul>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => updateTab('overview')}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Overview
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => updateTab('information')}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Information
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => updateTab('confirm')}
                                    disabled={!!disableSubmit()}
                            >
                                Finalize
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="item-content">
                    {!projectValues?.id &&
                        <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                    }

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(tabState.tab === 'overview') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter basic Project information. Click <em>Next</em> to enter Description information.
                        </p>
                        <div className="form-group">
                            {/* Username */}
                            <BaseInput
                                type="text"
                                id="project-username"
                                name="username" label="Project Owner" placeholder="Project Owner"
                                ref={register}
                                disabled={true}
                            >
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Title */}
                            <BaseInput
                                type="text"
                                id="project-title"
                                name="title" label="Title" placeholder="Title of Project"
                                aria-describedby="error-title-required error-title-minLength error-title-maxLength"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Title is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Title must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 80,
                                        message: (<FormError id="maxLength"
                                                             text="Title cannot be longer than 80 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Goals */}
                            <TextAreaInput
                                id="project-goals"
                                rows={3} cols={50}
                                name="goals" label="Project Goals"
                                aria-describedby="error-goals-required"
                                aria-invalid={errors.goals ? 'true' : 'false'}
                                ref={register}
                            >
                            </TextAreaInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Due Date */}
                            <label htmlFor="project-due-date">
                                <div>Due Date<span className="required">*</span></div>
                                <DatePicker
                                    id="project-due-date"
                                    minDate={dueDateState.min_date_obj.due_date}
                                    selected={dueDateState.due_date_obj}
                                    onChange={(e) => handleFormChange({name: 'due_date', value: e})}
                                    dateFormat="MMMM d, yyyy"
                                    popperPlacement="right"
                                    style={{display: 'block'}}
                                />
                                <input type="hidden" name="due_date" ref={register}/>
                            </label>
                            {/* Project Active */}
                            <SelectInput
                                id="project-active"
                                name="active"
                                label="Active"
                                options={BOOLEAN_OPTIONS}
                                changeFunc={(e) => handleFormChange(e)}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-btns right mr-1">
                            <p>
                                {projectValues?.active.toString() === 'true' &&
                                    <>
                                        &nbsp;
                                    </>
                                }
                                {projectValues?.active.toString() === 'false' &&
                                    <>
                                        Note: Inactive Projects cannot be viewed by Shared Users.
                                    </>
                                }
                            </p>
                        </div>
                        <div className="form-group row-2">
                            {/* Complete */}
                            <SelectInput
                                id="project-complete"
                                name="complete"
                                label="Complete"
                                options={BOOLEAN_OPTIONS}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <input type="hidden" name="id" ref={register}/>
                    </div>

                    {/* Tab: Information */}

                    <div className={`tab-content ${(tabState.tab === 'information') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter Project descriptive data. Markdown may be used, but no HTML is allowed. When all
                            information is completed, click <em>Next</em> to confirm and Save the Project.
                        </p>
                        <div className="form-group">
                            {/* Summary */}
                            <TextAreaInput
                                id="project-summary"
                                rows={10} cols={50}
                                name="summary" label="Summary"
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.summary ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Summary is required"/>)
                                })}
                                required
                                className={`form-input ${errors.summary ? 'input-error' : ''}`}
                            >
                                {errors.summary && errors.summary.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Introduction */}
                            <TextAreaInput
                                id="project-introduction"
                                rows={10} cols={50} maxLength={255}
                                name="introduction" label="Introduction"
                                aria-describedby="error-introduction-required"
                                aria-invalid={errors.introduction ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="An Introduction is required"/>)
                                })}
                                required
                                className={`form-input ${errors.introduction ? 'input-error' : ''}`}
                            >
                                {errors.introduction && errors.introduction.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Short Description */}
                            <TextAreaInput
                                id="project-community-desc"
                                rows={10} cols={50}
                                name="community_desc" label="Community Description"
                                placeholder="Community Description"
                                aria-invalid={errors.community_desc ? 'true' : 'false'}
                                ref={register}
                                className='form-input'
                            >
                            </TextAreaInput>
                        </div>
                    </div>

                    {/* Tab: Confirm && Save */}

                    <div className={`tab-content ${(tabState.tab === 'confirm') ? 'active' : ''}`}>
                        <p className="instructions">
                            Update your Project. Once the Project is updated, you will be able to upload a Dataset
                            associated with this Project if desired.
                        </p>
                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(tabState.tab === 'saving') ? 'active' : ''}`}>
                        <h4>Updating Project...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(tabState.tab === 'success') ? 'active' : ''}`}>
                        <h4>Project Updated</h4>
                        <p>
                            Your Project has been updated. If this Project needs a Dataset go to
                            your Account Dashboard and upload one.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(tabState.tab === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                            <p className="message">
                                {successState.message}
                            </p>
                        }
                        {successState.message === '' &&
                            <p className="message">
                                Sorry, there was an issue updating this Project.
                            </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        <div className="tab-nav">
                            {tabState.tab === 'overview' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Next"
                                            onClick={() => updateTab('information')}
                                    >
                                        Next
                                    </button>
                                </>
                            }
                            {tabState.tab === 'information' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Back"
                                            onClick={() => updateTab('overview')}
                                    >
                                        Back
                                    </button>
                                    <button type="button" className="btn btn-clear" title="Next"
                                            onClick={() => updateTab('confirm')}
                                            disabled={!!disableSubmit()}
                                    >
                                        Next
                                    </button>
                                </>
                            }
                            {tabState.tab === 'confirm' &&
                                <>
                                    <button type="button" className="btn btn-clear" title="Back"
                                            onClick={() => updateTab('information')}
                                    >
                                        Back
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                        Update Project
                                    </button>
                                </>
                            }
                            {projectPermissions.sysop && tabState.tab === 'success' &&
                                <>
                                    <Link to="/x/admin" title="Admin" className="btn btn-primary btn-icon">
                                        <IconUser/>
                                        Admin
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                    <div className="form-btns right mt-2 mb-1">
                        {!successState.success &&
                            <Link className="btn btn-clear"
                                  to={`${basePath}/x/hub/${projectPath}/${projectId}/overview`}>
                                Cancel
                            </Link>
                        }
                        {successState.success &&
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/hub/${projectPath}/${projectId}/overview`}>
                                View Project Details
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        project_list_own: state.project_list_own,
        user: state.user
    };
};

export default connect(
    mapStateToProps
)(FormProjectEdit);
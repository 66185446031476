import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setProjectId} from '../../store/actions';
import DashboardChallenges from '../../partials/DashboardChallenges';
import DashboardClustersPersona from '../../partials/DashboardClustersPersona';
import DashboardClustersProjects from '../../partials/DashboardClustersProjects';
import DashboardProjects from '../../partials/DashboardProjects';
import SubstrateGreeting from '../../partials/SubstrateGreeting';
import '../../css/challenge.css';

/**
 * Page: Connect
 */
class XConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            challengeList: [],
            projectList: [],
        };
    }

    componentDidMount() {
        this.props.setProjectId(null);
    }

    /**
     * Handle event from Dashboard Challenges or Projects
     *
     * @param type
     * @param list
     */
    onAssembledProjects = (type, list) => {
        if (type === 'challenge') {
            this.setState({
                challengeList: list
            });
        }
        if (type === 'project') {
            this.setState({
                projectList: list
            });
        }
    };

    render() {
        const {
            challengeList,
            projectList
        } = this.state;

        return (
            <div className="content xconnect">
                <h1 className="page-title">
                    My Xrathus
                </h1>
                <SubstrateGreeting substrate="Connect"/>

                {!challengeList.length && !projectList.length &&
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel">
                            You are on the Connect Dashboard, which will be populated with your choices once
                            you browse and join Challenges, subscribe to Clusters, create, or join
                            Projects and Training courses.
                        </div>
                    </div>
                </div>
                }

                {/* Row: Challenges and Projects */}

                <div className="row">
                    <DashboardChallenges onComplete={(e) => this.onAssembledProjects('challenge', e)}/>
                    <DashboardProjects onComplete={(e) => this.onAssembledProjects('project', e)}/>
                </div>

                {/* Row: Discourse Clusters (by Persona) */}

                <div className="row mt-2">
                    <div className="col col-xs-12">
                        <h3 className="section-header full">Clusters</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-12">
                        <DashboardClustersPersona/>
                    </div>
                </div>

                {/* Row: Discourse Clusters (Challenges & Projects) */}

                <DashboardClustersProjects challengeList={challengeList} projectList={projectList}/>

            </div>
        );
    }
}

const mapDispatchToProps = {
    setProjectId: setProjectId
};

XConnect.propTypes = {
    setProjectId: PropTypes.any,
};

export default connect(
    null,
    mapDispatchToProps
)(XConnect);

import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';

/**
 * A Browse Files/Folders input component
 */
class FileBrowse extends Component {
    constructor(props) {
        super(props);
        this.inputDirectoryBrowseRef = createRef();
        this.inputFileBrowseRef = createRef();
    }

    resetFileUpload = () => {
        if (this.inputDirectoryBrowseRef && this.inputDirectoryBrowseRef.current) {
            this.inputDirectoryBrowseRef.current.value = null;
        }
        if (this.inputFileBrowseRef && this.inputFileBrowseRef.current) {
            this.inputFileBrowseRef.current.value = null;
        }
    };

    handleBrowseClick = (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        if (type === 'folder') {
            this.inputDirectoryBrowseRef.current.click();
        }
        if (type === 'file') {
            this.inputFileBrowseRef.current.click();
        }
    };

    handleBrowseChange = async (e) => {
        const {target} = e;

        // Filter any Files meant to be hidden
        let filteredList = [];
        for (let i = 0; i < target.files.length; i++) {
            let file = target.files[i];
            if (file.name.substring(0, 1) !== '.') {
                // Create an object storing the File and the full path
                let obj = {
                    file: file
                };
                obj.path = (file.webkitRelativePath) ? file.webkitRelativePath : '';
                filteredList.push(obj);
            }
        }

        if (filteredList.length > 0) {
            this.props.onReady(filteredList);
        }
        // Clear the input in case of future browse events
        target.value = null;
        this.resetFileUpload(true);
    };

    render() {
        const {
            placement
        } = this.props;

        return (
            <>
                {placement === 'form' &&
                <>
                    <button className="btn btn-clear" onClick={(e) => this.handleBrowseClick(e, 'folder')}>
                        Browse Folder
                    </button>
                    <button className="btn btn-clear" onClick={(e) => this.handleBrowseClick(e, 'file')}>
                        Browse File(s)
                    </button>
                </>
                }

                <input type="file" id="input-browse-file" name="input-browse-file"
                       multiple
                       hidden
                       ref={this.inputFileBrowseRef}
                       onChange={(e) => this.handleBrowseChange(e)}
                />
                <input type="file" id="input-browse-directory" name="input-browse-directory"
                       webkitdirectory="" mozdirectory="" directory="" allowdirs=""
                       hidden
                       ref={this.inputDirectoryBrowseRef}
                       onChange={(e) => this.handleBrowseChange(e)}
                />
            </>
        )
    }
}

FileBrowse.propTypes = {
    onReady: PropTypes.func,
    placement: PropTypes.string
};

export default FileBrowse;

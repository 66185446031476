import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Component to display Career item
 *
 */
class CareerLink extends Component {
    render() {
        const {
            data
        } = this.props;

        let link = null;
        let title = data.title;
        if (data.link_email) {
            link = 'mailto:' + data.link_email;
            title = 'Email your resume to ' + data.link_email;
        } else if (data.link) {
            link = data.link;
        }

        return (
            <div className="career-item col col-xs-12 col-sm-6 col-md-4">
                {link &&
                    <a
                        href={link}
                        title={title}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="career-title"
                    >
                        {data.title}
                    </a>
                }
                {!link &&
                    <span className="career-title">{data.title}</span>
                }
                <span className="career-label">{data.label}</span>
            </div>
        )
    }
}

CareerLink.propTypes = {
    data: PropTypes.object
};

export default withRouter(CareerLink);

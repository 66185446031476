/**
 * Dashboard navigation (shared)
 *
 */
export const dashboardNav = [
    {
        title: 'My Xrathus',
        slug: 'nav-dashboard-my-xrathus',
        route: '/x/connect/my-xrathus',
    },
    {
        title: 'My Hub',
        slug: 'nav-dashboard-my-hub',
        route: '/x/hub/my-hub',
    },
    {
        title: 'My Training',
        slug: 'nav-dashboard-my-training',
        route: '/x/training/my-training',
    },
    {
        title: 'My Marketplace',
        slug: 'nav-dashboard-my-marketplace',
        route: '/x/marketplace/my-marketplace',
    },
];

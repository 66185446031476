import React from 'react';
import moment from 'moment';
import WeekCalendar from 'react-week-calendar';
import CalendarWeeklyDayItem from './CalendarWeeklyDayItem';
import CalendarWeeklyEvent from './CalendarWeeklyEvent';
import CalendarWeeklyNavigation from './CalendarWeeklyNavigation';
import PropTypes from 'prop-types';

let now = new Date();
class CalendarWeekly extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIntervals: [],
            activeStartDate: new Date(now.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1))),
            isDetailed: false,
            resetEvents: true,
        };
    }

  setActiveStartDate = (activeStartDate) => {
      this.setState({activeStartDate: activeStartDate});
  }

  setReset = async(value) => {
      await this.setState({resetEvents: value, isDetailed: this.props.isDetailed});
  }

  componentDidMount() {
      //convert the page events to selected interval format
      if (this.state.resetEvents === true) {
          this.updateEvents();
          this.setReset(false);
      }
  }

  componentDidUpdate() {
      if (this.state.resetEvents === true) {
          this.updateEvents();
          this.setReset(false);
      }
  }

  handleSelect = () => {

  }

  handleEventClick = () => {
  }

  updateEvents() {
      let tempIntervals = [];
      if (this.props.allEvents) {
          //sort all the events by date
          let sortedEvents = this.props.allEvents.sort(function(a,b) {
              return new Date(a.start_date+" UTC") - new Date(b.start_date+" UTC");
          });

          for (let i=0; i < sortedEvents.length; i++) {
              //find number of previous events on the same day
              let tempSameDayEvents = 0;
              let tempCurDate = new Date(sortedEvents[i].start_date+" UTC");
              if (!this.props.isDetailed) {
                  for (let j=0; j < tempIntervals.length; j++) {
                      let tempPrevDate = tempIntervals[j].start.toDate();
                      if (tempCurDate.getFullYear() === tempPrevDate.getFullYear() && 
                    tempCurDate.getMonth() === tempPrevDate.getMonth() &&
                    tempCurDate.getDate() === tempPrevDate.getDate()) {
                          tempSameDayEvents+=1;
                      }
                  }
              }

              tempIntervals.push({
                  uid: i+1,
                  start: moment(new Date(sortedEvents[i].start_date+" UTC")),
                  end: moment(new Date(sortedEvents[i].end_date+" UTC")),
                  value: sortedEvents[i].title,
                  eventInfo: sortedEvents[i],
                  sameDayEvents: tempSameDayEvents
              });
          }
          if (tempIntervals.length > 0)
              this.setState({selectedIntervals: tempIntervals});
      }
  }



  static getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.isDetailed !== nextProps.isDetailed) {
          return {
              resetEvents: true,
          };
      }

      return null;
  }

  render() {

      let tempScaleUnit = 60;
      let tempScaleFormat = 'HH:mm';
      let tempCellHeight = 15;

      if (!this.props.isDetailed) {
          tempScaleUnit = 3600;
          tempScaleFormat = ' ';
          tempCellHeight = 200;
      }

      return (
          <div>
              <CalendarWeeklyNavigation
                  activeStartDate = {this.state.activeStartDate}
                  setActiveStartDate = {this.setActiveStartDate}
              />
              <WeekCalendar
                  firstDay = {moment(this.state.activeStartDate)}
                  startTime = {moment({h: 0, m: 0})}
                  endTime = {moment({h: 23, m: 59})}
                  numberOfDays= {7}
                  dayFormat = {'ddd, DD'}
                  scaleFormat = {tempScaleFormat}
                  scaleUnit = {tempScaleUnit}
                  useModal = {false}
                  cellHeight = {tempCellHeight}
                  dayCellComponent = {CalendarWeeklyDayItem}
                  eventComponent = {CalendarWeeklyEvent}
                  onIntervalSelect = {this.handleSelect}
                  selectedIntervals = {this.state.selectedIntervals}
                  onEventClick = {this.handleEventClick}
              />
          </div>
      
      );
  }
}

CalendarWeekly.propTypes = {
    /**
     * All Events
     */
    allEvents: PropTypes.array,
    /**
     * Is detailed
     */
    isDetailed: PropTypes.bool,
};

export default CalendarWeekly;
import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import APIEventConfiguration from '../utils/APIEventConfiguration';
import FormEventConfigDefault from '../partials/forms/FormEventConfigDefault';
import FormEventConfigUser from '../partials/forms/FormEventConfigUser';

/**
 * Page: Update Global Event Configuration
 *
 */
class XEventConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshDefaultConfigs: false,
        };
    }

    updateRefresh = (refresh) => {
        this.setState({
            refreshDefaultConfigs: refresh
        });
    };

    render() {
        const {
            user
        } = this.props;

        const {
            refreshDefaultConfigs
        } = this.state;

        const username = (user.name) ? ' ' + user.name : '';

        if (!user.is_sysop) {
            return (
                <>
                    <div className="content user">
                        <h1 className="page-title">
                            Xrathus Administration
                        </h1>
                        <p>
                            Sorry{username}, you do not have access to this area.
                        </p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="content">
                    <h1 className="page-title">
                        Event Configuration
                    </h1>

                    <div className="panel no-padding">

                        <h3 className="header">Edit Global Event Configuration</h3>

                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormEventConfigDefault onConfigUpdated={() => this.updateRefresh(true)}/>
                                    <br/>
                                </div>
                            </div>
                        </div>

                        <h3 className="header">Edit Personal Event Configuration for User</h3>

                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormEventConfigUser/>
                                    <br/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-btns right mb-2 mr-2">
                                    <Link to="/x/admin"
                                          title="Back to Admin"
                                          className="btn btn-primary"
                                    >
                                        Back to Admin
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <APIEventConfiguration refresh={refreshDefaultConfigs} onResult={() => this.updateRefresh(false)}/>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event_configuration_refresh: state.event_configuration_refresh,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

export default withKeycloak(
    connect(
        mapStateToProps
    )(XEventConfiguration)
);

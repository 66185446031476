import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {setEventConfigUserRefresh, setEventListRefresh} from '../../store/actions';
import FormEventEdit from '../../partials/forms/FormEventEdit';
import FormEventConfigEdit from "../../partials/forms/FormEventConfigEdit";
import {getEventPermissions, inUserList} from '../../utils/projectFunctions';
import '../../css/challenge.css';


/**
 * Page: Create Event form
 */
class XConnectEventEdit extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        const eventType = (this.props.location.pathname.indexOf('personal') !== -1) ? 'personal' : 'public';
        const eventId = pathArr[pathArr.length - 2];
        this.state = {
            event: null,
            eventId: eventId,
            eventConfig: {
                max_shares: null,
            },
            formStage: 'event',
            eventType: eventType
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        if (e === 'event' && status === 'complete') {
            this.setState({
                eventId: data.id,
                eventConfig: {
                    max_shares: data.max_shares ?? null
                }
            }, () => {
                // Refresh User Configuration and Events
                this.props.setEventConfigUserRefresh(true);
                this.props.setEventListRefresh('personal_own');
            })
        }
    };

    render() {
        const {
            event_config,
            event_list_own,
            event_list_subscribed,
            user
        } = this.props;

        const {
            eventId,
            eventConfig,
            eventType,
            formStage
        } = this.state;
        const inSubscribedEvents = (inUserList(event_list_subscribed, eventId));
        const eventPermissions = getEventPermissions(user, event_list_own, eventId);

        // Display Edit Event form
        return (
            <>
                <div className="content xhub event">
                    <h1 className="page-title">
                        Edit a Personal Event
                    </h1>

                    {!user.is_sysop && !inSubscribedEvents && !eventPermissions.owner &&
                        <div className="row">
                            <div className="col col-xs-12" key="projects-empty">
                                <div className="panel">
                                    You are not authorized to edit this Project.
                                </div>
                            </div>
                        </div>
                    }
                    {(user.is_sysop || inSubscribedEvents || eventPermissions.owner) &&
                        <div className="panel no-padding">
                            <h3 className="header">Personal Event</h3>
                            <div className="row">
                                <div className="col col-xs-12">
                                    <div className="form-container">
                                        <FormEventEdit
                                            eventId={eventId}
                                            formStage={formStage}
                                            onFormEvent={this.handleFormEvent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {user.is_sysop &&
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="panel no-padding mb-0">
                                    <h3 className="header">Edit Personal Event Max Invites</h3>
                                    <FormEventConfigEdit
                                        eventType={eventType}
                                        eventId={eventId}
                                        eventConfig={eventConfig}
                                        defaultConfig={event_config}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        event_config: state.event_config,
        event_config_current: state.event_config_current,
        event_config_user: state.event_config_user,
        event_list_subscribed: state.event_list_subscribed,
        event_list_own: state.event_list_own,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

const mapDispatchToProps = {
    setEventConfigUserRefresh: setEventConfigUserRefresh,
    setEventListRefresh: setEventListRefresh
};

export default withKeycloak(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XConnectEventEdit)
);

import React from 'react';

const IconMenu = () => {
    return (
        <div className="menu-toggle-icon">
            <svg viewBox="0 0 100 80" width="40" height="34">
				<rect width="100" height="12" rx="8"/>
				<rect y="30" width="100" height="12" rx="8"/>
				<rect y="60" width="100" height="12" rx="8"/>
            </svg>
        </div>
    )
};

export default IconMenu;

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import {getEnvironmentConfig} from './utils/dataFunctions';
import App from './App';
import './css/index.css';
import * as serviceWorker from './serviceWorker';

let configParams = {
    api_url: '',
    auth_client_id: '',
    auth_realm: '',
    auth_url: '',
    forum_categories: '',
    forum_url: '',
    gitlab_url: '',
    google_analytics_id: '',
    google_measurement_id: '',
    lab_url: '',
    training_url: ''
};

localStorage.removeItem('server');
localStorage.removeItem('forum_categories');
localStorage.removeItem('forum_url');
localStorage.removeItem('gitlab_url');
localStorage.removeItem('lab_url');
localStorage.removeItem('training_url');

// Load values from public configuration file
let envConfigRequest = getEnvironmentConfig();

envConfigRequest
    .then(data => {
        if (data) {

            // Set values
            configParams.api_url = data.api_url;
            configParams.auth_client_id = data.auth_client_id;
            configParams.auth_realm = data.auth_realm;
            configParams.auth_url = data.auth_url;
            configParams.forum_categories = (data.forum_categories) ? data.forum_categories : {};
            configParams.forum_url = data.forum_url;
            configParams.google_analytics_id = data.google_analytics_id;
            configParams.google_measurement_id = data.google_measurement_id;
            configParams.gitlab_url = data.gitlab_url;
            configParams.lab_url = data.lab_url;
            configParams.training_url = data.training_url;

            // Store values to use for API calls
            localStorage.setItem('server', configParams.api_url);
            localStorage.setItem('forum_categories', JSON.stringify(configParams.forum_categories));
            localStorage.setItem('forum_url', configParams.forum_url);
            localStorage.setItem('gitlab_url', configParams.gitlab_url);
            localStorage.setItem('lab_url', configParams.lab_url);
            localStorage.setItem('training_url', configParams.training_url);

            // Create new Keycloak instance with parameters
            const keycloak = new window.Keycloak({
                realm: configParams.auth_realm,
                clientId: configParams.auth_client_id,
                url: configParams.auth_url
            });
            const keycloakInitConfig = {
                onLoad: 'check-sso',
                promiseType: 'native',
            };

            // Render the application
            ReactDOM.render(
                <Provider store={store}>
                    <App
                        keycloak={keycloak}
                        keycloakInitConfig={keycloakInitConfig}
                        configParams={configParams}
                    />
                </Provider>,
                document.getElementById('portal')
            );

            // Set server items
            serviceWorker.unregister();

        } else {
            return Promise.reject('Failed to load Server Configuration');
        }
    })
    .catch(error => {
        console.log('Server Configuration Error: ', error);
        return Promise.reject('Failed to load Server Configuration');
    });


serviceWorker.unregister();

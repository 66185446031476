import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resourcesProject';
import {setProjectConfigCurrent} from '../../store/actions';
import {makePostDataRequest} from '../../utils/postRequest';
import {getProjectPermissions} from '../../utils/projectFunctions';
import NumberInput from '../fields/NumberInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import ProjectConfigFields from './ProjectConfigFields';

/**
 * Form: Edit Project Configurations
 *
 * @param basePath
 * @param defaultConfig
 * @param projectType
 * @param projectConfig
 * @param projectId
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormProjectConfigEdit = (
    {
        basePath = '',
        defaultConfig,
        projectType,
        projectConfig,
        projectId,
        ...props
    }) => {

    // Set up form and state
    const {register, errors, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    const [projectValues, setProjectValues] = useState(null);

    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            saving: false,
            success: false
        }
    );

    const setDefaultConfigMessage = (type) => {
        if (defaultConfig[type]) {
            return ' The default is ' + defaultConfig[type] + '.';
        }
        return '';
    };

    // Callback: Set Configuration values and update form values
    const updateConfigurations = useCallback((data, defaultConfig) => {
        setValue('max_shares', data?.max_shares ?? defaultConfig.max_shares);
        setValue('max_dataset_size', data?.max_dataset_size ?? defaultConfig.max_dataset_size);
        setValue('max_volumes', data?.max_volumes ?? defaultConfig.max_volumes);
    }, [setValue]);

    // Effect: Get Project data
    useEffect(() => {
        const updateData = () => {
            setProjectValues(projectConfig);
            props.setProjectConfigCurrent({
                max_dataset_size: projectConfig.max_dataset_size ?? null,
                max_shares: projectConfig.max_shares ?? null,
                max_volumes: projectConfig.max_volumes ?? null,
            });
        };

        return updateData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, projectConfig]);

    // Effect: Update Project Configuration values when global values are populated
    useEffect(
        () => {
            const updateConfig = () => {
                const max_shares = projectValues?.max_shares ?? null;
                const max_dataset_size = projectValues?.max_dataset_size ?? null;
                const max_volumes = projectValues?.max_volumes ?? null;
                let config = {
                    max_shares: (!max_shares || max_shares === '') ? defaultConfig.max_shares : max_shares,
                    max_dataset_size: (!max_dataset_size || max_dataset_size === '') ? defaultConfig.max_dataset_size : max_dataset_size,
                    max_volumes: (!max_volumes || max_volumes === '') ? defaultConfig.max_volumes : max_volumes
                };
                updateConfigurations(config, defaultConfig);
            };

            return updateConfig();

        }, [defaultConfig, projectValues, updateConfigurations]
    );

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            getValues('max_dataset_size') === '' ||
            getValues('max_shares') === '' ||
            getValues('max_volumes') === '' ||
            (errors &&
                (
                    errors.max_dataset_size ||
                    errors.max_shares ||
                    errors.max_volumes
                ))
        );
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // Set parameters from form state
            data.id = projectId;

            // Reset Configurations if they equal the defaults
            const max_dataset_size = parseInt(data.max_dataset_size, 10);
            const max_shares = parseInt(data.max_shares, 10);
            const max_volumes = parseInt(data.max_volumes, 10);
            data.max_dataset_size = (max_dataset_size === defaultConfig.max_dataset_size) ? null : max_dataset_size;
            data.max_shares = (max_shares === defaultConfig.max_shares) ? null : max_shares;
            data.max_volumes = (max_volumes === defaultConfig.max_volumes) ? null : max_volumes;

            console.log('submit: ', data);

            // Submit form to API
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'ProjectPersonalConfig', 'UPDATE', data);
        request.then(result => {
            if (result && result.id) {
                updateSuccessState({saving: false, success: true, message: ''});
                // Store Project Configuration for Dataset
                props.setProjectConfigCurrent({
                    max_dataset_size: result.max_dataset_size ?? null,
                    max_shares: result.max_shares ?? null,
                    max_volumes: result.max_volumes ?? null,
                });
                return Promise.resolve(result);
            } else {
                updateSuccessState({
                    saving: false,
                    success: false,
                    message: 'Sorry, Project Configurations could not be updated.'
                });
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Update Project Configurations Error: ', error);
            updateSuccessState({
                saving: false,
                success: false,
                message: 'Sorry, there was an error updating Project Configurations: ' + error
            });
            return Promise.reject('Server Error');
        });
    };

    const handleConfigFieldUpdate = (e) => {
        // Update values from ProjectConfigFields (Project, User, Global)
        updateConfigurations(e, defaultConfig);
    };

    const projectPath = (projectType === 'personal' || projectType === 'personal_own') ? 'project/personal' : 'project';
    const projectPermissions = getProjectPermissions(props.user, props.project_list_own, projectId, null);
    const showConfig = (projectPermissions.sysop || projectPermissions.owner);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">
                <div className="item-content full">
                    <div className="tab-content active">
                        <p className="instructions">
                            If this Project should be allowed to override global configurations for
                            Sharing, Dataset Size and Volumes, set values.
                        </p>
                        {projectValues?.username && showConfig &&
                        <ProjectConfigFields
                            viewType="edit"
                            project={projectValues}
                            ownProject={projectPermissions.owner}
                            config={defaultConfig}
                            onConfigUpdated={(e) => handleConfigFieldUpdate(e)}
                        />
                        }
                        <div className="row">
                            <div className="form-group col col-xs-6">
                                <NumberInput
                                    id="config-max-shares"
                                    name="max_shares"
                                    label="Max Number of Shares"
                                    instructions={`The maximum number of times this Project may be shared with other users.${setDefaultConfigMessage('max_shares')}`}
                                    min={0}
                                    max={1000}
                                    step={1}
                                    defaultValue={null}
                                    aria-describedby="error-max_shares-required"
                                    aria-invalid={errors.max_shares ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    className={`form-input ${errors.max_shares ? 'input-error' : ''}`}
                                >
                                    {errors.max_shares && errors.max_shares.message}
                                </NumberInput>
                            </div>
                            <div className="form-group col col-xs-6">
                                <NumberInput
                                    id="config-max-dataset-size"
                                    name="max_dataset_size"
                                    label="Max Dataset Size"
                                    instructions={`The maximum size (in GB) of a Dataset and its files.${setDefaultConfigMessage('max_dataset_size')}`}
                                    min={1}
                                    max={100}
                                    step={1}
                                    aria-describedby="error-max_dataset_size-required"
                                    aria-invalid={errors.max_dataset_size ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    className={`form-input ${errors.max_dataset_size ? 'input-error' : ''}`}
                                >
                                    {errors.max_dataset_size && errors.max_dataset_size.message}
                                </NumberInput>
                            </div>
                            <div className="form-group col col-xs-6">
                                <NumberInput
                                    id="config-max-volumes"
                                    name="max_volumes"
                                    label="Max Number of Volumes"
                                    instructions={`The maximum number of versioned Dataset volumes this Project may possess.${setDefaultConfigMessage('max_volumes')}`}
                                    min={0}
                                    max={1000}
                                    step={1}
                                    aria-describedby="error-max_volumes-required"
                                    aria-invalid={errors.max_volumes ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    className={`form-input ${errors.max_volumes ? 'input-error' : ''}`}
                                >
                                    {errors.max_volumes && errors.max_volumes.message}
                                </NumberInput>
                            </div>
                        </div>
                    </div>

                    <div className="form-btns right mb-1">
                        {successState.saving &&
                        <>
                            <p className="message">
                                Saving Project Configurations...
                            </p>
                            <LoadingIndicator color="dark" centered={false} active={true}/>
                        </>
                        }
                        {successState.success &&
                        <p className="message">
                            Project Configurations updated.
                        </p>
                        }
                        {!successState.success && successState.message !== '' &&
                        <p className="message">
                            {successState.message}
                        </p>
                        }
                        <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                            Update Project Configuration
                        </button>
                    </div>
                    <div className="form-btns right mb-1">
                        {successState.success &&
                        <Link className="btn btn-primary" to={`${basePath}/x/hub/${projectPath}/${projectId}/overview`}>
                            View Project Details
                        </Link>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        project_config: state.project_config,
        project_list_own: state.project_list_own,
        user: state.user
    };
};

const mapDispatchToProps = {
    setProjectConfigCurrent: setProjectConfigCurrent
};

FormProjectConfigEdit.propTypes = {
    basePath: PropTypes.string,
    defaultConfig: PropTypes.any,
    projectType: PropTypes.any,
    projectConfig: PropTypes.any,
    projectId: PropTypes.any,
    setProjectConfigCurrent: PropTypes.any,
    user: PropTypes.any,
    project_list_own: PropTypes.any,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormProjectConfigEdit);
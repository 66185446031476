import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

/**
 * Component to display News/Post excerpt
 *
 */
class NewsLink extends Component {
    render() {
        const {
            data
        } = this.props;

        return (
            <div className="news-excerpt">
                <div className="excerpt-details">
                    <span className="excerpt-title">
                        <span className="excerpt-date">{data.dateStr}:</span>
                        <ReactMarkdown source={data.title} linkTarget="_blank"/>
                    </span>
                    <span className="excerpt">
                        <ReactMarkdown source={data.excerpt} linkTarget="_blank"/>
                    </span>
                </div>
            </div>
        )
    }
}

NewsLink.propTypes = {
    data: PropTypes.object
};

export default withRouter(NewsLink);

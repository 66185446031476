import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Storyblok from "../../utils/storyblok-client";

import '../../css/static.css';

export default class XClusterCollaborators extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collabEntries: []
        };
    }

    async componentDidMount() {
        // Load in the localStorage items that exist

        // Page specific
        const collaborators = JSON.parse(localStorage.getItem(`stories/collaborators`));
        if (collaborators !== this.state.collabEntries && collaborators) {
            this.setState({collabEntries: collaborators});
        }

        //load in full list and filters of collaborators from storyblok

        let params = {
            version: process.env.REACT_APP_STORYBLOK_API_KEY_TYPE,
            cv: Date.now(),
            starts_with: "collaborators/",
            per_page: 25,
            excluding_fields: "body,content,bread_crumb_steps_to_jump,component,custom_body_css,"+
                              "custom_link,custom_logo_css,dataset,full_slug,group_filter,header,"+
                              "hide_bread_crumbs,hide_logo,image,intro,no_document_margins,"+
                              "no_table_of_contents,only_available_to_user_groups,"+
                              "publisher_logo,publisher_name,require_knowledge_base,subtitle,"+
                              "tags,title,title_in_sidebar,title_on_page,"+
                              "title_style,_editable,_uid"
        };

        const resp = await Storyblok.get(`cdn/stories/`, params);

        let totalStories = [];
        if (resp && resp.data && resp.data.stories) {
            totalStories = resp.data.stories;
            const perPage = 25;
            if (resp.headers && resp.headers.total && resp.headers.total > perPage) {
                let totalPages = Math.ceil(resp.headers.total/perPage);
                for (let i = 2; i<=totalPages; i++) {
                    params.page = i;
                    const respAdditional = await Storyblok.get(`cdn/stories/`, params);
                    totalStories = totalStories.concat(respAdditional.data.stories);
                }
            }
        }

        //step 1: create parent menus from slugs
        let uniqueFilters = [];
        let formattedFilters = [];
        for (let i = 0; i < totalStories.length; i++ ) {
            let tempString = totalStories[i].full_slug;
            let tempFilter = tempString.split("/")[1];
            if (!uniqueFilters.includes(tempFilter)) {
                let formattedTitle = tempFilter.charAt(0).toUpperCase() + tempFilter.slice(1);
                let formattedFilter = {};
                if (totalStories[i].name !== formattedTitle) {
                    formattedFilter = {
                        title: totalStories[i].content.title_on_summary_page ? totalStories[i].content.title_on_summary_page : formattedTitle,
                        route: "/x/connect/collaborators/"+tempFilter,
                        priority: totalStories[i].content.priority,
                        links: [
                            {
                                title: totalStories[i].name,
                                route: '/x/connect/collaborators/' + tempFilter + '/' + totalStories[i].slug,
                                logo: totalStories[i].content.logo ? totalStories[i].content.logo.filename : null,
                                priority: totalStories[i].content.priority,
                            }
                        ]
                    };

                    formattedFilters.push(formattedFilter);
                    uniqueFilters.push(tempFilter);
                }
            }
            else {
                formattedFilters[uniqueFilters.indexOf(tempFilter)]['links'].push(
                    {
                        title: totalStories[i].content.title_on_summary_page ? totalStories[i].content.title_on_summary_page : totalStories[i].name,
                        route: '/x/connect/collaborators/' + tempFilter + '/' + totalStories[i].slug,
                        logo: totalStories[i].content.logo ? totalStories[i].content.logo.filename : null,
                        priority: totalStories[i].content.priority,
                    }
                );
            }
        }

        //step 2: sort filters by priority
        formattedFilters.sort(function (a, b) {
            if (a.priority > b.priority) return 1;
            if (a.priority < b.priority) return -1;
            return 0;
        });

        //step 3: alphabetize all the elements inside the filters
        for (let i = 0; i < formattedFilters.length; i++) {
            if ('links' in formattedFilters[i])
                formattedFilters[i]['links'].sort(function (a, b) {
                    if (a.title < b.title) return -1;
                    if (a.title > b.title) return 1;
                    return 0;
                });
        }

        if (this.state.collabEntries !== formattedFilters) {
            localStorage.setItem(`stories/collaborators`, JSON.stringify(formattedFilters));
            this.setState({collabEntries: formattedFilters});
        }

        
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = ()=>{
            return;
        };
    }



    render() {

        const {
            collabEntries
        } = this.state;

        return (
            <>
                <div className="content xconnect">
                    <h1 className="page-title">
                        Our Collaborators
                    </h1>
                    <br/>
                    <div className="">
                        {
                            collabEntries.map((element, index) => {return (
                                <div key={index}>
                                    <h2>
                                        {element.title}
                                    </h2>
                                    <div className="row">
                                        {element['links'].map((element, index) => {
                                            return (
                                                <div className="col col-xs-12 col-sm-6 col-md-4" key={index}>
                                                    <Link to={element.route}
                                                        title={element.title}
                                                        className={"btn btn-image collaborators"}
                                                    >
                                                        <div className="btn btn-image collaborator">
                                                            <img src={element.logo}
                                                                alt={element.title} className="btn btn-image"/>
                                                        </div>
                                                        {element.title}
                                                    </Link>
                                                    <Link to={element.route}
                                                        title={element.title}
                                                        className="btn btn-primary btn-image-text small"
                                                    >
                                                        {element.title}
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                &nbsp;
                                    <br/>
                                &nbsp;
                                </div>
                            
                            );} )
                        }
                    </div>
                </div>
            </>
        );
    }
}
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as eventResources} from '../../resourcesEvent';
import {RESOURCES as projectResources} from '../../resourcesProject';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import Modal from '../modal/Modal';

/**
 * Button with Unsubscribe User functionality for Projects and Events
 *
 * @param eventId
 * @param projectId
 * @param username
 * @param onFormEvent
 * @param type
 * @param isOwnerOrSysop
 * @returns {JSX.Element}
 * @constructor
 */
const UnsubscribeUserButton = (
    {
        eventId,
        projectId,
        username,
        onFormEvent,
        type = 'unsubscribe',
        isOwnerOrSysop = true
    }) => {

    const [modalStatus, setModalStatus] = useState(false);
    const [unsubscribeNotes, setUnsubscribeNotes] = useState('');
    const [unsubscribeError, setUnsubscribeError] = useState(null);

    const handleNotesUpdate = (e) => {
        setUnsubscribeNotes(e?.value ?? '');
    };

    const handleConfirmation = () => {
        let request;
        let params = {
            username: username,
            unsubscribed_notes: unsubscribeNotes
        };
        if (eventId) {
            params.event_id = eventId;
            request = makePostDataRequest(eventResources, 'EventPersonalUnsubscribe', 'POST', params);
        }
        if (projectId) {
            params.project_id = projectId;
            request = makePostDataRequest(projectResources, 'ProjectPersonalUnsubscribe', 'POST', params);
        }
        request.then(result => {
            if (result && result === 'OK') {
                setModalStatus(false);
                setUnsubscribeError(null);
                if (typeof onFormEvent === 'function') {
                    onFormEvent();
                }
                return Promise.resolve(result);
            } else if (result.error) {
                setUnsubscribeError('Sorry, this user could not be subscribed. Error: ' + result.error);
            } else {
                console.log('Error: Unable to Unsubscribe User');
                setUnsubscribeError('Sorry, this user could not be subscribed.');
                return Promise.reject('Unsubscribe failed');
            }
        }).catch(error => {
            console.log('Error: ', error);
            setUnsubscribeError('Sorry, this user could not be subscribed.');
            return Promise.reject('Server Error');
        });
    };

    const btn = (type === 'reject') ? 'Remove User' : 'Unsubscribe User';

    return (
        <>
            <button
                type="button"
                className="btn btn-primary table"
                title={btn}
                onClick={() => setModalStatus(true)}
            >
                {btn}
            </button>
            <Modal
                allowClose={true}
                id="modal-unsubscribe"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">
                        Are you sure you want to unsubscribe {username}?
                    </h3>
                    {eventId &&
                        <>
                            <p>This will disconnect all Event associations from this user.</p>
                            {type === 'reject' &&
                                <p>
                                    Note: This user has rejected an invitation to this Event.
                                </p>
                            }
                        </>
                    }
                    {projectId &&
                        <>
                            <p>This will disconnect all Project and Dataset associations from this user.</p>
                            {type === 'reject' &&
                                <p>
                                    Note: This user has rejected an invitation to this Project.
                                </p>
                            }
                        </>
                    }

                    {!isOwnerOrSysop &&
                    <>
                        <p>If desired, add brief notes regarding this unsubscription:</p>
                        <div className="form-group ml-1 mr-1">
                            <BaseInput
                                type="text"
                                id="unsubscribe_notes"
                                name="unsubscribe_notes"
                                label="Notes / Reason"
                                className="form-input"
                                changeFunc={(e) => handleNotesUpdate(e)}
                            >
                            </BaseInput>
                        </div>
                    </>
                    }

                    {unsubscribeError &&
                    <p className="error">
                        {unsubscribeError}
                    </p>
                    }

                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleConfirmation}>YES</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

UnsubscribeUserButton.propTypes = {
    username: PropTypes.string
};

export default UnsubscribeUserButton;

import jwt_decode from 'jwt-decode';

/**
 * Decode a JWT token and return its payload
 *
 * @param token
 * @return {any | undefined}
 */
export const decodeToken = (token) => {
    return jwt_decode(token);
};

/**
 * Determine if a user has a group among their roles
 *
 * @param groups
 * @param group
 * @return {boolean}
 */
export const hasGroup = (groups, group) => {
    const idx = groups.findIndex(r => r === group);
    return (idx !== -1);
};

/**
 * Check if a token's expiration has passed
 *
 * @param exp
 * @return {boolean}
 */
export const isTokenExpired = (exp) => {
    let nowDate = new Date();
    let expDate = new Date((exp * 1000));
    return (expDate - nowDate) <= 0;
};

/**
 * Extract User information from token
 *
 * @param token
 * @return {{email_verified: (*|string), name: (*|string), preferred_username: (*|string), given_name: (*|string), family_name: (*|string), email: (*|string)}|null}
 */
export const getUserInfoFromToken = (token) => {
    const dt = decodeToken(token);
    if (dt) {

        // Extract sysop and groups from roles
        const roles = (dt.realm_access && dt.realm_access.roles) ? dt.realm_access.roles : [];
        let isSysop = (roles.findIndex(r => r === 'sysop') !== -1);

        return {
            email: (dt && dt.email) ? dt.email : '',
            email_verified: (dt && dt.email_verified) ? dt.email_verified : '',
            family_name: (dt && dt.family_name) ? dt.family_name : '',
            given_name: (dt && dt.given_name) ? dt.given_name : '',
            groups: roles,
            is_sysop: isSysop,
            name: (dt && dt.name) ? dt.name : '',
            preferred_username: (dt && dt.preferred_username) ? dt.preferred_username : ''
        };
    }
    return null;
};

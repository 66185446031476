import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../../utils/eventEmitter';
import {setChallengeListSubscribedRefresh} from '../../store/actions';
import {isBlank} from '../../utils/dataFunctions';
import {assembleAirmeetPath, inUserList} from '../../utils/projectFunctions';
import {hasGroup} from '../../utils/tokenFunctions';
import APIChallenges from '../../utils/APIChallenges';
import APIProjectDataset from '../../utils/APIProjectDataset';
import AirmeetSessionsTable from '../../partials/AirmeetSessionsTable';
import ChallengeDetail from '../../partials/ChallengeDetail';
import ClusterTable from '../../partials/ClusterTable';
import FormAirmeetSessionCreate from '../../partials/forms/FormAirmeetSessionCreate';
import '../../css/challenge.css';

/**
 * Page: Single Challenge with additional panels
 */
class XConnectChallenge extends Component {
    constructor(props) {
        super(props);

        // Get Project ID from path: /x/{substrate}/challenge/{id}/{subpage}
        const pathArr = this.props.location.pathname.split('/');
        const challengeIdx = pathArr.findIndex(c => c === 'challenge');
        const challengeId = Number(pathArr[challengeIdx + 1]);
        const hasUserList = !!(this.props.challenge_list_subscribed?.length);
        this.state = {
            challenge: null,
            challengeId: challengeId,
            challengeRefresh: false,
            challengeTab: 'overview',
            dataId: null,
            dataset: null,
            datasetFiles: [],
            hasUserList: hasUserList
        };

        this.launchAirmeetView = this.launchAirmeetView.bind(this);
        this.setChallenge = this.setChallenge.bind(this);
    }

    componentDidMount() {
        Emitter.on('setChallengeTab', (e) => this.setChallengeTab(e));
    }

    componentWillUnmount() {
        Emitter.off('setChallengeTab');
    }

    // Set Challenge information and Data ID
    setChallenge = (data) => {

        // @TODO store in redux as current challenge for sidebar routes

        let dataId = (data && data.data_id) ? data.data_id : null;
        this.setState({
            challenge: data,
            dataId: dataId
        });
    };

    // Set Dataset description
    setDataset = (data) => {
        this.setState({
            dataset: data
        });
    };

    // Set Dataset File list
    setDatasetFiles = (data) => {
        this.setState({
            datasetFiles: data
        });
    };

    // Set the current tab of the Challenge detail
    setChallengeTab = (tab) => {
        this.setState({
            challengeTab: tab
        });
    };

    refreshChallenge = () => {
        this.setState({
            challengeRefresh: true
        }, () => {
            this.setState({
                challengeRefresh: false
            });
        });
    };

    // Notify the API Challenges component to update the User Challenge List
    handleJoinChallenge = () => {
        this.refreshChallenge();
        this.props.setChallengeListSubscribedRefresh(true);
    };

    launchAirmeetView = (projectType, challengeId, type, uuid, launch = true) => {
        const path = assembleAirmeetPath(projectType, challengeId, type, uuid);
        if (launch && path) {
            this.props.history.push(path);
        }
    };

    render() {
        const {
            challenge_list_subscribed,
            user
        } = this.props;

        const {
            challengeId,
            challengeRefresh,
            challengeTab,
            dataId,
            dataset,
            datasetFiles
        } = this.state;

        let {
            challenge
        } = this.state;

        const hasLab = hasGroup(user.groups, 'lab');
        const isCreator = (challenge && challenge.username === user.preferred_username);
        const inSubscribedChallenges = (inUserList(challenge_list_subscribed, challengeId));
        const airmeetView = (user.is_sysop) ? 'sysop' : 'user';
        const hasAirmeet = (challenge && !isBlank(challenge.airmeet_id));

        return (
            <>
                <div className="content xconnect challenge">
                    {challenge &&
                        <>
                            <h1 className="page-title">
                                {challenge.title}
                            </h1>

                            {/* Row: Challenges */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <ChallengeDetail
                                        projectItem={challenge}
                                        projectDataset={dataset}
                                        projectFiles={datasetFiles}
                                        onAirmeetLink={(type, uuid) => this.launchAirmeetView('challenge', challengeId, type, uuid, true)}
                                        onJoinFunc={() => this.handleJoinChallenge()}
                                        allowLab={hasLab}
                                        inSubscribedChallenges={inSubscribedChallenges}
                                        tab={challengeTab}
                                        isCreator={isCreator}
                                        isSysop={user.is_sysop}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {/* Row: Leaderboard / Submissions */}

                    <div className="row">
                        <div className="col col-xs-12 col-sm-6">
                            <div className="panel">
                                <h3 className="header">Leaderboard</h3>
                                <br/>
                                <Link to="#"
                                      title="Leaderboard" target="_blank" rel="noopener noreferrer"
                                      className="btn btn-primary"
                                >
                                    View
                                </Link>
                            </div>
                        </div>
                        <div className="col col-xs-12 col-sm-6">
                            <div className="panel">
                                <h3 className="header">Submissions</h3>
                                <br/>
                                <Link to="#"
                                      title="View Submissions" target="_blank" rel="noopener noreferrer"
                                      className="btn btn-primary"
                                >
                                    View
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Row: Discourse Forum/Cluster Links */}



                    {challenge && challenge.discourse_id &&
                        <div className="row">
                            <div className="col col-xs-12">
                                <h3 className="section-header full mt-0">Clusters</h3>
                            </div>
                            <div className="col col-xs-12">
                                <div className="panel no-padding">
                                    <h3 className="header">Challenge Discussions</h3>
                                    <ClusterTable discourseId={challenge.discourse_id} height="auto"/>
                                </div>
                            </div>
                        </div>
                    }

                    {(user.is_sysop || inSubscribedChallenges) && challenge && challengeId && hasAirmeet &&
                        <>
                            {/* Row: Airmeet Sessions Table and Create button */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <div className="panel no-padding">
                                        <h3 className="header">Real Time Collaboration Sessions</h3>

                                        {(user.is_sysop) &&
                                            <FormAirmeetSessionCreate
                                                itemType="challenge"
                                                item={challenge}
                                                onFormEvent={() => this.refreshChallenge()}
                                            />
                                        }

                                        <AirmeetSessionsTable
                                            airmeetView={airmeetView}
                                            itemId={challengeId}
                                            inPanel={false}
                                            refresh={challengeRefresh}
                                            onAirmeetLink={(type, uuid) => this.launchAirmeetView('challenge', challengeId, type, uuid, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <APIChallenges
                    getType="single"
                    id={challengeId}
                    onResult={this.setChallenge}
                    refresh={challengeRefresh}
                />

                {dataId &&
                    <APIProjectDataset
                        type="dataset"
                        id={dataId}
                        onDataResult={this.setDataset}
                        onFileListResult={this.setDatasetFiles}
                        refresh={false}/>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_subscribed: state.challenge_list_subscribed,
        user: state.user
    };
};

const mapDispatchToProps = {
    setChallengeListSubscribedRefresh: setChallengeListSubscribedRefresh
};

XConnectChallenge.propTypes = {
    user: PropTypes.any,
    location: PropTypes.any,
    challenge_list_subscribed: PropTypes.array,
    setChallengeListSubscribedRefresh: PropTypes.any,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XConnectChallenge)
);

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import {inUserList} from '../../utils/projectFunctions';
import APIAirmeetSessions from '../../utils/APIAirmeetSessions';
import APIEvents from '../../utils/APIEvents';
import LoadingIndicator from '../../partials/LoadingIndicator';
import {airmeetStyles} from '../../utils/stylesAirmeet';

/**
 * Page: Airmeet embed for specified Event
 */
class XConnectEventAirmeet extends Component {
    constructor(props) {
        super(props);
        // Get Event type and ID from path
        const pathArr = props.location.pathname.split('/');
        const eventIdx = pathArr.findIndex(c => c === 'event');
        let eventType = pathArr[eventIdx + 1];
        let eventId;
        if (eventType === 'personal') {
            // If path is /x/{substrate}/event/type/{id}/{subpage}
            eventId = Number(pathArr[eventIdx + 2]);
        } else {
            // If path is /x/{substrate}/event/{id}/{subpage}
            eventId = pathArr[(pathArr.length - 1)];
            eventType = null;
        }
        // Get Airmeet type and UUID from path
        const airmeetIdx = pathArr.findIndex(c => c === 'rtc');
        let airmeetType = pathArr[airmeetIdx + 1];
        let airmeetId = pathArr[airmeetIdx + 2];

        this.state = {
            event: null,
            eventId: eventId,
            eventType: eventType,
            airmeetType: airmeetType,
            airmeetId: airmeetId,
            airmeetUrl: null
        };
    }

    // Store Event and set the Airmeet URL
    setEvent = (data) => {
        this.setState({
            event: data
        }, () => {
            if (this.state.airmeetType === 'e') {
                this.setAirmeetURL(null);
            }
        });
    };

    // Set the Airmeet URL from the Event or Session Entry Link
    setAirmeetURL = (sessions) => {
        let url;
        if (sessions) {
            const session = sessions.find(r => r.session_id === this.state.airmeetId);
            url = session?.entry_link ?? null;
        } else {
            url = this.state.event?.entry_link ?? null;
        }
        this.setState({
            airmeetUrl: url
        });
    };

    render() {
        const {
            event_list_own,
            event_list_subscribed,
            user
        } = this.props;

        const {
            airmeetType,
            airmeetUrl,
            event,
            eventId,
            eventType
        } = this.state;

        const inSubscribedEvents = (inUserList(event_list_subscribed, eventId));
        const inOwnEvents = (inUserList(event_list_own, eventId));

        return (
            <div className="content xhub event">
                {/* User is not owner or has not joined Event */}
                {!user.is_sysop && !inSubscribedEvents && !inOwnEvents &&
                    <>
                        <div className="row">
                            <div className="col col-xs-12">
                                <h1 className="page-title" style={airmeetStyles.title}>
                                    {(event && event.name) ? event.name : 'Event'}
                                </h1>
                                <Link className="btn btn-primary mt-1 mr-0"
                                      to={`/x/connect/event/personal/${eventId}/overview`}>
                                    Back to Event
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-xs-12" key="events-empty">
                                <div className="panel">
                                    You do not have permission to access this Event's Airmeet
                                    Events or Sessions.
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* User is owner or has joined Event */}
                {(user.is_sysop || inSubscribedEvents || inOwnEvents) &&
                    <>
                        {!eventId &&
                            <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                        }

                        {event && eventId &&
                            <>
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h1 className="page-title" style={airmeetStyles.title}>
                                            {(event.name) ? event.name : 'Event'}
                                        </h1>
                                        <Link className="btn btn-primary mt-1 mr-0"
                                              to={`/x/connect/event/personal/${eventId}/overview`}>
                                            Back to Event
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-xs-12" style={airmeetStyles.container}>
                                        {airmeetUrl &&
                                            <Iframe
                                                id="Airmeet"
                                                url={airmeetUrl}
                                                display="flex"
                                                frameBorder="0"
                                                width="100%"
                                                height="100%"
                                                style={airmeetStyles.iframe}
                                                allow="camera *;microphone *;display-capture *;fullscreen *;"
                                            />
                                        }
                                    </div>
                                </div>

                                {airmeetType === 's' &&
                                    <APIAirmeetSessions
                                        id={eventId}
                                        onResult={(e) => this.setAirmeetURL(e)}
                                        refresh={false}
                                    />
                                }
                            </>
                        }

                        <APIEvents
                            getType="single"
                            viewType={eventType}
                            id={eventId}
                            onResult={this.setEvent}
                            refresh={false}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event_list_own: state.event_list_own,
        event_list_subscribed: state.event_list_subscribed,
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(XConnectEventAirmeet)
);

import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resourcesData';
import {makePostDataRequest} from '../../utils/postRequest';
import {runFileUploadTask} from '../../utils/apiFileFunctions';
import {
    removeFromDirectoryList,
    removeFromFileList,
    setFilePaths,
    updateDirectoryList,
    updateDirectoryListProgress,
    updateFileList
} from '../../utils/fileFunctions';
import {openHelpWidget} from '../../utils/pageFunctions';
import {withinDatasetSize} from '../../utils/projectFunctions';
import DatasetProjectButton from '../buttons/DatasetProjectButton';
import FileDrag from '../FileDrag';
import FileBrowse from '../FileBrowse';
import FileUploadList from '../FileUploadList';
import FileUploadProgressModal from '../modal/FileUploadProgressModal';
import FormError from './FormError';
import IconCheckmark from '../images/IconCheckmark';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import VolumeCreatedModal from "../modal/VolumeCreatedModal";
import '../../css/file.css';

/**
 * Form: Edit Dataset and Volume (Data, Files, Volumes)
 *
 * @param dataId
 * @param dataDesc
 * @param dataList
 * @param formType
 * @param onFormEvent
 * @param projectString
 * @param projectType
 * @param isSysop
 * @param inOwnProjects
 * @param maxDatasetSize
 * @param maxVolumes
 * @param totalVolumes
 * @return {*}
 * @constructor
 */
export default function FormDataFilesVolumesEdit(
    {
        dataId,
        dataDesc,
        dataList,
        formType,
        onFormEvent,
        projectString,
        projectType,
        isSysop,
        inOwnProjects,
        maxDatasetSize,
        maxVolumes,
        totalVolumes
    }) {

    // Set up form and state
    const {register, errors, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    // State: Process/Messaging
    const [processState, updateProcessState] = useState(
        {
            message: '',
            processing: false
        }
    );
    const [formMessage, setFormMessage] = useState('');

    // State: Dataset Form
    const [successState, updateSuccessState] = useState(false);

    // Uploaded Files and Directories
    let fileList = [];
    const [fileListState, updateFileListState] = useState({
        list: []
    });
    let directoryList = [];
    const [directoryListState, updateDirectoryListState] = useState({
        list: []
    });
    const [allowUpload, setAllowUpload] = useState(true);
    const [disableVolume, setDisableVolume] = useState(false);
    const [displayVolumeInfoState, setDisplayVolumeInfoState] = useState({
        open: false,
        volume: ''
    });

    // Effect: Set values of form inputs
    useEffect(() => {
        setValue('id', dataId);
        setValue('description', dataDesc);
    }, [dataId, dataDesc, setValue]);

    // Effect: Disable Volume creation button based on file list and volume limits
    useEffect(() => {
        if (
            (dataList.length === 0 && fileListState.list.length === 0 && !isSysop) ||
            (projectType === 'project' && totalVolumes >= maxVolumes.project && !isSysop)
        ) {
            setDisableVolume(true);
        } else if (dataList.length > 0 || fileListState.list.length > 0) {
            setDisableVolume(false);
        }
    }, [dataList, fileListState, isSysop, maxVolumes, projectType, totalVolumes]);

    // Handle input or drag events and update directory & file lists
    const handleFilesAdded = (arr) => {
        // Update all file paths to be consistent
        let list = setFilePaths(arr);

        // Add file to file list and directory list
        fileList = [];
        directoryList = [];
        fileList = updateFileList(fileListState.list, list);
        directoryList = updateDirectoryList(directoryListState.list, fileList);
        updateFileListState({list: fileList});
        updateDirectoryListState({list: directoryList});
        updateProcessState({message: '', processing: false});
        setFormMessage('');

        // Disallow upload if newly uploaded files surpass the Dataset Size limit
        if (projectType === 'project') {
            const withinLimits = withinDatasetSize(dataList, fileList, maxDatasetSize.project);
            setAllowUpload((withinLimits));
            // Set Volume creation based on file state and Volume totals
            setDisableVolume((totalVolumes >= maxVolumes.project && !isSysop));
        } else {
            setAllowUpload(true);
            setDisableVolume(false);
        }
    };

    // Handle remove event and update directory & file lists
    const handleRemoveFile = (path) => {
        // Remove file from file list and directory list
        fileList = [];
        fileList = removeFromFileList(fileListState.list, path);
        updateFileListState({list: fileList});
        directoryList = [];
        directoryList = removeFromDirectoryList(directoryListState.list, path);
        updateDirectoryListState({list: directoryList});
        setAllowUpload(true);
        setFormMessage('');
    };

    // Files: Handle event for XHR progress
    const handleFileUploadProgress = (event, args) => {
        if (event === 'fileUpload') {
            if (args.loaded && args.total) {
                let percent = Math.round((args.loaded * 100) / args.total);
                if (percent < 0.1) {
                    percent = 0;
                }
                if (percent > 99.9) {
                    percent = 100;
                }
                // Update each directory being uploaded
                let directoryList = updateDirectoryListProgress(directoryListState.list, args.path, percent);
                updateDirectoryListState({list: directoryList});
            }
        }
    };

    // Dataset: Disable specific elements if form is incomplete or processing
    const disableSubmit = () => {
        return !!(
            getValues('description') === '' ||
            errors.description ||
            processState.processing ||
            !allowUpload
        );
    };

    // Dataset: Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // Add parameters
            let message = '';
            if (formType === 'update') {
                data.id = dataId;
                message = 'Updating Dataset...';
            }
            // Submit form to API
            updateProcessState({message: message, processing: true});
            setFormMessage('');
            submitDataForm(data);
        }
    };

    // Dataset: Handle API call and post-submission
    const submitDataForm = (data) => {
        const request = makePostDataRequest(resources, 'Data', 'UPDATE', data);
        request.then(result => {
            if (result) {
                setTimeout(() => {
                    if (fileListState.list && fileListState.list.length > 0) {
                        // If files have been added, begin the upload process
                        setPostSubmitFiles(result).then();
                    } else {
                        // Otherwise, the dataset is complete
                        updateProcessState({message: 'Dataset updated.', processing: false});
                        updateSuccessState(true);
                        if (onFormEvent !== null && typeof onFormEvent === 'function') {
                            onFormEvent({type: 'data', state: 'complete'});
                        }
                    }
                    return Promise.resolve(result);
                }, 500);
            } else {
                updateProcessState({message: 'Sorry, your Dataset could not be updated.', processing: false});
                updateSuccessState(false);
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Update ' + projectString + ' Error: ', error);
            updateProcessState({
                message: 'Sorry, there was an error with your ' + projectString + '.',
                processing: false
            });
            updateSuccessState(false);
            return Promise.reject('Server Error');
        });
    };

    // Files: Process Files after completion of Dataset submission
    const setPostSubmitFiles = async (data) => {

        // If files have been added to this Dataset, begin upload
        if (fileListState.list && fileListState.list.length > 0) {

            updateProcessState({message: 'Dataset updated, uploading Files...', processing: true});
            setFormMessage('');

            // Assemble an array of actions to execute sequentially
            let tasks = [];
            let taskLength = 0;
            for (let i = 0; i < directoryListState.list.length; i++) {
                const task = directoryListState.list[i];
                if (task.files && task.files.length > 0) {
                    tasks.push({
                        index: taskLength,
                        task: 'get',
                        data: {
                            data_id: data.id,
                            index: taskLength,
                            path: task.directory,
                            collection: task.files,
                            progressFunc: handleFileUploadProgress
                        }
                    });
                    taskLength++;
                }
            }

            // Start a Promise sequence with all tasks
            const starterPromise = Promise.resolve(null);
            await tasks.reduce(
                (p, spec) => p.then(() => runFileUploadTask(spec)
                    .then((result) => {
                        // If all tasks in the list have completed:
                        if (spec.index >= (taskLength - 1)) {
                            // Note that Files are completed for Volume creation
                            updateProcessState({message: 'File update complete.', processing: false});
                            if (projectType === 'project') {
                                setDisableVolume((totalVolumes >= maxVolumes.project && !isSysop));
                            } else {
                                setDisableVolume(false);
                            }
                            updateFileListState({list: []});
                            updateDirectoryListState({list: []});
                            if (onFormEvent !== null && typeof onFormEvent === 'function') {
                                onFormEvent({type: 'files', state: 'complete'});
                            }
                        }
                    })), starterPromise)
                .catch((error) => {
                    console.log('File Upload error: ', error);
                    updateProcessState({message: '', processing: false});
                    setFormMessage('There was an error uploading one or more of your files. Please ensure all files are in the same location and that none was moved or deleted.');
                    updateSuccessState(false);
                });
        } else {
            updateProcessState({message: 'Dataset updated.', processing: false});
            updateSuccessState(true);
        }
    };

    // Volume: Process Volume (allowed after completion of File uploads)
    const submitVolume = () => {

        // If files have been added or removed, create a new Volume
        if (!disableVolume) {
            updateProcessState({message: 'Creating new volume...', processing: true});
            let messageSet = false;

            const request = makePostDataRequest(resources, 'Volume', 'PUT', {data_id: dataId});
            request.then(result => {
                if (result) {
                    if (result.error) {
                        let errorMessage = 'Sorry, a new volume of this Dataset could not be created.';
                        if (result.error.includes('max volumes exceeded')) {
                            if (result.error.includes('user')) {
                                errorMessage = 'Sorry, you have reached the max volumes you are allowed.';
                            } else {
                                errorMessage = 'Sorry, you have reached the max volumes allowed for this Dataset.';
                            }
                        }
                        updateProcessState({message: '', processing: false});
                        setFormMessage(errorMessage);
                        updateSuccessState(false);
                        messageSet = true;
                        return Promise.resolve();
                    } else {
                        if (result.substring(0, 8) === 'dataset-') {
                            updateProcessState({message: '', processing: false});
                            setFormMessage('Dataset volume ' + result + ' has been created.');
                            setDisplayVolumeInfoState({open: true, volume: result});
                            // Notify Volume list that a volume has been created
                            if (onFormEvent !== null && typeof onFormEvent === 'function') {
                                onFormEvent({type: 'volume', state: 'created'});
                            }
                        } else if (result.includes('process of being created')) {
                            // "Volume dataset-1462v1 is in the process of being created"
                            updateProcessState({message: '', processing: false});
                            setFormMessage(result + '.');
                            messageSet = true;
                            const datasetIdx = result.substring(result.indexOf('dataset-'));
                            const resultArr = datasetIdx.split(' ');
                            const volume = resultArr[0];
                            setDisplayVolumeInfoState({open: true, volume: volume});
                        }
                        updateSuccessState(true);
                        return Promise.resolve(result);
                    }
                } else {
                    updateProcessState({message: '', processing: false});
                    setFormMessage('Sorry, a new volume of this Dataset could not be created.');
                    updateSuccessState(false);
                    messageSet = true;
                    return Promise.resolve();
                }
            }).catch(error => {
                updateProcessState({message: '', processing: false});
                if (!messageSet) {
                    setFormMessage('Sorry, there was an error with this Dataset volume: ' + error);
                }
                updateSuccessState(false);
                return Promise.reject('Server Error');
            });
        }
    };

    const onCloseVolumeDialog = () => {
        setDisplayVolumeInfoState({
            ...displayVolumeInfoState,
            open: false
        });
    };

    const updateBtn = (fileListState.list.length > 0) ? 'Update Dataset and Upload Files' : 'Update Dataset';

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="xrathus-detail">
                <div className="item-content full">

                    <div className="tab-content active">
                        <p className="instructions">
                            Edit the Dataset description and add, remove or replace files if needed.
                        </p>

                        <div className="form-group">
                            {/* Data Description */}
                            <TextAreaInput
                                id="data-description"
                                rows={6} cols={50}
                                name="description" label="Description"
                                instructions="Enter a Dataset description."
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.description ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Description is required"/>)
                                })}
                                required
                                className={`form-input ${errors.description ? 'input-error' : ''}`}
                            >
                                {errors.description && errors.description.message}
                            </TextAreaInput>
                        </div>

                        <div className="form-group">
                            <label>Files</label>
                            <p className="instructions">
                                If files are associated with this Dataset, add them here. You may browse or drag
                                multiple folders or files.
                            </p>
                            <p className="instructions">
                                Note: If you need to move files into different directories after adding, remove them
                                below and re-add.
                            </p>
                            {!isSysop && inOwnProjects && projectType === 'project' &&
                                <p className="instructions">
                                    You may upload a maximum total of {maxDatasetSize.project} GB in files for this
                                    Project.
                                </p>
                            }

                            <div className="file-upload">
                                <div className="file-upload-browse">
                                    <FileBrowse placement="form" onReady={handleFilesAdded}/>
                                </div>
                                <div className="file-upload-drag">
                                    <FileDrag id="user-dashboard-data-drag" onReady={handleFilesAdded}/>
                                </div>
                            </div>

                            {!processState.processing &&
                                <div className="file-list">
                                    <h4>Files to Upload</h4>
                                    <FileUploadList list={fileListState.list} onRemove={handleRemoveFile}/>
                                </div>
                            }
                            <p className="instructions">
                                {fileListState.list && fileListState.list.length > 0 &&
                                    <>
                                        {!isSysop && !allowUpload && projectType === 'project' &&
                                            <>
                                                Note: Adding these files will exceed this Project's Dataset Size limit,
                                                which
                                                is set to {maxDatasetSize.project}. Please remove or replace these
                                                files, or
                                                delete one of the existing Files from the Dataset.
                                                <br/><br/>
                                            </>
                                        }
                                        {allowUpload &&
                                            <>
                                                If files are updated in your Dataset, you may create a new versioned,
                                                persistent volume of your Dataset. As files are added, removed or
                                                replaced,
                                                you may desire to create new volumes, in order to access different
                                                versions of
                                                your {projectString}'s data.
                                                <br/><br/>
                                                {projectType === 'project' &&
                                                    <>
                                                        If you have reached your limit for Volumes, delete old Volumes
                                                        or
                                                        contact <button type="button" className="link text"
                                                                        title="Support"
                                                                        onClick={() => openHelpWidget()}>Xrathus
                                                        Support</button>.
                                                        <br/><br/>
                                                    </>
                                                }
                                                After upload begins, do not navigate away from this page.
                                            </>
                                        }
                                    </>
                                }
                            </p>

                            <FileUploadProgressModal
                                list={directoryListState.list}
                                title="Uploading Files to Dataset"
                                open={(fileListState.list && fileListState.list.length > 0 && processState.processing)}
                            />

                        </div>
                    </div>

                    {/* Actions and Messaging */}

                    <div className="form-btns right">
                        {successState === true &&
                            <>
                                <p className="message">
                                    <IconCheckmark/>
                                </p>
                            </>
                        }
                        {processState.message !== '' &&
                            <p className="message">
                                {processState.message}
                            </p>
                        }

                        <LoadingIndicator color="dark" centered={false} active={processState.processing}/>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!!disableSubmit()}
                        >
                            {updateBtn}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={processState.processing || disableVolume}
                            onClick={() => submitVolume()}
                        >
                            Create Volume
                        </button>
                        {dataId &&
                            <DatasetProjectButton data_id={dataId}/>
                        }
                    </div>
                    <p className="form-message">
                        {formMessage !== '' &&
                            <span>
                                {formMessage}
                            </span>
                        }
                    </p>

                    <VolumeCreatedModal
                        open={displayVolumeInfoState.open}
                        volume={displayVolumeInfoState.volume}
                        onCloseDialog={() => onCloseVolumeDialog()}
                    />

                </div>
            </div>
        </form>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';
import Collapsible from 'react-collapsible';
import ContentRow from '../containers/ContentRow';
//import StoryblokEditComment from "../StoryblokEditComment"
import CalendarGeneric from '../../calendar/CalendarGeneric';
import {isMobileSmall} from '../../../utils/pageFunctions';
import '../../../css/storyblok-custom.css';


const CollaboratorHeader = ({
    content,
    panelCustomClass,
    panelLabel,
    labelCustomClass,
    showBorder,
    buttons,
    displayCalendar,
    calendarTags,
    extraContent,
    allEvents,
}) => {


    const siteIsMobileSmall = (isMobileSmall() === true);
    const editable = null;

    const PanelContents = ({className}) => {
        return (
            <div className={className}>
                {panelLabel ?
                    siteIsMobileSmall ?
                        <div>
                            {buttons ?                                         
                                <div>
                                    <ContentRow 
                                        isVertical={false} 
                                        content={buttons} 
                                        alignment='center' 
                                        storyblokFormatted={true}/>
                                </div> : ""}

                            <Collapsible trigger="Overview">
                                <div className={panelCustomClass+" panel extra-margin"}>
                                    {
                                        labelCustomClass ?
                                            <h1 className={labelCustomClass}>{panelLabel}</h1> 
                                            :   <h1 className="header">{panelLabel}</h1> 
                                    }
                                    {parseStoryblokContent(content)}
                                    {extraContent ? parseStoryblokContent(extraContent) : ""}
                                </div>
                            </Collapsible>
                        </div>
                        :
                        <div className={panelCustomClass+" panel extra-margin"}>
                            {
                                labelCustomClass ?
                                    <h3 className={labelCustomClass}>{panelLabel}</h3> 
                                    :   <h3 className="header">{panelLabel}</h3> 
                            }
                            {parseStoryblokContent(content)}
                            {buttons ?                                         
                                <div>
                                    <ContentRow 
                                        isVertical={false} 
                                        content={buttons} 
                                        alignment='center' 
                                        storyblokFormatted={true}
                                    />
                                </div> : ""}
                            {extraContent ? parseStoryblokContent(extraContent) : ""}
                        </div>
                    :
                    showBorder ? 
                        <div className={panelCustomClass+" panel extra-margin"}>
                            {parseStoryblokContent(content)}
                        </div>
                        : <div >
                            {parseStoryblokContent(content)}
                            {buttons ?                                         
                                <div>
                                    <ContentRow 
                                        isVertical={false} 
                                        content={buttons} 
                                        alignment='center' 
                                        storyblokFormatted={true}
                                    />
                                </div> : ""}
                            {extraContent ? parseStoryblokContent(extraContent) : ""}
                        </div>
                }
            </div>
        );
    };

    PanelContents.propTypes = {
        className: PropTypes.string,
    };

    return (
        <div className="">
            {editable}
            {displayCalendar ?
                <div className="row">
                    <div className={"col col-xs-12 col-sm-6"}>
                        <PanelContents className={panelCustomClass}/>
                    </div>
                    <div className={"col col-xs-12 col-sm-6"}>
                        <CalendarGeneric allEvents={allEvents} tags={calendarTags}/>
                    </div>
                </div>
                : <PanelContents className={panelCustomClass}/>}
        </div>
    );
};


CollaboratorHeader.propTypes = {
    /**
     * What content goes inside?
     */
    content: PropTypes.any,
    /**
     * Custom css class for panel.
     */
    panelCustomClass: PropTypes.string,
    /**
     * Displayed label for the panel.
     */
    panelLabel: PropTypes.string,
    /**
     * Custom css class for label.
     */
    labelCustomClass: PropTypes.string,
    /**
     * Show border of the panel
     */
    showBorder: PropTypes.bool,
    /**
     * Important Buttons to be displayed alongside panel
     */
    buttons: PropTypes.array,
    /**
     * Display the calendar?
     */
    displayCalendar: PropTypes.bool,
    /**
     * Tags for searching for the right calendar events.
     * Ignored if 'displayCalendar' is false.
     */
    calendarTags: PropTypes.string,
    /**
     * Extra content to be placed under everything else.
     */
    extraContent: PropTypes.any,
    /**
     * What events are used to search through.
     */
    allEvents: PropTypes.array,
    editableStoryblok: PropTypes.string,
};

CollaboratorHeader.defaultProps = {
    content: null,
    panelCustomClass: "",
    panelLabel: "Overview",
    labelCustomClass: "",
    showBorder: true,
    buttons: [],
    displayCalendar: true,
    calendarTags: "",
    extraContent: {},
    allEvents: [],
    editableStoryblok: "",
};

export default CollaboratorHeader;

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import {inUserList} from '../../utils/projectFunctions';
import APIAirmeetSessions from '../../utils/APIAirmeetSessions';
import APIProjects from '../../utils/APIProjects';
import LoadingIndicator from '../../partials/LoadingIndicator';
import {airmeetStyles} from '../../utils/stylesAirmeet';

/**
 * Page: Airmeet embed for specified Project
 */
class XHubProjectAirmeet extends Component {
    constructor(props) {
        super(props);
        // Get Project type and ID from path
        const pathArr = props.location.pathname.split('/');
        const projectIdx = pathArr.findIndex(c => c === 'project');
        let projectType = pathArr[projectIdx + 1];
        let projectId;
        if (projectType === 'personal') {
            // If path is /x/{substrate}/project/type/{id}/{subpage}
            projectId = Number(pathArr[projectIdx + 2]);
        } else {
            // If path is /x/{substrate}/project/{id}/{subpage}
            projectId = Number(pathArr[(pathArr.length - 1)]);
            projectType = null;
        }
        // Get Airmeet type and UUID from path
        const airmeetIdx = pathArr.findIndex(c => c === 'rtc');
        let airmeetType = pathArr[airmeetIdx + 1];
        let airmeetId = pathArr[airmeetIdx + 2];

        this.state = {
            project: null,
            projectId: projectId,
            projectType: projectType,
            airmeetType: airmeetType,
            airmeetId: airmeetId,
            airmeetUrl: null
        };
    }

    // Store Project and set the Airmeet URL
    setProject = (data) => {
        this.setState({
            project: data
        }, () => {
            if (this.state.airmeetType === 'e') {
                this.setAirmeetURL(null);
            }
        });
    };

    // Set the Airmeet URL from the Event or Session Entry Link
    setAirmeetURL = (sessions) => {
        let url;
        if (sessions) {
            const session = sessions.find(r => r.session_id === this.state.airmeetId);
            url = session?.entry_link ?? null;
        } else {
            url = this.state.project?.airmeet_entry_link ?? null;
        }
        this.setState({
            airmeetUrl: url
        });
    };

    render() {
        const {
            project_list_own,
            project_list_subscribed,
            user
        } = this.props;

        const {
            airmeetType,
            airmeetUrl,
            project,
            projectId,
            projectType
        } = this.state;

        const inSubscribedProjects = (inUserList(project_list_subscribed, projectId));
        const inOwnProjects = (inUserList(project_list_own, projectId));

        return (
            <div className="content xhub project">
                {/* User is not owner or has not joined Project */}
                {!user.is_sysop && !inSubscribedProjects && !inOwnProjects &&
                    <>
                        <div className="row">
                            <div className="col col-xs-12">
                                <h1 className="page-title" style={airmeetStyles.title}>
                                    {(project && project.title) ? project.title : 'Project'}
                                </h1>
                                <Link className="btn btn-primary mt-1 mr-0"
                                      to={`/x/hub/project/personal/${projectId}/overview`}>
                                    Back to Project
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-xs-12" key="projects-empty">
                                <div className="panel">
                                    You do not have permission to access this Project's Airmeet
                                    Events or Sessions.
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* User is owner or has joined Project */}
                {(user.is_sysop || inSubscribedProjects || inOwnProjects) &&
                    <>
                        {!projectId &&
                            <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                        }

                        {project && projectId &&
                            <>
                                <div className="row">
                                    <div className="col col-xs-12">
                                        <h1 className="page-title" style={airmeetStyles.title}>
                                            {(project.title) ? project.title : 'Project'}
                                        </h1>
                                        <Link className="btn btn-primary mt-1 mr-0"
                                              to={`/x/hub/project/personal/${projectId}/overview`}>
                                            Back to Project
                                        </Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-xs-12" style={airmeetStyles.container}>
                                        {airmeetUrl &&
                                            <Iframe
                                                id="Airmeet"
                                                url={airmeetUrl}
                                                display="flex"
                                                frameBorder="0"
                                                width="100%"
                                                height="100%"
                                                style={airmeetStyles.iframe}
                                                allow="camera *;microphone *;display-capture *;fullscreen *;"
                                            />
                                        }
                                    </div>
                                </div>

                                {airmeetType === 's' &&
                                    <APIAirmeetSessions
                                        id={projectId}
                                        onResult={(e) => this.setAirmeetURL(e)}
                                        refresh={false}
                                    />
                                }
                            </>
                        }

                        <APIProjects
                            getType="single"
                            viewType={projectType}
                            id={projectId}
                            onResult={this.setProject}
                            refresh={false}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_list_own: state.project_list_own,
        project_list_subscribed: state.project_list_subscribed,
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps
    )(XHubProjectAirmeet)
);

import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

class Release extends Component {
    render() {
        return (
            <>
                <div className="content article release">
                    <div className="panel">
                        <div className="strip small dark">
                        </div>
                        <h1 className="article-title">Xrathus Communities Touchpoint</h1>
                        <h2 className="article-subtitle">Closed Beta Release Statement</h2>
                        <p>
                            Welcome to Xrathus Touchpoint Community Preview! Please join us to discover our
                            community-enriched platform which enables you to transform your research and technology
                            advancement process from ideas to solutions. This preview will allow you to work independently,
                            on-demand or in full cooperation with collaborators to ideate and rapidly deliver optimal
                            solutions.
                        </p>
                        <h4>Focus on Community and Solution Building</h4>
                        <p>
                            Explore and evolve ideas being shared across the community via Clusters in Connect, Xrathus
                            connection and the collaboration substrate. Access our Knowledge Base to obtain instant and
                            in- depth information to accelerate and optimize your ideas and research.
                        </p>
                        <p>
                            Design and build out your solution(s) using the on-demand Data Science work environment in
                            Xrathus Hub, the innovation and development substrate. Explore Xrathus Workspace for rapid
                            prototyping and elaboration of advanced solutions with widely used open technologies and
                            readily available domain specific tools.
                        </p>
                        <p>
                            Get to know Xrathus Communities substrates features and capabilities by following
                            the <Link to="/x/getting-started" title="Getting Started">Getting Started</Link> guide.
                        </p>
                        <h4>Launch Plan</h4>
                        <p>
                            Xrathus Communities Touchpoint is open to everyone. The Xrathus Hub substrate is accessible by
                            invitation only. Once you sign up, if you have been selected as an invitee, you will receive
                            an email confirming your invitation and access. Invitations will be sent out during the
                            duration of the event.
                        </p>
                        <p>
                            As an invitee, please keep in mind that Touchpoint Community Previews are designed to give you
                            a sneak peek at upcoming technologies. They are non-final versions of our product and should
                            NOT be taken as a measure of the fit, finish, capability, and overall quality of the final
                            release.
                        </p>
                        <h4>Start Date</h4>
                        <p>
                            Xrathus Touchpoint Community Preview will be launched on:
                        </p>
                        <ul>
                            <li><strong>October 1st @ 10:30h CST / 11:30h EST</strong></li>
                        </ul>
                        <p>
                            For more information about our Touchpoint Community Preview, feel free to contact us
                            at <a href="mailto:info@xrathus.com" target="_blank"
                                  rel="noopener noreferrer">info@xrathus.com</a>.
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Release);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SectionImage from '../css/images/Substrates-Section-Connect.jpg';

class SubstrateSectionConnect extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Connect Substrate'
            });
        }
    };

    render() {
        return (
            <>
                <div id="connect" className="row">
                    <div className="col col-xs-12">
                        <div className="panel row substrate-section bg-page-color-connect no-border no-margin">
                            <div className="col col-xs-12 col-sm-5 col-md-4 vertical-center">
                                <img className="substrate-section-image" src={SectionImage} alt="Connect"/>
                            </div>
                            <div className="col col-xs-12 col-sm-7 col-sm-8 vertical-center">
                                <h2>
                                    Discuss. Solve. Repeat.
                                </h2>
                                <p>
                                    The <strong>Connect</strong> substrate is the nucleus of
                                    collaboration projects, where formal and informal discussions
                                    happen between participants with similar interests and goals.
                                    Private and public discussion forums are available for ideation,
                                    problem solving, and building valuable relationships.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-arrow table uppercase pull-right mt-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Let's Chat More About This
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

SubstrateSectionConnect.propTypes = {
    onClickFunc: PropTypes.func
};

export default SubstrateSectionConnect;

import React from 'react';
import PropTypes from 'prop-types';
import StoryblokPageFetcher from './content-fetcher/StoryblokPageFetcher';


const StoryblokPage = ({url}) => {
    let params = {
        version: process.env.REACT_APP_STORYBLOK_API_KEY_TYPE,
        cv: Date.now()
    };

    return (
        <div>
            <StoryblokPageFetcher url={url} params={params}/>
        </div>
    );
};

StoryblokPage.propTypes = {
    /**
     * Url for fetching
     */
    url: PropTypes.string,
};

export default StoryblokPage;
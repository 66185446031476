import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IconDeleteCircle from './images/IconDeleteCircle';

class FileUploadListItem extends Component {

    handleRemoveClick = () => {
        if (typeof this.props.onRemove === 'function') {
            this.props.onRemove(this.props.path);
        }
    };

    render() {
        const {
            path,
            sizeStr
        } = this.props;

        return (
            <span className="file-list-item">
                <span className="name">
                    {path}
                </span>
                <span className="size">
                    {sizeStr}
                </span>
                <span className="btn-col">
                    <button className="btn-icon" onClick={this.handleRemoveClick}>
                        <IconDeleteCircle className="icon-delete"/>
                    </button>
                </span>
            </span>
        )
    }
}

FileUploadListItem.propTypes = {
    name: PropTypes.string,
    path: PropTypes.string,
    onRemove: PropTypes.func,
    size: PropTypes.number,
    sizeStr: PropTypes.string
};

export default FileUploadListItem;

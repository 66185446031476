import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ArticleLinks from '../../partials/ArticleLinks';

class XConnectBlogosphere extends Component {
    render() {
        const {
            user
        } = this.props;

        return (
            <>
                <div className="content blogosphere">
                    <h1 className="page-title">
                        Blogosphere
                    </h1>
                    {user.name &&
                    <span className="welcome-user">
                        Welcome, {user.name}.
                    </span>
                    }

                    <h3 className="section-header full">New on the Blog</h3>
                    <ArticleLinks/>

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

XConnectBlogosphere.propTypes = {
    user: PropTypes.any,
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(XConnectBlogosphere)
);

import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {setProjectConfigUserRefresh, setProjectListRefresh} from '../../store/actions';
import {openHelpWidget} from '../../utils/pageFunctions';
import {
    getMaxDatasetSize,
    getProjectPermissions,
    populatePersonalProjectUserConfig
} from '../../utils/projectFunctions';
import FormDataFilesVolumes from '../../partials/forms/FormDataFilesVolumes';
import FormProjectAdd from '../../partials/forms/FormProjectAdd';
import '../../css/challenge.css';

/**
 * Page: Create Project & Datasets forms
 */
class XHubProjectAdd extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        const projectId = Number(pathArr.pop());

        // @TODO reset dataId = ''
        // @TODO reset Dataset display

        this.state = {
            dataId: '',
            formStage: 'project',
            project: null,
            projectId: projectId
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        if (e === 'project' && status === 'complete') {
            // Upon completed Project, populate the Dataset's data ID
            this.setState({
                dataId: data.data_id,
                projectId: data.id
            }, () => {
                // Display the Dataset/Files form
                this.updateFormStage('dataset');
                // Refresh User Configuration and Projects
                this.props.setProjectConfigUserRefresh(true);
                this.props.setProjectListRefresh('personal_own');
            })
        }
    };

    // Set form toggling
    updateFormStage = (stage) => {
        this.setState({
            formStage: stage
        });
    };

    // Populate Project variables and text for Add Project
    setProjectLimits = (ownProjects, userConfig) => {
        const created = ownProjects?.length ?? 0;
        const createdStr = (created > 0) ? created : 'no';
        const max = userConfig?.projects ?? 0;
        let allowed = max - created;
        let projectsStr = (created === 1) ? 'Project' : 'Projects';
        if (allowed < 0) {
            allowed = 0;
        }
        const textHave = ((created < max) || allowed > 0)
            ? 'You have created ' + createdStr + ' Personal ' + projectsStr + '. You may create ' + (allowed) + ' more.'
            : 'You have reached the maximum number of allotted Personal Projects: ' + createdStr + '. ';
        return {
            projects_created: created,
            projects_max: max,
            projects_allowed: allowed,
            text: textHave
        };
    };

    render() {
        const {
            project_config,
            project_config_current,
            project_config_user,
            project_list_own,
            user
        } = this.props;

        const {
            dataId,
            formStage,
            projectId
        } = this.state;

        const userConfig = populatePersonalProjectUserConfig(project_config, project_config_user);
        const projectLimits = this.setProjectLimits(project_list_own, userConfig);
        const projectPermissions = getProjectPermissions(user, project_list_own, null, dataId);
        const maxDatasetSize = getMaxDatasetSize(project_config, project_config_user, project_config_current);

        // If User cannot create Projects due to having reached a limit:
        if (!projectPermissions.sysop && formStage === 'project' && projectLimits.projects_allowed <= 0) {
            return (
                <>
                    <div className="content xhub project">
                        <h1 className="page-title">
                            Personal Projects
                        </h1>
                        <p>
                            {projectLimits.text}
                        </p>
                        {((projectLimits.projects_created >= projectLimits.projects_max) || projectLimits.projects_allowed === 0) &&
                        <p>
                            To create more, please
                            contact <button className="link text" title="Support"
                                            onClick={() => openHelpWidget()}>Xrathus Support</button>.
                        </p>
                        }
                    </div>
                </>
            )
        }

        // Display Add Project form
        return (
            <>
                <div className="content xhub project">
                    <h1 className="page-title">
                        Create a Personal Project
                    </h1>
                    {!projectPermissions.sysop &&
                    <p>
                        {projectLimits.text}
                    </p>
                    }

                    <div className="panel no-padding">
                        <h3 className="header">Personal Project</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormProjectAdd
                                        formStage={formStage}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel no-padding">
                        <h3 className="header">Dataset</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div
                                    className={`form-container expandable ${(formStage === 'dataset') ? 'expanded' : 'collapsed'}`}>
                                    <FormDataFilesVolumes
                                        formType="update"
                                        projectId={projectId}
                                        projectString="Project"
                                        projectType="project"
                                        dataId={dataId}
                                        formStage={formStage}
                                        maxDatasetSize={maxDatasetSize}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        project_config: state.project_config,
        project_config_current: state.project_config_current,
        project_config_user: state.project_config_user,
        project_list_own: state.project_list_own,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

const mapDispatchToProps = {
    setProjectConfigUserRefresh: setProjectConfigUserRefresh,
    setProjectListRefresh: setProjectListRefresh
};

export default withKeycloak(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XHubProjectAdd)
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setUser} from '../store/actions';
import Emitter from '../utils/eventEmitter';
import FormPersona from './forms/FormPersona';
import {PERSONAS} from '../data/personas';
import '../css/persona.css';
import '../css/persona-overlay.css';

/**
 * Component to select a Primary Persona in an overlay
 */
class PersonaSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: false,
            submitType: 'persona'
        };
    }

    componentDidMount() {
        Emitter.on('selectPersona', (e) => this.handlePersonaPrompt(e));
        Emitter.on('loadedPersona', (e) => this.handlePersonaSelection(e));
    }

    componentWillUnmount() {
        Emitter.off('selectPersona');
        Emitter.off('loadedPersona');
    }

    setDisplay = (display) => {
        this.setState({
            displayForm: display
        });
    };

    handlePersonaPrompt = (type) => {
        this.setState({
            // Set the Persona submission and display the form
            submitType: type
        }, () => {
            this.setDisplay(true);
        });
    };

    /**
     * Set and store selected or loaded Persona
     *
     * @param persona
     */
    handlePersonaSelection = (persona) => {
        const user = this.props.user.user;
        const personaObj = PERSONAS.find(r => r.id === persona);
        const userObj = {
            email: user.email,
            family_name: user.family_name,
            gitlab_user_name: user.gitlab_user_name,
            given_name: user.given_name,
            groups: user.groups,
            is_sysop: user.is_sysop,
            name: user.name,
            persona: {
                id: persona,
                name: (personaObj) ? personaObj.name : null,
                plural: (personaObj) ? personaObj.plural : null
            },
            preferred_username: user.preferred_username
        };
        this.props.setUser(userObj);
        Emitter.off('selectPersona');
        this.setDisplay(false);
    };

    render() {
        if (this.state.displayForm) {
            return (
                <div className="overlay persona">
                    <FormPersona
                        submitType={this.state.submitType}
                        onFormComplete={(e) => this.handlePersonaSelection(e)}
                    />
                </div>
            );
        }
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setUser: setUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonaSelect);

import React, {Component} from 'react';
import Calendar from 'react-calendar';
import Toggle from 'react-toggle';
import CalendarWeekly from './CalendarWeekly';
import Modal from '../modal/Modal';
import ChallengeLogo from '../../css/images/icon-Connect-Challenges.svg';
import MeetingLogo from '../../css/images/icon-Connect-Forums.svg';
import CalendarLogo from '../../css/images/icon-Connect-Calendar.svg';
import PropTypes from 'prop-types';

import '../../css/storyblok-custom.css';
import '../../css/static.css';
import '../../css/calendar.css';

const now = new Date();


class CalendarGeneric extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //calendar settings
            activeStartDate: new Date(now.getFullYear(), now.getMonth()),
            value: now,
            view: 'month',
            calendarWeeklyOn: false,
            calendarWeeklyDetailed: false,

            passedEvents: null,
            calendarEvents: null,
            eventOpen: "",
            nextEvents: null,
            resetEvents: true,
            isDetailed: false,
            modals: [],
        };
    }

    setReset = async(value) => {
        await this.setState({resetEvents: value, isDetailed: this.props.isDetailed});
    }

    componentDidMount() {
        if (this.state.resetEvents === true) {
            this.updateEvents();
            this.setReset(false);
        }
    }

    componentDidUpdate(prevState) {
        if (this.state.resetEvents === true) {
            this.updateEvents();
            this.setReset(false);
        }

        if (prevState.activeStartDate !== this.state.activeStartDate) {
            this.updateModals();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.passedEvents !== nextProps.allEvents) {
            return {
                resetEvents: true,
            };
        }
    
        return null;
    }

    updateModals() {

        let tempDate = this.state.activeStartDate;
        let tempModals = this.state.modals;

        //go through calendarevents, find the ones on the current page (depending on view and calendartype)
        //then create modals related to each of them
        if (this.state.calendarEvents) {
            if (!this.state.calendarWeeklyOn) {
                if (this.state.view === 'month') {
                    let relevantEvents = this.state.calendarEvents.filter(element => 
                        new Date(element.start_date+" UTC").getYear() === tempDate.getYear() && 
                        new Date(element.start_date+" UTC").getMonth() === tempDate.getMonth() 
                    );

                    for (let i=0; i<relevantEvents.length; i++) {
                        let event = relevantEvents[i];
                        let eventContent = this.getEventContent(event);
                        let uniqueKey = eventContent[1];
                        let content = eventContent[2];
                        let tempModal = (
                            <Modal
                                classes="open-event" 
                                id={uniqueKey}
                                show={this.state.eventOpen===uniqueKey}
                                onClose={() => this.closeModalEvent()}
                            >
                                {content}
                            </Modal>);
                        if (!tempModals.find(obj => obj.props.id === uniqueKey)) {
                            tempModals.push(tempModal);
                            this.setState({modals:tempModals});
                        }
                        else {
                            let tempIndex = tempModals.findIndex(obj => obj.props.id === uniqueKey);
                            if (tempModals[tempIndex].props.show !== tempModal.props.show) {
                                tempModals[tempIndex] = tempModal;
                                this.setState({modals:tempModals});
                            }
                        }
                    }
                }
            }
        }
    }

    getEventContent(eventInfo) {
        //determine color through event series filtering
        let uniqueLabel = eventInfo.title;
        let color="purple";
        let imageSource=CalendarLogo;
        let content=null;
        let tooltipText=uniqueLabel;
        let tempTimeResult=null;
        let tempDispTime=null;

        let tempEventOwner = (eventInfo.event_owner ? 
            <>
                <br/>
                <br/>
                <span>Created by: {eventInfo.event_owner}</span>
            </>
            : <></>);

        switch (eventInfo.event_type) {
            case "meeting":
            case "session":
                color="purple";
                imageSource=MeetingLogo;
                tempTimeResult = this.getDisplayedDateAndTime(eventInfo);
                tempDispTime = tempTimeResult[1];
                tooltipText = ( 
                    <div>
                        <span>{uniqueLabel}</span>
                        <br/>
                        <br/>
                        <span>{"Time: " + tempDispTime}</span>
                        {tempEventOwner}
                    </div>
                );
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        {eventInfo.event_image ? 
                            <img src={eventInfo.event_image} alt={uniqueLabel}
                                className="single-image aapg-pivoting-2021 thumbnail"/> : <></>}
                        <p className="info left">
                            Date: {new Date(eventInfo.start_date+" UTC").toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                        <div className="description">
                            {this.parseContent(eventInfo.description)}
                        </div>
                        <div className="form-btns center">
                            {eventInfo.discussion_link ? 
                                <a href={eventInfo.discussion_link}
                                    title="Discussion" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Discussion</a> : <></>}
                            {eventInfo.registration_link ? 
                                <a href={eventInfo.registration_link}
                                    title="Register" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Register</a> : <></>}
                            {eventInfo.join_link ? 
                                <a href={eventInfo.join_link}
                                    title="Join" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Join</a> : <></>}
                        </div>
                    </div>
                );
                break;
            case "challenge":
                color="purple";
                imageSource=ChallengeLogo;
                tempTimeResult = this.getDisplayedDateAndTime(eventInfo);
                tempDispTime = tempTimeResult[1];
                tooltipText = ( 
                    <div>
                        <span>{uniqueLabel}</span>
                        <br/>
                        <br/>
                        <span>{"Time: " + tempDispTime}</span>
                        {tempEventOwner}
                    </div>
                );
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        {eventInfo.event_image ? 
                            <img src={eventInfo.event_image} alt={uniqueLabel}
                                className="single-image aapg-pivoting-2021 thumbnail"/> : <></>}
                        <p className="info left">
                            Date: {new Date(eventInfo.end_date).toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                        <div className="description">
                            {this.parseContent(eventInfo.description)}
                        </div>
                        <div className="form-btns center">
                            {eventInfo.discussion_link ? 
                                <a href={eventInfo.discussion_link}
                                    title="Discussion" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Discussion</a> : <></>}
                            {eventInfo.registration_link ? 
                                <a href={eventInfo.registration_link}
                                    title="Register" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Register</a> : <></>}
                            {eventInfo.join_link ? 
                                <a href={eventInfo.join_link}
                                    title="Join" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap">
                                    Join</a> : <></>}
                        </div>
                    </div>
                );
                break;
            case "misc":
            default:
                tempTimeResult = this.getDisplayedDateAndTime(eventInfo);
                tempDispTime = tempTimeResult[1];
                tooltipText = ( 
                    <div>
                        <span>{uniqueLabel}</span>
                        <br/>
                        <br/>
                        <span>{"Time: " + tempDispTime}</span>
                        {tempEventOwner}
                    </div>
                );
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        <p className="info left">
                            Date: {new Date(eventInfo.start_date+" UTC").toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                    </div>
                );
        }

        let uniqueKey = uniqueLabel+eventInfo.start_date+"2";

        return [uniqueLabel, uniqueKey, content, tooltipText, imageSource, color];
    }

    //method that parses the events for relevance
    updateEvents() {
        let events = this.props.allEvents;
        this.setState({passedEvents: events});
        
        let nextEvents = events;

        if (events) {
            // sort out only relevant events with tags
            events = this.getTaggedData(events, this.props.tags);

            //add weekly events
            let updatedEvents = [];
            //create new events for weekly (one year ahead of the start)
            for (let i = 0; i < events.length; i++) {
                if (events[i].weekly) {
                    let tempStartDate = new Date(events[i].start_date);
                    let tempEndDate = new Date(events[i].end_date);
                    for (let j = 0; j < 52; j++) {
                        tempStartDate.setDate(tempStartDate.getDate() + 7);
                        tempEndDate.setDate(tempEndDate.getDate() + 7);

                        updatedEvents.push({...events[i], ...{
                            'start_date': tempStartDate.toString(),
                            'end_date': tempEndDate.toString()
                        }});
                    }
                }
            }
            events = events.concat(updatedEvents);


            //sort events by date order
            events = events.sort(function(a,b) {
                return new Date(a.start_date+" UTC") - new Date(b.start_date+" UTC");
            });
            this.setState({calendarEvents: events});

            //set nextEvents list
            nextEvents.filter(element =>  new Date(element.end_date).toISOString() >= now.toISOString());
            this.setState({nextEvents: nextEvents});
        }
    }

    openCalendarPage() {
    }

    openModalEvent = (eventNum) => {
        this.setState({eventOpen: eventNum});
    }

    closeModalEvent = () => {
        this.setState({eventOpen: ""});
    }

    render() {
        let renderedModals = this.state.modals.map(item => <div key={item.props.id}> {item} </div>);

        return (
            <div className="calendar">
                <this.CalendarWidget/>
                {renderedModals}
            </div>
        );
    }


    getTaggedData = (list, tags) => {
        if (!tags)
            return list;
        let allTags = tags ? tags.split(" ") : "";
        let relevantList = [];
        //sort out anything without the proper tag
        if (allTags !== "") {
            for (let i = 0; i < allTags.length; i++) {
                relevantList = relevantList.concat(list.filter(element => element.tags.indexOf(allTags[i]) !== -1));
            } 
            //remove duplicates
            let tempCheck = new Set();
            relevantList = relevantList.filter(obj => !tempCheck.has(obj) && tempCheck.add(obj));

            return relevantList;
        }
        else {
            return list; 
            //alternatively, return []
        }
    }


    calendarToolTip = ({eventInfo}) => {
        let eventContent = this.getEventContent(eventInfo);
        let uniqueLabel = eventContent[0];
        let uniqueKey = eventContent[1];
        //let tooltipText = eventContent[3];
        let imageSource = eventContent[4];
        let color = eventContent[5];

        return (
            <div className="react-calendar__event-dot" key={uniqueKey} >
                <img data-tip data-for={uniqueKey} src={imageSource} alt={uniqueLabel} className={"legend-icon icon-"+color}
                    onClick={() => this.openModalEvent(uniqueKey)}/>
                &nbsp;

            </div>
        );
    }


    parseContent = ( content, contentType="", className="") => {
        if (content) {
            if ("type" in content) {
                switch (content.type) {
                    case "text":
                        if (!("marks" in content))
                            return content.text;
                        else {
                            return this.parseTextStyle(content.text, content.marks, className);
                        }
                    case "heading":
                        if (!("attrs" in content))
                            return this.parseContent(content.content, content.type, className);
                        else {
                            return this.parseHeadingStyle(content.content, content.attrs, className);
                        }
                    case "image":
                        if (content.height && content.width)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height} width={content.width}></img>);
                        else if (content.height)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height}></img>);
                        else if (content.width)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} width={content.width}></img>);
                        else
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src}></img>);
                    case "bullet_list":
                        if ("content" in content) {
                            return <ul className={className} key={content._uid}>
                                {this.parseContent(content.content, content.type, className)}
                            </ul>;
                        }
                        return null;
                    case "paragraph":
                        if ("content" in content) {
                            //find unique key
                            let tempUniqueKey = content._uid;
                            if ("text" in content.content[0]) {
                                tempUniqueKey = content.content[0].text;
                            }
                            if ("attrs" in content.content[0]) {
                                tempUniqueKey = content.content[0].attrs.src;
                            }
                            return <p className={className} key={tempUniqueKey}>
                                {this.parseContent(content.content, content.type, className)}
                            </p>;
                        }
                        return null;
                    case "horizontal_rule":
                        return <hr className='horizontal-rule'/>;
                    case "ordered_list":
                        if ("content" in content) {
                            return <ol className={className} key={content._uid}>
                                {this.parseContent(content.content, content.type, className)}
                            </ol>;
                        }
                        return null;
                    case "doc":
                    default:
                        if ("content" in content) {
                            return this.parseContent(content.content, content.type, className);
                        }
                        return null;
                }
            }
            else if (Array.isArray(content)) {
                switch (contentType) {
                    case "bullet_list":
                    case "ordered_list":
                        return content.map((item, i) => (
                            <li key={i}>
                                {this.parseContent(item, contentType, className)}
                            </li>
                        ));
                    case "paragraph":
                        return content.map((item) => (
                            this.parseContent(item, contentType, className)
                        ));
                    default:
                        return content.map((item) => (
                            this.parseContent(item, contentType, className)
                        ));
                }
            }
            else return null;
        }
        return null;
    }


    parseHeadingStyle = (content, style, className="" ) => {
        if (Array.isArray(style)) {
            for (let i = 0; i < style.length; i++) {
                content = this.parseHeadingStyle(content, style[i].level, className);
            }
            return content;
        }
        else {
            let tempStyle = style;
            if ('level' in style) {
                tempStyle = style.level;
            }
            switch (tempStyle) {
                case 1:
                    return (<h1>{this.parseContent(content, "", className)}</h1>);
                case 2:
                    return (<h2>{this.parseContent(content, "", className)}</h2>);
                case 3:
                default:
                    return (<h3>{this.parseContent(content, "", className)}</h3>);
            }
        }
    }


    parseTextStyle = (content, style, className="", uniqueId="") => {
        if (Array.isArray(style)) {
            for (let i = 0; i < style.length; i++) {
                content = this.parseTextStyle(content, style[i].type, className, uniqueId);
            }
            return content;
        }
        else {
            switch (style) {
                case "italic":
                    return (<i key={content}>{content}</i>);
                case "bold":
                    return (<strong key={content}>{content}</strong>);
                default:
                    return (<strong key={content}>{content}</strong>);
            }
        }
    }


    getDisplayedDateAndTime = ( passedProps ) => {
        let tempTimeStart = new Date(passedProps.start_date+" UTC").toLocaleTimeString();
        let tempTimeEnd = new Date(passedProps.end_date+" UTC").toLocaleTimeString();
        let tempDispTime = tempTimeStart.substring(0,tempTimeStart.lastIndexOf(":")) + " " +
            tempTimeStart.substring(tempTimeStart.lastIndexOf(" ")) + " - " + 
            tempTimeEnd.substring(0, tempTimeEnd.lastIndexOf(":")) + " " +
            tempTimeEnd.substring(tempTimeEnd.lastIndexOf(" "));
        let tempDateStart = new Date(passedProps.start_date+" UTC").toDateString().substring(4);
        let tempDispDate = tempDateStart.substring(0,tempTimeStart.lastIndexOf(" ")-1) + ", " + 
            tempDateStart.substring(tempTimeStart.lastIndexOf(" ")-1);

        return [tempDispDate, tempDispTime];
    }

    handleCalendarToggleMonth = () => {
        this.setState({calendarWeeklyOn: false});
    }

    handleCalendarToggleWeek = () => {
        this.setState({calendarWeeklyOn: true});
    }

    handleDetailToggle = () => {
        this.setState({calendarWeeklyDetailed: !this.state.calendarWeeklyDetailed});
    }

    onChange = (value) => this.setState({ value });

    onViewOrDateChange = ({ activeStartDate, view }) => {
        if (this.state.eventOpen === "")
            this.setState({ activeStartDate, view });
    };

    CalendarWidget = () => {

        const {
            calendarEvents,
        } = this.state;

        let relevantEvents = [];

        if (calendarEvents)
            relevantEvents = calendarEvents;

        const calendarProps = {
            className: 'EventCalendar',
            relevantEvents,
            onActiveStartDateChange: this.onViewOrDateChange,
            onChange: this.onChange,
            onViewChange: this.onViewOrDateChange,
            //default calendar settings
            locale: null,
            maxDetail: 'month',
            maxDate: new Date(2026, 11, 31),
            minDate: new Date(2021, 0, 1),
            minDetail: 'year',
            returnValue: 'start',
            selectRange: false,
            showDoubleView: false,
            showFixedNumberOfWeeks: false,
            showNeighboringMonth: false,
            showWeekNumbers: false,
        };

        return (
            <div className="center">
                {!this.state.calendarWeeklyOn ? 
                    <div>
                        <button onClick={this.handleCalendarToggleMonth}
                            className="selected"
                            type="button">
                            Monthly
                        </button>
                        &nbsp;
                        <Calendar
                            {...calendarProps}
                            tileContent = {({date, view }) => 
                                (view === "month" && relevantEvents &&
                                relevantEvents.filter(element => 
                                    new Date(element.start_date+" UTC").getYear() === date.getYear() && 
                                    new Date(element.start_date+" UTC").getMonth() === date.getMonth() &&
                                    new Date(element.start_date+" UTC").getDate() === date.getDate()
                                ).length > 0) 
                                    ? 
                                    (relevantEvents.filter(element => 
                                        new Date(element.start_date+" UTC").getYear() === date.getYear() && 
                                        new Date(element.start_date+" UTC").getMonth() === date.getMonth() &&
                                        new Date(element.start_date+" UTC").getDate() === date.getDate()
                                    ).length < 4 
                                        ? 
                                        relevantEvents.filter(element => 
                                            new Date(element.start_date+" UTC").getYear() === date.getYear() && 
                                            new Date(element.start_date+" UTC").getMonth() === date.getMonth() &&
                                            new Date(element.start_date+" UTC").getDate() === date.getDate()
                                        ).map(element => <this.calendarToolTip key={element.title+element.start_date}
                                            eventInfo={element}/>)
                                        : 
                                        (
                                            relevantEvents.filter(element => 
                                                new Date(element.start_date+" UTC").getYear() === date.getYear() && 
                                            new Date(element.start_date+" UTC").getMonth() === date.getMonth() &&
                                            new Date(element.start_date+" UTC").getDate() === date.getDate()
                                            ).filter((element,idx) => idx < 4).map(element => <this.calendarToolTip key={element.title+element.start_date}
                                                eventInfo={element}/>)
                                        )
                                    )
                                    : 
                                    (view === "year" && relevantEvents &&
                                relevantEvents.filter(element => 
                                    new Date(element.start_date+" UTC").getYear() === date.getYear() && 
                                    new Date(element.start_date+" UTC").getMonth() === date.getMonth()
                                ).length > 0) ? 
                                        relevantEvents.filter(element => 
                                            new Date(element.start_date).getYear() === date.getYear() && 
                                        new Date(element.start_date).getMonth() === date.getMonth()
                                        ).map(element => <this.calendarToolTip key={element.title+element.start_date}
                                            eventInfo={element}/>)
                                        : 
                                        null}
                        />
                    </div> 
                    :
                    <div>
                        <button onClick={this.handleCalendarToggleMonth}
                            className=""
                            type="button">
                            Monthly
                        </button>
                        &nbsp;
                        <button onClick={this.handleCalendarToggleWeek}
                            className="selected"
                            type="button">
                            Weekly
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>Detailed: </span>
                        <div className="calendar-toggle">
                            <Toggle
                                defaultChecked={false}
                                icons={false}
                                onChange={this.handleDetailToggle}/>
                        </div> 
                        &nbsp;<br/>&nbsp;
                        <CalendarWeekly
                            allEvents = {relevantEvents}
                            isDetailed = {this.state.calendarWeeklyDetailed}
                        />
                    </div> 
                }
            </div>        
        );
        
    }
}

CalendarGeneric.propTypes = {
    /**
     * All Events
     */
    allEvents: PropTypes.array,
    /**
     * Tags used to identify the relevant events
     */
    tags: PropTypes.string,
    /**
     * Is detailed
     */
    isDetailed: PropTypes.bool,
};

export default CalendarGeneric;

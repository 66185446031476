import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SectionImage from '../css/images/Substrates-Section-Hub.jpg';

class SubstrateSectionHub extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Hub Substrate'
            });
        }
    };

    render() {
        return (
            <>
                <div id="hub" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-40 row substrate-section bg-page-color-hub">
                            <div className="col col-xs-12 col-sm-5 col-md-4 vertical-center">
                                <img className="substrate-section-image" src={SectionImage} alt="Hub"/>
                            </div>
                            <div className="col col-xs-12 col-sm-7 col-sm-8 vertical-center">
                                <h2>Where the action happens.</h2>
                                <p>
                                    The <strong>Hub</strong> substrate gives you seamless access
                                    to all Xrathus' asynchronous and synchronous capabilities,
                                    including the pre-configured data science environment. This
                                    is where agile innovation, improvement, and integration
                                    cycles happen.
                                </p>
                                <p>
                                    Each project is automatically equipped with a virtual Real
                                    Time Collaboration (RTC) zone, which persists throughout
                                    the project's life-cycle.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-hub btn-arrow table uppercase pull-right mt-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Show Me More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

SubstrateSectionHub.propTypes = {
    onClickFunc: PropTypes.func
};

export default SubstrateSectionHub;

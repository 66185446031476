import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resources} from '../resourcesProject';
import {makeGetDataRequest} from './getRequest';

/**
 * Component for retrieving Airmeet Sessions for a Challenge, Project, or Event
 */
class APIAirmeetSessions extends Component {

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh === true) {
            this.getList();
        }
    }

    /**
     * Get Airmeet Sessions
     *
     */
    getList = () => {
        const id = this.props.id;
        const request = makeGetDataRequest(resources, 'AirmeetSessions', 'GET_LIST', {id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Airmeet Sessions Retrieval Error: ', result);
                    // Pass results to parent
                    if (this.props.onResult) {
                        this.props.onResult(null);
                    }
                    return Promise.resolve(result);
                }
                // Pass results to parent
                if (this.props.onResult) {
                    this.props.onResult(result);
                }
                return Promise.resolve(result);
            } else {
                console.log('Airmeet Sessions Retrieval Error: No result');
                return Promise.reject('Airmeet Sessions Retrieval failed');
            }
        }).catch(error => {
            console.log('Airmeet Sessions Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    render() {
        return (
            <>
            </>
        )
    }
}

APIAirmeetSessions.propTypes = {
    id: PropTypes.number,
    onResult: PropTypes.func,
    params: PropTypes.object,
    refresh: PropTypes.bool
};

APIAirmeetSessions.defaultProps = {
    params: {},
    refresh: false
};

export default APIAirmeetSessions;

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FileDirectoryUploadItem extends Component {

    setFileItems = () => {
        const files = this.props.files;
        if (!files || files.length === 0) {
            return (
                <span key="file-empty">No Files</span>
            )
        }
        return (
            files.map((file, index) =>
                (
                    <span key={`file-item-${index}`}>
                        {file.name}<br/>
                    </span>
                )
            )
        )
    };

    render() {
        const {
            files,
            path,
            percent,
            totalFiles
        } = this.props;

        const totalStr = '(' + totalFiles + ((totalFiles === 1) ? ' File)' : ' Files)');
        const width = percent.toString() + '%';
        const right = (100 - percent);
        const rightPos = right.toString() + '%';
        const opacity = (percent >= 10) ? 1 : 0;

        return (
            <span className="file-list-item file-upload-progress-item">
                <span className="path-progress">
                    <span className="path">
                        {path} <span>{totalStr}</span>
                    </span>
                    <span className="progress-container">
                        <span className="progress-bar" style={{width: width}}>
                        </span>
                        <span className="progress-text" style={{right: rightPos, opacity: opacity}}>
                            {percent}%
                        </span>
                    </span>
                </span>
                <span className="items">
                    {files.length > 0 &&
                    <>
                        {[...this.setFileItems()]}
                    </>
                    }
                </span>
            </span>
        )
    }
}

FileDirectoryUploadItem.propTypes = {
    path: PropTypes.string,
    percent: PropTypes.number,
    totalFiles: PropTypes.number
};

FileDirectoryUploadItem.defaultProps = {
    path: '/',
    percent: 0,
    totalFiles: 0
};

export default FileDirectoryUploadItem;

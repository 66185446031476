import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {sortArrayByKey} from '../utils/dataFunctions';
import NewsLink from './NewsLink';
import {XRATHUS_NEWS} from '../data/news';

class NewsLinks extends Component {
    constructor(props) {
        super(props);

        const items = this.createNewsItems();
        this.state = {
            items: items
        };
    }

    createNewsItems = () => {
        let items = [];
        Object.keys(XRATHUS_NEWS).forEach(function (key) {
            const item = XRATHUS_NEWS[key];
            // Convert date to human-readable
            const date = new Date(item.date.substring(0, 10));
            date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
            const dateStr = date.toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric'});
            if (item.title) {
                const newsItem = {
                    slug: key,
                    article: item.article,
                    date: item.date,
                    dateStr: dateStr,
                    excerpt: item.excerpt,
                    link: item.link,
                    title: item.title
                };
                items.push(newsItem);
            }
        });
        sortArrayByKey(items, 'date', 'DESC')
        return items;
    };

    renderNewsItems = () => {
        const list = this.state.items;
        if (!list || list.length === 0) {
            return (
                <div className="col col-xs-12" key="news-excerpt-empty">
                    <p>No News Articles Available</p>
                </div>
            )
        }

        return (
            list.map((item, index) => (
                <NewsLink
                    key={`news-excerpt-${item.slug}-${index}`}
                    data={item}
                />
            ))
        )
    };

    render() {
        return (
            <div className="news-excerpts">
                {[...this.renderNewsItems()]}
            </div>
        )
    }
}

export default withRouter(NewsLinks);

import React from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';

import '../../../css/storyblok-custom.css';


const ContentRow = ({
    content,
    isVertical,
    useCols,
    colWidths,
    alignment,
    allStories,
    storyblokFormatted,
}) =>  {

    const contentList = content;
    let colSize = Array(contentList.length).fill(parseInt(12.0 / contentList.length).toString());

    if (alignment === '')
        alignment = 'left';

    if (colWidths) {
        //remove all spaces
        let tempColWidths = colWidths.split(" ").join("");
        //use ',' as delimiter
        colSize = tempColWidths.split(',');
    }

    if (isVertical) {
        return (
            <div>
                {storyblokFormatted ?       
                    contentList.map((item) => (
                        <div key={item._uid}>
                            {parseStoryblokContent(item, "", "", allStories)}
                        </div> 
                    ))
                    :
                    contentList.map((item, i) => (
                        <div key={i}>
                            {item}
                        </div> 
                    ))
                }
            </div>
        );
    }
    else {
        if (useCols) {
            return (
                <div className="row">
                    {storyblokFormatted ?
                        contentList.map((item, i) => (
                            <div className={"col col-xs-12 col-sm-"+colSize[i]} key={item._uid}>
                                {parseStoryblokContent(item, "", "", allStories)}
                            </div>
                        ))
                        :
                        contentList.map((item, i) => (
                            <div className={"col col-xs-12 col-sm-"+colSize[i]} key={i}>
                                {item}
                            </div> 
                        ))
                    }
                </div>
            );
        }
        else {
            return (
                <div className={`row content-row ${alignment}`}>
                    {storyblokFormatted ?
                        contentList.map((item) => (
                            <div className="" key={item._uid}>
                                {parseStoryblokContent(item, "", "", allStories)}
                            </div>
                        ))
                        :
                        contentList.map((item, i) => (
                            <div key={i}>
                                {item}
                            </div> 
                        ))
                    }
                </div>
            );
        }
    }
};

ContentRow.propTypes = {
    /**
     * What content goes inside?
     */
    content: PropTypes.array,
    /**
     * Is the content vertically oriented?
     */
    isVertical: PropTypes.bool,
    /**
     * Use the built-in css 12 column system?
     */
    useCols: PropTypes.bool,
    /**
     * String of comma separated integer column widths, preferrably totaling 12. Ignored if useCols is false.
     */
    colWidths: PropTypes.string,
    /**
     * Alignment of the content.
     */
    alignment: PropTypes.oneOf(['left', 'center', 'right', '']),
    /**
     * Storyblok-specific parameter passed alongside content.
     * @TODO : Eliminate this totally
     */
    allStories: PropTypes.any,
    /**
     * Is content formatted specifically by Storyblok?
     */
    storyblokFormatted: PropTypes.bool,
};

ContentRow.defaultProps = {
    content: [],
    sectionHeader: "",
    isVertical: false,
    useCols: false,
    colWidths: "",
    alignment: 'left',
    allStories: {},
    storyblokFormatted: false,
};

export default ContentRow;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

/**
 * Top Bar Public Navigation (logged out)
 */
class NavXrathusPublic extends Component {

    openHelpWidgetDemo = () => {
        const widget = window.FreshworksWidget;
        if (widget) {
            widget('prefill', 'ticketForm', {subject: 'Book A Call', type: 'Book A Call'});
            widget('open', 'ticketForm');
        }
    }

    render() {
        const {
            location
        } = this.props;

        let navLinks = [
            '/x/substrates',
            '/x/use-cases',
            '/x/about'
        ];
        let path = location.pathname;

        return (
            <div className="nav-top-public">
                <li>
                    <Link to="/x/substrates" title="Substrates"
                          className={`btn no-bg table uppercase extra-margin ${(navLinks[0] === path || path.indexOf(navLinks[0]) !== -1) ? "active" : ""}`}>
                        Substrates
                    </Link>
                </li>
                <li>
                    <Link to="/x/use-cases" title="Use Cases"
                          className={`btn no-bg table uppercase extra-margin ${(navLinks[1] === path || path.indexOf(navLinks[1]) !== -1) ? "active" : ""}`}>
                        Use Cases
                    </Link>
                </li>
                <li>
                    <Link to="/x/about" title="About Us"
                          className={`btn no-bg table uppercase extra-margin ${(navLinks[2] === path || path.indexOf(navLinks[2]) !== -1) ? "active" : ""}`}>
                        About Us
                    </Link>
                </li>
                <li>
                    <button
                        type="button"
                        className="btn btn-widget table uppercase"
                        onClick={() => this.openHelpWidgetDemo()}
                        title="Book A Call"
                    >
                        Book A Call
                    </button>
                </li>
            </div>
        );
    }
}

NavXrathusPublic.propTypes = {
    location: PropTypes.any,
};

export default withRouter(NavXrathusPublic);

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resources} from '../../resourcesData';
import {makePostDataRequest} from '../../utils/postRequest';
import ActiveIcon from '../../css/images/icon-Table-Active.svg';
import InactiveIcon from '../../css/images/icon-Table-Close.svg';
import Modal from '../modal/Modal';

const SetVolumeActiveButton = ({record = {}, dataId, onFormEvent, disabled}) => {

    const isActive = !!(record.cell.row.original.active);
    const [modalStatus, setModalStatus] = useState(false);

    const handleConfirmation = () => {
        const newStatus = (isActive) ? 0 : 1;
        const params = {
            data_id: dataId,
            status: newStatus,
            volume_name: record.cell.row.original.name
        };

        const request = makePostDataRequest(resources, 'VolumeStatus', 'PUT', params);
        request.then(result => {
            if (result) {
                setModalStatus(false);
                if (onFormEvent !== null && typeof onFormEvent === 'function') {
                    onFormEvent({type: 'volumes', state: 'complete'});
                }
                if (typeof result === 'string') {
                    console.log('Volume Status Error: ', result);
                    return Promise.resolve(result);
                }
                return Promise.resolve(result);
            } else {
                console.log('Volume Error: No volume');
                return Promise.reject('Volume Status failed');
            }
        }).catch(error => {
            console.log('Volume Status Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    return (
        <>
            <span
                className={`btn-icon x-table-icon${(disabled) ? ' disabled' : ''}`}
                onClick={() => setModalStatus(true)}
            >
                {isActive &&
                <img src={ActiveIcon} alt="Active Icon"/>
                }
                {!isActive &&
                <img src={InactiveIcon} alt="Inactive Icon"/>
                }
            </span>
            <Modal
                allowClose={true}
                id="modal-volume-status"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">Set this Volume as {(isActive) ? 'inactive' : 'active'}?</h3>
                    <p className="center">Volume: {record.cell.row.original.name}</p>
                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleConfirmation}>YES</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

SetVolumeActiveButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object
};

export default SetVolumeActiveButton;
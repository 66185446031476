import React from 'react';

const IconXrathusArrow = () => {
    return (
        <span className="icon-xrathus-arrow">
            <svg version="1.1" x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="-49.5 65.5 115.2 115.2"
                 enableBackground="new -49.5 65.5 115.2 115.2"
                 xmlSpace="preserve">
				<path id="path20"
                      d="M1.31,65.5l-19.199,19.198l24.824,24.826h-49.647v27.15H6.935l-24.824,24.824L1.31,180.7L58.912,123.1	L1.31,65.5"/>
			</svg>
        </span>
    )
};

export default IconXrathusArrow;

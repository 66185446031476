import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {openHelpWidgetWithParams} from '../utils/pageFunctions';
import AboutCollaborators from '../partials/AboutCollaborators';
import CareerLinks from '../partials/CareerLinks';
import NewsLinks from '../partials/NewsLinks';
import TeamMemberLinks from '../partials/TeamMemberLinks';
import TeamBG from '../css/images/bg-Hex-Gradient-Connect.jpg';
import XrathusContinuousImage from '../css/images/Xrathus-Continuous-diagram.png';
import '../css/page.css';

const teamStyle = {
    backgroundImage: `url(${TeamBG})`
};

class About extends Component {

    handleOpenWidget = (section) => {
        let params = {
            type: 'Demo Request'
        };
        if (section === 'schedule') {
            params.subject = 'Schedule a Call';
        }
        openHelpWidgetWithParams(params, true);
    };

    render() {
        return (
            <div className="content full about">

                {/* Section: Hero */}
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel full bg about-hero no-border">
                            <h2>
                                At Xrathus, we believe problems are best solved
                                <span className="break desktop"> </span>
                                by getting the right people working together.
                            </h2>
                            <p>
                                Xrathus empowers people to solve problems together by connecting
                                <span className="break desktop"> </span>
                                them through an engaging digital collaboration ecosystem.
                            </p>
                            <Link to="#careers"
                                  title="Careers Button"
                                  className="btn btn-primary table uppercase pull-right"
                            >
                                Join Our Team Today
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Section: Mission/Origin */}
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel full about-mission no-border">
                            <h2>
                                Empowering people to solve problems together
                                <span className="break desktop"> </span>
                                through an engaging digital collaboration system.
                            </h2>
                            <p>
                                Organizations understand the need for open innovation and fast,
                                effective commercialization of new solutions. What has been
                                missing is an ecosystem within which individuals and teams can
                                come together—across company boundaries—to make it happen.
                            </p>
                            <p>
                                Xrathus is a customizable collaboration platform built for today's
                                hybrid workplace. It creates a people-centric, discipline-agnostic
                                place for continuous, accelerated innovation.
                            </p>
                            <p>
                                Organizations of any shape and size can harness Xrathus to
                                dramatically accelerate their journey from ideation, to feasibility,
                                initial implementation, and out to the market.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Section: Team */}
                <div id="team" className="row">
                    <div className="col col-xs-12">
                        <div className="panel about-team no-border no-margin">
                            <div className="about-team-bg" style={teamStyle}></div>
                            <h2>Our Team</h2>
                            <TeamMemberLinks/>
                        </div>
                    </div>
                </div>

                {/* Section: Collaborators */}
                <div id="collaborators" className="row">
                    <div className="col col-xs-12">
                        <div className="panel full about-collaborators no-border">
                            <h2>
                                The following Collaborators are working with Xrathus to
                                <span className="break desktop"> </span>
                                bring added value to our user community.
                            </h2>
                            <AboutCollaborators/>
                        </div>
                    </div>
                </div>

                {/* Section: Latest News */}
                <div id="news" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-40 full bg-page-color-hub about-news">
                            <h2>Headlines</h2>
                            <NewsLinks/>
                        </div>
                    </div>
                </div>

                {/* Section: Learn More */}
                <div id="learn-more" className="row">
                    <div className="col col-xs-12">
                        <div className="panel full bg about-learn-more no-border">
                            <h2>
                                Want to understand the backstory to Xrathus?
                            </h2>
                            <br/>
                            <p>
                                When we began developing the concept that would eventually become
                                the Xrathus digital collaboration ecosystem, we gathered a lot of
                                information about the way companies work and how today's
                                organizations and offices impede effective collaboration.
                            </p>
                            <p>
                            <span className="prompt left">
                                If you're curious to learn more, download a copy of our white paper.
                                &nbsp;
                            </span>
                                <button
                                    title="Download Now >"
                                    className="btn btn-training btn-arrow table uppercase"
                                    onClick={() => {
                                        window.open('/docs/Xrathus_WhitePaper.pdf');
                                    }}
                                >
                                    Download Now
                                </button>
                            </p>
                            <p>
                            <span className="prompt left">
                                If you still have questions, let's set up a call - we love to talk
                                about this stuff.
                                &nbsp;
                            </span>
                                <button
                                    type="button"
                                    className="btn btn-hub btn-arrow table uppercase"
                                    onClick={() => this.handleOpenWidget('schedule')}
                                >
                                    Contact Us >
                                </button>
                            </p>
                            <img className="about-process-image" src={XrathusContinuousImage} alt="Xrathus Continuous Process"/>
                        </div>
                    </div>
                </div>

                {/* Section: Careers */}
                <div id="careers" className="row">
                    <div className="col col-xs-12">
                        <div className="panel full bg-page-color-connect about-careers no-border">
                            <h2>
                                Join Us!
                            </h2>
                            <p>
                                Xrathus is looking for natural-born collaborators to join us on our
                                quest to build the digital collaboration ecosystem that everyone
                                wants to use. Here are links to our current open positions.
                            </p>
                            <p>
                                Don't see anything that sounds like you? Email your resume to
                                us <a href="mailto:info@xrathus.com" title="Email us">here</a>.
                            </p>
                            <CareerLinks/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(About);

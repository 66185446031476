import React, {useCallback, useEffect, useState} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import Emitter from '../../utils/eventEmitter';
import Modal from '../modal/Modal';

/**
 * Modal for Login Prompt upon Token Expiration
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LoginModal = (props) => {

    const [modalStatus, setModalStatus] = useState(props.open);
    const [tokenActive, setTokenActive] = useState(false);

    const updateTokenStatus = useCallback((e) => {
        if (e === 'auth') {
            setTokenActive(true);
        }
        if (e === 'expired') {
            setTokenActive(false);
        }
        if (e === 'logout' && !tokenActive) {
            setModalStatus(true);
        }
    }, [tokenActive]);

    useEffect(
        () => {
            setModalStatus(props.open);
        }, [props.open]
    );

    useEffect(() => {
        const handleTokenEvent = (e) => {
            updateTokenStatus(e);
        };

        Emitter.on('tokenEvent', (e) => handleTokenEvent(e));

        return () => Emitter.off('tokenEvent', (e) => handleTokenEvent(e));

    }, [updateTokenStatus]);

    const handleLoginClick = () => {
        if (props.keycloak) {
            props.keycloak.login({
                action: 'login',
                redirectUri: window.location.href,
            });
        }
    };

    return (
        <Modal
            id="modal-login-prompt"
            show={modalStatus}
            onClose={() => setModalStatus(false)}
            allowClose={false}
            closeButtonOnly={true}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '50%',
                transform: 'translate(-50%, -100%)'
            }}
        >
            <div className="panel no-padding no-margin full-width center">
                <h3 className="header">Your session has expired</h3>
                <p>To continue using Xrathus, please click Sign In.</p>
                <button
                    type="button"
                    className="btn btn-primary uppercase"
                    onClick={handleLoginClick}
                >
                    Sign In
                </button>
            </div>
        </Modal>
    );
};

export default withKeycloak(LoginModal);
import React, {useEffect, useState} from 'react';
import Modal from '../modal/Modal';

/**
 * Modal for Info upon Volume Creation
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VolumeCreatedModal = (props) => {

    const [modalStatus, setModalStatus] = useState(props.open);

    useEffect(
        () => {
            setModalStatus(props.open);
        }, [props.open]
    );

    const handleClose = () => {
        if (typeof props.onCloseDialog === 'function') {
            props.onCloseDialog();
        }
    };

    const volumeName = props?.volume ?? '';

    return (
        <Modal
            id="modal-volume-created"
            show={modalStatus}
            onClose={() => handleClose()}
            allowClose={true}
            closeButtonOnly={true}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '50%',
                transform: 'translate(-50%, -100%)'
            }}
        >
            <div className="panel no-padding no-margin full-width center">
                <h3 className="header">Volume in Process</h3>
                <p>Volume {volumeName} is being created. This process may take some time depending on the
                    total size of the dataset being versioned.
                </p>
                <p>
                    Once the Volume has completed, the Volumes table below will be updated to show
                    that {volumeName} is created and active.
                </p>
                <button
                    type="button"
                    className="btn btn-primary uppercase"
                    onClick={() => handleClose()}
                >
                    OK
                </button>
            </div>
        </Modal>
    );
};

export default VolumeCreatedModal;
import React from 'react';
import PropTypes from 'prop-types';
import {Link, BrowserRouter} from 'react-router-dom';
import '../../../css/storyblok-custom.css';
import '../../../css/scroll-list.css';
import '../../../css/tile.css';


const Tile = ({   
    title,
    image,
    size,
    alignment,
    link,
    isExternal,
    displayImage,
    imageNearEdge,
    tileContent,
    footerContent,
    disabled,
    demoRouter,
    className
}) => {


    const imageSource = image ? image : 'https://a.storyblok.com/f/127061/300x300/fdce599fc6/no-image-icon.png';

    const imageContent = (
        <div className="preview-thumbnail-container">
            <img src={imageSource} alt="Open" className="preview-thumbnail-image"/>
        </div>
    );
        
    const displayedTileContent = (
        <div className={"xrathus-item"}>
            {(imageNearEdge && displayImage) ? 
                imageContent : null
            }
            <div className={"item-header "+alignment}>
                <h4>{title}</h4>
            </div>
            <div className={"item-content"}>
                {(!imageNearEdge && displayImage) ? 
                    imageContent : null
                }
                <div className={alignment}>
                    {tileContent}
                </div>
            </div>
            {footerContent ? 
                <div className={"item-footer"}>
                    {footerContent}
                </div>: null}
            
        </div>
    );

    const disabledClass = disabled ? " disabled" : "";

    if (isExternal) {
        return (
            <div className={"preview-thumbnail "+size}>
                <div className={"tile "+className+disabledClass}>
                    <a href={link}
                        title={title} target="_blank" rel="noopener noreferrer"
                        className=""
                    >
                        {displayedTileContent}
                    </a>
                </div>
            </div>
        );
    }
    else {
        if (demoRouter) {
            return (
                <div className={"preview-thumbnail "+size}>
                    <div className={"tile "+className+disabledClass}>
                        <BrowserRouter>
                            <Link to={link} title={title}>
                                {displayedTileContent}
                            </Link>
                        </BrowserRouter>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className={"preview-thumbnail "+size}>
                    <div className={"tile "+className+disabledClass}>
                        <Link to={link} title={title}>
                            {displayedTileContent}
                        </Link>
                    </div>
                </div>
            );
        }
    }
};


Tile.propTypes = {
    /**
     * The title to be displayed on the tile
     */
    title: PropTypes.string,
    /**
     * The link to the displayed image
     */
    image: PropTypes.string,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /**
     * Tile orientation
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * Text Alignment
     */
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    /**
     * The link for the tile.
     * A link to an internal page starts with '/x/'.
     */
    link: PropTypes.string,
    /**
     * Is the link external?
     */
    isExternal: PropTypes.bool,
    /**
     * Display the image?
     */
    displayImage: PropTypes.bool,
    /**
     * Is image pushed to the edge?
     */
    imageNearEdge: PropTypes.bool,
    /**
     * Extra inside tile.
     */
    tileContent: PropTypes.any,
    /**
     * Extra content on bottom of tile.
     */
    footerContent: PropTypes.any,
    /**
     * Disabled?
     */
    disabled: PropTypes.bool,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
    /**
     * Styling class name
     */
    className: PropTypes.string,
};

Tile.defaultProps = {
    title: "",
    image: "",
    size: 'medium',
    orientation: 'horizontal',
    alignment: 'left',
    link: "",
    isExternal: false,
    displayImage: true,
    imageNearEdge: false,
    tileContent: null,
    footerContent: null,
    disabled: false,
    demoRouter: false,
    className: "",
};

export default Tile;
import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../../utils/eventEmitter';
import {setProjectConfigCurrent, setProjectListRefresh} from '../../store/actions';
import {isBlank} from '../../utils/dataFunctions';
import {openHelpWidget} from '../../utils/pageFunctions';
import {assembleAirmeetPath, getMaxShares, inUserList} from '../../utils/projectFunctions';
import {hasGroup} from '../../utils/tokenFunctions';
import APIProjects from '../../utils/APIProjects';
import APIProjectDataset from '../../utils/APIProjectDataset';
import AirmeetSessionsTable from '../../partials/AirmeetSessionsTable';
import ClusterTable from '../../partials/ClusterTable';
import FormAirmeetSessionCreate from '../../partials/forms/FormAirmeetSessionCreate';
import FormShareWithUser from '../../partials/forms/FormShareWithUser';
import LoadingIndicator from '../../partials/LoadingIndicator';
import ProjectDetail from '../../partials/ProjectDetail';
import ProjectSharedUsersTable from '../../partials/ProjectSharedUsersTable';
import '../../css/challenge.css';

/**
 * Page: Single Project with additional panels
 */
class XHubProject extends Component {
    constructor(props) {
        super(props);
        // Get Project type and ID from path
        const pathArr = props.location.pathname.split('/');
        const projectIdx = pathArr.findIndex(c => c === 'project');
        let projectType = pathArr[projectIdx + 1];
        let projectId;
        if (projectType === 'personal') {
            // If path is /x/{substrate}/project/type/{id}/{subpage}
            projectId = Number(pathArr[projectIdx + 2]);
        } else {
            // If path is /x/{substrate}/project/{id}/{subpage}
            projectId = Number(pathArr[(pathArr.length - 1)]);
            projectType = null;
        }

        const hasUserList = !!(props.project_list_subscribed?.length);
        this.state = {
            dataId: null,
            dataset: null,
            datasetFiles: [],
            hasUserList: hasUserList,
            project: null,
            projectId: projectId,
            projectRefresh: false,
            projectType: projectType,
            redirectUrl: null,
            refreshSharedUsers: false,
            sharedUsers: [],
            tab: 'overview'
        };

        this.handleJoinOrLeaveProject = this.handleJoinOrLeaveProject.bind(this);
        this.launchAirmeetView = this.launchAirmeetView.bind(this);
        this.setProject = this.setProject.bind(this);
    }

    componentDidMount() {
        Emitter.on('setTab', (e) => this.setTab(e));
    }

    componentWillUnmount() {
        Emitter.off('setTab');
    }

    // Set Project information and Data ID
    setProject = (data) => {
        let dataId = (data && data.data_id) ? data.data_id : null;
        this.setState({
            dataId: dataId,
            project: data
        }, () => {
            // Store Project Configuration for Dataset
            if (dataId) {
                this.props.setProjectConfigCurrent({
                    max_dataset_size: data.max_dataset_size ?? null,
                    max_shares: data.max_shares ?? null,
                    max_volumes: data.max_volumes ?? null,
                })
            }
        });
    };

    // Set Dataset description
    setDataset = (data) => {
        this.setState({
            dataset: data,
            refreshSharedUsers: false
        });
    };

    // Set Dataset File list
    setDatasetFiles = (data) => {
        this.setState({
            datasetFiles: data
        });
    };

    // Set the current tab of the Project detail
    setTab = (tab) => {
        this.setState({
            tab: tab
        });
    };

    // Set list of Shared Users from event
    setSharedUsers = (list) => {
        this.setState({
            sharedUsers: list
        });
    };

    refreshProject = () => {
        this.setState({
            projectRefresh: true
        }, () => {
            this.setState({
                projectRefresh: false
            });
        });
    };

    // Refresh Shared Users after Share event
    handleRefreshSharedUsers = () => {
        this.setState({
            refreshSharedUsers: true
        }, () => {
            this.setState({
                refreshSharedUsers: false
            });
        });
    };

    // Notify the API Projects component to update the User Projects List
    handleJoinOrLeaveProject = (type) => {
        if (type === 'subscribe') {
            this.refreshProject();
        }
        if (type === 'reject') {
            this.props.setProjectListRefresh('shared');
            // Redirect to Sharing view
            setTimeout(() => {
                this.setState({
                    redirectUrl: '/x/hub/sharing'
                });
            }, 600);
        }
        let subscribedRefresh = (this.props.user.is_sysop) ? 'subscribed_sysop' : 'subscribed';
        this.props.setProjectListRefresh(subscribedRefresh);
    };

    launchAirmeetView = (projectType, projectId, type, uuid, launch = true) => {
        const path = assembleAirmeetPath(projectType, projectId, type, uuid);
        if (launch && path) {
            this.props.history.push(path);
        }
    };

    render() {
        const {
            project_config,
            project_config_current,
            project_config_user,
            project_list_invited,
            project_list_own,
            project_list_subscribed,
            user
        } = this.props;

        const {
            dataId,
            dataset,
            datasetFiles,
            project,
            projectId,
            projectRefresh,
            projectType,
            redirectUrl,
            refreshSharedUsers,
            sharedUsers,
            tab
        } = this.state;

        if (redirectUrl) {
            return <Redirect to={redirectUrl}/>
        }

        const hasLab = hasGroup(user.groups, 'lab');
        const inInvitedProjects = (inUserList(project_list_invited, projectId));
        const inSubscribedProjects = (inUserList(project_list_subscribed, projectId));
        const inOwnProjects = (inUserList(project_list_own, projectId));
        const maxShares = getMaxShares(project_config, project_config_user, project_config_current);
        const airmeetView = (inOwnProjects) ? 'owner' : (user.is_sysop) ? 'sysop' : 'user';
        const hasAirmeet = (project && !isBlank(project.airmeet_id));

        return (
            <>
                <div className="content xhub project">
                    {!user.is_sysop && !inSubscribedProjects && !inOwnProjects && !inInvitedProjects &&
                        <>
                            <h1 className="page-title">
                                Personal Project {projectId}
                            </h1>
                            <div className="row">
                                <div className="col col-xs-12" key="projects-empty">
                                    <div className="panel">
                                        This Project is not a Project you created, or you have not been invited to Join
                                        this
                                        Project.
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {!project && (user.is_sysop || inInvitedProjects || inSubscribedProjects || inOwnProjects) &&
                        <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                    }
                    {project &&
                        <>
                            <h1 className="page-title">
                                {project.title}
                            </h1>

                            {/* Row: Projects */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <ProjectDetail
                                        projectConfig={project_config}
                                        projectItem={project}
                                        projectDataset={dataset}
                                        projectFiles={datasetFiles}
                                        projectType={projectType}
                                        user={user}
                                        onAirmeetLink={(type, uuid) => this.launchAirmeetView('project', projectId, type, uuid, true)}
                                        onJoinOrLeaveFunc={(e) => this.handleJoinOrLeaveProject(e)}
                                        allowLab={hasLab}
                                        inInvitedProjects={inInvitedProjects}
                                        inOwnProjects={inOwnProjects}
                                        inSubscribedProjects={inSubscribedProjects}
                                        tab={tab}
                                        isSysop={user.is_sysop}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {/* Row: Discourse Forum/Cluster Links */}

                    {project?.discourse_id &&
                        <div className="row">
                            <div className="col col-xs-12">
                                <h3 className="section-header full mt-0">Clusters</h3>
                            </div>
                            <div className="col col-xs-12">
                                <div className="panel no-padding">
                                    <h3 className="header">Project Discussions</h3>
                                    <ClusterTable discourseId={project.discourse_id} height="auto"/>
                                </div>
                            </div>
                        </div>
                    }

                    {(user.is_sysop || inSubscribedProjects || inOwnProjects) && project && projectId && hasAirmeet &&
                        <>
                            {/* Row: Airmeet Sessions Table and Create button */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <div className="panel no-padding">
                                        <h3 className="header">Real Time Collaboration Sessions</h3>

                                        {(user.is_sysop || inOwnProjects) &&
                                            <FormAirmeetSessionCreate
                                                itemType="project"
                                                item={project}
                                                onFormEvent={() => this.refreshProject()}
                                            />
                                        }

                                        <AirmeetSessionsTable
                                            airmeetView={airmeetView}
                                            itemId={projectId}
                                            inPanel={false}
                                            refresh={projectRefresh}
                                            onAirmeetLink={(type, uuid) => this.launchAirmeetView('project', projectId, type, uuid, true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {(user.is_sysop || inOwnProjects) && projectId &&
                        <>

                            {/* Row: Share with User */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <div className="panel no-padding mb-0">
                                        <h3 className="header">Share Personal Project</h3>
                                        {project && !project.active &&
                                            <p className="margin-1">
                                                You cannot share an inactive Project.
                                            </p>
                                        }
                                        {sharedUsers.length >= maxShares.project && !user.is_sysop &&
                                            <p className="margin-1">
                                                You have reached the maximum total of shares for this Project.
                                                To change the share limit for this Project or for your
                                                account, contact <button
                                                type="button" className="link text" title="Support"
                                                onClick={() => openHelpWidget()}>Xrathus Support</button>.
                                            </p>
                                        }
                                        {project && project.active && (sharedUsers.length < maxShares.project || user.is_sysop) &&
                                            <FormShareWithUser
                                                project={project}
                                                sharedUsers={sharedUsers}
                                                onShare={() => this.handleRefreshSharedUsers()}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Row: Shared Users Table */}

                            <ProjectSharedUsersTable
                                projectId={projectId}
                                inPanel={true}
                                refresh={refreshSharedUsers}
                                onList={(e) => this.setSharedUsers(e)}
                            />
                        </>
                    }
                </div>

                {(user.is_sysop || inInvitedProjects || inSubscribedProjects || inOwnProjects) &&
                    <APIProjects
                        getType="single"
                        viewType={projectType}
                        id={projectId}
                        onResult={this.setProject}
                        refresh={projectRefresh}
                    />
                }

                {(user.is_sysop || inInvitedProjects || inSubscribedProjects || inOwnProjects) && dataId &&
                    <APIProjectDataset
                        type="dataset"
                        id={dataId}
                        onDataResult={this.setDataset}
                        onFileListResult={this.setDatasetFiles}
                        refresh={false}/>
                }
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        project_config: state.project_config,
        project_config_current: state.project_config_current,
        project_config_user: state.project_config_user,
        project_list_invited: state.project_list_invited,
        project_list_own: state.project_list_own,
        project_list_subscribed: state.project_list_subscribed,
        user: state.user
    };
};

const mapDispatchToProps = {
    setProjectConfigCurrent: setProjectConfigCurrent,
    setProjectListRefresh: setProjectListRefresh
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XHubProject)
);

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resourcesData';
import {makePostDataRequest} from '../../utils/postRequest';
import {runFileUploadTask} from '../../utils/apiFileFunctions';
import {
    removeFromDirectoryList,
    removeFromFileList,
    setFilePaths,
    updateDirectoryList,
    updateDirectoryListProgress,
    updateFileList
} from '../../utils/fileFunctions';
import {withinDatasetSize} from '../../utils/projectFunctions';
import FileDrag from '../FileDrag';
import FileBrowse from '../FileBrowse';
import FileUploadList from '../FileUploadList';
import FileUploadProgressModal from '../modal/FileUploadProgressModal';
import FormError from './FormError';
import IconCheckmark from '../images/IconCheckmark';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import '../../css/file.css';

/**
 * Form: Add Dataset and Volume (Data, Files, Volumes)
 *
 * @param dataId
 * @param formType
 * @param formStage
 * @param onFormEvent
 * @param projectId
 * @param projectPath
 * @param projectString
 * @param projectType
 * @param isSysop
 * @param maxDatasetSize
 * @param basePath
 * @return {*}
 * @constructor
 */
export default function FormDataFilesVolumes(
    {
        dataId,
        formType,
        formStage,
        onFormEvent,
        projectId,
        projectPath,
        projectString,
        projectType,
        isSysop,
        maxDatasetSize,
        basePath = ''
    }) {

    // Set up form and state
    const {register, errors, formState, getValues, handleSubmit} = useForm({mode: 'onBlur'});

    // State: Process/Messaging
    const [processState, updateProcessState] = useState(
        {
            message: '',
            processing: false
        }
    );

    // State: Dataset Form Success
    const [successState, updateSuccessState] = useState(false);

    // Uploaded Files and Directories
    let fileList = [];
    const [fileListState, updateFileListState] = useState({
        list: []
    });
    let directoryList = [];
    const [directoryListState, updatedDirectoryListState] = useState({
        list: []
    });
    const [filesComplete, setFilesComplete] = useState(false);
    const [allowUpload, setAllowUpload] = useState(true);

    // Handle input or drag events and update directory & file lists
    const handleFilesAdded = (arr) => {
        // Update all file paths to be consistent
        let list = setFilePaths(arr);

        // Add file to file list and directory list
        fileList = updateFileList(fileListState.list, list);
        directoryList = updateDirectoryList(directoryListState.list, fileList);
        updateFileListState({list: fileList});
        updatedDirectoryListState({list: directoryList});
        setFilesComplete(false);
        updateProcessState({message: '', processing: false});

        // Disallow upload if newly uploaded files surpass the Dataset Size limit
        if (projectType === 'project' && maxDatasetSize) {
            const withinLimits = withinDatasetSize([], fileList, maxDatasetSize.project);
            setAllowUpload((withinLimits));
        } else {
            setAllowUpload(true);
        }
    };

    // Handle remove event and update directory & file lists
    const handleRemoveFile = (path) => {
        // Remove file from file list and directory list
        fileList = removeFromFileList(fileListState.list, path);
        updateFileListState({list: fileList});
        directoryList = removeFromDirectoryList(directoryListState.list, path);
        updatedDirectoryListState({list: directoryList});
        setAllowUpload(true);
    };

    // Files: Handle event for XHR progress
    const handleFileUploadProgress = (event, args) => {
        if (event === 'fileUpload') {
            if (args.loaded && args.total) {
                let percent = Math.round((args.loaded * 100) / args.total);
                if (percent < 0.1) {
                    percent = 0;
                }
                if (percent > 99.9) {
                    percent = 100;
                }
                // Update each directory being uploaded
                let directoryList = updateDirectoryListProgress(directoryListState.list, args.path, percent);
                updatedDirectoryListState({list: directoryList});
            }
        }
    };

    // Dataset: Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            getValues('description') === '' ||
            errors.description ||
            processState.processing ||
            !fileListState.list.length ||
            filesComplete ||
            !allowUpload
        );
    };

    // Dataset: Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // Add parameters
            let message = '';
            if (formType === 'create') {
                message = 'Saving new Dataset...';
            }
            if (formType === 'update') {
                data.id = dataId;
                message = 'Updating Dataset...';
            }

            // Submit form to API
            updateProcessState({message: message, processing: true});
            submitDataForm(data);
        }
    };

    // Dataset: Handle API call and post-submission
    const submitDataForm = (data) => {
        const postType = (formType === 'create') ? 'CREATE' : 'UPDATE';
        const request = makePostDataRequest(resources, 'Data', postType, data);
        request.then(result => {
            if (result) {
                setTimeout(() => {
                    if (fileListState.list && fileListState.list.length > 0) {
                        // If files have been added, begin the upload process
                        setPostSubmitFiles(result);
                    } else {
                        // Otherwise, the dataset is complete
                        updateProcessState({message: 'Dataset saved.', processing: false});
                        updateSuccessState(true);
                        if (onFormEvent !== null && typeof onFormEvent === 'function') {
                            onFormEvent({type: 'data', state: 'complete'});
                        }
                    }
                    return Promise.resolve(result);
                }, 500);
            } else {
                updateProcessState({
                    message: 'Sorry, your ' + projectString + ' could not be created.',
                    processing: false
                });
                updateSuccessState(false);
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Add ' + projectString + ' Error: ', error);
            updateProcessState({
                message: 'Sorry, there was an error with your ' + projectString + '.',
                processing: false
            });
            updateSuccessState(false);
            return Promise.reject('Server Error');
        });
    };

    // Files: Process Files after completion of Dataset submission
    const setPostSubmitFiles = async (data) => {

        // If files have been added to this Dataset, begin upload
        if (fileListState.list && fileListState.list.length > 0) {

            updateProcessState({message: 'Dataset saved, uploading Files...', processing: true});

            // Assemble an array of actions to execute sequentially
            let tasks = [];
            let taskLength = 0;
            for (let i = 0; i < directoryListState.list.length; i++) {
                const task = directoryListState.list[i];
                if (task.files && task.files.length > 0) {
                    tasks.push({
                        index: taskLength,
                        task: 'get',
                        data: {
                            data_id: data.id,
                            index: taskLength,
                            path: task.directory,
                            collection: task.files,
                            progressFunc: handleFileUploadProgress
                        }
                    })
                    taskLength++;
                }
            }

            // Start a Promise sequence with all tasks
            const starterPromise = Promise.resolve(null);
            await tasks.reduce(
                (p, spec) => p.then(() => runFileUploadTask(spec)
                    .then((result) => {
                        // If all tasks in the list have completed:
                        if (spec.index >= (taskLength - 1)) {
                            // Note that Files are completed
                            updateProcessState({message: 'File upload complete.', processing: false});
                            setFilesComplete(true);
                            updateFileListState({list: []});
                            updatedDirectoryListState({list: []});
                            if (onFormEvent !== null && typeof onFormEvent === 'function') {
                                onFormEvent('files', 'complete', data);
                            }
                        }
                    })), starterPromise)
                .catch((error) => {
                    console.log('File Upload error: ', error);
                    updateProcessState({
                        message: 'There was an error uploading one or more of your files. Please ensure all files are in the same location and that none was moved or deleted.',
                        processing: false
                    });
                    updateSuccessState(false);
                });
        } else {
            updateProcessState({message: 'Dataset saved.', processing: false});
            updateSuccessState(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="xrathus-detail">
                <div className="item-content full">

                    <div className={`tab-content ${(successState === false) ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter a Dataset description and add, remove or replace files if needed.
                        </p>

                        <div className="form-group">
                            {/* Data Description */}
                            <TextAreaInput
                                id="data-description"
                                rows={6} cols={50}
                                name="description" label="Description"
                                instructions="Enter a Dataset description."
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.description ? "true" : "false"}
                                ref={register({
                                    required: (<FormError id="required" text="A Description is required"/>)
                                })}
                                required
                                className={`form-input ${errors.description ? 'input-error' : ''}`}
                            >
                                {errors.description && errors.description.message}
                            </TextAreaInput>
                        </div>

                        <div className="form-group">
                            <label>Files</label>
                            <p className="instructions">
                                If files are associated with this Dataset, add them here. You may browse or drag
                                multiple folders or files.
                            </p>
                            <p className="instructions">
                                Note: If you need to move files into different directories after adding, remove them
                                below and re-add.
                            </p>
                            {!isSysop && projectType === 'project' &&
                            <p className="instructions">
                                You may upload a maximum total of {maxDatasetSize.project} GB in files for this Project.
                            </p>
                            }

                            <div className="file-upload">
                                <div className="file-upload-browse">
                                    <FileBrowse placement="form" onReady={handleFilesAdded}/>
                                </div>
                                <div className="file-upload-drag">
                                    <FileDrag id="user-dashboard-data-drag" onReady={handleFilesAdded}/>
                                </div>
                            </div>

                            {!processState.processing &&
                            <div className="file-list">
                                <h4>Files to Upload</h4>
                                <FileUploadList list={fileListState.list} onRemove={handleRemoveFile}/>
                            </div>
                            }

                            <FileUploadProgressModal
                                list={directoryListState.list}
                                title="Uploading Files to Dataset"
                                open={(fileListState.list.length > 0 && processState.processing)}
                            />

                            <p className="instructions">
                                {fileListState.list && fileListState.list.length > 0 &&
                                <>
                                    {!isSysop && !allowUpload && projectType === 'project' &&
                                    <>
                                        Note: Adding these files will exceed this Project's Dataset Size limit, which
                                        is set to {maxDatasetSize.project}. Please remove or replace these files, or
                                        delete one of the existing Files from the Dataset.
                                        <br/><br/>
                                    </>
                                    }
                                    {allowUpload &&
                                    <>
                                        Once your Dataset is saved with files, you may view the new {projectString} to
                                        edit its dataset or files, access the workspace, or create a versioned,
                                        persistent volume of your Dataset.
                                        <br/><br/>
                                        After upload begins, do not navigate away from this page.
                                    </>
                                    }
                                </>
                                }
                            </p>
                        </div>
                    </div>

                    <div className={`tab-content ${(successState === true) ? 'active' : ''}`}>
                        <h4>Dataset Saved</h4>
                        <p>
                            Your Dataset has been saved. Visit your User Dashboard to view and add more Datasets.
                        </p>
                    </div>

                    {/* Actions and Messaging */}

                    <div className="form-btns right">
                        {successState === true &&
                        <>
                            <p className="message">
                                <IconCheckmark/>
                            </p>
                        </>
                        }
                        {processState.message !== '' &&
                        <p className="message">
                            {processState.message}
                        </p>
                        }

                        <LoadingIndicator color="dark" centered={false} active={processState.processing}/>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!!disableSubmit()}
                        >
                            Save Dataset and Upload Files
                        </button>
                        {projectType === 'challenge' &&
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/${projectPath}/challenge/${projectId}/overview`}>
                                View Challenge Details
                            </Link>
                        }
                        {projectType === 'project' &&
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/hub/project/personal/${projectId}/overview`}
                            >
                                View Project Details
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
}

import React, {useState} from 'react';
import {RESOURCES as resources} from '../../resourcesProject';
import {makeDeleteDataRequest} from '../../utils/deleteRequest';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../modal/Modal';

const DeleteProjectButton = ({projectId, projectName, onDeletedProject, disabled}) => {
    const [modalStatus, setModalStatus] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleDeleteConfirmation = () => {
        setSaving(true);
        const params = {
            id: projectId
        };
        const request = makeDeleteDataRequest(resources, 'ProjectPersonalDelete', 'DELETE', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string' && result.indexOf('Deleted') !== -1) {
                    handlePostDeletion();
                } else if (result?.error?.indexOf('no personal project found') !== -1) {
                    console.log('Project Deletion Error: No Project found');
                }
                return Promise.resolve(result);
            } else {
                console.log('Project Deletion Error: No volume');
                return Promise.reject('Project Deletion failed');
            }
        }).catch(error => {
            console.log('Project Deletion Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    const handlePostDeletion = () => {
        setDeleted(true);
        setSaving(false);
        setTimeout(() => {
            if (typeof onDeletedProject === 'function') {
                onDeletedProject({type: 'project', state: 'deleted'});
            }
            setModalStatus(false);
        }, 1000);
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                title="Delete Project"
                onClick={() => setModalStatus(true)}
            >
                Delete Project
            </button>
            <Modal
                allowClose={true}
                id="modal-project-delete"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">Are you sure you want to delete this Project?</h3>
                    {projectName &&
                    <p>Project: {projectName}</p>
                    }
                    <p>Note: All Datasets, Files and Volumes associated with this Project will be marked for deletion. Any users who have joined this Project will no longer be able to access it.</p>
                    {!saving && !deleted &&
                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleDeleteConfirmation}>YES</button>
                    </div>
                    }
                    {saving &&
                    <>
                        <LoadingIndicator color="dark" centered={true} size={26} active={true}/>
                        <div className="form-btns center mt-1">
                            Deleting Project...
                        </div>
                    </>
                    }
                    {deleted &&
                    <div className="form-btns center">
                        This Project has been deleted.
                    </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default DeleteProjectButton;

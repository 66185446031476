import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../../utils/eventEmitter';
import {setEventConfigCurrent, setEventListRefresh} from '../../store/actions';
import {openHelpWidget} from '../../utils/pageFunctions';
import {assembleAirmeetPath, getMaxEventShares, inUserList} from '../../utils/projectFunctions';
import APIEvents from '../../utils/APIEvents';
import EventDetail from '../../partials/EventDetail';
import EventPublicJoinedUsersTable from "../../partials/EventPublicJoinedUsersTable";
import EventSharedUsersTable from '../../partials/EventSharedUsersTable';
import FormShareWithUser from '../../partials/forms/FormShareWithUser';
import LoadingIndicator from '../../partials/LoadingIndicator';
import '../../css/challenge.css';

/**
 * Page: Single Event with additional panels
 */
class XConnectEvent extends Component {
    constructor(props) {
        super(props);
        // Get Event type and ID from path
        const pathArr = props.location.pathname.split('/');
        const eventIdx = pathArr.findIndex(c => c === 'event');
        let eventType = pathArr[eventIdx + 1];
        let eventId;
        if (eventType === 'personal' || eventType === 'public') {
            // If path is /x/{substrate}/event/type/{id}/{subpage}
            eventId = pathArr[eventIdx + 2];
        } else {
            // If path is /x/{substrate}/event/{id}/{subpage}
            eventId = pathArr[(pathArr.length - 1)];
            eventType = null;
        }

        const hasUserList = !!(props.event_list_subscribed?.length);
        this.state = {
            hasUserList: hasUserList,
            event: null,
            eventId: eventId,
            eventRefresh: false,
            eventType: eventType,
            redirectUrl: null,
            refreshSharedUsers: false,
            sharedUsers: [],
            tab: 'overview'
        };

        this.handleJoinOrLeaveEvent = this.handleJoinOrLeaveEvent.bind(this);
        this.launchAirmeetView = this.launchAirmeetView.bind(this);
        this.setEvent = this.setEvent.bind(this);
    }

    componentDidMount() {
        Emitter.on('setTab', (e) => this.setTab(e));
    }

    componentWillUnmount() {
        Emitter.off('setTab');
    }

    // Set Event information
    setEvent = (data) => {
        this.setState({
            event: data
        });
    };

    // Set the current tab of the Event detail
    setTab = (tab) => {
        this.setState({
            tab: tab
        });
    };

    // Set list of Shared Users from Event
    setSharedUsers = (list) => {
        this.setState({
            sharedUsers: list
        });
    };

    refreshEvent = () => {
        this.setState({
            eventRefresh: true
        }, () => {
            this.setState({
                eventRefresh: false
            });
        });
    };

    // Refresh Shared Users after Share event
    handleRefreshSharedUsers = () => {
        this.setState({
            refreshSharedUsers: true
        }, () => {
            this.setState({
                refreshSharedUsers: false
            });
        });
    };

    // Notify the API Events component to update the User Events List
    handleJoinOrLeaveEvent = (type) => {
        if (type === 'subscribe') {
            this.refreshEvent();
        }
        if (type === 'reject') {
            this.props.setEventListRefresh('shared');
            // Redirect to Sharing view

            setTimeout(() => {
                this.setState({
                    redirectUrl: '/x/connect/events/sharing'
                });
            }, 600);
        }
        let subscribedRefresh = (this.props.user.is_sysop) ? 'subscribed_sysop' : 'subscribed';
        this.props.setEventListRefresh(subscribedRefresh);
    };

    launchAirmeetView = (eventType, eventId, type, uuid, launch = true) => {
        const path = assembleAirmeetPath('event', eventId, type, uuid);
        if (launch && path) {
            this.props.history.push(path);
        }
    };

    render() {
        const {
            event_config,
            event_config_current,
            event_config_user,
            event_list_invited,
            event_list_own,
            event_list_subscribed,
            user
        } = this.props;

        const {
            event,
            eventId,
            eventRefresh,
            eventType,
            redirectUrl,
            refreshSharedUsers,
            sharedUsers,
            tab
        } = this.state;

        if (redirectUrl) {
            return <Redirect to={redirectUrl}/>
        }

        const inInvitedEvents = (inUserList(event_list_invited, eventId));
        const inSubscribedEvents = (inUserList(event_list_subscribed, eventId));
        const inOwnEvents = (inUserList(event_list_own, eventId));
        const isPublic = (eventType === 'public');
        const maxShares = getMaxEventShares(event_config, event_config_user, event_config_current);

        return (
            <>
                <div className="content xhub event">
                    {!user.is_sysop && !inSubscribedEvents && !inOwnEvents && !inInvitedEvents && !isPublic &&
                        <>
                            <h1 className="page-title">
                                Personal Event {eventId}
                            </h1>
                            <div className="row">
                                <div className="col col-xs-12" key="events-empty">
                                    <div className="panel">
                                        This Event is not an Event you created, or you have not been invited to Join
                                        this Event.
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {!event && (user.is_sysop || inInvitedEvents || inSubscribedEvents || inOwnEvents) &&
                        <LoadingIndicator color="dark" centered={false} size={40} active={true}/>
                    }
                    {event &&
                        <>
                            <h1 className="page-title">
                                {event.name}
                            </h1>

                            {/* Row: Events */}

                            <div className="row">
                                <div className="col col-xs-12">
                                    <EventDetail
                                        eventConfig={event_config}
                                        eventItem={event}
                                        eventType={eventType}
                                        user={user}
                                        onAirmeetLink={(type, uuid) => this.launchAirmeetView('event', eventId, type, uuid, true)}
                                        onJoinOrLeaveFunc={(e) => this.handleJoinOrLeaveEvent(e)}
                                        inInvitedEvents={inInvitedEvents}
                                        inOwnEvents={inOwnEvents}
                                        inSubscribedEvents={inSubscribedEvents}
                                        tab={tab}
                                        isSysop={user.is_sysop}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {(user.is_sysop || inOwnEvents) && eventId &&
                        <>

                            {/* Row: Share with User */}
                            {event && event.private &&
                            <div className="row">
                                <div className="col col-xs-12">
                                    <div className="panel no-padding mb-0">
                                        <h3 className="header">Share Personal Event</h3>
                                        {sharedUsers.length >= maxShares.event && !user.is_sysop &&
                                            <p className="margin-1">
                                                You have reached the maximum total of shares for this Event.
                                                To change the share limit for this Event or for your
                                                account, contact <button
                                                type="button" className="link text" title="Support"
                                                onClick={() => openHelpWidget()}>Xrathus Support</button>.
                                            </p>
                                        }
                                        {event && (sharedUsers.length < maxShares.event || user.is_sysop) &&
                                            <FormShareWithUser
                                                event={event}
                                                sharedUsers={sharedUsers}
                                                onShare={() => this.handleRefreshSharedUsers()}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            {/* Row: Shared Users Table For Private Event */}
                            {event && event.private &&
                                <EventSharedUsersTable
                                    eventId={eventId}
                                    inPanel={true}
                                    refresh={refreshSharedUsers}
                                    onList={(e) => this.setSharedUsers(e)}
                                />
                            }

                            {/* Row: Shared Users Table For Public Event */}
                            {event && !event.private &&
                                <EventPublicJoinedUsersTable
                                    eventId={eventId}
                                    inPanel={true}
                                    refresh={refreshSharedUsers}
                                    onList={(e) => this.setSharedUsers(e)}
                                />
                            }
                        </>
                    }
                </div>

                {(user.is_sysop || inInvitedEvents || inSubscribedEvents || inOwnEvents || isPublic) &&
                    <APIEvents
                        getType="single"
                        viewType={eventType}
                        id={eventId}
                        onResult={this.setEvent}
                        refresh={eventRefresh}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        event_config: state.event_config,
        event_config_current: state.event_config_current,
        event_config_user: state.event_config_user,
        event_list_invited: state.event_list_invited,
        event_list_own: state.event_list_own,
        event_list_subscribed: state.event_list_subscribed,
        user: state.user
    };
};

const mapDispatchToProps = {
    setEventConfigCurrent: setEventConfigCurrent,
    setEventListRefresh: setEventListRefresh
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(XConnectEvent)
);

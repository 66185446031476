import React, {Component, Suspense} from 'react';

const Content = React.lazy(() => import('../data/content/PrivacyPolicy'));

class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <div className="content legal privacy">
                    <Suspense
                        fallback={<h1 className="page-title legal">Xrathus Communities Platform Privacy Policy</h1>}>
                        <Content/>
                    </Suspense>
                </div>
            </>
        )
    }
}

export default PrivacyPolicy;

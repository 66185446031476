import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {RESOURCES as resources} from '../resourcesData';
import {makeGetDataRequest} from '../utils/getRequest';
import {makeDateString, sortArrayByKey} from '../utils/dataFunctions';
import DeleteVolumeButton from './buttons/DeleteVolumeButton';
import ProjectVolumesTotal from '../partials/ProjectVolumesTotal';
import SetVolumeActiveButton from './buttons/SetVolumeActiveButton';
import TableBooleanField from "./fields/TableBooleanField";
import TableWithSubrows from './tables/TableWithSubrows';
import ViewVolumeFilesButton from './buttons/ViewVolumeFilesButton';
import VolumeFileList from './VolumeFileList';

let volumeTimer = null;

/**
 * Table of Volumes for Datasets
 *
 * @param dataId
 * @param isSysop
 * @param inOwnProjects
 * @param onVolumeList
 * @param projectType
 * @param maxVolumes
 * @param checkVolumeStatus
 * @returns {JSX.Element}
 * @constructor
 */
const VolumeList = (
    {
        dataId,
        isSysop,
        inOwnProjects,
        onVolumeList,
        projectType,
        maxVolumes = 1,
        checkVolumeStatus = false
    }) => {

    const [volumeList, setVolumeList] = useState([]);
    const [refreshList, setRefreshList] = useState(true);

    // Callback: Render a Volume list row
    const renderSubrow = useCallback(
        ({row}) => (
            <VolumeFileList
                dataId={dataId}
                volumeName={row.values.name}
            />
        ),
        [dataId]
    )

    // Callback: Set the Volume list and notify parent
    const setVolumeData = useCallback((list) => {
        setVolumeList(list);
        if (typeof onVolumeList === 'function') {
            onVolumeList(list.length);
        }
    }, [onVolumeList]);

    // Callback: Refresh Volume list until all Volumes are created
    const startVolumeTimer = useCallback((list) => {
        volumeTimer = setInterval(() => {
            let complete = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i].created === false) {
                    complete = false;
                    setRefreshList(true);
                    break;
                }
            }
            if (complete) {
                clearInterval(volumeTimer);
                volumeTimer = null;
            }
        }, 4000);
    }, []);

    useEffect(() => {
        const params = {
            data_id: dataId
        };

        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'Volume', 'GET_LIST', params);
            setRefreshList(false);
            request.then(result => {
                if (result) {
                    if (result.length > 0) {
                        for (let i = 0; i < result.length; i++) {
                            result[i].created_on_str = makeDateString(result[i].created_on);
                        }
                        let list = sortArrayByKey(result, 'created_on', 'DESC');
                        setVolumeData(list);
                        let complete = true;
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].created === false) {
                                complete = false;
                                break;
                            }
                        }
                        if (complete) {
                            clearInterval(volumeTimer);
                            volumeTimer = null;
                        } else {
                            if (!volumeTimer) {
                                setRefreshList(true);
                                startVolumeTimer(list);
                            }
                        }
                    } else if (result && !result.length) {
                        setVolumeData([]);
                    }
                    return Promise.resolve(result);
                } else {
                    console.log('Volumes Error: No Volumes');
                    return Promise.reject('Volumes failed');
                }
            }).catch(error => {
                console.log('Volumes Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        if (dataId && refreshList) {
            return fetchData();
        }

    }, [dataId, refreshList, setVolumeData, startVolumeTimer]);

    useEffect(() => {
        if (checkVolumeStatus && !volumeTimer) {
            return () => {
                setRefreshList(true);
            }
        }
    }, [checkVolumeStatus]);

    const handleRefresh = (e) => {
        if (e.state === 'complete') {
            if (e.type === 'volumes') {
                setRefreshList(true);
            }
        }
    };

    // Table setup
    const tableData = useMemo(() => volumeList, [volumeList]);
    const columns = useMemo(
        () => [
            {
                Header: 'Volume',
                accessor: 'name',
                Cell: ({row}) => (
                    <span className={(row.original.deleted) ? 'deleted' : ''}>
                        {row.original.name}
                    </span>
                ),
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Created',
                accessor: 'created_on_str',
                Cell: ({row}) => (
                    <span className={(row.original.deleted) ? 'deleted' : ''}>
                        {row.original.created_on_str}
                    </span>
                ),
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'View Files',
                id: 'fileExpander',
                Cell: ({row}) => {
                    return (
                        <span {...row.getToggleRowExpandedProps()}>
                            <ViewVolumeFilesButton
                                dataId={dataId}
                                volumeName={row.original.name}
                                disabled={(!row.original.created)}
                            />
                        </span>
                    )
                },
                width: 30,
                maxWidth: 30,
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (value) => {
                    return (
                        <span style={{display: 'block', textAlign: 'left'}}>
                            <TableBooleanField name="Created" active={value.cell.row.original.created}/>
                        </span>
                    )
                },
                width: 60,
                maxWidth: 60,
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Active',
                accessor: 'active',
                Cell: (value) => {
                    return (
                        <span style={{display: 'block', textAlign: 'left'}}>
                            <SetVolumeActiveButton
                                record={value}
                                dataId={dataId}
                                disabled={(value.cell.row.original.deleted || !value.cell.row.original.created)}
                                onFormEvent={(e) => handleRefresh(e)}
                            />
                        </span>
                    )
                },
                width: 60,
                maxWidth: 60,
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: '',
                accessor: ' ',
                Cell: (value) => {
                    return (
                        <DeleteVolumeButton
                            record={value}
                            dataId={dataId}
                            disabled={(value.cell.row.original.deleted || !value.cell.row.original.created)}
                            onFormEvent={(e) => handleRefresh(e)}/>
                    )
                },
                width: 30,
                maxWidth: 30,
                disableFilters: true,
                disableSortBy: true
            }
        ], [dataId]
    );

    return (
        <>
            <div className="panel no-padding">
                <h3 className="header">Volumes ({volumeList.length ? volumeList.length : '0'})</h3>

                {!isSysop && !inOwnProjects &&
                    <div className="row">
                        <div className="col col-xs-12" key="projects-empty">
                            <div className="panel">
                                You are not authorized to view Volumes from this Dataset.
                            </div>
                        </div>
                    </div>
                }
                {(isSysop || inOwnProjects) &&
                    <>
                        {tableData.length > 0 &&
                            <TableWithSubrows
                                columns={columns}
                                data={tableData}
                                subrowComponent={renderSubrow}
                                buttonsClass='last-1'
                            />
                        }
                        {projectType === 'project' &&
                            <ProjectVolumesTotal size={volumeList.length} maxSize={maxVolumes}/>
                        }
                    </>
                }
            </div>
        </>
    );
};

export default VolumeList;

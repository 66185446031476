import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {filterListByValue, sortListByValue} from '../../utils/filterFunctions';
import {setChallengesList, setProjectsList} from '../../utils/projectFunctions';
import APIProjects from '../../utils/APIProjects';
import {Select, Header, Input} from '../../partials/filters/ChallengeFilterComponents';
import '../../css/challenge.css';
import '../../css/search-filter.css';

/**
 * Set current list type based on Project/Challenge route
 *
 * @param path
 * @returns {string}
 */
const setListType = (path) => {
    if (path.indexOf('projects/personal') !== -1) {
        return 'projects-personal-own';
    } else if (path.indexOf('projects/challenges') !== -1) {
        return 'challenges-subscribed';
    } else if (path.indexOf('hub/sharing') !== -1) {
        return 'projects-shared';
    } else if (path.indexOf('projects/subscribed') !== -1) {
        return 'projects-subscribed';
    } else if (path.indexOf('projects/training') !== -1 || path.indexOf('projects/hub-training') !== -1) {
        return 'projects-training';
    }
    return 'projects-personal-own';
};

/**
 * Set page title based on type of Projects requested
 *
 * @param listType
 * @return {string}
 */
const setProjectPageTitle = (listType) => {
    if (listType === 'projects-personal-own') {
        return 'My Personal Projects';
    } else if (listType === 'challenges-subscribed') {
        return 'Joined Challenges';
    } else if (listType === 'projects-training') {
        return 'Training Projects';
    } else if (listType === 'projects-shared') {
        return 'Projects Shared with You';
    } else if (listType === 'projects-subscribed') {
        return 'Projects Shared with You';
    }
    return 'Projects';
};

const sortTypes = {
    latest: 'created_on',
    popular: 'created_on',
    alphabetically: 'title',
}

/**
 * Page: Projects List (Personal (Own), Challenges (Joined), Training, Shared (Joined))
 */
class XHubProjects extends Component {
    constructor(props) {
        super(props);

        const listType = setListType(props.location.pathname);
        const pageTitle = setProjectPageTitle(listType);
        this.state = {
            active: true,
            filteredList: [],
            listTotals: {
                challengeSubscribed: 0,
                projectOwn: 0,
                projectPersonal: 0,
                projectShared: 0,
                projectSubscribed: 0,
                projectTraining: 0,
            },
            listType: listType,
            mainList: [],
            pageTitle: pageTitle,
            pathname: '',
            projectList: [],
            publicChallengeTotal: 0,
            resetList: true,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        };
    }

    componentDidMount() {
        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Update state on location change
        if (nextProps.location.pathname !== prevState.pathname) {
            const listType = setListType(nextProps.location.pathname);
            const pageTitle = setProjectPageTitle(listType);
            if (document.getElementById('searchHeader')) {
                document.getElementById('searchHeader').reset();
            }

            return {
                filteredList: [],
                listType: listType,
                pageTitle: pageTitle,
                pathname: nextProps.location.pathname,
                projectList: [],
                resetList: true,
                search: {
                    value: ''
                },
                sort: {
                    value: 'alphabetically'
                }
            };
        }

        // Reset current list if stored totals have changed or populated
        if (
            prevState.listTotals.challengeSubscribed !== nextProps.challenge_list_subscribed.length ||
            prevState.listTotals.projectSubscribed !== nextProps.project_list_subscribed.length
        ) {
            return {
                listTotals: {
                    challengeSubscribed: nextProps.challenge_list_subscribed.length,
                    projectTraining: 0,
                    projectSubscribed: nextProps.project_list_subscribed.length,
                },
                resetList: true
            }
        }

        return null;
    }

    // Handle on-page Projects API results (instead of stored results)
    handleProjectListResult = (data) => {
        this.setState({
            projectList: data,
            resetList: false,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        }, () => {
            this.setMainList();
        });
    };

    // Handle event from Filter By Title
    setSearch = (value) => {
        this.setState({
            search: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    // Handle event from Active Filter
    setActive = (value) => {
        this.setState({
            active: (value === 'active'),
            resetList: true,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        });
    };

    // Handle event from Sort By filter
    setSort = (value) => {
        this.setState({
            sort: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    setReset = (value) => {
        this.setState({
            resetList: value
        }, () => {
            this.filterAndSortList();
        });
    }

    // Populate page list based on listType
    setMainList = () => {
        let mainList;
        switch (this.state.listType) {
            case 'challenges-subscribed':
                mainList = this.props.challenge_list_subscribed;
                break;
            case 'projects-personal-own':
                mainList = this.state.projectList;
                break;
            case 'projects-training':
                // Unavailable
                mainList = this.props.project_list_subscribed;
                break;
            case 'projects-subscribed':
                mainList = this.props.project_list_subscribed;
                break;
            default:
                mainList = this.state.projectList;
                break;
        }
        this.setState({
            mainList: mainList
        }, () => {
            this.filterAndSortList();
        })
    }

    filterAndSortList = () => {
        let tempList = JSON.parse(JSON.stringify(this.state.mainList));
        if (tempList && tempList.length > 0) {
            // Apply Text Filter
            tempList = filterListByValue(tempList, this.state.search?.value?.toLowerCase(), ['title', 'summary']);

            // Apply sorting
            tempList = sortListByValue(tempList, this.state.sort?.value, sortTypes);
        }
        this.setState({
            filteredList: tempList
        })
    }

    render() {
        const {
            challenge_list_subscribed,
            project_list_subscribed
        } = this.props;

        const {
            active,
            filteredList,
            listType,
            pageTitle,
            projectList,
            resetList,
            search,
            sort
        } = this.state;

        return (
            <>
                <div className="content xhub">
                    <h1 className="page-title">
                        {pageTitle}
                    </h1>
                    <form id="searchHeader">
                        <Header>
                            <Input
                                placeholder="Filter by Title"
                                onKeyUp={e => this.setSearch(e.target.value)}
                                className="control-search"
                            />
                            {listType === 'projects-personal-own' &&
                            <Select
                                values={[
                                    "Active",
                                    "Inactive"
                                ]}
                                onChange={e => this.setActive(e.target.value)}
                                className="control-active"
                            />
                            }
                            <div className="nowrap">
                                <Select
                                    values={[
                                        "Alphabetically",
                                        "Latest"
                                    ]}
                                    onChange={e => this.setSort(e.target.value)}
                                    className="control-sort"
                                />
                                <div className="control-label">
                                    Sort By:
                                </div>
                            </div>
                        </Header>
                    </form>

                    {/* Row: Personal Projects (Own) */}
                    {listType === 'projects-personal-own' &&
                    <div className="row">
                        {[...setProjectsList(filteredList, projectList, 'No Personal Projects found.')]}
                    </div>
                    }

                    {/* Row: Joined Challenges */}
                    {listType === 'challenges-subscribed' &&
                    <div className="row">
                        {[...setChallengesList(filteredList, challenge_list_subscribed, 'You have not yet joined any Challenges.')]}
                    </div>
                    }

                    {/* Row: Training Projects */}
                    {listType === 'projects-training' &&
                    <div className="row">
                        <div className="col col-xs-12" key="projects-empty">
                            <div className="panel">
                                Training Projects are not yet available.
                            </div>
                        </div>
                    </div>
                    }

                    {/* Row: Projects shared with User */}
                    {listType === 'projects-shared' &&
                    <div className="row">
                        {[...setProjectsList(filteredList, project_list_subscribed, 'No Projects have been shared with you, or you have not yet joined any Projects.')]}
                    </div>
                    }

                    {/* Row: Projects joined by User */}
                    {listType === 'projects-subscribed' &&
                    <div className="row">
                        {[...setProjectsList(filteredList, project_list_subscribed, 'You have not yet joined any Projects.')]}
                    </div>
                    }
                </div>

                {listType === 'projects-personal-own' &&
                <APIProjects
                    getType="list"
                    viewType="personal_own_by_active"
                    params={{active: active}}
                    refresh={resetList}
                    storeResults={false}
                    onResult={(e) => this.handleProjectListResult(e)}
                />
                }
                {listType === 'projects-shared' &&
                <APIProjects
                    getType="list"
                    viewType="shared"
                    params={{
                        order: 'ASC',
                        page: 1,
                        perPage: 10000,
                        search: (search.value !== '') ? search.value : '',
                        sort: 'ASC',
                        sortBy: (sort.value === 'alphabetically') ? 'title' : 'created_on'
                    }}
                    refresh={resetList}
                    storeResults={true}
                    onResult={(e) => this.handleProjectListResult(e)}
                />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        challenge_list_subscribed: state.challenge_list_subscribed,
        project_list_own: state.project_list_own,
        project_list_subscribed: state.project_list_subscribed
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(XHubProjects)
);

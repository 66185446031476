import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';

class GoogleAnalytics extends Component {
    constructor(props) {
        super(props);

        // Initialize Google Analytics if an ID is passed from the config
        // Set up to accommodate a Google Analytics 4 Measurement ID for future
        const ga = props.configParams.google_analytics_id;
        let hasAnalytics = false;
        if (ga && ga !== '' && ga.indexOf('-XXX') === -1) {
            hasAnalytics = true;
            ReactGA.initialize(ga);
        }

        this.state = {
            analyticsId: ga,
            hasAnalytics: hasAnalytics
        };
    }

    componentDidMount() {
        if (this.state.hasAnalytics && this.props.keycloakReady) {
            this.onRouteChange(this.props.location.pathname, this.props.location.search);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.hasAnalytics) {
            const keycloakReady = this.props.keycloakReady;
            if (keycloakReady) {
                const {location: {pathname, search}} = this.props;
                const prevLocation = prevProps.location;
                const isDifferentPath = pathname !== prevLocation.pathname;
                const isDifferentSearch = search !== prevLocation.search;
                const setInitialRoute = (!prevProps.keycloakReady);

                if (isDifferentPath || isDifferentSearch || setInitialRoute) {
                    this.onRouteChange(pathname, search);
                }
            }
        }
    }

    onRouteChange = (pathname, search = '') => {
        const page = pathname + search;
        const {location} = window;
        ReactGA.set({
            page,
            location: `${location.origin}${page}`,
            ...this.props.options
        });
        ReactGA.pageview(page);
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady
    };
};

GoogleAnalytics.propTypes = {
    configParams: PropTypes.object,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    options: PropTypes.object
}

export default withRouter(connect(
    mapStateToProps
)(GoogleAnalytics));

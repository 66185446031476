import React from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';
import '../../../css/storyblok-custom.css';
import '../../../css/collaborator-custom.css';


const Panel = ({
    content,
    panelCustomClass,
    panelCustomStyle,
    label,
    labelCustomClass,
    labelCustomStyle,
    showBorder,
    allStories,
    storyblokFormatted,
}) =>  {

    let customClass = panelCustomClass ? panelCustomClass : "";

    let borderClass = showBorder ? customClass+" panel extra-margin " : "";

    //@TODO: Create panel label option without border
    return (
        <div className={customClass} style={JSON.parse(panelCustomStyle)}>
            {label ? 
                <div className={borderClass}>
                    {
                        labelCustomClass ?
                            <h3 className={labelCustomClass+" header"} style={JSON.parse(labelCustomStyle)}>{label}</h3> 
                            :   <h3 className="header" style={JSON.parse(labelCustomStyle)}>{label}</h3> 
                    }
                    {storyblokFormatted ?  parseStoryblokContent(content, "", "", allStories) : content}
                </div>
                :
                <div className={borderClass}>
                    {storyblokFormatted ?  parseStoryblokContent(content, "", "", allStories) : content}
                </div>
            }
        </div>
    );
};

Panel.propTypes = {
    /**
     * What content goes inside?
     */
    content: PropTypes.array,
    /**
     * Custom css class for panel.
     */
    panelCustomClass: PropTypes.string,
    panelCustomStyle: PropTypes.string,
    /**
     * Displayed label for the panel.
     */
    label: PropTypes.string,
    /**
     * Custom css class for label.
     */
    labelCustomClass: PropTypes.string,
    labelCustomStyle: PropTypes.string,
    /**
     * Show border of the panel
     */
    showBorder: PropTypes.bool,
    /**
     * Storyblok-specific parameter passed alongside content.
     * @TODO : Eliminate this totally
     */
    allStories: PropTypes.any,
    /**
     * Is content formatted specifically by Storyblok?
     */
    storyblokFormatted: PropTypes.bool,
};

Panel.defaultProps = {
    content: [],
    panelCustomClass: "",
    panelCustomStyle: "{}",
    label: "",
    labelCustomClass: "",
    labelCustomStyle: "{}",
    showBorder: true,
    allStories: {},
    storyblokFormatted: false,
};

export default Panel;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {checkAuthentication} from '../utils/authFunctions';
import {XRATHUS_PAGE_FORM} from '../data/page-form';
import '../css/page-form.css';
import {connect} from 'react-redux';

class PagePrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {
                company: '',
                email: '',
                name: '',
            },
            page: XRATHUS_PAGE_FORM[props.type]
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let updateState = false;
        let user = {
            email: prevState.formValues.email,
            name: prevState.formValues.name
        };
        const isAuthenticated = checkAuthentication(nextProps.tokens.token, nextProps.keycloak.token, nextProps.keycloak.authenticated);
        if (
            isAuthenticated &&
            nextProps.keycloakReady &&
            (nextProps.user.name !== prevState.formValues.name)
        ) {
            user = {
                email: nextProps.user.email,
                name: nextProps.user.name
            };
            updateState = true;
        }
        if (updateState) {
            return {
                formValues: {
                    name: user.name,
                    email: user.email
                }
            };
        }

        return null;
    }

    handleClick = (type) => {
        let args = {
            form: null
        };
        if (type === 'form') {
            let formValues = this.state.formValues;
            args.type = 'Book A Call';
            args.subject = 'Book A Call';
            if (formValues.name) {
                args.name = formValues.name;
            }
            if (formValues.company) {
                args.company = formValues.company;
            }
            if (formValues.email) {
                args.email = formValues.email;
            }
        }
        if (type === 'demo') {
            args.type = 'Demo Request';
            args.subject = 'Demo Request';
        }
        if (typeof this.props.onSubmitFunc === 'function') {
            this.props.onSubmitFunc(args);
        }
    };

    render() {
        const {
            page
        } = this.state;

        return (
            <div id="page-form" className="row">
                <div className="col col-xs-12">
                    <div className="panel page-form center-content column">
                        <div className="row">
                            <div className="col col-sm-1">
                            </div>
                            <div className="col col-xs-10">
                                {page.title &&
                                    <h2>
                                        {page.title}
                                    </h2>
                                }
                                {page.content1 &&
                                    <p>{page.content1}</p>
                                }
                                {page.content2 &&
                                    <p>{page.content2}</p>
                                }
                            </div>
                            <div className="col col-sm-1">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-1">
                            </div>
                            <div className="col col-xs-5 center">
                                <button
                                    type="button"
                                    className="btn btn-hub btn-arrow table uppercase mt-2"
                                    onClick={() => this.handleClick('demo')}
                                >
                                    Book A Demo >
                                </button>
                            </div>
                            <div className="col col-xs-5 center">
                                <button
                                    type="button"
                                    className="btn btn-training btn-arrow table uppercase mt-2"
                                    onClick={() => this.handleClick('form')}
                                >
                                    Request A Call >
                                </button>
                            </div>
                            <div className="col col-sm-1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

PagePrompt.propTypes = {
    onSubmitFunc: PropTypes.func,
    type: PropTypes.string.isRequired
};

export default withKeycloak(
    connect(mapStateToProps)(PagePrompt)
);
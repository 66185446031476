export const airmeetStyles = {
    container: {
        height: 'calc(100vh - 210px)',
        minHeight: 520
    },
    iframe: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000
    },
    title: {
        display: 'inline-block',
        width: 'calc(100% - 200px)'
    }
};

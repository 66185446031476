import React from 'react';
import {useTable} from 'react-table';

/**
 * Basic React Table
 *
 * @param columns
 * @param data
 * @param buttonsClass
 * @returns {JSX.Element}
 * @constructor
 */
const BasicTable = ({columns, data, buttonsClass}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });

    return (
        <div className="x-table-container">
            <table className={"x-table challenge no-margin " + buttonsClass} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return (
                                <th
                                    {...column.getHeaderProps()}
                                    className={column?.headerClassName ?? undefined}
                                >
                                    {column.render('Header')}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td
                                    {...cell.getCellProps()}
                                    className={cell?.column?.className ?? undefined}
                                >
                                    {cell.render('Cell')}
                                </td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
                {footerGroups.length > 0 &&
                <tfoot>
                {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                    </tr>
                ))}
                </tfoot>
                }
            </table>
        </div>
    )
}

export default BasicTable;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './modal.css';

const modalStyle = {
    width: '80%'
};

/**
 * Generic Modal container
 */
export default class Modal extends Component {

    onClose = () => {
        this.props.onClose();
    };

    render() {
        const {
            allowClose,
            classes,
            closeButtonOnly,
            children,
            header,
            id,
            show,
            style
        } = this.props;

        let modalClass = (show) ? ' toggled-on' : '';
        let modalContentClass = (show) ? ' active' : '';
        if (header) {
            modalContentClass += ' has-header';
        }
        if (classes) {
            modalContentClass += ' ' + classes;
        }

        return (
            <div className={`modal${modalClass}`} id={id}>
                <div className="modal-overlay" onClick={() => (closeButtonOnly) ? undefined : this.onClose()}>
                </div>
                <div className={`modal-content${modalContentClass}`} style={style}>
                    {children}
                    {allowClose &&
                    <button type="button" className="modal-close" onClick={() => this.onClose()}>X</button>
                    }
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    allowClose: PropTypes.bool,
    classes: PropTypes.string,
    closeButtonOnly: PropTypes.bool,
    header: PropTypes.bool,
    id: PropTypes.string,
    onClose: PropTypes.func,
    show: PropTypes.bool,
    style: PropTypes.object
};

Modal.defaultProps = {
    allowClose: true,
    closeButtonOnly: false,
    header: false,
    id: 'modal',
    style: modalStyle
};

import React, {Component} from 'react';
import '../../css/static.css';

class XHubFiles extends Component {
    render() {

        return (
            <>
                <div className="content files">
                    <h1 className="page-title">
                        Files
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Once available, the Files menu in the Dashboard will facilitate uploads, reviews
                                    and sharing. File editing is available using the Xrathus Workspace.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubFiles;

import {assembleQuery} from './utils/dataFunctions';

/**
 * Resources for API
 *      Event Configuration (Default)
 *      Event Configuration (Event Override)
 *      Non-Personal Events (pending)
 *      Personal Events (CRUD)
 *      Personal Events (Owned by User)
 *      Personal Events (Joined by User)
 *      Personal Events (Shared with User)
 *      Shared Users
 *      Airmeet Sessions
 *      Join Personal Event
 *      Reject Shared Personal Event
 *      Share Personal Event with User
 *      Unsubscribe User from Personal Event
 */
export const RESOURCES = {
    /* Event Configuration (Global Default) */
    'EventConfiguration': {
        GET_LIST: function () {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/global/event/configs'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/global/event/config/' + params.config
            }
        },
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/global/event/config/' + params.config + '/' + params.value
            }
        }
    },
    /* Personal Public Events */
    'Event': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/browse/public/events?' + query
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/create/event'
            }
        },
    },
    /* Personal Events: List, Single, Create/Update */
    'EventPersonal': {
        GET_LIST: function (params) {
            // @TODO need API
            console.log('GET EventPersonal');
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/event/browse/personal/' + params.active + '?' + query
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/create/event'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/update/event'
            }
        }
    },
    /* List: Personal Events owned by User */
    'EventPersonalOwn': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/events/user/all'
            }
        }
    },
    /* List: Personal Events joined/subscribed to by User */
    'EventPersonalSubscribed': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/browse/joined/events?' + query
            }
        }
    },
    /* List: Personal Events joined/subscribed to by Sysop */
    'EventPersonalSubscribedSysop': {
        GET_LIST: function (params) {
            console.log('GET EventPersonalSubscribedSysop');
            // @TODO need API
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/event/browse/joined/personal/sysop?' + query
            }
        }
    },
    /* List: Personal Events shared with User  */
    'EventPersonalShared': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/browse/shared/events?' + query
            }
        }
    },
    /* List: Events in Admin (Sysop) */
    'EventAdmin': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/events/all?' + query
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/create/event'
            }
        },
    },
    /* List: Users a Personal Event is shared with  */
    'EventPersonalSharedUsers': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/shares/' + params.id
            }
        }
    },
    /* List: Users a Personal Event is shared with  */
    'EventPublicSharedUsers': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/joins/' + params.id
            }
        }
    },
    /* List: Homepage Public Events  */
    'EventHomePagePublic': {
        GET_LIST: function (params) {
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/browse/homepage/public/events?' + query
            }
        }
    },
    /* Action: Delete a Personal Event */
    'EventPersonalDelete': {
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/event/' + params.id
            }
        }
    },
    /* Action: Update a Personal Event's Max Shares */
    'EventPersonalUpdateMaxShares': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/update/event/' + params.id + '/' + params.value
            }
        }
    },
    /* Action: Join a Personal Event */
    'EventPersonalJoin': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/join/event/' + params.id
            }
        }
    },
    /* Action: Reject a Shared Personal Event */
    'EventPersonalReject': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/reject/event/invite/' + params.id
            }
        }
    },
    /* Action: Share a Personal Event */
    'EventPersonalShare': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/event/share/' + params.id + '/' + params.username
            }
        }
    },
    /* Action: Unsubscribe User from Personal Event (by self or by owner) */
    'EventPersonalUnsubscribe': {
        POST: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/event/unsubscribe'
            }
        }
    },
    /* List, Action: Airmeet Sessions for a Public or Personal Event  */
    'AirmeetSessions': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/sessions/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/airmeet/create/session'
            }
        }
    },
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Event"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}

import React, {useState} from 'react';
import {RESOURCES as resources} from '../../resourcesEvent';
import {makeDeleteDataRequest} from '../../utils/deleteRequest';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../modal/Modal';

const DeleteEventButton = ({eventId, eventName, onDeletedEvent, disabled}) => {
    const [modalStatus, setModalStatus] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleDeleteConfirmation = () => {
        setSaving(true);
        const params = {
            id: eventId
        };
        const request = makeDeleteDataRequest(resources, 'EventPersonalDelete', 'DELETE', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string' && result.indexOf('Deleted') !== -1) {
                    handlePostDeletion();
                } else if (result?.error?.indexOf('no personal event found') !== -1) {
                    console.log('Event Deletion Error: No Event found');
                }
                return Promise.resolve(result);
            } else {
                console.log('Event Deletion Error');
                return Promise.reject('Event Deletion failed');
            }
        }).catch(error => {
            console.log('Event Deletion Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    const handlePostDeletion = () => {
        setDeleted(true);
        setSaving(false);
        setTimeout(() => {
            if (typeof onDeletedEvent === 'function') {
                onDeletedEvent({type: 'event', state: 'deleted'});
            }
            setModalStatus(false);
        }, 1000);
    };

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                title="Delete Event"
                onClick={() => setModalStatus(true)}
            >
                Delete Event
            </button>
            <Modal
                allowClose={true}
                id="modal-event-delete"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">Are you sure you want to delete this Event?</h3>
                    {eventName &&
                    <p>Event: {eventName}</p>
                    }
                    <p>Note: Any users who have joined this Event will no longer be able to access it.</p>
                    {!saving && !deleted &&
                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleDeleteConfirmation}>YES</button>
                    </div>
                    }
                    {saving &&
                    <>
                        <LoadingIndicator color="dark" centered={true} size={26} active={true}/>
                        <div className="form-btns center mt-1">
                            Deleting Event...
                        </div>
                    </>
                    }
                    {deleted &&
                    <div className="form-btns center">
                        This Event has been deleted.
                    </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default DeleteEventButton;

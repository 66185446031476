import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {RESOURCES as resourcesEvent} from '../../resourcesEvent';
import {RESOURCES as resourcesProject} from '../../resourcesProject';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import LoadingIndicator from '../LoadingIndicator';

/**
 * Form: Share Project or Event with Username
 *
 * @param event
 * @param project
 * @param sharedUsers
 * @param onShare
 * @returns {JSX.Element}
 * @constructor
 */
const FormShareWithUser = ({event, project, sharedUsers, onShare}) => {

    const {handleSubmit} = useForm({mode: 'onBlur'});

    const [label] = useState((event) ? 'Event' : 'Project');
    const [sharedUserName, setSharedUserName] = useState('');
    const [userMessage, setSharedUserMessage] = useState(null);
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    const [formStageState, setFormStageState] = useState('form');

    const handleUserInput = (e) => {
        setSharedUserName(e?.value?.toLowerCase() ?? '');
        setSharedUserMessage(null);
    };

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            formStageState === 'saving' ||
            sharedUserName === ''
        );
    };

    // Handle submission event
    const onSubmit = () => {
        if (sharedUserName) {
            if (event && event.id) {
                if (event.username.toLowerCase() === sharedUserName.toLowerCase()) {
                    // Prevent sharing with self
                    setSharedUserMessage('You cannot share your Event with yourself.');
                } else {
                    // Submit form to API
                    setFormStageState('saving');
                    submitForm({id: event.id, username: sharedUserName});
                }
            }
            if (project && project.id) {
                if (project.username.toLowerCase() === sharedUserName.toLowerCase()) {
                    // Prevent sharing with self
                    setSharedUserMessage('You cannot share your Project with yourself.');
                } else {
                    // Submit form to API
                    setFormStageState('saving');
                    submitForm({id: project.id, username: sharedUserName});
                }
            }
        }
    };

    // Handle API call and post-submission
    const submitForm = (params) => {
        let request;
        if (event) {
            request = makePostDataRequest(resourcesEvent, 'EventPersonalShare', 'PUT', params);
        }
        if (project) {
            request = makePostDataRequest(resourcesProject, 'ProjectPersonalShare', 'PUT', params);
        }

        let message = '';
        request.then(result => {
            if (result && result === 'OK') {
                updateSuccessState({
                    success: true,
                    message: 'This Personal ' + label + ' has been shared with ' + sharedUserName + '.'
                });
                setFormStageState('success');
                if (typeof onShare === 'function') {
                    onShare();
                }
                return Promise.resolve(result);
            }
            if (result?.error) {
                if (result.error.indexOf('already shared') !== -1) {
                    message = 'This Personal ' + label + ' has already been shared with the user \'' + sharedUserName + '\'.';
                } else if (result.error.indexOf('no user info found') !== -1) {
                    message = 'Sorry, the user ' + sharedUserName + ' was not found.';
                } else if (event && result.error.indexOf('no personal event found') !== -1) {
                    message = 'Sorry, there was an error. Personal Event ID ' + event.id + ' was not found.';
                } else if (project && result.error.indexOf('no personal project found') !== -1) {
                    message = 'Sorry, there was an error. Personal Project ID ' + project.id + ' was not found.';
                } else {
                    message = 'Sorry, there was an error sharing this Personal ' + label + '.';
                }
            } else if (result?.message && result.message.indexOf('unauthorized') !== -1) {
                message = 'Sorry, you are not authorized to share this Personal ' + label + '.';
            } else {
                message = 'Sorry, there was an error sharing this Personal ' + label + '.';
            }
            updateSuccessState({
                success: false,
                message: message
            });
            setFormStageState('fail');
            return Promise.resolve();
        }).catch(error => {
            console.log('Share Personal ' + label + ' Error: ', error);
            updateSuccessState({
                success: false,
                message: 'Sorry, there was an error sharing this Personal ' + label + ': ' + error
            });
            setFormStageState('fail');
            return Promise.reject('Server Error');
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="item-content full margin-1 mb-2">
                <p className="instructions">
                    Share this {label} with a specific user. Type in a username.
                </p>

                {/* Find User */}

                <div className="row">
                    <div className="form-group col col-xs-6 mb-0">
                        <BaseInput
                            type="text"
                            id="username"
                            name="username"
                            label=""
                            placeholder="Enter a Username"
                            value={sharedUserName}
                            changeFunc={(e) => handleUserInput(e)}
                            className="form-input"
                        >
                        </BaseInput>
                        {userMessage &&
                            <p className="message"><br/>
                                {userMessage}
                            </p>
                        }
                    </div>
                    <div className="form-group col col-xs-6 mb-0">
                        <button type="submit" className="btn btn-primary mb-0" disabled={!!disableSubmit()}>
                            Share with User
                        </button>
                    </div>
                </div>

                {/* Messaging and Actions */}

                <div className="form-btns mt-1">
                    {formStageState === 'saving' &&
                        <>
                            <LoadingIndicator color="dark" centered={false} active={true}/>
                            <p className="message">Sharing {label}...</p>
                        </>
                    }
                    {formStageState === 'success' &&
                        <p className="message">{label} shared with user {sharedUserName}</p>
                    }
                    {formStageState === 'fail' &&
                        <>
                            {successState.message !== '' &&
                                <p className="message">
                                    {successState.message}
                                </p>
                            }
                            {successState.message === '' &&
                                <p className="message">
                                    Sorry, there was an issue sharing this {label}.
                                </p>
                            }
                        </>
                    }
                </div>
            </div>
        </form>
    );
};

export default FormShareWithUser;

import { useState, useEffect, useRef } from 'react'; 

/**
 * Perform a Storyblok content fetch
 *
 * @param url
 * @param params
 * @return {array | null}
 */
export function useYoutubePlaylistFetch( playlistId ) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const unmountedRef = useRef(false);
    useEffect(()=>()=>(unmountedRef.current = true), []);

    const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
    const YOUTUBE_API_SERVICE_NAME = "youtube";
    const YOUTUBE_API_VERSION = "v3";
    const numOfVidToLoad = 50;

    let url = `https://www.googleapis.com/${YOUTUBE_API_SERVICE_NAME}/${YOUTUBE_API_VERSION}/`+
            `playlistItems?part=snippet&maxResults=${numOfVidToLoad}&playlistId=${playlistId}&key=${YOUTUBE_API_KEY}`;

    useEffect(() => {
        let effectStale = false;
        
        async function fetchData() {
            if (unmountedRef.current) return;
            if (effectStale) return;

            const response = await fetch(url)
                .then(response => {
                    return response.json();
                })
                .then(function(response) {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        let videoList = [];
                        for (let i=0; i < response.items.length; i++) {
                            videoList.push({id: response.items[i].snippet.resourceId.videoId, 
                                name: response.items[i].snippet.title,
                                playlistId: response.items[i].snippet.playlistId
                            });
                        }
                        return videoList;
                    }
                });
            if (unmountedRef.current) return;
            if (effectStale) return;
            setLoading(false);

            if (response) {
                setData(response);
            }
        }

        if (isLoading) {
            // call the function
            fetchData()
                // make sure to catch any error
                .catch(function (error) {
                    if (error.response) {
                        // request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // request was made but no response was received
                        console.log(error.request);
                    } else {
                        // something happened in setting up the request that triggered an error
                        console.log('Error', error.message);
                    }
                });
        }
        return ()=>(effectStale = true);

    },[data, isLoading, url]);

    return [data, isLoading];
}
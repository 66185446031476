/**
 * User Resources for API
 *
 */
export const RESOURCES = {
    'User': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/profile'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/update'
            }
        }
    },
    'UserInfo': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/info/' + params.username
            }
        }
    },
    'UserInit': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/init/' + params.persona
            }
        }
    },
    'UserPersona': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/update/primaryPersona/' + params.persona
            }
        }
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "UserPersona"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}

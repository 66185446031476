import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {sortArrayByKey} from '../utils/dataFunctions';
import TeamMemberLink from './TeamMemberLink';
import {XRATHUS_TEAM} from '../data/team';

class TeamMemberLinks extends Component {
    constructor(props) {
        super(props);

        const items = this.createTeamItems();
        this.state = {
            items: items
        };
    }

    createTeamItems = () => {
        let items = [];
        Object.keys(XRATHUS_TEAM).forEach(function (key) {
            const item = XRATHUS_TEAM[key];
            if (item.name) {
                const teamMember = {
                    slug: key,
                    order: item.order,
                    featured: item.featured,
                    name: item.name,
                    role: item.role,
                    thumb: item.thumb,
                    link: item.link
                };
                items.push(teamMember);
            }
        });
        sortArrayByKey(items, 'order', 'ASC')
        return items;
    };

    renderTeamItems = () => {
        return (
            this.state.items.map((item, index) => (
                <TeamMemberLink
                    key={`team-member-${item.slug}-${index}`}
                    data={item}
                />
            ))
        )
    };

    render() {
        return (
            <div className="team-members">
                {[...this.renderTeamItems()]}
            </div>
        )
    }
}

export default withRouter(TeamMemberLinks);

import React from 'react';
import SelectInput from '../fields/SelectInput';

const ActiveFilter = ({handleActive, type}) => {
    return (
        <form className="form-group x-filter-active">
            <SelectInput
                id={`${type}-active-filter`}
                name={`${type}-active-filter`}
                label="Active"
                options={[
                    {name: 'True', value: true},
                    {name: 'False', value: false}
                ]}
                changeFunc={handleActive}
            />
        </form>
    );
};

export default ActiveFilter;
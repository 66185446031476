import React from 'react';
import ChallengeItem from '../partials/ChallengeItem';
import EventItem from "../partials/EventItem";
import ProjectItem from '../partials/ProjectItem';

/**
 * Generate an array of Challenge Items for a grid
 *
 * @param project_list
 * @param user_challenge_list
 * @param empty_message
 * @return {JSX.Element|*}
 */
export const setChallengesList = (project_list, user_challenge_list, empty_message = 'No Challenges') => {
    if (!project_list || project_list.length === 0) {
        return (
            [0].map(() => (
                <div className="col col-xs-12" key="challenges-empty">
                    <div className="panel">
                        {empty_message}
                    </div>
                </div>
            ))
        );
    }
    return (
        project_list.map((item, index) => (
            <div className="col col-xs-12 col-md-6 col-lg-4" key={`challenge-${item.id}-${index}`}>
                <ChallengeItem
                    projectItem={item}
                    idx={index}
                    inUserChallenges={(inUserList(user_challenge_list, item.id))}
                    progress={false}
                    clickFunc={null}
                    clickLink={`/x/${(inUserList(user_challenge_list, item.id)) ? 'hub' : 'hub'}/challenge/${item.id}/overview`}
                />
            </div>
        ))
    );
};

/**
 * Generate an array of Event Items for a grid
 *
 * @param list_type
 * @param event_list
 * @param user_event_list
 * @param empty_message
 * @param isAuthenticated
 * @return {JSX.Element|*}
 */
export const setEventsList = (list_type, event_list, user_event_list, empty_message = 'No Events', isAuthenticated = true) => {

    if (!event_list || event_list.length === 0) {
        return (
            [0].map(() => (
                <div className="col col-xs-12" key="events-empty">
                    <div className="panel">
                        {empty_message}
                    </div>
                </div>
            ))
        );
    }

    return (
        event_list.map((item, index) => {
            let clickLink;
            if(isAuthenticated) {
                clickLink = `/x/connect/event/${(list_type === 'events-public') ? 'public' : 'personal'}/${item.id}/overview`;
            } else {
                clickLink = `https://www.airmeet.com/e/${item.id}`
            }

            return (
                <div className="col col-xs-12 col-md-6 col-lg-4" key={`event-${item.id}-${index}`}>
                    <EventItem
                        eventItem={item}
                        idx={index}
                        inUserOrOwnEvents={(inUserList(user_event_list, item.id))}
                        clickFunc={null}
                        clickLink={clickLink}
                        isAuthenticated={isAuthenticated}
                    />
                </div>
            )
        })
    );
};

/**
 * Generate an array of Project Items for a grid
 *
 * @param project_list
 * @param user_project_list
 * @param empty_message
 * @return {JSX.Element|*}
 */
export const setProjectsList = (project_list, user_project_list, empty_message = 'No Projects') => {
    if (!project_list || project_list.length === 0) {
        return (
            [0].map(() => (
                <div className="col col-xs-12" key="projects-empty">
                    <div className="panel">
                        {empty_message}
                    </div>
                </div>
            ))
        );
    }

    return (
        project_list.map((item, index) => (
            <div className="col col-xs-12 col-md-6 col-lg-4" key={`project-${item.id}-${index}`}>
                <ProjectItem
                    projectItem={item}
                    idx={index}
                    inUserOrOwnProjects={(inUserList(user_project_list, item.id))}
                    clickFunc={null}
                    clickLink={`/x/${(inUserList(user_project_list, item.id)) ? 'hub' : 'hub'}/project/personal/${item.id}/overview`}
                />
            </div>
        ))
    );
};

/**
 * Determine if an ID is in a list of Challenges, Projects, or Events
 *
 * @param list
 * @param id
 * @returns {boolean}
 */
export const inUserList = (list, id) => {
    const idx = list.findIndex(r => r.id === id);
    return (idx !== -1);
};

/**
 * Determine if a Data ID is in a list of Challenges, Projects, or Events
 *
 * @param list
 * @param dataId
 * @returns {boolean}
 */
export const datasetInUserList = (list, dataId) => {
    const idx = list.findIndex(r => r.data_id === dataId);
    return (idx !== -1);
};

/**
 * Get Events permissions (Sysop or Event Owner) by Event ID
 *
 * @param user
 * @param list
 * @param eventId
 * @returns {{owner: boolean, sysop: (boolean|*)}}
 */
export const getEventPermissions = (user, list, eventId) => {
    return {
        sysop: user?.is_sysop ?? false,
        owner: inUserList(list, eventId)
    };
};

/**
 * Get Project permissions (Sysop or Project Owner) by Project ID or Dataset ID
 *
 * @param user
 * @param list
 * @param projectId
 * @param dataId
 * @returns {{owner: boolean, sysop: (boolean|*)}}
 */
export const getProjectPermissions = (user, list, projectId, dataId) => {
    const inOwnProjects = ((projectId)
            ? inUserList(list, projectId)
            : datasetInUserList(list, dataId)
    );
    return {
        sysop: user?.is_sysop ?? false,
        owner: inOwnProjects
    };
};

/**
 * Assemble Max Dataset Size values from all configurations
 *
 * @param config
 * @param userConfig
 * @param projectConfig
 * @returns {{default, project: (null|*), user: *}}
 */
export const getMaxDatasetSize = (config, userConfig, projectConfig) => {
    const project = projectConfig ?? {max_dataset_size: null};
    return {
        default: config.max_dataset_size,
        project: (project.max_dataset_size !== null)
            ? project.max_dataset_size : (userConfig.max_dataset_size !== null)
                ? userConfig.max_dataset_size : config.max_dataset_size,
        user: (userConfig.max_dataset_size !== null) ? userConfig.max_dataset_size : config.max_dataset_size
    };
};

/**
 * Assemble Max Event Shares values from all configurations
 *
 * @param config
 * @param userConfig
 * @param eventConfig
 * @returns {{default, project: (null|*), user: *}}
 */
export const getMaxEventShares = (config, userConfig, eventConfig) => {
    const event = eventConfig ?? {max_shares: null};
    return {
        default: config.max_shares,
        event: (event.max_shares !== null)
            ? event.max_shares : (userConfig.max_shares !== null)
                ? userConfig.max_event_shares : config.max_shares,
        user: (userConfig.max_event_shares !== null) ? userConfig.max_event_shares : config.max_shares
    };
};

/**
 * Assemble Max Project Shares values from all configurations
 *
 * @param config
 * @param userConfig
 * @param projectConfig
 * @returns {{default, project: (null|*), user: *}}
 */
export const getMaxShares = (config, userConfig, projectConfig) => {
    const project = projectConfig ?? {max_shares: null};
    return {
        default: config.max_shares,
        project: (project.max_shares !== null)
            ? project.max_shares : (userConfig.max_shares !== null)
                ? userConfig.max_shares : config.max_shares,
        user: (userConfig.max_shares !== null) ? userConfig.max_shares : config.max_shares
    };
};

/**
 * Assemble Max Volumes values from all configurations
 *
 * @param config
 * @param userConfig
 * @param projectConfig
 * @returns {{default, project: *, user: *}}
 */
export const getMaxVolumes = (config, userConfig, projectConfig) => {
    const project = projectConfig ?? {max_volumes: null};
    return {
        default: config.max_volumes,
        project: (project.max_volumes !== null)
            ? project.max_volumes : (userConfig.max_volumes !== null)
                ? userConfig.max_volumes : config.max_volumes,
        user: (userConfig.max_volumes !== null) ? userConfig.max_volumes : config.max_volumes
    };
};

/**
 * Determine if newly uploaded files and existing files surpass the Dataset Size limit
 *
 * @param existingFiles
 * @param newFiles
 * @param maxSize
 * @returns {boolean}
 */
export const withinDatasetSize = (existingFiles, newFiles, maxSize) => {
    let existingSize = 0;
    let newSize = 0;
    for (let e = 0; e < existingFiles.length; e++) {
        existingSize += existingFiles[e].size;
    }
    for (let n = 0; n < newFiles.length; n++) {
        newSize += newFiles[n].file.size;
    }
    return (existingSize + newSize) < (maxSize * 1000000000);
};

/**
 * Populate User Personal Event Configuration from global values
 *
 * @param defaultConfig
 * @param userConfig
 * @returns {{}}
 */
export const populatePersonalEventUserConfig = (defaultConfig, userConfig) => {
    let config = {};
    config.max_shares = userConfig?.max_shares ?? defaultConfig.max_shares;
    config.max_events = userConfig?.max_events ?? defaultConfig.max_events;
    return config;
};

/**
 * Populate User Personal Project Configuration from global values
 *
 * @param defaultConfig
 * @param userConfig
 * @returns {{}}
 */
export const populatePersonalProjectUserConfig = (defaultConfig, userConfig) => {
    let config = {};
    config.max_dataset_size = userConfig?.max_dataset_size ?? defaultConfig.max_dataset_size;
    config.max_shares = userConfig?.max_shares ?? defaultConfig.max_shares;
    config.max_volumes = userConfig?.max_volumes ?? defaultConfig.max_volumes;
    config.projects = userConfig?.projects ?? defaultConfig.projects;
    return config;
};

/**
 * Populate a short description for a Challenge, Event or Project from its Summary
 *
 * @param data
 * @param type
 * @returns {string}
 */
export const populateShortDescription = (data, type) => {
    let summary;
    summary = data?.summary ?? '';

    if (type === 'event') {
        summary = data?.short_desc ?? '';
    }

    const ell = (summary.length > 100) ? '...' : '';
    return summary.substr(0, 100) + ell;
};

/**
 * Create a future due date
 *
 * @param separator
 * @returns {{due_str: string, due_date: Date}}
 */
export const setDueDate = (separator = '/') => {
    const today = new Date();
    const due = new Date(today);
    due.setDate(due.getDate() + 1);
    return {
        due_date: due,
        due_str: due.getFullYear() + separator + (due.getMonth() + 1) + separator + due.getDate()
    };
};

/**
 * Prevent previous date selection
 *
 * @param time
 * @returns {boolean}
 */
export const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

/**
 * Assemble a URL for an Airmeet URL and launch a tab
 *
 * @param projectType
 * @param projectId
 * @param type
 * @param uuid
 * @param launch
 */
export const launchAirmeet = (projectType, projectId, type, uuid, launch = true) => {

    // Assemble Airmeet path with type and UUID
    let path = '';
    switch (projectType) {
        case 'challenge':
            path = '/x/hub/challenge/';
            break;
        case 'event':
            path = '/x/connect/event/';
            break;
        case 'project':
            path = '/x/hub/project/personal/';
            break;
        case 'project_personal':
            path = '/x/hub/project/personal/';
            break;
        default:
            break;
    }
    path += projectId + '/rtc/';
    path += (type === 'session') ? 's/' : 'e/';
    path += uuid;
    if (launch) {
        window.open(window.location.origin + path, '_self');
    }
};

/**
 * Assemble path for an Airmeet embed view
 *
 * @param projectType
 * @param projectId
 * @param type
 * @param uuid
 * @returns {string}
 */
export const assembleAirmeetPath = (projectType, projectId, type, uuid) => {
    let path = '';
    switch (projectType) {
        case 'challenge':
            path = '/x/hub/challenge/';
            break;
        case 'event':
            path = '/x/connect/event/';
            break;
        case 'project':
            path = '/x/hub/project/personal/';
            break;
        case 'project_personal':
            path = '/x/hub/project/personal/';
            break;
        default:
            break;
    }
    path += projectId + '/rtc/';
    path += (type === 'session') ? 's/' : 'e/';
    path += uuid;
    return path;
};

import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import Config from '../config';

const initialState = {
    challenge_list_public: [],
    challenge_list_subscribed: [],
    challenge_list_subscribed_refresh: false,
    event_config: Config.eventConfigMin,
    event_config_current: Config.eventConfigCurrent,
    event_config_refresh: false,
    event_config_user: Config.eventConfigUser,
    event_config_user_refresh: false,
    event_id: null,
    event_list_invited: [],
    event_list_own: [],
    event_list_public: [],
    event_list_refresh: null,
    event_list_subscribed: [],
    event_list_subscribed_refresh: false,
    keycloakReady: false,
    list_status: Config.listStatus,
    project_config: Config.projectConfigMin,
    project_config_current: Config.projectConfigCurrent,
    project_config_refresh: false,
    project_config_user: Config.projectConfigUser,
    project_config_user_refresh: false,
    project_id: null,
    project_file_list: [],
    project_list_invited: [],
    project_list_own: [],
    project_list_refresh: null,
    project_list_subscribed: [],
    sidebarOpen: false,
    tokens: Config.tokensDefault,
    user: Config.user
};

// Create a Redux store with initial state, Redux-Thunk and DevTools
const store = createStore(
    reducer,
    initialState,
    compose(
        applyMiddleware(thunk),
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    )
);

export default store;

/**
 * Messaging / Language
 *      Personas
 *      Substrates
 *      Value Drivers
 */
export const language = {
    personas: {
        default: {
            description: `
            We are here for you. As a community member, find the tools, knowledge base, and support you need in our 
            five core personas.
            `
        },
        data_scientist: {
            name: 'Data Scientist',
            description: `
            Analyzes data, seeks accessible infrastructure to design and builds AI/ML models. Collaborates with 
            Domain and Developer personas to smarten and optimize solutions
            `,
            icon: 'icon-role-Data-Scientist.svg'
        },
        developer: {
            name: 'Developer',
            description: `
            Optimizes algorithms, extends platform functionality and ideates with other personas to create and 
            deliver optimal deployments
            `,
            icon: 'icon-role-Developer.svg'
        },
        domain: {
            name: 'Domain',
            description: `
            Collaborates on technical issues and innovation, imparts subject expertise and intuitions. Domain 
            personas innovate, mentor and deliver solutions in Xrathus Communities
            `,
            icon: 'icon-role-Domain.svg'
        },
        product_owner: {
            name: 'Product Owner',
            description: `
            Collaborates with fellow owners, such as  portfolio, feature, scrum, component, platform owners, to 
            glean updates, share experiences and expertise and mentor on developments and more to maximize value 
            for all solutions
            `,
            icon: 'icon-role-Product-Owner.svg'
        },
        sponsor: {
            name: 'Sponsor',
            description: `
            Establishes and enhances network of contacts to implement delivery of strategic solutions and projects 
            to completion. Participates in investment and market opportunities
            `,
            icon: 'icon-role-Sponsor.svg'
        }
    },
    substrates: {
        connect: {
            name: 'Connect',
            description_short: 'Connection and collaboration substrate',
            description: `
            Convening substrate for large scale, multi-party cross-disciplinary collaboration and ideation
            `
        },
        hub: {
            name: 'Hub',
            description_short: 'Innovation and development substrate',
            description: `
            Collaborative data science environment & on-demand AI/ML tools to advance solutions
            `
        },
        marketplace: {
            name: 'Marketplace',
            description_short: 'Commercialization substrate',
            description: `
            Community-powered marketplace optimizing the on-demand provisioning of services and products
            `
        },
        training: {
            name: 'Training',
            description_short: 'Learning and knowledge Base substrate',
            description: `
            On-demand, cross-disciplinary learning with robust knowledge bases
            `
        }
    }
};

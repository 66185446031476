import React from 'react';
import TableProgressBar from './TableProgressBar';

const styles = {
    text: {textAlign: 'right'},
    numbers: {fontWeight: 500, textAlign: 'right', width: 120},
    progress: {width: 220}
};

/**
 * Table for Max Dataset Size Progress
 *
 * @param sizeString
 * @param size
 * @param maxSize
 */
export default function ProjectDatasetSizeTotal({sizeString, size = 0, maxSize = 1}) {
    return (
        <table className="x-table challenge mt-1">
            <tbody>
            <tr>
                <td style={styles.text}>
                    Total Size of all Files:<br/>
                    Maximum Total Size allowed for Dataset:
                </td>
                <td style={styles.numbers}>
                    {sizeString}<br/>
                    {maxSize}GB
                </td>
                <td style={styles.progress}>
                    <TableProgressBar amount={size} total={maxSize * 1000000000}/>
                </td>
            </tr>
            </tbody>
        </table>
    );
}
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

/**
 * Top Bar Substrate Navigation (logged in)
 */
class NavXrathus extends Component {

    render() {
        const {
            location,
        } = this.props;

        let navLinks = [
            "/x/connect/my-xrathus",
            "/x/hub/my-hub",
            "/x/training/my-training",
            "/x/marketplace/my-marketplace" 
        ];
        let path = location.pathname;

        return (
            <ul className="nav-xrathus">
                <li className={`xconnect ${(navLinks[0] === path || path.indexOf(navLinks[0]) !== -1) ? "active" : ""}`}>
                    <Link to={navLinks[0]} title="Connect" 
                        className={`xconnect`}>
                        Connect</Link>
                </li>
                <li className={`xhub ${(navLinks[1] === path || path.indexOf(navLinks[1]) !== -1) ? "active" : ""}`}>
                    <Link to={navLinks[1]} title="Hub" 
                        className={`xhub`}>
                        Hub</Link>
                </li>
                <li className={`xtraining ${(navLinks[2] === path || path.indexOf(navLinks[2]) !== -1) ? "active" : ""}`}>
                    <Link to={navLinks[2]} title="Training" 
                        className={`xtraining`}>
                        Training</Link>
                </li>
                <li className={`xmarketplace ${(navLinks[3] === path || path.indexOf(navLinks[3]) !== -1) ? "active" : ""}`}>
                    <Link to={navLinks[3]} title="Marketplace" 
                        className={`xmarketplace`}>
                        Marketplace</Link>
                </li>
            </ul>
        );
    }
}

NavXrathus.propTypes = {
    location: PropTypes.any,
};

export default withRouter(NavXrathus);

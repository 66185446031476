import {useState, useEffect} from "react";
import {setEventsList} from "../utils/projectFunctions";
import {makeGetDataRequest} from "../utils/getRequest";
import {RESOURCES as resources} from "../resourcesEvent";

const EventCarrousel = ({isAuthenticated}) => {
    const [publicList, setPublicList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const params = {
            order: 'ASC',
            page: currentPage,
            perPage: 3,
            sort: 'ASC',
            sortBy: 'start_time'
        }

        const request = makeGetDataRequest(resources, 'EventHomePagePublic', 'GET_LIST', params, isAuthenticated);
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Events Retrieval Error: ', result);
                    setPublicList(null);
                    return Promise.resolve(result);
                }
                const totalPages = Math.ceil(result.TotalRows / 3);
                setPublicList(result.AirmeetEvents);
                setTotalPages(totalPages);
                return Promise.resolve(result);
            } else {
                console.log('Events Retrieval Error: No result');
                return Promise.reject('Events Retrieval failed');
            }
        }).catch(error => {
            console.log('Events Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    }, [currentPage, isAuthenticated])

    function handleRightClick() {
        setCurrentPage((prevState) => prevState + 1);
    }

    function handleLeftClick() {
        setCurrentPage((prevState) => prevState - 1);
    }

    return (
        <>
            <div className="row no-wrap">
                {[...setEventsList('events-public', publicList, publicList, 'No Public Events found.', isAuthenticated)]}
                {publicList && publicList.length > 0 &&
                    <div className='event-carrousel-arrows'>
                        <button className='event-carrousel-arrow-left' disabled={currentPage === 1}
                                onClick={handleLeftClick}>
                            <svg width="32px" height="32px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000" transform="rotate(180)">
                                    <rect width="16" height="16" id="icon-bound" fill="none"></rect>
                                    <polygon points="11,8 6,13 6,3"></polygon>
                            </svg>
                        </button>
                        <button className='event-carrousel-arrow-right' disabled={currentPage === totalPages}
                                onClick={handleRightClick}>
                            <svg width="32px" height="32px" viewBox="0 0 16 16" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="16" height="16" id="icon-bound" fill="none"/>
                                <polygon points="11,8 6,13 6,3"/>
                            </svg>
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default EventCarrousel;
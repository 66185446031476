import React, {Component} from 'react';

class NotFound extends Component {
    render() {
        return (
            <>
                <div className="content not-found">
                    <h1 className="page-title">
                        Not Found
                    </h1>
                    <p>Sorry, this page was not found.</p>
                </div>
            </>
        )
    }
}

export default NotFound;

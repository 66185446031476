import React from 'react';
import PropTypes from 'prop-types';
import Tile from "./Tile";
import '../../../css/storyblok-custom.css';
import '../../../css/scroll-list.css';
import '../../../css/tile.css';

const SolutionTile = ({   
    title,
    image,
    subtitle,
    dataset,
    size,
    orientation,
    alignment,
    link,
    isExternal,
    imageNearEdge,
    disabled,
    ownerTitle,
    ownerLogo,
    demoRouter,
}) => {

    const tileContent = (
        <div>
            <br/>
            {subtitle ? <small><i className="solution-subtitle">{subtitle}</i><br/></small> : null}
            {dataset ? <small className="solution-dataset">Example Dataset: {dataset}</small> : null}
        </div>
    );

    const footerContent = (
        <>
            <div className="alignleft"></div>
            <div className="alignright">
                <div className="owner">
                    {
                        ownerLogo && ownerLogo.filename ?
                            <div className="logo-storyblok">
                                <img src={ownerLogo.filename}
                                    alt={ownerTitle} className=""/>
                            </div>
                            :
                            ownerTitle ?
                                ownerTitle
                                :
                                " "
                    }
                </div>
            </div>
        </>
    );

    return (
        <Tile
            title={title}
            image={image}
            size={size}
            orientation={orientation}
            alignment={alignment}
            isExternal={isExternal}
            imageNearEdge={imageNearEdge}
            link={link}
            tileContent={tileContent}
            footerContent={footerContent}
            disabled={disabled}
            demoRouter={demoRouter}
            className="solution-tile"
        />
    );
};


SolutionTile.propTypes = {
    /**
     * The title to be displayed on the tile
     */
    title: PropTypes.string,
    /**
     * The link to the displayed image
     */
    image: PropTypes.string,
    /**
     * The subtitle to be displayed on the tile
     */
    subtitle: PropTypes.string,
    /**
     * The demo dataset related to this solution
     */
    dataset: PropTypes.string,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /**
     * Tile orientation
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * Text Alignment
     */
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    /**
     * The link for the tile.
     * A link to an internal page starts with '/x/'.
     */
    link: PropTypes.string,
    /**
     * Is the link external?
     */
    isExternal: PropTypes.bool,
    /**
     * Is image pushed to the edge?
     */
    imageNearEdge: PropTypes.bool,
    /**
     * Disabled?
     */
    disabled: PropTypes.bool,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    ownerTitle: PropTypes.string,
    ownerLogo: PropTypes.any,
    demoRouter: PropTypes.bool,
};

SolutionTile.defaultProps = {
    title: "",
    image: "",
    subtitle: "",
    dataset: "",
    size: 'medium',
    orientation: 'horizontal',
    alignment: 'left',
    link: "",
    isExternal: false,
    imageNearEdge: false,
    disabled: false,
    ownerTitle: "",
    ownerLogo: null,
    demoRouter: false,
};

export default SolutionTile;
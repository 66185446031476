// Auth
export const LOG_OUT = 'LOG_OUT';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_KEYCLOAK_READY = 'SET_KEYCLOAK_READY';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_USER = 'SET_USER';

// UI
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';

// Xrathus

export const SET_CHALLENGE_LIST_PUBLIC = 'SET_CHALLENGE_LIST_PUBLIC';
export const SET_CHALLENGE_LIST_SUBSCRIBED = 'SET_CHALLENGE_LIST_SUBSCRIBED';
export const SET_CHALLENGE_LIST_SUBSCRIBED_REFRESH = 'SET_CHALLENGE_LIST_SUBSCRIBED_REFRESH';

export const SET_EVENT_CONFIG = 'SET_EVENT_CONFIG';
export const SET_EVENT_CONFIG_CURRENT = 'SET_EVENT_CONFIG_CURRENT';
export const SET_EVENT_CONFIG_REFRESH = 'SET_EVENT_CONFIG_REFRESH';
export const SET_EVENT_CONFIG_USER = 'SET_EVENT_CONFIG_USER';
export const SET_EVENT_CONFIG_USER_REFRESH = 'SET_EVENT_CONFIG_USER_REFRESH';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const SET_EVENT_LIST_INVITED = 'SET_EVENT_LIST_INVITED';
export const SET_EVENT_LIST_OWN = 'SET_EVENT_LIST_OWN';
export const SET_EVENT_LIST_PUBLIC = 'SET_EVENT_LIST_PUBLIC';
export const SET_EVENT_LIST_REFRESH = 'SET_EVENT_LIST_REFRESH';
export const SET_EVENT_LIST_SUBSCRIBED = 'SET_EVENT_LIST_SUBSCRIBED';
export const SET_EVENT_LIST_SUBSCRIBED_REFRESH = 'SET_EVENT_LIST_SUBSCRIBED_REFRESH';

export const SET_LIST_STATUS = 'SET_LIST_STATUS';

export const SET_PROJECT_CONFIG = 'SET_PROJECT_CONFIG';
export const SET_PROJECT_CONFIG_CURRENT = 'SET_PROJECT_CONFIG_CURRENT';
export const SET_PROJECT_CONFIG_REFRESH = 'SET_PROJECT_CONFIG_REFRESH';
export const SET_PROJECT_CONFIG_USER = 'SET_PROJECT_CONFIG_USER';
export const SET_PROJECT_CONFIG_USER_REFRESH = 'SET_PROJECT_CONFIG_USER_REFRESH';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_PROJECT_FILE_LIST = 'SET_PROJECT_FILE_LIST';
export const SET_PROJECT_LIST_INVITED = 'SET_PROJECT_LIST_INVITED';
export const SET_PROJECT_LIST_OWN = 'SET_PROJECT_LIST_OWN';
export const SET_PROJECT_LIST_REFRESH = 'SET_PROJECT_LIST_REFRESH';
export const SET_PROJECT_LIST_SUBSCRIBED = 'SET_PROJECT_LIST_SUBSCRIBED';

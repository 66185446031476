import React from 'react';
import TableProgressBar from './TableProgressBar';

const styles = {
    text: {textAlign: 'right'},
    numbers: {fontWeight: 500, textAlign: 'right', width: 120},
    progress: {width: 220}
};

/**
 * Table for Max Volumes Progress
 *
 * @param size
 * @param maxSize
 */
export default function ProjectVolumesTotal({size = 0, maxSize = 1}) {
    return (
        <table className="x-table challenge mt-1">
            <tbody>
            <tr>
                <td style={styles.text}>
                    Total Volumes:<br/>
                    Maximum Volumes allowed for Dataset:
                </td>
                <td style={styles.numbers}>
                    {size}<br/>
                    {maxSize}
                </td>
                <td style={styles.progress}>
                    <TableProgressBar amount={size} total={maxSize}/>
                </td>
            </tr>
            </tbody>
        </table>
    );
}
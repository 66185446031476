import React, {Component, Suspense} from 'react';

const Content = React.lazy(() => import('../data/content/Terms'));

class Terms extends Component {
    render() {
        return (
            <>
                <div className="content legal terms">
                    <Suspense
                        fallback={<h1 className="page-title legal">Xrathus Communities Platform Terms of Use</h1>}>
                        <Content/>
                    </Suspense>
                </div>
            </>
        )
    }
}

export default Terms;

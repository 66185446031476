import React, {Component} from 'react';
import '../../css/static.css';

class XHubPipelines extends Component {
    render() {

        return (
            <>
                <div className="content pipelines">
                    <h1 className="page-title">
                        Pipelines
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Once available, the Pipelines section will provide a visual editor to create,
                                    customize, and extend data science pipelines.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubPipelines;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

function TeamContainer(props) {
    const data = props.data;

    if (data.link) {
        return (
            <a
                href={data.link}
                title={data.name}
                target="_blank"
                rel="noopener noreferrer"
                className={(data.featured) ? 'team-member featured' : 'team-member'}
            >
                {props.children}
            </a>
        )
    }
    return (
        <div className={(data.featured) ? 'team-member featured' : 'team-member'}>
            {props.children}
        </div>
    )
}

/**
 * Component to display Team Member item
 *
 */
class TeamMemberLink extends Component {
    render() {
        const {
            data
        } = this.props;

        return (
            <TeamContainer data={data}>
                {data.thumb &&
                    <img src={`/images/${data.thumb}`} alt={data.name}/>
                }
                <span className="team-name">{data.name}</span>
                <span className="team-role">{data.role}</span>
            </TeamContainer>
        )
    }
}

TeamMemberLink.propTypes = {
    data: PropTypes.object
};

export default withRouter(TeamMemberLink);

import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resourcesEvent';
import {makePostDataRequest} from '../../utils/postRequest';
import NumberInput from '../fields/NumberInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';

/**
 * Form: Edit Event Configuration Defaults
 *
 * @return {*}
 * @constructor
 */
const FormEventConfigDefault = (props) => {

    const eventConfig = props.event_config;

    const {register, errors, formState, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    const [config, setConfig] = useState({
        max_events: eventConfig?.max_events ?? 1,
        max_shares: eventConfig?.max_shares ?? 5
    });
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    const [formStageState, setFormStageState] = useState('form');

    // Callback: Set Configuration values and update form values
    const updateConfigurations = useCallback((data) => {
        setConfig(data);
        setValue('max_events', data?.max_events ?? config.max_events);
        setValue('max_shares', data?.max_shares ?? config.max_shares);
    }, [config, setValue]);

    // Effect: Update default Configuration values if Redux has been updated
    useEffect(() => {
        const updateConfig = () => {
            updateConfigurations(eventConfig);
        };

        if (config !== eventConfig) {
            return updateConfig();
        }

    }, [config, eventConfig, updateConfigurations]);

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            formStageState === 'saving' ||
            (errors &&
                (
                    errors.max_events ||
                    errors.max_shares
                ))
        );
    };

    const onConfigFormChange = () => {
        if (formStageState !== 'form') {
            setFormStageState('form');
        }
        updateSuccessState({success: false, message: ''});
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // Assemble changed values
            let valuesToSubmit = [];
            if (parseInt(data.max_events, 10) !== props.event_config.max_events) {
                valuesToSubmit.push({name: 'max_events', value: data.max_events});
            }
            if (parseInt(data.max_shares, 10) !== props.event_config.max_shares) {
                valuesToSubmit.push({name: 'max_shares', value: data.max_shares});
            }

            if (valuesToSubmit.length > 0) {
                // Submit form to API
                setFormStageState('saving');
                submitForm(valuesToSubmit);
            } else {
                setFormStageState('fail');
                updateSuccessState({success: false, message: 'There are no changes to submit.'});
            }
        } else {
            setFormStageState('fail');
            updateSuccessState({success: false, message: 'There are no changes to submit.'});
        }
    };

    // Handle queued API calls and post-submission
    const submitForm = async (values) => {
        if (values.length) {
            let taskLength = values.length;
            // Start a Promise sequence with all tasks
            const starterPromise = Promise.resolve(null);
            values.reduce(
                (p, item) => p.then(() => postConfigUpdate(item)
                    .then(() => {
                        // If all Configuration updates have completed:
                        if (values.length >= (taskLength - 1)) {
                            updateSuccessState({success: true, message: ''});
                            setFormStageState('success');
                            if (typeof props.onConfigUpdated === 'function') {
                                props.onConfigUpdated();
                            }
                        }
                    })
                ), starterPromise)
                .catch((error) => {
                    console.log('Config error: ', error);
                    updateSuccessState({
                        success: false,
                        message: 'Sorry, there was an error updating a Configuration: ' + error
                    });
                    setFormStageState('fail');
                }
                );
        }
    };

    const postConfigUpdate = (item) => {
        return new Promise(function (resolve, reject) {
            if (item) {
                const onFailure = function (result) {
                    return reject('Configuration Update failed: ', result);
                };
                const onSuccess = function (result) {
                    return resolve(result);
                };
                const params = {
                    config: item.name,
                    value: item.value
                };
                const request = makePostDataRequest(resources, 'EventConfiguration', 'PUT', params);
                request.then(result => {
                    if (result && result.name) {
                        return onSuccess(result.data);
                    } else {
                        return resolve();
                    }
                }).catch(error => {
                    console.log('Configuration Update Error: ', error);
                    onFailure(error.response);
                });
            } else {
                return resolve();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                <div className="item-content full">
                    <div className="tab-content active">
                        <p className="instructions">
                            Set default Event Configuration values below. Values may be overridden for individual
                            users.
                        </p>

                        {/* Form Inputs */}

                        <div className="row">
                            <div className="form-group col col-xs-12 col-md-6">
                                <NumberInput
                                    id="config-max-events"
                                    name="max_events"
                                    label="Max Number of Events"
                                    instructions={`The maximum number of Personal Events a user may create.`}
                                    min={0}
                                    max={100}
                                    step={1}
                                    aria-describedby="error-max-events-required"
                                    aria-invalid={errors.max_events ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    changeFunc={() => onConfigFormChange()}
                                    className={`form-input ${errors.max_events ? 'input-error' : ''}`}
                                >
                                    {errors.max_events && errors.max_events.message}
                                </NumberInput>
                            </div>
                            <div className="form-group col col-xs-12 col-md-6">
                                <NumberInput
                                    id="config-max-event-shares"
                                    name="max_shares"
                                    label="Max Number of Shares"
                                    instructions={`The maximum number of times an Event may be shared with other users.`}
                                    min={0}
                                    max={1000}
                                    step={1}
                                    aria-describedby="error-max_shares-required"
                                    aria-invalid={errors.max_shares ? 'true' : 'false'}
                                    ref={register({
                                        required: (<FormError id="required" text="A value is required"/>)
                                    })}
                                    changeFunc={() => onConfigFormChange()}
                                    className={`form-input ${errors.max_shares ? 'input-error' : ''}`}
                                >
                                    {errors.max_shares && errors.max_shares.message}
                                </NumberInput>
                            </div>
                        </div>
                    </div>

                    {/* Messaging and Actions */}

                    <div className="form-btns right">
                        {formStageState === 'saving' &&
                        <>
                            <LoadingIndicator color="dark" centered={false} active={true}/>
                            <p className="message">Saving Configuration...</p>
                        </>
                        }
                        {formStageState === 'success' &&
                        <p className="message">Global Event Configuration saved</p>
                        }
                        {formStageState === 'fail' &&
                        <>
                            {successState.message !== '' &&
                            <p className="message">
                                {successState.message}
                            </p>
                            }
                            {successState.message === '' &&
                            <p className="message">
                                Sorry, there was an issue saving the Global Event Configuration.
                            </p>
                            }
                        </>
                        }

                        <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                            Save Configuration
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        event_config: state.event_config
    };
};

FormEventConfigDefault.propTypes = {
    event_config: PropTypes.any,
    onConfigUpdated: PropTypes.any,
};

export default connect(
    mapStateToProps
)(FormEventConfigDefault);

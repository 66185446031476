import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {addItemsToListByKey, copyArray} from '../utils/dataFunctions';
import APICluster from '../utils/APICluster';
import ClusterTable from '../partials/ClusterTable';

/**
 * A component to retrieve and display multiple Discourse Topics by ID
 */
class DashboardClustersProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discourseClusters: [],
            discourseIdList: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(prevProps.challengeList) !== JSON.stringify(this.props.challengeList) ||
            JSON.stringify(prevProps.projectList) !== JSON.stringify(this.props.projectList)
        ) {
            this.assembleDiscourseIds();
        }
    }

    // Assemble array of Discourse IDs for Cluster population
    assembleDiscourseIds = () => {
        let discourseIdList = copyArray(this.state.discourseIdList);
        const challenge1 = this.props.challengeList[0]?.discourse_id ?? null;
        const challenge2 = this.props.challengeList[1]?.discourse_id ?? null;
        const project1 = this.props.projectList[0]?.discourse_id ?? null;
        const project2 = this.props.projectList[0]?.discourse_id ?? null;
        if (challenge1 && discourseIdList.findIndex(r => r === challenge1) === -1) {
            discourseIdList.push(challenge1);
        }
        if (challenge2 && discourseIdList.findIndex(r => r === challenge2) === -1) {
            discourseIdList.push(challenge2);
        }
        if (project1 && discourseIdList.findIndex(r => r === project1) === -1) {
            discourseIdList.push(project1);
        }
        if (project2 && discourseIdList.findIndex(r => r === project2) === -1) {
            discourseIdList.push(project2);
        }
        this.setState({
            discourseIdList: discourseIdList
        });
    };

    // Set Cluster table rows
    setDiscourseAPIs = () => {
        return (
            this.state.discourseIdList.map((id, index) => (
                <APICluster
                    key={`api-cluster-${id}-${index}`}
                    discourseId={id}
                    onResult={(e) => this.handleClusterAPIResults(e)}
                />
            ))
        );
    };

    // Assemble Cluster list from Discourse API results
    handleClusterAPIResults = (list) => {
        let clusters = copyArray(this.state.discourseClusters);
        clusters = addItemsToListByKey(clusters, list, 'id');
        this.setState({
            discourseClusters: clusters
        });
    };

    render() {
        const {
            discourseClusters
        } = this.state;

        return (
            <>
                {[...this.setDiscourseAPIs()]}
                {discourseClusters.length > 0 &&
                <div className="row mt-2">
                    <div className="col col-xs-12">
                        <div className="panel no-padding">
                            <h3 className="header">Latest Project Discussions</h3>
                            <ClusterTable clusterList={discourseClusters} height="auto"/>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
}

DashboardClustersProjects.propTypes = {
    challengeList: PropTypes.array,
    projectList: PropTypes.array
};

export default DashboardClustersProjects;

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useStoryblokFetch, filterStoryblokContentThroughGroups, sortStoryblokListContent} from '../../../utils/StoryblokFunctions';
import LoadingIndicator from '../../LoadingIndicator';

import '../../../css/storyblok-custom.css';


const StoryblokContentFetcher = ({
    params,
    render,
    nullText,
    nullRender,
    ordered,
    user
}) => {

    //for localstorage purposes, clean up params
    let localStorageSaveParams = params;
    if (localStorageSaveParams['cv']) {delete localStorageSaveParams['cv'];} if (localStorageSaveParams['token']) {delete localStorageSaveParams['token'];}

    //check if content has been fetched recently
    const prevTime = localStorage.getItem(`fetchedContent//${JSON.stringify(localStorageSaveParams)}/fetchTime`);

    const savedContent = JSON.parse(localStorage.getItem(`fetchedContent//${JSON.stringify(localStorageSaveParams)}`));
    const [data, isLoading] = useStoryblokFetch('', params, 500);

    if (!params || params === {}) {
        if (nullRender) {
            return nullRender(null);
        }
        else {
            return null;
        }
    } 

    if (isLoading) {
        if (data && data.stories) {
            return data.stories.map(dataItem => render(dataItem));
        }
        if (savedContent && savedContent.stories && savedContent.stories.length > 0) {
            return savedContent.stories.map(dataItem => render(dataItem));
        }
        if (nullRender) {
            return nullRender(null);
        }
        if (prevTime && (new Date().getTime() - prevTime)>2000.0) {
            //this is in case fetcher gets stuck in a loading animation
            return (
                <div className="center">
                &nbsp;
                    <h4>{nullText}</h4>
                &nbsp;
                </div>
            );
        }
        return <LoadingIndicator color="dark" centered={true} size={24} active={isLoading}/>;
    }

    //return data
    if (data && data.stories && data.stories.length > 0) {
        //save fetch time
        if (localStorageSaveParams['cv']) {delete localStorageSaveParams['cv'];} if (localStorageSaveParams['token']) {delete localStorageSaveParams['token'];}
        localStorage.setItem(`fetchedContent/${JSON.stringify(localStorageSaveParams)}/fetchTime`, `${new Date().getTime()}`);

        // filter content based on if user has access to it or not. 
        // next, clear filtered content of null values
        let filteredContent = data.stories.map((item) => ((user) ? 
            filterStoryblokContentThroughGroups(item, user.groups) : item)).filter(element => {
            return element !== null;
        });
        // sort content by appropriate param
        filteredContent = sortStoryblokListContent(filteredContent, ordered);

        let filteredData = data;
        filteredData.stories = filteredContent;

        if (!savedContent || (savedContent !== data.stories)) {
            //save data to localStorage
            localStorage.setItem(`fetchedContent//${JSON.stringify(localStorageSaveParams)}`, JSON.stringify(data));
        }
        if (data && data.stories && data.stories.length > 0) {
            return data.stories.map(dataItem => render(dataItem));
        }
    }
    if (savedContent && savedContent.stories && savedContent.stories.length > 0) {
        return savedContent.stories.map(dataItem => render(dataItem));
    }

    return (
        <div className="center">
        &nbsp;
            <h4>{nullText}</h4>
        &nbsp;
        </div>
    );
    
};


StoryblokContentFetcher.propTypes = {
    /**
     * Params sent
     */
    params: PropTypes.any,
    render: PropTypes.any,
    nullText: PropTypes.string,
    nullRender: PropTypes.any,
    ordered: PropTypes.oneOf([
        'alphabet', 
        'priority',
        'time',
        'time-from-present',
    ]),
    user: PropTypes.any,
};

StoryblokContentFetcher.defaultProps = {
    params: {},
    render: null,
    nullText: "No Content",
    nullRender: null,
    ordered: 'alphabet',
    user: null,
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(StoryblokContentFetcher)
);

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {RESOURCES as resources} from '../../resources';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import SelectInput from '../fields/SelectInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import IconUser from '../images/IconUser';
import TextAreaInput from '../fields/TextAreaInput';
import {US_STATES} from '../../data/usStates';
import '../../css/organization.css';
import {BOOLEAN_OPTIONS} from '../../data/objects';
import {makeGetDataRequest} from '../../utils/getRequest';

/**
 * Form: Edit Organization
 *
 * @return {*}
 * @constructor
 */
export default function FormOrganizationEdit({pathArr}) {

    // Set up form and state
    const {register, errors, formState, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [formStageState, setFormStageState] = useState('form');

    useEffect(() => {
        const orgId = pathArr[3];
        const params = {
            id: orgId,
            getBy: 'id'
        };
        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'Organization', 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    setValue('id', result?.id ?? '');
                    setValue('active', !!(result.active));
                    setValue('address', result?.address ?? '');
                    setValue('city', result?.city ?? '');
                    setValue('country', result?.country ?? '');
                    setValue('description', result?.description ?? '');
                    setValue('name', result?.name ?? '');
                    setValue('state', result?.state ?? '');
                    setValue('website', result?.website ?? '');
                    setValue('zip', result?.zip ?? '');
                    return Promise.resolve(result);
                } else {
                    console.log('Challenge Error: No result');
                    return Promise.reject('Challenge failed');
                }
            }).catch(error => {
                console.log('Challenge Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            getValues('name') === '' ||
            getValues('description') === '' ||
            formStageState === 'saving' ||
            (errors &&
                (
                    errors.name ||
                    errors.description
                ))
        );
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // console.log('SUBMIT data args before: ', data);

            data.id = parseInt(getValues('id'), 10);
            data.active = (getValues('active') === 'true');
            // console.log('SUBMIT data args: ', data);
            // Submit form to API
            setFormStageState('saving');
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'Organization', 'UPDATE', data);
        request.then(result => {
            if (result && result.id) {
                updateSuccessState({success: true, message: ''});
                setFormStageState('success');
                return Promise.resolve(result);
            } else {
                updateSuccessState({success: false, message: 'Sorry, your Organization could not be updated.'});
                setFormStageState('fail');
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Add Organization Error: ', error);
            updateSuccessState({success: false, message: 'Sorry, there was an error with your Organization: ' + error});
            setFormStageState('fail');
            return Promise.reject('Server Error');
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                <div className="item-content full">

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(formStageState === 'form') ? 'active' : ''}`}>
                        <p className="instructions">
                            Edit basic Organization information.
                        </p>
                        <div className="form-group">
                            {/* Title */}
                            <BaseInput
                                type="text"
                                id="organization-name"
                                name="name"
                                label="Name"
                                aria-describedby="error-name-required error-name-maxLength"
                                aria-invalid={errors.name ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Name is required"/>),
                                    maxLength: {
                                        value: 180,
                                        message: (<FormError id="maxLength"
                                                             text="Name cannot be longer than 180 characters"/>)
                                    }
                                })}
                                disabled
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Description */}
                            <TextAreaInput
                                id="organization-desc"
                                rows={5} cols={50}
                                name="description"
                                label="Description"
                                ref={register({
                                    required: (<FormError id="required" text="Description is required"/>),
                                    maxLength: {
                                        value: 300,
                                        message: (<FormError id="maxLength"
                                                             text="Description cannot be longer than 300 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.description ? 'input-error' : ''}`}
                            >
                                {errors.description && errors.description.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Address */}
                            <BaseInput
                                type="text"
                                id="organization-address"
                                name="address"
                                label="Address"
                                ref={register()}
                                className="form-input"
                            >
                            </BaseInput>
                        </div>
                        <div className="xrathus-org-form-row">
                            <div className="form-group xrathus-org-33">
                                {/* State */}
                                <SelectInput
                                    id="organization-state"
                                    name="state"
                                    label="State"
                                    options={US_STATES}
                                    ref={register()}
                                    className="form-input"
                                >
                                </SelectInput>
                            </div>
                            <div className="form-group xrathus-org-33">
                                {/* city */}
                                <BaseInput
                                    type="text"
                                    id="organization-city"
                                    name="city"
                                    label="City"
                                    ref={register()}
                                    className="form-input"
                                >
                                </BaseInput>
                            </div>
                            <div className="form-group xrathus-org-33">
                                {/* Zip */}
                                <BaseInput
                                    type="text"
                                    id="organization-zip"
                                    name="zip"
                                    label="Zip"
                                    ref={register()}
                                    className="form-input"
                                >
                                </BaseInput>
                            </div>
                        </div>
                        <div className="xrathus-org-form-row">
                            <div className="form-group xrathus-org-50">
                                {/* Country */}
                                <BaseInput
                                    type="text"
                                    id="organization-country"
                                    name="country"
                                    label="Country"
                                    ref={register()}
                                    className="form-input"
                                >
                                </BaseInput>
                            </div>
                            <div className="form-group xrathus-org-50">
                                {/* Active */}
                                <SelectInput
                                    id="organization-active"
                                    name="active"
                                    label="Active"
                                    options={BOOLEAN_OPTIONS}
                                    ref={register()}
                                    className="form-input"
                                >
                                </SelectInput>
                            </div>
                        </div>
                        <div className="form-group">
                            {/* Website */}
                            <BaseInput
                                type="text"
                                id="organization-website"
                                name="website"
                                label="Website"
                                ref={register()}
                                className="form-input"
                            >
                            </BaseInput>
                        </div>
                        <input type="hidden" name="id" ref={register}/>

                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(formStageState === 'saving') ? 'active' : ''}`}>
                        <h4>Updating Organization...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(formStageState === 'success') ? 'active' : ''}`}>
                        <h4>Organization Updated</h4>
                        <p>
                            Your Organization has been updated.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(formStageState === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                        <p className="message">
                            {successState.message}
                        </p>
                        }
                        {successState.message === '' &&
                        <p className="message">
                            Sorry, there was an issue updating this Organization.
                        </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        {formStageState !== 'success' &&
                        <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                            Update Organization
                        </button>
                        }
                        {formStageState === 'success' &&
                        <>
                            <Link to="/x/admin" title="Admin" className="btn btn-primary btn-icon">
                                <IconUser/>
                                Admin
                            </Link>
                        </>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
}

import React, {Component} from 'react';
import StoryblokPage from '../../partials/storyblok/StoryblokPageTemplate';
import '../../css/static.css';

class XMarketplaceSolutionsXrathus extends Component {
    render() {
        return (
            <StoryblokPage url={'solutions/xrathus'}/>
        );
    }
}

export default XMarketplaceSolutionsXrathus;

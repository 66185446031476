/**
 * Resources for API
 *      Data
 *      File
 *      Volume
 */
export const RESOURCES = {
    'Data': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data/create'
            }
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data/' + params.id
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data/update'
            }
        },
    },
    'DataAssociation': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/data/' + params.data_id + '/association'
            }
        }
    },
    'DataSize': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/bucket/size/' + params.data_id
            }
        }
    },
    'File': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/files/getAll/' + params.data_id + '/'
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/file/' + params.data_id + '/' + params.filepath
            }
        },
        POST_UPLOAD: function (params) {
            let path = (params.path) ? '/' + params.path : '';
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/file/' + params.data_id + path
            }
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/file/' + params.data_id + params.filepath
            }
        }
    },
    'Volume': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/list/' + params.data_id
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/info/' + params.data_id + '/' + params.volume_name
            }
        },
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/create/' + params.data_id
            }
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/delete/' + params.data_id + '/' + params.volume_name
            }
        }
    },
    'VolumeFile': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/fileTree/' + params.data_id + '/' + params.volume_name
            }
        }
    },
    'VolumeStatus': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/pv/status/' + params.data_id + '/' + params.volume_name
            }
        },
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/volume/update/' + params.data_id + '/' + params.volume_name + '/' + params.status
            }
        }
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Volume"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {logOutUser, setSidebarVisibility} from '../store/actions';
import {checkAuthentication} from '../utils/authFunctions';
//temporary mobile fix without activating ismobile sitewide
import {isMobile, isMobileSmall} from '../utils/tempFunctions';
import IconMenu from '../partials/images/IconMenu';
import NavXrathus from '../partials/NavXrathus';
import NavXrathusPublic from '../partials/NavXrathusPublic';
import XrathusLogoHorizontal from '../partials/images/XrathusLogoHorizontal';
import UserIcon from '../css/images/icon-User.svg';
import {
    Menu,
    MenuItem,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import '../css/top-bar.css';

class AppHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            prevMobileSmall: isMobileSmall()
        };

        this.ref = React.createRef();
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleIntersectionScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleIntersectionScroll);
    }

    // Hide or show the sidebar if window size changes to mobile
    handleResize = () => {
        const {prevMobileSmall} = this.state;

        const currentMobileSmall = isMobileSmall();

        if ((prevMobileSmall && !currentMobileSmall) || this.props.sidebarOpen) {
            // @TODO: fix the disconnect between mobilesmall and mobile. Hiding sidebar may be better with mobile
            this.openSidebar();
        }
        if (!prevMobileSmall && currentMobileSmall) {
            this.closeSidebar();
        }

        this.setState({prevMobileSmall: currentMobileSmall});
    }

    handleIntersectionScroll = () => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const id = entry.target.getAttribute('id');

                if (document.querySelector(`.toc-import li button[id="${id}"]`)) {
                    if (entry.intersectionRatio > 0 && (entry.intersectionRect.height > window.innerHeight / 2.1 || entry.intersectionRect.height >= entry.boundingClientRect.height)) {
                        document.querySelector(`.toc-import li button[id="${id}"]`).parentElement.classList.add('active');
                    } else {
                        document.querySelector(`.toc-import li button[id="${id}"]`).parentElement.classList.remove('active');
                    }
                }

            });
        });

        // Track all sections that have an `id` applied
        document.querySelectorAll('div[id]').forEach((section) => {
            observer.observe(section);
        });
    }

    handleEvent = function (e) {
        e.preventDefault();
    };

    showHelpWidget = () => {
        const widget = window.FreshworksWidget;
        if (widget) {
            widget('show');
        }
    };

    hideHelpWidget = () => {
        const widget = window.FreshworksWidget;
        if (widget) {
            widget('hide');
        }
    };

    handleLoginClick = () => {
        this.props.keycloak.login({
            action: 'login',
            redirectUri: window.location.origin + '/x/connect/my-xrathus',
        });
    };

    handleLogout = () => {
        localStorage.removeItem('destination');
        this.props.logOutUser();
        this.props.keycloak.logout();
    };

    openSidebar = () => {
        this.props.setSidebarVisibility(true);
    }

    closeSidebar = () => {
        this.props.setSidebarVisibility(false);
    }

    handleMenuToggle = () => {
        this.props.setSidebarVisibility(!this.props.sidebarOpen);
    };

    render() {
        const {
            keycloak,
            sidebarOpen,
            tokens
        } = this.props;

        const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        const siteIsMobile = (isMobile() === true);
        const siteIsMobileSmall = (isMobileSmall() === true);
        const sidebarClass = (sidebarOpen) ? ' sidebar-open' : '';
        const topbarHiddenClass = (this.state.visible || !siteIsMobileSmall) ? '' : ' top-bar--hidden';
        if (this.state.visible || !siteIsMobileSmall) {
            this.showHelpWidget();
        } else {
            this.hideHelpWidget();
        }
        const logoLink = (isAuthenticated) ? '/x/connect/my-xrathus' : '/';

        if (this.state.visible && siteIsMobile) {
            if (sidebarOpen) {
                this.hideHelpWidget();
            } else {
                this.showHelpWidget();
            }
        }

        return (
            <>
                <div id="top-bar" className={`top-bar${topbarHiddenClass}${sidebarClass}`}>

                    <div className="menu-toggle" onClick={() => this.handleMenuToggle()}>
                        <IconMenu/>
                    </div>

                    <div className="logo-container">
                        <Link to={logoLink} title="Xrathus">
                            <XrathusLogoHorizontal/>
                        </Link>
                    </div>

                    {isAuthenticated &&
                        <NavXrathus/>
                    }

                    {!isAuthenticated &&
                        <NavXrathusPublic/>
                    }

                    <ul className="nav-top" ref={this.ref}>
                        {!isAuthenticated &&
                            <li>
                                <button
                                    type="button"
                                    className="btn btn-primary table uppercase"
                                    onClick={this.handleLoginClick}
                                >
                                    User Sign In
                                </button>
                            </li>
                        }
                        {(isAuthenticated && !siteIsMobileSmall) &&
                            <li className="visible-signout">
                                <button
                                    type="button"
                                    className="btn btn-primary table uppercase"
                                    onClick={this.handleLogout}
                                >
                                    Sign Out
                                </button>
                                <Link to="/x/profile" title="Profile" className="icon user">
                                    <img src={UserIcon} alt="User"/>
                                    My Profile
                                </Link>
                            </li>
                        }
                        {(isAuthenticated && siteIsMobileSmall) &&
                            <li className="hidden-signout">
                                <div className="icon user" onClick={this.closeSidebar}>
                                    <Menu menuButton={<img src={UserIcon} alt="User"/>}
                                          transition
                                          direction={'bottom'}
                                          position={'anchor'}
                                          align={'end'}
                                          boundingBoxRef={this.ref}
                                          className="nav-top-menu">
                                        <MenuItem>
                                            <Link to="/x/profile">
                                                My Profile
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-arrow table uppercase"
                                                onClick={this.handleLogout}
                                            >
                                                Sign Out
                                            </button>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    let tokens = state.tokens;
    if (tokens && sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            sidebarOpen: sidebarOpen,
            tokens: {
                token: tokens.token
            }
        };
    }
    return {};
};

const mapDispatchToProps = {
    logOutUser: logOutUser,
    setSidebarVisibility: setSidebarVisibility
};

//@TODO: cleanup proptypes to have the right types on each variable
AppHeader.propTypes = {
    /**
     * Is sidebar open?
     */
    sidebarOpen: PropTypes.bool,
    /**
     * Keycloak
     */
    keycloak: PropTypes.any,
    /**
     * Tokens
     */
    tokens: PropTypes.any,
    /**
     * Log out User function
     */
    logOutUser: PropTypes.func,
    /**
     * Set sidebar visibility function
     */
    setSidebarVisibility: PropTypes.func,
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AppHeader)
    )
);

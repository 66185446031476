import store from '../store/store';

/**
 * Retrieve token from Redux store
 *
 * @return {null|*}
 */
export const getStoreToken = () => {
    let state = store.getState();
    let tokens = state.tokens;
    if (tokens && tokens.token) {
        return tokens.token;
    }
    return null;
};
store.subscribe(getStoreToken);

/**
 * Create POST headers
 *
 * @return Object
 */
export const getAuthHeaders = () => {
    return {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
    };
};

/**
 * Create POST headers using access or refresh token
 *
 * @param useBearer
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getPostHeaders = (useBearer) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (useBearer) {
        // Set a Bearer token from the Access Token
        let storeToken = getStoreToken();
        headers.Authorization = 'Bearer ' + storeToken;
    }
    return headers;
};

/**
 * Create POST headers using access or refresh token
 *
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getUploadPostHeaders = () => {
    let storeToken = getStoreToken();
    return {
        'Accept': '*/*',
        'Authorization': 'Bearer ' + storeToken,
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
    };
};

/**
 * Create GET headers
 *
 * @return Object
 */
export const getGetHeaders = (isLoggedIn) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    };
    // Set a Bearer token from the Access Token
    if (isLoggedIn) {
        let storeToken = getStoreToken();
        headers.Authorization = 'Bearer ' + storeToken;
    }
    return headers;
};

/**
 * Create DELETE headers using access or refresh token
 *
 * @param useBearer
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getDeleteHeaders = (useBearer) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (useBearer) {
        // Set a Bearer token from the Access Token
        let storeToken = getStoreToken();
        headers.Authorization = 'Bearer ' + storeToken;
    }
    return headers;
};

import React from 'react';
import PropTypes from 'prop-types';
import {useYoutubePlaylistFetch} from '../../../utils/youtubeFunctions';
import LoadingIndicator from '../../LoadingIndicator';

import '../../../css/storyblok-custom.css';


const YoutubePlaylistFetcher = ({
    playlistId,
    render
}) => {
    const [data, isLoading] = useYoutubePlaylistFetch(playlistId);

    if (!playlistId || playlistId === "") return null;
    
    if (isLoading) {
        return <LoadingIndicator/>;
    }

    if (data) {
        return (
            data.map(dataItem => render(dataItem))
        );
    }
    return <LoadingIndicator/>;
};


YoutubePlaylistFetcher.propTypes = {
    /**
     * Playlist id for the youtube playlist
     */
    playlistId: PropTypes.string,
    render: PropTypes.any,
};

YoutubePlaylistFetcher.defaultProps = {
    playlistId: "",
    render: null,
};

export default YoutubePlaylistFetcher;

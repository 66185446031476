import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resources} from '../../resources';
import {makeGetDataRequest} from '../../utils/getRequest';

/**
 * Button with GitLab Project retrieval
 *
 * @param type
 * @param id
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const EditFilesButton = (
    {
        type,
        id,
        label = 'Edit Files'
    }) => {

    const [link, setLink] = useState(null);

    useEffect(() => {
        const params = {
            id: id
        };
        const fetchData = () => {
            const resource = (type === 'challenge') ? 'GitLabProjectChallenge' : 'GitLabProjectProject';
            const request = makeGetDataRequest(resources, resource, 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    if (result.error) {
                        return Promise.resolve(result);
                    }
                    if (result.web_url) {
                        setLink(result.web_url);
                    }
                    return Promise.resolve(result);
                } else {
                    console.log('GitLab URL Error: No result');
                    return Promise.reject('Projects failed');
                }
            }).catch(error => {
                console.log('GitLab URL Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        if (!link) {
            return fetchData();
        }

    }, [id, link, type]);

    return (
        <>
            <a
                className={`btn btn-primary${(!link || !id) ? ' disabled' : ''}`}
                href={link}
                title={label}
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
            </a>
        </>
    );
}

EditFilesButton.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
};

export default EditFilesButton;
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {sortArrayByKey} from '../utils/dataFunctions';
import CareerLink from './CareerLink';
import {XRATHUS_CAREERS} from '../data/careers';

class CareerLinks extends Component {
    constructor(props) {
        super(props);

        const items = this.createCareerItems();
        this.state = {
            items: items
        };
    }

    createCareerItems = () => {
        let items = [];
        Object.keys(XRATHUS_CAREERS).forEach(function (key) {
            const item = XRATHUS_CAREERS[key];
            if (item.title) {
                const teamMember = {
                    order: item.order,
                    slug: key,
                    label: item.label,
                    link_email: item.link_email,
                    link: item.link,
                    title: item.title
                };
                items.push(teamMember);
            }
        });
        sortArrayByKey(items, 'order', 'ASC')
        return items;
    };

    renderCareerItems = () => {
        return (
            this.state.items.map((item, index) => (
                <CareerLink
                    key={`career-item-${item.slug}-${index}`}
                    data={item}
                />
            ))
        )
    };

    render() {
        return (
            <div className="career-items row">
                {[...this.renderCareerItems()]}
            </div>
        )
    }
}

export default withRouter(CareerLinks);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import FormChallengeEdit from '../../partials/forms/FormChallengeEdit';
import '../../css/challenge.css';

/**
 * Page: Edit Challenge & Datasets forms
 */
class XConnectChallengeEdit extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        const challengeId = Number(pathArr.reverse()[1]);
        const challengePath = (this.props.location.pathname.indexOf('x/hub') !== -1) ? 'hub' : 'connect';
        this.state = {
            challenge: null,
            dataId: '',
            formStage: 'challenge',
            challengeId: challengeId,
            challengePath: challengePath,
            pathArr: pathArr
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        if (e === 'challenge' && status === 'complete') {
            // Upon completed Challenge, populate the Dataset's data ID
            this.setState({
                dataId: data.data_id
            }, () => {
                // Display the Dataset/Files form
                this.updateFormStage('dataset');
            })
        }
    };

    // Set form toggling
    updateFormStage = (stage) => {
        this.setState({
            formStage: stage
        });
    };

    render() {
        const {
            challengeId,
            challengePath,
            formStage,
            pathArr
        } = this.state;

        return (
            <>
                <div className="content xhub challenge">
                    <h1 className="page-title">
                        Edit Challenge
                    </h1>

                    <div className="panel no-padding">
                        <h3 className="header">Challenge</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormChallengeEdit
                                        challengeId={challengeId}
                                        challengePath={challengePath}
                                        formStage={formStage}
                                        onFormEvent={this.handleFormEvent}
                                        pathArr={pathArr}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(XConnectChallengeEdit);

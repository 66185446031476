import React from 'react';
import PropTypes from 'prop-types';
import { getUserLocale } from 'get-user-locale';
import '../../css/calendar.css';

const className = 'react-calendar__navigation';

export default function CalendarWeeklyNavigation({
    activeStartDate,
    locale,
    maxDate,
    minDate,
    navigationAriaLabel = '',
    navigationLabel,
    next2AriaLabel = '',
    next2Label = '»',
    nextAriaLabel = '',
    nextLabel = '›',
    prev2AriaLabel = '',
    prev2Label = '«',
    prevAriaLabel = '',
    prevLabel = '‹',
    setActiveStartDate,
    showDoubleView,
}) {
    const shouldShowPrevNext2Buttons = true;

    const previousActiveStartDate = getBeginPreviousWeek(activeStartDate);
    const previousActiveStartDate2 = (
        shouldShowPrevNext2Buttons
        && getBeginPreviousWeek2(activeStartDate)
    );
    const nextActiveStartDate = getBeginNextWeek(activeStartDate);
    const nextActiveStartDate2 = shouldShowPrevNext2Buttons && getBeginNextWeek2(activeStartDate);

    const prevButtonDisabled = (() => {
        const previousActiveEndDate = getEndWeekPrevious(activeStartDate);
        return minDate && minDate >= previousActiveEndDate;
    })();

    const prev2ButtonDisabled = shouldShowPrevNext2Buttons && (() => {
        const previousActiveEndDate = getEndWeekPrevious2(activeStartDate);
        return minDate && minDate >= previousActiveEndDate;
    })();

    const nextButtonDisabled = maxDate && maxDate <= nextActiveStartDate;

    const next2ButtonDisabled = (
        shouldShowPrevNext2Buttons
        && maxDate
        && maxDate <= nextActiveStartDate2
    );

    function cloneDate(date) {
        return new Date(date.getTime());
    }

    function getBeginNextWeek(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() + 7);
        return tempDate;
    }

    function getBeginNextWeek2(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() + 28);
        if (tempDate.getMonth() === activeStartDate.getMonth())
            tempDate.setDate(activeStartDate.getDate() + 35);
        return tempDate;
    }

    function getBeginPreviousWeek(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() - 7);
        return tempDate;
    }

    function getBeginPreviousWeek2(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() - 28);
        if (tempDate.getMonth() === activeStartDate.getMonth())
            tempDate.setDate(activeStartDate.getDate() - 35);
        return tempDate;
    }

    function getEndWeekPrevious(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() - 1);
        return tempDate;
    }

    function getEndWeekPrevious2(activeStartDate) {
        let tempDate = cloneDate(activeStartDate);
        tempDate.setDate(activeStartDate.getDate() - 29);
        return tempDate;
    }

    function formatWeekLabel(date) {

        let displayString = "";
        let endDate = cloneDate(activeStartDate);
        endDate.setDate(activeStartDate.getDate() + 6);

        displayString+="";
        displayString+=date.toLocaleString('default', { month: 'long'});
        displayString+=" ";
        displayString+=date.getDate();
        displayString+=" - ";
        displayString+=endDate.toLocaleString('default', { month: 'long'});
        displayString+=" ";
        displayString+=endDate.getDate();

        return displayString;
    }

    function onClickPrevious() {
        setActiveStartDate(previousActiveStartDate);
    }

    function onClickPrevious2() {
        setActiveStartDate(previousActiveStartDate2);
    }

    function onClickNext() {
        setActiveStartDate(nextActiveStartDate);
    }

    function onClickNext2() {
        setActiveStartDate(nextActiveStartDate2);
    }

    function renderLabel(date) {
        const label = (() => {
            return formatWeekLabel(date);
        })();

        return (
            navigationLabel
                ? navigationLabel({
                    date,
                    label,
                    locale: locale || getUserLocale(),
                })
                : label
        );
    }

    function renderButton() {
        const labelClassName = `${className}__label`;
        return (
            <button
                aria-label={navigationAriaLabel}
                className={labelClassName}
                disabled={true}
                style={{ flexGrow: 1 }}
                type="button"
            >
                <span className={`${labelClassName}__labelText ${labelClassName}__labelText--from`}>
                    {renderLabel(activeStartDate)}
                </span>
                {showDoubleView && (
                    <>
                        <span className={`${labelClassName}__divider`}>
                            {' '}
                –
                            {' '}
                        </span>
                        <span className={`${labelClassName}__labelText ${labelClassName}__labelText--to`}>
                            {renderLabel(nextActiveStartDate)}
                        </span>
                    </>
                )}
            </button>
        );
    }

    return (
        <div className={className}>
            {prev2Label !== null && shouldShowPrevNext2Buttons && (
                <button
                    aria-label={prev2AriaLabel}
                    className={`${className}__arrow ${className}__prev2-button`}
                    disabled={prev2ButtonDisabled}
                    onClick={onClickPrevious2}
                    type="button"
                >
                    {prev2Label}
                </button>
            )}
            {prevLabel !== null && (
                <button
                    aria-label={prevAriaLabel}
                    className={`${className}__arrow ${className}__prev-button`}
                    disabled={prevButtonDisabled}
                    onClick={onClickPrevious}
                    type="button"
                >
                    {prevLabel}
                </button>
            )}
            {renderButton()}
            {nextLabel !== null && (
                <button
                    aria-label={nextAriaLabel}
                    className={`${className}__arrow ${className}__next-button`}
                    disabled={nextButtonDisabled}
                    onClick={onClickNext}
                    type="button"
                >
                    {nextLabel}
                </button>
            )}
            {next2Label !== null && shouldShowPrevNext2Buttons && (
                <button
                    aria-label={next2AriaLabel}
                    className={`${className}__arrow ${className}__next2-button`}
                    disabled={next2ButtonDisabled}
                    onClick={onClickNext2}
                    type="button"
                >
                    {next2Label}
                </button>
            )}
        </div>
    );
}

CalendarWeeklyNavigation.propTypes = {
    activeStartDate: PropTypes.instanceOf(Date).isRequired,
    formatMonthYear: PropTypes.func,
    formatYear: PropTypes.func,
    locale: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    navigationAriaLabel: PropTypes.string,
    navigationLabel: PropTypes.func,
    next2AriaLabel: PropTypes.string,
    next2Label: PropTypes.node,
    nextAriaLabel: PropTypes.string,
    nextLabel: PropTypes.node,
    prev2AriaLabel: PropTypes.string,
    prev2Label: PropTypes.node,
    prevAriaLabel: PropTypes.string,
    prevLabel: PropTypes.node,
    setActiveStartDate: PropTypes.func.isRequired,
    showDoubleView: PropTypes.bool,
};

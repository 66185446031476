import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SectionImage from '../css/images/Substrates-Section-Marketplace.jpg';

class SubstrateSectionMarketplace extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Marketplace Substrate'
            });
        }
    };

    render() {
        return (
            <>
                <div id="marketplace" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-30 row substrate-section bg-page-color-marketplace">
                            <div className="col col-xs-12 col-sm-5 col-md-4 vertical-center">
                                <img className="substrate-section-image" src={SectionImage} alt="Marketplace"/>
                            </div>
                            <div className="col col-xs-12 col-sm-7 col-sm-8 vertical-center">
                                <h2>The fast track to commerciality.</h2>
                                <p>
                                    The <strong>Marketplace</strong> is where viable products and
                                    services get their start on the pathway to commercial success.
                                </p>
                                <p>
                                    As a community member, you can advertise and sell your solutions
                                    and services and receive feedback from early adopters to drive
                                    further development.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-marketplace btn-arrow table uppercase pull-right mt-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Show Me How This Works
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

SubstrateSectionMarketplace.propTypes = {
    onClickFunc: PropTypes.func
};

export default SubstrateSectionMarketplace;

import React, {useState, useEffect, useCallback} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setEventConfigUserRefresh, setEventListRefresh} from '../store/actions';
import ReactMarkdown from 'react-markdown';
import {RESOURCES as resources} from '../resourcesEvent';
import {isBlank, makeDateString} from '../utils/dataFunctions';
import {makePostDataRequest} from '../utils/postRequest';
import DeleteEventButton from './buttons/DeleteEventButton';
import EventConfigFields from './forms/EventConfigFields';
import LoadingIndicator from './LoadingIndicator';
import UnsubscribeSelfButton from './buttons/UnsubscribeSelfButton';
import IconCheckmark from './images/IconCheckmark';

const tabs = {
    overview: {tab: 'overview', title: 'Overview'},
    description: {tab: 'description', title: 'Description'}
};

/**
 * Panel: Event Detail
 *
 * @param isSysop
 * @param basePath
 * @param eventConfig
 * @param eventItem
 * @param eventType
 * @param user
 * @param onAirmeetLink
 * @param onJoinOrLeaveFunc
 * @param inInvitedEvents
 * @param inSubscribedEvents
 * @param inOwnEvents
 * @param tab
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EventDetail = (
    {
        isSysop,
        basePath = '',
        eventConfig,
        eventItem,
        eventType,
        user,
        onAirmeetLink,
        onJoinOrLeaveFunc,
        inInvitedEvents = false,
        inSubscribedEvents = false,
        inOwnEvents = false,
        tab = 'overview',
        ...props
    }) => {

    const [tabState, setTab] = useState({
        tab: tabs.overview.tab,
        title: tabs.overview.title
    });

    const [isSaving, setIsSaving] = useState(false);
    const [message, setMessage] = useState({
        error: false,
        message: ''
    });
    const [redirect, setRedirect] = useState(false);

    // Set tab if an outside source requests it
    useEffect(() => {
        // @TODO first click from outside after internal tabState change fails
        // @TODO avoid checking/update internally
        if (tabs[tab] && (tabs[tab].tab)) {
            // console.log('useEffect check tab: current ' + tabState.tab + ', new: ', tab);
            // console.log('useEffect tabs[tab].tab: ', tabs[tab].tab);
            // changeTab(tabs[tab].tab, tabs[tab].title);
        }
    }, [tab]);

    const changeTab = (tab, title) => {
        setIsSaving(false);
        setMessage({error: false, message: ''});
        setTab({tab: tab, title: title});
    };

    // API: Join Event
    const joinEvent = () => {
        setIsSaving(true);
        const request = makePostDataRequest(resources, 'EventPersonalJoin', 'PUT', {id: eventItem.id});
        let errorMessage = 'Sorry, there was an issue joining this Event.';
        request.then(result => {
            setIsSaving(false);
            if (result && (result.sign_up_date || result === 'OK')) {
                setMessage({error: false, message: 'You have successfully joined this Event.'});
                // Notify the parent page
                if (typeof onJoinOrLeaveFunc === 'function') {
                    onJoinOrLeaveFunc('subscribe');
                }
                return Promise.resolve(result);
            } else {
                if (result?.data?.error) {
                    if (
                        result.data.error.indexOf('no personal event share found') !== -1 ||
                        result.data.error.indexOf('no event found') !== -1
                    ) {
                        errorMessage = 'No Personal Event was found.';
                    }
                    if (result.data.error.indexOf('already') !== -1) {
                        errorMessage = 'You are already participating in this Event.';
                    }
                }
                setMessage({
                    error: true,
                    message: errorMessage
                });
                return Promise.reject('Unsubscribe failed');
            }
        }).catch(error => {
            // console.log('Join Event Error: ', error);
            setIsSaving(false);
            setMessage({
                error: true,
                message: errorMessage
            });
            return Promise.reject('Server Error');
        });
    };

    const handleUnsubscribe = (e) => {
        const actions = (e === 'reject')
            ? {
                past: 'rejected',
                func: 'reject',
            }
            : {
                past: 'unsubscribed from',
                func: 'subscribe',
            };
        if (typeof onJoinOrLeaveFunc === 'function') {
            onJoinOrLeaveFunc(actions.func);
        }
        setMessage({
            error: false,
            message: 'You have successfully ' + actions.past + ' this Event.'
        });
        setTimeout(() => {
            setRedirect(true);
        }, 100);
    };

    const handleDeleted = () => {
        // Refresh User Configuration (for Event limits) and list of own Events
        props.setEventConfigUserRefresh(true);
        props.setEventListRefresh('personal_own');
        setTimeout(() => {
            setRedirect(true);
        }, 200);
    };

    // Handle click from Session Join button
    const launchAirmeetEvent = useCallback((uuid) => {
        if (typeof onAirmeetLink === 'function') {
            onAirmeetLink('event', uuid);
        }
    }, [onAirmeetLink]);

    const setButtonDisplay = () => {
        return {
            join: (!(inSubscribedEvents || inOwnEvents)),
            launch: !!(inSubscribedEvents || inOwnEvents),
            leave: (inSubscribedEvents && !inOwnEvents && !isSysop),
            reject: (inInvitedEvents && !inSubscribedEvents)
        };
    };

    if (redirect) {
        return <Redirect to="/x/connect/events"/>
    }

    const startDateStr = (eventItem.start_time) ? makeDateString(eventItem.start_time, 'm', true) : '';
    const endDateStr = (eventItem.end_time) ? makeDateString(eventItem.end_time, 'm', true) : '';
    const eventPath = (eventType === 'personal' || eventType === 'personal_own') ? 'event/personal' : 'event/public';
    const buttonDisplay = setButtonDisplay();
    const hasAirmeet = (!isBlank(eventItem.entry_link));

    return (
        <div className="panel no-padding">

            <h3 className="header">{tabState.title}</h3>

            <div className="xrathus-detail">

                {/* Left Side Detail Menu */}

                <div className="item-menu">
                    <ul>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.overview.tab, tabs.overview.title)}>
                                Overview
                            </button>
                        </li>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.description.tab, tabs.description.title)}>
                                Description
                            </button>
                        </li>
                        <li className="divider">
                        </li>
                    </ul>
                </div>

                {/* Right-Side Detail Content */}

                <div className="item-content">
                    {/* Tab: Overview */}
                    {tabState.tab === 'overview' &&
                        <>
                            {eventItem.username &&
                                <>
                                    <h4>Event Creator</h4>
                                    <p className="details">{eventItem.username}</p>
                                </>
                            }

                            {eventItem.speaker &&
                                <>
                                    <h4>Event Speaker</h4>
                                    <p className="details">{eventItem.speaker}</p>
                                </>
                            }

                            <h4>Short Description</h4>
                            <ReactMarkdown source={eventItem.short_desc}/>

                            {startDateStr &&
                                <>
                                    <h4>Start Date</h4>
                                    <p className="details">{startDateStr}</p>
                                </>
                            }
                            {endDateStr &&
                                <>
                                    <h4>End Date</h4>
                                    <p className="details">{endDateStr}</p>
                                </>
                            }
                            {(isSysop || inOwnEvents) &&
                                <div className="row mb-2">
                            <span className="col col-xs-6 col-md-4">
                                <h4>Private</h4>
                                <p className="details">{eventItem.private ? 'Yes' : 'No'}</p>
                            </span>
                                </div>
                            }

                            {/* Event Configuration (Sysop or creator only) */}
                            {(isSysop || inOwnEvents) &&
                                <EventConfigFields
                                    viewType="show"
                                    event={eventItem}
                                    ownEvent={inOwnEvents}
                                    config={eventConfig}
                                    isSysop={isSysop}
                                />
                            }
                        </>
                    }

                    {/* Tab: Description */}
                    {tabState.tab === 'description' &&
                        <>
                            <h4>Description</h4>
                            <ReactMarkdown source={eventItem.long_desc}/>
                        </>
                    }

                    {/* Buttons and Messaging */}

                    <div className="form-btns right">
                        {isSaving &&
                            <p className="message">
                                <LoadingIndicator color="dark" centered={false} size={26} active={isSaving}/>
                            </p>
                        }
                        {!message.error && message.message !== '' &&
                            <p className="message">
                                <IconCheckmark/>
                            </p>
                        }
                        {buttonDisplay.join &&
                            <button type="button" className="btn btn-primary" title="Join Event"
                                    onClick={() => joinEvent()}
                            >
                                Join Event
                            </button>
                        }
                        {buttonDisplay.reject &&
                            <UnsubscribeSelfButton
                                type="reject"
                                eventId={eventItem.id}
                                username={user.preferred_username}
                                onFormEvent={() => handleUnsubscribe('reject')}
                            />
                        }
                        {eventItem.private === true && buttonDisplay.leave &&
                            <UnsubscribeSelfButton
                                type="unsubscribe"
                                eventId={eventItem.id}
                                username={user.preferred_username}
                                onFormEvent={() => handleUnsubscribe('unsubscribe')}
                            />
                        }
                        {(isSysop || inOwnEvents) &&
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/connect/${eventPath}/${eventItem.id}/edit`}>
                                Edit Event
                            </Link>
                        }
                        {(inSubscribedEvents || inOwnEvents) && hasAirmeet &&
                            <button type="button" className="btn btn-primary"
                                    title="Access Event Real Time Collaboration Tool"
                                    onClick={() => launchAirmeetEvent(eventItem.id)}
                            >
                                Launch RTC
                            </button>
                        }
                        {(isSysop || inOwnEvents) &&
                            <DeleteEventButton
                                eventId={eventItem.id}
                                eventName={eventItem.title}
                                onDeletedEvent={() => handleDeleted()}
                            />
                        }
                    </div>

                    {message.message !== '' &&
                        <div className="form-btns right mt-1">
                            <p className={`message${(message.error) ? ' error' : ''}`}>
                                {message.message}
                            </p>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setEventConfigUserRefresh: setEventConfigUserRefresh,
    setEventListRefresh: setEventListRefresh
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(EventDetail)
);

import React, {Component} from 'react';
import '../../css/static.css';

/**
 * (Unused)
 */
class XHubSharing extends Component {
    render() {

        return (
            <>
                <div className="content sharing">
                    <h1 className="page-title">
                        Sharing
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Collaboration is a core aspect of the Xrathus experience. One way of collaborating
                                    is sharing assets. Any asset within the project directory needs to be shareable
                                    with others, as long as it is permissible by the the owner.
                                </p>
                                <p>
                                    Once available, the Sharing area can also be used for submitting assets for
                                    review, feedback for improvements, and eventually readiness for publishing to
                                    the marketplace.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubSharing;

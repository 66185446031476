export const XRATHUS_NEWS = {
    'cio-applications': {
        title: 'CIO Applications selects Xrathus as one of the Top Collaboration Solution Provider for 2023',
        date: '2023-06-05T00:00:00+07:00',
        excerpt: `
Article about Xrathus: A DXP for Innovation and Education can be 
found [here](https://www.cioapplications.com/xrathus).
        `,
        article: `
        `,
        link: '/'
    },
    'ion-houston': {
        title: 'Ion Houston leverages Xrathus DXP to organize Corporate Innovation Challenges for their partners',
        date: '2023-02-24T00:00:00+07:00',
        excerpt: ``,
        article: `
        `,
        link: '/'
    },
    'created-taskcubes-program': {
        title: 'Created TASKcubes Program',
        date: '2022-08-01T00:00:00+07:00',
        excerpt: `
TASKcubes is a B²Hub Accelerator Program & Xrathus Ecosystem Collaboration for Bootstraps, 
Early-Stage, and Growth-Driven Startups.
        `,
        article: `
        `,
        link: '/'
    },
    'bichronous-ecosystem': {
        title: 'Xrathus Bichronous Ecosystem Released',
        date: '2022-06-27T00:00:00+07:00',
        excerpt: `
Includes enhanced project functionality and on-demand real-time collaboration capabilities.
        `,
        article: `
        `,
        link: '/'
    },
    'sponsored-pivoting-2021': {
        title: 'Sponsored Pivoting 2021, Thriving with Change Series ',
        date: '2021-01-15T00:00:00+07:00',
        excerpt: `
AAPG interactive webinar series focused on innovation.
        `,
        article: `
        `,
        link: '/'
    },
    'released-xrathus-platform': {
        title: 'Released Xrathus Collaboration Platform',
        date: '2020-10-01T00:00:00+07:00',
        excerpt: `
Completed integration of all substrates including Training.
        `,
        article: `
        `,
        link: '/'
    },
    'released-crowdsourcing-functionality': {
        title: 'Released Crowdsourcing Functionality',
        date: '2020-08-01T00:00:00+07:00',
        excerpt: `
Included Clusters, Data Science workspace and Challenge Functionality for SEAM AI.
        `,
        article: `
        `,
        link: '/'
    },
    'incorporated-xrathus': {
        title: 'Incorporated Xrathus',
        date: '2019-12-14T00:00:00+07:00',
        excerpt: `
        `,
        article: `
        `,
        link: '/'
    },
};

import axios from 'axios';
import {getAuthHeaders, getPostHeaders, getUploadPostHeaders} from './headers';

/**
 * Make a post request with a specific resource
 *
 * @param resource
 * @param name
 * @param type
 * @param params
 * @param useBearer
 * @return {Promise<*>}
 */
export async function makePostDataRequest(resource, name, type, params, useBearer = true) {
    let func;
    let method = 'POST';
    switch (type) {
        case 'AUTH':
            func = resource[name].AUTH(params);
            break;
        case 'CREATE':
            func = resource[name].CREATE(params);
            break;
        case 'GET_ONE':
            func = resource[name].GET_ONE(params);
            break;
        case 'POST':
            func = resource[name].POST(params);
            break;
        case 'POST_UPLOAD':
            func = resource[name].POST_UPLOAD(params);
            break;
        case 'PUT':
            method = 'PUT';
            func = resource[name].PUT(params);
            break;
        case 'UPDATE':
            func = resource[name].UPDATE(params);
            break;
        default:
            func = resource[name].GET_ONE();
    }
    try {
        return await postRequest(type, func.uri, method, func.data, useBearer);
    } catch (e) {
        console.log('data error: ', e.message);
        return e;
    }
}

/**
 * Make POST request
 *
 * @param type
 * @param uri
 * @param method
 * @param payload
 * @param useBearer
 * @return {Promise<AxiosResponse<any>>}
 */
const postRequest = (type, uri, method, payload, useBearer = true) => {
    let headers;
    if (type === 'AUTH') {
        headers = getAuthHeaders();
    } else if (type === 'POST_UPLOAD') {
        headers = getUploadPostHeaders();
    } else {
        headers = getPostHeaders(useBearer);
    }
    return axios({
        method: method,
        url: uri,
        data: payload,
        headers: headers
    }).then((response) => {
        if (response.data && response.data.payload) {
            return response.data.payload;
        } else if (response.data) {
            return response.data;
        } else if (response) {
            return response;
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            // Server response outside 2xx
            return error.response.data;
        } else if (error.response) {
            // Server response outside 2xx
            return error.response;
        } else if (error.request) {
            // No response
            return error.request;
        } else {
            console.log('CATCH error ', error);
            return error;
        }
    })
};

export default postRequest;

import React, {forwardRef} from 'react';

const NumberInput = forwardRef(
    (
        {id, label, name, placeholder, instructions, className, changeFunc, children, defaultValue, required, min, max, step, disabled, value}, ref) => {
        return (
            <label htmlFor={id}>
                {label &&
                <span>
                    {label}
                    {required &&
                    <span className="required">*</span>
                    }
                </span>
                }
                {instructions &&
                <span className="instructions">{instructions}</span>
                }
                <input
                    type="number"
                    id={id}
                    name={name}
                    aria-label={placeholder}
                    placeholder={placeholder}
                    min={(min !== null) ? min : undefined}
                    max={(max !== null) ? max : undefined}
                    step={(step) ? step : 1}
                    defaultValue={(defaultValue) ? defaultValue : undefined}
                    value={(value) ? value : undefined}
                    ref={ref}
                    required={(required) ? true : undefined}
                    disabled={(disabled) ? true : undefined}
                    className={className}
                    onChange={(changeFunc)
                        ? (e) => changeFunc({
                            name: e.target.name,
                            value: e.target.value
                        })
                        : undefined}
                />
                {children}
            </label>
        );
    }
);

export default NumberInput;

import axios from 'axios';
import {getStoreToken, getUploadPostHeaders} from './headers';

/**
 * Return a Promise for a timeout
 *
 * @param delay
 * @return {Promise<string>}
 */
const asyncTimeout = (delay) => {
    return (
        new Promise(resolve => {
            setTimeout(() => resolve(delay), delay)
        }))
        .then(d => `Waited ${d} seconds`);
};

/**
 * Return a Promise for File Upload
 *
 * @param data
 * @return {Promise<unknown>}
 */
const asyncFileUpload = (data) => {
    return uploadFiles(data.data_id, data.index, data.path, data.collection, data.progressFunc);
};

/**
 * Execute a Promise-based task
 *
 * @param spec
 * @return {*}
 */
export const runFileUploadTask = (spec) => {
    return (spec && spec.task === 'wait') ? asyncTimeout(spec.duration) : asyncFileUpload(spec.data);
};

/**
 * POST a set of Dataset-specific files to the server
 *
 * @param data_id
 * @param index
 * @param path
 * @param collection
 * @param progressFunc
 * @return {Promise<unknown>}
 */
export const uploadFiles = (data_id, index, path, collection, progressFunc) => {

    return new Promise(function (resolve, reject) {

        // Assemble File objects into FormData
        const fd = new FormData();
        collection.forEach(file => {
            fd.append('files', file);
        });
        fd.append('timestamp', new Date().getTime().toString());

        // Create a function to lift loading progress
        const progressConfig = {
            onUploadProgress: function (progressEvent) {
                if (typeof progressFunc === 'function') {
                    progressFunc('fileUpload', {
                        index: index,
                        loaded: progressEvent.loaded,
                        path: path,
                        total: progressEvent.total
                    });
                }
            }
        }

        const onFailure = function (result) {
            return reject('File Upload failed: ', result);
        }

        const onSuccess = function (result) {
            return resolve(result);
        }

        // Set the folder path the files will upload to
        let pathStr = (path) ? path : '/';
        const url = localStorage.getItem('server') + "/api/v1/mgmt/file/" + data_id + pathStr;

        // Set headers
        const headers = getUploadPostHeaders();

        try {
            axios({
                method: 'POST',
                url: url,
                data: fd,
                headers: headers,
                onUploadProgress: progressConfig.onUploadProgress
            })
                .then((result) => {
                    if (result && result.data) {
                        return onSuccess(result.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // Server response outside 2xx
                        console.log('File Upload Error response: ', error);
                        onFailure(error.response);
                    } else if (error.request) {
                        // No response
                        console.log('File Upload Error request: ', error);
                        onFailure(error.request);
                    } else {
                        console.log('File Upload Error: ', error);
                        onFailure(error);
                    }
                });
        } catch (err) {
            console.log('File Upload try/catch error: ', err);
            onFailure(err);
        }
    });
};

/**
 * Get request for a File download
 *
 * @param filename
 * @param progressFunc
 */
export const downloadFile = (filename, progressFunc) => {

    let xhr = new XMLHttpRequest();
    let downloadUrl;
    return new Promise(function (resolve, reject) {

        xhr.onreadystatechange = function () {
            // console.log(`Ready State: ${xhr.readyState}`);
            if (xhr.readyState === 4) {
                if (xhr.response) {
                    // console.log(`response?: `, xhr.response.substr(0, 100));
                }
            }
        };

        xhr.onload = function () {
            if (xhr.response) {
                // Create a link element to download a file
                if (downloadUrl) {
                    URL.revokeObjectURL(downloadUrl);
                }
                downloadUrl = URL.createObjectURL(new Blob([xhr.response], {type: 'text/csv'}));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                // const downloadFileName = filename.replace(/\.[^/.]+$/, "") + '.csv';
                link.setAttribute('download', filename);
                link.click();
                return resolve(xhr.response);
            } else {
                console.log('File Download failed: ', xhr);
                return reject('File Download failed');
            }
        };

        xhr.onerror = function () {
            console.log(`Network Error ${xhr.status}: ${xhr.statusText}`, xhr);
            return reject('File Download failed: ', xhr.statusText);
        };

        xhr.onprogress = function (event) {
            progressFunc('fileDownload', {loaded: event.loaded, total: event.total});
        };

        xhr.ontimeout = function (event) {
            console.log(`File Download Timeout: `, event);
        };

        xhr.onabort = function (event) {
            console.log(`File Download Aborted: `, event);
        };

        const url = localStorage.getItem('server') + "/api/v1/file/" + filename;
        xhr.open('GET', url);

        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        // xhr.setRequestHeader('Content-Type', 'text/csv');
        const token = getStoreToken();
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.responseType = 'text';

        try {
            return xhr.send();
        } catch (err) {
            console.log('try/catch error: ', err);
        }
    });
};

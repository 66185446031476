import React, {useState, useEffect, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setProjectConfigUserRefresh} from '../store/actions';
import {RESOURCES as resources} from '../resourcesData';
import {makeGetDataRequest} from '../utils/getRequest';
import {convertSizeToDisplayString} from '../utils/dataFunctions';
import {getMaxDatasetSize, getMaxVolumes, getProjectPermissions} from '../utils/projectFunctions';
import BasicTable from '../partials/tables/BasicTable';
import DeleteFileButton from '../partials/buttons/DeleteFileButton';
import FormDataFilesVolumesEdit from '../partials/forms/FormDataFilesVolumesEdit';
import ProjectDatasetSizeTotal from '../partials/ProjectDatasetSizeTotal';
import VolumeList from '../partials/VolumeList';
import '../css/challenge.css';
import '../css/file.css';

/**
 * Page: Edit Dataset form for Challenges or Projects
 *
 * @param location
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const XDatasetEdit = ({location, ...props}) => {

    const pathArr = location.pathname.split('/');
    const dataId = Number(pathArr.reverse()[1]);
    const projectTypeStr = (location.pathname.indexOf('challenge') !== -1) ? 'Challenge' : 'Project';

    const [dataDesc, setDataDesc] = useState({});
    const [dataList, setDataList] = useState([]);
    const [dataListSize, setDataListSize] = useState({
        size: 0,
        sizeStr: '0KB'
    });
    const [refreshDesc, setRefreshDesc] = useState(true);
    const [refreshList, setRefreshList] = useState(true);
    const [checkVolumeStatus, setCheckVolumeStatus] = useState(false);
    const [totalVolumes, setTotalVolumes] = useState(0);

    // Effect: Get Dataset data
    useEffect(() => {
        const params = {
            id: dataId
        };

        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'Data', 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    if (typeof result === 'string') {
                        console.log('Dataset Error: ', result);
                        setRefreshDesc(false);
                        return Promise.resolve(result);
                    }
                    if (result) {
                        setDataDesc(result);
                    }
                    setRefreshDesc(false);
                    return Promise.resolve(result);
                } else {
                    console.log('Dataset Error: No result');
                    setRefreshDesc(false);
                    return Promise.reject('Dataset failed');
                }
            }).catch(error => {
                console.log('Dataset Error: ', error);
                setRefreshDesc(false);
                return Promise.reject('Server Error');
            });
        };

        if (dataId && refreshDesc) {
            return fetchData();
        }

    }, [dataId, refreshDesc]);

    // Effect: Get Dataset File list
    useEffect(() => {
        const params = {
            data_id: dataId
        };

        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'File', 'GET_LIST', params);
            request.then(result => {
                if (result?.files?.length > 0) {
                    let totalSize = 0;
                    for (let i = 0; i < result.files.length; i++) {
                        result.files[i].size_string = convertSizeToDisplayString(result.files[i].size);
                        totalSize += result.files[i].size;
                    }
                    setDataList(result.files);
                    setDataListSize({
                        size: totalSize,
                        sizeStr: convertSizeToDisplayString(totalSize)
                    });
                    return Promise.resolve(result);
                } else if (result && !result.files) {
                    setDataList([]);
                    setDataListSize({size: 0, sizeStr: '0KB'});
                    return Promise.resolve(result);
                } else {
                    console.log('Dataset Error: No dataset');
                    return Promise.reject('Dataset failed');
                }
            }).catch(error => {
                console.log('Dataset Error: ', error);
                return Promise.reject('Server Error');
            }).finally(() => {
                setRefreshList(false);
            });
        };

        if (dataId && refreshList) {
            return fetchData();
        }

    }, [dataId, refreshList]);

    const handleRefresh = (e) => {
        if (e.state === 'complete') {
            if (e.type === 'files') {
                setRefreshList(true);
            }
            setRefreshDesc(true);
            // Refresh User Configuration (in case Dataset Files were updated)
            props.setProjectConfigUserRefresh(true);
        }
        if (e.type === 'volume' && e.state === 'created') {
            setCheckVolumeStatus(true);
            setCheckVolumeStatus(false);
        }
    };

    const handleOnVolumes = (e) => {
        setTotalVolumes(e);
    };

    // Files Table setup
    const tableData = useMemo(() => dataList, [dataList]);
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'object',
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: 'Size',
                Footer: () => (
                    <span className="table-column-total">
                        Total: {dataListSize.sizeStr}
                    </span>
                ),
                accessor: 'size_string',
                disableFilters: true,
                disableSortBy: true,
                className: 'right',
                headerClassName: 'right',
            },
            {
                Header: '',
                accessor: ' ',
                Cell: (value) => (<DeleteFileButton record={value} dataId={dataId} onFormEvent={(e) => handleRefresh(e)}/>),
                width: 30,
                maxWidth: 30,
                disableFilters: true,
                disableSortBy: true
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        ], [dataId, dataListSize.sizeStr]
    );

    const projectPermissions = getProjectPermissions(props.user, props.project_list_own, null, dataId);
    const maxDatasetSize = getMaxDatasetSize(props.project_config, props.project_config_user, props.project_config_current);
    const maxVolumes = getMaxVolumes(props.project_config, props.project_config_user, props.project_config_current);

    return (
        <div className="content xhub challenge">
            <h1 className="page-title">
                Edit Dataset
            </h1>
            {!projectPermissions.sysop && !projectPermissions.owner &&
            <div className="row">
                <div className="col col-xs-12" key="projects-empty">
                    <div className="panel">
                        You are not authorized to edit this Dataset and its Files.
                    </div>
                </div>
            </div>
            }
            {(projectPermissions.sysop || projectPermissions.owner) &&
            <>
                <div className="panel">
                    <h3 className="header">Dataset</h3>
                    <FormDataFilesVolumesEdit
                        formType="update"
                        projectString={projectTypeStr}
                        projectType={(projectTypeStr === 'Challenge') ? 'challenge' : 'project'}
                        dataId={dataDesc.id}
                        dataDesc={dataDesc.description}
                        dataList={dataList}
                        isSysop={projectPermissions.sysop}
                        inOwnProjects={projectPermissions.owner}
                        maxDatasetSize={maxDatasetSize}
                        maxVolumes={maxVolumes}
                        totalVolumes={totalVolumes}
                        onFormEvent={(e) => handleRefresh(e)}
                    />
                </div>

                <div className="panel no-padding">
                    <h3 className="header">Files ({dataList.length ? dataList.length : '0'})</h3>
                    {tableData.length > 0 &&
                    <BasicTable columns={columns} data={tableData} buttonsClass='last-1'/>
                    }
                    {projectTypeStr === 'Project' &&
                    <ProjectDatasetSizeTotal
                        size={dataListSize.size}
                        maxSize={maxDatasetSize.project}
                        sizeString={dataListSize.sizeStr}
                    />
                    }
                </div>

                <VolumeList
                    dataId={dataId}
                    projectType={(projectTypeStr === 'Challenge') ? 'challenge' : 'project'}
                    isSysop={projectPermissions.sysop}
                    inOwnProjects={projectPermissions.owner}
                    maxVolumes={maxVolumes.project}
                    onVolumeList={(e) => handleOnVolumes(e)}
                    checkVolumeStatus={checkVolumeStatus}
                />
            </>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        project_config: state.project_config,
        project_config_current: state.project_config_current,
        project_config_user: state.project_config_user,
        project_list_own: state.project_list_own,
        user: state.user
    };
};

const mapDispatchToProps = {
    setProjectConfigUserRefresh: setProjectConfigUserRefresh,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(XDatasetEdit));

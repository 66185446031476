import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as eventResources} from '../resourcesEvent';
import {RESOURCES as projectResources} from '../resourcesProject';
import {makeGetDataRequest} from './getRequest';

/**
 * Component for retrieving Users of a Shared Project or Event
 */
class APISharedUsers extends Component {

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.refresh && this.props.refresh) {
            this.getList();
        }
    }

    /**
     * Get Users of a Shared Event
     *
     */
    getList = () => {
        const params = {
            id: this.props.id
        };
        let request;
        if (this.props.getType === 'event') {
            request = makeGetDataRequest(eventResources, 'EventPersonalSharedUsers', 'GET_LIST', params);
        }
        if (this.props.getType === 'event-public') {
            request = makeGetDataRequest(eventResources, 'EventPublicSharedUsers', 'GET_LIST', params);
        }
        if (this.props.getType === 'project') {
            request = makeGetDataRequest(projectResources, 'ProjectPersonalSharedUsers', 'GET_LIST', params);
        }
        request.then(result => {
            let empty = false;
            if (result) {
                if (typeof result === 'string') {
                    console.log('Shared Users Retrieval Error: ', result);
                    empty = true;
                }
                if (result.status === 200 && result.data === null) {
                    empty = true;
                }
                // Pass results to parent
                if (typeof this.props.onResult === 'function') {
                    this.props.onResult((empty) ? [] : result);
                }
                return Promise.resolve(result);
            } else {
                console.log('Shared Users Retrieval Error: No result');
                if (typeof this.props.onResult === 'function') {
                    this.props.onResult((empty) ? [] : result);
                }
                return Promise.reject('Shared Users Retrieval failed');
            }
        }).catch(error => {
            console.log('Shared Users Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    render() {
        return (
            <>
            </>
        )
    }
}

APISharedUsers.propTypes = {
    getType: PropTypes.string,
    id: PropTypes.string,
    onResult: PropTypes.func,
    refresh: PropTypes.bool
};

APISharedUsers.defaultProps = {
    getType: 'project',
    refresh: false
};

export default APISharedUsers;

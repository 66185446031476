import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resourcesProject';
import {
    setListStatus,
    setProjectId,
    setProjectListInvited,
    setProjectListOwn,
    setProjectListRefresh,
    setProjectListSubscribed
} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import {copyArray} from './dataFunctions';
import {populateShortDescription} from './projectFunctions';

/**
 * Component for retrieving Project Lists and single Project items
 *
 */
class APIProjects extends Component {

    componentDidMount() {
        const getType = this.props.getType;
        if (getType === 'list') {
            this.getProjects();
        }
        if (getType === 'single') {
            this.getProject();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const getType = this.props.getType;
        if (this.props.refresh === true) {
            if (getType === 'list') {
                this.getProjects();
            }
            if (getType === 'single') {
                this.getProject();
            }
        } else if (this.props.project_list_refresh !== null) {
            if (getType === 'list') {
                this.getProjects();
            }
        }
    }


    /**
     * Get Projects with optional parameters
     *
     */
    getProjects = () => {
        const refreshType = this.props.project_list_refresh;
        const viewType = this.props.viewType;
        // If Projects are being refreshed, only do so if this API matches the refresh type
        if (refreshType && refreshType !== viewType) {
            return;
        }
        let resourceName = 'Project';
        if (viewType === 'personal_own') {
            resourceName = 'ProjectPersonalOwn';
        }
        if (viewType === 'personal_own_by_active') {
            resourceName = 'ProjectPersonalOwnByActive';
        }
        if (viewType === 'shared') {
            resourceName = 'ProjectPersonalShared';
        }
        if (viewType === 'subscribed') {
            resourceName = 'ProjectPersonalSubscribed';
        }
        if (viewType === 'subscribed_sysop') {
            resourceName = 'ProjectPersonalSubscribedSysop';
        }
        const params = this.props.params;
        const request = makeGetDataRequest(resources, resourceName, 'GET_LIST', params);
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    // console.log('Projects Retrieval Error: ', result);
                    this.processList(null);
                    return Promise.resolve(result);
                }
                this.processList(result);
                return Promise.resolve(result);
            } else {
                console.log('Projects Retrieval Error: No result');
                return Promise.reject('Projects Retrieval failed');
            }
        }).catch(error => {
            console.log('Projects Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Get single Project
     */
    getProject = () => {
        const id = this.props.id;
        if (!id) {
            return Promise.reject('No ID provided');
        }
        const viewType = this.props.viewType;
        let resource = 'Project';
        if (viewType === 'personal' || viewType === 'personal_own' || viewType === 'personal_own_by_active') {
            resource = 'ProjectPersonal';
        }
        const request = makeGetDataRequest(resources, resource, 'GET_ONE', {id: id});
        request.then(result => {
            if (result) {
                if (typeof result === 'string') {
                    console.log('Project Retrieval Error: ', result);
                    this.processSingle(null);
                    return Promise.resolve(result);
                }
                this.processSingle(result);
                return Promise.resolve(result);
            } else {
                console.log('Project Retrieval Error: No result');
                return Promise.reject('Project Retrieval failed');
            }
        }).catch(error => {
            console.log('Project Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    /**
     * Return and/or Store list data
     *
     * @param data
     */
    processList = (data) => {
        let list = [];
        const viewType = this.props.viewType;

        // Projects owned by User
        if (viewType === 'personal_own' || viewType === 'personal_own_by_active') {
            if (data && data.length > 0) {
                list = copyArray(data);
                for (let i = 0; i < list.length; i++) {
                    let c = list[i];
                    c.short_desc = populateShortDescription(c);
                }
            }
        }
        // Projects shared with or subscribed to by User
        if (viewType === 'shared' || viewType === 'subscribed' || viewType === 'subscribed_sysop') {
            if (data.PsProjects && data.PsProjects.length > 0) {
                list = copyArray(data.PsProjects);
                for (let i = 0; i < list.length; i++) {
                    let c = list[i];
                    c.short_desc = populateShortDescription(c);
                }
            }
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(list);
        }
        // Store results in Redux for specific lists
        if (this.props.storeExists && this.props.storeResults) {
            if (viewType === 'personal_own') {
                this.props.setProjectListOwn(list);
                this.props.setListStatus({list: 'project_own', status: true});
            }
            if (viewType === 'shared') {
                this.props.setProjectListInvited(list);
                this.props.setListStatus({list: 'project_invited', status: true});
            }
            if (viewType === 'subscribed' || viewType === 'subscribed_sysop') {
                this.props.setProjectListSubscribed(list);
                this.props.setListStatus({list: 'project_subscribed', status: true});
            }
            this.props.setProjectListRefresh(null);
        }
    };

    /**
     * Return single result
     *
     * @param data
     */
    processSingle = (data) => {
        if (data) {
            data.short_desc = populateShortDescription(data);
        }

        // Pass results to parent
        if (this.props.onResult) {
            this.props.onResult(data);
        }
        // Store results in Redux
        if (this.props.storeExists && this.props.storeResults) {
            this.props.setProjectId(data.id);
        }
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_list_refresh: state.project_list_refresh
    };
};

const mapDispatchToProps = {
    setListStatus: setListStatus,
    setProjectId: setProjectId,
    setProjectListInvited: setProjectListInvited,
    setProjectListOwn: setProjectListOwn,
    setProjectListRefresh: setProjectListRefresh,
    setProjectListSubscribed: setProjectListSubscribed
};

APIProjects.propTypes = {
    getType: PropTypes.string,
    id: PropTypes.number,
    onResult: PropTypes.func,
    params: PropTypes.object,
    refresh: PropTypes.bool,
    storeExists: PropTypes.bool,
    storeResults: PropTypes.bool,
    viewType: PropTypes.string
};

APIProjects.defaultProps = {
    getType: 'list',
    params: {
        active: true
    },
    refresh: false,
    storeExists: true,
    storeResults: true,
    viewType: 'subscribed'
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIProjects);

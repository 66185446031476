import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resourcesProject';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../modal/Modal';
import NumberInput from '../fields/NumberInput';
import 'react-datepicker/dist/react-datepicker.min.css';

const defaultDuration = 30;
const today = new Date();

/**
 * Button with Create Airmeet Session functionality
 *
 * @param item
 * @param itemType
 * @param onFormEvent
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const FormAirmeetSessionCreate = (
    {
        item,
        itemType,
        onFormEvent
    }) => {

    const {errors, getValues, handleSubmit, register, setValue, setError, clearErrors} = useForm({mode: 'onBlur'});

    const [modalStatus, setModalStatus] = useState(false);
    const [createError, setCreateError] = useState(null);
    const [formStageState, setFormStageState] = useState('form');
    const [fieldState, updateFieldState] = useState(
        {
            duration: defaultDuration,
            event_id: item?.airmeet_id ?? '',
            start_time: today.getTime(),
            summary: '',
            title: ''
        }
    );
    const [startTime, updateStartTime] = useState({
        obj: today,
        ms: today.getTime()
    });

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const reset = () => {
        setValue('duration', defaultDuration);
        setValue('title', '');
        setValue('summary', '');
        updateFieldState({
            ...fieldState,
            duration: defaultDuration,
            start_time: today.getTime(),
            summary: '',
            title: ''
        });
        updateStartTime({...startTime, obj: today, ms: today.getTime()});
        setFormStageState('form');
        setCreateError(null);
    };

    const open = () => {
        reset();
        setModalStatus(true);
    };

    const close = () => {
        reset();
        setModalStatus(false);
    };

    const handleFormChange = (e) => {
        if (e.name === 'start_time') {
            let date = e.value;
            const stDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
            const stMs = stDate.getTime();
            const now = new Date();
            const nowDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
            const nowMs = nowDate.getTime();
            if (stMs < nowMs) {
                setError('start_time', {
                    type: 'manual',
                    message: 'Dates and Times must be from now onward.',
                });
                updateStartTime({...startTime, obj: nowDate, ms: nowMs});
            } else {
                clearErrors('start_time');
                updateStartTime({...startTime, obj: stDate, ms: stMs});
            }
        } else if (e.name === 'duration_minutes') {
            // Airmeet API actually needs minutes despite its documentation
            let mins = (e.value) ? parseInt(e.value, 10) : 30;
            updateFieldState({...fieldState, duration: mins});
        } else {
            updateFieldState({...fieldState, [e.name]: e.value});
        }
    };

    const disableSubmit = () => {
        return !!(
            getValues('title') === '' ||
            getValues('start_time') === -1 ||
            (errors &&
                (
                    errors.title
                ))
        );
    };

    const onSubmit = () => {
        const eventId = getValues('event_id');
        const params = {
            eventId: eventId,
            sessionTitle: getValues('title'),
            sessionStartTime: parseInt(getValues('start_time'), 10),
            sessionDuration: parseInt(getValues('duration'), 10),
            sessionSummary: getValues('summary')
        };
        if (!eventId || eventId === '') {
            setCreateError('Sorry, there was an error accessing the Airmeet Event.');
        } else {
            setCreateError(null);
            setFormStageState('saving');
            submitForm(params);
        }
    };

    const submitForm = (params) => {
        const request = makePostDataRequest(resources, 'AirmeetSessions', 'CREATE', params);
        request.then(result => {
            if (result && result.uid) {
                close();
                setCreateError(null);
                setFormStageState('form');
                if (typeof onFormEvent === 'function') {
                    onFormEvent();
                }
                return Promise.resolve(result);
            } else if (result.error) {
                if (result.error.indexOf('overlapping') !== -1) {
                    setCreateError('Sorry, this Session overlaps an existing Session and could not be created.');
                } else {
                    setCreateError('Sorry, this Session could not be created. Error: ' + result.error);
                }
                setFormStageState('form');
            } else {
                console.log('Error: Unable to create Session');
                setCreateError('Sorry, this Session could not be created.');
                setFormStageState('form');
                return Promise.reject('Create Session failed');
            }
        }).catch(error => {
            console.log('Error: ', error);
            setCreateError('Sorry, this Session could not be created.');
            setFormStageState('form');
            return Promise.reject('Server Error');
        });
    };

    const setItemStr = (type) => {
        if (type === 'challenge') {
            return 'Challenge';
        }
        if (type === 'event') {
            return 'Event';
        }
        return 'Project';
    };

    const itemStr = setItemStr(itemType);

    return (
        <>
            <div className="form-btns right margin-2 mb-1">
                <button
                    type="button"
                    className="btn btn-primary"
                    title="Create Session"
                    onClick={() => open()}
                >
                    Create Session
                </button>
            </div>
            <Modal
                allowClose={true}
                id="modal-create-airmeet-session"
                show={modalStatus}
                onClose={() => close()}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 200,
                    top: 20,
                    transform: 'translate(-50%, 0)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">
                        Create Session
                    </h3>
                    <p>Enter data below to create a Real Time Collaboration Session for
                        this {itemStr}'s Event.</p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group ml-1 mr-1">
                            <BaseInput
                                type="text"
                                id="session-title" name="title"
                                label="Session Title"
                                placeholder="Add a Title for this Session"
                                aria-describedby="error-title-required error-title-minLength error-title-maxLength"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Title is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Title must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: (<FormError id="maxLength"
                                                             text="Title cannot be longer than 100 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group ml-1 mr-1">
                            <BaseInput
                                type="text"
                                id="session-summary" name="summary"
                                label="Session Summary"
                                placeholder="Add a brief description of this Session"
                                ref={register}
                                className="form-input"
                            >
                            </BaseInput>
                        </div>
                        <div className="form-group ml-1 mr-1">
                            <label htmlFor="select_start_time">
                                <div>Start Time<span className="required">*</span></div>
                                <span className="instructions">When will this Session start?</span>
                                <DatePicker
                                    id="start_time_select"
                                    selected={startTime.obj}
                                    onChange={(e) => handleFormChange({name: 'start_time', value: e})}
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    showTimeInput
                                    minDate={today}
                                    filterTime={filterPassedTime}
                                    popperPlacement="right"
                                />
                                <input readOnly type="hidden" name="start_time" value={startTime.ms} ref={register}/>
                                {errors.start_time && errors.start_time.message &&
                                    <>
                                        <FormError id="start_time_now" text="Date and Time must be from now onward."/>
                                        <div className="mb-1">&nbsp;</div>
                                    </>
                                }
                            </label>
                        </div>

                        <div className="form-group ml-1 mr-1">
                            <NumberInput
                                id="session-duration"
                                name="duration_minutes"
                                label="Duration (in Minutes)"
                                instructions="How long will this Session last? Default is 30."
                                min={10}
                                step={1}
                                ref={register}
                                defaultValue={30}
                                changeFunc={(e) => handleFormChange({name: 'duration_minutes', value: e.value})}
                                className="form-input half"
                            >
                                <input readOnly type="hidden" name="duration" value={fieldState.duration}
                                       ref={register}/>
                            </NumberInput>
                        </div>

                        <BaseInput
                            type="hidden"
                            id="session-event-id"
                            name="event_id"
                            label=""
                            value={(item?.airmeet_id ?? '')}
                            ref={register}
                            disabled
                        >
                        </BaseInput>

                        <div className="form-btns center mb-1">
                            <button className="btn" onClick={() => close()}>Cancel</button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={!!(disableSubmit())}
                            >
                                Create Session
                            </button>
                            {formStageState === 'saving' &&
                                <LoadingIndicator color="dark" centered={true} active={true}/>
                            }
                        </div>
                    </form>

                    {createError &&
                        <p className="error">
                            {createError}
                        </p>
                    }
                </div>
            </Modal>
        </>
    );
};

FormAirmeetSessionCreate.propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string
};

FormAirmeetSessionCreate.defaultProps = {
    itemType: 'project'
};

export default FormAirmeetSessionCreate;

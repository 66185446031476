import React, {Component} from 'react';
import FormChallengeAdd from '../forms/FormChallengeAdd';

class ChallengeAddModalContent extends Component {
    render() {
        return (
            <>
                <h3 className="header modal-header">Add Challenge</h3>
                <div className="form-container">
                    <FormChallengeAdd/>
                </div>
            </>
        )
    }
}

export default ChallengeAddModalContent;

/**
 * Collaborators navigation (shared)
 *
 */
export const collaboratorsNav = {
    title: 'Collaborators',
    slug: 'connect-collaborators',
    substrateClass: 'connect',
    route: '/x/connect/collaborators',
    icon: 'images/icon-Connect-Collaboration.svg',
    links: [
        {
            title: 'Academia',
            slug: 'connect-collaborators-academia',
            substrateClass: 'connect',
        },
        {
            title: 'Associations',
            slug: 'connect-collaborators-associations',
            substrateClass: 'connect',
            route: '/x/connect/collaborators/associations',
            links: [
                {
                    title: 'AAPG',
                    slug: 'connect-collaborators-associations-aapg',
                    substrateClass: 'connect',
                    route: '/x/connect/collaborators/associations/aapg-pivoting-2021',
                },
            ]
        },
        {
            title: 'Industry',
            slug: 'connect-collaborators-industry',
            substrateClass: 'connect',
            route: '/x/connect/collaborators/industry',
            links: [
                {
                    title: 'Criterion',
                    slug: 'connect-collaborators-industry-criterion',
                    substrateClass: 'connect',
                    route: '/x/connect/collaborators/industry/criterion',
                },
                {
                    title: 'Deasil',
                    slug: 'connect-collaborators-industry-deasil',
                    substrateClass: 'connect',
                    route: '/x/connect/collaborators/industry/deasil',
                },
                {
                    title: 'Seera Labs',
                    slug: 'connect-collaborators-industry-seera-labs',
                    substrateClass: 'connect',
                    route: '/x/connect/collaborators/industry/seera-labs',
                }
            ]
        },
        {
            title: 'Xrathus',
            slug: 'connect-collaborators-xrathus',
            substrateClass: 'connect',
        },
    ]
};

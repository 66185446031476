import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ChallengeTable from '../partials/ChallengeTable';
import EventTable from '../partials/EventTable';
import OrganizationTable from '../partials/OrganizationTable';
import ProjectTable from '../partials/ProjectTable';
import '../css/challenge.css';

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasetFormType: 'none',
            datasetId: null,
            showDatasetForm: false,
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
    };

    render() {
        const {
            user
        } = this.props;

        if (!user.is_sysop) {
            return (
                <>
                    <div className="content user">
                        <h1 className="page-title">
                            Xrathus Administration
                        </h1>
                        {user.name &&
                        <span className="welcome-user">
                            Welcome, {user.name}
                        </span>
                        }
                        <p>
                            Sorry, you do not have access to this area.
                        </p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="content user">
                    <h1 className="page-title">
                        Xrathus Administration
                    </h1>
                    {user.name &&
                    <span className="welcome-user">
                        Welcome, {user.name}
                    </span>
                    }

                    {/* Organizations */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Organizations</h3>
                                <br/>
                                <p>
                                    Review and edit Organization data.
                                </p>
                                <OrganizationTable perPage={10000}/>
                                <br/>
                                <div className="form-btns x-table-buttons">
                                    <Link to="/x/organization/add"
                                          title="Add Organization"
                                          className="btn btn-primary"
                                    >
                                        Add Organization
                                    </Link>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {/* Personal Projects */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Personal User Projects</h3>
                                <br/>
                                <p>
                                    Review and edit Personal User Projects and Datasets.
                                </p>
                                <ProjectTable projectType="personal"/>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {/* Challenges */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Challenges</h3>
                                <br/>
                                <p>
                                    Review and edit Challenges and Datasets.
                                </p>
                                <ChallengeTable/>
                                <br/>
                                <div className="form-btns x-table-buttons">
                                    <Link to="/x/connect/challenges/add"
                                          title="Add Challenge"
                                          className="btn btn-primary"
                                    >
                                        Add Challenge
                                    </Link>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {/* Personal Events */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Personal User Events</h3>
                                <br/>
                                <p>
                                    Review and edit Personal User Events.
                                </p>
                                <EventTable eventType="admin"/>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {/* Project Configuration */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Personal Project Configuration</h3>
                                <br/>
                                <p>
                                    Review and edit default Configurations for Personal Projects, and override
                                    Personal Project Configurations for specific Users.
                                </p>
                                <br/>
                                <div className="form-btns x-table-buttons">
                                    <Link to="/x/admin/configuration"
                                          title="Manage Configuration"
                                          className="btn btn-primary"
                                    >
                                        Manage Configuration
                                    </Link>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {/* zEvent Configuration */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Personal Event Configuration</h3>
                                <br/>
                                <p>
                                    Review and edit default Configurations for Personal Events, and override
                                    Personal Event Configurations for specific Users.
                                </p>
                                <br/>
                                <div className="form-btns x-table-buttons">
                                    <Link to="/x/admin/event-configuration"
                                          title="Manage Configuration"
                                          className="btn btn-primary"
                                    >
                                        Manage Configuration
                                    </Link>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps
        )(AdminDashboard)
    )
);

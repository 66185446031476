import React from 'react';

const IconDeleteCircle = () => {
    return (
        <span className="icon-add-circle">
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none">
				<g clipPath="url(#icon-delete)">
					<path
                        d="M8 0.32666C3.71875 0.32666 0.25 3.76107 0.25 7.99993C0.25 12.2388 3.71875 15.6732 8 15.6732C12.2812 15.6732 15.75 12.2388 15.75 7.99993C15.75 3.76107 12.2812 0.32666 8 0.32666ZM8 14.188C4.54688 14.188 1.75 11.4189 1.75 7.99993C1.75 4.58099 4.54688 1.81181 8 1.81181C11.4531 1.81181 14.25 4.58099 14.25 7.99993C14.25 11.4189 11.4531 14.188 8 14.188ZM11.1812 6.07542L9.2375 7.99993L11.1812 9.92443C11.3281 10.0699 11.3281 10.305 11.1812 10.4504L10.475 11.1497C10.3281 11.2951 10.0906 11.2951 9.94375 11.1497L8 9.22517L6.05625 11.1497C5.90938 11.2951 5.67188 11.2951 5.525 11.1497L4.81875 10.4504C4.67188 10.305 4.67188 10.0699 4.81875 9.92443L6.7625 7.99993L4.81875 6.07542C4.67188 5.93 4.67188 5.69485 4.81875 5.54943L5.525 4.85017C5.67188 4.70475 5.90938 4.70475 6.05625 4.85017L8 6.77468L9.94375 4.85017C10.0906 4.70475 10.3281 4.70475 10.475 4.85017L11.1812 5.54943C11.3281 5.69485 11.3281 5.93 11.1812 6.07542Z"/>
				</g>
				<defs>
					<clipPath id="icon-delete">
						<rect y="0.0791016" width="16" height="15.8416" fill="white"/>
					</clipPath>
				</defs>
			</svg>
        </span>
    )
};

export default IconDeleteCircle;

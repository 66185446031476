import React from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent, getTaggedData, getDisplayedDateAndTime } from '../../../utils/StoryblokFunctions';

import '../../../css/storyblok-custom.css';

const now = new Date();


const NextEvent = ({
    tags,
    allEvents
}) => {
    
    let nextEventProps = null;
    let events = allEvents;
    let relevantEvents = [];

    if (events) {
        //sort out only next events
        events.filter(element =>  new Date(element.start_date).toISOString() >= now.toISOString());
        relevantEvents = getTaggedData(events, tags);
    }

    //only review appropriate event type (default "session" for now)
    relevantEvents = relevantEvents.filter(element => (element.event_type === "session" || element.event_type === "meeting"));

    //resort events
    relevantEvents = relevantEvents.sort(function(a,b) {
        return new Date(a.start) - new Date(b.start);
    });

    if (relevantEvents) {
        nextEventProps = relevantEvents.find(element => new Date(element.end_date).toISOString() >= new Date().toISOString());
    }

    if (nextEventProps && events) {
        let displayedDescription = "";

        if (!nextEventProps.description.type) {
            displayedDescription = nextEventProps.description;
        }
        else {
            displayedDescription = parseStoryblokContent(nextEventProps.description);
        }

        let tempTimeResult = getDisplayedDateAndTime(nextEventProps);
        let tempDispDate = tempTimeResult[0];
        let tempDispTime = tempTimeResult[1];

        return (
            <div>
                {nextEventProps.event_image ? 
                    <img src={nextEventProps.event_image} alt={nextEventProps.title}
                        className="single-image aapg-pivoting-2021 thumbnail"/> : <></>}
                
                <h4>{nextEventProps.title}</h4>
                <p className="info left">
                    Date: {tempDispDate}
                    <br/>
                    Time: {tempDispTime}
                </p>
                <div>{displayedDescription}</div>
                &nbsp;
                <div className="row">
                    {nextEventProps.discussion_link ? 
                        <div className="col col-xs-12 col-sm-5">
                            <a href={nextEventProps.discussion_link}
                                title="Discuss" target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary btn-image-text nowrap center"
                            >Discussion</a>
                        </div> : <></>}
                    {nextEventProps.discussion_link && (nextEventProps.registration_link || nextEventProps.join_link) ? 
                        <div className="col col-xs-12 col-sm-2"></div> : <></>}
                    {nextEventProps.registration_link ? 
                        <div className="col col-xs-12 col-sm-5">
                            <a href={nextEventProps.registration_link}
                                title="Register" target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary btn-image-text nowrap center"
                            >Register</a>
                        </div> : <></>}
                    {nextEventProps.registration_link && nextEventProps.join_link ? 
                        <div className="col col-xs-12 col-sm-2"></div> : <></>}
                    {nextEventProps.join_link ? 
                        <div className="col col-xs-12 col-sm-5">
                            <a href={nextEventProps.join_link}
                                title="Join" target="_blank" rel="noopener noreferrer"
                                className="btn btn-primary btn-image-text nowrap"
                            >Join</a>
                        </div> : <></>}
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="center">
                &nbsp;
                <h4>No New Events Planned Yet</h4>
                &nbsp;
            </div>
        );
    }
};


NextEvent.propTypes = {
    /**
     * What tags to use to sort out the relevant events.
     */
    tags: PropTypes.string,
    /**
     * What events are used to search through.
     */
    allEvents: PropTypes.array,
};

NextEvent.defaultProps = {
    tags: "",
    allEvents: [],
};

export default NextEvent;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ValueImage from '../css/images/Home-Value-Collaboration.jpg';

class HomeValueSectionCollaboration extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Shared Projects'
            });
        }
    };

    render() {
        return (
            <>
                <div id="collaboration" className="row">
                    <div className="col col-xs-12">
                        <div className="panel center-content row home-value collaboration">
                            <div className="col col-xs-12 col-sm-4  vertical-center">
                                <img className="value-image" src={ValueImage} alt="Collaboration"/>
                            </div>
                            <div className="col col-xs-12 col-sm-8">
                                <h2>
                                    Bring people together, no matter what silo or state they live in.
                                </h2>
                                <p>
                                    Combine the power of collaboration with the efficiency of a
                                    digital workspace and sprinkle in some powerful data science
                                    tools to accelerate solutions from ideas to commercial
                                    implementation.
                                </p>

                                <button
                                    type="button"
                                    className="btn btn-training btn-arrow table uppercase pull-right mt-1 mb-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Learn More >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

HomeValueSectionCollaboration.propTypes = {
    onClickFunc: PropTypes.func
};

export default HomeValueSectionCollaboration;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import {populatePersonalProjectUserConfig} from '../utils/projectFunctions';
import APIUser from '../utils/APIUser';

class UserDashboard extends Component {

    render() {
        const {
            keycloak,
            keycloakReady,
            project_config,
            project_config_user,
            tokens,
            user
        } = this.props;

        const isAuth = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        const userConfig = populatePersonalProjectUserConfig(project_config, project_config_user);

        return (
            <>
                <div className="content user">
                    <h1 className="page-title">
                        My Profile
                    </h1>
                    {user.name &&
                    <span className="welcome-user">
                        Welcome, {user.name}
                    </span>
                    }

                    {/* User Profile */}
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <h3 className="header">Overview</h3>
                                <br/>

                                <div className="panel no-border mb-0">
                                    <table className="x-table all-row-color">
                                        <tbody>
                                            <tr>
                                                <td className="right">Username:</td>
                                                <td>{user.preferred_username}</td>
                                            </tr>
                                            <tr>
                                                <td className="right">Email Address:</td>
                                                <td>{user.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="right">Persona:</td>
                                                <td>{user.persona.name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="panel no-border ml-1 mr-1 mb-0">
                                    <div className="row ml-0">
                                        <div className="col col-xs-6 col-md-3 user-config">
                                            <h4>Number of Projects</h4>
                                            <p className="user-config-desc">
                                                The maximum number of Personal Projects you may create.
                                            </p>
                                            <p className="user-config-value">
                                                {userConfig.projects}
                                            </p>
                                        </div>
                                        <div className="col col-xs-6 col-md-3 user-config">
                                            <h4>Max Number of Shares</h4>
                                            <p className="user-config-desc">
                                                The maximum number of times you may share Projects with other users.
                                            </p>
                                            <p className="user-config-value">
                                                {userConfig.max_shares}
                                            </p>
                                        </div>
                                        <div className="col col-xs-6 col-md-3 user-config">
                                            <h4>Max Dataset Size</h4>
                                            <p className="user-config-desc">
                                                The maximum size (in GB) of Datasets and their files you may possess.
                                            </p>
                                            <p className="user-config-value">
                                                {userConfig.max_dataset_size} GB
                                            </p>
                                        </div>
                                        <div className="col col-xs-6 col-md-3 user-config">
                                            <h4>Max Number of Volumes</h4>
                                            <p className="user-config-desc">
                                                The maximum number of versioned volumes of a Dataset you may create.
                                            </p>
                                            <p className="user-config-value">
                                                {userConfig.max_volumes}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-btns">
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    {user.is_sysop &&
                    <>
                        {/* Administration */}
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="panel">
                                    <h3 className="header">Administration</h3>
                                    <br/>
                                    <p>
                                        Manage Organization details, Challenges and Datasets created for
                                        Organizations, and Project Configuration.
                                    </p>
                                    <div className="form-btns">
                                        <Link to="/x/admin"
                                            title="Admin"
                                            className="btn btn-primary"
                                        >
                                            Admin
                                        </Link>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
                {keycloakReady && isAuth &&
                <APIUser user={user}/>
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        project_config: state.project_config,
        project_config_user: state.project_config_user,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

UserDashboard.propTypes = {
    keycloak: PropTypes.any,
    keycloakReady: PropTypes.any,
    project_config: PropTypes.any,
    project_config_user: PropTypes.any,
    tokens: PropTypes.any,
    user: PropTypes.any,
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps
        )(UserDashboard)
    )
);

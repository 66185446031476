import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resourcesEvent';
import {setEventConfigCurrent} from '../../store/actions';
import {makePostDataRequest} from '../../utils/postRequest';
import {filterPassedTime} from '../../utils/projectFunctions';
import BaseInput from '../fields/BaseInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import {EVENT_TABS} from '../../data/formTabs';
import 'react-datepicker/dist/react-datepicker.min.css';
import SelectInput from "../fields/SelectInput";

const now = new Date().getTime();
const fifteenMin = (1000 * 60 * 15);

/**
 * Form: Add Event
 *
 * @param basePath
 * @param formStage
 * @param onFormEvent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormEventAdd = (
    {
        formStage,
        onFormEvent,
        basePath = '',
        ...props
    }) => {

    // Set up form and state
    const {register, errors, formState, getValues, handleSubmit} = useForm({mode: 'onBlur'});

    // State: Form Stage (from parent)
    const [formStageState, updateFormStageState] = useState(
        {
            stage: formStage
        }
    );
    if (formStageState.stage !== formStage) {
        updateFormStageState({...formStageState, stage: formStage});
    }

    // State: Due Date Object
    const [startTime, setStartTime] = useState(now);
    const [endTime, setEndTime] = useState(now + fifteenMin);
    const [minEndTime, setMinEndTime] = useState(now + fifteenMin);
    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [tabState, setTab] = useState(EVENT_TABS.overview);
    // State: Created Event
    const [eventId, setEventId] = useState('');

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            getValues('long_desc') === '' ||
            getValues('name') === '' ||
            getValues('short_desc') === '' ||
            getValues('start_time') === '' ||
            tabState.tab === 'saving' ||
            (errors &&
                (
                    errors.start_time ||
                    errors.end_time ||
                    errors.short_desc ||
                    errors.long_desc ||
                    errors.name

                ))
        );
    };

    // Handle change from specific form fields
    const handleFormChange = (e) => {
        if (e.name === 'start_time') {
            const sTime = (e.value) ? e.value.getTime() : '';
            if (sTime) {
                // Set a minimum End Time
                const minEnd = sTime + fifteenMin;
                setEndTime(minEnd)
                setMinEndTime(minEnd);
                setStartTime(sTime);
            } else {

            }
        } else if (e.name === 'end_time') {
            const eTime = (e.value) ? e.value.getTime() : '';
            if (eTime) {
                setEndTime(eTime);
            }
        }
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            data.start_time = parseInt(data.start_time, 10);
            data.end_time = parseInt(data.end_time, 10);
            data.private = (data.private.toLowerCase() === 'true');

            // console.log('SUBMIT data args: ', data);
            // Submit form to API
            setTab(EVENT_TABS.saving);
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'EventPersonal', 'CREATE', data);
        let processed = false;
        request.then(result => {
            let error = null;
            let errorMessage = null;
            if (result?.data?.error) {
                error = result.data.error;
                errorMessage = result.data.message;
            } else if (result?.error) {
                error = result.error;
                errorMessage = result.message;
            }
            if (result.id && !error) {
                updateSuccessState({success: true, message: ''});
                setTab(EVENT_TABS.success);
                setEventId(result.id);
                setPostEvent(result);
                processed = true;
                return Promise.resolve(result);
            } else {
                const message = (errorMessage) ? ' Error: "' + errorMessage + '"' : '';
                updateSuccessState({success: false, message: 'Sorry, your Event could not be created.' + message});
                setTab(EVENT_TABS.fail);
                processed = true;
                return Promise.resolve();
            }
        }).catch(error => {
            if (!processed) {
                updateSuccessState({success: false, message: 'Sorry, there was an error with your Event: ' + error});
                setTab(EVENT_TABS.fail);
                return Promise.reject('Server Error');
            }
        });
    };

    // Process after Event form completion
    const setPostEvent = (data) => {
        if (onFormEvent !== null && typeof onFormEvent === 'function') {
            // Notify the page that Event has been created
            onFormEvent('event', 'complete', data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                {/* Form Menu */}
                <div className="item-menu">
                    <ul>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(EVENT_TABS.overview)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Overview
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="item-content">

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(tabState.tab === 'overview') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter basic information for your Event.
                        </p>

                        <div className="form-group">
                            {/* Event Name */}
                            <BaseInput
                                type="text"
                                id="event-name"
                                name="name" label="Event Name" placeholder="Name of Event"
                                aria-describedby="error-name-required error-name-minLength error-name-maxLength"
                                aria-invalid={errors.name ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Name is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Name must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 80,
                                        message: (<FormError id="maxLength"
                                                             text="Name cannot be longer than 80 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.name ? 'input-error' : ''}`}
                            >
                                {errors.name && errors.name.message}
                            </BaseInput>
                        </div>
                        <p className="instructions">
                            Enter short and long Event descriptions. Markdown may be used in the long
                            description, but no HTML is allowed.
                        </p>
                        <div className="form-group">
                            {/* Short Description */}
                            <TextAreaInput
                                id="event-short-desc"
                                rows={2} cols={50}
                                name="short_desc" label="Short Description"
                                aria-describedby="error-short_desc-required"
                                aria-invalid={errors.short_desc ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Short Description is required"/>)
                                })}
                                required
                                className={`form-input ${errors.short_desc ? 'input-error' : ''}`}
                            >
                                {errors.short_desc && errors.short_desc.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Long Description */}
                            <TextAreaInput
                                id="event-long-desc"
                                rows={4} cols={50}
                                name="long_desc" label="Long Description"
                                aria-describedby="error-long_desc-required"
                                aria-invalid={errors.long_desc ? 'true' : 'false'}
                                ref={register}
                                required
                                className={`form-input ${errors.long_desc ? 'input-error' : ''}`}
                            >
                                {errors.long_desc && errors.long_desc.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Start Time */}
                            <label htmlFor="event-start-time">
                                <div>Start Time <span className="required">*</span></div>
                                <DatePicker
                                    id="event-start-time"
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    minDate={new Date()}
                                    selected={startTime}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    timeIntervals={15}
                                    onChange={(e) => handleFormChange({name: 'start_time', value: e})}
                                    popperPlacement="right"
                                    ref={register}
                                    aria-describedby="error-start_time-required"
                                    aria-invalid={errors.start_time ? 'true' : 'false'} required
                                    className={`form-input ${errors.start_time ? 'input-error' : ''}`}
                                    style={{display: 'block'}}
                                />
                                {errors.start_time && errors.start_time.message &&
                                    <FormError id="required" text="A Start Time is required"/>
                                }
                                <input type="hidden" name="start_time" value={startTime} ref={register}/>
                            </label>

                            {/* End Time */}
                            <label htmlFor="event-end-time">
                                <div>End Time <span className="required">*</span></div>
                                <DatePicker
                                    id="event-end-time"
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    minDate={minEndTime}
                                    selected={endTime}
                                    showTimeSelect
                                    filterTime={filterPassedTime}
                                    timeIntervals={15}
                                    onChange={(e) => handleFormChange({name: 'end_time', value: e})}
                                    popperPlacement="right"
                                    ref={register}
                                    aria-describedby="error-end_time-required"
                                    aria-invalid={errors.end_time ? 'true' : 'false'} required
                                    className={`form-input ${errors.end_time ? 'input-error' : ''}`}
                                    style={{display: 'block'}}
                                />
                                {errors.end_time && errors.end_time.message &&
                                    <FormError id="required" text="An End Time is required"/>
                                }
                                <input type="hidden" name="end_time" value={endTime} ref={register}/>
                            </label>
                        </div>
                        <div className="form-group row-2">
                            <SelectInput
                                id="event-private"
                                label="Private"
                                name="private"
                                ref={register}
                                options={[
                                    {name: 'True', value: true},
                                    {name: 'False', value: false}
                                ]}
                            />
                        </div>
                        <p className="instructions">
                            When all information is completed, click <em>Add Event</em> to Save the Event.
                        </p>
                    </div>

                    {/* Tab: Confirm && Save */}

                    <div className={`tab-content ${(tabState.tab === 'confirm') ? 'active' : ''}`}>
                        <p className="instructions">
                            Save your Event.
                        </p>
                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(tabState.tab === 'saving') ? 'active' : ''}`}>
                        <h4>Saving Event...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(tabState.tab === 'success') ? 'active' : ''}`}>
                        <h4>Event Saved</h4>
                        <p>
                            Your Event has been saved.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(tabState.tab === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                            <p className="message">
                                {successState.message}
                            </p>
                        }
                        {successState.message === '' &&
                            <p className="message">
                                Sorry, there was an issue saving this Event.
                            </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        <div className="tab-nav">
                            {tabState.tab === 'overview' &&
                                <>
                                    <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                        Add Event
                                    </button>
                                </>
                            }
                            {tabState.tab === 'success' &&
                                <>
                                    <Link className="btn btn-primary"
                                          to={`${basePath}/x/connect/event/personal/${eventId}/overview`}>
                                        View Event Details
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = {
    setEventConfigCurrent: setEventConfigCurrent
};

export default connect(
    null,
    mapDispatchToProps
)(FormEventAdd);

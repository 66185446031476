import React, {useEffect, useMemo, useState} from 'react';
import {useTable} from 'react-table';
import {timeSince} from '../utils/dataFunctions';
import APICluster from '../utils/APICluster';

/**
 * Assemble User Avatars for a table row
 *
 * @param users
 * @return {JSX.Element|*}
 */
const createUserAvatars = (users) => {
    if (!users || !users.length) {
        return (
            <span>
            </span>
        )
    }
    if (users.length > 5) {
        users.length = 5;
    }
    return (
        users.map((user, index) => (
            <span className="avatar" key={`avatar-${user.id}-${index}`}>
                <img src={user.avatar} alt={user.name}/>
            </span>
        ))
    )
};

/**
 * Component that displays Discourse Category Topics in a React Table with optional API
 *
 * @param discourseId
 * @param clusterList
 * @param height
 * @return {JSX.Element}
 * @constructor
 */
const ClusterTable = ({discourseId, clusterList = null, height = 'auto'}) => {

    const [topicsList, setTopicsList] = useState(clusterList ?? []);

    useEffect(
        () => {
            if (clusterList && clusterList.length > 0) {
                setTopicsList(clusterList)
            }
        }, [clusterList]
    );

    const handleData = (list) => {
        setTopicsList(topicsList => [...topicsList, ...list])
    };

    // Set table data
    const tableData = useMemo(() => topicsList, [topicsList]);

    // Set table columns
    const columns = React.useMemo(
        () => [
            {
                Header: 'Topic',
                accessor: 'title',
                Cell: ({row}) => (<span><a
                    href={row.original.link} target="_blank" title={row.original.title}
                    rel="noopener noreferrer">{row.original.title}</a></span>),
                className: 'topic th'
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({row}) => (
                    <span className="avatars">
                        {[...createUserAvatars(row.original.posters)]}
                    </span>
                ),
                className: 'avatars-td right'
            }, {
                Header: 'Posts',
                accessor: 'posts_count',
                width: 80,
                maxWidth: 80,
                className: 'center'
            },
            {
                Header: 'Views',
                accessor: 'views',
                width: 80,
                maxWidth: 80,
                className: 'center'
            },
            {
                Header: 'Activity',
                accessor: 'last_posted_at',
                Cell: ({row}) => (<span>{timeSince(row.original.activity_date)}</span>),
                className: 'center'
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({columns, data: tableData});

    return (
        <>
            <div className="x-table-container" style={{height: height}}>
                <table {...getTableProps()} className="x-table discourse topics">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps([{
                                    className: column.className,
                                    style: column.style
                                }])}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps([{
                                            className: cell.column.className,
                                            style: cell.column.style
                                        }])}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {!clusterList && discourseId &&
            <APICluster discourseId={discourseId} onResult={(e) => handleData(e)}/>
            }
        </>
    )
};

export default ClusterTable;

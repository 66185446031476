import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {filterListByValue, sortListByValue} from '../../utils/filterFunctions';
import {setEventsList} from '../../utils/projectFunctions';
import APIEvents from '../../utils/APIEvents';
import {Select, Header, Input} from '../../partials/filters/ChallengeFilterComponents';
import '../../css/challenge.css';
import '../../css/search-filter.css';

/**
 * Set current list type based on Event route
 *
 * @param path
 * @returns {string}
 */
const setListType = (path) => {
    if (path.indexOf('events/personal') !== -1) {
        return 'events-personal-own';
    } else if (path.indexOf('events/public') !== -1) {
        return 'events-public';
    } else if (path.indexOf('events/sharing') !== -1) {
        return 'events-shared';
    } else if (path.indexOf('events/subscribed') !== -1) {
        return 'events-subscribed';
    }
    return 'events-personal-own';
};

/**
 * Set page title based on type of Events requested
 *
 * @param listType
 * @return {string}
 */
const setEventPageTitle = (listType) => {
    if (listType === 'events-personal-own') {
        return 'My Personal Events';
    } else if (listType === 'events-public') {
        return 'Public Events';
    } else if (listType === 'events-shared') {
        return 'Events Shared with You';
    } else if (listType === 'events-subscribed') {
        return 'Joined Events';
    }
    return 'Events';
};

const sortTypes = {
    // latest: 'start_time',
    alphabetically: 'name',
}

/**
 * Page: Events List (Personal (Own), (Joined), Shared (Joined))
 */
class XConnectEvents extends Component {
    constructor(props) {
        super(props);

        const listType = setListType(props.location.pathname);
        const pageTitle = setEventPageTitle(listType);
        this.state = {
            active: true,
            filteredList: [],
            listTotals: {
                eventJoined: 0,
                eventOwn: 0,
                eventPersonal: 0,
                eventShared: 0,
                eventSubscribed: 0
            },
            listType: listType,
            mainList: [],
            pageTitle: pageTitle,
            pathname: '',
            eventList: [],
            publicEventTotal: 0,
            resetList: true,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        };
    }

    componentDidMount() {
        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.resetList === true) {
            this.setMainList();
            this.setReset(false);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // Update state on location change
        if (nextProps.location.pathname !== prevState.pathname) {
            const listType = setListType(nextProps.location.pathname);
            const pageTitle = setEventPageTitle(listType);
            if (document.getElementById('searchHeader')) {
                document.getElementById('searchHeader').reset();
            }

            return {
                filteredList: [],
                listType: listType,
                pageTitle: pageTitle,
                pathname: nextProps.location.pathname,
                eventList: [],
                resetList: true,
                search: {
                    value: ''
                },
                sort: {
                    value: 'alphabetically'
                }
            };
        }

        // Reset current list if stored totals have changed or populated
        if (
            prevState.listTotals.eventSubscribed !== nextProps.event_list_subscribed.length
        ) {
            return {
                listTotals: {
                    eventSubscribed: nextProps.event_list_subscribed.length
                },
                resetList: true
            }
        }

        return null;
    }

    // Handle on-page Events API results (instead of stored results)
    handleEventListResult = (data) => {
        this.setState({
            eventList: data,
            resetList: false,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        }, () => {
            this.setMainList();
        });
    };

    // Handle event from Filter By Event Name
    setSearch = (value) => {
        this.setState({
            search: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    // Handle event from Active Filter
    setActive = (value) => {
        this.setState({
            active: (value === 'active'),
            resetList: true,
            search: {
                value: ''
            },
            sort: {
                value: 'alphabetically'
            }
        });
    };

    // Handle event from Sort By filter
    setSort = (value) => {
        this.setState({
            sort: {value: value}
        }, () => {
            this.filterAndSortList();
        });
    }

    setReset = (value) => {
        this.setState({
            resetList: value
        }, () => {
            this.filterAndSortList();
        });
    }

    // Populate page list based on listType
    setMainList = () => {
        let mainList;
        switch (this.state.listType) {
            case 'events-personal-own':
                mainList = this.state.eventList;
                break;
            case 'events-subscribed':
                mainList = this.props.event_list_subscribed;
                break;
            default:
                mainList = this.state.eventList;
                break;
        }
        this.setState({
            mainList: mainList
        }, () => {
            this.filterAndSortList();
        })
    }

    filterAndSortList = () => {
        let tempList = JSON.parse(JSON.stringify(this.state.mainList));
        if (tempList && tempList.length > 0) {
            // Apply Text Filter
            tempList = filterListByValue(tempList, this.state.search?.value?.toLowerCase(), ['name', 'short_desc']);

            // Apply sorting
            tempList = sortListByValue(tempList, this.state.sort?.value, sortTypes);
        }
        this.setState({
            filteredList: tempList
        })
    }

    render() {
        const {
            event_list_subscribed
        } = this.props;

        const {
            active,
            eventList,
            filteredList,
            listType,
            pageTitle,
            resetList,
            search,
            sort
        } = this.state;

        return (
            <>
                <div className="content xconnect">
                    <h1 className="page-title">
                        {pageTitle}
                    </h1>
                    <form id="searchHeader">
                        <Header>
                            <Input
                                placeholder="Filter by Event Name"
                                onKeyUp={e => this.setSearch(e.target.value)}
                                className="control-search"
                            />
                            <div className="nowrap">
                                <Select
                                    values={[
                                        "Alphabetically",
                                        // "Latest"
                                    ]}
                                    onChange={e => this.setSort(e.target.value)}
                                    className="control-sort"
                                />
                                <div className="control-label">
                                    Sort By:
                                </div>
                            </div>
                        </Header>
                    </form>

                    {/* Row: Personal Events (Own) */}
                    {listType === 'events-personal-own' &&
                        <div className="row">
                            {[...setEventsList(listType, filteredList, eventList, 'No Personal Events found.')]}
                        </div>
                    }

                    {/* Row: Public Events */}
                    {listType === 'events-public' &&
                        <div className="row">
                            {[...setEventsList(listType,filteredList, eventList, 'No Public Events found.')]}
                        </div>
                    }

                    {/* Row: Events shared with User */}
                    {listType === 'events-shared' &&
                        <div className="row">
                            {[...setEventsList(listType, filteredList, event_list_subscribed, 'No Events have been shared with you, or you have joined all Events.')]}
                        </div>
                    }

                    {/* Row: Events joined by User */}
                    {listType === 'events-subscribed' &&
                        <div className="row">
                            {[...setEventsList(listType, filteredList, event_list_subscribed, 'You have not yet joined any Events.')]}
                        </div>
                    }
                </div>

                {listType === 'events-personal-own' &&
                    <APIEvents
                        getType="list"
                        viewType="personal_own"
                        params={{active: active}}
                        refresh={resetList}
                        storeResults={false}
                        onResult={(e) => this.handleEventListResult(e)}
                    />
                }
                {listType === 'events-public' &&
                    <APIEvents
                        getType="list"
                        viewType="personal_public"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: (search.value !== '') ? search.value : '',
                            sort: 'ASC',
                            sortBy: (sort.value === 'alphabetically') ? 'name' : 'start_time'
                        }}
                        refresh={resetList}
                        storeResults={false}
                        onResult={(e) => this.handleEventListResult(e)}
                    />
                }
                {listType === 'events-shared' &&
                    <APIEvents
                        getType="list"
                        viewType="shared"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: (search.value !== '') ? search.value : '',
                            sort: 'ASC',
                            sortBy: (sort.value === 'alphabetically') ? 'name' : 'start_time'
                        }}
                        refresh={resetList}
                        storeResults={true}
                        onResult={(e) => this.handleEventListResult(e)}
                    />
                }
                {listType === 'events-subscribed' &&
                    <APIEvents
                        getType="list"
                        viewType="subscribed"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: (search.value !== '') ? search.value : '',
                            sort: 'ASC',
                            sortBy: (sort.value === 'alphabetically') ? 'name' : 'start_time'
                        }}
                        refresh={resetList}
                        storeResults={true}
                        onResult={(e) => this.handleEventListResult(e)}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        event_list_own: state.event_list_own,
        event_list_public: state.event_list_public,
        event_list_subscribed: state.event_list_subscribed
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(XConnectEvents)
);

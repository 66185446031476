import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {copyArray, dateIsPast, sortArrayByKey} from '../utils/dataFunctions';
import {inUserList} from '../utils/projectFunctions';
import ProjectItem from '../partials/ProjectItem';

/**
 * A component to assemble and display Project result items by Due Date
 */
class DashboardProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectList: []
        };
    }

    componentDidMount() {
        this.assembleProjectList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(prevProps.project_list_invited) !== JSON.stringify(this.props.project_list_invited) ||
            JSON.stringify(prevProps.project_list_own) !== JSON.stringify(this.props.project_list_own) ||
            JSON.stringify(prevProps.project_list_subscribed) !== JSON.stringify(this.props.project_list_subscribed)
        ) {
            this.assembleProjectList();
        }
    }

    /**
     * Add Projects that have not expired to list
     *
     * @param list
     * @param newList
     * @returns {*}
     */
    addProjectsToList = (list, newList) => {
        for (let i = 0; i < newList.length; i++) {
            if (list.findIndex(r => r.id === newList[i].id) === -1) {
                const dueDate = newList[i].due_date;
                if (dueDate) {
                    if (!dateIsPast(new Date(dueDate))) {
                        list.push(newList[i]);
                    }
                }
            }
        }
        return list;
    };

    /**
     * Assemble list of invited, joined and owned Projects by Due Date
     */
    assembleProjectList = () => {
        const onComplete = this.props.onComplete;
        let invited = copyArray(this.props.project_list_invited);
        let joined = copyArray(this.props.project_list_subscribed);
        let own = copyArray(this.props.project_list_own);
        // Combine all Project arrays
        let sorted = [];
        sorted = this.addProjectsToList(sorted, invited);
        sorted = this.addProjectsToList(sorted, joined);
        sorted = this.addProjectsToList(sorted, own);
        sorted = sortArrayByKey(sorted, 'due_date', 'ASC');
        // Keep the latest two
        if (sorted.length > 2) {
            sorted.length = 2;
        }
        this.setState({
            projectList: sorted
        });
        if (typeof onComplete === 'function') {
            onComplete(sorted);
        }
    };

    /**
     * Set pair of Project result cards
     *
     * @param list
     * @returns {*}
     */
    setProjectItems = (list) => {
        return (
            list.map((item, index) => (
                <div className="col col-sm-12 col-md-6" key={`project-${item.id}-${index}`}>
                    <ProjectItem
                        projectItem={item}
                        inInvitedProjects={(inUserList(this.props.project_list_invited, item.id))}
                        inOwnProjects={(inUserList(this.props.project_list_own, item.id))}
                        inUserOrOwnProjects={
                            (
                                inUserList(this.props.project_list_own, item.id) ||
                                inUserList(this.props.project_list_subscribed, item.id)
                            )
                        }
                        progress={true}
                        clickFunc={null}
                        clickLink={`/x/hub/project/personal/${item.id}/overview`}
                    />
                </div>
            ))
        )
    };

    render() {
        const {
            projectList
        } = this.state;

        return (
            <>
                {projectList.length > 0 &&
                <div className="col col-xs-12 col-sm-6">
                    <h3 className="section-header no-underline">Projects</h3>
                    <div className="row">
                        {[...this.setProjectItems(projectList)]}
                    </div>
                    <div className="form-btns center">
                        <Link className="btn btn-primary center" title="Personal Projects"
                              to="/x/hub/projects/personal">
                            Show More
                        </Link>
                    </div>
                </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_list_invited: state.project_list_invited,
        project_list_own: state.project_list_own,
        project_list_subscribed: state.project_list_subscribed
    };
};

DashboardProjects.propTypes = {
    onComplete: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps
    )(DashboardProjects)
);

/**
 * Public Page navigation (shared)
 *
 */
export const publicNav = [
    {
        title: 'About Us',
        slug: 'nav-public-about',
        route: '/x/about',
        substrateClass: 'connect',
        icon: '',
    },
    {
        title: 'Substrates',
        slug: 'nav-public-substrates',
        route: '/x/substrates',
        substrateClass: 'connect',
        icon: '',
    },
    {
        title: 'Use Cases',
        slug: 'nav-public-use-cases',
        route: '/x/use-cases',
        substrateClass: 'connect',
        icon: '',
    },
    {
        title: 'Book A Call',
        slug: 'nav-public-book-call',
        func: 'openHelpWidget',
        route: '/',
        substrateClass: 'connect',
        icon: '',
    },
];

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resourcesProject';
import {setProjectConfigCurrent} from '../../store/actions';
import {makePostDataRequest} from '../../utils/postRequest';
import {createDateTimeObject} from '../../utils/dataFunctions';
import {setDueDate} from '../../utils/projectFunctions';
import BaseInput from '../fields/BaseInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import {PROJECT_TABS} from '../../data/formTabs';
import 'react-datepicker/dist/react-datepicker.min.css';

/**
 * Form: Add Project
 *
 * @param basePath
 * @param formStage
 * @param onFormEvent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormProjectAdd = (
    {
        formStage,
        onFormEvent,
        basePath = '',
        ...props
    }) => {

    // Set up form and state
    const {register, errors, formState, getValues, handleSubmit} = useForm({mode: 'onBlur'});

    // State: Form Stage (from parent)
    const [formStageState, updateFormStageState] = useState(
        {
            stage: formStage
        }
    );
    if (formStageState.stage !== formStage) {
        updateFormStageState({...formStageState, stage: formStage});
    }
    // Initial Form State
    const [fieldState, updateFieldState] = useState(
        {
            community_desc: '',
            due_date: createDateTimeObject(new Date()),
            goals: '',
            introduction: '',
            title: '',
            summary: ''
        }
    );
    // State: Due Date Object
    const [dueDateState, updateDueDateState] = useState(
        {
            min_date_obj: setDueDate('/'),
            due_date_obj: setDueDate('/').due_date
        }
    );
    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [tabState, setTab] = useState(PROJECT_TABS.overview);
    // State: Created Project
    const [projectId, setProjectId] = useState('');

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            getValues('introduction') === '' ||
            getValues('summary') === '' ||
            getValues('title') === '' ||
            tabState.tab === 'saving' ||
            (errors &&
                (
                    errors.introduction ||
                    errors.summary ||
                    errors.title
                ))
        );
    };

    // Handle change from specific form fields
    const handleFormChange = (e) => {
        if (e.name === 'due_date') {
            // Set a Due Date object
            const dateString = createDateTimeObject(e.value);
            updateFieldState({...fieldState, [e.name]: dateString});
            updateDueDateState({...dueDateState, due_date_obj: e.value});
        } else {
            updateFieldState({...fieldState, [e.name]: e.value});
        }
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // console.log('SUBMIT fieldState: ', fieldState);
            // console.log('SUBMIT data args: ', data);

            // Submit form to API
            setTab(PROJECT_TABS.saving);
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'ProjectPersonal', 'CREATE', data);
        let processed = false;
        request.then(result => {
            let error = null;
            let errorMessage = null;
            if (result?.data?.error) {
                error = result.data.error;
                errorMessage = result.data.message;
            } else if (result?.error) {
                error = result.error;
                errorMessage = result.message;
            }
            if (result.id && !error) {
                updateSuccessState({success: true, message: ''});
                setTab(PROJECT_TABS.success);
                setProjectId(result.id);
                // Store Project Configuration for Dataset
                props.setProjectConfigCurrent({
                    max_dataset_size: result.max_dataset_size ?? null,
                    max_shares: result.max_shares ?? null,
                    max_volumes: result.max_volumes ?? null,
                })
                setPostProject(result);
                processed = true;
                return Promise.resolve(result);
            } else {
                const message = (errorMessage) ? ' Error: "' + errorMessage + '"' : '';
                updateSuccessState({success: false, message: 'Sorry, your Project could not be created.' + message});
                setTab(PROJECT_TABS.fail);
                processed = true;
                return Promise.resolve();
            }
        }).catch(error => {
            if (!processed) {
                updateSuccessState({success: false, message: 'Sorry, there was an error with your Project: ' + error});
                setTab(PROJECT_TABS.fail);
                return Promise.reject('Server Error');
            }
        });
    };

    // Process after Project form completion
    const setPostProject = (data) => {
        if (onFormEvent !== null && typeof onFormEvent === 'function') {
            // Notify the page that Project has been created
            onFormEvent('project', 'complete', data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                {/* Form Menu */}
                <div className="item-menu">
                    <ul>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(PROJECT_TABS.overview)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Overview
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(PROJECT_TABS.information)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Information
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(PROJECT_TABS.confirm)}
                                    disabled={!!disableSubmit()}
                            >
                                Finalize
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="item-content">

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(tabState.tab === 'overview') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter basic information for your Project. Click <em>Next</em> to enter Description
                            information.
                        </p>

                        <div className="form-group">
                            {/* Title */}
                            <BaseInput
                                type="text"
                                id="project-title"
                                name="title" label="Title" placeholder="Title of Project"
                                instructions="Note: Project titles must be unique."
                                aria-describedby="error-title-required error-title-minLength error-title-maxLength"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Title is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Title must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 80,
                                        message: (<FormError id="maxLength"
                                                             text="Title cannot be longer than 80 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Goals */}
                            <TextAreaInput
                                id="project-goals"
                                rows={3} cols={50}
                                name="goals" label="Project Goals"
                                ref={register}
                            >
                            </TextAreaInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Due Date */}
                            <label htmlFor="project-due-date">
                                <div>Due Date</div>
                                <DatePicker
                                    id="project-due-date"
                                    minDate={dueDateState.min_date_obj.due_date}
                                    selected={dueDateState.due_date_obj}
                                    onChange={(e) => handleFormChange({name: 'due_date', value: e})}
                                    dateFormat="MMMM d, yyyy"
                                    popperPlacement="right"
                                    style={{display: 'block'}}
                                />
                                <input type="hidden" name="due_date" value={fieldState.due_date} ref={register}/>
                            </label>
                        </div>
                    </div>

                    {/* Tab: Information */}

                    <div className={`tab-content ${(tabState.tab === 'information') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter Project descriptive data. Markdown may be used, but no HTML is allowed. When all
                            information is completed, click <em>Next</em> to confirm and Save the Project.
                        </p>
                        <div className="form-group">
                            {/* Summary */}
                            <TextAreaInput
                                id="project-summary"
                                rows={4} cols={50}
                                name="summary" label="Summary"
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.summary ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Summary is required"/>)
                                })}
                                required
                                className={`form-input ${errors.summary ? 'input-error' : ''}`}
                            >
                                {errors.summary && errors.summary.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Introduction */}
                            <TextAreaInput
                                id="project-introduction"
                                rows={6} cols={50} maxLength={255}
                                name="introduction" label="Introduction"
                                aria-describedby="error-introduction-required"
                                aria-invalid={errors.introduction ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="An Introduction is required"/>)
                                })}
                                required
                                className={`form-input ${errors.introduction ? 'input-error' : ''}`}
                            >
                                {errors.introduction && errors.introduction.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Community Description */}
                            <TextAreaInput
                                id="project-community-description"
                                rows={6} cols={50}
                                name="community_desc" label="Community Description"
                                ref={register}
                                className="form-input"
                            >
                            </TextAreaInput>
                        </div>
                    </div>

                    {/* Tab: Confirm && Save */}

                    <div className={`tab-content ${(tabState.tab === 'confirm') ? 'active' : ''}`}>
                        <p className="instructions">
                            Save your Project. Once the Project is saved, you will be able to upload a Dataset
                            associated with this Project if desired.
                        </p>
                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(tabState.tab === 'saving') ? 'active' : ''}`}>
                        <h4>Saving Project...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(tabState.tab === 'success') ? 'active' : ''}`}>
                        <h4>Project Saved</h4>
                        <p>
                            Your Project has been saved. If this Project needs a Dataset, add one below. You can add a
                            Dataset at any time by viewing a Project and
                            clicking <strong>Edit Dataset</strong>.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(tabState.tab === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                        <p className="message">
                            {successState.message}
                        </p>
                        }
                        {successState.message === '' &&
                        <p className="message">
                            Sorry, there was an issue saving this Project.
                        </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        <div className="tab-nav">
                            {tabState.tab === 'overview' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Next"
                                        onClick={() => setTab(PROJECT_TABS.information)}
                                >
                                    Next
                                </button>
                            </>
                            }
                            {tabState.tab === 'information' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Back"
                                        onClick={() => setTab(PROJECT_TABS.overview)}
                                >
                                    Back
                                </button>
                                <button type="button" className="btn btn-clear" title="Next"
                                        onClick={() => setTab(PROJECT_TABS.confirm)}
                                        disabled={!!disableSubmit()}
                                >
                                    Next
                                </button>
                            </>
                            }
                            {tabState.tab === 'confirm' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Back"
                                        onClick={() => setTab(PROJECT_TABS.information)}
                                >
                                    Back
                                </button>
                                <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                    Add Project
                                </button>
                            </>
                            }
                            {tabState.tab === 'success' &&
                            <>
                                <Link className="btn btn-primary"
                                      to={`${basePath}/x/hub/project/personal/${projectId}/overview`}>
                                    View Project Details
                                </Link>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapDispatchToProps = {
    setProjectConfigCurrent: setProjectConfigCurrent
};

export default connect(
    null,
    mapDispatchToProps
)(FormProjectAdd);
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

class HomeSubstrate extends Component {

    handleMouseOver = (e) => {
        if (typeof this.props.onMouseOverFunc === 'function') {
            this.props.onMouseOverFunc(this.props.type);
        }
    };

    handleMouseOut = (e) => {
        if (typeof this.props.onMouseOutFunc === 'function') {
            this.props.onMouseOutFunc(null);
        }
    };

    render() {
        const {
            active,
            allowHover,
            content,
            link,
            type
        } = this.props;

        const activeClass = (allowHover && active) ? 'active' : '';
        const allowHoverClass = (allowHover) ? 'allow-hover' : '';

        if (link) {
            return (
                <Link to={link}
                      title={content.name}
                      className={`substrate ${type} ${activeClass} ${allowHoverClass}`}
                      onMouseOver={(e) => this.handleMouseOver(e)}
                      onMouseOut={(e) => this.handleMouseOut(e)}
                >
                    <span className={`name color-x` + type}>{content.name}</span>
                    <span className="description-short">{content.description_short}</span>
                    <span className="description">{content.description}</span>
                </Link>
            )
        }

        return (
            <span
                className={`substrate ${type} ${activeClass}`}
                onMouseOver={(e) => this.handleMouseOver(e)}
                onMouseOut={(e) => this.handleMouseOut(e)}
            >
                <span className={`name color-x` + type}>{content.name}</span>
                <span className="description-short">{content.description_short}</span>
                <span className="description">{content.description}</span>
            </span>
        )
    }
}

HomeSubstrate.propTypes = {
    active: PropTypes.bool,
    allowHover: PropTypes.bool,
    content: PropTypes.object,
    link: PropTypes.string,
    onMouseOutFunc: PropTypes.func,
    onMouseOverFunc: PropTypes.func,
    type: PropTypes.string
};

HomeSubstrate.defaultProps = {
    active: false,
    allowHover: true,
    content: {
        name: '',
        description: '',
        description_short: ''
    },
    type: 'connect',
};

export default withRouter(HomeSubstrate);

import React, {Component} from 'react';

class ParticipationTerms extends Component {

    // @TODO

    render() {
        return (
            <>
                <div className="content legal privacy">
                    <h1 className="page-title legal">
                        Training and Challenge Participation Terms (the "Participation Terms")
                    </h1>
                    <p className="updated">
                        <strong>Last Updated</strong>: October 1, 2020
                    </p>

                </div>
            </>
        )
    }
}

export default ParticipationTerms;

import React, {Component} from 'react';
import '../../css/static.css';

class XHubPublish extends Component {
    render() {

        return (
            <>
                <div className="content publish">
                    <h1 className="page-title">
                        Publish
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Once available, the Publish section allows the submission of reviewed project
                                    assets for publication in the Marketplace, as well as solutions for Challenges
                                    and Training courses.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubPublish;

import React from 'react';
import ActiveIcon from '../../css/images/icon-Table-Active.svg';
import InactiveIcon from '../../css/images/icon-Table-Close.svg';

const TableBooleanField = ({active = false, name = 'Icon'}) => {
    return (
        <span className="btn-icon x-table-icon no-click">
            {active &&
                <img src={ActiveIcon} alt={`${name} True`}/>
            }
            {!active &&
                <img src={InactiveIcon} alt={`${name} True`}/>
            }
            </span>
    );
};

export default TableBooleanField;
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {openHelpWidget} from '../utils/pageFunctions';
import SocialIconLinkedInWhite from '../css/images/icon-Social-LinkedIn-white.png';
import SubdirectoryIconHub from '../css/images/icon-SubdirectoryHub.svg';
import SubdirectoryIconConnect from '../css/images/icon-SubdirectoryConnect.svg';
import SubdirectoryIconTraining from '../css/images/icon-SubdirectoryTraining.svg';
import XrathusLogoHorizontalReversed from '../partials/images/XrathusLogoHorizontalReversed';
import '../css/footer.css';

const todayFooter = new Date();
const copyrightYear = todayFooter.getFullYear();

class AppFooter extends Component {

    render() {
        const {
            sidebarOpen,
        } = this.props;

        const sidebarClass = (sidebarOpen) ? ' sidebar-open' : '';
        const displayEvents = false;

        return (
            <>
                <div className={`footer${sidebarClass}`}>
                    <div className="footer-content">
                        {/* Logo */}
                        <div className="footer-identity">
                            <XrathusLogoHorizontalReversed/>
                            <p>
                                1334 Brittmoore Road<br/>
                                Suite 1000C<br/>
                                Houston, TX 77043
                            </p>
                            <p>
                            </p>
                            <p>
                                <a href="mailto:info@xrathus.com" title="Email"
                                   target="_blank" rel="noopener noreferrer"
                                >
                                    info@xrathus.com
                                </a>
                            </p>
                        </div>

                        {/* Social Links and Menus */}
                        <div className="footer-menus">
                            <div className="social">
                                <span>Follow us on social media:</span>
                                <a href="https://www.linkedin.com/company/xrathus/"
                                   title="Xrathus on LinkedIn" target="_blank" rel="noopener noreferrer"
                                   className="btn-icon-social">
                                    <img src={SocialIconLinkedInWhite} alt="LinkedIn"/>
                                    LinkedIn
                                </a>
                            </div>

                            <div className="footer-menu-cols">
                                <div className="col col-footer">
                                    <ul className="nav-footer">
                                        <li>
                                            <Link to="/x/substrates" title="Substrates">
                                                <h4 className="footer-substrates">Xrathus Substrates</h4>
                                            </Link>
                                        </li>
                                        <li className="subpage footer-substrates">
                                            <img className="icon-subdirectory" src={SubdirectoryIconHub}
                                                 alt="Connect"/>
                                            <Link to="/x/substrates#connect" title="Substrate: Connect">
                                                Connect
                                            </Link>
                                        </li>
                                        <li className="subpage footer-substrates">
                                            <img className="icon-subdirectory" src={SubdirectoryIconHub}
                                                 alt="Hub"/>
                                            <Link to="/x/substrates#hub" title="Substrate: Hub">
                                                Hub
                                            </Link>
                                        </li>
                                        <li className="subpage footer-substrates">
                                            <img className="icon-subdirectory" src={SubdirectoryIconHub}
                                                 alt="Marketplace"/>
                                            <Link to="/x/substrates#marketplace" title="Substrate: Marketplace">
                                                Marketplace
                                            </Link>
                                        </li>
                                        <li className="subpage footer-substrates">
                                            <img className="icon-subdirectory" src={SubdirectoryIconHub}
                                                 alt="Training"/>
                                            <Link to="/x/substrates#training" title="Substrate: Training">
                                                Training
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col col-footer">
                                    <ul className="nav-footer">
                                        <li>
                                            <Link to="/x/use-cases" title="Use Cases">
                                                <h4 className="footer-use-cases">Use Cases</h4>
                                            </Link>
                                        </li>
                                        <li className="subpage footer-use-cases">
                                            <img className="icon-subdirectory" src={SubdirectoryIconTraining}
                                                 alt="Hackathon"/>
                                            <Link to="/x/use-cases#hackathon" title="Hackathon">
                                                Hackathon
                                            </Link>
                                        </li>
                                        <li className="subpage footer-use-cases">
                                            <img className="icon-subdirectory" src={SubdirectoryIconTraining}
                                                 alt="Open Project"/>
                                            <Link to="/x/use-cases#open-project" title="Open Project">
                                                Open Project
                                            </Link>
                                        </li>
                                        <li className="subpage footer-use-cases">
                                            <img className="icon-subdirectory" src={SubdirectoryIconTraining}
                                                 alt="Ideation"/>
                                            <Link to="/x/use-cases#ideation" title="Ideation">
                                                Ideation
                                            </Link>
                                        </li>
                                        <li className="subpage footer-use-cases">
                                            <img className="icon-subdirectory" src={SubdirectoryIconTraining}
                                                 alt="Collaboration"/>
                                            <Link to="/x/use-cases#collaboration" title="Collaboration">
                                                Collaboration
                                            </Link>
                                        </li>
                                        <li className="subpage footer-use-cases">
                                            <img className="icon-subdirectory" src={SubdirectoryIconTraining}
                                                 alt="Learning"/>
                                            <Link to="/x/use-cases#learning" title="Learning">
                                                Learning
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col col-footer">
                                    <ul className="nav-footer">
                                        <li>
                                            <Link to="/x/about" title="About Us">
                                                <h4 className="footer-about">About Us</h4>
                                            </Link>
                                        </li>
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="Our Why"/>
                                            <Link to="/x/about" title="Our Why">
                                                Our Why
                                            </Link>
                                        </li>
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="Our Team"/>
                                            <Link to="/x/about#team" title="Our Team">
                                                Our Team
                                            </Link>
                                        </li>
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="Collaborators"/>
                                            <Link to="/x/about#collaborators" title="Collaborators">
                                                Collaborators
                                            </Link>
                                        </li>
                                        {displayEvents &&
                                            <li className="subpage footer-about">
                                                <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                     alt="Events"/>
                                                <Link to="/#events" title="Events">
                                                    Events
                                                </Link>
                                            </li>
                                        }
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="News"/>
                                            <Link to="/x/about#news" title="News">
                                                News
                                            </Link>
                                        </li>
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="White Paper"/>
                                            <button
                                                className="link"
                                                onClick={() => {
                                                    window.open('/docs/Xrathus_WhitePaper.pdf');
                                                }}
                                                title="White Paper"
                                            >
                                                Whitepaper
                                            </button>
                                        </li>
                                        <li className="subpage footer-about">
                                            <img className="icon-subdirectory" src={SubdirectoryIconConnect}
                                                 alt="Careers"/>
                                            <Link to="/x/about#careers" title="Careers">
                                                Careers
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Row: Copyright, Additional links, Legal links */}
                    <div className="footer-content">
                        <div className="footer-copyright">
                            &copy; {copyrightYear} Xrathus, Inc.
                        </div>

                        <div className="footer-legal">
                            <div className="footer-menus">
                                <div className="footer-menu-cols">
                                    <div className="col col-footer">
                                        <ul className="nav-footer">
                                            <li>
                                                <Link to="/x/getting-started" title="Getting Started">
                                                    Getting Started
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/x/terms" title="Terms of Use">
                                                    Terms of Use
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col col-footer">
                                        <ul className="nav-footer">
                                            <li>
                                                <Link to="/x/faq" title="FAQ">
                                                    FAQs
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/x/privacy-policy" title="Privacy Policy">
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col col-footer">
                                        <ul className="nav-footer">
                                            <li>
                                                <button className="link" title="Support"
                                                        onClick={openHelpWidget}>
                                                    Support
                                                </button>
                                            </li>
                                            <li>
                                                <button className="link" title="Contact Us"
                                                        onClick={openHelpWidget}>
                                                    Contact Us
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    return {
        sidebarOpen: sidebarOpen,
    };
};

AppFooter.propTypes = {
    /**
     * Is sidebar open?
     */
    sidebarOpen: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(AppFooter)
);

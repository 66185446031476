import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Get Substrate and Widget ID from path
const getWidgetParams = (path) => {
    let substrate = 'General';
    let widget_id = 67000000652;

    if (path.indexOf('/x/connect') !== -1) {
        substrate = 'Connect';
        widget_id = 67000000653;
    } else if (path.indexOf('/x/hub') !== -1) {
        substrate = 'Hub';
        widget_id = 67000000654;
    } else if (path.indexOf('/x/training') !== -1) {
        substrate = 'Training';
        widget_id = 67000000655;
    } else if (path.indexOf('/x/marketplace') !== -1) {
        substrate = 'Marketplace';
        widget_id = 67000003568;
    }

    // @TODO override since multiple Widget IDs do work but
    // were specifically requested to be left as one
    //substrate = 'General';
    //widget_id = 67000000652;

    return {
        substrate: substrate,
        widget_id: widget_id
    };
};

/**
 * Class for rendering a support widget script
 *
 */
class SupportScript extends Component {
    constructor(props) {
        super(props);
        const widgetParams = getWidgetParams(props.path);
        this.state = {
            isAuthenticated: props.isAuthenticated,
            keycloakReady: props.keycloakReady,
            path: props.path,
            substrate: widgetParams.substrate,
            user: {
                name: props.user.name,
                email: props.user.email,
                preferred_username: props.user.preferred_username
            },
            widget_id: widgetParams.widget_id,
        };
    }

    componentDidMount() {
        if (this.state.keycloakReady && this.state.substrate && this.state.widget_id) {
            this.appendWindowScript();
            this.loadFWWidget();
            this.setFWSettings();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let path = prevState.path;
        let updateState = false;
        let user = {
            email: prevState.user.email,
            name: prevState.user.name,
            preferred_username: prevState.user.preferred_username,
        };
        let widgetParams = {
            substrate: prevState.substrate,
            widget_id: prevState.widget_id
        };

        if (nextProps.isAuthenticated !== prevState.isAuthenticated) {
            updateState = true;
        }

        if (nextProps.keycloakReady !== prevState.keycloakReady) {
            updateState = true;
        }

        // If path changes, reset widget parameters
        if (nextProps.path !== prevState.path) {
            path = nextProps.path;
            widgetParams = getWidgetParams(path);
            updateState = true;
        }

        if (nextProps.user.preferred_username !== prevState.user.preferred_username) {
            user = {
                email: nextProps.user.email,
                name: nextProps.user.name,
                preferred_username: nextProps.user.preferred_username,
            };
            updateState = true;
        }
        if (updateState) {
            return {
                isAuthenticated: nextProps.isAuthenticated,
                keycloakReady: nextProps.keycloakReady,
                path: path,
                substrate: widgetParams.substrate,
                user: {
                    name: user.name,
                    email: user.email,
                    preferred_username: user.preferred_username
                },
                widget_id: widgetParams.widget_id
            };
        }

        return null;
    }

    delay = ms => new Promise(res => setTimeout(res, ms));

    async componentDidUpdate(prevProps, prevState) {
        if (
            prevState.isAuthenticated !== this.props.isAuthenticated ||
            prevState.keycloakReady !== this.props.keycloakReady ||
            prevState.user.preferred_username !== this.props.user.preferred_username ||
            prevState.substrate !== this.state.substrate
        ) {
            //temporary fix for using multiple widget IDs
            this.removeScripts();
            //wait some time before going on to bypass 'postMessage' error
            await this.delay(100);
            this.appendWindowScript();
            await this.delay(200);
            this.loadFWWidget();
            await this.delay(300);
            this.setFWSettings();
        }
    }

    componentWillUnmount() {
        this.removeScripts();
    }

    // Load a script from Freshworks
    loadFWWidget = () => {
        let script = document.getElementById('support-widget-import');
        if (!script) {
            script = document.createElement('script');
            script.id = 'support-widget-import';
            script.async = true;
            script.defer = true;
            script.src = 'https://widget.freshworks.com/widgets/' + this.state.widget_id + '.js';
            document.body.appendChild(script);
            script.onload = () => {
            };
        }
    };

    // Create or update a script that sets Widget ID and widget access
    appendWindowScript = () => {
        window.fwSettings = {
            widget_id: this.state.widget_id
        };
        let script = document.getElementById('support-widget-window');
        if (!script) {
            script = document.createElement('script');
            const code = `!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
            script.id = 'support-widget-window';
            script.appendChild(document.createTextNode(code));
            document.body.appendChild(script);
        }
    };

    // Set and hide fields for widget
    async setFWSettings() {
        const widget = window.FreshworksWidget;
        if (widget) {
            const substrate = this.state.substrate;
            const user = this.state.user;

            //wait some time to make sure the user information is fully loaded
            await this.delay(100);

            if (!user.preferred_username) {
                widget('hide', 'ticketForm', ['custom_fields.cf_xrathus_username', 'custom_fields.cf_xrathus_substrate']);
                widget('identify', 'ticketForm', {
                    custom_fields: {
                        cf_xrathus_substrate: substrate,
                    }
                });
            } else {
                widget('hide', 'ticketForm', ['custom_fields.cf_xrathus_username', 'custom_fields.cf_xrathus_substrate']);
                widget('identify', 'ticketForm', {
                    name: user.name,
                    email: user.email,
                    custom_fields: {
                        cf_xrathus_username: user.preferred_username,
                        cf_xrathus_substrate: substrate,
                    }
                });
                widget('disable', 'ticketForm', ['name', 'email']);
            }
        }
    }

    removeScripts = () => {
        const widget = window.FreshworksWidget;
        if (widget) {
            widget('destroy');
        }
        const wScript = document.getElementById('support-widget-window');
        if (wScript) {
            wScript.remove();
        }
        const fwScript = document.getElementById('support-widget-import');
        if (fwScript) {
            fwScript.remove();
        }
        const fwIFrame = document.getElementById('freshworks-frame');
        if (fwIFrame) {
            fwIFrame.remove();
        }
        //'name' query doesn't work, 'title' does, but throws an error, so the above frame removal makes 
        //sure no error is thrown, though dom grows by an iframe each time substrate is 
        //changed by user. Almost seems like a Freshworks bug, need more investigation to be sure.
        const fwIframes = document.querySelectorAll(`iframe[name="FreshworksWidget"]`);
        fwIframes.forEach(function (frame) {
            frame.remove();
        });
        const fwContainer = document.getElementById('freshworks-container');
        if (fwContainer) {
            fwContainer.remove();
        }
        window.FreshworksWidget = null;
    };

    render() {
        return (
            <>
            </>
        );
    }
}

SupportScript.propTypes = {
    isAuthenticated: PropTypes.bool,
    keycloakReady: PropTypes.bool,
    path: PropTypes.string,
    substrate: PropTypes.string,
    user: PropTypes.object,
    widget_id: PropTypes.string
};

SupportScript.defaultProps = {
    isAuthenticated: false,
    keycloakReady: false,
    path: null,
    substrate: null,
    user: {
        name: '',
        email: '',
        preferred_username: ''
    },
    widget_id: null
};

export default SupportScript;

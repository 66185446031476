import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: 'what-is'
        };
    }

    toggleContent = (e) => {
        let openState = this.state.open;
        let open = null;
        if (openState !== e) {
            open = e;
        }
        this.setState({
            open: open
        })
    };

    render() {
        const {open} = this.state;

        return (
            <>
                <div className="content faq">
                    <h1 className="page-title">
                        Frequently Asked Questions
                    </h1>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('what-is')}>
                            What is Xrathus Communities and what can I do there?
                        </h3>
                        <div className={`faq-content ${(open === 'what-is') ? 'expanded' : ''}`}>
                            <p>Xrathus Communities is a unified collaboration platform comprised of four seamlessly
                                integrated substrates - Connect, Hub, Training, and Marketplace. Xrathus provides an
                                engagement framework for five personas: Domain, Data Scientist, Developer, Product Owner
                                and Sponsor, enabling its members to work independently, on-demand or in full
                                cooperation with collaborators to ideate and rapidly deliver optimal solutions.</p>
                        </div>
                    </div>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('substrates')}>
                            What are the substrates of Xrathus Communities and how do I interact with them?
                        </h3>
                        <div className={`faq-content ${(open === 'substrates') ? 'expanded' : ''}`}>
                            <p>Xrathus communities is composed of the four following substrates:</p>
                            <ul>
                                <li><u>Connect</u> - The connection and collaboration substrate is the main entry point
                                    to Xrathus Communities platform. Upon signing in, members are directed to their
                                    personalized "My Xrathus" page which enables users to customize their own experience
                                    with the networking components. In Connect, members can ideate, advertise,
                                    collaborate, review and partake in activities of choice while determining decisions
                                    on their interactions with community members. Xrathus members are enabled to
                                    leverage their network and add new members to their personalized network across all
                                    four substrates.
                                </li>
                                <li><u>Hub</u> - Provides a fully integrated and choice collaboration data science
                                    environment for innovation, rapid prototyping and development of advanced solutions
                                    with readily available domain specific tools and libraries. Xrathus Hub enables
                                    member expedite adaptation to the Xrathus workspace environment to maximize
                                    collaborative solution deliveries. The Xrathus Hub also provisions for members
                                    seamless access to their network, Xrathus Training and industry Knowledge- Bases.
                                </li>
                                <li><u>Training</u> - The Training substrate provides an open Knowledge Base which
                                    includes wikis and other repositories. Online, on-demand courses, webinars,
                                    workshops and such, will be facilitated through the Xrathus Training substrate.
                                    Xrathus Partners deliver and host high-value domain sciences, AI/ML and a host of
                                    software technologies training. Mentorships, advice and domain guidance from
                                    academia and industry mentors is available for all members.
                                </li>
                                <li><u>Marketplace (Coming Soon)</u> - The Marketplace substrate optimizes on-demand
                                    provisioning of products, support, and service providers. Xrathus multi-sided
                                    marketplace will provide a catalog of products such as Apps, Algorithms, Datasets,
                                    and Learning content commercialized by Xrathus Members and Partners.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('personas')}>
                            What are the personas of Xrathus Communities and how do I interact with
                            them?
                        </h3>
                        <div className={`faq-content ${(open === 'personas') ? 'expanded' : ''}`}>
                            <p>Members of Xrathus Communities are interested in generating/finding knowledge and
                                delivering solutions, and their persona determines what role they play in those efforts.
                                The five personas that Xrathus Communities is crafted around are:</p>
                            <ul>
                                <li><u>Domain</u> - Collaborates on technical issues and innovation, imparts subject
                                    expertise and intuitions. Domain personas innovate, mentor, and deliver solutions in
                                    Xrathus Communities.
                                </li>
                                <li><u>Data Scientist</u> - Analyzes data, seeks accessible infrastructure to design and
                                    build AI/ML models. Collaborates with Domain and Developer personas to smarten and
                                    optimize solutions.
                                </li>
                                <li><u>Developer</u> - Optimizes algorithms, extends platform functionality, and ideates
                                    with other personas to create and deliver optimal deployments.
                                </li>
                                <li><u>Product Owner</u> - Collaborates with fellow owners, such as portfolio, feature,
                                    scrum, component, platform owners, to glean updates, share experiences and
                                    expertise, and mentor on developments and more to maximize value for all solutions.
                                </li>
                                <li><u>Sponsor</u> - Establishes and enhances network of contacts to implement delivery
                                    of strategic solutions and projects to completion. Participates in investment and
                                    market opportunities.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('get-started')}>
                            How do I get started on Xrathus Communities?
                        </h3>
                        <div className={`faq-content ${(open === 'get-started') ? 'expanded' : ''}`}>
                            <p>Sign up for a Xrathus account to get started. You can use your Email, Google, Microsoft,
                                or GitHub account. Part of the signup process is selecting your primary persona. This
                                choice customizes your experience in Xrathus Communities.</p>
                        </div>
                    </div>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('request')}>
                            How do I request more information about Xrathus Communities?
                        </h3>
                        <div className={`faq-content ${(open === 'request') ? 'expanded' : ''}`}>
                            <p>You can submit your questions by accessing the <strong>Contact Us</strong> link found in
                                the "<strong>Stay Connected</strong>" section. Further, by clicking the Help button
                                located in the bottom right of each page across the site and selecting the request type
                                as information.</p>
                        </div>
                    </div>

                    <div className="panel faq">
                        <h3 className="header" onClick={() => this.toggleContent('support')}>
                            How do I direct support questions to Xrathus?
                        </h3>
                        <div className={`faq-content ${(open === 'support') ? 'expanded' : ''}`}>
                            <p>You can send your support inquiries by accessing the <strong>Support</strong> link found
                                in the "<strong>Quick Links</strong>" section. You can also click the Help button
                                located in the bottom right of each page across the site and selecting the corresponding
                                request type.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(FAQ);

import {assembleQuery} from './utils/dataFunctions';

/**
 * Resources for API
 *      Challenge (Challenges, Joined, Public, User)
 *      Discourse Topic
 *      Organization (Organizations, Browse)
 *      Project (Projects, Personal, Own)
 */
export const RESOURCES = {
    'Challenge': {
        GET_LIST: function (params) {
            const orgId = params.orgId ? params.orgId : '0';
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenges/' + params.active + '/' + orgId + '?' + query
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/create'
            }
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/' + params.id
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/update'
            }
        }
    },
    'ChallengeJoin': {
        PUT: function (params) {
            return {
                'method': 'PUT',
                'data': null,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/' + params.id + '/join'
            }
        }
    },
    'ChallengePublic': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/public/challenges'
            }
        }
    },
    'ChallengeUser': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/challenges'
            }
        }
    },
    'DiscourseTopic': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/discourse/topics/' + params.discourse_id
            }
        },
    },
    'GitLabProjectChallenge': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/challenge/' + params.id + '/gitlabProjectUrl'
            }
        }
    },
    'GitLabProjectProject': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/project/personal/' + params.id + '/gitlabProjectUrl'
            }
        }
    },
    'Organization': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organizations/all/' + params.active
            }
        },
        GET_ONE: function (params) {
            let getBy = (params && params.getBy === 'machine_name') ? 'machineName' : 'id';
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/' + getBy + '/' + params.id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/create'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/update'
                // 'uri': 'https://api.xrathus.d4l.cloud/v1/mgmt/organization/update'
            }
        }
    },
    'OrganizationBrowse': {
        GET_LIST: function (params) {
            const isActive = params.active === 'true' ? '1' : '0';
            const query = assembleQuery(params);
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organizations/browse/' + isActive + '?' + query
            }
        },
    }
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Challenge"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}

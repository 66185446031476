import {sortArrayByKey} from './dataFunctions';

/**
 * Extract files and folders from a DataTransfer of folders
 *
 * @param dataTransfer
 * @return {Promise<[]>}
 */
export const getFilesAsync = async (dataTransfer) => {
    const files = [];
    const length = dataTransfer.items.length;
    for (let i = 0; i < length; i++) {
        let item = dataTransfer.items[i];
        if (item && item.kind === 'file') {
            let entry;
            if (typeof item.getAsEntry === 'function') {
                entry = item.getAsEntry();
            } else if (typeof item.webkitGetAsEntry === 'function') {
                entry = item.webkitGetAsEntry();
            }
            if (entry) {
                const entryContent = await readEntryContentAsync(entry);
                files.push(...entryContent);
                continue;
            }

            const file = item.getAsFile();
            if (file) {
                files.push(file);
            }
        }
    }
    return files;
};

/**
 * Read a DataTransfer File or Folder Entry
 *
 * @param entry
 * @return {Promise<unknown>}
 */
export const readEntryContentAsync = (entry) => {
    return new Promise((resolve, reject) => {
        let reading = 0;
        const contents = [];

        readEntry(entry);

        function readEntry(entry) {
            if (entry.isFile) {
                let path = entry.fullPath;
                reading++;
                entry.file(file => {
                    reading--;
                    if (file.name.substring(0, 1) !== '.') {
                        // Create an object storing the File and the full path
                        let obj = {
                            file: file,
                            path: path
                        };
                        contents.push(obj);
                    }
                    if (reading === 0) {
                        resolve(contents);
                    }
                });
            } else if (entry.isDirectory) {
                readReaderContent(entry.createReader());
            }
        }

        function readReaderContent(reader) {
            reading++;
            reader.readEntries(function (entries) {
                reading--;
                for (const entry of entries) {
                    readEntry(entry);
                }
                if (reading === 0) {
                    resolve(contents);
                }
            });
        }
    });
};

/**
 * Update all file items in an array with consistent path behavior
 *
 * @param list
 * @return {*}
 */
export const setFilePaths = (list) => {
    if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            let path = item.path;
            // Add slash to beginning of path if needed
            if (path.charAt(0) !== '/') {
                item.path = '/' + path;
            }
            // If path is empty, set it to the file name
            if (item.path === '/') {
                item.path = '/' + item.file.name;
            }
        }
    }
    return list;
};

/**
 * Add one or more items to a list of File objects
 *
 * @param fileList
 * @param array
 * @return {*}
 */
export const updateFileList = (fileList, array) => {
    if (array && array.length > 0) {
        for (let a = 0; a < array.length; a++) {
            let item = array[a];
            // let dir = item.path.split('/');

            const pathIdx = fileList.findIndex(r => r.path === item.path);
            if (pathIdx !== -1) {
                // If this file matches an existing item, replace it
                fileList.splice(pathIdx, 1);
                fileList.push(item);
            } else {
                // Add this item
                fileList.push(item);
            }
        }

        // Sort the array by directories
        fileList = sortArrayByKey(fileList, 'path', 'ASC');
    }

    return fileList;
};

/**
 * Remove an item by path from a list of File objects
 *
 * @param fileList
 * @param path
 * @return {*}
 */
export const removeFromFileList = (fileList, path) => {
    const idx = fileList.findIndex(r => r.path === path);
    if (idx !== -1) {
        fileList.splice(idx, 1);
    }

    return fileList;
};

/**
 * Create/Update an array of directories with files
 *
 * @param directoryList
 * @param array
 * @return {*}
 */
export const updateDirectoryList = (directoryList, array) => {
    if (array && array.length > 0) {
        for (let a = 0; a < array.length; a++) {
            let item = array[a];
            // Extract the directory path and remove the filename
            let arr = item.path.split('/');
            arr.pop();
            // Create a string for assembly
            let dirStr = arr.join('/');
            if (dirStr === '') {
                dirStr = '/';
            }
            // Create/update an array of directories containing files
            const dirIdx = directoryList.findIndex(r => r.directory === dirStr);
            if (dirIdx === -1) {
                let obj = {
                    directory: dirStr,
                    files: [],
                    progress: 0
                };
                obj.files.push(item.file);
                directoryList.push(obj);
            } else {
                const fileIdx = directoryList[dirIdx].files.findIndex(r => r.name === item.file.name);
                // Add file if it is not already in this directory
                if (fileIdx === -1) {
                    directoryList[dirIdx].files.push(item.file);
                }
            }
        }
    }
    return directoryList;
};

/**
 * Remove a file from a list of directory objects
 *
 * @param directoryList
 * @param path
 * @return {*}
 */
export const removeFromDirectoryList = (directoryList, path) => {
    let pathArr = path.split('/');
    let filename = pathArr.pop();
    // Cycle through all directories and remove the requested file
    for (let d = 0; d < directoryList.length; d++) {
        let dir = directoryList[d];
        const idx = dir.files.findIndex(r => r.name === filename);
        if (idx !== -1) {
            directoryList[d].files.splice(idx, 1);
            break;
        }
    }
    // If a directory is empty due to having removed all its files, remove the directory
    let directories = directoryList.length;
    for (let d = directories - 1; d >= 0; d--) {
        let dir = directoryList[d];
        if (dir.files.length === 0) {
            directoryList.splice(d, 1);
        }
    }

    return directoryList;
};

/**
 * Update a directory object with upload progress
 *
 * @param directoryList
 * @param path
 * @param progress
 * @return {*}
 */
export const updateDirectoryListProgress = (directoryList, path, progress) => {
    const idx = directoryList.findIndex(r => r.directory === path);
    if (idx !== -1) {
        directoryList[idx].progress = progress;
    }
    return directoryList;
};

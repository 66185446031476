import StoryblokClient from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
    accessToken: process.env.REACT_APP_STORYBLOK_API_KEY,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
});

export const StoryblokAsset = new StoryblokClient({
    accessToken: process.env.REACT_APP_STORYBLOK_ASSET_API_KEY,
    cache: {
        clear: 'auto',
        type: 'memory'
    }
});

export const getSignedUrl = async function(filename) {
    let response = await StoryblokAsset.get('cdn/assets/me', {
        filename: filename
    });
   
    return response.data.asset.signed_url;
};

export default Storyblok;
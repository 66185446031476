import React from 'react';
//import PropTypes from 'prop-types';
import { replaceAll, createLink, createTitle } from './utils';

export default class Heading {
    constructor(
        title,
        level,
        titleLimit,
        customMatchers,
    ) {
        this.title = title;
        this.level = level;
        this.titleLimit = titleLimit;
        this.customMatchers = customMatchers ? customMatchers : {};
    }

    disableScroll() {
        // Get the current page scroll position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
        // if any scroll is attempted, set this to the previous value
        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    timedLink = (link) => {
        //this.disableScroll();
        window.location = `#${replaceAll(link, this.customMatchers)}`;
        /*
        setTimeout(function(){ 
            window.location = `#${replaceAll(link, this.customMatchers)}`;
            //window.onscroll = function() {};
      }, 700);
      */
    }

    generateList() {
        const link = createLink(this.title);
        /*
        const listItem = (
            <li>
                <a href={`#${replaceAll(link, this.customMatchers)}`}>
                    {createTitle(this.title, this.titleLimit)}
                </a>
            </li>
        );*/
        const listItem = (
            <li>
                <button id={`${link}`} onClick={() => this.timedLink(link)}>{createTitle(this.title, this.titleLimit)}</button>
            </li>
        );

        return <>{nestUl(this.level, listItem)}</>;
    }
}

/*
 Create a new heading object from the given string
*/
const newHeading = (
    headingText,
    titleLimit,
    customMatchers,
) => {
    const matchedHashes = headingText.match(/^#+/);
    if (!matchedHashes) return null;
    const headingLevel = matchedHashes[0].split('').length;
    const matchers = customMatchers ? customMatchers : {};

    return new Heading(headingText, headingLevel, titleLimit, matchers);
};

/* 
 Return a nested Unordered list based on the given heading level.
*/
const nestUl = (level, listItem) => {
    switch (level) {
        case 1:
            return listItem;
        case 2:
            return <ul>{listItem}</ul>;
        case 3:
            return (
                <ul>
                    <ul>{listItem}</ul>
                </ul>
            );
        case 4:
            return (
                <ul>
                    <ul>
                        <ul>{listItem}</ul>
                    </ul>
                </ul>
            );
        case 5:
            return (
                <ul>
                    <ul>
                        <ul>
                            <ul>{listItem}</ul>
                        </ul>
                    </ul>
                </ul>
            );
        case 6:
            return (
                <ul>
                    <ul>
                        <ul>
                            <ul>
                                <ul>{listItem}</ul>
                            </ul>
                        </ul>
                    </ul>
                </ul>
            );
        default:
            return listItem;
    }
};

export { newHeading };
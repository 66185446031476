import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {openHelpWidgetWithParams} from '../utils/pageFunctions';
import PagePrompt from '../partials/PagePrompt';
import SectionCollaborationImage from '../css/images/Use-Cases-Section-Collaboration.jpg';
import SectionHackathonImage from '../css/images/Use-Cases-Section-Hackathon.jpg';
import SectionIdeationImage from '../css/images/Use-Cases-Section-Ideation.jpg';
import SectionLearningImage from '../css/images/Use-Cases-Section-Learning.jpg';
import SectionOpenProjectImage from '../css/images/Use-Cases-Section-Open-Project.jpg';
import '../css/page.css';

class UseCases extends Component {

    handleOpenWidget = (section, args = null) => {
        let params = {
            type: 'Question'
        };
        if (section === 'hackathon') {
            params.subject = 'Hackathon';
        }
        if (section === 'collaboration') {
            params.subject = 'Collaboration';
        }
        if (section === 'open-project') {
            params.subject = 'Open Project';
        }
        if (section === 'learning') {
            params.subject = 'Learning';
        }
        if (section === 'ideation') {
            params.subject = 'Ideation';
        }
        if (section === 'your-idea') {
            params.type = 'Information';
            params.subject = 'Ideas for Xrathus';
        }
        if (section === 'page-form') {
            params.subject = args.subject;
            params.type = args.type;
            if (args.name) {
                params.name = args.name;
            }
            if (args.company) {
                params.company = args.company;
            }
            if (args.email) {
                params.email = args.email;
            }
        }
        openHelpWidgetWithParams(params, true);
    };

    render() {
        return (
            <div className="Headline. full use-cases">
                {/* Section: Hero */}
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel full center use-cases-hero no-border no-margin">
                            <h2>
                                Xrathus gives you a thousand ways to collaborate.
                                <span className="break desktop"> </span>
                                Here are five examples to get your neurons firing.
                            </h2>

                            <div className="use-cases-links">
                                <Link to="#hackathon" title="Hackathon" className="btn btn-use-case-link">
                                    <span className="use-case-name">Hackathon</span>
                                    <span>
                                        Bring in-house and external experts together for
                                        intensive problem-solving.
                                    </span>
                                </Link>
                                <Link to="#collaboration" title="Collaboration" className="btn btn-use-case-link">
                                    <span className="use-case-name">Collaboration</span>
                                    <span>
                                        Connect participants across boundaries to work together
                                        without inhibitions.
                                    </span>
                                </Link>
                                <Link to="#open-project" title="Open Project" className="btn btn-use-case-link">
                                    <span className="use-case-name">Open Project</span>
                                    <span>
                                        Let the world know what you're tackling and invite them
                                        to contribute.
                                    </span>
                                </Link>
                                <Link to="#learning" title="Learning" className="btn btn-use-case-link">
                                    <span className="use-case-name">Learning</span>
                                    <span>
                                        Share your knowledge and empower others to take it
                                        forward.
                                    </span>
                                </Link>
                                <Link to="#ideation" title="Ideation" className="btn btn-use-case-link">
                                    <span className="use-case-name">Ideation</span>
                                    <span>
                                        Great ideas don't grow on trees, but they flourish
                                        in the Xrathus environment.
                                    </span>
                                </Link>
                                <button
                                    type="button"
                                    className="btn btn-use-case-link widget"
                                    onClick={() => this.handleOpenWidget('your-idea')}
                                >
                                    <span className="use-case-name">Your Idea Here</span>
                                    <span>
                                        How are you going to use Xrathus? Let's discuss
                                        your first project today.
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Use Case: Hackathon */}
                <div id="hackathon" className="row">
                    <div className="col col-xs-12">
                        <div className="panel use-case-section bg-page-color-connect hackathon">
                            <div className="row">
                                <div className="col col-xs-12 col-md-4">
                                    <img className="use-case-section-image" src={SectionHackathonImage} alt="Hackathon"/>
                                </div>
                                <div className="col col-xs-12 col-md-8">
                                    <h2>Set Challenges. Drive Innovation.</h2>
                                    <p>
                                        Run hackathons to promote multidisciplinary collaboration
                                        and shorten innovation cycles to address fast-changing
                                        customer demands.
                                    </p>
                                    <p>
                                        Xrathus helps you find talent, improve engagement,
                                        accelerate problem solving, reduce R&D cost, and build
                                        community, brand, and leadership.
                                    </p>
                                    <p>
                                        Get a head start in the race to market.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-arrow table uppercase pull-right mb-1"
                                        onClick={() => this.handleOpenWidget('hackathon')}
                                    >
                                        Let's Chat More About This
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Use Case: Open Project */}
                <div id="open-project" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-50 use-case-section bg-page-color-hub open-project">
                            <div className="row">
                                <div className="col col-xs-12 col-md-4">
                                    <img className="use-case-section-image" src={SectionOpenProjectImage} alt="Open Project"/>
                                </div>
                                <div className="col col-xs-12 col-md-8">
                                    <h2>Hello, can you help me?</h2>
                                    <p>
                                        Create your project and share it for qualified community
                                        members to view and join. Then, take advantage of all the
                                        scalable collaboration tools that Xrathus has to offer.
                                    </p>
                                    <p>
                                        Update project details, add and remove files, network with
                                        participants, manage real-time sessions, upload datasets,
                                        save code and documentation in your project's repository,
                                        and apply real-time knowledge capture for future
                                        redeployment.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-hub btn-arrow table uppercase pull-right"
                                        onClick={() => this.handleOpenWidget('open-project')}
                                    >
                                        Show Me More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Use Case: Ideation */}
                <div id="ideation" className="row">
                    <div className="col col-xs-12">
                        <div className="panel use-case-section bg-page-color-connect ideation">
                            <div className="row">
                                <div className="col col-xs-12 col-md-4">
                                    <img className="use-case-section-image" src={SectionIdeationImage} alt="Ideation"/>
                                </div>
                                <div className="col col-xs-12 col-md-8">
                                    <h2>Conceptualize your next solution.</h2>
                                    <p>
                                        Solutions come from tangible concepts, and concepts come
                                        from bright ideas. Tap into the Xrathus community and
                                        ecosystem to generate the sparks that light your
                                        innovation fire.
                                    </p>
                                    <p>
                                        Gathering more ideas from a greater diversity of minds and
                                        experiences means accelerated innovation with superior
                                        odds of commercial success.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-arrow table uppercase pull-right"
                                        onClick={() => this.handleOpenWidget('ideation')}
                                    >
                                        Let's Chat More About This
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Use Case: Collaboration */}
                <div id="collaboration" className="row">
                    <div className="col col-xs-12">
                        <div className="panel panel-overlay overlay-light overlay-50 use-case-section bg-page-color-hub collaboration">
                            <div className="row">
                                <div className="col col-xs-12 col-md-4">
                                    <img className="use-case-section-image" src={SectionCollaborationImage} alt="Collaboration"/>
                                </div>
                                <div className="col col-xs-12 col-md-8">
                                    <h2>Goodbye, boundaries. <br/>Hello, effective digital teamwork.</h2>
                                    <p>
                                        Break down the barriers within your organization or with
                                        suppliers, customers, and consortium members.
                                    </p>
                                    <p>
                                        Securely create and share projects and manage them from
                                        ideation to solutions, taking advantage of all the
                                        collaboration tools and modes of working enabled by the
                                        Xrathus ecosystem.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-hub btn-arrow table uppercase pull-right"
                                        onClick={() => this.handleOpenWidget('collaboration')}
                                    >
                                        Show Me More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Use Case: Learning */}
                <div id="learning" className="row">
                    <div className="col col-xs-12">
                        <div className="panel use-case-section bg-page-color-connect learning">
                            <div className="row">
                                <div className="col col-xs-12 col-md-4">
                                    <img className="use-case-section-image" src={SectionLearningImage} alt="Learning"/>
                                </div>
                                <div className="col col-xs-12 col-md-8">
                                    <h2>Upskill, cross-train, or teach.</h2>
                                    <p>
                                        Within the Xrathus ecosystem you can easily deliver or
                                        participate in online, on-demand courses and workshops.
                                    </p>
                                    <p>
                                        Our open Knowledge Base includes wikis and other
                                        repositories to help you upskill, cross-train, and
                                        teach.
                                    </p>
                                    <p>
                                        Our Collaborators and industry partners use the
                                        platform to host high-value science, AI/ML, and software
                                        training accessible to all community members.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-arrow table uppercase pull-right"
                                        onClick={() => this.handleOpenWidget('learning')}
                                    >
                                        Tell Me More About Courses & Skilling
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Form */}
                <PagePrompt type="use-cases" onSubmitFunc={(args) => this.handleOpenWidget('page-form', args)}/>

            </div>
        )
    }
}

export default withRouter(UseCases);

import React from 'react';
import PropTypes from 'prop-types';
import {Link, BrowserRouter} from 'react-router-dom';

import '../../../css/storyblok-custom.css';


function truncateString(str, n) {
    if (!str)
        return "none";
    if (str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
}


function prepareList(inputList) {
    //remove empty strings
    inputList = inputList.filter(e => e);
    for (let i = 0; i < inputList.length; i++) {
        inputList[i] = capitalizeFirstLetters(inputList[i]);
        //limit string length if necessary
        inputList[i] = truncateString(inputList[i], 20);
    }
    return inputList;
}

function capitalizeFirstLetters(inputString) {    
    const words = inputString.split(" ");

    for (let i = 0; i < words.length; i++) {
        //perform platform acronym check
        const platformAcronyms = ['2d', '3d', 'aapg', 'seg', 'wxdc'];

        for (let j = 0; j < platformAcronyms.length; j++) {
            if (words[i] === platformAcronyms[j])
                words[i] = words[i].toUpperCase();
        }

        if (words[i][0])
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    
    return words.join(" ");
}

function getAllPageLinks(pageLink) {
    let pageLinks = [];
    let tempLink = pageLink;
    pageLinks.push(tempLink);

    while (tempLink.lastIndexOf('/')) {
        tempLink = tempLink.substring(0, tempLink.lastIndexOf('/'));
        pageLinks.push(tempLink);
    }

    pageLinks.reverse();

    //reverse list before returning
    return pageLinks;
}


const BreadCrumbs = ({
    pageLink,
    startIndex,
    endIndex,
    addSelf,
    stepsToJump,
    debugOn,
    demoRouter,
}) => {

    //generate list of links
    let displayedLinks = getAllPageLinks(pageLink);

    if (endIndex !== 0) {
        displayedLinks = displayedLinks.slice(startIndex, endIndex);
    }
    else {
        displayedLinks = displayedLinks.slice(startIndex);
    }

    //go through the pageLink and replace '-' with ' '
    let readableString = pageLink.replace(/-/g, ' '); 

    //split pageLink based on '/'
    let linkLabels = readableString.split('/');

    //capitalize
    linkLabels = prepareList(linkLabels);

    let selfLabel = linkLabels[linkLabels.length-1];

    if (endIndex !== 0) {
        linkLabels = linkLabels.slice(startIndex+1, endIndex);
    }
    else {
        linkLabels = linkLabels.slice(startIndex+1);
    }

    const displayNull = false;

    if (displayNull)
        return null;
    else
        return (
            <div className="breadcrumbs">
                {
                    debugOn ? <>
                        {demoRouter 
                            ? 
                            <BrowserRouter>
                                {linkLabels.map((label, index) => (
                                    <Link to={displayedLinks[index]}  title={label}  className="" key={index}>
                                        <h4>{label + " > "}</h4>
                                    </Link>
                                ))}
                            </BrowserRouter>
                            : 
                            <>
                                {linkLabels.map((label, index) => (
                                    <Link to={displayedLinks[index]}  title={label}  className="" key={index}>
                                        <h4>{label + " > "}</h4>
                                    </Link>
                                ))}
                            </>
                        }
                        {addSelf ?
                            <h3>{selfLabel}</h3>
                            : null
                        }
                    </>
                        :             
                        <div>
                            {demoRouter 
                                ? 
                                <BrowserRouter>
                                    <Link to={displayedLinks[displayedLinks.length-stepsToJump]}  title={"Back"}  className=""><h3>{"< Back"}</h3></Link>
                                </BrowserRouter>
                                : 
                                <Link to={displayedLinks[displayedLinks.length-stepsToJump]}  title={"Back"}  className=""><h3>{"< Back"}</h3></Link>
                            }
                            <br/>&nbsp;
                        </div>
                }
            </div>
        );
};


BreadCrumbs.propTypes = {
    /**
     * The link being separated into breadcrumbs
     */
    pageLink: PropTypes.string,
    /**
     * The first part of the link to be used.
     */
    startIndex: PropTypes.number,
    /**
     * The last part of the link to be used.
     */
    endIndex: PropTypes.number,
    /**
     * Use the link itself as the last link
     */
    addSelf: PropTypes.bool,
    stepsToJump: PropTypes.number,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
    debugOn: PropTypes.bool
};

BreadCrumbs.defaultProps = {
    pageLink: "",
    startIndex: 0,
    endIndex: -1,
    addSelf: true,
    stepsToJump: 2,
    demoRouter: false,
    debugOn: false
};

export default BreadCrumbs;

import React from 'react';

const IconCheckmark = () => {
    return (
        <span className="icon-checkmark">
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="-49.5 65.5 115.2 115.2"
                 enableBackground="new -49.5 65.5 115.2 115.2"
                 xmlSpace="preserve"
            >
                <g>
                    <path fill="#5a358c"
                          d="M65.707,84.688L4.77,175.64h-2.04l-52.229-45.11l14.744-19.311l34.412,29.647l47.101-70.306L65.707,84.688z"/>
                </g>
            </svg>
        </span>
    )
};

export default IconCheckmark;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';
import SectionHeader from "../basic/SectionHeader";

import '../../../css/storyblok-custom.css';


const SectionCollapsible = ({
    title,
    style,
    content,
    storyblokFormatted,
}) => {

    const [collapsedContent, setCollapsedContent] = useState(true);

    let contentClass = '';

    switch (style) {
        case 'document-toggle':
        case 'document-primary-toggle':
        case 'document-secondary-toggle':
        case 'document-tertiary-toggle':
            contentClass = 'collapsible-content';
            break;
        default:
    }

    return (
        <>
            <SectionHeader title={title} style={style} onClick={() => {setCollapsedContent(!collapsedContent);}}/>
            <div className={contentClass + (collapsedContent ? "" : " expanded") }>
                {storyblokFormatted ?  parseStoryblokContent(content, "", "", []) : content}
            </div>
        </>
    );
};


SectionCollapsible.propTypes = {
    /**
     * What is the displayed title?
     */
    title: PropTypes.string,
    /**
     * Required style
     */
    style: PropTypes.oneOf([
        'primary', 
        'secondary', 
        'tertiary', 
        'underlined', 
        'panel', 
        //deprecated
        'document', 
        //deprecated
        'document-subheader',
        'document-primary',
        'document-secondary',
        'document-tertiary',
        'document-toggle',
        'document-primary-toggle',
        'document-secondary-toggle',
        'document-tertiary-toggle',
    ]),
    /**
     * What is the displayed content?
     */
    content: PropTypes.any,
    /**
     * Is content formatted specifically by Storyblok?
     */
    storyblokFormatted: PropTypes.bool,
};

SectionCollapsible.defaultProps = {
    title: "",
    style: 'primary',
    content: [],
    storyblokFormatted: false,
};

export default SectionCollapsible;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import FormChallengeAdd from '../../partials/forms/FormChallengeAdd';
import FormDataFilesVolumes from '../../partials/forms/FormDataFilesVolumes';
import '../../css/challenge.css';

/**
 * Page: Create Challenge & Datasets forms
 */
class XConnectChallengeAdd extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        const challengeId = Number(pathArr.pop());
        const challengePath = (this.props.location.pathname.indexOf('x/hub') !== -1) ? 'hub' : 'connect';

        // @TODO reset dataId = ''
        // @TODO reset Dataset display

        this.state = {
            challenge: null,
            challengePath: challengePath,
            dataId: '',
            formStage: 'challenge',
            challengeId: challengeId
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        if (e === 'challenge' && status === 'complete') {
            // Upon completed Challenge, populate the Dataset's data ID
            this.setState({
                challengeId: data.id,
                dataId: data.data_id
            }, () => {
                // Display the Dataset/Files form
                this.updateFormStage('dataset');
            })
        }
    };

    // Set form toggling
    updateFormStage = (stage) => {
        this.setState({
            formStage: stage
        });
    };

    render() {
        const {
            challengeId,
            challengePath,
            dataId,
            formStage
        } = this.state;

        return (
            <>
                <div className="content xconnect challenge">
                    <h1 className="page-title">
                        Create a Challenge
                    </h1>

                    <div className="panel no-padding">
                        <h3 className="header">Challenge</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormChallengeAdd
                                        formStage={formStage}
                                        challengePath={challengePath}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel no-padding">
                        <h3 className="header">Dataset</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div
                                    className={`form-container expandable ${(formStage === 'dataset') ? 'expanded' : 'collapsed'}`}>
                                    <FormDataFilesVolumes
                                        formType="update"
                                        projectId={challengeId}
                                        projectPath={challengePath}
                                        projectString="Challenge"
                                        projectType="challenge"
                                        dataId={dataId}
                                        formStage={formStage}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(XConnectChallengeAdd);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Iframe from 'react-iframe';
import {hasGroup} from '../../utils/tokenFunctions';

class XHubWorkspace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labLocation: 'iframe'
        };
    }

    /**
     * Set location of Lab in iframe or note as external site
     *
     * @param location
     */
    setLabLocation = (location) => {
        this.setState({
            labLocation: location
        });
    };

    render() {
        const {
            lab_url,
            user
        } = this.props;

        const {
            labLocation
        } = this.state;

        const hasLab = hasGroup(user.groups, 'lab');
        const showIframe = false;

        return (
            <>
                <div className="content xhub">
                    <h1 className="page-title">
                        Workspace
                    </h1>
                    {hasLab &&
                    <p className="indent">
                        {labLocation === 'iframe' &&
                        <a href={lab_url} className="button link"
                           title="Launch Workspace" target="_blank" rel="noopener noreferrer"
                           onClick={() => this.setLabLocation('external')}
                        >
                            Launch in New Window
                        </a>
                        }
                        {labLocation === 'external' &&
                        <button className="button link" onClick={() => this.setLabLocation('iframe')}>
                            Launch Workspace
                        </button>
                        }
                    </p>
                    }
                    <div className="lab-container">
                        {showIframe && lab_url !== '' && labLocation === 'iframe' &&
                        <>
                            <Iframe url={lab_url} id="lab" display="flex" width="100%" height="100%"/>
                        </>
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    let urls = state.urls;
    if (tokens && urls && urls.lab_url) {
        return {
            lab_url: urls.lab_url,
            tokens: {
                token: tokens.token
            },
            user: state.user
        };
    }
    return {
        lab_url: ''
    };
};

export default connect(
    mapStateToProps,
    null
)(XHubWorkspace);

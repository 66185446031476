import React, {useEffect, useState} from 'react';
import FileDirectoryUpload from '../FileDirectoryUpload';
import Modal from '../modal/Modal';

/**
 * Modal for File Upload Progress
 *
 * @param open
 * @param list
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const FileUploadProgressModal = ({open, list = [], title = 'Uploading Files'}) => {

    const [modalStatus, setModalStatus] = useState(open);

    useEffect(
        () => {
            setModalStatus(open);
        }, [open]
    );

    return (
        <Modal
            id="modal-file-upload-progress"
            show={modalStatus}
            onClose={() => setModalStatus(false)}
            allowClose={false}
            closeButtonOnly={true}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '80%',
                minHeight: 320,
                top: '50%',
                transform: 'translate(-50%, -90%)'
            }}
        >
            <div className="panel no-padding no-margin">
                <h3 className="header">{title}</h3>
                <div className="file-list">
                    <p>Caution: Do not navigate away from this page while files are uploading to the server.</p>
                    <FileDirectoryUpload list={list}/>
                </div>
            </div>
        </Modal>
    );
};

export default FileUploadProgressModal;
import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import APIChallenges from '../utils/APIChallenges';
import APIProjectConfiguration from '../utils/APIProjectConfiguration';
import APIProjects from '../utils/APIProjects';
import APIUser from '../utils/APIUser';
import APIEventConfiguration from '../utils/APIEventConfiguration';
import APIEvents from '../utils/APIEvents';

class APIContainer extends Component {
    render() {
        const {
            keycloak,
            keycloakReady,
            tokens,
            user
        } = this.props;

        const isAuth = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        const isSysop = !!(user.is_sysop);

        return (
            <>
                {keycloakReady && isAuth &&
                <>
                    {/* API: User Initialization and Persona */}
                    <APIUser user={user}/>

                    {/* API: User Challenges (Joined) */}
                    <APIChallenges getType="list" viewType="subscribed"/>

                    {/* API: User Projects (Invited) */}
                    <APIProjects
                        getType="list"
                        viewType="shared"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: '',
                            sort: 'ASC',
                            sortBy: 'title'
                        }}
                    />

                    {/* API: User Projects (Joined) with Sysop-only option */}
                    <APIProjects
                        getType="list"
                        viewType={(isSysop) ? 'subscribed_sysop' : 'subscribed'}
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: '',
                            sort: 'ASC',
                            sortBy: 'title'
                        }}
                    />

                    {/* API: User Projects (Own) */}
                    <APIProjects
                        getType="list"
                        viewType="personal_own"
                    />

                    {/* API: User Events (Invited) */}
                    <APIEvents
                        getType="list"
                        viewType="shared"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: '',
                            sort: 'ASC',
                            sortBy: 'title'
                        }}
                    />

                    {/* API: User Events (Joined) */}
                    <APIEvents
                        getType="list"
                        viewType="subscribed"
                        params={{
                            order: 'ASC',
                            page: 1,
                            perPage: 10000,
                            search: '',
                            sort: 'ASC',
                            sortBy: 'title'
                        }}
                    />

                    {/* API: User Events (Own) */}
                    <APIEvents
                        getType="list"
                        viewType="personal_own"
                    />

                    {/* API: Global Personal Events Configurations */}
                    <APIEventConfiguration refresh={false}/>

                    {/* API: Global Personal Project Configurations */}
                    <APIProjectConfiguration refresh={false}/>
                </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

export default withKeycloak(
    connect(
        mapStateToProps,
        null
    )(APIContainer)
);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {language} from '../data/language';
import HomeSubstrate from '../partials/HomeSubstrate';
import SubstratesConnect from '../css/images/Substrates-Connect.svg';
import SubstratesHub from '../css/images/Substrates-Hub.svg';
import SubstratesMarketplace from '../css/images/Substrates-Marketplace.svg';
import SubstratesTraining from '../css/images/Substrates-Training.svg';
import '../css/substrates-icon-links.css';

class SubstratesIconLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubstrate: null
        };
    }

    /**
     * Set selected Substrate to active
     */
    setActiveSubstrate = (e) => {
        if (this.props.allowHover) {
            const activeState = this.state.activeSubstrate;
            if (e !== activeState) {
                this.setState({
                    activeSubstrate: e
                });
            }
        }
    };

    render() {
        const {
            allowLink,
            isAuthenticated
        } = this.props;

        const {
            activeSubstrate
        } = this.state;

        const connectLink = (allowLink)
            ? (isAuthenticated) ? '/x/connect' : '/x/substrates#connect'
            : null;
        const hubLink = (allowLink)
            ? (isAuthenticated) ? '/x/hub' : '/x/substrates#hub'
            : null;
        const marketplaceLink = (allowLink)
            ? (isAuthenticated) ? '/x/marketplace' : '/x/substrates#marketplace'
            : null;
        const trainingLink = (allowLink)
            ? (isAuthenticated) ? '/x/training' : '/x/substrates#training'
            : null;

        return (
            <div className="substrates-icon-links-container">
                <div className="substrates-icon-links">
                    <img className={`icon connect ${(activeSubstrate === 'connect') ? 'active' : ''}`}
                         src={SubstratesConnect} alt="Substrates Connect"/>
                    <img className={`icon hub ${(activeSubstrate === 'hub') ? 'active' : ''}`}
                         src={SubstratesHub} alt="Substrates Hub"/>
                    <img className={`icon marketplace ${(activeSubstrate === 'marketplace') ? 'active' : ''}`}
                         src={SubstratesMarketplace} alt="Substrates Marketplace"/>
                    <img className={`icon training ${(activeSubstrate === 'training') ? 'active' : ''}`}
                         src={SubstratesTraining} alt="Substrates Training"/>

                    <div className="icon-overlay"
                         onMouseOut={() => this.setActiveSubstrate(null)}>
                            <span className="icon-overlay connect"
                                  onMouseOver={() => this.setActiveSubstrate('connect')}>
                            </span>
                        <span className="icon-overlay hub"
                              onMouseOver={() => this.setActiveSubstrate('hub')}>
                            </span>
                        <span className="icon-overlay marketplace"
                              onMouseOver={() => this.setActiveSubstrate('marketplace')}>
                            </span>
                        <span className="icon-overlay training"
                              onMouseOver={() => this.setActiveSubstrate('training')}>
                            </span>
                    </div>
                    <HomeSubstrate
                        type="connect"
                        content={language.substrates.connect}
                        active={(activeSubstrate === 'connect')}
                        onMouseOverFunc={(e) => this.setActiveSubstrate(e)}
                        onMouseOutFunc={(e) => this.setActiveSubstrate(e)}
                        link={connectLink}
                    />
                    <HomeSubstrate
                        type="hub"
                        content={language.substrates.hub}
                        active={(activeSubstrate === 'hub')}
                        onMouseOverFunc={(e) => this.setActiveSubstrate(e)}
                        onMouseOutFunc={(e) => this.setActiveSubstrate(e)}
                        link={hubLink}
                    />
                    <HomeSubstrate
                        type="marketplace"
                        content={language.substrates.marketplace}
                        active={(activeSubstrate === 'marketplace')}
                        onMouseOverFunc={(e) => this.setActiveSubstrate(e)}
                        onMouseOutFunc={(e) => this.setActiveSubstrate(e)}
                        link={marketplaceLink}
                    />
                    <HomeSubstrate
                        type="training"
                        content={language.substrates.training}
                        active={(activeSubstrate === 'training')}
                        onMouseOverFunc={(e) => this.setActiveSubstrate(e)}
                        onMouseOutFunc={(e) => this.setActiveSubstrate(e)}
                        link={trainingLink}
                    />
                </div>
            </div>
        );
    }
}

SubstratesIconLinks.propTypes = {
    allowHover: PropTypes.bool,
    allowLink: PropTypes.bool,
    isAuthenticated: PropTypes.bool
};

SubstratesIconLinks.defaultProps = {
    allowHover: true,
    allowLink: true,
    isAuthenticated: false
};

export default SubstratesIconLinks;

import React from 'react';
import PropTypes from 'prop-types';
import Toc from '../toc/toc';
import { parseStoryblokContent, parseStoryblokContentForHeadings } from '../../../utils/StoryblokFunctions';
import BreadCrumbs from '../basic/BreadCrumbs';


import '../../../css/storyblok-custom.css';

const StoryblokDocument = ({
    title,
    subtitle,
    slug,
    image,
    imageCustomClass,
    contentCustomClass,
    tocToggleOff,
    marginsToggleOff,
    content,
    hideBreadCrumbs,
    breadCrumbStepsToJump,
    storyblokVisualEditorComponent,
    demoRouter,
    //@TODO: maybe a future feature: tableOfContents
}) => {

    const imageClass = imageCustomClass ? imageCustomClass : "title";

    const marginsClass = marginsToggleOff ? "" : " extra-horizontal-margins";

    //get Headings data for ToC
    const headingsFromParsedContent = parseStoryblokContentForHeadings(content).join(' ');
    const willUseToc = headingsFromParsedContent && headingsFromParsedContent!=='';

    //Match the headings to generated slugs
    const matchers = {"–[?!]/": "–"};

    return (
        <div className={"content storyblok-document " + slug} >
            {storyblokVisualEditorComponent}
            {!hideBreadCrumbs ?
                <>
                    <BreadCrumbs pageLink={window.location.pathname} startIndex={2} demoRouter={demoRouter} stepsToJump={breadCrumbStepsToJump}/>
                    <div><br/>&nbsp;</div>
                </>
                : null
            }
            {(willUseToc && !tocToggleOff) ?
                <div className="document-and-toc-wrapper">
                    <div className={contentCustomClass + marginsClass +" document-content"}>
                        <div className="center">
                            <h1 className="title">{title}</h1>
                            {image ?
                                <h1 className={imageClass}>
                                    <img src={image} alt={title}/>
                                </h1>
                                : null
                            }
                            {subtitle ? <h3 className="title">{subtitle}</h3> : <></>}
                        </div>
                        { parseStoryblokContent(content) }
                    </div>
                    <div className="toc-all">
                        <div className="toc-template-box"/>
                        <div className="toc-box"/>
                        <div className="toc-content">
                            <div className="center">
                                <h2>Contents:</h2>
                            </div>
                            <Toc className={"toc-import"} markdownText={headingsFromParsedContent} customMatchers={matchers}/>
                        </div>
                    </div>
                </div>
                :
                <div className={contentCustomClass + marginsClass}>
                    <div className="center">
                        <h1 className="title">{title}</h1>
                        <br/>
                        <br/>
                        {image ?
                            <h1 className={imageClass}>
                                <img src={image} alt={title}/>
                            </h1>
                            : null
                        }
                        {subtitle ? <h3 className="title">{subtitle}</h3> : <></>}
                    </div>
                    <br/>&nbsp;
                    { parseStoryblokContent(content) }
                </div>
            }
        </div>
    );
};


StoryblokDocument.propTypes = {
    /**
     * The displayed title of the page
     */
    title: PropTypes.string,
    /**
     * The displayed subtitle of the page
     */
    subtitle: PropTypes.string,
    /**
     * The slug, usually similar to the title
     */
    slug: PropTypes.string,
    /**
     * The top image.
     */
    image: PropTypes.string,
    /**
     * Custom class for the image. 
     * Ignored if no image is passed.
     * @TODO: Eliminate the need for this variable.
     */
    imageCustomClass: PropTypes.string,
    /**
     * Custom class for the content. 
     * Ignored if no content is passed.
     * @TODO: Eliminate the need for this variable.
     */
    contentCustomClass: PropTypes.string,
    tocToggleOff: PropTypes.bool,
    marginsToggleOff: PropTypes.bool,
    /**
     * The main body content of the page
     */
    content: PropTypes.array,
    /**
     * Hide breadcrumbs on this page. Usually a landing-style page.
     */
    hideBreadCrumbs: PropTypes.bool,
    breadCrumbStepsToJump: PropTypes.number,
    /**
     * Component that loads the code for running the Visual Editor bridge
     */
    storyblokVisualEditorComponent: PropTypes.object,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
};

StoryblokDocument.defaultProps = {
    title: "", 
    subtitle: "", 
    slug: "", 
    image: "", 
    imageCustomClass: "", 
    contentCustomClass: "", 
    tocToggleOff: false,
    marginsToggleOff: false,
    content: [], 
    hideBreadCrumbs: false,
    breadCrumbStepsToJump: 2,
    storyblokVisualEditorComponent: null,
    demoRouter: false,
};

export default StoryblokDocument;

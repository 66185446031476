export const articles = {
    'why-incubate-when-you-can-accelerate': {
        featured: true,
        title: 'Why Incubate when you can Accelerate?',
        thumb_title: 'Michael Crain on Why Incubate when you can Accelerate?',
        subtitle: '',
        author: 'Michael Crain',
        article: `
![](/images/blog-when-incubate-michael.png)

May 27, 6 min read.

Updated: May 28

![](/images/blog-when-incubate-idea.jpg)

Unique and mature businesses will continue to evaluate various opportunities, transitioning into influential entrepreneurs with vital market solutions while preparing to penetrate new markets or disrupt oversaturated markets with innovative initiatives and futuristic brands. The crucial development includes streamlining the core business process and functional capabilities. When developing a concept or articulating the hypotheses, many will wonder how to build a solid foundation, how to move an idea forward, and where to obtain the necessary capital. Collectively, many variables are considered relevant to risk, opportunity, and required credentials to ensure success.

**Does a question become whether to incubate or accelerate?**

There is considerable overlap between the two methods as many inquiries continue to seek the applied benefits. The underlying objectives require proactively identifying risks, materializing a strategy, and managing the probabilities to ensure a specific outcome. Critical elements address the ability to move the concept into the market, creating a niche or first-to-market condition that stimulates a competitive advantage and initiates traction assurance. The idea generated by the startup mandates continuous support and a process of intense, rapid, and immersive education to condense years of learning-by-doing into merely a few months.

**When considering the difference in the value offered, consider the following incentives and expectations.**

- Time commitment
- The ability to work with like-minded people with similar interests and goals
- Associated cost
- Cyclical to the business cycle
- Venture stage of capturing investors
- The approach of applying education
- Enabling a partnership through mentorship

![](/images/blog-when-incubate-table.jpg)

Over the years, two major premier "accelerator programs" continue to flourish. The most popular include Y Combinator, launched as the first "seed" accelerator program. TechStars has two locations, (1) Boulder, Colorado, and (2) Seattle, Washington, providing services across the global landscape. Much of the growth of accelerators began in 2008, collectively with venture investments becoming broadly interested in various markets with a notable increase averaging 50% each year – between 2008 and 2014. More importantly, accelerators invested in more than 5,000 U.S. startups while raising $20 billion in funding with an expectation of increasing accelerator programs. A few companies have used accelerators, such as Airbnb, Dropbox, and Stripe, among several others.

**Today's Success of Accelerators**

The data presented by SEEd-DB is appropriate to Accelerators, and their portfolio of companies continues to impress with accomplishments. Seed-DB is worth mentioning, taking into consideration the aggregate amount of dollars. In my opinion, the top-ten accelerators would be the following:

1. Y Combinator - $5B
2. Techstars - $1B
3. AngelPad - $493M
4. DreamIT Ventures - $397M
5. fbFund - $359M
6. LaunchpadLA - $185M
7. SeedCamp - $137M
8. NYC SeedStart - $130M
9. LA - $57M
10. Wayra - $44M

![](/images/blog-when-incubate-ideas.gif)

**Why Accelerators?**

(1) Why have accelerators taken hold in recent years?

(2) What makes them different from other early-stage programs?

Accelerators provide _ **vital support for a challenging startup** _, from knowledgeable business owners and academic professionals to the program's founders. The program continues to grow from instituting an experience distinguishable from other early-stage programs. Furthermore, the versatility of various resources contributes to new ventures beginning with several modular stages that infuse an immersive educational element to boost the acceleration of weak startups while demonstrating a unique method. Brad Feld, the co-founder of TechStars, said, "The focus is to learn at a rapid pace while learning-by-doing is vital when scaling ventures." Their long-term success is notable by compressing years of experience to create a readiness to launch in a complex market

  **The benefits relevant to an Accelerator Program;**

(1) When enrolling in an accelerator program, participants seek a _ **rapid timeline to develop** _, merge into the market, and have the knowledge to remain successful. The program offers feedback, meetings with mentors, social activities, and other interactive events that directly apply to the startup.

(2) _ **Investor access for startups** _ is readily available from direct connections to local bankers, Angel Funding, Venture Capital, and Private Equity opportunities. Investor conversation is conducted on behalf of the entrepreneur to develop the startup further while illustrating the potential for return.

(3) An accelerator program furnishes a considerable amount of information from "_years of experience and skills_" that each mentor or board member has acquired. Such concentration of knowledge and expertise enables startups and early-stage companies to _ **speed up their development with confidence** _. Instead of reinventing the wheel using predetermined templates, participants can leverage their years of accumulated wisdom to launch a startup in a more calculated, strategic method, suggesting an increase in success rates.

(4) In many cases, an accelerator program _ **focuses on instruction, curriculum, and expanding knowledge and skills** _ essential to operating a business and strengthening sustainability. Specific education points include sales and marketing to unlock new opportunities to grow revenue streams and engage new companies. The baseline of education focuses on developing necessary skills appropriate to market strategies, finance, and technical skills and sustaining a solid pulse relevant to key performance indicators, business objectives, and meeting critical milestones.

(5) Risk remains at the forefront for many, as the current failure rate of 50% remains for startups reaching five years. The risk is compounding when preparing to penetrate new markets, increase product offerings, and sell concepts. _ **Extensive planning** _ is the answer for the success of startups, collectively with various functions applicable to product development, price modeling, marketing strategies, ensuring an effective and efficient operation, and auditing finance capabilities, all of which directly impact the venture's performance. Startups are finding it is safe to innovate through the process of development, exploring new possibilities, and implementing a different approach while capitalizing on a unique mindset that embodies a diverse experience, perspectives, and multiple resources.

(6) It can seemingly be overwhelming for various startups and early-stage businesses and impossible to visualize where the company may be in six months or even a year. However, it's essential to _ **imagine a long-term vision to forge critical objectives** _ when evaluating the big picture. Mentors are motivated to assist in the complexity and guide the entrepreneur in the right direction. Their expertise enables the vital questions requiring critical thinking to assembling essential tools and practical tactics when navigating through the journey.

(7) The characterization of the _ **accelerator model fosters growth** _ in all phases of development. BAM nourishes a unique platform for startups, early-stage, and growth-driven businesses. The venue solves problems, emerges a concept, develops market strategies, uncovers detailed objectives, implements critical goals, and creates a plan to confirm current and future market share. The environment encourages a partnership between mentors and entrepreneurs to guide development through phases of the platform. Many have used the accelerator for the expertise to launch a more diversified venture or expand into new markets. Accelerators are known for _ **solving problems** _, cultivating innovative methodologies to empower growth, and facilitating the necessary resources to maintain market success.

(8) The collective and _ **collaborative environment** _ motivates entrepreneurs to develop a product/service-market fit that aligns with early adopters. Interaction with other business owners, founders, and professionals is an inspirational reinforcement. It resonates when you hear their personal challenges, self-doubts, and extraordinary accomplishments. Moreover, their testimony reminds us that it is possible to overcome uncertainty while working together to remove the obstacles.

(9) The _ **Accelerator Program does not end after launch** _ or when entering a perspective market. A long-term relationship remains available throughout the life of the business. In addition, the alumni network is an added source to inquire about available talent and various investors and soliciting feedback throughout the startup or supplemental support in future developments.

In an article by _ **Harvard Business Review, Ian Hathaway** _ believes "_a comparison of graduates of top accelerators with a set of similar startups that instead raised angel funding from leading angel investment groups - found that the accelerator graduates were more likely to receive their next round of financing significantly sooner._"

- Additional research indicates accelerators aid venture development, establishing a foundation to primarily educate throughout the accelerator experience, exemplifying the value of a stable learning environment that promotes a higher success rate.
- In most cases, accelerators positively affect startups relevant to performance compared with early-stage investors and sustain the ability to have a positive impact when attracting seed funding.

Evidence is encouraging when driving a favorable startup ecosystem across the global landscape when considering the _ **rapid growth of accelerators** _. Universities may believe it doesn't make much difference, but many do by referencing the success provided by accelerators. The ones that find accelerators most beneficial are the _ **ones that significantly improve the odds of success.** _

![](/images/blog-when-incubate-b2hub.png)

The unique development of the _ **B²Hub Accelerator Program** _ capitalizes on expertise from our board members, resource partners, and investors. The board demonstrates various proficiencies from grant writing, industry experts, attorneys, accountants, and academic advisors that can offer essential services. The service side of development includes market research to find market gaps and evaluate competitors and market trends relevant to the selected demographics. Other services provided include patents, trademarks, copyrights, websites, advertising platforms (strategies), and contracts. As education and mentorship are the foundation of our success, we offer many online courses to build the proficiency of business owners and employees to safeguard sustainable success relevant to creating flexibility.

**BAM - B²Hub Accelerator Program**

![](/images/blog-when-incubate-mentorship.jpg)

[BAMBIZHUB.COM](http://bambizhub.com/)

For more information call 432-247-8840

Email: [info@bamconsulting.com](mailto:info@bamconsulting.com)

Thank you for all your support!
`,
        thumb: 'blog-default-thumb.png',
    },
    'planning-your-pivot': {
        featured: true,
        title: 'Planning your Pivot',
        thumb_title: 'Angel Garcia on Planning your Pivot',
        subtitle: '',
        author: 'Angel Garcia, MBA',
        article: `
May 8, 2021, 4 min read

"Don't lose faith guys, we've got a great name. We've got a great team. We've got a great logo, and we've got a great name. Now we just need an idea. _ **Let's pivot!!** _"

- Jared, Silicon Valley, TV Show

**Have a purpose, find the path, and navigate the pivot.**

![](/images/blog-planning-your-pivot.jpg)

On a fundamental level, a _pivot_ is _'a change in direction.'_ In the business vernacular, a pivot is loosely defined as 'a _significant_ change in strategy _without a vision change_.'

Today's entrepreneur savvy social media-driven business landscape has dramatically overused the term watering it down to the point of meaning to make any simple adjustment.

Originally used to denote a significant transition for a business, specifically a _startup_, the utilization of "pivot" refers to redirecting an ineffective business model and defining a multiple business transition to one much more efficient, productive, and conducive to sustainability.

Whereas today, for example, the term is used to explain a nuance as simple as a change in the number of posts per day on social media. A simple adjustment is not a pivot by any means and only a difference in deploying a single tactic within the overall strategy.

To paraphrase the legendary general, Sun Tzu, employing strategy without tactics is the most passive way to gain customers and grow your business. Tactical implementation without a plan leads to spending marketing dollars resulting in low sales, declining ROI, and accelerating business failure.

**A pivot is not a single tactic but a 90-degree shift to a set of tactics to achieve a specific outcome.**

A real-world example of an authentic pivot was Charles Schwab, when they foresaw that the future of the brokerage industry due to the internet was an opportunity and made the pivot to online stock trading.

To do this, they created a separate _Strategic Business Unit_, SBU, and the employees making the transition over to the new business unit was made for walking across the Golden Gate Bridge and back to the office to mark the event.

The Charles Schwab pivot meant that a client no longer had to wait several days to physically speak with a broker and then for the broker to make the call in to purchase or sell the stocks. The customer could now conduct transactions online, which attracted new customers activating sustainable growth.

**When to Pivot?**

![](/images/blog-planning-your-pivot-change-plan.jpg)

In referencing the above essential elements, a business should remain in a favorable position to grow. If not, it is time to pivot and improve the various aspects of the company.

For example, the failure of Blockbuster to make the pivot online was catastrophic in hindsight; however, all the new information, market trends, data, and availability of new technology was the same for them as anyone else.

If Blockbuster would have made a pivot online before Netflix and did it better at the time, the world would still have Blockbuster and may not have ever heard the name Netflix.

They were in an optimal position to embrace the innovative technology of the time and pivot to online streaming; however, they chose to double-down on the physical DVD medium, their champion product, and ignore what the data, trends, new information begged them to do.

When a company invests in product development before it has identified a market, there is no new customer buy-in, subsequently followed by loyal customers abandoning the brand to discover other initiatives, innovation, and convenient technology. The situation should require an executive team to continually evaluate the applied analytics, patterns of data, and high-quality data sources to ensure a pivot is necessary.

_ **As demonstrated by Blockbuster, the number one reason businesses fail –** _

_ **is because their product or service does not have a market.** _

**To answer the question - When to pivot?**

![](/images/blog-planning-your-pivot-simple.jpg)

The question requires the ability of business owners or executives to connect with data models to enable market trends and quantitative analysis relevant to establishing market position, new initiatives, and competitive innovation, which together formulate a market shift. The importance of data relates to the ability to expedite answers, direction, and preparation to market disruption while advancing a strategic pivot.

This will inform that they are either losing market share or an innovative technology looms on the horizon destined to change how business is done in the industry, disrupting the current business model. This is known as a disruptive technology. This is an excellent sign to implement a smart pivot as soon as possible. Be first to market. Be Charles Schwab and Netflix, don't be Blockbuster.

When a product or service is fully loaded with bells and whistles at the initial launch, there may not be enough customers who embrace it, and the company would have spent much more money in development than necessary and will likely have missed the mark of incorporating a sustainable solution that incentivizes growth.

The purpose of any business is to prove viability, which influences sustainable growth. The process includes developing and revealing an MVP, _Minimal Viable Product_, as a new version or a completely new product to ensure product-market fit by maximizing validation with early adopters.

By developing and deploying the MVP within a given product life cycle to a market, the company can collect and analyze crucial data on how to further develop the next generation of the product or services, hence the consistency of a sustainable business model.

Check out this online class addressing [Business Management](https://bam.coursecatalog.com/CourseListAccordion?type=src&keyword=managing).

For more information on developing a sustainable business model strategy, please reference our Blog, [Business Development Strategy: Theory to](https://www.bamconsulting.com/post/business-development-strategy-theory-to-application-written-by-angel-m-garcia-mba) Application, and give us a call with any questions you may have.

1-833-BAM-IDEA (226-4332) or (512)736-1931 or email: info@bamconsulting.com

[Visit our website: www.bamconsulting.com, and follow us on social media FB, IG, Li.](https://linktr.ee/BAMConsulting)
`,
        thumb: 'blog-default-thumb.png',
    },
    'seera-labs-on-data-science': {
        featured: true,
        title: 'Seera Labs on Data Science',
        thumb_title: 'Seera Labs on Data Science',
        subtitle: '',
        author: '',
        article: `
Digital data collected by many industries support many, if not, most of its activities. Data integrity, security, mining, analysis, and transfer are critical to its particular goal of providing insight. Related topics such as the use and management of massive data sets ("big data"), data value and ownership, cybersecurity, cloud computing, machine learning, and virtual twin modeling and simulation represent only a small subset of the derivative uses of digital data being contemplated by all industries. Particular questions to be answered by data analysts, data scientists, and/or subject material experts are:

- Once data has been collected, what we do with it?
- How do we extract knowledge and value from collected data to benefit operations, gain efficiencies, and improve safety and security?
- What are the business propositions that sensed data collection, storage, and analysis bring to the table?
- How effectively is data analyzed?
- What data analysis methods and tools should the industry be adopting that aren't now being used?

To help answer some or all of the questions, Seera Labs provides the "Student/Professional Client" the knowledge and skills to achieve a level as a "citizen data scientist". This requires a mix of statistical understanding, code programming, data mining & visualization, and the ability to apply engineering knowledge in new and challenging pursuits within his/her respective engineering domain. Hence, this leads to Seera Labs vision and mission:

![Trending in Education](/images/blog-Seera-Labs-On-Data-Science-Trending.png#thumbnail)
*We believe that industries that uplearn/upskill their workforce with data science fundamentals through microlearning leads to value-creation and retention.*

![Educational Experience](/images/blog-Seera-Labs-On-Data-Science-Hands.png#thumbnail)
*To provide an unparalleled educational experience that invites professionals to discover data science and all its insightful benefits.*
`,
        thumb: 'blog-Seera-Labs-On-Data-Science-thumb.jpg',
    },
    'susan-nash-on-collaboration': {
        featured: true,
        title: 'Susan Nash on Collaboration',
        thumb_title: 'Susan Nash on Collaboration',
        subtitle: 'What does effective collaboration involve now, in the "new normal"?',
        author: 'Susan Nash, PhD',
        article: `
Imagine this scenario: Your company has an opportunity to partner with a company that specializes in providing sensors to alternative energy electricity generation of all kinds. The market is robust, but you have to make sure your sensors work in all kinds of alternative energy sources, ranging from geothermal to wind to solar to small hydroelectric. Your team consists of a heterogeneous "dog's breakfast" of engineers, geologists, psychology majors, sales reps, attorneys, and creative writing and designers. Somehow, you've got to come together, define your mission and goals, and develop a plan that will work in this wildly unpredictable world, now in the "new normal."

How would you get started?

It would be very tempting to start with the tools we have at hand. After all, we live in a world with a number of collaborative tools and cloud-based programs. Perhaps you've worked for years with Google apps, Microsoft Teams and then project management platforms such as Trello and Basecamp. You might have also worked with distributed teams using collaborative publishing platforms such as Panopto. You may have also worked in collaborative environments with blogs (Blogger, WordPress) and social media (Twitter, Instagram, LinkedIn, FaceBook, etc.).

In theory, these products and platforms are perfect for creating collaborative environments, and in reality, they have worked in the sense that tasks were distributed and products/services were produced. Organizational continuity was assured, although, chances are, not much innovation or creative thinking emerged.

However, the problem with most tools and platforms is that they encourage you to replicate your old way of doing business, with the idea that however you were working in the past, in the traditional office environment or from distributed, remote sites worked really well, and it should be the way of the future.

In reality, to replicate the past is to be consigned to the past. At best, all we'll see is a repetition of old patterns and ways of thinking.

Effective collaboration in the "new normal" of distributed, remote teams, and a highly fluid environment characterized by constant change requires a new way of thinking, and in such an environment, it requires new structures.

An effective collaboration space will allow the team members to do the following:

**SMART goal-setting**: Specific, Measurable, Achievable, Relevant, Time-based

**Stackable goals and scaffolding**: The best goals are those that are building-block goals, and are sufficiently granular to be done quickly so that they quickly contribute to the overall goal.

**Role-reversals**: Team members will be required to assume the part of what they might consider their absolute antithesis. The highly technical data scientist now has to design logos. The marketing strategy director now has to make three data-supported recommendations that will measurably improve operations performance. What does it mean to suddenly have to live the life of someone you've observed from afar? After tackling the kinds of problems and challenges they face in their day to day job, how quickly will you throw stones now?

**Personae correspond to stakeholders**: What's the role you will play? What is your persona? When working within a simulation or role-playing, be sure to select a role to play that corresponds to a true stakeholder, a person who has a lot of skin in the game.

**Ongoing SWOT analyses**: Technology and business conditions change quickly, and yet decisions may be made based on analyses performed in the past. In an ideal collaboration space, relevant data gathered from independent third parties should be referred to constantly. Each team member can provide an individual assessment of Strengths, Weaknesses, Opportunities, and Threats, which can then be used as a point of departure for evaluating paths forward. SWOT analyses should be fluid and ongoing, to allow for proactive planning.

**Cloud access / shared repositories**: Collaborative teams work together, and it is more important than ever to be able to share files and resources. Not only repositories are cloud-based, but increasingly, the programs are cloud-based and accessible using mobile devices.

**Critical Path project planning**: Critical paths continue to be useful, with milestones and goals. However, collaborative teams use them as scenarios, and develop contingency plans.

Each one of the points mentioned in this post is in reality worthy of being the topic of an entire blog post. In addition, effective collaboration requires an awareness of how the mind works and makes meaning, which include cognitive bias and pitfalls. There is a great deal to learn about how best to work in a new framework, and how best to create settings that allow new solutions to emerge rather than simply replicating the past.
`,
        thumb: 'blog-Nash-Collaboration-thumb.jpg',
    }
};

import React from 'react';

const SearchFilter = ({changeFunc, submitFunc, type, label = 'Search'}) => {
    return (
        <form className={`form-group x-filter-search-${type}`}>
            <label htmlFor="search">
                <span>{label}</span>
                <div className="x-table-filters-row">
                    <input
                        type="text"
                        id={`search-${type}`}
                        name={`search-${type}`}
                        onChange={changeFunc}
                        className="filter-input"
                    />
                    <button type="submit" className="filter-btn" onClick={(e) => submitFunc(e)}>Search</button>
                </div>
            </label>
        </form>
    );
};

export default SearchFilter;
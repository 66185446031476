import axios from 'axios';
import {getDeleteHeaders} from './headers';

/**
 * Make a delete request with a specific resource
 *
 * @param resource
 * @param name
 * @param type
 * @param params
 * @param useBearer
 * @return {Promise<*>}
 */
export async function makeDeleteDataRequest(resource, name, type, params, useBearer = true) {
    let func = resource[name].DELETE(params);
    let method = 'DELETE';
    try {
        return await deleteRequest(type, func.uri, method, func.data, useBearer);
    } catch (e) {
        console.log('data error: ', e.message);
        return e;
    }
}

/**
 * Make POST request
 *
 * @param type
 * @param uri
 * @param method
 * @param payload
 * @param useBearer
 * @return {Promise<AxiosResponse<any>>}
 */
const deleteRequest = (type, uri, method, payload, useBearer = true) => {
    let headers = getDeleteHeaders(true);
    return axios({
        method: method,
        url: uri,
        data: payload,
        headers: headers
    }).then((response) => {
        if (response.data && response.data.payload) {
            return response.data.payload;
        } else if (response.data) {
            return response.data;
        } else if (response) {
            return response;
        }
    }).catch((error) => {
        if (error.response) {
            // Server response outside 2xx
            return error.response;
        } else if (error.request) {
            // No response
            return error.request;
        } else {
            return error;
        }
    })
};

export default deleteRequest;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {RESOURCES as resources} from '../../resourcesData';
import {makeGetDataRequest} from '../../utils/getRequest';

/**
 * Button for Dataset forms to link to Challenge or Project
 *
 * @param data_id
 * @returns {JSX.Element}
 * @constructor
 */
const DatasetProjectButton = ({data_id}) => {

    const [link, setLink] = useState(null);
    const [label, setLabel] = useState('View Details');

    useEffect(() => {
        const params = {
            data_id: data_id
        };
        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'DataAssociation', 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    if (result.error) {
                        return Promise.resolve(result);
                    }
                    const id = result.id;
                    const type = result.project_type;
                    if (id && type) {
                        setLabel((type === 'challenge') ? 'View Challenge' : 'View Project');
                        setLink((type === 'challenge')
                            ? '/x/hub/challenge/' + id + '/overview'
                            : '/x/hub/project/personal/' + id + '/overview');
                    }
                    return Promise.resolve(result);
                } else {
                    console.log('Data Association Error: No result');
                    return Promise.reject('Projects failed');
                }
            }).catch(error => {
                console.log('Data Association Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        if (!link) {
            return fetchData();
        }

    }, [data_id, link]);

    return (
        <>
            {link && data_id &&
            <Link className="btn btn-primary" to={link} title={label}>{label}</Link>
            }
        </>
    );
}

DatasetProjectButton.propTypes = {
    data_id: PropTypes.number,
};

export default DatasetProjectButton;
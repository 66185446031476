import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ValueImage from '../css/images/Home-Value-Marketplace.jpg';

class HomeValueSectionMarketplace extends Component {

    handleClick = (e) => {
        e.preventDefault();
        if (typeof this.props.onClickFunc === 'function') {
            this.props.onClickFunc({
                type: 'Question',
                subject: 'Marketplace'
            });
        }
    };

    render() {
        return (
            <>
                <div id="marketplace" className="row">
                    <div className="col col-xs-12">
                        <div className="panel center-content row home-value marketplace">
                            <div className="col col-xs-12 col-sm-4  vertical-center">
                                <img className="value-image" src={ValueImage} alt="marketplace"/>
                            </div>
                            <div className="col col-xs-12 col-sm-8">
                                <h2>
                                    Find the services and expertise you need, when you need them,
                                    through our community-powered marketplace.
                                </h2>
                                <p>
                                    We're building a network of partners and collaborators to
                                    deliver the support and services your project needs, whenever
                                    you realize it needs them.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-training btn-arrow uppercase table pull-right mt-1 mb-1"
                                    onClick={(e) => this.handleClick(e)}
                                >
                                    Learn More >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

HomeValueSectionMarketplace.propTypes = {
    onClickFunc: PropTypes.func
};

export default HomeValueSectionMarketplace;

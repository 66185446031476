import Config from '../config';
import {copyArray, updateObject} from '../utils/dataFunctions';

const INITIAL_STATE = {
    challenge_list_public: [],
    challenge_list_subscribed: [],
    challenge_list_subscribed_refresh: false,
    event_config: Config.eventConfigMin,
    event_config_current: Config.eventConfigCurrent,
    event_config_refresh: false,
    event_config_user: Config.eventConfigUser,
    event_config_user_refresh: false,
    event_id: null,
    event_list_invited: [],
    event_list_own: [],
    event_list_public: [],
    event_list_refresh: null,
    event_list_subscribed: [],
    event_list_subscribed_refresh: false,
    keycloakReady: false,
    list_status: Config.listStatus,
    project_config: Config.projectConfigMin,
    project_config_current: Config.projectConfigCurrent,
    project_config_refresh: false,
    project_config_user: Config.projectConfigUser,
    project_config_user_refresh: false,
    project_id: null,
    project_file_list: [],
    project_list_invited: [],
    project_list_own: [],
    project_list_refresh: null,
    project_list_subscribed: [],
    sidebarOpen: false,
    tokens: Config.tokensDefault,
    user: Config.user
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            let tokensDefault = updateObject(state.tokens, Config.tokensDefault);
            let userDefault = updateObject(state.user, Config.user);
            return {
                ...state,
                tokens: tokensDefault,
                user: userDefault
            };
        case 'SET_AUTHENTICATED':
            return {
                ...state,
                authenticated: action.authenticated,
            };
        case 'SET_KEYCLOAK_READY' :
            return {
                ...state,
                keycloakReady: true
            };
        case 'SET_LIST_STATUS':
            return {
                ...state,
                list_status: {
                    ...state.list_status,
                    [action.obj.list]: action.obj.status
                }
            };
        case 'SET_EVENT_CONFIG':
            return {
                ...state,
                event_config: action.event_config
            };
        case 'SET_EVENT_CONFIG_CURRENT':
            return {
                ...state,
                event_config_current: action.obj
            };
        case 'SET_EVENT_CONFIG_REFRESH':
            return {
                ...state,
                event_config_refresh: action.payload
            };
        case 'SET_EVENT_CONFIG_USER':
            return {
                ...state,
                event_config_user: action.obj
            };
        case 'SET_EVENT_CONFIG_USER_REFRESH':
            return {
                ...state,
                event_config_user_refresh: action.payload
            };
        case 'SET_EVENT_ID':
            return {
                ...state,
                event_id: action.event_id
            };
        case 'SET_EVENT_LIST_INVITED':
            let event_list_invited = copyArray(action.event_list_invited)
            return {
                ...state,
                event_list_invited: event_list_invited
            };
        case 'SET_EVENT_LIST_OWN':
            let event_list_own = copyArray(action.event_list_own)
            return {
                ...state,
                event_list_own: event_list_own
            };
        case 'SET_EVENT_LIST_PUBLIC':
            let event_list_public = copyArray(action.event_list_public)
            return {
                ...state,
                event_list_public: event_list_public
            };
        case 'SET_EVENT_LIST_REFRESH':
            return {
                ...state,
                event_list_refresh: action.payload
            };
        case 'SET_EVENT_LIST_SUBSCRIBED':
            let event_list_subscribed = copyArray(action.event_list_subscribed)
            return {
                ...state,
                event_list_subscribed: event_list_subscribed
            };
        case 'SET_EVENT_LIST_SUBSCRIBED_REFRESH':
            return {
                ...state,
                event_list_subscribed_refresh: action.payload
            };
        case 'SET_PROJECT_CONFIG':
            return {
                ...state,
                project_config: action.project_config
            };
        case 'SET_PROJECT_CONFIG_CURRENT':
            return {
                ...state,
                project_config_current: action.obj
            };
        case 'SET_PROJECT_CONFIG_REFRESH':
            return {
                ...state,
                project_config_refresh: action.payload
            };
        case 'SET_PROJECT_CONFIG_USER':
            return {
                ...state,
                project_config_user: action.obj
            };
        case 'SET_PROJECT_CONFIG_USER_REFRESH':
            return {
                ...state,
                project_config_user_refresh: action.payload
            };
        case 'SET_PROJECT_ID':
            return {
                ...state,
                project_id: action.project_id
            }
        case 'SET_PROJECT_FILE_LIST':
            let project_file_list = copyArray(action.project_file_list)
            return {
                ...state,
                project_file_list: project_file_list
            };
        case 'SET_CHALLENGE_LIST_PUBLIC':
            let challenge_list_public = copyArray(action.challenge_list_public)
            return {
                ...state,
                challenge_list_public: challenge_list_public
            };
        case 'SET_CHALLENGE_LIST_SUBSCRIBED':
            let challenge_list_subscribed = copyArray(action.challenge_list_subscribed)
            return {
                ...state,
                challenge_list_subscribed: challenge_list_subscribed
            };
        case 'SET_CHALLENGE_LIST_SUBSCRIBED_REFRESH':
            return {
                ...state,
                challenge_list_subscribed_refresh: action.payload
            };
        case 'SET_PROJECT_LIST_INVITED':
            let project_list_invited = copyArray(action.project_list_invited)
            return {
                ...state,
                project_list_invited: project_list_invited
            };
        case 'SET_PROJECT_LIST_OWN':
            let project_list_own = copyArray(action.project_list_own)
            return {
                ...state,
                project_list_own: project_list_own
            };
        case 'SET_PROJECT_LIST_PERSONAL':
            let project_list_personal = copyArray(action.project_list_personal)
            return {
                ...state,
                project_list_personal: project_list_personal
            };
        case 'SET_PROJECT_LIST_REFRESH':
            return {
                ...state,
                project_list_refresh: action.payload
            };
        case 'SET_PROJECT_LIST_SUBSCRIBED':
            let project_list_subscribed = copyArray(action.project_list_subscribed)
            return {
                ...state,
                project_list_subscribed: project_list_subscribed
            };
        case 'SET_SIDEBAR_VISIBILITY':
            return {
                ...state,
                sidebarOpen: action.payload
            };
        case 'SET_TOKENS':
            return {
                ...state,
                tokens: action.tokens
            };
        case 'SET_USER':
            return {
                ...state,
                user: action.obj
            };
        default:
            return state;
    }
};

export default reducer;

import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';
import {openHelpWidgetWithParams} from '../utils/pageFunctions';
import {language} from '../data/language';
import HomeHero from '../partials/HomeHero';
import HomeOrganizations from '../partials/HomeOrganizations';
import HomePersona from '../partials/HomePersona';
import HomeValueSectionCollaboration from '../partials/HomeValueSectionCollaboration';
import HomeValueSectionBichronous from '../partials/HomeValueSectionBichronous';
import HomeValueSectionMarketplace from '../partials/HomeValueSectionMarketplace';
import PagePrompt from '../partials/PagePrompt';
import RoleDataScientist from '../css/images/icon-role-Data-Scientist.svg';
import RoleDeveloper from '../css/images/icon-role-Developer.svg';
import RoleDomain from '../css/images/icon-role-Domain.svg';
import RoleProductOwner from '../css/images/icon-role-Product-Owner.svg';
import RoleSponsor from '../css/images/icon-role-Sponsor.svg';
import SubstratesIconLinks from '../partials/SubstratesIconLinks';
import '../css/home.css';
import '../css/persona.css';
import EventCarrousel from "../partials/EventCarrousel";

const Home = ({keycloak, keycloakReady, tokens}) => {

    const handleOpenWidget = (section, args = null) => {
        let params = {
            type: 'Question'
        };
        if (section === 'personas') {
            params.subject = 'Personas';
        }
        if (section === 'schedule-demo') {
            params.subject = 'Schedule A Demo';
            params.type = 'Demo Request';
        }
        if (section === 'value') {
            params.subject = args.subject;
            params.type = args.type;
        }
        if (section === 'page-form') {
            params.subject = args.subject;
            params.type = args.type;
            if (args.name) {
                params.name = args.name;
            }
            if (args.company) {
                params.company = args.company;
            }
            if (args.email) {
                params.email = args.email;
            }
        }
        openHelpWidgetWithParams(params, true);
    };

    if (!keycloakReady) {
        return (
            <>
                <div className="content full">
                </div>
            </>
        );
    }

    const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
    const displayEvents = true;

    return (
        <>
            <div className="content full">

                {/* Section: Hero/Intro */}
                <HomeHero/>

                {/* Section: Personas */}
                <div id="personas" className="personas">
                    <div className="detail-diagram">
                        <div className="detail">
                            <h2>
                                Bring people, knowledge, and resources together to create
                                deployment-ready solutions.
                            </h2>
                            <p>
                                As a Xrathus community member, you'll find all the tools,
                                knowledge, and support you need to create, manage, and
                                contribute to projects that drive business forward.
                            </p>
                            <button
                                type="button"
                                className="btn btn-training btn-arrow uppercase mt-3"
                                onClick={() => handleOpenWidget('personas')}
                            >
                                Learn More >
                            </button>
                        </div>
                        <div className="diagram">
                            <div className="structure">
                                <div className="bg">
                                </div>
                                <HomePersona
                                    type="developer" typeName="developer"
                                    content={language.personas.developer}
                                    icon={RoleDeveloper}
                                />
                                <HomePersona
                                    type="product-owner" typeName="product_owner"
                                    content={language.personas.product_owner}
                                    icon={RoleProductOwner}
                                />
                                <HomePersona
                                    type="sponsor" typeName="sponsor"
                                    content={language.personas.sponsor}
                                    icon={RoleSponsor}
                                />
                                <HomePersona
                                    type="domain" typeName="domain"
                                    content={language.personas.domain}
                                    icon={RoleDomain}
                                />
                                <HomePersona
                                    type="data-scientist" typeName="data_scientist"
                                    content={language.personas.data_scientist}
                                    icon={RoleDataScientist}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Substrates */}
                <SubstratesIconLinks
                    allowHover={true}
                    allowLink={true}
                    isAuthenticated={isAuthenticated}
                />
                <div className="home-substrates-schedule">
                    <button
                        type="button"
                        className="btn btn-hub btn-arrow uppercase mb-0"
                        onClick={() => handleOpenWidget('schedule-demo')}
                    >
                        Schedule A Live Demo >
                    </button>
                </div>

                {/* Section: Value Drivers */}
                <HomeValueSectionBichronous onClickFunc={(args) => handleOpenWidget('value', args)}/>
                <HomeValueSectionMarketplace onClickFunc={(args) => handleOpenWidget('value', args)}/>
                <HomeValueSectionCollaboration onClickFunc={(args) => handleOpenWidget('value', args)}/>

                {/* Section: Organizations */}
                <div id="organizations" className="row">
                    <div className="col col-xs-12">
                        <div className="panel organizations no-border mt-2">
                            <h2>
                                Work alongside all types of leading organizations
                            </h2>
                            <p>
                                We have created an ecosystem that feels like home to some of the
                                world's leading organizations, including global corporations,
                                industrial societies, accelerators, incubators, consortia, and
                                academic institutions.
                            </p>
                            <HomeOrganizations/>
                        </div>
                    </div>
                </div>

                {/* Section: Events */}
                {displayEvents &&
                    <div id="events" className="bg-page-color-connect row">
                        <div className="col col-xs-12">
                            <div className="panel events no-border mt-1 mb-0">
                                <h2>
                                    Join our Community Events and Programs
                                </h2>
                            </div>
                        </div>
                        <div className="col col-xs-12">
                            <div className="panel events no-border mt-0 mb-2 center-content">
                                {/* Place Event objects or components in columns */}
                                <EventCarrousel isAuthenticated={isAuthenticated}/>
                            </div>
                        </div>
                    </div>
                }

                {/* Section: Form */}
                <PagePrompt type="home" onSubmitFunc={(args) => handleOpenWidget('page-form', args)}/>

            </div>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        tokens: {
            token: state.tokens.token,
            idToken: state.tokens.idToken,
            refreshToken: state.tokens.refreshToken
        },
    };
};

Home.propTypes = {
    keycloak: PropTypes.any,
    keycloakReady: PropTypes.any,
    tokens: PropTypes.any,
};

export default withKeycloak(
    connect(mapStateToProps)(Home)
);

export const XRATHUS_TEAM = {
    'rekha-patel': {
        featured: true,
        order: 1,
        name: 'Rekha Patel',
        role: 'Founder and President',
        thumb: 'Team-Rekha-Patel.jpg',
        link: 'https://www.linkedin.com/in/rekhapatelxrathus/'
    },
    'pradeep-anand': {
        featured: false,
        order: 2,
        name: 'Pradeep Anand',
        role: 'Advisor',
        thumb: 'Team-Pradeep-Anand.jpg',
        link: 'https://www.linkedin.com/in/pradeepanand/'
    },
    'matt-bell': {
        featured: false,
        order: 3,
        name: 'Matt Bell',
        role: 'Advisor',
        thumb: 'Team-Matt-Bell.jpg',
        link: 'https://www.linkedin.com/in/mattbell/'
    },
    'hani-elshahawi': {
        featured: false,
        order: 4,
        name: 'Hani Elshahawi',
        role: 'Advisor',
        thumb: 'Team-Hani-Elshahawi.jpg',
        link: 'https://www.linkedin.com/in/hani-elshahawi/'
    },
    'lynette-kiss': {
        featured: false,
        order: 5,
        name: 'Lynnette Kiss',
        role: 'CFO (fractional)',
        thumb: 'Team-Lynnette-Kiss.jpg',
        link: 'https://www.linkedin.com/in/lynnette-m-kiss-cpa-aa4a5a44/'
    },
    'egidio-marotta': {
        featured: false,
        order: 6,
        name: 'Egidio Marotta',
        role: 'Advisor',
        thumb: 'Team-Egidio-Marotta.jpg',
        link: 'https://www.linkedin.com/in/egidio-ed-marotta-8182021a9/'
    },
    'susan-nash': {
        featured: false,
        order: 7,
        name: 'Susan Nash',
        role: 'Advisor',
        thumb: 'Team-Susan-Nash.jpg',
        link: 'https://www.linkedin.com/in/susannash/'
    },
    'denis-potapov': {
        featured: false,
        order: 8,
        name: 'Denis Potapov',
        role: 'Advisor',
        thumb: 'Team-Denis-Potapov.jpg',
        link: 'https://www.linkedin.com/in/denis-potapov-60950a4/'
    },
    'ken-tubman': {
        featured: false,
        order: 9,
        name: 'Ken Tubman',
        role: 'Advisor',
        thumb: 'Team-Ken-Tubman.jpg',
        link: 'https://www.linkedin.com/in/ktubman/'
    }
};

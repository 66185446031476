import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';


const propTypes = {
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    eventInfo: PropTypes.object.isRequired,
    sameDayEvents: PropTypes.number.isRequired,
};


class CalendarWeeklyEvent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        };
    }

    openModalEvent() {
        this.setState({modalOpen: true});
    }

    closeModalEvent() {
        this.setState({modalOpen: false});
    }

    getDisplayedDateAndTime = ( passedProps ) => {
        let tempTimeStart = new Date(passedProps.start_date+" UTC").toLocaleTimeString();
        let tempTimeEnd = new Date(passedProps.end_date+" UTC").toLocaleTimeString();
        let tempDispTime = tempTimeStart.substring(0,tempTimeStart.lastIndexOf(":")) + " " +
            tempTimeStart.substring(tempTimeStart.lastIndexOf(" ")) + " - " + 
            tempTimeEnd.substring(0, tempTimeEnd.lastIndexOf(":")) + " " +
            tempTimeEnd.substring(tempTimeEnd.lastIndexOf(" "));
        let tempDateStart = new Date(passedProps.start_date+" UTC").toDateString().substring(4);
        let tempDispDate = tempDateStart.substring(0,tempTimeStart.lastIndexOf(" ")-1) + ", " + 
            tempDateStart.substring(tempTimeStart.lastIndexOf(" ")-1);

        return [tempDispDate, tempDispTime];
    }

    truncateString(str, n) {
        if (!str)
            return "none";
        if (str.length > n) {
            return str.substring(0, n) + "...";
        } else {
            return str;
        }
    }

    render() {
        const {
            start,
            end,
            value,
            eventInfo,
            sameDayEvents
        } = this.props;

        let content=null;
        let uniqueLabel = eventInfo.title;
        let tempTimeResult=null;
        let tempTimeEnd=null;
        let tempDispTime=null;

        switch (eventInfo.event_type) {
            case "meeting":
            case "session":
                tempTimeResult = this.getDisplayedDateAndTime(eventInfo);
                tempDispTime = tempTimeResult[1];
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        {eventInfo.event_image ? 
                            <img src={eventInfo.event_image} alt={uniqueLabel}
                                className="single-image aapg-pivoting-2021 thumbnail"/> : <></>}
                        <p className="info left">
                            Date: {new Date(eventInfo.start_date+" UTC").toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                        <div className="description left">
                            {this.parseContent(eventInfo.description)}
                        </div>
                        <div className="form-btns center">
                            {eventInfo.discussion_link ? 
                                <a href={eventInfo.discussion_link}
                                    title="Discuss" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap"
                                >Discussion</a> : <></>}
                            {eventInfo.registration_link ? 
                                <a href={eventInfo.registration_link}
                                    title="Register" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap"
                                >Register</a> : <></>}
                        </div>
                    </div>
                );
                break;
            case "challenge-due":
                tempTimeEnd = new Date(eventInfo.end_date).toLocaleTimeString();
                tempDispTime = tempTimeEnd.substring(0, tempTimeEnd.lastIndexOf(":")) + " " +
                    tempTimeEnd.substring(tempTimeEnd.lastIndexOf(" ")) + " (CST)";
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        {eventInfo.event_image ? 
                            <img src={eventInfo.event_image} alt={uniqueLabel}
                                className="single-image aapg-pivoting-2021 thumbnail"/> : <></>}
                        <p className="info left">
                            Date: {new Date(eventInfo.end_date).toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                        <div className="description left">
                            {this.parseContent(eventInfo.description)}
                        </div>
                        <div className="form-btns center">
                            {eventInfo.discussion_link ? 
                                <a href={eventInfo.discussion_link}
                                    title="Discuss" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap"
                                >Discussion</a> : <></>}
                            {eventInfo.registration_link ? 
                                <a href={eventInfo.registration_link}
                                    title="Register" target="_blank" rel="noopener noreferrer"
                                    className="btn btn-primary btn-image-text nowrap"
                                >Register</a> : <></>}
                        </div>
                    </div>
                );
                break;
            default:
                tempTimeResult = this.getDisplayedDateAndTime(eventInfo);
                tempDispTime = tempTimeResult[1];
                content = (
                    <div className="panel">
                        <h3 className="header">{uniqueLabel}</h3>
                        <p className="info left">
                            Date: {new Date(eventInfo.start_date+" UTC").toDateString().substring(4)}
                            <br/>
                            Time: {tempDispTime}
                        </p>
                    </div>
                );
        }

        let totalTopMargin = (sameDayEvents*43).toString()+"px";

        let displayedValue = this.truncateString(value, 15);

        return (
            <div className="event" style={{marginTop: totalTopMargin}} >
                <div className="event" onClick={() => this.openModalEvent()}>
                    <span>{`${start.format('HH:mm')} - ${end.format('HH:mm')}`}</span>
                    <br /><br />
                    {displayedValue}
                </div>
                <Modal
                    classes="open-event" 
                    id="modal-event-show"
                    show={this.state.modalOpen}
                    onClose={() => this.closeModalEvent()}
                >
                    {content}
                </Modal>
            </div>
        );
    }

    parseContent = ( content, contentType="", className="") => {
        if (content) {
            if ("type" in content) {
                switch (content.type) {
                    case "text":
                        if (!("marks" in content))
                            return content.text;
                        else {
                            return this.parseTextStyle(content.text, content.marks, className);
                        }
                    case "heading":
                        if (!("attrs" in content))
                            return this.parseContent(content.content, content.type, className);
                        else {
                            return this.parseHeadingStyle(content.content, content.attrs, className);
                        }
                    case "image":
                        if (content.height && content.width)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height} width={content.width}></img>);
                        else if (content.height)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} height={content.height}></img>);
                        else if (content.width)
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src} width={content.width}></img>);
                        else
                            return (<img alt='' key={content.attrs.src} src={content.attrs.src}></img>);
                    case "bullet_list":
                        if ("content" in content) {
                            return <ul className={className} key={content._uid}>
                                {this.parseContent(content.content, content.type, className)}
                            </ul>;
                        }
                        return null;
                    case "paragraph":
                        if ("content" in content) {
                            //find unique key
                            let tempUniqueKey = content._uid;
                            if ("text" in content.content[0]) {
                                tempUniqueKey = content.content[0].text;
                            }
                            if ("attrs" in content.content[0]) {
                                tempUniqueKey = content.content[0].attrs.src;
                            }
                            return <p className={className} key={tempUniqueKey}>
                                {this.parseContent(content.content, content.type, className)}
                            </p>;
                        }
                        return null;
                    case "horizontal_rule":
                        return <hr className='horizontal-rule'/>;
                    case "ordered_list":
                        if ("content" in content) {
                            return <ol className={className} key={content._uid}>
                                {this.parseContent(content.content, content.type, className)}
                            </ol>;
                        }
                        return null;
                    case "doc":
                    default:
                        if ("content" in content) {
                            return this.parseContent(content.content, content.type, className);
                        }
                        return null;
                }
            }
            else if (Array.isArray(content)) {
                switch (contentType) {
                    case "bullet_list":
                    case "ordered_list":
                        return content.map((item, i) => (
                            <li key={i}>
                                {this.parseContent(item, contentType, className)}
                            </li>
                        ));
                            
                    case "paragraph":
                        return content.map((item) => (
                            this.parseContent(item, contentType, className)
                        ));
                    default:
                        return content.map((item) => (
                            this.parseContent(item, contentType, className)
                        ));
                }
            }
            else return null;
        }
        return null;
    }


    parseHeadingStyle = (content, style, className="" ) => {
        if (Array.isArray(style)) {
            for (let i = 0; i < style.length; i++) {
                content = this.parseHeadingStyle(content, style[i].level, className);
            }
            return content;
        }
        else {
            let tempStyle = style;
            if ('level' in style) {
                tempStyle = style.level;
            }
            switch (tempStyle) {
                case 1:
                    return (<h1>{this.parseContent(content, "", className)}</h1>);
                case 2:
                    return (<h2>{this.parseContent(content, "", className)}</h2>);
                case 3:
                default:
                    return (<h3>{this.parseContent(content, "", className)}</h3>);
            }
        }
    }


    parseTextStyle = (content, style, className="", uniqueId="") => {
        if (Array.isArray(style)) {
            for (let i = 0; i < style.length; i++) {
                content = this.parseTextStyle(content, style[i].type, className, uniqueId);
            }
            return content;
        }
        else {
            switch (style) {
                case "italic":
                    return (<i key={content}>{content}</i>);
                case "bold":
                    return (<strong key={content}>{content}</strong>);
                default:
                    return (<strong key={content}>{content}</strong>);
            }
        }
    }
}

CalendarWeeklyEvent.propTypes = propTypes;
export default CalendarWeeklyEvent;
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import IconXrathusArrow from '../partials/images/IconXrathusArrow';
import '../css/slideshow.css';

class GettingStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide: 0,
            total: 6
        };
    }

    navigate = (dir, idx) => {
        let slide = this.state.slide;
        const max = this.state.total - 1;
        if (dir === 'prev') {
            slide = slide - 1;
        }
        if (dir === 'next') {
            slide = slide + 1;
        }
        if (dir === 'slide') {
            slide = idx;
        }
        if (slide < 0) {
            slide = 0;
        }
        if (slide >= max) {
            slide = max;
        }
        this.setState({
            slide: slide
        });
    };

    renderIndicators = () => {
        return (
            Array(this.state.total).fill().map((item, index) => (
                <span
                    key={`indicator-${index}`}
                    className={`indicator ${(index === this.state.slide) ? ' active' : ''}`}
                    onClick={() => this.navigate('slide', index)}
                >
                    {index}
                </span>
            ))
        );
    };

    render() {
        const {
            slide,
            total
        } = this.state;

        return (
            <>
                <div className="content faq">
                    <h1 className="page-title">
                        Getting Started
                    </h1>

                    <div id="slideshow-getting-started" className="slides-container">

                        <div className={`slide${(slide === 0) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12 col-sm-12 center">
                                    <p><strong>Welcome to the Xrathus Communities Platform!</strong></p>
                                </div>
                                <div className="col col-xs-12 col-sm-3"></div>
                                <div className="col col-xs-12 col-sm-6 center">
                                    <img className="tall-6" src="/images/Getting-Started-2-Home.jpg" alt="Welcome to Xrathus"/>
                                </div>
                                <div className="col col-xs-12 col-sm-3"></div>
                            </div>
                        </div>

                        <div className={`slide${(slide === 1) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12 center">
                                    <p>First, create your Xrathus Account. From the Xrathus Homepage
                                        invoke <strong>Sign Up with Email</strong> or <strong>Sign In using Google,
                                            Microsoft, and/or GitHub</strong> to create a Xrathus Account.</p>
                                    <img src="/images/Getting-Started-3-Join.jpg" alt="Join Xrathus"/>
                                </div>
                            </div>
                        </div>

                        <div className={`slide${(slide === 2) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12">
                                    <p>To participate in the community, review
                                        the <strong>Xrathus Terms of Use</strong> and provide requested information
                                        to create your personal Xrathus Account.</p>
                                </div>
                                <div className="col col-xs-12 col-sm-6">
                                    <img className="tall-6" src="/images/Getting-Started-4-Register.jpg" alt="Registration Screen"/>
                                </div>
                                <div className="col col-xs-12 col-sm-6 vertical-center">
                                    <p className="detail"><strong>Upon receipt of your sign-in information, you will
                                        immediately receive an automated email to verify your choice of Email ID.
                                        Check your junk email or spam email in case you do not immediately receive
                                        the verification email. Once you verify your Email ID, your Xrathus Account
                                        will be activated and ready for immediate access.</strong></p>
                                    <p>
                                        <img className="tall-6" src="/images/Getting-Started-4-Email.jpg" alt="Registration Email"/>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={`slide${(slide === 3) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12">
                                    <p>For all your future visits to the Xrathus Communities Platform, you
                                        can <strong>Sign-in</strong> with your credentials using the Sign-In link
                                        located at the top right-hand corner of the Xrathus Communities home page.</p>
                                </div>
                                <div className="col col-xs-12 col-sm-6 center">
                                    <img className="tall-6" src="/images/Getting-Started-5-Home.jpg" alt="Xrathus Homepage"/>
                                </div>
                                <div className="col col-xs-12 col-sm-6 vertical-center center">
                                    <img className="tall-6" src="/images/Getting-Started-5-Login.jpg" alt="Login Screen"/>
                                </div>
                            </div>
                        </div>

                        <div className={`slide${(slide === 4) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12">
                                    <p>To optimize your journey through Xrathus Communities, you will be presented 
                                        with our five personas at first sign-in. Review our five personas and select 
                                        and save the one persona you most identify with as your <strong>primary persona</strong>. 
                                        You will be enabled to select multiple personas as you progress your skills for the 
                                        other personas.</p>
                                </div>
                                <div className="col col-xs-12 center">
                                    <img className="tall" src="/images/Getting-Started-5-Select-Persona-2.jpg" alt="Select Persona 2"/>
                                </div>
                            </div>
                        </div>

                        <div className={`slide${(slide === 5) ? ' active' : ''}`}>
                            <div className="slide-content">
                                <div className="col col-xs-12 col-sm-12">
                                    <p>Once signed in, you land on the <strong>Connect Dashboard</strong>. This Dashboard is 
                                        identical to the dashboards in the Hub and Training substrates, differentiated by the 
                                        multiplicity of tools available in each.</p>
                                </div>
                                <div className="col col-xs-12 col-sm-12">
                                    <img src="/images/Getting-Started-6-Dashboards.jpg" alt="Dashboards"/>
                                </div>
                            </div>
                        </div>


                        {/* Slide Navigation */}

                        <div className="slides-navigation">
                            <button
                                className="nav prev"
                                onClick={() => this.navigate('prev', -1)}
                                disabled={(slide === 0)}
                            >
                                Previous
                                <IconXrathusArrow/>
                            </button>
                            <button
                                className="nav next"
                                onClick={() => this.navigate('next', -1)}
                                disabled={(slide >= (total - 1))}
                            >
                                Next
                                <IconXrathusArrow/>
                            </button>
                        </div>

                    </div>

                    <div className="slides-indicators">
                        {[...this.renderIndicators()]}
                    </div>

                </div>
            </>
        );
    }
}

export default withRouter(GettingStarted);
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as resourcesUser} from '../../resourcesUser';
import {makePostDataRequest} from '../../utils/postRequest';
import HomePersona from '../HomePersona';
import LoadingIndicator from '../LoadingIndicator';
import RoleDataScientist from '../../css/images/icon-role-Data-Scientist.svg';
import RoleDeveloper from '../../css/images/icon-role-Developer.svg';
import RoleDomain from '../../css/images/icon-role-Domain.svg';
import RoleProductOwner from '../../css/images/icon-role-Product-Owner.svg';
import RoleSponsor from '../../css/images/icon-role-Sponsor.svg';
import {language} from '../../data/language';

/**
 * Form: Select Primary Persona with optional User initialization
 *
 * @return {*}
 * @constructor
 */
export default function FormPersona({onFormComplete, submitType}) {

    const [formStatus, setFormStatus] = useState('form');
    const [currentPersona, setCurrentPersona] = useState(null);
    const [selectedPersona, setSelectedPersona] = useState(null);

    const handleFormChange = (type, e) => {
        if (type === 'hover') {
            if (e) {
                setCurrentPersona(e);
            } else {
                setCurrentPersona(selectedPersona);
            }
        }
        if (type === 'select') {
            let selected = e;
            if (selected === 'data-scientist') {
                selected = 'data_scientist';
            }
            if (selected === 'product-owner') {
                selected = 'product_owner';
            }
            setCurrentPersona(selected);
            setSelectedPersona(selected);
        }
    };

    const disableSubmit = () => {
        return (!selectedPersona || selectedPersona === '');
    };

    const handleSubmit = () => {
        if (selectedPersona !== '') {
            setFormStatus('saving');
            submitForm();
        }
    };

    const submitForm = () => {
        let request;
        const params = {
            persona: selectedPersona
        };
        if (submitType === 'init') {
            // Submit Persona and initialize User
            request = makePostDataRequest(resourcesUser, 'UserInit', 'PUT', params);
        }
        if (submitType === 'persona') {
            // Submit Persona
            request = makePostDataRequest(resourcesUser, 'UserPersona', 'PUT', params);
        }
        request.then(result => {
            if (result && (result.username || result?.status === 'OK')) {
                setFormStatus('success');
                handleFormComplete();
                return Promise.resolve(result);
            } else {
                setFormStatus('fail');
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('User Update Error: ', error);
            setFormStatus('fail');
            return Promise.reject('Server Error');
        });
    };

    const handleFormComplete = () => {
        if (typeof onFormComplete === 'function') {
            onFormComplete(selectedPersona);
        }
    };

    const FormBottom = ({className}) => {
        return (
            <div className={className}>
                {currentPersona &&
                <span className="current">
                    <h6>{language.personas[currentPersona].name}</h6>
                    <p>
                        {language.personas[currentPersona].description}
                    </p>
                </span>
                }
                <p className="message">
                    {formStatus === 'fail' &&
                    <>
                        Sorry, your User Information could not be updated.
                    </>
                    }
                </p>
                <div className="form-btns">
                    {formStatus === 'fail' &&
                    <button type="button" className="btn btn-cancel" onClick={() => handleFormComplete()}>
                        Cancel
                    </button>
                    }
                    {formStatus !== 'success' &&
                    <button
                        type="button"
                        className="btn btn-training uppercase"
                        disabled={!!disableSubmit()}
                        onClick={() => handleSubmit()}
                    >
                        Save Primary Persona
                    </button>
                    }
                </div>
                <LoadingIndicator color="dark" centered={true} active={(formStatus === 'saving')}/>
            </div>
        );
    };

    FormBottom.propTypes = {
        className: PropTypes.string,
    };

    return (
        <div className="form-persona">
            <div className="content full">
                <div className="personas">
                    <div className="detail-diagram">
                        <div className="detail">
                            <h4>Select Xrathus Persona</h4>
                            <h6>Make it your own</h6>
                            <p>
                                {language.personas.default.description}
                            </p>
                            <FormBottom className="nonmobile"/>
                        </div>
                        <div className="diagram">
                            <div className="structure">
                                <div className="bg">
                                </div>
                                <HomePersona
                                    type="developer" typeName="developer"
                                    content={language.personas.developer}
                                    icon={RoleDeveloper}
                                    selected={(selectedPersona === 'developer')}
                                    onHoverFunc={(e) => handleFormChange('hover', e)}
                                    onClickFunc={(e) => handleFormChange('select', e)}
                                />
                                <HomePersona
                                    type="product-owner" typeName="product_owner"
                                    content={language.personas.product_owner}
                                    icon={RoleProductOwner}
                                    selected={(selectedPersona === 'product_owner')}
                                    onHoverFunc={(e) => handleFormChange('hover', e)}
                                    onClickFunc={(e) => handleFormChange('select', e)}
                                />
                                <HomePersona
                                    type="sponsor" typeName="sponsor"
                                    content={language.personas.sponsor}
                                    icon={RoleSponsor}
                                    selected={(selectedPersona === 'sponsor')}
                                    onHoverFunc={(e) => handleFormChange('hover', e)}
                                    onClickFunc={(e) => handleFormChange('select', e)}
                                />
                                <HomePersona
                                    type="domain" typeName="domain"
                                    content={language.personas.domain}
                                    icon={RoleDomain}
                                    selected={(selectedPersona === 'domain')}
                                    onHoverFunc={(e) => handleFormChange('hover', e)}
                                    onClickFunc={(e) => handleFormChange('select', e)}
                                />
                                <HomePersona
                                    type="data-scientist" typeName="data_scientist"
                                    content={language.personas.data_scientist}
                                    icon={RoleDataScientist}
                                    selected={(selectedPersona === 'data_scientist')}
                                    onHoverFunc={(e) => handleFormChange('hover', e)}
                                    onClickFunc={(e) => handleFormChange('select', e)}
                                />
                            </div>
                        </div>
                        <div className="detail mobile">
                            <FormBottom className="mobile"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FormPersona.propTypes = {
    onFormComplete: PropTypes.any,
    submitType: PropTypes.any,
};
import React from 'react';

const ColumnFilter = ({column}) => {

    const {filterValue, setFilter} = column;

    return (
        <span className="x-table-column-filter">
            <input type="text" value={filterValue || ''} onChange={(e) => setFilter(e.target.value)}/>
        </span>
    );
};

export default ColumnFilter;
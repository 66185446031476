import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useForm} from 'react-hook-form';
import {RESOURCES as resourcesUser} from '../../resourcesUser';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import NumberInput from '../fields/NumberInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import {makeGetDataRequest} from '../../utils/getRequest';

/**
 * Form: Edit Project Configuration for specified User
 *
 * @returns {JSX.Element}
 * @constructor
 */
const FormProjectConfigUser = (props) => {

    const projectConfig = props.project_config;

    const {register, errors, formState, handleSubmit, getValues, setValue} = useForm({mode: 'onBlur'});

    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState(null);
    const [userMessage, setUserMessage] = useState(null);
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    const [formStageState, setFormStageState] = useState('form');

    const setDefaultConfigMessage = (type) => {
        if (projectConfig[type]) {
            return ' The default is ' + projectConfig[type] + '.';
        }
        return '';
    };

    const handleUserInput = (e) => {
        setUserName(e?.value ?? null);
        setUser(null);
        setUserMessage(null);
    };

    const getUser = () => {
        const request = makeGetDataRequest(resourcesUser, 'UserInfo', 'GET_ONE', {username: userName});
        request.then(result => {
            if (result) {
                if (result.error) {
                    setUser(null);
                    setUserMessage('A user with the username \'' + userName + '\' was not found.');
                } else if (result.username) {
                    // Set user Configurations to defaults if the user does not already override them
                    let userConfig = {
                        projects: result?.projects ?? props.project_config.projects,
                        max_shares: result?.max_shares ?? props.project_config.max_shares,
                        max_dataset_size: result?.max_dataset_size ?? props.project_config.max_dataset_size,
                        max_volumes: result?.max_volumes ?? props.project_config.max_volumes,
                    };
                    setValue('projects', userConfig.projects);
                    setValue('max_shares', userConfig.max_shares);
                    setValue('max_dataset_size', userConfig.max_dataset_size);
                    setValue('max_volumes', userConfig.max_volumes);
                    setUser(result);
                    setUserMessage(null);
                }

                return Promise.resolve(result);
            } else {
                console.log('User Info Error: No result');
                setUserMessage('A user with the username ' + userName + ' was not found.');
                return Promise.reject('User Info failed');
            }
        }).catch(error => {
            console.log('User Info Error: ', error);
            setUserMessage('A user with the username ' + userName + ' was not found.');
            return Promise.reject('Server Error');
        });
    };

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            !formState.isDirty ||
            formStageState === 'saving' ||
            (errors &&
                (
                    errors.projects ||
                    errors.max_shares ||
                    errors.max_dataset_size ||
                    errors.max_volumes
                ))
        );
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            const userObj = JSON.parse(JSON.stringify(user));
            const projects = parseInt(getValues('projects'), 10);
            const max_shares = parseInt(getValues('max_shares'), 10);
            const max_dataset_size = parseInt(getValues('max_dataset_size'), 10);
            const max_volumes = parseInt(getValues('max_volumes'), 10);
            // Nullify Configuration values that equal the default
            userObj.projects = (projects !== projectConfig.projects) ? projects : null;
            userObj.max_shares = (max_shares !== projectConfig.max_shares) ? max_shares : null;
            userObj.max_dataset_size = (max_dataset_size !== projectConfig.max_dataset_size) ? max_dataset_size : null;
            userObj.max_volumes = (max_volumes !== projectConfig.max_volumes) ? max_volumes : null;

            // Submit form to API
            setFormStageState('saving');
            submitForm(userObj);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resourcesUser, 'User', 'UPDATE', data);
        request.then(result => {
            if (result && result.username) {
                updateSuccessState({success: true, message: ''});
                setFormStageState('success');
                return Promise.resolve(result);
            } else {
                updateSuccessState({success: false, message: 'Sorry, there was an error updating this user\'s Personal Project Configuration.'});
                setFormStageState('fail');
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('User Personal Project Configuration Error: ', error);
            updateSuccessState({
                success: false,
                message: 'Sorry, there was an error updating this user\'s Personal Project Configuration: ' + error
            });
            setFormStageState('fail');
            return Promise.reject('Server Error');
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="xrathus-detail">

                    <div className="item-content full">
                        <div className="tab-content active">
                            <p className="instructions">
                                Override Project Configuration values for a specific user. Find a User by username.
                            </p>

                            {/* Find User */}

                            <div className="row">
                                <div className="form-group col col-xs-6">
                                    <BaseInput
                                        type="text"
                                        id="username"
                                        name="username"
                                        label=""
                                        placeholder="Enter a Username"
                                        changeFunc={(e) => handleUserInput(e)}
                                        className="form-input"
                                    >
                                    </BaseInput><br/>
                                    {userMessage &&
                                    <p className="message">
                                        {userMessage}
                                    </p>
                                    }
                                </div>
                                <div className="form-group col col-xs-6">
                                    <button type="button" className="btn btn-primary"
                                        onClick={() => getUser()}
                                        disabled={(!userName)}
                                    >
                                        Find User
                                    </button>
                                </div>
                            </div>

                            {/* Form Inputs */}

                            <div className={`row ${(user) ? 'active' : 'hidden'}`}>
                                <div className="form-group col col-xs-12 col-md-6">
                                    <NumberInput
                                        id="config-projects"
                                        name="projects"
                                        label="Number of Projects"
                                        instructions={`The maximum number of Personal Projects this user may create.${setDefaultConfigMessage('projects')}`}
                                        min={0}
                                        max={100}
                                        step={1}
                                        aria-describedby="error-projects-required"
                                        aria-invalid={errors.projects ? 'true' : 'false'}
                                        ref={register({
                                            required: (<FormError id="required" text="A Projects value is required"/>)
                                        })}
                                        className={`form-input ${errors.projects ? 'input-error' : ''}`}
                                    >
                                        {errors.projects && errors.projects.message}
                                    </NumberInput>
                                </div>
                                <div className="form-group col col-xs-12 col-md-6">
                                    <NumberInput
                                        id="config-max-shares"
                                        name="max_shares"
                                        label="Max Number of Shares"
                                        instructions={`The maximum number of times this user may share a Project with other users.${setDefaultConfigMessage('max_shares')}`}
                                        min={0}
                                        max={1000}
                                        step={1}
                                        aria-describedby="error-max_shares-required"
                                        aria-invalid={errors.max_shares ? 'true' : 'false'}
                                        ref={register({
                                            required: (<FormError id="required" text="A value is required"/>)
                                        })}
                                        className={`form-input ${errors.max_shares ? 'input-error' : ''}`}
                                    >
                                        {errors.max_shares && errors.max_shares.message}
                                    </NumberInput>
                                </div>
                                <div className="form-group col col-xs-12 col-md-6">
                                    <NumberInput
                                        id="config-max-dataset-size"
                                        name="max_dataset_size"
                                        label="Max Dataset Size"
                                        instructions={`The maximum size (in GB) of a Dataset and its files.${setDefaultConfigMessage('max_dataset_size')}`}
                                        min={1}
                                        max={100}
                                        step={1}
                                        aria-describedby="error-max_dataset_size-required"
                                        aria-invalid={errors.max_dataset_size ? 'true' : 'false'}
                                        ref={register({
                                            required: (<FormError id="required" text="A value is required"/>)
                                        })}
                                        className={`form-input ${errors.max_dataset_size ? 'input-error' : ''}`}
                                    >
                                        {errors.max_dataset_size && errors.max_dataset_size.message}
                                    </NumberInput>
                                </div>
                                <div className="form-group col col-xs-12 col-md-6">
                                    <NumberInput
                                        id="config-max-volumes"
                                        name="max_volumes"
                                        label="Max Number of Volumes"
                                        instructions={`The maximum number of versioned volumes of a Dataset this user may create.${setDefaultConfigMessage('max_volumes')}`}
                                        min={0}
                                        max={1000}
                                        step={1}
                                        aria-describedby="error-max_volumes-required"
                                        aria-invalid={errors.max_volumes ? 'true' : 'false'}
                                        ref={register({
                                            required: (<FormError id="required" text="A value is required"/>)
                                        })}
                                        className={`form-input ${errors.max_volumes ? 'input-error' : ''}`}
                                    >
                                        {errors.max_volumes && errors.max_volumes.message}
                                    </NumberInput>
                                </div>
                            </div>
                        </div>

                        {/* Messaging and Actions */}

                        <div className={`form-btns right ${(user) ? 'active' : 'hidden'}`}>
                            {formStageState === 'saving' &&
                            <>
                                <LoadingIndicator color="dark" centered={false} active={true}/>
                                <p className="message">Saving User Configuration...</p>
                            </>
                            }
                            {formStageState === 'success' &&
                            <p className="message">User Project Configuration saved</p>
                            }
                            {formStageState === 'fail' &&
                            <>
                                {successState.message !== '' &&
                                <p className="message">
                                    {successState.message}
                                </p>
                                }
                                {successState.message === '' &&
                                <p className="message">
                                    Sorry, there was an issue saving this user&apos;s Project Configuration.
                                </p>
                                }
                            </>
                            }

                            <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                Save User Configuration
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        project_config: state.project_config
    };
};

FormProjectConfigUser.propTypes = {
    project_config: PropTypes.any,
};

export default connect(
    mapStateToProps
)(FormProjectConfigUser);
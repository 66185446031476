import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {setSidebarVisibility} from '../store/actions';
import Emitter from '../utils/eventEmitter';
import {checkAuthentication} from '../utils/authFunctions';
import {copyArray} from '../utils/dataFunctions';
import {isFullWidth, openHelpWidgetWithParams} from '../utils/pageFunctions';
//temporary mobile fix without activating ismobile sitewide
import {isMobile} from '../utils/tempFunctions';
import {hasGroup} from '../utils/tokenFunctions';
import ExpandIconButton from '../partials/buttons/ExpandIconButton';
import {navigationSidebar} from '../data/navigation';
import Storyblok from '../utils/storyblok-client';
import '../css/sidebar.css';

/**
 * Retrieve designed navigation object and its links
 *
 * @param path
 * @return {{}|*}
 */
const loadSidebarNavigation = (path) => {
    if (!path) {
        return {};
    }
    // Accommodate routes with trailing slashes
    if (path.slice(-1) === '/') {
        path = path.substr(0, path.length - 1);
    }
    // Retrieve the appropriate navigation object
    const paths = path.split('/');
    // Set the key from the main path (usually a Substrate)
    let navKey = Object.keys(navigationSidebar).find(r => r === paths[2]);
    // Override the key if the path needs a different menu within the Substrate
    let subNavKey = Object.keys(navigationSidebar).find(r => r === paths[2] + '-' + paths[3]);
    if (subNavKey) {
        navKey = subNavKey;
    }
    // If no navigation applies, use the default
    if (!navKey) {
        navKey = 'default';
    }
    return copyArray(navigationSidebar[navKey]);
};

/**
 * Recursively create an array of all links
 *
 * @param data
 * @param outputArray
 */
function createLinksList(data, outputArray) {
    data.forEach(function (element) {
        if (element.route) {
            outputArray.push({slug: element.slug, route: element.route});
        }
        if (element.links) {
            createLinksList(element.links, outputArray);
        }
    });
}

const handleOpenWidget = (section) => {
    let params = {
        type: 'Demo Request'
    };
    if (section === 'schedule') {
        params.subject = 'Schedule a Call';
    }
    openHelpWidgetWithParams(params, true);
};

/**
 * A component to display page-specific headers and navigation
 */
class AppSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseAll: true,
            navObj: null,
            openMenus: [],
            path: '/',
            collabMenu: [],
            kbMenu: [],
        };
    }

    async getStoryblokListofSidebarContent(substrate, storyblokSection, notClustersCollab) {
        let slugSubstrate = substrate;
        if (!notClustersCollab) {
            slugSubstrate = "nav-clusters";
        }

        //load in full list and filters of collaborators from storyblok
        let params = {
            version: process.env.REACT_APP_STORYBLOK_API_KEY_TYPE,
            cv: Date.now(),
            starts_with: storyblokSection + '/',
            per_page: 25,
            excluding_fields: "body,content,bread_crumb_steps_to_jump,component,custom_body_css," +
                "custom_link,custom_logo_css,dataset,full_slug,group_filter,header," +
                "hide_bread_crumbs,hide_logo,image,intro,logo,no_document_margins," +
                "no_table_of_contents,only_available_to_user_groups," +
                "publisher_logo,publisher_name,require_knowledge_base,subtitle," +
                "tags,title,title_on_page,title_on_summary_page,title_style,_editable,_uid"
        };

        const resp = await Storyblok.get(`cdn/stories/`, params);

        let totalStories = [];
        if (resp && resp.data && resp.data.stories) {
            totalStories = resp.data.stories;
            const perPage = 25;
            if (resp.headers && resp.headers.total && resp.headers.total > perPage) {
                let totalPages = Math.ceil(resp.headers.total / perPage);
                for (let i = 2; i <= totalPages; i++) {
                    params.page = i;
                    const respAdditional = await Storyblok.get(`cdn/stories/`, params);
                    totalStories = totalStories.concat(respAdditional.data.stories);
                }
            }
        }

        //step 1: create parent menus from slugs
        let uniqueFilters = [];
        let formattedFilters = [];
        for (let i = 0; i < totalStories.length; i++) {
            let tempString = totalStories[i].full_slug;
            let tempFilter = tempString.split('/')[1];
            if (!uniqueFilters.includes(tempFilter)) {
                let formattedTitle = tempFilter.charAt(0).toUpperCase() + tempFilter.slice(1);
                let formattedFilter = {};
                if (totalStories[i].name !== formattedTitle) {
                    formattedFilter = {
                        title: formattedTitle,
                        slug: slugSubstrate + '-' + storyblokSection + '-' + tempFilter,
                        substrateClass: substrate,
                        route: notClustersCollab ? '/x/' + substrate + '/' + storyblokSection + '/' + tempFilter : null,
                        priority: totalStories[i].content.priority,
                        links: [
                            {
                                title: totalStories[i].name,
                                slug: slugSubstrate + '-' + storyblokSection + '-' + tempFilter + '-' + totalStories[i].slug,
                                substrateClass: substrate,
                                priority: totalStories[i].content.priority,
                                route: notClustersCollab ? '/x/' + substrate + '/' + storyblokSection + '/' + tempFilter + '/' + totalStories[i].slug :
                                    totalStories[i].content.cluster_link,
                                external: notClustersCollab ? false : true,
                            }
                        ]
                    };
                } else {
                    formattedFilter = {
                        title: formattedTitle,
                        slug: slugSubstrate + '-' + storyblokSection + '-' + tempFilter,
                        substrateClass: substrate,
                        priority: totalStories[i].content.priority,
                        route: notClustersCollab ? '/x/' + substrate + '/' + storyblokSection + '/' + tempFilter : null,
                    };
                }

                formattedFilters.push(formattedFilter);
                uniqueFilters.push(tempFilter);
            } else {
                if (formattedFilters[uniqueFilters.indexOf(tempFilter)]['links']) {
                    formattedFilters[uniqueFilters.indexOf(tempFilter)]['links'].push(
                        {
                            title: totalStories[i].content.title_in_sidebar ? totalStories[i].content.title_in_sidebar : totalStories[i].name,
                            slug: slugSubstrate + '-' + storyblokSection + '-' + tempFilter + '-' + totalStories[i].slug,
                            substrateClass: substrate,
                            priority: totalStories[i].content.priority,
                            route: notClustersCollab ? '/x/' + substrate + '/' + storyblokSection + '/' + tempFilter + '/' + totalStories[i].slug :
                                totalStories[i].content.cluster_link,
                            external: notClustersCollab ? false : true,
                        }
                    );
                }
            }
        }

        //step 2: sort filters by priority
        formattedFilters.sort(function (a, b) {
            if (!a.priority && !b.priority) return 0;
            if (!b.priority || (a.priority < b.priority)) return -1;
            if (!a.priority || (a.priority > b.priority)) return 1;
            return 0;
        });

        //step 3: alphabetize all the elements inside the filters
        for (let i = 0; i < formattedFilters.length; i++) {
            if ('links' in formattedFilters[i])
                formattedFilters[i]['links'].sort(function (a, b) {
                    if (a.title < b.title) return -1;
                    if (a.title > b.title) return 1;
                    return 0;
                });
        }

        return formattedFilters;
    }


    async componentDidMount() {
        const collabMenu = await this.getStoryblokListofSidebarContent('connect', 'collaborators', true);
        const kbMenu = await this.getStoryblokListofSidebarContent('training', 'knowledge-base', true);
        const clustersCollabMenu = await this.getStoryblokListofSidebarContent('connect', 'collaborators', false);

        this.setState({collabMenu: collabMenu, kbMenu: kbMenu, clustersCollabMenu: clustersCollabMenu});
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.location.pathname && nextProps.location.pathname !== prevState.path) {

            // When path is updated, store the desired navigation object
            const path = nextProps.location.pathname;
            let navObj = loadSidebarNavigation(path);
            if (navObj && navObj.slug === 'connect') {
                //inject loaded collaborators into the clusters list
                navObj.links[1].links[1].links = prevState.clustersCollabMenu;

                //also inject loaded collaborators into the collaborators list
                navObj.links[2].links = prevState.collabMenu;
            }
            if (navObj && navObj.slug === 'training') {
                //inject loaded knowledge base menu into the knowledge base list
                navObj.links[6].links = prevState.kbMenu;
            }

            // Add any nav items containing the current route to open menus
            let openMenus = JSON.parse(JSON.stringify(prevState.openMenus));
            // If open menus only has challenges but the page is overview, add overview to open menus to make it highlighted
            if (path.indexOf("hub") !== -1 && path.indexOf("overview") !== -1) {
                openMenus = ["hub-overview"];
            }
            let linksList = [];
            createLinksList(navObj.links, linksList);
            for (let i = 0; i < linksList.length; i++) {
                let item = linksList[i];
                // If this link's route is current and is not in the list, add it
                //exception added for nav-dashboard
                if (openMenus.findIndex(r => r === item.slug) === -1) {
                    if (path.indexOf(item.route) !== -1 && item.slug.substring(0, 13) !== "nav-dashboard")
                        openMenus.push(item.slug);
                } else {
                    //exception made for open projects, since the route has $project_id variable
                    if (path.indexOf(item.route) === -1 && item.route.indexOf('$project_id') === -1)
                        openMenus.splice(openMenus.findIndex(r => r === item.slug), 1);
                }
            }

            return {
                navObj: navObj,
                openMenus: openMenus,
                path: path
            };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const wasAuthenticated = checkAuthentication(prevProps.tokens.token, prevProps.keycloak.token, prevProps.keycloak.authenticated);
        const isAuthenticated = checkAuthentication(this.props.tokens.token, this.props.keycloak.token, this.props.keycloak.authenticated);
        const pageIsFullWidth = isFullWidth(this.props.location);
        const siteIsMobile = (isMobile() === true);

        if (!siteIsMobile) {
            // Set Sidebar to open if newly logged in
            if (isAuthenticated && !wasAuthenticated && !pageIsFullWidth) {
                this.props.setSidebarVisibility(true);
            }
            // Ensure Sidebar is open if authenticated on a non-full-width page
            if (isAuthenticated && !pageIsFullWidth && !this.props.sidebarOpen) {
                this.props.setSidebarVisibility(true);
            }
        } else {
            // If navigated to a new page, collapse sidebar
            if (prevProps.location !== this.props.location) {
                this.props.setSidebarVisibility(false);
            }
        }

        if (!isAuthenticated && this.props.sidebarOpen) {
            this.props.setSidebarVisibility(false);
        }
    }

    componentWillUnmount() {
    }

    /**
     * Assemble Sidebar navigation menus and active states
     *
     * @param path
     * @return {[]|*}
     */
    createNavigation = (path) => {
        if (!path) {
            return (
                <></>
            );
        }

        let navObj = this.state.navObj;
        if (navObj && navObj.slug === "connect") {
            //inject loaded collaborators into the clusters list
            navObj.links[1].links[1].links = this.state.clustersCollabMenu;

            //also inject loaded collaborators into the collaborators list
            navObj.links[2].links = this.state.collabMenu;
        }
        if (navObj && navObj.slug === 'training') {
            //inject loaded knowledge base menu into the knolwedgebase list
            navObj.links[6].links = this.state.kbMenu;
        }
        let nav = [];

        if (navObj) {
            // Create a header
            nav.push(this.createNavHeader(navObj.path, navObj.title, navObj.path));
        } else {
            nav.push(<h2 key="nav-sidebar-title-blank">&nbsp;</h2>);
        }

        if (navObj) {
            // Create recursive menu
            nav.push(this.createNavLinks(navObj.links, path, navObj.slug));
        }

        return nav;
    };

    /**
     * Generate a header for the current path
     *
     * @param path
     * @param label
     * @param key
     * @return {*}
     */
    createNavHeader = (path, label, key) => {
        if (!label) {
            return (
                <h2 key={`nav-sidebar-header-${key}`}>
                </h2>
            );
        }
        let hClass = (path.slice(0, 1) === '/') ? path.substr(1, path.length) : path;
        return (
            <Link to={path} key={`nav-sidebar-header-${key}`}>
                <h2 className={hClass}>
                    <span>
                        <i className="fa fa-arrow-right" aria-hidden="true"/>
                        {label}
                    </span>
                </h2>
            </Link>
        );
    };

    /**
     * Generate a menu from an array of links
     *
     * @param links
     * @param path
     * @param slug
     * @return {[]}
     */
    createNavLinks = (links, path, slug) => {
        let nav = [];
        let linkItems = [];
        if (links && links.length) {
            for (let i = 0; i < links.length; i++) {
                let item = links[i];
                // Add the link if it is available to this user
                const role = item.role;
                let allowItem = true;
                if (role) {
                    if (
                        (role === 'sysop' && !this.props.user.is_sysop) ||
                        !hasGroup(this.props.user.groups, role)
                    ) {
                        allowItem = false;
                    }
                }
                if (allowItem) {
                    let link = this.createNavLink(item, path);
                    linkItems.push(link);
                }
            }
        }
        let openClass = (this.openMenusIndex(slug) !== -1) ? ' open openul' : undefined;
        nav.push(
            <ul key={'nav-sidebar-list-' + path} className={openClass}>
                {linkItems}
            </ul>
        );
        return nav;
    };

    /**
     * Create a link item with optional sublinks
     *
     * @param item
     * @param path
     * @return {*}
     */
    createNavLink = (item, path) => {
        let itemRoute = item.route;

        // If this path is in current route, item is active
        let isActive = (path === itemRoute || path.indexOf(itemRoute) !== -1);
        let activeClass = (isActive) ? ' active' : '';

        let iconModule, icon;
        if (item.icon) {
            iconModule = require('../css/' + item.icon);
            if (iconModule) {
                icon = iconModule.default;
            }
        }

        // Add substrate class if present
        let substrateClass = '';
        if (item.substrateClass) {
            substrateClass = ' ' + item.substrateClass;
        }

        // Link with function call instead of route:
        if (item.func) {
            const funcArgs = (item.funcArgs) ? item.funcArgs : null;
            return (
                <li key={`nav-sidebar-func-${item.slug}`} className={`${substrateClass}`}>
                    <button onClick={(e) => this.handleNavFunctionClick(e, item.func, funcArgs)}
                            className="sidebar-button">
                        {icon &&
                            <div className="sidebar-icon">
                                <div className="center"><img src={icon} alt={item.title}/></div>
                            </div>
                        }
                        {item.title}
                    </button>
                </li>
            );
        }

        // Link to external URL:
        if (item.external) {
            return (
                <li key={`nav-sidebar-external-${item.slug}`} className={`${substrateClass}`}>
                    <a href={itemRoute} title={item.title} target="_blank" rel="noopener noreferrer">
                        {icon &&
                            <div className="sidebar-icon">
                                <div className="center"><img src={icon} alt={item.title}/></div>
                            </div>
                        }
                        {item.title}
                    </a>
                </li>
            );
        }

        // Add submenu if present:
        let links = null;
        let isOpen = false;
        let childrenClass = '';
        if (item.links && item.links.length > 0) {
            links = [];
            links = this.createNavLinks(item.links, path, item.slug);
            childrenClass = ' has-children';
        }

        // If this path is in the list of open menus, submenu is open
        isOpen = (this.openMenusIndex(item.slug) !== -1);
        let openClass = (isOpen) ? ' open openli' : '';

        // If no link is provided (likely an item with children):
        // @TODO: modify this to be better at toggling and navigating
        if (!itemRoute) {
            return (
                <li key={`nav-sidebar-nolink-${item.slug}`}
                    className={`${substrateClass} no-link${childrenClass}${openClass}`}>
                    <button title={item.title}
                            onClick={() => this.handleNavToggle(!isOpen, item, path)}
                            className="sidebar-button"
                    >
                        {icon &&
                            <div className="sidebar-icon">
                                <div className="center"><img src={icon} alt={item.title}/></div>
                            </div>
                        }
                        {item.title}
                    </button>
                    {links &&
                        <ExpandIconButton active={isActive} open={isOpen}/>
                    }
                    {links}
                </li>
            );
        }

        // Update the route if it contains a variable
        if (item.replace && itemRoute.indexOf(item.replace) !== -1) {
            // Challenge/Project ID
            if (item.replace === '$project_id') {
                const project_id = this.props.project_id;
                if (project_id) {
                    let route = itemRoute;
                    itemRoute = route.replace(item.replace, project_id);
                }
            }
        }

        // Return a link with the route
        return (
            <li key={`nav-sidebar-link-${item.slug}`}
                className={`${substrateClass} ${childrenClass}${activeClass}${openClass}`}
            >


                <button title={item.title}
                        onClick={() => this.handleNavToggle(!isOpen, item, path)}
                        className="sidebar-button"
                >
                    <Link to={itemRoute} title={item.title}>
                        {icon &&
                            <div className="sidebar-icon">
                                <div className="center"><img src={icon} alt={item.title}/></div>
                            </div>
                        }
                        {item.title}
                    </Link>
                </button>
                {links &&
                    <ExpandIconButton active={isActive} open={isOpen}/>
                }
                {links}
            </li>
        );
    };

    /**
     * Get index of item in list of Open Menus
     *
     * @param item
     * @return {number}
     */
    openMenusIndex = (item) => {
        return this.state.openMenus.findIndex(r => r === item);
    };

    /**
     * Expand or collapse menu items
     *
     * @param expand
     * @param item
     */
    handleNavToggle = (expand, item) => {
        let openMenus = JSON.parse(JSON.stringify(this.state.openMenus));
        let updated = false;
        let idx = this.openMenusIndex(item.slug);

        if (expand === true && idx === -1) {
            if (this.state.collapseAll) {
                // Collapse any open items (except parent of this item)
                for (let i = (openMenus.length - 1); i >= 0; i--) {
                    if (item.slug.indexOf(openMenus[i]) === -1) {
                        openMenus.splice(i, 1);
                    }
                }
            }
            openMenus.push(item.slug);
            updated = true;
        }

        //making exceptions for nonexpandables on other pages
        if (expand === false && idx !== -1 && item.links) {
            // Collapse this item
            openMenus.splice(idx, 1);
            if (this.state.collapseAll) {
                // Collapse any children of this item
                for (let i = (openMenus.length - 1); i >= 0; i--) {
                    if (openMenus[i].indexOf(item.slug) !== -1) {
                        openMenus.splice(i, 1);
                    }
                }
            }
            updated = true;
        }

        if (updated) {
            this.setState({
                openMenus: openMenus
            });
        }
    };

    /**
     * Process sidebar navigation item with a function callback
     *
     * @param event
     * @param funcName
     * @param funcArgs
     */
    handleNavFunctionClick = (event, funcName, funcArgs) => {
        event.preventDefault();

        if (funcName === 'addChallenge') {
            Emitter.emit('addChallenge');
        }
        if (funcName === 'setChallengeTab') {
            Emitter.emit('setChallengeTab', funcArgs);
        }
        if (funcName === 'openHelpWidget') {
            handleOpenWidget('schedule');
        }
    };

    handlePageResize = () => {
    };

    render() {
        const {
            location,
            sidebarOpen,
        } = this.props;

        const isHomepage = (location.pathname === '/');
        const pageIsFullWidth = isFullWidth(location);
        const siteIsMobile = (isMobile() === true);
        const openClass = (
            (siteIsMobile && sidebarOpen) ||
            (!siteIsMobile && sidebarOpen && !isHomepage && !pageIsFullWidth)
        ) ? 'open' : 'closed';
        let modalClass = (sidebarOpen && siteIsMobile) ? ' toggled-on' : '';

        return (
            <>
                <div className={`sidebar-modal modal${modalClass}`}>
                    <div className="modal-overlay" onClick={() => this.props.setSidebarVisibility(false)}>
                    </div>
                </div>
                <div className={`sidebar ${openClass}`}>
                    {location && location.pathname &&
                        <>
                            {[...this.createNavigation(location.pathname)]}
                        </>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    let tokens = state.tokens;
    if (tokens && sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            project_id: state.project_id,
            sidebarOpen: sidebarOpen,
            tokens: {
                token: tokens.token
            },
            user: state.user
        };
    }
    return {
        project_id: state.project_id,
        sidebarOpen: sidebarOpen,
        tokens: {
            token: null
        },
        user: state.user
    };
};

const mapDispatchToProps = {
    setSidebarVisibility: setSidebarVisibility
};

//@TODO: cleanup proptypes to have the right types on each variable
AppSidebar.propTypes = {
    /**
     * Function on Menu Close
     */
    onMenuClose: PropTypes.func,
    /**
     * Is sidebar open?
     */
    sidebarOpen: PropTypes.bool,
    /**
     * Keycloak
     */
    keycloak: PropTypes.any,
    /**
     * Tokens
     */
    tokens: PropTypes.any,
    /**
     * Set sidebar visibility function
     */
    setSidebarVisibility: PropTypes.func,
    /**
     * Location
     */
    location: PropTypes.any,
    /**
     * User
     */
    user: PropTypes.any,
    /**
     * Project ID
     */
    project_id: PropTypes.any,
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AppSidebar)
    )
);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setProjectListRefresh} from '../../store/actions';
import FormProjectConfigEdit from '../../partials/forms/FormProjectConfigEdit';
import FormProjectEdit from '../../partials/forms/FormProjectEdit';
import {inUserList, getProjectPermissions} from '../../utils/projectFunctions';
import '../../css/challenge.css';

/**
 * Page: Edit Project & Datasets forms
 */
class XHubProjectEdit extends Component {
    constructor(props) {
        super(props);
        const pathArr = this.props.location.pathname.split('/');
        const projectId = Number(pathArr.reverse()[1]);
        const projectType = (this.props.location.pathname.indexOf('personal') !== -1) ? 'personal' : 'private';
        this.state = {
            dataId: '',
            formStage: 'project',
            pathArr: pathArr,
            project: null,
            projectConfig: {
                max_dataset_size: null,
                max_shares: null,
                max_volumes: null,
                retrieved: false
            },
            projectId: projectId,
            projectType: projectType,
        };
    }

    // Handle form completion/error events
    handleFormEvent = (e, status, data) => {
        // Store Project values to pass to Edit Configuration form
        if (e === 'project' && status === 'info') {
            this.setState({
                projectConfig: {
                    max_dataset_size: data.max_dataset_size ?? null,
                    max_shares: data.max_shares ?? null,
                    max_volumes: data.max_volumes ?? null,
                    retrieved: true
                }
            });
        }
        if (e === 'project' && status === 'complete') {
            // Upon completed Project, populate the Dataset's data ID
            this.setState({
                dataId: data.data_id
            }, () => {
                // Display the Dataset/Files form
                this.updateFormStage('dataset');
                // Refresh User's Own Projects
                this.props.setProjectListRefresh('personal_own');
            })
        }
    };

    // Set form toggling
    updateFormStage = (stage) => {
        this.setState({
            formStage: stage
        });
    };

    render() {
        const {
            project_config,
            project_list_own,
            project_list_subscribed,
            user
        } = this.props;

        const {
            formStage,
            projectConfig,
            projectId,
            projectType,
        } = this.state;

        const inSubscribedProjects = (inUserList(project_list_subscribed, projectId));
        const projectPermissions = getProjectPermissions(user, project_list_own, projectId, null);

        return (
            <>
                <div className="content xhub project">
                    <h1 className="page-title">
                        Edit Project
                    </h1>

                    {!user.is_sysop && !inSubscribedProjects && !projectPermissions.owner &&
                    <div className="row">
                        <div className="col col-xs-12" key="projects-empty">
                            <div className="panel">
                                You are not authorized to edit this Project.
                            </div>
                        </div>
                    </div>
                    }
                    {(user.is_sysop || inSubscribedProjects || projectPermissions.owner) &&
                    <div className="panel no-padding">
                        <h3 className="header">Project</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormProjectEdit
                                        formStage={formStage}
                                        projectType={projectType}
                                        projectId={projectId}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {user.is_sysop &&
                    <div className="panel no-padding">
                        <h3 className="header">Project Configuration</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormProjectConfigEdit
                                        projectType={projectType}
                                        projectId={projectId}
                                        projectConfig={projectConfig}
                                        defaultConfig={project_config}
                                        onFormEvent={this.handleFormEvent}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_config: state.project_config,
        project_list_own: state.project_list_own,
        project_list_subscribed: state.project_list_subscribed,
        user: state.user
    };
};

const mapDispatchToProps = {
    setProjectListRefresh: setProjectListRefresh
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(XHubProjectEdit));

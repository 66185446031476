import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import FormOrganizationAdd from '../partials/forms/FormOrganizationAdd';
import '../css/challenge.css';

/**
 * Page: Create Organization
 */
class XOrganizationAdd extends Component {

    render() {

        return (
            <>
                <div className="content xconnect challenge">
                    <h1 className="page-title">
                        Create an Organization
                    </h1>

                    <div className="panel no-padding">
                        <h3 className="header">Organization</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormOrganizationAdd/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withRouter(XOrganizationAdd);

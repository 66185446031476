import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setProjectId} from '../../store/actions';
import SubstrateGreeting from '../../partials/SubstrateGreeting';
import StoryblokPage from '../../partials/storyblok/StoryblokPageTemplate';


class XMarketplace extends Component {
    componentDidMount() {
        this.props.setProjectId(null);
    }

    render() {
        return (
            <>
                <div className="content xmarketplace dashboard">
                    <h1 className="page-title">
                        My Marketplace
                    </h1>
                    <SubstrateGreeting substrate="Marketplace"/>
                    <div className="row mt-2">
                        <div className="col col-xs-12">
                            <h3 className="section-header full">Solutions</h3>
                        </div>
                    </div>
                    <div className="xmarketplace">
                        <StoryblokPage url={'dashboards/solutions'}/>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = {
    setProjectId: setProjectId
};

XMarketplace.propTypes = {
    setProjectId: PropTypes.any,
};

export default connect(
    null,
    mapDispatchToProps
)(XMarketplace);

import React, {Component} from 'react';
import '../../css/static.css';

class XHubWorkflows extends Component {
    render() {

        return (
            <>
                <div className="content workflows">
                    <h1 className="page-title">
                        Workflows
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Once available, the Workflows section will provide a visual editor to create,
                                    edit, and extend data science workflows.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubWorkflows;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '../../css/images/icon-Table-Delete.svg';
import Modal from '../modal/Modal';
import {RESOURCES as resources} from '../../resourcesData';
import {makeDeleteDataRequest} from '../../utils/deleteRequest';

const DeleteFileButton = ({record = {}, dataId, onFormEvent}) => {
    const [modalStatus, setModalStatus] = useState(false);

    const handleDeleteConfirmation = () => {
        const filepath = record.cell.row.original.object;
        const params = {
            data_id: dataId,
            filepath: filepath
        };

        const request = makeDeleteDataRequest(resources, 'File', 'DELETE', params);
        request.then(result => {
            if (result) {
                setModalStatus(false);
                if (onFormEvent !== null && typeof onFormEvent === 'function') {
                    onFormEvent({type: 'files', state: 'complete'});
                }
                if (typeof result === 'string') {
                    console.log('Files Error: ', result);
                    return Promise.resolve(result);
                }
                return Promise.resolve(result);
            } else {
                console.log('Dataset Error: No files');
                return Promise.reject('Files failed');
            }
        }).catch(error => {
            console.log('Files Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    return (
        <>
            <div className="btn-icon x-table-icon" onClick={() => setModalStatus(true)}>
                <img src={DeleteIcon} alt="Delete Icon"/>
            </div>
            <Modal
                allowClose={true}
                id="modal-data-delete"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    <h3 className="header">Are you sure you want to delete this file?</h3>
                    <p>File name: {record.cell.row.original.object}</p>
                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleDeleteConfirmation}>YES</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

DeleteFileButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object
};

export default DeleteFileButton;
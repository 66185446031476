import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';
import SectionHeader from "../basic/SectionHeader";
import SectionCollapsible from "./SectionCollapsible";

import '../../../css/storyblok-custom.css';

const slugify = str =>
    str
        .toLowerCase()
        .trim()
    //.replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

const SectionWithHeader = ({
    title,
    style,
    hideTitle,
    content,
    toggleButtonText,
    toggleCustomCss,
    toggleContent,
    storyblokFormatted,
}) => {

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });

    const slugTitle = slugify(title);

    let contentClass = '';

    switch (style) {
        case 'document-toggle':
        case 'document-primary-toggle':
        case 'document-secondary-toggle':
        case 'document-tertiary-toggle':
            contentClass = 'collapsible-content';
            break;
        default:
    }

    if (contentClass === 'collapsible-content') {
        return (
            <div id={slugTitle} ref={ref} className={`${inView ? "active" : ""}`}>
                <SectionCollapsible title={title} style={style} content={content} storyblokFormatted={storyblokFormatted}/>
            </div>
        );
    }
    return (
        <div id={slugTitle} ref={ref} className={`${inView ? "active" : ""}`}>
            <SectionHeader title={title} hideTitle={hideTitle} style={style} 
                toggleButtonText={toggleButtonText} toggleCustomCss={toggleCustomCss} toggleContent={toggleContent}/>
            <div className={contentClass}>
                {storyblokFormatted ?  parseStoryblokContent(content, "", "", []) : content}
            </div>
        </div>
    );
};


SectionWithHeader.propTypes = {
    /**
     * What is the displayed title?
     */
    title: PropTypes.string,
    /**
     * Required style
     */
    style: PropTypes.oneOf([
        'primary', 
        'secondary', 
        'tertiary', 
        'underlined', 
        'panel', 
        //deprecated
        'document', 
        //deprecated
        'document-subheader',
        'document-primary',
        'document-secondary',
        'document-tertiary',
        'document-toggle',
        'document-primary-toggle',
        'document-secondary-toggle',
        'document-tertiary-toggle',
    ]),
    hideTitle: PropTypes.bool,
    /**
     * What is the displayed content?
     */
    content: PropTypes.any,
    /**
     * Is content formatted specifically by Storyblok?
     */
    storyblokFormatted: PropTypes.bool,
    toggleButtonText: PropTypes.string,
    toggleCustomCss: PropTypes.string,
    toggleContent: PropTypes.any,
};

SectionWithHeader.defaultProps = {
    title: "",
    style: 'primary',
    hideTitle: false,
    content: [],
    storyblokFormatted: false,
    toggleButtonText: "",
    toggleCustomCss: "",
    toggleContent: null,
};

export default SectionWithHeader;

import Config from '../config';

/**
 * Determine mobile state based on width
 *
 * @return {boolean}
 */
export const isMobile = () => {
    // @TODO implement once approved
    return false;
    // return (window.innerWidth < Config.pageSize.mobile);
};

/**
 * Return small mobile state based on width
 *
 * @return {boolean}
 */
export const isMobileSmall = () => {
    // @TODO implement once approved
    return false;
    // return (window.innerWidth < Config.pageSize.small);
};

/**
 * Get current body classes
 *
 * @return {DOMTokenList}
 */
export const getBodyClasses = () => {
    return document.body.classList;
};

/**
 * Determine if body has a specific class
 *
 * @param className
 * @return {boolean}
 */
export const bodyHasClass = (className) => {
    return document.body.classList.contains(className);
};

/**
 * Add a class to the body
 *
 * @param classes
 */
export const setBodyClasses = (classes) => {
    clearBodyClasses();
    document.body.classList.add(...classes);
};

/**
 * Remove all classes from body
 */
const clearBodyClasses = () => {
    let classList = getBodyClasses();
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
};

/**
 * Set body classes from listed routes
 *
 * @param location
 * @param isAuthenticated
 */
export const setPathElements = (location, isAuthenticated) => {
    if (location) {
        let pathname = location.pathname;
        let hash = location.hash;
        let classArr = [];
        if (pathname && pathname.indexOf('/') !== -1) {
            let pathArray = pathname.split('/');
            if (pathArray[1] === 'x') {
                // Add all path elements
                for (let p = 2; p < pathArray.length; p++) {
                    if (pathArray[p] !== '') {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                }
            } else {
                // For Pages without /x/, use the first value
                if (pathArray[1] !== '') {
                    classArr.push(pathArray[1]);
                }
                for (let p = 1; p < pathArray.length - 1; p++) {
                    if (pathArray[p] !== '') {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                }
            }
            // Add any hash elements
            let hashArray = hash.split('/');
            for (let h = 1; h < hashArray.length; h++) {
                classArr.push(hashArray[h].replace('%20', '-'));
            }
        } else {
            classArr.push(pathname);
        }

        // Set a class for the body based on route
        if (pathname === '/') {
            classArr.push('home', 'full-width');
        }

        // Add a class for substrate pages
        if (Config.routesSubstrate.findIndex(r => r === classArr[0]) !== -1) {
            classArr.push('substrate');
        }

        // Add a class for full-width pages
        if (isFullWidth(location)) {
            classArr.push('full-width');
        }

        // Add a class for mobile
        if (isMobile() === true) {
            // @TODO implement once approved
            // classArr.push('mobile');
        }


        // Add a class for authenticated state
        if (isAuthenticated) {
            classArr.push('authenticated')
        }

        setBodyClasses(classArr);
    }
};

export const getSubstrate = (location) => {
    if (location) {
        let pathArray = location.pathname.split('/');
        for (let p = 0; p < pathArray.length - 1; p++) {
            if (Config.routesSubstrate.findIndex(r => r === pathArray[p]) !== -1) {
                return pathArray[p];
            }
        }
    }
    return '';
};

export const isFullWidth = (location) => {
    if (location.pathname) {
        const routes = Config.routesFullWidth;
        for (let r = 0; r < routes.length; r++) {
            if (location.pathname.indexOf(routes[r]) !== -1) {
                return true;
            }
        }
    }
    return false;
};

export const scrollToElement = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const openHelpWidgetWithParams = (params, open = true) => {
    const widget = window.FreshworksWidget;
    if (widget) {
        widget('clear', 'ticketForm');
        widget('prefill', 'ticketForm', params);
        if (params.name || params.email || params.company) {
            widget('identify', 'ticketForm', {
                name: params.name,
                email: params.email,
                custom_fields: {
                    cf_user_company: params.company,
                }
            });
        }
        if (open) {
            widget('open');
        }
    }
};

export const openHelpWidget = () => {
    const widget = window.FreshworksWidget;
    if (widget) {
        widget('clear', 'ticketForm');
        widget('open');
    }
};
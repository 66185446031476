import React, {Fragment} from 'react';
import {useTable, useExpanded} from 'react-table';

/**
 * Basic React Table
 *
 * @param columns
 * @param data
 * @param buttonsClass
 * @param subrowComponent
 * @returns {JSX.Element}
 * @constructor
 */
const TableWithSubrows = ({columns, data, buttonsClass, subrowComponent}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        visibleColumns
    } = useTable(
        {
            columns,
            data
        },
        useExpanded);

    return (
        <div className="x-table-container">
            <table className={"x-table challenge no-margin " + buttonsClass} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return (
                                <th
                                    {...column.getHeaderProps()}
                                    className={column?.headerClassName ?? undefined}
                                >
                                    {column.render('Header')}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <Fragment key={`row-${i}`}>
                            <tr {...row.getRowProps()} key={`row-data-${i}`}>
                                {row.cells.map(cell => {
                                    return <td
                                        {...cell.getCellProps()}
                                        className={cell?.column?.className ?? undefined}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                            {row.isExpanded ? (
                                <tr key={`subrow-${i}`}>
                                    <td colSpan={visibleColumns.length}>
                                        {subrowComponent({row})}
                                    </td>
                                </tr>
                            ) : null}
                        </Fragment>
                    )
                })}
                </tbody>
                {footerGroups.length > 0 &&
                <tfoot>
                {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()}>
                        {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                    </tr>
                ))}
                </tfoot>
                }
            </table>
        </div>
    )
}

export default TableWithSubrows;
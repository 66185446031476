import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from './utils/authFunctions';
import {setPathElements} from './utils/pageFunctions';
import {getUserInfoFromToken} from './utils/tokenFunctions';
import Config from './config';
import SupportScript from './utils/SupportScript';

import About from './routes/About';
import AdminDashboard from './routes/Admin';
import Collaborators from './routes/Collaborators';
import FAQ from './routes/FAQ';
import GettingStarted from './routes/GettingStarted';
import Home from './routes/Home';
import LoggedOut from './routes/LoggedOut';
import NotFound from './routes/NotFound';
import ParticipationTerms from './routes/ParticipationTerms';
import PrivacyPolicy from './routes/PrivacyPolicy';
import Release from './routes/Release';
import Substrates from './routes/Substrates';
import Support from './routes/Support';
import Terms from './routes/Terms';
import UseCases from './routes/UseCases';
import UserDashboard from './routes/UserDashboard';

import XConnect from './routes/connect/XConnect';
import XConnectChallengeAirmeet from './routes/connect/ChallengeAirmeet';
import XConnectBlogosphere from './routes/connect/Blogosphere';
import Article from './partials/Article';
import XConnectChallenges from './routes/connect/Challenges';
import XConnectChallenge from './routes/connect/Challenge';
import XConnectChallengeAdd from './routes/connect/ChallengeAdd';
import XConnectChallengeEdit from './routes/connect/ChallengeEdit';
import XConnectEvent from './routes/connect/Event';
import XConnectEventAdd from './routes/connect/EventAdd';
import XConnectEventEdit from './routes/connect/EventEdit';
import XConnectEvents from './routes/connect/Events';
import XConnectEventAirmeet from "./routes/connect/EventAirmeet";
// @TODO remove
import XEventsStatic from './routes/connect/EventsStatic';
import XOrganizationAdd from './routes/OrganizationAdd';
import XOrganizationEdit from './routes/OrganizationEdit';
import XOrganizationView from './routes/OrganizationView';

import XHub from './routes/hub/XHub';
import XHubFiles from './routes/hub/Files';
import XHubModels from './routes/hub/Models';
import XHubPipelines from './routes/hub/Pipelines';
import XHubProject from './routes/hub/Project';
import XHubProjectAdd from './routes/hub/ProjectAdd';
import XHubProjectAirmeet from './routes/hub/ProjectAirmeet';
import XHubProjectEdit from './routes/hub/ProjectEdit';
import XHubPublish from './routes/hub/Publish';
import XHubSharing from './routes/hub/Sharing';
import XHubToolbox from './routes/hub/Toolbox';
import XHubWorkflows from './routes/hub/Workflows';
import XHubWorkspace from './routes/hub/Workspace';

import XDatasetEdit from './routes/DatasetEdit';

import XMarketplace from './routes/marketplace/XMarketplace';
import XMarketplaceSolutions from './routes/marketplace/Solutions';
import XMarketplaceSolutionsXrathus from './routes/marketplace/SolutionsXrathus';

import XTraining from './routes/training/XTraining';

import KnowledgeBase from './routes/training/KnowledgeBase';
import XClusterCollaborators from './routes/collaborators/Collaborators';

import StoryblokPage from './partials/storyblok/StoryblokPageTemplate';
import Storyblok from "./utils/storyblok-client";

import XEventConfiguration from './routes/EventConfiguration';
import XProjectConfiguration from './routes/ProjectConfiguration';

import XHubProjects from './routes/hub/Projects';


let allowScripts = false;

/**
 * Return Route or Redirect based on authentication status
 *
 * @param Component
 * @param auth
 * @param exact
 * @param redirect
 * @param rest
 * @return {*}
 * @constructor
 */
const PrivateRoute = ({
    component: Component,
    // eslint-disable-next-line no-unused-vars
    render: Function,
    auth,
    redirect,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                allowScripts = (auth === true);
                return (
                    (auth === true) ? (
                        <Component {...props} {...rest}/>
                    ) : (
                        <Redirect
                            to={{
                                pathname: redirect,
                                state: {from: props.location}
                            }}/>
                    )
                );
            }}/>
    );
};

PrivateRoute.propTypes = {
    location: PropTypes.any,
    component: PropTypes.any,
    render: PropTypes.func,
    auth: PropTypes.any,
    redirect: PropTypes.any,
};

/**
 * Class for rendering Routes and PrivateRoutes
 *
 */
class RouteHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            destination: null,
            navCollab: null,
            navKB: null,
            allDocuments: null,
            allNested: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let lState = nextProps.location.state;
        if (
            lState && lState.from &&
            lState.from.pathname !== prevState.destination &&
            lState.from.pathname !== null
        ) {
            // If a destination was redirected due to Keycloak login refresh, store it
            return {
                destination: nextProps.location.state.from.pathname
            };
        }
        return null;
    }

    async loadStoryblokPages(slug) {
        let params = {
            version: process.env.REACT_APP_STORYBLOK_API_KEY_TYPE,
            cv: Date.now(),
            starts_with: slug+"/",
            per_page: 25,
            excluding_fields: "body,content,bread_crumb_steps_to_jump,component,custom_body_css,"+
                              "custom_link,custom_logo_css,dataset,full_slug,group_filter,header,"+
                              "hide_bread_crumbs,hide_logo,image,intro,logo,no_document_margins,"+
                              "no_table_of_contents,only_available_to_user_groups,priority,"+
                              "publisher_logo,publisher_name,require_knowledge_base,subtitle,"+
                              "tags,title,title_in_sidebar,title_on_page,title_on_summary_page,"+
                              "title_style,_editable,_uid"
        };

        const resp = await Storyblok.get(`cdn/stories/`, params);

        let totalStories = [];
        if (resp && resp.data && resp.data.stories) {
            totalStories = resp.data.stories;
            const perPage = 25;
            if (resp.headers && resp.headers.total && resp.headers.total > perPage) {
                let totalPages = Math.ceil(resp.headers.total/perPage);
                for (let i = 2; i<=totalPages; i++) {
                    params.page = i;
                    const respAdditional = await Storyblok.get(`cdn/stories/`, params);
                    totalStories = totalStories.concat(respAdditional.data.stories);
                }
            }
        }

        if (slug === "documents" || slug === "nested-pages") {
            const slugStartEnd = slug.length+1;
            if (totalStories && totalStories.length > 0) {
                return totalStories.map((element) => {return ({
                    slug: element.full_slug.substring(slugStartEnd),
                    full_slug: element.full_slug,
                }); });
            }
            else {
                return null;
            }
        }
        else {
            if (totalStories && totalStories.length > 0) {
                return totalStories.map((element) => {return ({
                    slug: element.full_slug,
                }); });
            }
            else {
                return null;
            }
        }
    }

    async componentDidMount() {
        //@TODO: get any cached storyblok pages
        // doing so will improve load time on refresh

        //get actual storyblok pages
        const tempNavCollab = await this.loadStoryblokPages("collaborators");
        const tempNavKB = await this.loadStoryblokPages("knowledge-base");
        const tempDocuments = await this.loadStoryblokPages("documents");
        const tempNested = await this.loadStoryblokPages("nested-pages");

        this.setState({navKB: tempNavKB, navCollab: tempNavCollab,
            allDocuments: tempDocuments, allNested: tempNested});
    }

    componentDidUpdate() {
        // If a destination was updated from derived state, store it locally
        let destination = this.state.destination;
        if (destination) {
            localStorage.setItem('destination', destination);
        }
    }

    render() {
        const {
            keycloak,
            keycloakReady,
            location,
            tokens
        } = this.props;

        const {
            navCollab,
            navKB,
            allDocuments,
            allNested,
        } = this.state;

        // Note authentication status and roles
        const isAuth = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        setPathElements(location, isAuth);
        let isAuthSysop = false;
        let userInfo = Config.user;

        if (tokens.token) {
            userInfo = getUserInfoFromToken(tokens.token);
            isAuthSysop = !!(isAuth && userInfo.is_sysop);
        }

        // Determine if a destination existed before Keycloak refreshed the site
        let destination = localStorage.getItem('destination');
        if (
            (isAuth || isAuthSysop) &&
            (destination !== null) &&
            (destination !== location.pathname)
        ) {
            localStorage.removeItem('destination');
            // Do not redirect if user cannot access
            const requiresSysopIdx = Config.routesSysop.findIndex(r => r === destination);
            const disallowRedirect = (
                (!isAuthSysop && destination.indexOf('/challenge/') !== -1 && destination.indexOf('/edit') !== -1) ||
                (!isAuthSysop && destination.indexOf('/organization/') !== -1)
            );
            if (!disallowRedirect && (requiresSysopIdx === -1 || isAuthSysop)) {
                allowScripts = false;
                return <Redirect to={destination}/>;
            }
        }

        if (keycloakReady) {
            allowScripts = true;
        }

        return (
            <>
                <Switch>

                    {/* Public Pages */}
                    <Route exact path="/x/about" component={About}/>
                    <Route exact path="/x/collaborators" component={Collaborators}/>
                    <Route exact path="/x/faq" component={FAQ}/>
                    <Route exact path="/x/getting-started" component={GettingStarted}/>
                    <Route exact path="/x/participation-terms" component={ParticipationTerms}/>
                    <Route exact path="/x/privacy-policy" component={PrivacyPolicy}/>
                    <Route exact path="/x/release" component={Release}/>
                    <Route exact path="/x/substrates" component={Substrates}/>
                    <Route exact path="/x/support" component={Support}/>
                    <Route exact path="/x/terms" component={Terms}/>
                    <Route exact path="/x/use-cases" component={UseCases}/>

                    {/* Substrate-agnostic */}
                    {/* Documents */}
                    {
                        allDocuments ? allDocuments.map((element, index) => {return (
                            <PrivateRoute key={index} path={"/x/"+element.slug} component={() =><StoryblokPage url={element.full_slug} />}
                                auth={isAuth} redirect='/'/>
                        );} ) : null
                    }
                    {/* Nested Pages */}
                    {
                        allNested ? allNested.map((element, index) => {return (
                            <PrivateRoute key={index} path={"/x/"+element.slug} component={() =><StoryblokPage url={element.full_slug} />}
                                auth={isAuth} redirect='/'/>
                        );} ) : null
                    }

                    {/* Connect */}
                    <PrivateRoute path="/x/connect/blogosphere/why-incubate-when-you-can-accelerate"
                                  component={Article} slug="why-incubate-when-you-can-accelerate" auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/blogosphere/planning-your-pivot"
                                  component={Article} slug="planning-your-pivot" auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/blogosphere/susan-nash-on-collaboration"
                        component={Article} slug="susan-nash-on-collaboration" auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/blogosphere/seera-labs-on-data-science"
                        component={Article} slug="seera-labs-on-data-science" auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/blogosphere"
                        component={XConnectBlogosphere} auth={isAuth} redirect='/'/>
                    {
                        navCollab ? navCollab.map((element, index) => {return (
                            <PrivateRoute key={index} path={"/x/connect/"+element.slug} component={() =><StoryblokPage url={element.slug} />}
                                auth={isAuth} redirect='/'/>
                        );} ) : null
                    }
                    <PrivateRoute path="/x/connect/collaborators" component={XClusterCollaborators}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/connect/events/add" component={XConnectEventAdd}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/events" component={XConnectEvents}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/event/personal/:id/overview" component={XConnectEvent}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/event/public/:id/overview" component={XConnectEvent}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/event/:id/rtc/:rtcType/:uuid" component={XConnectEventAirmeet}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/event/personal/:id/edit" component={XConnectEventEdit}
                                  auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/event/public/:id/edit" component={XConnectEventEdit}
                                  auth={isAuth} redirect='/'/>

                    {/* @TODO remove */}
                    <PrivateRoute path="/x/connect/events-static" component={XEventsStatic}
                                  auth={isAuth} redirect='/'/>

                    {/* Connect > Challenge paths */}
                    <PrivateRoute path="/x/connect/challenges/add" component={XConnectChallengeAdd}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenges/list" component={XConnectChallenges}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenges/list/:sub" component={XConnectChallenges}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenges/:id" component={XConnectChallenge}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenges" component={XConnectChallenges}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/connect/challenge/:id/rtc/:rtcType/:uuid" component={XConnectChallengeAirmeet}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenge/:id/edit" component={XConnectChallengeEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenge/dataset/:id/edit" component={XDatasetEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/connect/challenge/:id/overview" component={XConnectChallenge}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/connect/:sub" component={XConnect} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/connect" component={XConnect} auth={isAuth} redirect='/'/>

                    {/* Organization */}
                    <PrivateRoute path="/x/organization/add" component={XOrganizationAdd}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/organization/:id/edit" component={XOrganizationEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/organization/:id/overview" component={XOrganizationView}
                        auth={isAuthSysop} redirect='/'/>

                    {/* Hub / Project */}
                    <PrivateRoute path="/x/hub/files" component={XHubFiles} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/models" component={XHubModels} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/pipelines" component={XHubPipelines} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/publish" component={XHubPublish} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/sharing" component={XHubProjects}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/toolbox" component={XHubToolbox} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/workflows" component={XHubWorkflows} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/workspace" component={XHubWorkspace} auth={isAuth} redirect='/'/>

                    {/* Hub > Challenge paths */}
                    <PrivateRoute path="/x/hub/challenge/dataset/:id/edit" component={XDatasetEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/rtc/:rtcType/:uuid" component={XConnectChallengeAirmeet}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/edit" component={XConnectChallengeEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/files" component={XHubFiles}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/models" component={XHubModels}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/overview" component={XConnectChallenge}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/pipelines" component={XHubPipelines}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/publish" component={XHubPublish}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/sharing" component={XHubSharing}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/toolbox" component={XHubToolbox}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id/workflows" component={XHubWorkflows}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/challenge/:id" component={XConnectChallenge}
                        auth={isAuth} redirect='/'/>

                    {/* Hub > Project paths */}
                    <PrivateRoute path="/x/hub/projects/add" component={XHubProjectAdd}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects/personal" component={XHubProjects}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects/challenges" component={XHubProjects}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects/hub-training" component={XHubProjects}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects/shared" component={XHubProjects}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects/:sub" component={XHub}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/projects" component={XHubProjects}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/hub/project/personal/dataset/:id/edit" component={XDatasetEdit}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/rtc/:rtcType/:uuid" component={XHubProjectAirmeet}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/edit" component={XHubProjectEdit}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/files" component={XHubFiles}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/models" component={XHubModels}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/overview" component={XHubProject}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/pipelines" component={XHubPipelines}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/publish" component={XHubPublish}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/sharing" component={XHubSharing}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/toolbox" component={XHubToolbox}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id/workflows" component={XHubWorkflows}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/personal/:id" component={XHubProject}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/hub/project/dataset/:id/edit" component={XDatasetEdit}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/:id/rtc/:rtcType/:uuid" component={XHubProjectAirmeet}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/:id/edit" component={XHubProjectEdit}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub/project/:id/overview" component={XHubProject}
                        auth={isAuth} redirect='/'/>

                    <PrivateRoute path="/x/hub/:sub" component={XHub} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/hub" component={XHub} auth={isAuth} redirect='/'/>

                    {/* Marketplace */}
                    <PrivateRoute path="/x/marketplace/solutions/xrathus" component={XMarketplaceSolutionsXrathus}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/marketplace/solutions" component={XMarketplaceSolutions}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/marketplace/:sub" component={XMarketplace} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/marketplace" component={XMarketplace} auth={isAuth} redirect='/'/>

                    {/* Training */}
                    {
                        navKB ? navKB.map((element, index) => {return (
                            <PrivateRoute key={index} path={"/x/training/"+element.slug} component={() =><StoryblokPage url={element.slug} />}
                                auth={isAuth} redirect='/'/>
                        );} ) : null
                    }
                    <PrivateRoute path="/x/training/knowledge-base" component={KnowledgeBase}
                        auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/training/:sub" component={XTraining} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/training" component={XTraining} auth={isAuth} redirect='/'/>

                    {/* Clusters/Collaborators */}
                    <PrivateRoute path="/x/clusters" component={XConnect} auth={isAuth} redirect='/'/>

                    {/* Private Routes */}
                    <PrivateRoute path="/x/admin/configuration" component={XProjectConfiguration}
                        auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/admin/event-configuration" component={XEventConfiguration}
                                  auth={isAuthSysop} redirect='/'/>
                    <PrivateRoute path="/x/admin" component={AdminDashboard} auth={isAuth} redirect='/'/>
                    <PrivateRoute path="/x/logout" component={LoggedOut} auth={!isAuth} redirect='/'/>
                    <PrivateRoute path="/x/profile" component={UserDashboard} auth={isAuth} redirect='/'/>

                    <Route exact path="/" component={Home}/>

                    <Route path="*" component={NotFound}/>
                </Switch>

                {allowScripts &&
                <SupportScript
                    isAuthenticated={isAuth}
                    keycloakReady={keycloakReady}
                    path={location.pathname}
                    user={userInfo}
                />
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        keycloakReady: state.keycloakReady,
        tokens: {
            token: state.tokens.token
        },
        user: state.user
    };
};

RouteHandler.propTypes = {
    keycloak: PropTypes.any,
    keycloakReady: PropTypes.any,
    location: PropTypes.any,
    tokens: PropTypes.any,
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps
        )(RouteHandler)
    )
);

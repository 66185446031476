import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {articles} from '../data/articles';

/**
 * Component to display article content
 *
 */
class Article extends Component {
    render() {
        const {
            slug
        } = this.props;

        const article = articles[slug];
        let content = article.article.replace('\n', '<br/>');

        return (
            <>
                <div className={`content blogosphere article ${slug}`}>
                    <div className="panel">
                        {article.title &&
                            <h1 className="article-title">{article.title}</h1>
                        }
                        {article.subtitle &&
                        <h2 className="article-subtitle">{article.subtitle}</h2>
                        }
                        {article.author &&
                        <div className="article-author">{article.author}</div>
                        }

                        <ReactMarkdown source={content} escapeHtml={false}/>
                    </div>
                </div>
            </>
        )
    }
}

Article.propTypes = {
    slug: PropTypes.string
};

export default Article;

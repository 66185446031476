import React from 'react';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {makeDateString} from '../utils/dataFunctions';

/**
 * Event Item (grid)
 *
 * @param eventItem
 * @param classes
 * @param inInvitedEvents
 * @param inOwnEvents
 * @param inUserOrOwnEvents
 * @param clickFunc
 * @param clickLink
 * @param isAuthenticated
 * @return {*}
 * @constructor
 */
export default function EventItem({
                                      eventItem,
                                      classes,
                                      inInvitedEvents = false,
                                      inOwnEvents = false,
                                      inUserOrOwnEvents = false,
                                      clickFunc = null,
                                      clickLink = null,
                                      isAuthenticated
                                  }) {

    // Set classes, button text, strings
    const classStr = (classes) ? classes : '';
    const buttonText = (inUserOrOwnEvents) ? 'Access' : 'Preview';
    const startDateStr = (eventItem.start_time) ? makeDateString(eventItem.start_time, 'm', true) : '';
    const endDateStr = (eventItem.end_time) ? makeDateString(eventItem.end_time, 'm', true) : '';


    const handleClick = (e) => {
        e.preventDefault();
        if (clickFunc) {
            clickFunc(eventItem.id);
        }
    };

    return (
        <div>
            <div className={`xrathus-item ${classStr}`}>

                <div className="item-header">
                    <div className="row">
                        <div className={"col col-xs-12 col-sm-12"}>
                            <h4>{eventItem.name}</h4>
                        </div>
                    </div>
                </div>

                <div className="item-content">
                    <div className="short-description">
                        <ReactMarkdown source={eventItem.short_desc}/>
                    </div>
                    <div className="details">
                        <p className="owner">
                            Event Creator: {eventItem.username}
                        </p>
                        {startDateStr !== '' &&
                            <p className="start-date">
                                Starts: {startDateStr}
                            </p>
                        }
                        {endDateStr !== '' &&
                            <p className="end-date">
                                Ends: {endDateStr}
                            </p>
                        }
                    </div>
                </div>

                <div className="item-footer">
                    {clickFunc !== null &&
                        <button onClick={(e) => handleClick(e)} title={buttonText}>
                            {buttonText}
                        </button>
                    }
                    {clickLink !== null && isAuthenticated &&
                        <Link to={clickLink} title={eventItem.title}>{buttonText}</Link>
                    }
                    {clickLink !== null && !isAuthenticated &&
                        <a href={clickLink} target='_blank' rel="noreferrer">{buttonText}</a>
                    }
                </div>
            </div>
        </div>
    );
}

import React from 'react';

/**
 * Progress Bar for table display
 *
 * @param amount
 * @param total
 */
export default function TableProgressBar({amount, total}) {
    let percent = ((amount * 100) / total).toFixed(2);
    if (percent < 0.1) {
        percent = 0;
    }
    if (percent > 99.9) {
        percent = 100;
    }
    return (
        <span className="progress-container">
            <span className="progress-bar" style={{width: percent + '%'}}>
            </span>
            <span className="progress-text">
                {percent.toString()}%
            </span>
        </span>
    );
}
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RESOURCES as eventResources} from '../../resourcesEvent';
import {RESOURCES as projectResources} from '../../resourcesProject';
import {makePostDataRequest} from '../../utils/postRequest';
import BaseInput from '../fields/BaseInput';
import Modal from '../modal/Modal';

/**
 * Button with Unsubscribe Self functionality
 *
 * @param eventId
 * @param projectId
 * @param username
 * @param onFormEvent
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const UnsubscribeSelfButton = (
    {
        eventId,
        projectId,
        username,
        onFormEvent,
        type = 'unsubscribe'
    }) => {

    const [modalStatus, setModalStatus] = useState(false);
    const [unsubscribeNotes, setUnsubscribeNotes] = useState('');
    const [unsubscribeError, setUnsubscribeMessage] = useState(null);

    const handleNotesUpdate = (e) => {
        setUnsubscribeNotes(e?.value ?? '');
    };

    const handleConfirmation = () => {
        let label = (eventId) ? 'Event' : 'Project';
        let resource = (eventId) ? eventResources : projectResources;
        let actions = (type === 'reject')
            ? {
                present: 'rejecting',
                past: 'rejected',
                func: 'reject',
                method: 'PUT',
                params: {}
            }
            : {
                present: 'unsubscribing from',
                past: 'unsubscribed from',
                func: 'subscribe',
                method: 'POST',
                params: {}
            };
        if (eventId) {
            if (type === 'reject') {
                actions.params.id = eventId;
                actions.resource = 'EventPersonalReject';
            }
            if (type === 'unsubscribe') {
                actions.params.event_id = eventId;
                actions.resource = 'EventPersonalUnsubscribe';
            }
        } else if (projectId) {
            if (type === 'reject') {
                actions.params.id = projectId;
                actions.resource = 'ProjectPersonalReject';
            }
            if (type === 'unsubscribe') {
                actions.params.project_id = projectId;
                actions.resource = 'ProjectPersonalUnsubscribe';
            }
        }
        if (type === 'unsubscribe') {
            if (eventId) {
                actions.params.event_id = eventId;
            } else if (projectId) {
                actions.params.project_id = projectId;
            }
            actions.params.unsubscribed_notes = unsubscribeNotes;
            actions.params.username = username;
        }
        let errorMessage = 'Sorry, there was an issue ' + actions.present + ' this ' + label + '.';
        const request = makePostDataRequest(resource, actions.resource, actions.method, actions.params);
        request.then(result => {
            if (result && result === 'OK') {
                setModalStatus(false);
                setUnsubscribeMessage('You have successfully ' + actions.past + ' this ' + label + '.');
                if (typeof onFormEvent === 'function') {
                    onFormEvent();
                }
                return Promise.resolve(result);
            } else {
                if (result?.data?.error) {
                    if (result.data.error.indexOf('no personal project share found') !== -1) {
                        errorMessage = 'No Personal Project was found.';
                    }
                    if (result.data.error.indexOf('no personal event share found') !== -1) {
                        errorMessage = 'No Personal Event was found.';
                    }
                }
                console.log('Error: Unable to process Unsubscribe request');
                setUnsubscribeMessage(errorMessage);
                return Promise.reject('Unsubscribe failed');
            }
        }).catch(error => {
            console.log('Error: ', error);
            setUnsubscribeMessage('Sorry, there was an error processing this request.');
            return Promise.reject('Server Error');
        });
    };

    const labelType = (eventId) ? 'Event' : 'Project';
    const btnLabelAction = (type === 'reject') ? 'Reject' : 'Leave';
    const btnLabel = btnLabelAction + ' ' + labelType;

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                title={btnLabel}
                onClick={() => setModalStatus(true)}
            >
                {btnLabel}
            </button>
            <Modal
                allowClose={true}
                id="modal-unsubscribe-self"
                show={modalStatus}
                onClose={() => setModalStatus(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '40%',
                    minHeight: 200,
                    top: '50%',
                    transform: 'translate(-50%, -100%)'
                }}
            >
                <div className="panel no-padding no-margin">
                    {type === 'reject' &&
                        <>
                            <h3 className="header">
                                Are you sure you want to reject this shared {labelType}?
                            </h3>
                            {eventId &&
                                <p>You will not be able to view this Event's details.</p>
                            }
                            {projectId &&
                                <p>You will not be able to view this Project's details or access its files.</p>
                            }
                        </>
                    }
                    {type === 'unsubscribe' &&
                        <>
                            <h3 className="header">
                                Are you sure you want to leave this {labelType}?
                            </h3>
                            {eventId &&
                                <p>This will disconnect your associations from this Event.</p>
                            }
                            {projectId &&
                                <p>This will disconnect your associations from this Project and Dataset. Your own
                                    forks will remain accessible.</p>
                            }
                            <p>If desired, add brief notes regarding the reason for your leaving this {labelType}:</p>
                            <div className="form-group ml-1 mr-1">
                                <BaseInput
                                    type="text"
                                    id="unsubscribe_notes"
                                    name="unsubscribe_notes"
                                    label="Notes / Reason"
                                    className="form-input"
                                    changeFunc={(e) => handleNotesUpdate(e)}
                                >
                                </BaseInput>
                            </div>
                        </>
                    }

                    {unsubscribeError &&
                        <p className="error">
                            {unsubscribeError}
                        </p>
                    }

                    <div className="form-btns center">
                        <button className="btn" onClick={() => setModalStatus(false)}>NO</button>
                        <button className="btn btn-primary" onClick={handleConfirmation}>YES</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

UnsubscribeSelfButton.propTypes = {
    username: PropTypes.string
};

export default UnsubscribeSelfButton;

import React from 'react';

const IconUser = () => {
    return (
        <span className="icon-user">
            <svg version="1.1" id="icon-user" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="-49.5 65.5 115.2 115.2"
                 enableBackground="new -49.5 65.5 115.2 115.2"
                 xmlSpace="preserve"
            >
                <path fill="#5A358C" d="M-40.916,153.159v27.506c32.683-0.122,65.333,0.122,98.013,0c-0.122-6.375,0.122-20.759,0-27.132
	c-1.344-2.486-3.687-5.541-6.601-8.581c-5.187-5.408-10.804-8.171-14.338-9.562c-3.236-1.148-7.013-2.244-11.277-3.063
	c-6.468-1.242-12.237-1.489-16.912-1.349c-4.175-0.057-9.463,0.237-15.442,1.472c-6.13,1.265-11.207,3.151-15.075,4.902
	c-2.781,1.365-6.299,3.424-9.928,6.497c-3.884,3.289-6.616,6.66-8.456,9.312"/>
	<path fill="#5A358C" d="M7.986,65.551c1.875-0.125,10.255-0.475,17.604,5.625c8.796,7.298,8.961,17.897,8.959,19.374
	c-0.003,2.139-0.26,10.49-6.667,17.707c-7.686,8.657-18.2,8.906-19.896,8.918c-1.613,0.046-11.402,0.155-19.061-7.668
	c-7.197-7.352-7.305-16.388-7.291-18.143c-0.021-1.936,0.104-10.425,6.666-17.583C-4.004,65.385,6.416,65.506,7.986,65.551"/>
            </svg>
        </span>
    )
};

export default IconUser;

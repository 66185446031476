import {filter, orderBy, reject, reverse, sortBy} from 'lodash';

/**
 * Filter a Project/Challenge List's attributes by a value
 *
 * @param list
 * @param searchValue
 * @param attributes
 * @returns {*[]|*}
 */
export const filterListByValue = (list, searchValue, attributes = []) => {

    if (!list || list.length === 0) {
        return [];
    }
    const value = searchValue?.toLowerCase() ?? '';
    if (!value || value === '') {
        return list;
    }

    // Filter list by each attribute
    return list.filter(item => {
        for (let i = 0; i < attributes.length; i++) {
            const term = attributes[i];
            if (item[term] && item[term].toLowerCase().indexOf(value) !== -1) {
                return true;
            }
        }
        return false;
    });
};

/**
 * Sort a Project/Challenge list by selected sort value
 *
 * @param list
 * @param sortValue
 * @param sortTypes
 * @returns {unknown[]|*[]|*}
 */
export const sortListByValue = (list, sortValue, sortTypes) => {

    if (!list || list.length === 0) {
        return [];
    }
    if (!sortValue || sortValue === '') {
        return list;
    }
    let tempList = JSON.parse(JSON.stringify(list));

    // Sort by Sort value
    let nullValues = filter(tempList, [sortTypes[sortValue], null]);
    tempList = orderBy(reject(tempList, [sortTypes[sortValue], null]), [item => item[sortTypes[sortValue]].toLowerCase()], sortTypes[sortValue]);
    if (sortValue === 'latest' || sortValue === 'popular') {
        tempList = reverse(tempList);
    }

    // Sort nullvalues by default sort (Latest) @TODO: Change to Popular when API for it exists
    nullValues = reverse(sortBy(nullValues, sortTypes['latest']));
    tempList.push(...nullValues);

    return tempList;
};
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RESOURCES as resources} from '../resourcesProject';
import {setProjectConfig} from '../store/actions';
import {makeGetDataRequest} from './getRequest';
import Config from '../config';

/**
 * Component for retrieving default Project Configuration
 */
class APIProjectConfiguration extends Component {

    componentDidMount() {
        this.getGlobalProjectConfigurations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh  && !prevProps.refresh) {
            this.getGlobalProjectConfigurations();
        }
    }

    /**
     * Get Configuration
     */
    getGlobalProjectConfigurations = () => {
        const configDefaults = Config.projectConfigDefault;
        const request = makeGetDataRequest(resources, 'ProjectConfiguration', 'GET_LIST', null);
        request.then(result => {
            let configs = JSON.parse(JSON.stringify(configDefaults));
            if (result) {
                if (typeof result === 'string') {
                    console.log('Project Configuration Retrieval Error: ', result);
                    this.props.setProjectConfig(configs);
                    return Promise.resolve(configs);
                }
                for (let i = 0; i < result.length; i++) {
                    const config = result[i];
                    // console.log('set project config ', config);
                    configs[config.name] = config?.value ?? configDefaults[config.name];
                }
                this.props.setProjectConfig(configs);
                if (typeof this.props.onResult === 'function') {
                    this.props.onResult();
                }
                return Promise.resolve(configs);
            } else {
                console.log('Project Configuration Retrieval Error: No result');
                return Promise.reject('Project Configuration Retrieval failed');
            }
        }).catch(error => {
            console.log('Project Retrieval Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project_config_refresh: state.project_config_refresh
    };
};

const mapDispatchToProps = {
    setProjectConfig: setProjectConfig
};

APIProjectConfiguration.propTypes = {
    onResult: PropTypes.func,
    refresh: PropTypes.bool,
};

APIProjectConfiguration.defaultProps = {
    refresh: false,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(APIProjectConfiguration);

import React, {Component} from 'react';
import '../../css/static.css';

class XHubModels extends Component {
    render() {

        return (
            <>
                <div className="content models">
                    <h1 className="page-title">
                        Models
                    </h1>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="panel">
                                <p>
                                    <strong>For the current Release, this feature is not
                                        available.</strong>
                                </p>
                                <p>
                                    Once available, the Models menu will enable multi-party submissions for reviews,
                                    reiterations, and automated feedback for improvements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default XHubModels;

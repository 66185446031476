import {
    LOG_OUT,
    SET_AUTHENTICATED,
    SET_CHALLENGE_LIST_PUBLIC,
    SET_CHALLENGE_LIST_SUBSCRIBED,
    SET_CHALLENGE_LIST_SUBSCRIBED_REFRESH,
    SET_EVENT_CONFIG,
    SET_EVENT_CONFIG_CURRENT,
    SET_EVENT_CONFIG_REFRESH,
    SET_EVENT_CONFIG_USER,
    SET_EVENT_CONFIG_USER_REFRESH,
    SET_EVENT_ID,
    SET_EVENT_LIST_INVITED,
    SET_EVENT_LIST_OWN,
    SET_EVENT_LIST_PUBLIC,
    SET_EVENT_LIST_REFRESH,
    SET_EVENT_LIST_SUBSCRIBED,
    SET_EVENT_LIST_SUBSCRIBED_REFRESH,
    SET_KEYCLOAK_READY,
    SET_LIST_STATUS,
    SET_PROJECT_ID,
    SET_PROJECT_CONFIG,
    SET_PROJECT_CONFIG_CURRENT,
    SET_PROJECT_CONFIG_REFRESH,
    SET_PROJECT_CONFIG_USER,
    SET_PROJECT_CONFIG_USER_REFRESH,
    SET_PROJECT_FILE_LIST,
    SET_PROJECT_LIST_INVITED,
    SET_PROJECT_LIST_OWN,
    SET_PROJECT_LIST_REFRESH,
    SET_PROJECT_LIST_SUBSCRIBED,
    SET_SIDEBAR_VISIBILITY,
    SET_TOKENS,
    SET_USER
} from './actionTypes';

/*
    Auth / User :::::::::::::::::::::::::::::::::::::::::::::
 */

export const setAuthenticated = authenticated => ({
    type: SET_AUTHENTICATED,
    authenticated
});

export const setKeycloakReady = () => {
    return {
        type: SET_KEYCLOAK_READY
    }
};

export const setTokens = tokens => {
    return {
        type: SET_TOKENS,
        tokens
    };
};

export const setUser = obj => {
    return {
        type: SET_USER,
        obj
    };
};

export function logOutUser() {
    return {
        type: LOG_OUT
    }
}

/*
    UI :::::::::::::::::::::::::::::::::::::::::::::
 */

export const setSidebarVisibility = isOpen => ({
    type: SET_SIDEBAR_VISIBILITY,
    payload: isOpen,
});

/*
    Xrathus Challenges :::::::::::::::::::::::::::::::::::::::::::::
 */

export const setChallengeListPublic = challenge_list_public => {
    return {
        type: SET_CHALLENGE_LIST_PUBLIC,
        challenge_list_public
    };
};

export const setChallengeListSubscribedRefresh = refresh => ({
    type: SET_CHALLENGE_LIST_SUBSCRIBED_REFRESH,
    payload: refresh,
});

export const setChallengeListSubscribed = challenge_list_subscribed => {
    return {
        type: SET_CHALLENGE_LIST_SUBSCRIBED,
        challenge_list_subscribed
    };
};

export const setListStatus = obj => {
    return {
        type: SET_LIST_STATUS,
        obj
    }
};

/*
    Xrathus Events :::::::::::::::::::::::::::::::::::::::::::::
 */

export const setEventConfig = event_config => {
    return {
        type: SET_EVENT_CONFIG,
        event_config
    };
};

export const setEventConfigCurrent = obj => {
    return {
        type: SET_EVENT_CONFIG_CURRENT,
        obj
    };
};

export const setEventConfigRefresh = refresh => ({
    type: SET_EVENT_CONFIG_REFRESH,
    payload: refresh,
});

export const setEventConfigUser = obj => {
    return {
        type: SET_EVENT_CONFIG_USER,
        obj
    };
};

export const setEventConfigUserRefresh = refresh => ({
    type: SET_EVENT_CONFIG_USER_REFRESH,
    payload: refresh,
});

export const setEventId = event_id => {
    return {
        type: SET_EVENT_ID,
        event_id
    }
};

export const setEventListInvited = event_list_invited => {
    return {
        type: SET_EVENT_LIST_INVITED,
        event_list_invited
    };
};

export const setEventListOwn = event_list_own => {
    return {
        type: SET_EVENT_LIST_OWN,
        event_list_own
    };
};

export const setEventListPublic = event_list_public => {
    return {
        type: SET_EVENT_LIST_PUBLIC,
        event_list_public
    };
};

export const setEventListRefresh = type => ({
    type: SET_EVENT_LIST_REFRESH,
    payload: type,
});

export const setEventListSubscribed = event_list_subscribed => {
    return {
        type: SET_EVENT_LIST_SUBSCRIBED,
        event_list_subscribed
    };
};

export const setEventListSubscribedRefresh = refresh => ({
    type: SET_EVENT_LIST_SUBSCRIBED_REFRESH,
    payload: refresh,
});

/*
    Xrathus Projects and Files :::::::::::::::::::::::::::::::::::::::::::::
 */

export const setProjectConfig = project_config => {
    return {
        type: SET_PROJECT_CONFIG,
        project_config
    };
};

export const setProjectConfigCurrent = obj => {
    return {
        type: SET_PROJECT_CONFIG_CURRENT,
        obj
    };
};

export const setProjectConfigRefresh = refresh => ({
    type: SET_PROJECT_CONFIG_REFRESH,
    payload: refresh,
});

export const setProjectConfigUser = obj => {
    return {
        type: SET_PROJECT_CONFIG_USER,
        obj
    };
};

export const setProjectConfigUserRefresh = refresh => ({
    type: SET_PROJECT_CONFIG_USER_REFRESH,
    payload: refresh,
});

export const setProjectId = project_id => {
    return {
        type: SET_PROJECT_ID,
        project_id
    }
};

export const setProjectFileList = project_file_list => {
    return {
        type: SET_PROJECT_FILE_LIST,
        project_file_list
    };
};

export const setProjectListInvited = project_list_invited => {
    return {
        type: SET_PROJECT_LIST_INVITED,
        project_list_invited
    };
};

export const setProjectListOwn = project_list_own => {
    return {
        type: SET_PROJECT_LIST_OWN,
        project_list_own
    };
};

export const setProjectListRefresh = type => ({
    type: SET_PROJECT_LIST_REFRESH,
    payload: type,
});

export const setProjectListSubscribed = project_list_subscribed => {
    return {
        type: SET_PROJECT_LIST_SUBSCRIBED,
        project_list_subscribed
    };
};

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import FormOrganizationEdit from '../partials/forms/FormOrganizationEdit';
import '../css/challenge.css';

/**
 * Page: Create Organization
 */
class XOrganizationEdit extends Component {
    constructor(props) {
        super(props);

        const pathArr = this.props.location.pathname.split('/');
        this.state = {
            pathArr: pathArr
        };
    }
    render() {
        const {
            pathArr
        } = this.state;

        return (
            <>
                <div className="content xconnect challenge">
                    <h1 className="page-title">
                        Edit Organization
                    </h1>

                    <div className="panel no-padding">
                        <h3 className="header">Organization</h3>
                        <div className="row">
                            <div className="col col-xs-12">
                                <div className="form-container">
                                    <FormOrganizationEdit pathArr={pathArr}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default withRouter(XOrganizationEdit);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import RouteHandler from '../RouteHandler';
import {setSidebarVisibility} from '../store/actions';
import {isFullWidth} from '../utils/pageFunctions';
import APIContainer from './APIs';
import AppHeader from './Header';
import AppFooter from './Footer';
import AppSidebar from './Sidebar';
import LoginModal from '../partials/modal/LoginModal';
import PersonaSelect from '../partials/PersonaSelect';

function AppLayout(props) {

    const setSidebar = (open) => {
        props.setSidebarVisibility(open);
    };

    const isHomepage = (props.location.pathname === '/');
    const pageIsFullWidth = isFullWidth(props.location);

    return (
        <>
            <AppSidebar onMenuClose={() => setSidebar(false)}/>
            <div className={(props.sidebarOpen && !isHomepage && !pageIsFullWidth) ? 'page sidebar-open' : 'page'}>
                <AppHeader/>
                <RouteHandler/>
                <APIContainer/>
                <AppFooter/>
                <LoginModal/>
            </div>
            <PersonaSelect/>
        </>
    )
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    if (sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            sidebarOpen: sidebarOpen
        };
    }
    return {};
};

AppLayout.propTypes = {
    configParams: PropTypes.object,
    sidebarOpen: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        {setSidebarVisibility: setSidebarVisibility}
    )(AppLayout)
);

import {getAllForumCategoryLinks} from '../utils/dataFunctions';

let forum_paths = getAllForumCategoryLinks();
if (!forum_paths || !forum_paths['domain']) {
    forum_paths = {};
    forum_paths['aapg-pivoting-2021'] = '';
    forum_paths['academia'] = '';
    forum_paths['criterion'] = '';
    forum_paths['deasil'] = '';
    forum_paths['domain'] = '';
    forum_paths['data-science'] = '';
    forum_paths['developer'] = '';
    forum_paths['product-owner'] = '';
    forum_paths['seam'] = '';
    forum_paths['seera-labs'] = '';
    forum_paths['sponsors'] = '';
}
const forum_url = localStorage.getItem('forum_url');

/**
 * Clusters navigation (shared)
 *
 */
export const clustersNav = {
    title: 'Clusters',
    slug: 'nav-clusters',
    icon: 'images/icon-Connect-Forums.svg',
    substrateClass: 'connect',
    links: [
        {
            title: 'Xrathus',
            slug: 'nav-clusters-xrathus',
            substrateClass: 'connect',
            links: [
                {
                    title: 'Domain',
                    slug: 'nav-clusters-xrathus-domain',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['domain'],
                    external: true
                },
                {
                    title: 'Data Science',
                    slug: 'nav-clusters-xrathus-data-science',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['data-science'],
                    external: true
                },
                {
                    title: 'Developer',
                    slug: 'nav-clusters-xrathus-developer',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['developer'],
                    external: true
                },
                {
                    title: 'Product Owner',
                    slug: 'nav-clusters-xrathus-product-owner',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['product-owner'],
                    external: true
                },
                {
                    title: 'Sponsors',
                    slug: 'nav-clusters-xrathus-sponsors',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['sponsors'],
                    external: true
                }
            ]
        },
        {
            title: 'Collaborators',
            slug: 'nav-clusters-collaborators',
            substrateClass: 'connect',
            links: [
                {
                    title: 'SEAM',
                    slug: 'nav-clusters-collaborators-seam',
                    substrateClass: 'connect',
                    route: forum_url + forum_paths['seam'],
                    external: true
                }
            ]
        },
        {
            title: 'Academia',
            slug: 'nav-clusters-academia',
            substrateClass: 'connect',
            route: forum_url + forum_paths['academia'],
            external: true
        }
    ]
};

import React from 'react';

const XrathusLogoHorizontal = () => {
    return (
        <span className="logo">
            <svg version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px"
                 width="648px" height="165.6px"
                 viewBox="290.52 146.433 648 165.6" enableBackground="new 290.52 146.433 648 165.6"
            >
				<g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,177.86667)">
					<g id="g12" transform="scale(0.1)">
		<path id="path14" fill="#D2A715" d="M3419.673-314.929h-254.927l127.463,127.471l-98.584,98.577l-295.767-295.752l295.767-295.759
			l98.584,98.591l-127.463,127.463h254.927V-314.929"/>
		<path id="path16" fill="#69C7EE" d="M2996.458,108.279L2868.987-19.162V235.75h-139.409V-19.162L2602.11,108.279L2503.526,9.71
			l295.763-295.759L3095.042,9.71L2996.458,108.279"/>
		<path id="path18" fill="#AE8BCD" d="M3095.042-778.977l-295.752,295.752l-295.763-295.752l98.584-98.584l127.467,127.463v-256.047
			h139.409v256.047l127.471-127.463L3095.042-778.977"/>
		<path id="path20" fill="#71C32D" d="M2404.939-88.881l-98.58-98.577l127.46-127.471h-254.919v-139.409h254.919l-127.46-127.463
			l98.58-98.591l295.759,295.759L2404.939-88.881"/>
		<path id="path22" fill="#512883" d="M2996.458,108.279h295.752v-295.737l-98.584,98.577l-295.767-295.752l-98.569,98.584
			L3095.042,9.71L2996.458,108.279"/>
		<path id="path24" fill="#512883" d="M2306.358-187.458v295.737h295.752L2503.526,9.71l295.763-295.759l-98.591-98.584
			L2404.939-88.881L2306.358-187.458"/>
		<path id="path26" fill="#512883" d="M2602.11-877.561h-295.752v295.759l98.58-98.591l295.759,295.759l98.591-98.591
			l-295.763-295.752L2602.11-877.561"/>
		<path id="path28" fill="#512883" d="M3292.209-581.801v-295.759h-295.752l98.584,98.584l-295.752,295.752l98.569,98.591
			l295.767-295.759L3292.209-581.801"/>
		<path id="path30" fill="#FFFFFF"
              d="M2799.289-483.225l-98.591,98.591l98.591,98.584l98.569-98.584L2799.289-483.225"/>
		<path id="path32" fill="#512883" d="M4394.036-467.097v70.986h-181.069v-100.825h67.896v-277.756h-121.26l-184.131,267.268
			l181.055,255.169h63.779v100.847h-269.531v-100.847h64.805l-109.058-157.339l-106.978,157.339h63.779v100.847h-275.698v-100.847
			h67.896l175.92-265.261l-182.102-257.175h-66.855v-102.876h271.589v102.876h-65.852l111.116,159.36l109.05-159.36h-63.794
			v-102.876h528.647v102.876h-74.062v211.926c46.289,48.347,90.52,68.928,151.23,68.928c7.192,0,14.399,0,21.592-1.033v104.927
			c-1.025,0-2.065,0-3.083,0C4508.22-389.943,4451.641-416.691,4394.036-467.097"/>
		<path id="path34" fill="#512883" d="M4793.462-799.367c-60.696,0-97.727,29.824-97.727,77.153
			c0,42.173,37.031,69.954,97.727,69.954c65.852,0,110.083-30.864,110.083-73.044
			C4903.545-766.452,4858.274-799.367,4793.462-799.367z M5001.279-567.9c0,58.63-4.124,98.745-44.246,132.7
			c-35.999,29.832-90.527,47.322-156.365,47.322c-56.587,0-111.116-13.374-166.663-39.089l38.057-83.335
			c42.18,17.483,79.226,28.806,116.25,28.806c77.168,0,113.174-31.897,113.174-99.785c0-7.2,0-15.432-1.025-24.69
			c-40.129,23.665-84.36,41.147-134.766,41.147c-111.108,0-188.269-69.954-188.269-161.521c0-93.604,70.986-163.564,180.037-163.564
			c61.721,0,111.101,17.49,153.281,55.547v-43.206h162.554v99.792h-72.02V-567.9"/>
		<path id="path36" fill="#512883" d="M5282.925-239.738h-118.308V-396.11h-72.012v-100.825h72.012v-380.632h193.396v102.876
			h-75.088v277.756h75.088v100.825h-75.088V-239.738"/>
		<path id="path38" fill="#512883" d="M5912.098-586.423c0,131.675-42.181,195.454-152.264,195.454
			c-53.488,0-106.992-22.632-164.597-68.928v308.489h-187.236v-99.785h67.91v-523.499h-67.91v-102.876H5669.3v102.876h-74.062
			v211.926c44.238,44.231,85.393,65.83,127.565,65.83c49.38,0,69.954-31.882,69.954-97.727v-180.029h-69.954v-102.876h254.106
			v102.876h-64.812V-586.423"/>
		<path id="path40" fill="#512883" d="M6494.446-396.11h-192.386v-100.825h74.077v-216.021
			c-44.238-44.246-86.411-66.877-128.599-66.877c-49.38,0-68.928,31.897-68.928,97.734v285.989H5994.48v-100.825h64.812v-192.363
			c0-131.682,43.198-196.494,152.249-196.494c54.528,0,108.018,23.665,166.655,70.986v-62.761h185.178v102.876h-68.928V-396.11"/>
		<path id="path42" fill="#512883" d="M6869.072-583.332c-87.437,18.508-137.849,22.632-137.849,62.747
			c0,29.832,27.788,50.405,70.979,50.405c39.111,0,76.143-13.374,115.232-42.18v-33.948h88.477v150.198h-88.477v-27.773
			c-39.09,27.773-78.193,39.097-123.442,39.097c-102.891,0-169.754-62.769-169.754-151.238c0-47.307,18.523-84.346,57.605-111.101
			c33.947-23.657,87.443-32.915,154.321-47.322c61.721-13.367,94.644-17.483,94.644-55.547c0-28.806-27.788-51.445-68.929-51.445
			c-53.488,0-97.727,16.458-137.856,52.471v38.057h-94.636v-166.655h94.636v34.973c41.147-31.882,84.368-47.314,133.74-47.314
			c113.159,0,181.069,62.754,181.069,151.23C7038.833-653.293,6983.271-606.997,6869.072-583.332"/>
					</g>
				</g>
            </svg>
        </span>
    )
};

export default XrathusLogoHorizontal;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {openHelpWidgetWithParams} from '../utils/pageFunctions';
import PagePrompt from '../partials/PagePrompt';
import SubstratesIconLinks from '../partials/SubstratesIconLinks';
import SubstratesProcess from '../css/images/Substrates-Process.png';
import SubstrateSectionConnect from '../partials/SubstrateSectionConnect';
import SubstrateSectionHub from '../partials/SubstrateSectionHub';
import SubstrateSectionMarketplace from '../partials/SubstrateSectionMarketplace';
import SubstrateSectionTraining from '../partials/SubstrateSectionTraining';
import '../css/page.css';

class Substrates extends Component {

    handleOpenWidget = (section, args = null) => {
        let params = {
            type: 'Question'
        };
        if (section === 'connect' || section === 'hub' || section === 'marketplace' || section === 'training') {
            params.subject = args.subject;
            params.type = args.type;
        }
        if (section === 'page-form') {
            params.subject = args.subject;
            params.type = args.type;
            if (args.name) {
                params.name = args.name;
            }
            if (args.company) {
                params.company = args.company;
            }
            if (args.email) {
                params.email = args.email;
            }
        }
        openHelpWidgetWithParams(params, true);
    };

    render() {
        return (
            <div className="content full substrates">

                {/* Section: Hero */}
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel full bg substrates-hero no-border">
                            <div className="row">
                                <div className="col col-xs-12 col-sm-5 vertical-center">
                                    <h2>
                                        Connect, innovate, commercialize, capture, and learn.
                                    </h2>
                                    <p>
                                        Xrathus provides the collaboration functionality you need
                                        to manage projects and knowledge from inception to
                                        completion.
                                    </p>
                                </div>
                                <div className="col col-xs-12 col-sm-7 substrates-half">
                                    <SubstratesIconLinks
                                        allowHover={false}
                                        allowLink={false}
                                        isAuthenticated={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section: Introduction */}
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="panel full substrates-intro no-border">
                            <h2>
                                Xrathus enables faster, better outcomes by fostering
                                <span className="break desktop"> </span>
                                multidisciplinary interactions and new ways of working.
                            </h2>
                            <br/>
                            <p>
                                The Xrathus environment is built on four
                                substrates: <a href="#connect" title="Connect">Connect</a>
                                , <a href="#hub" title="Hub">Hub</a>
                                , <a href="#marketplace" title="Marketplace">Marketplace</a>
                                , and <a href="#training" title="Training">Training</a>.
                            </p>
                            <p>
                                Each substrate contains a framework of on-demand tools that
                                stimulate collaboration through continuous feedback loops and
                                seamless integration.
                            </p>
                            <p>
                                Workflows help participants move between tools, streamlining
                                projects, evolving collective knowledge, and minimizing delivery
                                times.
                            </p>
                            <p>
                                The real-time collaboration zone within
                                the <a href="#hub" title="Hub">Hub</a> substrate makes
                                bichronous collaboration available to all project participants.
                            </p>
                            <img className="substrates-process-image" src={SubstratesProcess} alt="Xrathus Process"/>
                        </div>
                    </div>
                </div>

                {/* Section: Substrates */}
                <SubstrateSectionConnect onClickFunc={(args) => this.handleOpenWidget('connect', args)}/>
                <SubstrateSectionHub onClickFunc={(args) => this.handleOpenWidget('hub', args)}/>
                <SubstrateSectionTraining onClickFunc={(args) => this.handleOpenWidget('training', args)}/>
                <SubstrateSectionMarketplace onClickFunc={(args) => this.handleOpenWidget('marketplace', args)}/>

                {/* Section: Form */}
                <PagePrompt type="substrates" onSubmitFunc={(args) => this.handleOpenWidget('page-form', args)}/>

            </div>
        )
    }
}

export default withRouter(Substrates);

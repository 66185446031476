import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useSortBy, useFilters, usePagination} from 'react-table';
import {RESOURCES as resources} from '../resources';
import {makeGetDataRequest} from '../utils/getRequest';
import ActiveFilter from './filters/ActiveFilter';
import ChevronDown from '../css/images/icon-Table-Down-Chevron.svg';
import ChevronUp from '../css/images/icon-Table-Up-Chevron.svg';
import ColumnFilter from './filters/ColumnFilter';
import DocumentButton from './buttons/DocumentButton';
import EditButton from './buttons/EditButton';
import LoadingIndicator from './LoadingIndicator';
import ResourceSelectInput from './fields/ResourceSelectInput';
import SearchFilter from './filters/SearchFilter';
import ViewButton from './buttons/ViewButton';

/**
 * Component that gets and displays Challenges in a React Table
 *
 * @param active
 * @param height
 * @return {JSX.Element}
 * @constructor
 */
const ChallengeTable = ({height = 'auto'}) => {

    const [itemList, setItemList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState('true');
    const [searchFilterValue, setSearchFilterValue] = useState('');
    const [searchFilterSubmit, setSearchFilterSubmit] = useState(false);
    const [orgIdFilter, setOrgIdFilter] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [pageState, setPageState] = useState(0);
    const [pageSizeState, setPageSizeState] = useState(5);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortDesc, setSortDesc] = useState(false);

    useEffect(() => {
        const params = {
            active: activeFilter,
            orgId: orgIdFilter,
            page: pageState + 1,
            perPage: pageSizeState,
            sortBy: sortColumn,
            order: sortDesc ? 'DESC' : 'ASC',
            search: searchFilterValue
        };
        const fetchData = () => {
            setIsLoading(true);
            const request = makeGetDataRequest(resources, 'Challenge', 'GET_LIST', params);
            request.then(result => {
                setIsLoading(false);
                if (result) {
                    if (typeof result === 'string') {
                        console.log('Challenges Error: ', result);
                        setItemList([]);
                        setTotalRows(0);
                        setPageState(0);
                        return Promise.resolve(result);
                    }
                    if (result.Challenges) {
                        setItemList(result.Challenges);
                        setTotalRows(result.TotalRows);
                    }
                    return Promise.resolve(result);
                } else {
                    console.log('Challenges Error: No result');
                    return Promise.reject('Challenges failed');
                }
            }).catch(error => {
                console.log('Challenges Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter, orgIdFilter, pageState, pageSizeState, sortColumn, sortDesc, searchFilterSubmit]);

    // Set org filter
    const handleOrgFilter = (e) => {
        setOrgIdFilter(e.id);
        setPageState(0);
    };

    // Set active filter
    const handleActiveFilter = (e) => {
        setActiveFilter(e.value);
        setPageState(0);
    };

    // Set search filter
    const handleSearchFilter = (e) => {
        setSearchFilterValue(e.target.value);
        setPageState(0);
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchFilterSubmit(!searchFilterSubmit);
        setPageState(0);
    }

    const handleSort = (id) => {
        if (id) {
            setSortColumn(id);
            setSortDesc(!sortDesc);
            setPageState(0);
        }
    };

    const handleTableRows = (e) => {
        setPageSizeState(e.target.value);
        setPageState(0);
    };

    // Set table data
    const tableData = useMemo(() => itemList, [itemList]);

    // Set table columns
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                Filter: ColumnFilter,
                disableFilters: true
            },
            {
                Header: 'Title',
                accessor: 'title',
                Filter: ColumnFilter,
                disableFilters: true
            },
            {
                Header: 'Published',
                accessor: 'published',
                Cell: (value) => (value.cell.row.original.published) ? 'Yes' : 'No',
                Filter: ColumnFilter,
                disableFilters: true
            },
            {
                Header: 'Private',
                accessor: 'private',
                Cell: (value) => (value.cell.row.original.private) ? 'Yes' : 'No',
                Filter: ColumnFilter,
                disableFilters: true
            },
            {
                Header: 'Complete',
                accessor: 'complete',
                Cell: (value) => (value.cell.row.original.complete) ? 'Yes' : 'No',
                Filter: ColumnFilter,
                disableFilters: true
            },
            {
                Header: '',
                accessor: ' ',
                Cell: (value) => <ViewButton type='x/hub/challenge' record={value} label="View Challenge"/>,
                maxWidth: 50,
                Filter: ColumnFilter,
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: '',
                accessor: '  ',
                Cell: (value) => (<EditButton type='x/hub/challenge' record={value} label="Edit Challenge"/>),
                maxWidth: 50,
                Filter: ColumnFilter,
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: '',
                accessor: '   ',
                Cell: (value) => (<DocumentButton type='x/hub/challenge/dataset' record={value} label="Edit Dataset"/>),
                maxWidth: 50,
                Filter: ColumnFilter,
                disableFilters: true,
                disableSortBy: true
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state: {pageIndex},
        prepareRow
    } = useTable(
        {
            columns,
            data: tableData,
            initialState: {pageIndex: pageState, pageSize: pageSizeState},
            manualPagination: true,
            pageCount: totalRows
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const totalPages = Math.ceil(totalRows / pageSizeState);

    return (
        <>
            <div className="x-table-filters">
                <ActiveFilter type='challenge' handleActive={handleActiveFilter}/>
                <div className="form-group x-filter-org">
                    <ResourceSelectInput
                        label="By Organization"
                        resource="Organization"
                        optionText="name"
                        optionValue="id"
                        addOption={{label: 'All', value: '0'}}
                        params={{active: true, page: 1, perPage: 10000}}
                        changeFunc={(e => handleOrgFilter(e))}
                    />
                </div>
                <SearchFilter type='challenge' label="Search by Title" changeFunc={handleSearchFilter}
                              submitFunc={handleSearchSubmit}/>
            </div>

            <div className="x-table-container" style={{height: height}}>
                <table {...getTableProps()} className="x-table last-3 challenge">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.canSort &&
                                    <div
                                        onClick={() => handleSort(column.id)}
                                        className="no-wrap"
                                    >
                                        {column.render('Header')}
                                        <span className="btn-icon xs">
                                        {(sortColumn === column.id) ?
                                            (sortDesc ?
                                                <img src={ChevronDown} alt=""/> : <img src={ChevronUp} alt=""/>) : ''}
                                        </span>
                                    </div>
                                    }
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);

                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {itemList.length === 0 &&
                <table className="x-table">
                    <tbody>
                    <tr>
                        <td>
                            No Challenges found
                        </td>
                    </tr>
                    </tbody>
                </table>
                }
                <div className="x-table-page-controllers">
                    <span>{`Page ${pageIndex + 1} of ${totalPages} `}</span>
                    <select value={pageSizeState} onChange={(e) => handleTableRows(e)}>
                        {
                            [5, 10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>Show {pageSize}</option>
                            ))
                        }
                    </select>
                    <button onClick={() => setPageState(0)} disabled={pageIndex + 1 <= 1}>{'<<'}</button>
                    <button onClick={() => setPageState(prevState => prevState - 1)}
                            disabled={pageIndex + 1 <= 1}>Previous
                    </button>
                    <button onClick={() => setPageState(prevState => prevState + 1)}
                            disabled={pageIndex + 1 >= totalPages}>Next
                    </button>
                    <button onClick={() => setPageState(totalPages - 1)}
                            disabled={pageIndex + 1 >= totalPages}>{'>>'}</button>
                    <span>
                        &nbsp; Go to Page:
                        <input
                            type="number"
                            min={1}
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                setPageState(pageNumber);
                            }}
                            style={{width: '50px'}}
                        />
                    </span>
                </div>
            </div>

            {isLoading &&
            <LoadingIndicator color="dark" centered={false} size={36} active={true}/>
            }
        </>
    );
};

export default ChallengeTable;

import React from 'react';
import PropTypes from 'prop-types';
import StoryblokContentFetcher from '../content-fetcher/StoryblokContentFetcher';
import YoutubePlaylistFetcher from '../content-fetcher/YoutubePlaylistFetcher';
import { getListItemRenderer, getListRenderer } from '../../../utils/StoryblokFunctions';


const ContentList = ({
    isVertical,
    is2D,
    useSlides,
    direction,
    listType,
    tags,
    playlistLink,
    //slidesToShow,
    //infiniteSlides,
    customNullText,
    sortByPriority,
    sortedBy,
    //content,
    //storyblokFormatted,
    //demoRouter,
}) => {

    if (!direction) {
        //direction = 
        direction = 'horizontal';
        if (isVertical) {
            direction = 'vertical';
        }
        if (useSlides) {
            direction = 'horizontal-slides';
        }
        if (is2D) {
            direction = 'grid';
        }
    }

    let ListRenderer = getListRenderer(direction);

    let useYoutubeFetcher = false;

    let paramsListType = listType;

    let ItemRenderer = getListItemRenderer(listType);

    if (sortByPriority) {
        sortedBy = 'priority';
    }

    switch (listType) {
        case 'highlights':
            break;
        case 'events':
            sortedBy = 'time-from-present';
            break;
        case 'youtube-playlist':
            useYoutubeFetcher = true;
            break;
        case 'people':
            break;
        case 'knowledge-base-documents':
            paramsListType = 'documents';
            break;
        case 'solution-documents':
            paramsListType = 'documents';
            break;
        case 'xrathus-documents':
            paramsListType = 'documents';
            break;
        case "posts":
            break;
        case "terms":
            break;
        default:
            break;
    }
    
    let params = {
        version: process.env.REACT_APP_STORYBLOK_API_KEY_TYPE,
        cv: Date.now(),
        starts_with: paramsListType+"/",
        with_tag: tags.replace(" ", ","),
        per_page: 25,
    };

    if (useYoutubeFetcher) {
        return (
            <div className="scroll-list center">
                <YoutubePlaylistFetcher playlistId={playlistLink} render={ItemRenderer}/>
            </div>
        );
    }
    else {
        return (
            <div className="">
                <ListRenderer>
                    <StoryblokContentFetcher params={params} render={ItemRenderer} ordered={sortedBy}
                        nullText={(customNullText!=="") ? customNullText : "No Content"}/>
                </ListRenderer>
            </div>
        );
    }
    

};

ContentList.propTypes = {
/**
     * Is the list vertical?
     * This is going to be deprecated. "direction" covers what needs to be covered
     */
    isVertical: PropTypes.bool,
    /**
     * Is the list a 2D grid?
     * This is going to be deprecated. "direction" covers what needs to be covered
     */
    is2D: PropTypes.bool,
    /**
     * Organize the data in arrow-click browsable slides. Ignored if 'isVertical' is true.
     * This is going to be deprecated. "direction" covers what needs to be covered
     */
    useSlides: PropTypes.bool,
    /**
     * Which direction does the list go?
     */
    direction: PropTypes.oneOf([
        'vertical', 
        'horizontal', 
        'horizontal-slides', 
        'grid',]),
    /**
     * If 'content' is chosen, content is used and allStories are ignored. 
     * Otherwise, list gets pulled from the chosen entry in allStories.
     */
    listType: PropTypes.oneOf([
        'events', 
        'highlights', 
        'youtube-playlist', 
        'people', 
        'knowledge-base-documents', 
        'solution-documents', 
        'xrathus-documents',
        'topics',
        'posts', 
        'terms',
        'content']),
    /**
     * Tags for filtering content
     */
    tags: PropTypes.string,
    /**
     * Playlist link, only used if selected listType is 'youtube-playlist'
     */
    playlistLink: PropTypes.string,
    /**
     * Determines the default max number of slides to show in a row. 
     * Ignored if 'useSlides' is false.
     */
    slidesToShow: PropTypes.number,
    /**
     * Enable infinite scroll.
     * Ignored if 'useSlides' is false.
     */
    infiniteSlides: PropTypes.bool,
    /**
     * Custom text to be shown if nothing being displayed
     */
    customNullText: PropTypes.string,
    /**
     * (deprecated)
     * Sort based on the priorty parameter.
     * Eventually may need an enum if other sorts are introduced
     */
    sortByPriority: PropTypes.bool,
    /**
     * Sort enum
     */
    sortedBy: PropTypes.oneOf([
        'alphabet', 
        'priority',
        'time',
        'time-from-present',
    ]),
    /**
     * Content array, only used if selected listType is 'content'
     */
    content: PropTypes.array,
    /**
     * All the content that may be related that is parsed through to find the relevant content
     */
    allStories: PropTypes.object,
    /**
     * Is content formatted specifically by Storyblok?
     */
    storyblokFormatted: PropTypes.bool,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
};

ContentList.defaultProps = {
    isVertical: true,
    is2D: false,
    useSlides: false,
    direction: null,
    listType: 'content',
    tags: "",
    playlistLink: "",
    slidesToShow: 4,
    infiniteSlides: false,
    customNullText: "",
    sortByPriority: false,
    sortedBy: 'alphabet',
    content: [],
    storyblokFormatted: false,
    demoRouter: false,
};

export default ContentList;
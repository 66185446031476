import React, {useState, useEffect, useCallback} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setProjectConfigUserRefresh, setProjectListRefresh} from '../store/actions';
import ReactMarkdown from 'react-markdown';
import {RESOURCES as resources} from '../resourcesProject';
import {isBlank, makeDateString} from '../utils/dataFunctions';
import {makePostDataRequest} from '../utils/postRequest';
import DeleteProjectButton from './buttons/DeleteProjectButton';
import EditFilesButton from './buttons/EditFilesButton';
import FileDatasetList from './FileDatasetList';
import LoadingIndicator from './LoadingIndicator';
import ProjectConfigFields from './forms/ProjectConfigFields';
import UnsubscribeSelfButton from './buttons/UnsubscribeSelfButton';
import IconCheckmark from './images/IconCheckmark';

const tabs = {
    overview: {tab: 'overview', title: 'Overview'},
    description: {tab: 'description', title: 'Description'},
    data: {tab: 'data', title: 'Data'}
};

const lab_url = localStorage.getItem('lab_url');

/**
 * Panel: Project Detail
 *
 * @param isSysop
 * @param basePath
 * @param projectConfig
 * @param projectItem
 * @param projectDataset
 * @param projectFiles
 * @param projectType
 * @param user
 * @param onAirmeetLink
 * @param onJoinOrLeaveFunc
 * @param inInvitedProjects
 * @param inSubscribedProjects
 * @param inOwnProjects
 * @param allowLab
 * @param tab
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectDetail = (
    {
        isSysop,
        basePath = '',
        projectConfig,
        projectItem,
        projectDataset,
        projectFiles,
        projectType,
        user,
        onAirmeetLink,
        onJoinOrLeaveFunc,
        inInvitedProjects = false,
        inSubscribedProjects = false,
        inOwnProjects = false,
        allowLab = false,
        tab = 'overview',
        ...props
    }) => {

    const [tabState, setTab] = useState({
        tab: tabs.overview.tab,
        title: tabs.overview.title
    });

    const [isSaving, setIsSaving] = useState(false);
    const [message, setMessage] = useState({
        error: false,
        message: ''
    });
    const [labMessage, setLabMessage] = useState(false);
    const [redirect, setRedirect] = useState(false);

    // Set tab if an outside source requests it
    useEffect(() => {
        // @TODO first click from outside after internal tabState change fails
        // @TODO avoid checking/update internally
        if (tabs[tab] && (tabs[tab].tab)) {
            // console.log('useEffect check tab: current ' + tabState.tab + ', new: ', tab);
            // console.log('useEffect tabs[tab].tab: ', tabs[tab].tab);
            // changeTab(tabs[tab].tab, tabs[tab].title);
        }
    }, [tab]);

    const changeTab = (tab, title) => {
        setIsSaving(false);
        setMessage({error: false, message: ''});
        setTab({tab: tab, title: title});
    };

    // API: Join Project
    const joinProject = () => {
        setIsSaving(true);
        const request = makePostDataRequest(resources, 'ProjectPersonalJoin', 'PUT', {id: projectItem.id});
        let errorMessage = 'Sorry, there was an issue joining this Project.';
        request.then(result => {
            setIsSaving(false);
            if (result && (result.sign_up_date || result === 'OK')) {
                setMessage({error: false, message: 'You have successfully joined this Project.'});
                // Notify the parent page
                if (typeof onJoinOrLeaveFunc === 'function') {
                    onJoinOrLeaveFunc('subscribe');
                }
                return Promise.resolve(result);
            } else {
                if (result?.data?.error) {
                    if (
                        result.data.error.indexOf('no personal project share found') !== -1 ||
                        result.data.error.indexOf('no project found') !== -1
                    ) {
                        errorMessage = 'No Personal Project was found.';
                    }
                    if (result.data.error.indexOf('already') !== -1) {
                        errorMessage = 'You are already participating in this Project.';
                    }
                }
                setMessage({
                    error: true,
                    message: errorMessage
                });
                return Promise.reject('Unsubscribe failed');
            }
        }).catch(error => {
            // console.log('Join Project Error: ', error);
            setIsSaving(false);
            setMessage({
                error: true,
                message: errorMessage
            });
            return Promise.reject('Server Error');
        });
    };

    const handleUnsubscribe = (e) => {
        const actions = (e === 'reject')
            ? {
                past: 'rejected',
                func: 'reject',
            }
            : {
                past: 'unsubscribed from',
                func: 'subscribe',
            };
        if (typeof onJoinOrLeaveFunc === 'function') {
            onJoinOrLeaveFunc(actions.func);
        }
        setMessage({
            error: false,
            message: 'You have successfully ' + actions.past + ' this Project.'
        });
    };

    const handleDeleted = () => {
        // Refresh User Configuration (for Project limits) and list of own Projects
        props.setProjectConfigUserRefresh(true);
        props.setProjectListRefresh('personal_own');
        setTimeout(() => {
            setRedirect(true);
        }, 200);
    };

    // Handle click from Session Join button
    const launchAirmeetEvent = useCallback((uuid) => {
        if (typeof onAirmeetLink === 'function') {
            onAirmeetLink('event', uuid);
        }
    }, [onAirmeetLink]);

    const displayLabMessaging = () => {
        setLabMessage(true);
    };

    const setButtonDisplay = () => {
        return {
            join: (!(inSubscribedProjects || inOwnProjects)),
            launch: !!(inSubscribedProjects || inOwnProjects),
            leave: (inSubscribedProjects && !inOwnProjects && !isSysop),
            reject: (inInvitedProjects && !inSubscribedProjects)
        };
    };

    if (redirect) {
        return <Redirect to="/x/hub/projects"/>
    }

    const dateObj = (projectItem.due_date) ? new Date(projectItem.due_date.replace(/-/g, '/').replace(/T.+/, '')) : null;
    const dueStr = (dateObj) ? makeDateString(dateObj, 'm', false) : null;
    const discourseLink = (projectItem.discourse_id)
        ? localStorage.getItem('forum_url') + 'c/project/-/' + projectItem.discourse_id
        : null;
    const projectPath = (projectType === 'personal' || projectType === 'personal_own') ? 'project/personal' : 'project';
    const buttonDisplay = setButtonDisplay();
    const hasAirmeet = (!isBlank(projectItem.airmeet_id));

    return (
        <div className="panel no-padding">

            <h3 className="header">{tabState.title}</h3>

            <div className="xrathus-detail">

                {/* Left Side Detail Menu */}

                <div className="item-menu">
                    <ul>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.overview.tab, tabs.overview.title)}>
                                Overview
                            </button>
                        </li>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.description.tab, tabs.description.title)}>
                                Description
                            </button>
                        </li>
                        <li>
                            <button className="btn"
                                    onClick={() => changeTab(tabs.data.tab, tabs.data.title)}>
                                Data
                            </button>
                        </li>
                        <li className="divider">
                        </li>
                        {discourseLink &&
                        <li>
                            <a href={discourseLink} title="View Cluster" className="btn"
                               target="_blank" rel="noopener noreferrer">
                                View Cluster
                            </a>
                        </li>
                        }
                    </ul>
                </div>

                {/* Right-Side Detail Content */}

                <div className="item-content">
                    {/* Tab: Overview */}
                    {tabState.tab === 'overview' &&
                    <>
                        {projectItem.username &&
                        <>
                            <h4>Project Owner</h4>
                            <p className="details">{projectItem.username}</p>
                        </>
                        }

                        <h4>Short Description</h4>
                        <ReactMarkdown source={projectItem.short_desc}/>

                        {projectItem.goals &&
                        <>
                            <h4>Goals</h4>
                            <ReactMarkdown source={projectItem.goals}/>
                        </>
                        }

                        {dueStr &&
                        <>
                            <h4>Due Date</h4>
                            <p className="details">{dueStr}</p>
                        </>
                        }
                        {(isSysop || inOwnProjects) &&
                        <div className="row mb-2">
                            <span className="col col-xs-6 col-md-4">
                                <h4>Active</h4>
                                <p className="details">{projectItem.active ? 'Yes' : 'No'}</p>
                            </span>

                            <span className="col col-xs-6 col-md-4">
                                <h4>Complete</h4>
                                <p className="details">{projectItem.complete ? 'Yes' : 'No'}</p>
                            </span>
                        </div>
                        }

                        {/* Project Configuration (Sysop or creator only) */}
                        {(isSysop || inOwnProjects) &&
                        <ProjectConfigFields
                            viewType="show"
                            project={projectItem}
                            ownProject={inOwnProjects}
                            config={projectConfig}
                            isSysop={isSysop}
                        />
                        }
                    </>
                    }

                    {/* Tab: Description */}
                    {tabState.tab === 'description' &&
                    <>
                        <h4>Introduction</h4>
                        <ReactMarkdown source={projectItem.introduction}/>
                        <h4>Summary</h4>
                        <ReactMarkdown source={projectItem.summary}/>
                        <h4>Community Description</h4>
                        <ReactMarkdown source={projectItem.community_desc}/>
                    </>
                    }

                    {/* Tab: Data */}
                    {tabState.tab === 'data' &&
                    <>
                        {projectDataset &&
                        <>
                            <h4>Data Description</h4>
                            <ReactMarkdown source={projectDataset.description}/>
                        </>
                        }
                        {projectFiles && projectFiles.length > 0 &&
                        <>
                            <h4>Files ({projectFiles.length})</h4>
                            <FileDatasetList list={projectFiles}/>
                        </>
                        }
                    </>
                    }

                    {/* Buttons and Messaging */}

                    <div className="form-btns right">
                        {isSaving &&
                        <p className="message">
                            <LoadingIndicator color="dark" centered={false} size={26} active={isSaving}/>
                        </p>
                        }
                        {!message.error && message.message !== '' &&
                        <p className="message">
                            <IconCheckmark/>
                        </p>
                        }
                        {buttonDisplay.join &&
                        <button type="button" className="btn btn-primary" title="Join Project"
                                onClick={() => joinProject()}
                        >
                            Join Project
                        </button>
                        }
                        {buttonDisplay.reject &&
                        <UnsubscribeSelfButton
                            type="reject"
                            projectId={projectItem.id}
                            username={user.preferred_username}
                            onFormEvent={() => handleUnsubscribe('reject')}
                        />
                        }
                        {buttonDisplay.leave &&
                        <UnsubscribeSelfButton
                            type="unsubscribe"
                            projectId={projectItem.id}
                            username={user.preferred_username}
                            onFormEvent={() => handleUnsubscribe('unsubscribe')}
                        />
                        }
                        {(isSysop || inOwnProjects) &&
                        <Link className="btn btn-primary"
                              to={`${basePath}/x/hub/${projectPath}/${projectItem.id}/edit`}>
                            Edit Project
                        </Link>
                        }
                        {(isSysop || inOwnProjects) &&
                        <>
                            <Link className="btn btn-primary"
                                  to={`${basePath}/x/hub/${projectPath}/dataset/${projectItem.data_id}/edit`}>
                                Edit Dataset
                            </Link>
                            <EditFilesButton
                                id={projectItem.id}
                                type="project"
                            />
                        </>
                        }
                        {(inSubscribedProjects || inOwnProjects) && hasAirmeet &&
                            <button type="button" className="btn btn-primary"
                                    title="Access Project Real Time Collaboration Tool"
                                    onClick={() => launchAirmeetEvent(projectItem.airmeet_id)}
                            >
                                Launch RTC
                            </button>
                        }
                        {allowLab && buttonDisplay.launch &&
                        <a href={lab_url} title="Launch Workspace" className="btn btn-primary" target="_blank"
                           rel="noopener noreferrer">
                            Launch Workspace
                        </a>
                        }
                        {(isSysop || inOwnProjects) &&
                        <DeleteProjectButton
                            projectId={projectItem.id}
                            projectName={projectItem.title}
                            onDeletedProject={() => handleDeleted()}
                        />
                        }
                        {!allowLab && inSubscribedProjects &&
                        <button type="button" className="btn btn-primary" title="Join Project"
                                onClick={() => displayLabMessaging()}
                        >
                            Launch Workspace
                        </button>
                        }
                    </div>

                    {message.message !== '' &&
                    <div className="form-btns right mt-1">
                        <p className={`message${(message.error) ? ' error' : ''}`}>
                            {message.message}
                        </p>
                    </div>
                    }

                    {labMessage &&
                    <div className="form-btns right">
                        <p className="message right">You need to be granted access to the Xrathus Lab.<br/>
                            Once status is granted, please logout and log back in to access.
                        </p>
                    </div>
                    }

                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setProjectConfigUserRefresh: setProjectConfigUserRefresh,
    setProjectListRefresh: setProjectListRefresh
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(ProjectDetail)
);

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useStoryblokFetch, getPageRenderer, filterStoryblokContentThroughGroups} from '../../../utils/StoryblokFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import NotFound from '../../../routes/NotFound';

import '../../../css/storyblok-custom.css';


const StoryblokPageFetcher = ({
    url,
    params,
    nullRender,
    user
}) => {

    //@TODO: filtering by url content may not be the most robust, may need fixing
    const pageFolder = url.split('/')[0];

    //for localstorage purposes, clean up params
    let localStorageSaveParams = params;
    if (localStorageSaveParams['cv']) {
        delete localStorageSaveParams['cv'];
    }
    if (localStorageSaveParams['token']) {
        delete localStorageSaveParams['token'];
    }

    //check if content has been fetched recently
    const prevTime = localStorage.getItem(`fetchedContent/${url}/${JSON.stringify(localStorageSaveParams)}/fetchTime`);

    const savedPage = JSON.parse(localStorage.getItem(`fetchedPage/${url}`));

    const [data, isLoading] = useStoryblokFetch(url, params, 300);

    if (!url || url === "") {
        if (nullRender) {
            return nullRender(null);
        }
        else {
            return (
                <NotFound/>
            );
        }
    }

    if (isLoading) {
        if (data && data.story) {
            return getPageRenderer(pageFolder)(data.story);
        }
        if (savedPage) {
            return getPageRenderer(pageFolder)(savedPage.story);
        }
        if (nullRender) {
            return nullRender(null);
        }
        if (prevTime && (new Date().getTime() - prevTime)>2000.0) {
            return (
                <NotFound/>
            );
        }
        //return setTimeout(returnNotFound, 2000);
        return <LoadingIndicator color="dark" centered={true} size={60} active={isLoading}/>;
    }

    if (data && data.story) {
        if (localStorageSaveParams['cv']) {delete localStorageSaveParams['cv'];} if (localStorageSaveParams['token']) {delete localStorageSaveParams['token'];}
        localStorage.setItem(`fetchedContent/${url}/${JSON.stringify(localStorageSaveParams)}/fetchTime`, `${new Date().getTime()}`);
        
        if (!savedPage || (savedPage !== data.story)) {
            const filteredPage = user ? filterStoryblokContentThroughGroups(data.story, user.groups) : data.story;

            if (filteredPage) {
                if (filteredPage !== savedPage)
                    localStorage.setItem(`fetchedPage/${url}`, JSON.stringify(data));
            }
            else {
                return (
                    <NotFound/>
                );
            }
                
        }
        return getPageRenderer(pageFolder)(data.story);
    }
    if (savedPage) {
        return getPageRenderer(pageFolder)(savedPage.story);
    }

    return (
        <NotFound/>
    );

};


StoryblokPageFetcher.propTypes = {
    /**
     * What is the displayed title?
     */
    url: PropTypes.string,
    /**
     * Required style
     */
    params: PropTypes.any,
    nullRender: PropTypes.any,
    user: PropTypes.any,
};

StoryblokPageFetcher.defaultProps = {
    url: "",
    params: {},
    nullRender: null,
    user: null,
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(StoryblokPageFetcher)
);

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import DatePicker from 'react-datepicker';
import {RESOURCES as resources} from '../../resources';
import {makeGetDataRequest} from '../../utils/getRequest';
import {makePostDataRequest} from '../../utils/postRequest';
import {createDateTimeObject} from '../../utils/dataFunctions';
import {setDueDate} from '../../utils/projectFunctions';
import BaseInput from '../fields/BaseInput';
import SelectInput from '../fields/SelectInput';
import FormError from './FormError';
import LoadingIndicator from '../LoadingIndicator';
import TextAreaInput from '../fields/TextAreaInput';
import IconUser from '../images/IconUser';
import {CHALLENGE_TABS} from '../../data/formTabs';
import {BOOLEAN_OPTIONS} from '../../data/objects';
import 'react-datepicker/dist/react-datepicker.min.css';

/**
 * Form: Edit Challenge
 *
 * @param basePath
 * @param challengeId
 * @param challengePath
 * @param formStage
 * @param onFormEvent
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormChallengeEdit(
    {
        basePath = '',
        challengeId,
        challengePath,
        formStage,
        onFormEvent
    }) {

    // Set up form and state
    const {register, errors, getValues, handleSubmit, setValue} = useForm({mode: 'onBlur'});

    // State: Form Stage (from parent)
    const [formStageState, updateFormStageState] = useState(
        {
            stage: formStage
        }
    );
    if (formStageState.stage !== formStage) {
        updateFormStageState({...formStageState, stage: formStage});
    }

    // State: Organization Name
    const [orgName, setOrgName] = useState('');

    // State: Due Date Object
    const [dueDateState, updateDueDateState] = useState(
        {
            min_date_obj: setDueDate('/'),
            due_date_obj: ''
        }
    );
    // State: Success
    const [successState, updateSuccessState] = useState(
        {
            message: '',
            success: false
        }
    );
    // State: Current Tab
    const [tabState, setTab] = useState(CHALLENGE_TABS.overview);

    useEffect(() => {
        const params = {
            id: challengeId
        };
        const fetchData = () => {
            const request = makeGetDataRequest(resources, 'Challenge', 'GET_ONE', params);
            request.then(result => {
                if (result) {
                    const data = JSON.parse(JSON.stringify(result));
                    fetchOrgData({getBy: 'id', id: data.org_id});
                    setValue('id', result?.id ?? '');
                    setValue('active', !!(result.active));
                    setValue('community_desc', result?.community_desc ?? '');
                    setValue('complete', !!(result.complete));
                    setValue('due_date', result?.due_date ?? '');
                    setValue('goals', result?.goals ?? '');
                    setValue('introduction', result?.introduction ?? '');
                    setValue('private', !!(result.private));
                    setValue('published', !!(result.published));
                    setValue('title', result?.title ?? '');
                    setValue('reward', result?.reward ?? '');
                    setValue('summary', result?.summary ?? '');

                    const due_date_obj = (data.due_date) ? new Date(data.due_date.replace(/-/g, '/').replace(/T.+/, '')) : '';
                    updateDueDateState({...dueDateState, due_date_obj: due_date_obj});
                    return Promise.resolve(result);
                } else {
                    console.log('Challenge Error: No result');
                    return Promise.reject('Challenge failed');
                }
            }).catch(error => {
                console.log('Challenge Error: ', error);
                return Promise.reject('Server Error');
            });
        };

        return fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrgData = (params) => {
        const request = makeGetDataRequest(resources, 'Organization', 'GET_ONE', params);
        request.then(result => {
            if (result) {
                const data = JSON.parse(JSON.stringify(result));
                setOrgName(data.name);
                return Promise.resolve(result);
            } else {
                console.log('Organization Error: No result');
                return Promise.reject('Challenge failed');
            }
        }).catch(error => {
            console.log('Organization Error: ', error);
            return Promise.reject('Server Error');
        });
    };

    // Determine if form is incomplete for disabling specific elements
    const disableSubmit = () => {
        return !!(
            getValues('introduction') === '' ||
            getValues('reward') === '' ||
            getValues('summary') === '' ||
            getValues('title') === '' ||
            tabState.tab === 'saving' ||
            (errors &&
                (
                    errors.introduction ||
                    errors.reward ||
                    errors.summary ||
                    errors.title
                ))
        );
    };

    // Handle change from specific form fields
    const handleFormChange = (e) => {
        if (e.name === 'due_date') {
            // Set a Due Date object
            const dateString = createDateTimeObject(e.value);
            setValue(e.name, dateString);
            updateDueDateState({...dueDateState, due_date_obj: e.value});
        }
    };

    // Handle submission event
    const onSubmit = (data) => {
        if (data) {
            // console.log('SUBMIT data args before: ', data);

            // Set parameters from form state
            data.id = parseInt(getValues('id'), 10);
            data.active = (getValues('active').toString() === 'true');
            data.complete = (getValues('complete').toString() === 'true');
            data.private = (getValues('private').toString() === 'true');
            data.published = (getValues('published').toString() === 'true');

            // console.log('SUBMIT data args: ', data);

            // Submit form to API
            setTab(CHALLENGE_TABS.saving);
            submitForm(data);
        }
    };

    // Handle API call and post-submission
    const submitForm = (data) => {
        const request = makePostDataRequest(resources, 'Challenge', 'UPDATE', data);
        request.then(result => {
            if (result && result.id) {
                updateSuccessState({success: true, message: ''});
                setTab(CHALLENGE_TABS.success);
                setPostChallenge(result);
                return Promise.resolve(result);
            } else {
                updateSuccessState({success: false, message: 'Sorry, your Challenge could not be updated.'});
                setTab(CHALLENGE_TABS.fail);
                return Promise.resolve();
            }
        }).catch(error => {
            console.log('Updated Challenge Error: ', error);
            updateSuccessState({success: false, message: 'Sorry, there was an error with your Challenge: ' + error});
            setTab(CHALLENGE_TABS.fail);
            return Promise.reject('Server Error');
        });
    };

    // Process after Challenge form completion
    const setPostChallenge = (data) => {
        if (onFormEvent !== null && typeof onFormEvent === 'function') {
            // Notify the page that Challenge has been created
            onFormEvent('challenge', 'complete', data);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="xrathus-detail">

                {/* Form Menu */}
                <div className="item-menu">
                    <ul>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.overview)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Overview
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.information)}
                                    disabled={(tabState.tab === 'saving') ? true : undefined}
                            >
                                Information
                            </button>
                        </li>
                        <li>
                            <button type="button" className="btn"
                                    onClick={() => setTab(CHALLENGE_TABS.confirm)}
                                    disabled={!!disableSubmit()}
                            >
                                Finalize
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="item-content">

                    {/* Tab: Overview */}

                    <div className={`tab-content ${(tabState.tab === 'overview') ? 'active' : ''}`}>
                        <p className="instructions">
                            Select an Organization this Challenge is associated with.<br/>
                            Enter basic Challenge information. Click <em>Next</em> to enter Description information.
                        </p>
                        <div className="form-group">
                            {/* Organization Name */}
                            <BaseInput
                                type="text"
                                value={orgName}
                                id="organization-name"
                                name="organization"
                                label="Organization Name"
                                disabled
                            >
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Title */}
                            <BaseInput
                                type="text"
                                id="challenge-title"
                                name="title" label="Title" placeholder="Title of Challenge"
                                aria-describedby="error-title-required error-title-minLength error-title-maxLength"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Title is required"/>),
                                    minLength: {
                                        value: 3,
                                        message: (<FormError id="minLength"
                                                             text="Title must be at least 3 characters"/>)
                                    },
                                    maxLength: {
                                        value: 80,
                                        message: (<FormError id="maxLength"
                                                             text="Title cannot be longer than 80 characters"/>)
                                    }
                                })}
                                required
                                className={`form-input ${errors.title ? 'input-error' : ''}`}
                            >
                                {errors.title && errors.title.message}
                            </BaseInput>
                        </div>
                        <div className="form-group">
                            {/* Reward */}
                            <TextAreaInput
                                id="challenge-reward"
                                rows={3} cols={50}
                                name="reward" label="Reward"
                                instructions="Enter a dollar amount, list of prizes, or other rewards for this Challenge."
                                aria-describedby="error-reward-required"
                                aria-invalid={errors.reward ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="Reward is required"/>)
                                })}
                                required
                                className={`form-input ${errors.reward ? 'input-error' : ''}`}
                            >
                                {errors.reward && errors.reward.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Goals */}
                            <TextAreaInput
                                id="challenge-goals"
                                rows={3} cols={50}
                                name="goals" label="Challenge Goals"
                                aria-describedby="error-goals-required"
                                aria-invalid={errors.goals ? 'true' : 'false'}
                                ref={register}
                            >
                            </TextAreaInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Due Date */}
                            <label htmlFor="challenge-due-date">
                                <div>Due Date<span className="required">*</span></div>
                                <DatePicker
                                    id="challenge-due-date"
                                    minDate={dueDateState.min_date_obj.due_date}
                                    selected={dueDateState.due_date_obj}
                                    onChange={(e) => handleFormChange({name: 'due_date', value: e})}
                                    dateFormat="MMMM d, yyyy"
                                    popperPlacement="right"
                                    style={{display: 'block'}}
                                />
                                <input type="hidden" name="due_date" ref={register}/>
                            </label>
                            {/* Challenge Active */}
                            <SelectInput
                                id="challenge-active"
                                name="active"
                                label="Active"
                                options={BOOLEAN_OPTIONS}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-group">
                            {/* Private */}
                            <SelectInput
                                id="challenge-private"
                                name="private"
                                label="Private"
                                options={BOOLEAN_OPTIONS}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-group">
                            {/* Published */}
                            <SelectInput
                                id="challenge-published"
                                name="published"
                                label="Published"
                                options={BOOLEAN_OPTIONS}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <div className="form-group row-2">
                            {/* Complete */}
                            <SelectInput
                                id="challenge-complete"
                                name="complete"
                                label="Complete"
                                options={BOOLEAN_OPTIONS}
                                ref={register()}
                            >
                            </SelectInput>
                        </div>
                        <input type="hidden" name="id" ref={register}/>
                    </div>

                    {/* Tab: Information */}

                    <div className={`tab-content ${(tabState.tab === 'information') ? 'active' : ''}`}>
                        <p className="instructions">
                            Enter Challenge descriptive data. Markdown may be used, but no HTML is allowed. When all
                            information is completed, click <em>Next</em> to confirm and Save the Challenge.
                        </p>
                        <div className="form-group">
                            {/* Summary */}
                            <TextAreaInput
                                id="challenge-summary"
                                rows={10} cols={50}
                                name="summary" label="Summary"
                                aria-describedby="error-summary-required"
                                aria-invalid={errors.summary ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="A Summary is required"/>)
                                })}
                                required
                                className={`form-input ${errors.summary ? 'input-error' : ''}`}
                            >
                                {errors.summary && errors.summary.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Introduction */}
                            <TextAreaInput
                                id="challenge-introduction"
                                rows={10} cols={50} maxLength={255}
                                name="introduction" label="Introduction"
                                aria-describedby="error-introduction-required"
                                aria-invalid={errors.introduction ? 'true' : 'false'}
                                ref={register({
                                    required: (<FormError id="required" text="An Introduction is required"/>)
                                })}
                                required
                                className={`form-input ${errors.introduction ? 'input-error' : ''}`}
                            >
                                {errors.introduction && errors.introduction.message}
                            </TextAreaInput>
                        </div>
                        <div className="form-group">
                            {/* Short Description */}
                            <TextAreaInput
                                id="challenge-community-desc"
                                rows={10} cols={50}
                                name="community_desc" label="Community Description"
                                placeholder="Community Description"
                                aria-invalid={errors.community_desc ? 'true' : 'false'}
                                ref={register}
                                className='form-input'
                            >
                            </TextAreaInput>
                        </div>
                    </div>

                    {/* Tab: Confirm && Save */}

                    <div className={`tab-content ${(tabState.tab === 'confirm') ? 'active' : ''}`}>
                        <p className="instructions">
                            Update your Challenge. Once the Challenge is updated, you will be able to upload a Dataset
                            associated with this Challenge if desired.
                        </p>
                    </div>

                    {/* Tab: Saving */}

                    <div className={`tab-content ${(tabState.tab === 'saving') ? 'active' : ''}`}>
                        <h4>Updating Challenge...</h4>
                        <div>
                            <LoadingIndicator color="dark" centered={true} active={true}/>
                        </div>
                    </div>

                    {/* Tab: Success */}

                    <div className={`tab-content ${(tabState.tab === 'success') ? 'active' : ''}`}>
                        <h4>Challenge Updated</h4>
                        <p>
                            Your Challenge has been updated. If this Challenge needs a Dataset go to
                            your Account Dashboard and upload one.
                        </p>
                    </div>

                    {/* Tab: Fail */}

                    <div className={`tab-content ${(tabState.tab === 'fail') ? 'active' : ''}`}>
                        <h4>Error</h4>
                        {successState.message !== '' &&
                        <p className="message">
                            {successState.message}
                        </p>
                        }
                        {successState.message === '' &&
                        <p className="message">
                            Sorry, there was an issue updating this Challenge.
                        </p>
                        }
                    </div>

                    {/* Actions */}

                    <div className="form-btns right">
                        <div className="tab-nav">
                            {tabState.tab === 'overview' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Next"
                                        onClick={() => setTab(CHALLENGE_TABS.information)}
                                >
                                    Next
                                </button>
                            </>
                            }
                            {tabState.tab === 'information' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Back"
                                        onClick={() => setTab(CHALLENGE_TABS.overview)}
                                >
                                    Back
                                </button>
                                <button type="button" className="btn btn-clear" title="Next"
                                        onClick={() => setTab(CHALLENGE_TABS.confirm)}
                                        disabled={!!disableSubmit()}
                                >
                                    Next
                                </button>
                            </>
                            }
                            {tabState.tab === 'confirm' &&
                            <>
                                <button type="button" className="btn btn-clear" title="Back"
                                        onClick={() => setTab(CHALLENGE_TABS.information)}
                                >
                                    Back
                                </button>
                                <button type="submit" className="btn btn-primary" disabled={!!disableSubmit()}>
                                    Update Challenge
                                </button>
                            </>
                            }
                            {tabState.tab === 'success' &&
                            <>
                                <Link to="/x/admin" title="Admin" className="btn btn-primary btn-icon">
                                    <IconUser/>
                                    Admin
                                </Link>
                            </>
                            }
                        </div>
                    </div>
                    <div className="form-btns right mt-2 mb-1">
                        {!successState.success &&
                        <Link className="btn btn-clear" to={`${basePath}/x/${challengePath}/challenge/${challengeId}/overview`}>
                            Cancel
                        </Link>
                        }
                        {successState.success &&
                        <Link className="btn btn-primary" to={`${basePath}/x/${challengePath}/challenge/${challengeId}/overview`}>
                            View Challenge Details
                        </Link>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
}


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FileDirectoryUploadItem from './FileDirectoryUploadItem';

class FileDirectoryUpload extends Component {

    setFileItems = () => {
        const list = this.props.list;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Files Uploading</span>
            )
        }
        return (
            list.map((dir, index) => (
                <FileDirectoryUploadItem
                    key={`directory-upload-${index}`}
                    path={dir.directory}
                    percent={dir.progress}
                    files={dir.files}
                    totalFiles={dir.files.length}
                />
            ))
        )
    };

    render() {
        const {
            list
        } = this.props;

        return (
            <div>
                {list.length > 0 &&
                <>
                    {[...this.setFileItems()]}
                </>
                }
            </div>
        )
    }
}

FileDirectoryUpload.propTypes = {
    list: PropTypes.array
};

FileDirectoryUpload.defaultProps = {
    list: []
};

export default FileDirectoryUpload;

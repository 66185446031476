import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import StoryblokDataSourceFetcher from '../content-fetcher/StoryblokDataSourceFetcher';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';


import '../../../css/storyblok-custom.css';


const TermLink = ({
    term,
    displayedTerm,
}) => {

    const TooltipRenderer = (item, definition, content) => {
        const text = <span>
            <b>{term+": "}</b>
            {definition}
        </span>;
        return (
            <Tooltip overlayClassName={"tooltip"} placement="bottom" overlay={text}>
                {content}
            </Tooltip> 
        );
    };

    const linkFetchRenderer = (item, link) => {
        let passedLink = null;
        let text=null;
        if (link) {
            passedLink = link;
            if (link.substring(0,5) === "https") {
                text=<a href={passedLink} data-tip data-for={item}>
                    <b>{displayedTerm}</b>
                </a>;
            }
            else {
                text=<HashLink to={"/x/"+passedLink} data-tip data-for={item}>
                    <b>{displayedTerm}</b>
                </HashLink>;
            }
        }
        else {
            text = <button onClick={()=>{return null;}} data-tip data-for={item}>
                <b>{displayedTerm}</b>
            </button>;
        }
        return (
            <>
                <StoryblokDataSourceFetcher name={term} datasource={"platform-terminology"} innerContent={text}
                    render={TooltipRenderer} nullRender={()=>{return null;}}/>
            </>
        );
    };

    return (   
        <>
            <StoryblokDataSourceFetcher name={term} datasource={"platform-terminology-links"} 
                render={linkFetchRenderer} nullRender={linkFetchRenderer}/>
        </>
    );
};


TermLink.propTypes = {
    term: PropTypes.string,
    displayedTerm: PropTypes.string,
};

TermLink.defaultProps = {
    term: "",
    displayedTerm: "",
};

export default TermLink;

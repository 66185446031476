import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {KeycloakProvider} from '@react-keycloak/web';
import {BrowserRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from './utils/eventEmitter';
import {setKeycloakReady, setTokens, setUser} from './store/actions';
import {getUserInfoFromToken} from './utils/tokenFunctions';
import AppLayout from './containers/Layout';
import GoogleAnalytics from './utils/GoogleAnalytics';
import ScrollHandler from './utils/ScrollHandler';
import './css/font-awesome.min.css';

class App extends Component {

    /**
     * Process auth events from Keycloak
     *
     * @param event
     * @param error
     */
    handleKeycloakEvent = (event, error) => {
        // console.log('Keycloak event: ', event);
        // console.log('KC event error: ', error);
        if (event === 'onReady') {
            this.props.setKeycloakReady();
        }
        if (event === 'onAuthSuccess') {
            Emitter.emit('tokenEvent', 'auth');
        }
        if (event === 'onAuthRefreshSuccess') {
            Emitter.emit('tokenEvent', 'auth');
        }
        if (event === 'onTokenExpired') {
            Emitter.emit('tokenEvent', 'expired');
        }
        if (event === 'onAuthLogout') {
            Emitter.emit('tokenEvent', 'logout');
        }
    };

    /**
     * Process tokens event from Keycloak
     *
     * @param event
     * @param error
     */
    handleKeycloakTokens = (event, error) => {
        // console.log('Keycloak token event: ', event);
        // console.log('Keycloak token error: ', error);
        if (event && (event.token || event.refreshToken)) {
            const tokens = {
                token: event.token,
                idToken: event.idToken,
                refreshToken: event.refreshToken,
            };

            const tokenUser = getUserInfoFromToken(tokens.token);
            const userObj = {
                email: tokenUser.email,
                family_name: tokenUser.family_name,
                gitlab_user_name: '',
                given_name: tokenUser.given_name,
                groups: tokenUser.groups,
                is_sysop: tokenUser.is_sysop,
                name: tokenUser.name,
                persona: {
                    id: null,
                    name: null
                },
                preferred_username: tokenUser.preferred_username,
            };

            // Store token
            this.props.setTokens({token: tokens.token});
            // Store user information
            this.props.setUser(userObj);

        } else if (error) {
            console.log('Keycloak token error: ', error);
        }
    };

    render() {
        const {
            configParams,
            keycloak,
            keycloakInitConfig
        } = this.props;

        return (
            <KeycloakProvider
                keycloak={keycloak}
                initConfig={keycloakInitConfig}
                onEvent={this.handleKeycloakEvent}
                onTokens={this.handleKeycloakTokens}
            >
                <BrowserRouter>
                    <ScrollHandler/>
                    <AppLayout configParams={configParams}/>
                    <GoogleAnalytics configParams={configParams}/>
                </BrowserRouter>
            </KeycloakProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        tokens: {
            token: state.tokens.token,
            idToken: state.tokens.idToken,
            refreshToken: state.tokens.refreshToken
        },
        user: {
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setKeycloakReady: setKeycloakReady,
    setTokens: setTokens,
    setUser: setUser
};

App.propTypes = {
    configParams: PropTypes.object,
    keycloak: PropTypes.any,
    keycloakInitConfig: PropTypes.object,
    tokens: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

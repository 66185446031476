import React from 'react';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {makeDateString} from '../utils/dataFunctions';

//@TODO: Create an Image List once API is in place for images related to Projects.

/**
 * Project Item (grid)
 *
 * @param projectItem
 * @param classes
 * @param inInvitedProjects
 * @param inOwnProjects
 * @param inUserOrOwnProjects
 * @param clickFunc
 * @param clickLink
 * @param progress
 * @return {*}
 * @constructor
 */
export default function ProjectItem({
    projectItem,
    classes,
    inInvitedProjects = false,
    inOwnProjects = false,
    inUserOrOwnProjects = false,
    clickFunc = null,
    clickLink = null,
    progress = false
}) {

    // Set classes, button text, strings
    const classStr = (classes) ? classes : '';
    const buttonText = (inUserOrOwnProjects) ? 'Access' : 'Preview';
    const dateObj = (projectItem.due_date) ? new Date(projectItem.due_date.replace(/-/g, '/').replace(/T.+/, '')) : null;
    const dueStr = (dateObj) ? makeDateString(dateObj, 'm', false) : null;

    // If requested, create a Progress element with days remaining
    let days, remainingStr;
    let percent = 0;
    let projectType = 'Personal Project';
    if (progress) {
        const created = new Date(projectItem.created_on);
        const due = new Date(projectItem.due_date);
        const today = new Date();
        days = (Math.ceil((today.getTime() - due.getTime()) / (1000 * 60 * 60 * 24))) * -1;
        remainingStr = (days === 1) ? days + ' day left' : days + ' days left';
        if (days < 0) {
            remainingStr = 'Due Date Past';
        }
        percent = ((today.getTime() - created.getTime()) / (due.getTime() - created.getTime()) * 100);
        if (percent < 0.1) {
            percent = 1;
        }
        if (percent > 99.9) {
            percent = 100;
        }
        if (inInvitedProjects) {
            projectType = 'Shared Personal Project';
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        if (clickFunc) {
            clickFunc(projectItem.id);
        }
    };

    return (
        <div>
            {progress &&
            <div className="xrathus-item-progress center padding-0">
                <div className="details">
                    <span className="name">{projectType}</span>
                    <span className="remaining">{remainingStr}</span>
                </div>
                <div className="progress">
                    <span className="bar" style={{width: percent + '%'}}>
                    </span>
                </div>
            </div>
            }
            <div className={`xrathus-item ${classStr}`}>

                <div className="item-header">
                    <div className="row">
                        <div className={"col col-xs-12 col-sm-12"}>
                            <h4>{projectItem.title}</h4>
                        </div>
                    </div>
                </div>

                <div className="item-content">
                    <div className="short-description">
                        <ReactMarkdown source={projectItem.short_desc}/>
                    </div>
                    <div className="details">
                        <p className="owner">
                            Project Owner: {projectItem.username}
                        </p>
                        {dueStr &&
                        <p className="due-date">
                            Due Date: {dueStr}
                        </p>
                        }
                    </div>
                </div>

                <div className="item-footer">
                    {clickFunc !== null &&
                    <button onClick={(e) => handleClick(e)} title={buttonText}>
                        {buttonText}
                    </button>
                    }
                    {clickLink !== null &&
                    <Link to={clickLink} title={projectItem.title}>{buttonText}</Link>
                    }
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { parseStoryblokContent } from '../../../utils/StoryblokFunctions';
import BreadCrumbs from '../basic/BreadCrumbs';

import '../../../css/storyblok-custom.css';

const StoryblokWebpage = ({
    title, 
    titleStyle,
    subtitle, 
    slug, 
    logo, 
    hideLogo,
    logoCustomClass, 
    content, 
    contentCustomClass,
    hideBreadCrumbs,
    breadCrumbStepsToJump,
    storyblokVisualEditorComponent,
    allStories,
    demoRouter,
}) => {

    const logoClass = logoCustomClass ? logoCustomClass : "title";

    return (
        <div className={"content collaborator " + slug}>
            {storyblokVisualEditorComponent}
            {!hideBreadCrumbs ?
                <BreadCrumbs pageLink={window.location.pathname} startIndex={2} demoRouter={demoRouter} stepsToJump={breadCrumbStepsToJump}/>
                : null
            }
            {!hideBreadCrumbs ?<div><br/>&nbsp;</div> : null}
            {
                (logo && !hideLogo) ?
                    <h1 className={logoClass}>
                        <img src={logo} alt={title}/>
                    </h1>
                    : null
            }
            {
                title ?
                    <>
                        <h1 className={titleStyle}>{title}</h1>
                        {subtitle ? <h3 className="header">{subtitle}</h3> : <></>}
                        <br/>&nbsp;
                    </>
                    : null
            }

            <div className={contentCustomClass}>
                { parseStoryblokContent(content, "", "", allStories) }
            </div>
        </div>
    );
};


StoryblokWebpage.propTypes = {
    /**
     * The displayed title of the page
     */
    title: PropTypes.string,
    /**
     * The styling of the page title
     */
    titleStyle: PropTypes.oneOf([
        'header', 
        'page-title']),
    /**
     * The displayed subtitle of the page
     */
    subtitle: PropTypes.string,
    /**
     * The slug, usually similar to the title
     */
    slug: PropTypes.string,
    /**
     * The top logo.
     */
    logo: PropTypes.string,
    /**
     * Custom class for the logo. 
     * Ignored if no logo is passed.
     * @TODO: Eliminate the need for this variable.
     */
    logoCustomClass: PropTypes.string,
    /**
     * The main body content of the page
     */
    hideLogo: PropTypes.bool,
    content: PropTypes.array,
    contentCustomClass: PropTypes.string,
    /**
     * Hide breadcrumbs on this page. Usually a landing-style page.
     */
    hideBreadCrumbs: PropTypes.bool,
    breadCrumbStepsToJump: PropTypes.number,
    /**
     * Component that loads the code for running the Visual Editor bridge
     */
    storyblokVisualEditorComponent: PropTypes.object,
    /**
     * All the extra content that may be related that is parsed through to find the relevant content
     */
    allStories: PropTypes.object,
    /**
     * If element is used outside Xrathus site, an error may be thrown.
     * Turning on this boolean makes sure that doesn't happen.
     */
    demoRouter: PropTypes.bool,
};

StoryblokWebpage.defaultProps = {
    title: "", 
    titleStyle: 'header',
    subtitle: "", 
    slug: "", 
    logo: "", 
    logoCustomClass: "", 
    hideLogo: false,
    content: [], 
    contentCustomClass: "",
    hideBreadCrumbs: false,
    breadCrumbStepsToJump: 2,
    storyblokVisualEditorComponent: null,
    allStories: {},
    demoRouter: false,
};

export default StoryblokWebpage;

export const XRATHUS_PAGE_FORM = {
    'home': {
        title: 'How can we help you collaborate more productively?',
        content1: 'Ready to see what Xrathus can do for you?',
        content2: `
Book a demo today or fill in the contact form and one of our specialists will get in touch to learn more about your business and explore how we can help you collaborate.
        `
    },
    'substrates': {
        title: 'Which aspect of collaboration interests you most?',
        content1: 'Ready to put Xrathus to the test?',
        content2: `
Book a demo today or fill in the contact form and one of our specialists will get in touch to learn more about your collaboration challenge.
        `
    },
    'use-cases': {
        title: 'How can Xrathus help your ideas flourish?',
        content1: 'Ready to work in the Xrathus ecosystem?',
        content2: `
Book a demo today or fill in the contact form and one of our specialists will get in touch to learn more about your collaboration challenge.
        `
    }
};

import React from 'react';

const IconAddCircle = () => {
    return (
        <span className="icon-add-circle">
            <svg version="1.1" id="icon-add-circle"
                 x="0px" y="0px"
                 width="115.2px" height="115.2px" viewBox="-49.5 65.5 115.2 115.2"
                 enableBackground="new -49.5 65.5 115.2 115.2"
                 xmlSpace="preserve">
				<g>
					<g>
						<g>
							<path fill="#5a358c" d="M-32.56,163.76c5.279,5.28,11.429,9.425,18.276,12.321c7.092,3,14.623,4.521,22.384,4.521
				c7.761,0,15.292-1.521,22.383-4.521c6.848-2.896,12.997-7.042,18.277-12.321c5.279-5.279,9.425-11.429,12.321-18.276
				c2.999-7.092,4.521-14.623,4.521-22.384s-1.521-15.292-4.521-22.384c-2.896-6.848-7.042-12.997-12.321-18.276
				c-5.28-5.279-11.43-9.425-18.277-12.321C23.392,67.119,15.86,65.598,8.1,65.598c-7.761,0-15.292,1.521-22.384,4.521
				c-6.848,2.896-12.997,7.042-18.276,12.322c-5.28,5.279-9.425,11.428-12.322,18.276c-3,7.092-4.521,14.622-4.521,22.383
				c0,7.762,1.521,15.292,4.521,22.384C-41.985,152.331-37.84,158.48-32.56,163.76z M-37.159,103.982
				c2.474-5.848,6.016-11.101,10.528-15.613c4.512-4.512,9.766-8.054,15.614-10.527c6.052-2.561,12.484-3.858,19.117-3.858
				c6.632,0,13.064,1.298,19.118,3.858c5.848,2.474,11.101,6.016,15.613,10.527c4.512,4.513,8.054,9.766,10.527,15.614
				c2.56,6.052,3.858,12.484,3.858,19.116c0,6.633-1.299,13.064-3.858,19.118c-2.474,5.848-6.016,11.101-10.527,15.613
				c-4.513,4.512-9.766,8.054-15.613,10.527c-6.054,2.56-12.485,3.858-19.118,3.858s-13.064-1.299-19.117-3.858
				c-5.848-2.474-11.102-6.016-15.614-10.527c-4.512-4.513-8.054-9.766-10.528-15.614c-2.56-6.053-3.858-12.484-3.858-19.117
				C-41.017,116.468-39.718,110.035-37.159,103.982z"/>
				<path fill="#5a358c" d="M8.1,180.7c-7.774,0-15.318-1.523-22.422-4.528c-6.859-2.901-13.019-7.054-18.308-12.343
				c-5.288-5.288-9.441-11.448-12.342-18.308c-3.005-7.104-4.528-14.647-4.528-22.422c0-7.773,1.523-15.317,4.528-22.422
				c2.901-6.859,7.054-13.019,12.342-18.307c5.289-5.289,11.448-9.441,18.307-12.343C-7.218,67.023,0.326,65.5,8.1,65.5
				s15.318,1.523,22.421,4.528c6.859,2.901,13.02,7.054,18.308,12.343c5.289,5.288,9.441,11.447,12.343,18.307
				c3.005,7.104,4.528,14.648,4.528,22.422c0,7.774-1.523,15.318-4.528,22.422c-2.901,6.859-7.054,13.02-12.343,18.308
				c-5.288,5.289-11.448,9.441-18.308,12.343C23.418,179.177,15.874,180.7,8.1,180.7z M8.1,65.695
				c-7.748,0-15.266,1.519-22.346,4.513c-6.836,2.892-12.975,7.03-18.246,12.301c-5.27,5.271-9.409,11.409-12.3,18.245
				c-2.995,7.08-4.513,14.599-4.513,22.346c0,7.748,1.518,15.267,4.513,22.346c2.892,6.836,7.03,12.975,12.3,18.246
				c5.271,5.271,11.409,9.408,18.246,12.3c7.08,2.995,14.598,4.513,22.346,4.513c7.748,0,15.266-1.518,22.345-4.513
				c6.836-2.892,12.975-7.029,18.246-12.3c5.27-5.271,9.408-11.41,12.3-18.246c2.994-7.079,4.513-14.598,4.513-22.346
				c0-7.747-1.519-15.266-4.513-22.346c-2.892-6.836-7.03-12.975-12.301-18.245S37.281,73.1,30.445,70.208
				C23.365,67.214,15.848,65.695,8.1,65.695z M8.1,172.314c-6.646,0-13.09-1.301-19.155-3.866
				c-5.86-2.479-11.123-6.027-15.645-10.549c-4.521-4.521-8.07-9.784-10.549-15.645c-2.565-6.064-3.866-12.509-3.866-19.155
				c0-6.646,1.301-13.09,3.866-19.154c2.479-5.86,6.028-11.124,10.549-15.645c4.521-4.521,9.785-8.07,15.645-10.549
				C-4.99,75.187,1.454,73.886,8.1,73.886s13.091,1.301,19.155,3.866c5.86,2.479,11.123,6.027,15.645,10.549
				s8.07,9.785,10.549,15.645c2.565,6.064,3.865,12.509,3.865,19.155c0,6.646-1.3,13.09-3.865,19.155
				c-2.479,5.859-6.027,11.123-10.549,15.645c-4.521,4.521-9.784,8.069-15.645,10.548C21.19,171.014,14.746,172.314,8.1,172.314z
				 M8.1,74.081c-6.62,0-13.039,1.296-19.079,3.851c-5.836,2.469-11.08,6.004-15.583,10.507s-8.038,9.746-10.507,15.583
				c-2.555,6.04-3.85,12.459-3.85,19.078c0,6.62,1.295,13.039,3.85,19.079c2.469,5.837,6.004,11.08,10.507,15.583
				c4.503,4.503,9.746,8.038,15.583,10.507c6.041,2.555,12.459,3.85,19.079,3.85c6.619,0,13.038-1.295,19.079-3.85
				c5.837-2.469,11.079-6.004,15.583-10.507c4.503-4.504,8.037-9.746,10.507-15.582c2.555-6.041,3.85-12.46,3.85-19.08
				c0-6.619-1.295-13.038-3.85-19.078c-2.469-5.837-6.004-11.079-10.507-15.583c-4.504-4.503-9.746-8.038-15.583-10.507
				C21.138,75.377,14.719,74.081,8.1,74.081z"/>
						</g>
						<g>
							<path fill="#5a358c" d="M-18.01,127.293H3.787v21.798c0,2.315,1.877,4.193,4.193,4.193c2.316,0,4.193-1.878,4.193-4.193v-21.798
				h21.798c2.315,0,4.192-1.877,4.192-4.193c0-2.315-1.877-4.192-4.192-4.192H12.173V97.109c0-2.316-1.877-4.193-4.193-4.193
				c-2.315,0-4.193,1.877-4.193,4.193v21.798H-18.01c-2.316,0-4.193,1.877-4.193,4.192
				C-22.203,125.416-20.326,127.293-18.01,127.293z"/>
				<path fill="#5a358c" d="M7.98,153.382c-2.366,0-4.291-1.925-4.291-4.291v-21.7h-21.7c-2.366,0-4.291-1.925-4.291-4.291
				c0-2.365,1.925-4.29,4.291-4.29h21.7v-21.7c0-2.366,1.925-4.291,4.291-4.291s4.291,1.925,4.291,4.291v21.7h21.7
				c2.366,0,4.291,1.925,4.291,4.29c0,2.366-1.925,4.291-4.291,4.291h-21.7v21.7C12.271,151.457,10.346,153.382,7.98,153.382z
				 M-18.01,119.005c-2.258,0-4.095,1.837-4.095,4.095c0,2.259,1.837,4.096,4.095,4.096H3.885v21.896
				c0,2.258,1.837,4.095,4.095,4.095c2.258,0,4.095-1.837,4.095-4.095v-21.896h21.896c2.258,0,4.095-1.837,4.095-4.096
				c0-2.258-1.837-4.095-4.095-4.095H12.075V97.109c0-2.258-1.837-4.096-4.095-4.096c-2.258,0-4.095,1.838-4.095,4.096v21.896
				H-18.01z"/>
						</g>
					</g>
				</g>
            </svg>
        </span>
    )
};

export default IconAddCircle;
